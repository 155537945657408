import { AUTENTIFICACION_FALLIDO } from "../actions/auth/types";
import {
  ARTICULO_TAREA_SELECCIONADO,
  CARGANDO_LIMPIAR_LEYES,
  LIMPIAR_NORMAS_FILTROS,
  MENSAJES_LIMPIAR,
  NORMAS_ESTADISTICAS_FALLIDO,
  NORMAS_ESTADISTICAS_OBTENER,
  NORMAS_FALLIDO,
  NORMAS_FAVORITAS_FALLIDO,
  NORMAS_FAVORITAS_OBTENER,
  NORMAS_FILTROS_FALLIDO,
  NORMAS_FILTROS_INPUT,
  NORMAS_FILTROS_OBTENER,
  NORMAS_OBTENER,
  NORMAS_PROMULGADAS_FALLIDO,
  NORMAS_PROMULGADAS_OBTENER,
  NORMAS_PUBLICADAS_FALLIDO,
  NORMAS_PUBLICADAS_OBTENER,
  NORMA_ALERTAS_FALLIDO,
  NORMA_ALERTAS_OBTENER,
  NORMA_ALERTA_CREAR_FALLIDO,
  NORMA_ALERTA_CREAR_OBTENER,
  NORMA_ALERTA_ELIMINAR_FALLIDO,
  NORMA_ALERTA_ELIMINAR_OBTENER,
  NORMA_FALLIDO,
  NORMA_FAVORITA_CREAR_FALLIDO,
  NORMA_FAVORITA_CREAR_OBTENER,
  NORMA_FAVORITA_ELIMINAR_FALLIDO,
  NORMA_FAVORITA_ELIMINAR_OBTENER,
  NORMA_GUARDAR,
  NORMA_OBTENER,
  REDIRECT_FAV,
  TITULO_SELECCIONADO,
} from "../actions/leyes/types";

const initialState = {
  is_loading_leyes: true,
  // norma
  norma: {},
  norma_id: 0,
  norma_favorita: {},
  norma_favorita_error: {},
  norma_favorita_eliminar: {},
  norma_favorita_eliminar_error: {},
  // alertas
  normas_alertas: {},
  normas_alertas_filtros: {},
  normas_alerta_eliminar: {},
  normas_alerta_eliminar_error: {},
  normas_alerta_crear: {},
  normas_alerta_crear_error: {},
  // normas
  normas: {},
  normas_filtros: {},
  filtros_normas: {},
  filtros_errores: {},
  normas_filtro_input: {},
  normas_publicadas: {},
  normas_promulgadas: {},
  normas_estadisticas: {},
  // normas favoritas
  normas_favoritas: {},
  redirect_fav: false,
  // articulo
  articulo_tarea_seleccionado: {},
  titulo_seleccionado: [],
};

export default function vigilancias(state = initialState, action) {
  switch (action.type) {
    case AUTENTIFICACION_FALLIDO:
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
        is_authenticated: false,
        is_loading_leyes: false,
        errores: action.payload,
      };
    case NORMA_ALERTAS_OBTENER:
      return {
        ...state,
        normas_alertas: action.payload.alertas,
        normas_alertas_filtros: action.payload.filtros,
        is_loading_leyes: false,
      };
    case NORMA_ALERTAS_FALLIDO:
      return {
        ...state,
        normas_alertas: {},
        normas_alertas_filtros: {},
        is_loading_leyes: false,
      };
    case NORMA_ALERTA_CREAR_FALLIDO:
      return {
        ...state,
        normas_alerta_crear: {},
        normas_alerta_crear_error: action.payload,
      };
    case NORMA_ALERTA_CREAR_OBTENER:
      return {
        ...state,
        normas_alerta_crear: action.payload,
        normas_alerta_crear_error: {},
      };
    case NORMA_ALERTA_ELIMINAR_FALLIDO:
      return {
        ...state,
        normas_alerta_eliminar: {},
        normas_alerta_eliminar_error: action.payload,
      };
    case NORMA_ALERTA_ELIMINAR_OBTENER:
      return {
        ...state,
        normas_alerta_eliminar: action.payload,
        normas_alerta_eliminar_error: {},
      };
    case NORMAS_FILTROS_INPUT:
      return {
        ...state,
        normas_filtro_input: action.payload,
      };
    case NORMAS_FILTROS_OBTENER:
      return {
        ...state,
        normas_filtros: action.payload.normas,
        filtros_errores: {},
        is_loading_leyes: false,
      };
    case NORMAS_FILTROS_FALLIDO:
      return {
        ...state,
        normas_filtros: {},
        filtros_errores: action.payload,
        is_loading_leyes: false,
      };
    case NORMAS_ESTADISTICAS_OBTENER:
      return {
        ...state,
        normas_estadisticas: action.payload.normas,
        is_loading_leyes: false,
      };
    case NORMAS_ESTADISTICAS_FALLIDO:
      return {
        ...state,
        normas_estadisticas: {},
        is_loading_leyes: false,
      };
    case NORMAS_PUBLICADAS_OBTENER:
      return {
        ...state,
        normas_publicadas: action.payload.normas,
        is_loading_leyes: false,
      };
    case NORMAS_PUBLICADAS_FALLIDO:
      return {
        ...state,
        normas_publicadas: {},
        is_loading_leyes: false,
      };
    case NORMAS_PROMULGADAS_OBTENER:
      return {
        ...state,
        normas_promulgadas: action.payload.normas,
        is_loading_leyes: false,
      };
    case NORMAS_PROMULGADAS_FALLIDO:
      return {
        ...state,
        normas_promulgadas: {},
        is_loading_leyes: false,
      };
    case ARTICULO_TAREA_SELECCIONADO:
      return {
        ...state,
        articulo_tarea_seleccionado: action.payload,
      };
    case NORMAS_OBTENER:
      return {
        ...state,
        normas: action.payload.normas,
        filtros_normas: action.payload.filtros,
        is_loading_leyes: false,
      };
    case NORMAS_FALLIDO:
      return {
        ...state,
        normas: {},
        filtros_normas: {},
        is_loading_leyes: false,
      };
    case REDIRECT_FAV:
      return {
        ...state,
        redirect_fav: action.payload,
      };
    case NORMA_FAVORITA_ELIMINAR_OBTENER:
      return {
        ...state,
        norma_favorita_eliminar: action.payload,
        norma_favorita_eliminar_error: {},
      };
    case NORMA_FAVORITA_ELIMINAR_FALLIDO:
      return {
        ...state,
        norma_favorita_eliminar: {},
        norma_favorita_eliminar_error: action.payload,
      };
    case NORMA_FAVORITA_CREAR_OBTENER:
      return {
        ...state,
        norma_favorita: action.payload,
        norma_favorita_error: {},
      };
    case NORMA_FAVORITA_CREAR_FALLIDO:
      return {
        ...state,
        norma_favorita: {},
        norma_favorita_error: action.payload,
      };
    case MENSAJES_LIMPIAR:
      return {
        ...state,
        norma_favorita: {},
        norma_favorita_error: {},
        norma_favorita_eliminar: {},
        norma_favorita_eliminar_error: {},
        normas_alerta_eliminar: {},
        normas_alerta_eliminar_error: {},
        normas_alerta_crear_error: {},
        normas_alerta_crear: {},
        redirect_fav: false,
      };
    case NORMAS_FAVORITAS_OBTENER:
      return {
        ...state,
        normas_favoritas: action.payload,
        is_loading_leyes: false,
        norma_id: 0,
        titulo_seleccionado: [],
      };
    case NORMAS_FAVORITAS_FALLIDO:
      return {
        ...state,
        normas_favoritas: {},
        is_loading_leyes: false,
        norma_id: 0,
        titulo_seleccionado: [],
      };
    case NORMA_OBTENER:
      return {
        ...state,
        norma: action.payload,
        is_loading_leyes: false,
      };
    case NORMA_FALLIDO:
      return {
        ...state,
        norma: {},
        is_loading_leyes: false,
      };
    case CARGANDO_LIMPIAR_LEYES:
      return {
        ...state,
        is_loading_leyes: true,
        normas_ultimas: {},
      };
    case LIMPIAR_NORMAS_FILTROS:
      return {
        ...state,
        is_loading_leyes: true,
        normas_filtros: {},
        normas_filtro_input: {},
        normas: {},
      };
    case NORMA_GUARDAR:
      return {
        ...state,
        norma_id: action.payload,
      };
    case TITULO_SELECCIONADO:
      return {
        ...state,
        titulo_seleccionado: action.payload,
      };
    default:
      return state;
  }
}
