import { AUTENTIFICACION_FALLIDO } from '../actions/auth/types';
import {
  PLANACCION_ELIMINAR_FALLIDO,
  PLANACCION_ELIMINAR_OBTENER,
  PLANACCION_ESTADO_FALLIDO,
  PLANACCION_ESTADO_OBTENER,
  PLANACCION_FALLIDO,
  PLANACCION_OBTENER,
} from '../actions/tareas/planaccion/types';
import {
  PROYECTO_EDITAR_FALLIDO,
  PROYECTO_EDITAR_OBTENER,
  PROYECTO_ELIMINAR_FALLIDO,
  PROYECTO_ELIMINAR_OBTENER,
  PROYECTO_NUEVO_FALLIDO,
  PROYECTO_NUEVO_OBTENER,
  PROYECTOS_TAREAS_FALLIDO,
  PROYECTOS_TAREAS_OBTENER,
} from '../actions/tareas/proyectos/types';
import {
  ARCHIVO_ELIMINAR_FALLIDO,
  ARCHIVO_ELIMINAR_OBTENER,
  ARCHIVO_ESTADO_FALLIDO,
  ARCHIVO_ESTADO_OBTENER,
  ARCHIVO_NUEVO_FALLIDO,
  ARCHIVO_NUEVO_OBTENER,
  CARGA_NUEVO_FALLIDO,
  CARGA_NUEVO_OBTENER,
  CARGANDO_LIMPIAR_TAREAS,
  ENTREGABLE_DUPLICAR_FALLIDO,
  ENTREGABLE_DUPLICAR_OBTENER,
  ENTREGABLE_EDITAR_FALLIDO,
  ENTREGABLE_EDITAR_OBTENER,
  ENTREGABLE_ELIMINAR_FALLIDO,
  ENTREGABLE_ELIMINAR_OBTENER,
  ENTREGABLE_ESTADO_FALLIDO,
  ENTREGABLE_ESTADO_OBTENER,
  ENTREGABLE_NUEVO_FALLIDO,
  ENTREGABLE_NUEVO_OBTENER,
  ENTREGABLE_RESPONSABLE_FALLIDO,
  ENTREGABLE_RESPONSABLE_OBTENER,
  EQUIPO_ELIMINAR_FALLIDO,
  EQUIPO_ELIMINAR_OBTENER,
  EQUIPO_NUEVO_FALLIDO,
  EQUIPO_NUEVO_OBTENER,
  LOADING_TRUE,
  MENSAJES_LIMPIAR,
  RECURSO_NUEVO_FALLIDO,
  RECURSO_NUEVO_OBTENER,
  REQUISITO_ELIMINAR_FALLIDO,
  REQUISITO_ELIMINAR_OBTENER,
  REQUISITO_NUEVO_FALLIDO,
  REQUISITO_NUEVO_OBTENER,
  TAREA_EDITAR_FALLIDO,
  TAREA_EDITAR_OBTENER,
  TAREA_ELIMINAR_FALLIDO,
  TAREA_ELIMINAR_OBTENER,
  TAREA_ESTADO_FALLIDO,
  TAREA_ESTADO_OBTENER,
  TAREA_FALLIDO,
  TAREA_NUEVA_FALLIDO,
  TAREA_NUEVA_OBTENER,
  TAREA_OBTENER,
  TAREA_RESPONSABLE_FALLIDO,
  TAREA_RESPONSABLE_OBTENER,
  TAREA_SELECCIONADA,
} from '../actions/tareas/types';

const initialState = {
  is_loading_tareas: true,
  proyectos: [],
  tarea_seleccionada: 0,
  tarea: {},
  tarea_responsable_asignar: {},
  tarea_responsable_asignar_error: {},
  entregable_responsable_asignar: {},
  entregable_responsable_asignar_error: {},
  equipo: [],
  equipo_nuevo: {},
  equipo_nuevo_error: {},
  equipo_eliminar: {},
  equipo_eliminar_error: {},
  tarea_estado: {},
  tarea_estado_error: {},
  entregable_estado: {},
  entregable_estado_error: {},
  entregable_editar: {},
  entregable_editar_error: {},
  entregable_eliminar: {},
  entregable_eliminar_error: {},
  entregable_duplicar: {},
  entregable_duplicar_error: {},
  entregable_nuevo: {},
  entregable_nuevo_error: {},
  requisito_nuevo: {},
  requisito_nuevo_error: {},
  requisito_eliminar: {},
  requisito_eliminar_error: {},
  archivo_estado: {},
  archivo_estado_error: {},
  tarea_eliminar: {},
  tarea_eliminar_error: {},
  tarea_crear: {},
  tarea_crear_error: {},
  tarea_editar: {},
  tarea_editar_error: {},
  archivo_carga: {},
  archivo_carga_error: {},
  archivo_recurso: {},
  archivo_recurso_error: {},
  archivo_eliminar: {},
  archivo_eliminar_error: {},
  proyecto_crear: {},
  proyecto_crear_error: {},
  proyecto_eliminar: {},
  proyecto_eliminar_error: {},
  proyecto_editar: {},
  proyecto_editar_error: {},
  archivo_nuevo: {},
  archivo_nuevo_error: {},
  planaccion_nuevo: {},
  planaccion_nuevo_error: {},
  planaccion_eliminar: {},
  planaccion_eliminar_error: {},
  planaccion_estado: {},
  planaccion_estado_error: {},
};

export default function soporte(state = initialState, action) {
  switch (action.type) {
    case AUTENTIFICACION_FALLIDO:
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
        is_authenticated: false,
        is_loading_tareas: false,
        errores: action.payload
      };
    case CARGANDO_LIMPIAR_TAREAS:
      return {
        ...state,
        is_loading_tareas: true,
      };
    case PLANACCION_ELIMINAR_OBTENER:
      return {
        ...state,
        is_loading_tareas: false,
        planaccion_eliminar: action.payload,
        planaccion_eliminar_error: {},
      };
    case PLANACCION_ESTADO_OBTENER:
      return {
        ...state,
        is_loading_tareas: false,
        planaccion_estado: action.payload,
        planaccion_estado_error: {},
      };
    case PLANACCION_ESTADO_FALLIDO:
      return {
        ...state,
        is_loading_tareas: false,
        planaccion_estado: {},
        planaccion_estado_error: action.payload,
      };
    case PLANACCION_ELIMINAR_FALLIDO:
      return {
        ...state,
        is_loading_tareas: false,
        planaccion_eliminar_error: action.payload,
        planaccion_eliminar: {},
      };
    case PLANACCION_OBTENER:
      return {
        ...state,
        is_loading_tareas: false,
        planaccion_nuevo: action.payload,
        planaccion_nuevo_error: {},
      };
    case PLANACCION_FALLIDO:
      return {
        ...state,
        is_loading_tareas: false,
        planaccion_nuevo_error: action.payload,
        planaccion_nuevo: {},
      };
    case ENTREGABLE_DUPLICAR_OBTENER:
      return {
        ...state,
        is_loading_tareas: false,
        entregable_duplicar: action.payload,
        entregable_duplicar_error: {},
      };
    case ENTREGABLE_DUPLICAR_FALLIDO:
      return {
        ...state,
        is_loading_tareas: false,
        entregable_duplicar_error: action.payload,
        entregable_duplicar: {},
      };
    case TAREA_RESPONSABLE_FALLIDO:
      return {
        ...state,
        is_loading_tareas: false,
        tarea_responsable_asignar_error: action.payload,
        tarea_responsable_asignar: {},
      };
    case TAREA_RESPONSABLE_OBTENER:
      return {
        ...state,
        is_loading_tareas: false,
        tarea_responsable_asignar: action.payload,
        tarea_responsable_asignar_error: {},
      };
    case ENTREGABLE_RESPONSABLE_FALLIDO:
      return {
        ...state,
        is_loading_tareas: false,
        entregable_responsable_asignar_error: action.payload,
        entregable_responsable_asignar: {},
      };
    case ENTREGABLE_RESPONSABLE_OBTENER:
      return {
        ...state,
        is_loading_tareas: false,
        entregable_responsable_asignar: action.payload,
        entregable_responsable_asignar_error: {},
      };
    case ARCHIVO_NUEVO_OBTENER:
      return {
        ...state,
        archivo_nuevo: action.payload,
        archivo_nuevo_error: {},
      };
    case ARCHIVO_NUEVO_FALLIDO:
      return {
        ...state,
        archivo_nuevo_error: action.payload,
        archivo_nuevo: {},
      };
    case PROYECTO_EDITAR_OBTENER:
      return {
        ...state,
        proyecto_editar: action.payload,
        proyecto_editar_error: {},
      };
    case PROYECTO_EDITAR_FALLIDO:
      return {
        ...state,
        proyecto_editar: {},
        proyecto_editar_error: action.payload,
      };
    case PROYECTO_ELIMINAR_OBTENER:
      return {
        ...state,
        proyecto_eliminar: action.payload,
        proyecto_eliminar_error: {},
      };
    case PROYECTO_ELIMINAR_FALLIDO:
      return {
        ...state,
        proyecto_eliminar: {},
        proyecto_eliminar_error: action.payload,
      };
    case ARCHIVO_ELIMINAR_OBTENER:
      return {
        ...state,
        archivo_eliminar: action.payload,
        archivo_eliminar_error: {},
      };
    case ARCHIVO_ELIMINAR_FALLIDO:
      return {
        ...state,
        archivo_eliminar_error: action.payload,
        archivo_eliminar: {},
      };
    case RECURSO_NUEVO_FALLIDO:
      return {
        ...state,
        archivo_recurso_error: action.payload,
        archivo_recurso: {},
      };
    case RECURSO_NUEVO_OBTENER:
      return {
        ...state,
        archivo_recurso: action.payload,
        archivo_recurso_error: {},
      };
    case CARGA_NUEVO_FALLIDO:
      return {
        ...state,
        archivo_carga_error: action.payload,
        archivo_carga: {},
      };
    case CARGA_NUEVO_OBTENER:
      return {
        ...state,
        archivo_carga: action.payload,
        archivo_carga_error: {},
      };
    case TAREA_EDITAR_FALLIDO:
      return {
        ...state,
        tarea_editar_error: action.payload,
        tarea_editar: {},
      };
    case TAREA_EDITAR_OBTENER:
      return {
        ...state,
        tarea_editar: action.payload,
        tarea_editar_error: {},
      };
    case PROYECTO_NUEVO_OBTENER:
      return {
        ...state,
        proyecto_crear: action.payload,
        proyecto_crear_error: {},
      };
    case PROYECTO_NUEVO_FALLIDO:
      return {
        ...state,
        proyecto_crear_error: action.payload,
        proyecto_crear: {},
      };
    case TAREA_NUEVA_OBTENER:
      return {
        ...state,
        tarea_crear: action.payload,
        tarea_crear_error: {},
      };
    case TAREA_NUEVA_FALLIDO:
      return {
        ...state,
        tarea_crear_error: action.payload,
        tarea_crear: {},
      };
    case TAREA_ELIMINAR_FALLIDO:
      return {
        ...state,
        tarea_eliminar_error: action.payload,
        tarea_eliminar: {},
      };
    case TAREA_ELIMINAR_OBTENER:
      return {
        ...state,
        tarea_eliminar: action.payload,
        tarea_eliminar_error: {},
      };
    case ARCHIVO_ESTADO_FALLIDO:
      return {
        ...state,
        archivo_estado_error: action.payload,
        archivo_estado: {},
      };
    case ARCHIVO_ESTADO_OBTENER:
      return {
        ...state,
        archivo_estado: action.payload,
        archivo_estado_error: {},
      };
    case REQUISITO_ELIMINAR_FALLIDO:
      return {
        ...state,
        requisito_eliminar_error: action.payload,
        requisito_eliminar: {},
      };
    case REQUISITO_ELIMINAR_OBTENER:
      return {
        ...state,
        requisito_eliminar: action.payload,
        requisito_eliminar_error: {},
      };
    case REQUISITO_NUEVO_FALLIDO:
      return {
        ...state,
        requisito_nuevo_error: action.payload,
        requisito_nuevo: {},
      };
    case REQUISITO_NUEVO_OBTENER:
      return {
        ...state,
        requisito_nuevo: action.payload,
        requisito_nuevo_error: {},
      };
    case ENTREGABLE_NUEVO_OBTENER:
      return {
        ...state,
        entregable_nuevo: action.payload,
        entregable_nuevo_error: {},
      };
    case ENTREGABLE_NUEVO_FALLIDO:
      return {
        ...state,
        entregable_nuevo_error: action.payload,
        entregable_nuevo: {},
      };
    case ENTREGABLE_ELIMINAR_FALLIDO:
      return {
        ...state,
        entregable_eliminar_error: action.payload,
        entregable_eliminar: {},
      };
    case ENTREGABLE_ELIMINAR_OBTENER:
      return {
        ...state,
        entregable_eliminar: action.payload,
        entregable_eliminar_error: {},
      };
    case ENTREGABLE_EDITAR_OBTENER:
      return {
        ...state,
        entregable_editar: action.payload,
        entregable_editar_error: {},
      };
    case ENTREGABLE_EDITAR_FALLIDO:
      return {
        ...state,
        entregable_editar_error: action.payload,
        entregable_editar: {},
      };
    case TAREA_ESTADO_OBTENER:
      return {
        ...state,
        tarea_estado: action.payload,
        tarea_estado_error: {},
      };
    case TAREA_ESTADO_FALLIDO:
      return {
        ...state,
        tarea_estado_error: action.payload,
        tarea_estado: {},
      };
    case ENTREGABLE_ESTADO_OBTENER:
      return {
        ...state,
        entregable_estado: action.payload,
        entregable_estado_error: {},
      };
    case ENTREGABLE_ESTADO_FALLIDO:
      return {
        ...state,
        entregable_estado_error: action.payload,
        entregable_estado: {},
      };
    case EQUIPO_ELIMINAR_OBTENER:
      return {
        ...state,
        equipo_eliminar: action.payload,
        equipo_eliminar_error: {},
      };
    case EQUIPO_ELIMINAR_FALLIDO:
      return {
        ...state,
        equipo_eliminar_error: action.payload,
        equipo_eliminar: {},
      };
    case EQUIPO_NUEVO_OBTENER:
      return {
        ...state,
        equipo_nuevo: action.payload,
        equipo_nuevo_error: {},
      };
    case EQUIPO_NUEVO_FALLIDO:
      return {
        ...state,
        equipo_nuevo_error: action.payload,
        equipo_nuevo: {},
      };
    case PROYECTOS_TAREAS_OBTENER:
      return {
        ...state,
        proyectos: action.payload,
        tarea_seleccionada: 0,
        is_loading_tareas: false,
      };
    case PROYECTOS_TAREAS_FALLIDO:
      return {
        ...state,
        proyectos: [],
        tarea_seleccionada: 0,
        is_loading_tareas: false,
      };
    case TAREA_SELECCIONADA:
      return {
        ...state,
        tarea_seleccionada: action.payload,
        is_loading_tareas: false,
      };
    case LOADING_TRUE:
      return {
        ...state,
        is_loading_tareas: true,
      };
    case TAREA_OBTENER:
      return {
        ...state,
        tarea: action.payload,
        is_loading_tareas: false,
      };
    case TAREA_FALLIDO:
      return {
        ...state,
        tarea: {},
        is_loading_tareas: false,
      };
    case MENSAJES_LIMPIAR:
      return {
        ...state,
        equipo_nuevo: {},
        equipo_nuevo_error: {},
        equipo_eliminar: {},
        equipo_eliminar_error: {},
        tarea_estado: {},
        tarea_estado_error: {},
        entregable_editar: {},
        entregable_editar_error: {},
        entregable_eliminar: {},
        entregable_eliminar_error: {},
        entregable_nuevo: {},
        entregable_nuevo_error: {},
        requisito_nuevo: {},
        requisito_nuevo_error: {},
        requisito_eliminar: {},
        requisito_eliminar_error: {},
        archivo_estado: {},
        archivo_estado_error: {},
        tarea_eliminar: {},
        tarea_eliminar_error: {},
        tarea_crear: {},
        tarea_crear_error: {},
        tarea_editar: {},
        tarea_editar_error: {},
        archivo_carga: {},
        archivo_carga_error: {},
        archivo_recurso: {},
        archivo_recurso_error: {},
        archivo_eliminar: {},
        archivo_eliminar_error: {},
        proyecto_crear: {},
        proyecto_crear_error: {},
        proyecto_eliminar: {},
        proyecto_eliminar_error: {},
        proyecto_editar: {},
        proyecto_editar_error: {},
        archivo_nuevo: {},
        archivo_nuevo_error: {},
        tarea_responsable_asignar: {},
        tarea_responsable_asignar_error: {},
        entregable_responsable_asignar: {},
        entregable_responsable_asignar_error: {},
        entregable_estado: {},
        entregable_estado_error: {},
        entregable_duplicar_error: {},
        entregable_duplicar: {},
        planaccion_nuevo: {},
        planaccion_nuevo_error: {},
        planaccion_eliminar: {},
        planaccion_eliminar_error: {},
        planaccion_estado: {},
        planaccion_estado_error: {},
      };
    default:
      return state;
  }
}
