import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VGC } from "../equipos/types";
import {
  TEAMS_LIMPIAR_PROCEDIMIENTOS_FILTROS,
  TEAMS_PROCEDIMIENTOS_FALLIDO,
  TEAMS_PROCEDIMIENTOS_FILTROS_FALLIDO,
  TEAMS_PROCEDIMIENTOS_FILTROS_INPUT,
  TEAMS_PROCEDIMIENTOS_FILTROS_OBTENER,
  TEAMS_PROCEDIMIENTOS_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerProcedimientos = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .get(`${url_api}/api/teams/procedimientos/${pagina}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: TEAMS_PROCEDIMIENTOS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_PROCEDIMIENTOS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerProcedimientosFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .post(
      `${url_api}/api/teams/procedimientos/${data.pagina}`,
      data.filtros,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: TEAMS_PROCEDIMIENTOS_FILTROS_INPUT,
        payload: data.filtros,
      });
      dispatch({
        type: TEAMS_PROCEDIMIENTOS_FILTROS_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_PROCEDIMIENTOS_FILTROS_FALLIDO,
        payload: error,
      });
    });
};

export const limpiarFiltroProcedimiento = () => (dispatch) => {
  dispatch({ type: TEAMS_LIMPIAR_PROCEDIMIENTOS_FILTROS });
};
