import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  inteligenciaSeleccionada,
  obtenerInteligencias,
} from "../../../redux/actions/permitting/action";
import { Footer, Navbar, SidebarInicio } from "../../general";

export class Proyectos extends Component {
  componentDidMount = () => {
    const { obtenerInteligencias } = this.props;
    obtenerInteligencias();
  };

  setInteligencia = (proyecto) => {
    const { inteligenciaSeleccionada } = this.props;
    inteligenciaSeleccionada({
      id: proyecto.id,
      is_pas: proyecto.is_pas,
    });
  };

  render = () => {
    const { inteligencias, is_loading_permitting } = this.props;

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Proyectos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Cargando proyectos, espere unos segundos",
        icon: "info",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="permitting_permisoteca" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fab fa-react"></i> GRUPOS DE PERMISOS{" "}
                    <small>({inteligencias ? inteligencias.length : 0})</small>
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              {inteligencias && inteligencias.length > 0 ? (
                inteligencias.map((inteligencia, x) => (
                  <div className="col-sm-12 col-lg-6" key={x}>
                    <div className="card card-eco">
                      <div className="card-header">
                        <h5>
                          <span className="badge badge-soft-dark">
                            ID: {x + 1}
                          </span>{" "}
                          {inteligencia.nombre}
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <tbody>
                              <tr>
                                <td className="table-secondary w-45 text-dark">
                                  Nombre de la empresa
                                </td>
                                <td>{inteligencia.empresa}</td>
                              </tr>
                              {inteligencia.tipo_display && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Tipo
                                  </td>
                                  <td>{inteligencia.tipo_display}</td>
                                </tr>
                              )}
                              {inteligencia.elemento_tipo &&
                                inteligencia.elemento_tipo.length > 0 && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Elemento consultado
                                    </td>
                                    <td>
                                      <ul className="ps-4 mb-0">
                                        {inteligencia.elemento_tipo.map(
                                          (elemento, x) => (
                                            <li key={x}>{elemento.nombre}</li>
                                          )
                                        )}
                                      </ul>
                                    </td>
                                  </tr>
                                )}
                              {inteligencia.elemento_nombre && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Nombre del elemento consultado
                                  </td>
                                  <td>{inteligencia.elemento_nombre}</td>
                                </tr>
                              )}
                              {inteligencia.titular && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Titular del elemento consultado
                                  </td>
                                  <td>{inteligencia.titular}</td>
                                </tr>
                              )}
                              {inteligencia.comuna_display &&
                                inteligencia.region_display && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Ubicación geográfica del elemento
                                      consultado
                                    </td>
                                    <td>
                                      {inteligencia.comuna_display} /{" "}
                                      {inteligencia.region_display}
                                    </td>
                                  </tr>
                                )}
                              {inteligencia.estado &&
                                inteligencia.estado.length > 0 && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Estado del elemento consultado
                                    </td>
                                    <td>
                                      <ul className="ps-4 mb-0">
                                        {inteligencia.estado.map(
                                          (estado, x) => (
                                            <li key={x}>{estado.nombre}</li>
                                          )
                                        )}
                                      </ul>
                                    </td>
                                  </tr>
                                )}
                              {inteligencia.tipologia && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Tipología del proyecto
                                  </td>
                                  <td>{inteligencia.tipologia.nombre}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="card-footer text-end">
                        <Link
                          data-tip
                          data-tooltip-id={`permiso_${inteligencia.id}`}
                          className="btn btn-primary"
                          onClick={this.setInteligencia.bind(
                            this,
                            inteligencia
                          )}
                          to="/permitting-target/permisoteca/permisos"
                        >
                          Ver permisos <i className="fa fa-angle-right"></i>
                        </Link>
                        <Tooltip
                          id={`permiso_${inteligencia.id}`}
                          aria-haspopup="true"
                        >
                          Ver permiso del proyecto
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body text-center">
                      <h5>
                        <i className="fa fa-exclamation-circle"></i> No tienes
                        proyectos asociados a tu cuenta
                      </h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Proyectos.propTypes = {
  obtenerInteligencias: PropTypes.func.isRequired,
  inteligenciaSeleccionada: PropTypes.func.isRequired,
  inteligencias: PropTypes.array.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  inteligencias: state.permitting.inteligencias,
  is_loading_permitting: state.permitting.is_loading_permitting,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerInteligencias,
      inteligenciaSeleccionada,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Proyectos);
