import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  INSTRUMENTO_INFRINGIDO_FALLIDO,
  INSTRUMENTO_INFRINGIDO_GUARDAR,
  INSTRUMENTO_INFRINGIDO_OBTENER,
  INSTRUMENTOS_INFRINGIDOS_FALLIDO,
  INSTRUMENTOS_INFRINGIDOS_FILTROS_FALLIDO,
  INSTRUMENTOS_INFRINGIDOS_FILTROS_INPUT,
  INSTRUMENTOS_INFRINGIDOS_FILTROS_OBTENER,
  INSTRUMENTOS_INFRINGIDOS_OBTENER,
  LIMPIAR_INSTRUMENTOS_INFRINGIDOS_FILTROS,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerInstrumentoInfringido = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/instrumento-infringido`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: INSTRUMENTO_INFRINGIDO_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : INSTRUMENTO_INFRINGIDO_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerInstrumentosInfringidos =
  (pagina) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .get(
        `${url_api}/api/vigilances/instrumentos-infringidos/${pagina}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({ type: INSTRUMENTOS_INFRINGIDOS_OBTENER, payload: res.data });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : INSTRUMENTOS_INFRINGIDOS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerInstrumentosInfringidosFiltros =
  (data) => (dispatch, getState) => {
    dispatch({ type: LIMPIAR_INSTRUMENTOS_INFRINGIDOS_FILTROS });
    axios
      .post(
        `${url_api}/api/vigilances/instrumentos-infringidos/filtros/${data.pagina}`,
        data.filtros,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: INSTRUMENTOS_INFRINGIDOS_FILTROS_INPUT,
          payload: data.filtros,
        });
        dispatch({
          type: INSTRUMENTOS_INFRINGIDOS_FILTROS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : INSTRUMENTOS_INFRINGIDOS_FILTROS_FALLIDO,
          payload: error,
        });
      });
  };

export const guardarInstrumentoInfringido = (id) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  dispatch({ type: INSTRUMENTO_INFRINGIDO_GUARDAR, payload: id });
};

export const limpiarFiltroInstrumentosFiscalizados = () => (dispatch) => {
  dispatch({ type: LIMPIAR_INSTRUMENTOS_INFRINGIDOS_FILTROS });
};
