import axios from 'axios';

import { tokenConfig } from '../../auth/action';
import { AUTENTIFICACION_FALLIDO } from '../../auth/types';
import { CARGANDO_LIMPIAR_VIGILANCIAS } from '../types';
import {
  LITE_OBTENER,
  LITE_FALLIDO,
  LITE_LIMPIAR,
} from './types';

var url_api = process.env.REACT_APP_API_URL;


export const obtenerLiteFiltros = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(`${url_api}/api/vigilances/lite/${data.pagina}`, data.filtros, tokenConfig(getState))
    .then(res => {
      dispatch({ type: LITE_OBTENER, payload: res.data });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : LITE_FALLIDO,
        payload: error
      });
    });
};

export const limpiarFiltroLite = () => (dispatch) => {
  dispatch({ type: LITE_LIMPIAR });
};
