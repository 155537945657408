import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerExpediente } from "../../../redux/actions/mma/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Tabs } from "./";

export class Detalle extends Component {
  componentDidMount = () => {
    const { obtenerExpediente, expediente_id } = this.props;
    if (expediente_id > 0) {
      obtenerExpediente({
        id: expediente_id,
      });
    }
  };

  render = () => {
    const { is_loading_mma, expediente_id, expediente } = this.props;

    if (expediente_id === 0) {
      return <Navigate to="/legal-tracking/mma/expedientes" />;
    }

    if (is_loading_mma) {
      SweetAlert.fire({
        title: "Expedientes MMA",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo el expediente, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="mma_expedientes" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-envelope-open-text"></i> EXPEDIENTES MMA
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">{expediente.nombre}</h5>
                    <div className="float-end btn-group">
                      <Link
                        className="btn btn-primary"
                        to="/legal-tracking/mma/expedientes"
                      >
                        <i className="fa fa-angle-left"></i> Volver al listado
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <Tabs expedientes={[expediente]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerExpediente: PropTypes.func.isRequired,
  is_loading_mma: PropTypes.bool.isRequired,
  expediente: PropTypes.object.isRequired,
  expediente_id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_mma: state.mma.is_loading_mma,
  expediente: state.mma.expediente,
  expediente_id: state.mma.expediente_id,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerExpediente }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
