export const MENSAJES_LIMPIAR = "MENSAJES_LIMPIAR";
export const EXPEDIENTES_MMA_OBTENER = "EXPEDIENTES_MMA_OBTENER";
export const EXPEDIENTES_MMA_FALLIDO = "EXPEDIENTES_MMA_FALLIDO";
export const EXPEDIENTE_MMA_OBTENER = "EXPEDIENTE_MMA_OBTENER";
export const EXPEDIENTE_MMA_FALLIDO = "EXPEDIENTE_MMA_FALLIDO";
export const CARGANDO_LIMPIAR_MMA = "CARGANDO_LIMPIAR_MMA";
export const EXPEDIENTE_MMA_GUARDAR = "EXPEDIENTE_MMA_GUARDAR";
export const EXPEDIENTES_MMA_FAVORITO_ELIMINAR_OBTENER =
  "EXPEDIENTES_MMA_FAVORITO_ELIMINAR_OBTENER";
export const EXPEDIENTES_MMA_FAVORITO_ELIMINAR_FALLIDO =
  "EXPEDIENTES_MMA_FAVORITO_ELIMINAR_FALLIDO";
export const EXPEDIENTES_MMA_FILTROS_INPUT = "EXPEDIENTES_MMA_FILTROS_INPUT";
export const LIMPIAR_EXPEDIENTES_MMA_FILTROS =
  "LIMPIAR_EXPEDIENTES_MMA_FILTROS";
export const EXPEDIENTES_MMA_FILTROS_OBTENER =
  "EXPEDIENTES_MMA_FILTROS_OBTENER";
export const EXPEDIENTES_MMA_FILTROS_FALLIDO =
  "EXPEDIENTES_MMA_FILTROS_FALLIDO";
export const EXPEDIENTES_MMA_FAVORITO_OBTENER =
  "EXPEDIENTES_MMA_FAVORITO_OBTENER";
export const EXPEDIENTES_MMA_FAVORITO_FALLIDO =
  "EXPEDIENTES_MMA_FAVORITO_FALLIDO";
