import { AUTENTIFICACION_FALLIDO } from "../actions/auth/types";
import {
  CARGANDO_LIMPIAR_ESTADISTICAS,
  ESTADISTICAS_FALLIDO,
  ESTADISTICAS_OBTENER,
} from "../actions/usuario/estadisticas/types";
import {
  CARGANDO_LIMPIAR_NOTIFICACIONES,
  NOTIFICACIONES_FALLIDO,
  NOTIFICACIONES_OBTENER,
} from "../actions/usuario/notificaciones/types";
import {
  ALERTAS_FALLIDO,
  ALERTAS_OBTENER,
  ALERTA_CREAR_FALLIDO,
  ALERTA_CREAR_OBTENER,
  ALERTA_ELIMINAR_FALLIDO,
  ALERTA_ELIMINAR_OBTENER,
  MENSAJES_LIMPIAR_ALERTAS,
} from "../actions/vigilancias/alertas/types";
import {
  DOCUMENTOS_FALLIDO,
  DOCUMENTOS_FILTROS_FALLIDO,
  DOCUMENTOS_FILTROS_INPUT,
  DOCUMENTOS_FILTROS_OBTENER,
  DOCUMENTOS_OBTENER,
  DOCUMENTO_FALLIDO,
  DOCUMENTO_GUARDAR,
  DOCUMENTO_OBTENER,
  LIMPIAR_DOCUMENTOS_FILTROS,
} from "../actions/vigilancias/documentos/types";
import {
  ENCARGADOS_FALLIDO,
  ENCARGADOS_FILTRO_FALLIDO,
  ENCARGADOS_FILTRO_OBTENER,
  ENCARGADOS_OBTENER,
  ENCARGADO_FALLIDO,
  ENCARGADO_GUARDAR,
  ENCARGADO_OBTENER,
} from "../actions/vigilancias/encargados/types";
import {
  EXPEDIENTES_DOCUMENTOS_FALLIDO,
  EXPEDIENTES_DOCUMENTOS_FILTROS_INPUT,
  EXPEDIENTES_DOCUMENTOS_OBTENER,
  EXPEDIENTES_ESTADISTICAS_FALLIDO,
  EXPEDIENTES_ESTADISTICAS_GENERADORA_FALLIDO,
  EXPEDIENTES_ESTADISTICAS_GENERADORA_OBTENER,
  EXPEDIENTES_ESTADISTICAS_OBTENER,
  EXPEDIENTES_FALLIDO,
  EXPEDIENTES_FILTROS_FALLIDO,
  EXPEDIENTES_FILTROS_INPUT,
  EXPEDIENTES_FILTROS_OBTENER,
  EXPEDIENTES_MAPA_FALLIDO,
  EXPEDIENTES_MAPA_FILTROS_INPUT,
  EXPEDIENTES_MAPA_OBTENER,
  EXPEDIENTES_OBTENER,
  EXPEDIENTE_FALLIDO,
  EXPEDIENTE_GUARDAR,
  EXPEDIENTE_OBTENER,
  FILTROS_EXPEDIENTES_DOCUMENTOS_FALLIDO,
  FILTROS_EXPEDIENTES_DOCUMENTOS_OBTENER,
  FILTROS_EXPEDIENTES_MAPA_FALLIDO,
  FILTROS_EXPEDIENTES_MAPA_OBTENER,
  LIMPIAR_EXPEDIENTES_DOCUMENTOS_FILTROS,
  LIMPIAR_EXPEDIENTES_FILTROS,
  LIMPIAR_EXPEDIENTES_MAPA_FILTROS,
} from "../actions/vigilancias/expedientes/types";
import {
  FAVORITA_ELIMINAR_FALLIDO,
  FAVORITA_ELIMINAR_OBTENER,
  FAVORITOS_VIGILANCIA_FALLIDO,
  FAVORITOS_VIGILANCIA_OBTENER,
  FAVORITO_GUARDAR_FALLIDO,
  FAVORITO_GUARDAR_OBTENER,
} from "../actions/vigilancias/favoritos/types";
import {
  FISCALIZACIONES_ESTADISTICAS_FALLIDO,
  FISCALIZACIONES_ESTADISTICAS_OBTENER,
  FISCALIZACIONES_FALLIDO,
  FISCALIZACIONES_FILTROS_FALLIDO,
  FISCALIZACIONES_FILTROS_OBTENER,
  FISCALIZACIONES_OBTENER,
  FISCALIZACION_FALLIDO,
  FISCALIZACION_FILTROS_INPUT,
  FISCALIZACION_GUARDAR,
  FISCALIZACION_OBTENER,
  LIMPIAR_FISCALIZACIONES_FILTROS,
} from "../actions/vigilancias/fiscalizaciones/types";
import {
  HECHOS_ESTADISTICAS_FALLIDO,
  HECHOS_ESTADISTICAS_OBTENER,
  HECHOS_FALLIDO,
  HECHOS_FILTROS_FALLIDO,
  HECHOS_FILTROS_INPUT,
  HECHOS_FILTROS_OBTENER,
  HECHOS_OBTENER,
  HECHO_FALLIDO,
  HECHO_GUARDAR,
  HECHO_OBTENER,
  LIMPIAR_HECHOS_FILTROS,
} from "../actions/vigilancias/hechos/types";
import {
  INSTRUMENTOS_INFRINGIDOS_FALLIDO,
  INSTRUMENTOS_INFRINGIDOS_FILTROS_FALLIDO,
  INSTRUMENTOS_INFRINGIDOS_FILTROS_INPUT,
  INSTRUMENTOS_INFRINGIDOS_FILTROS_OBTENER,
  INSTRUMENTOS_INFRINGIDOS_OBTENER,
  INSTRUMENTO_INFRINGIDO_FALLIDO,
  INSTRUMENTO_INFRINGIDO_GUARDAR,
  INSTRUMENTO_INFRINGIDO_OBTENER,
  LIMPIAR_INSTRUMENTOS_INFRINGIDOS_FILTROS,
} from "../actions/vigilancias/instrumentos_infringidos/types";
import {
  LITE_FALLIDO,
  LITE_LIMPIAR,
  LITE_OBTENER,
} from "../actions/vigilancias/lite/types";
import {
  LIMPIAR_MEDIDAS_FILTROS,
  MEDIDAS_PROVISIONALES_ESTADISTICAS_FALLIDO,
  MEDIDAS_PROVISIONALES_ESTADISTICAS_OBTENER,
  MEDIDAS_PROVISIONALES_FALLIDO,
  MEDIDAS_PROVISIONALES_FILTROS_FALLIDO,
  MEDIDAS_PROVISIONALES_FILTROS_INPUT,
  MEDIDAS_PROVISIONALES_FILTROS_OBTENER,
  MEDIDAS_PROVISIONALES_OBTENER,
  MEDIDA_PROVISIONAL_FALLIDO,
  MEDIDA_PROVISIONAL_GUARDAR,
  MEDIDA_PROVISIONAL_OBTENER,
} from "../actions/vigilancias/medidas/types";
import {
  LIMPIAR_PERTINENCIAS_FILTROS,
  PERTINENCIAS_ESTADISTICAS_FALLIDO,
  PERTINENCIAS_ESTADISTICAS_OBTENER,
  PERTINENCIAS_FALLIDO,
  PERTINENCIAS_FILTROS_FALLIDO,
  PERTINENCIAS_FILTROS_INPUT,
  PERTINENCIAS_FILTROS_OBTENER,
  PERTINENCIAS_OBTENER,
  PERTINENCIA_FALLIDO,
  PERTINENCIA_GUARDAR,
  PERTINENCIA_OBTENER,
} from "../actions/vigilancias/pertinencia/types";
import {
  LIMPIAR_PROCEDIMIENTO_FILTROS,
  PROCEDIMIENTOS_ESTADISTICAS_FALLIDO,
  PROCEDIMIENTOS_ESTADISTICAS_OBTENER,
  PROCEDIMIENTOS_FALLIDO,
  PROCEDIMIENTOS_FILTROS_FALLIDO,
  PROCEDIMIENTOS_FILTROS_INPUT,
  PROCEDIMIENTOS_FILTROS_OBTENER,
  PROCEDIMIENTOS_OBTENER,
  PROCEDIMIENTO_FALLIDO,
  PROCEDIMIENTO_GUARDAR,
  PROCEDIMIENTO_OBTENER,
} from "../actions/vigilancias/procedimientos/types";
import {
  LIMPIAR_PROGRAMA_FILTROS,
  PROGRAMAS_ESTADISTICAS_FALLIDO,
  PROGRAMAS_ESTADISTICAS_OBTENER,
  PROGRAMAS_FALLIDO,
  PROGRAMAS_FILTROS_FALLIDO,
  PROGRAMAS_FILTROS_INPUT,
  PROGRAMAS_FILTROS_OBTENER,
  PROGRAMAS_OBTENER,
  PROGRAMA_FALLIDO,
  PROGRAMA_GUARDAR,
  PROGRAMA_OBTENER,
} from "../actions/vigilancias/programas/types";
import {
  QUINENCO_DASHBOARD_FALLIDO,
  QUINENCO_DASHBOARD_OBTENER,
  QUINENCO_EXPEDIENTES_FALLIDO,
  QUINENCO_EXPEDIENTES_FILTROS_FALLIDO,
  QUINENCO_EXPEDIENTES_FILTROS_INPUT,
  QUINENCO_EXPEDIENTES_FILTROS_OBTENER,
  QUINENCO_EXPEDIENTES_OBTENER,
  QUINENCO_LIMPIAR_EXPEDIENTES_FILTROS,
  QUINENCO_LIMPIAR_PERTINENCIAS_FILTROS,
  QUINENCO_LIMPIAR_UNIDADES_FILTROS,
  QUINENCO_PERTINENCIAS_FALLIDO,
  QUINENCO_PERTINENCIAS_FILTROS_FALLIDO,
  QUINENCO_PERTINENCIAS_FILTROS_INPUT,
  QUINENCO_PERTINENCIAS_FILTROS_OBTENER,
  QUINENCO_PERTINENCIAS_OBTENER,
  QUINENCO_UNIDADES_FALLIDO,
  QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_FALLIDO,
  QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_INPUT,
  QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_OBTENER,
  QUINENCO_UNIDADES_OBTENER,
} from "../actions/vigilancias/quinenco/types";
import {
  LIMPIAR_RCAS_FILTROS,
  RCAS_ESTADISTICAS_FALLIDO,
  RCAS_ESTADISTICAS_OBTENER,
  RCAS_FALLIDO,
  RCAS_FILTROS_FALLIDO,
  RCAS_FILTROS_INPUT,
  RCAS_FILTROS_OBTENER,
  RCAS_OBTENER,
  RCA_FALLIDO,
  RCA_GUARDAR,
  RCA_OBTENER,
} from "../actions/vigilancias/rcas/types";
import {
  LIMPIAR_REQUERIMIENTOS_INGRESO_FILTROS,
  REQUERIMIENTOS_INGRESOS_FALLIDO,
  REQUERIMIENTOS_INGRESOS_FILTROS_FALLIDO,
  REQUERIMIENTOS_INGRESOS_FILTROS_INPUT,
  REQUERIMIENTOS_INGRESOS_FILTROS_OBTENER,
  REQUERIMIENTOS_INGRESOS_OBTENER,
  REQUERIMIENTO_INGRESO_FALLIDO,
  REQUERIMIENTO_INGRESO_GUARDAR,
  REQUERIMIENTO_INGRESO_OBTENER,
} from "../actions/vigilancias/requerimientos/types";
import {
  SANCIONES_BUSQUEDA_FALLIDO,
  SANCIONES_BUSQUEDA_OBTENER,
  SANCIONES_ESTADISTICAS_FALLIDO,
  SANCIONES_ESTADISTICAS_OBTENER,
  SANCIONES_FALLIDO,
  SANCIONES_GUARDAR,
  SANCIONES_OBTENER,
} from "../actions/vigilancias/sanciones/types";
import {
  LIMPIAR_SEGUIMIENTOS_FILTROS,
  SEGUIMIENTOS_FALLIDO,
  SEGUIMIENTOS_FILTROS_FALLIDO,
  SEGUIMIENTOS_FILTROS_INPUT,
  SEGUIMIENTOS_FILTROS_OBTENER,
  SEGUIMIENTOS_OBTENER,
  SEGUIMIENTO_FALLIDO,
  SEGUIMIENTO_GUARDAR,
  SEGUIMIENTO_OBTENER,
} from "../actions/vigilancias/seguimientos/types";
import {
  TITULARES_FALLIDO,
  TITULARES_FILTRO_FALLIDO,
  TITULARES_FILTRO_OBTENER,
  TITULARES_OBTENER,
  TITULAR_FALLIDO,
  TITULAR_GUARDAR,
  TITULAR_OBTENER,
} from "../actions/vigilancias/titulares/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../actions/vigilancias/types";
import {
  LIMPIAR_UNIDADES_FILTROS,
  UNIDADES_FISCALIZABLES_ESTADISTICAS_FALLIDO,
  UNIDADES_FISCALIZABLES_ESTADISTICAS_OBTENER,
  UNIDADES_FISCALIZABLES_FALLIDO,
  UNIDADES_FISCALIZABLES_FILTROS_FALLIDO,
  UNIDADES_FISCALIZABLES_FILTROS_INPUT,
  UNIDADES_FISCALIZABLES_FILTROS_OBTENER,
  UNIDADES_FISCALIZABLES_OBTENER,
  UNIDAD_FISCALIZABLE_FALLIDO,
  UNIDAD_FISCALIZABLE_GUARDAR,
  UNIDAD_FISCALIZABLE_OBTENER,
} from "../actions/vigilancias/unidades_fiscalizables/types";

const initialState = {
  is_loading_vigilancias: true,
  estadisticas: {},
  notificaciones: [],
  // alertas
  alertas: {},
  alertas_filtros: {},
  alerta_crear: {},
  alerta_crear_error: {},
  alerta_eliminar: {},
  alerta_eliminar_error: {},
  // rca
  rcas_estadisticas: {},
  rcas: {},
  rca_id: 0,
  rcas_filtros: {},
  rcas_filtro_input: {},
  rca: {},
  // medidas provisionales
  medidas_provisionales: {},
  medidas_provisionales_estadisticas: {},
  medida_provisional_id: 0,
  medida_provisional_componente: 0,
  medida_provisional: {},
  medidas_provisionales_filtros: {},
  medidas_provisionales_filtro_input: {},
  // expedientes
  expedientes: {},
  expedientes_estadisticas: {},
  expedientes_estadisticas_generadoras: {},
  expediente: {},
  expedientes_filtros: {},
  expedientes_documentos_filtros: {},
  expedientes_filtro_input: {},
  expediente_id: 0,
  expediente_componente: "",
  expedientes_mapa: [],
  expedientes_mapa_filtro_input: {},
  expedientes_documentos: {},
  expedientes_documentos_filtro_input: {},
  // seguimientos
  seguimiento_componente: "",
  seguimiento_id: 0,
  seguimientos: {},
  seguimiento: {},
  seguimientos_filtros: {},
  seguimientos_filtro_input: {},
  // procedimientos
  procedimientos_filtros: {},
  procedimientos_filtro_input: {},
  procedimientos: {},
  procedimientos_estadisticas: {},
  procedimiento: {},
  procedimiento_id: 0,
  procedimiento_componente: "",
  // pertinencias
  pertinencias: {},
  pertinencias_estadisticas: {},
  pertinencias_filtro_input: {},
  pertinencia_id: 0,
  pertinencia_componente: "",
  pertinencia: {},
  pertinencias_filtros: {},
  // titular
  titulares: {},
  titular: {},
  titular_id: 0,
  // programas de cumplimiento
  programas_filtro_input: {},
  programas_filtros: {},
  programas: {},
  programas_estadisticas: {},
  programa_id: 0,
  programa: {},
  // instrumentos infringidos
  instrumentos_infringidos: {},
  instrumentos_infringidos_filtros: {},
  instrumentos_infringidos_filtro_input: {},
  instrumento_infringido_id: 0,
  instrumento_infringido: {},
  // hechos
  hechos: {},
  hechos_estadisticas: {},
  hechos_filtros: {},
  hechos_filtro_input: {},
  hecho: {},
  hecho_id: 0,
  // unidades fiscalizables
  unidades: {},
  unidades_estadisticas: {},
  unidades_filtros: {},
  unidades_filtro_input: {},
  unidad_fiscalizable_id: 0,
  unidad_fiscalizable_componente: "",
  unidad_fiscalizable: {},
  // encargados
  encargados: {},
  encargado: {},
  encargado_id: 0,
  // filtros
  filtros: {},
  filtros_errores: {},
  filtros_expedientes: {},
  filtros_expedientes_mapa: {},
  filtros_expedientes_documentos: {},
  filtros_pertinencias: {},
  filtros_procedimientos: {},
  filtros_unidades: {},
  filtros_hechos: {},
  filtros_programas: {},
  filtros_instrumentos_infringidos: {},
  filtros_fiscalizaciones: {},
  filtros_rcas: {},
  filtros_seguimientos: {},
  filtros_medidas: {},
  filtros_sanciones: {},
  filtros_documentos: {},
  filtros_requerimientos: {},
  // favoritos
  favoritos_vigilancia: {},
  favorito_guardado: {},
  favorito_eliminar: {},
  // sanciones
  sanciones: {},
  sanciones_estadisticas: {},
  sancion_id: 0,
  // fiscalizaciones
  fiscalizaciones: {},
  fiscalizaciones_estadisticas: {},
  fiscalizacion: {},
  fiscalizacion_id: 0,
  fiscalizaciones_filtros: {},
  fiscalizaciones_filtro_input: {},
  // documentos
  documentos: {},
  documento: {},
  documento_id: 0,
  documentos_filtros: {},
  documentos_filtro_input: {},
  // requerimientos
  requerimientos: {},
  requerimiento_id: 0,
  requerimiento_componente: "",
  requerimientos_filtro_input: {},
  requerimientos_filtros: {},
  requerimiento: {},
  // lite
  lite_filtros: {},
  // quinenco
  quinenco_unidades: {},
  quinenco_unidades_filtros: {},
  quinenco_unidades_filtro_input: {},
  quinenco_expedientes: {},
  quinenco_expedientes_filtros: {},
  quinenco_expedientes_filtro_input: {},
  quinenco_pertinencias: {},
  quinenco_pertinencias_filtros: {},
  quinenco_pertinencias_filtro_input: {},
  quinenco_dashboard: {},
};

export default function vigilancias(state = initialState, action) {
  switch (action.type) {
    case AUTENTIFICACION_FALLIDO:
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
        is_authenticated: false,
        is_loading_vigilancias: false,
        errores: action.payload,
      };
    case ALERTA_CREAR_FALLIDO:
      return {
        ...state,
        alerta_crear: {},
        alerta_crear_error: action.payload,
      };
    case ALERTA_CREAR_OBTENER:
      return {
        ...state,
        alerta_crear: action.payload,
        alerta_crear_error: {},
      };
    case ALERTA_ELIMINAR_FALLIDO:
      return {
        ...state,
        alerta_eliminar: {},
        alerta_eliminar_error: action.payload,
      };
    case ALERTA_ELIMINAR_OBTENER:
      return {
        ...state,
        alerta_eliminar: action.payload,
        alerta_eliminar_error: {},
      };
    case MENSAJES_LIMPIAR_ALERTAS:
      return {
        ...state,
        alerta_crear: {},
        alerta_crear_error: {},
        alerta_eliminar: {},
        alerta_eliminar_error: {},
      };
    case ALERTAS_OBTENER:
      return {
        ...state,
        alertas: action.payload.alertas,
        alertas_filtros: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case ALERTAS_FALLIDO:
      return {
        ...state,
        alertas: {},
        alertas_filtros: {},
        is_loading_vigilancias: false,
      };
    case QUINENCO_UNIDADES_OBTENER:
      return {
        ...state,
        quinenco_unidades: action.payload.unidades,
        quinenco_unidades_filtros: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case QUINENCO_UNIDADES_FALLIDO:
      return {
        ...state,
        quinenco_unidades: {},
        quinenco_unidades_filtros: {},
        is_loading_vigilancias: false,
      };
    case QUINENCO_LIMPIAR_UNIDADES_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        quinenco_unidades_filtro_input: {},
        quinenco_unidades: {},
      };
    case QUINENCO_LIMPIAR_EXPEDIENTES_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        quinenco_expedientes_filtro_input: {},
        quinenco_expedientes: {},
      };
    case QUINENCO_LIMPIAR_PERTINENCIAS_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        quinenco_pertinencias_filtro_input: {},
        quinenco_pertinencias: {},
      };
    case QUINENCO_PERTINENCIAS_FILTROS_INPUT:
      return {
        ...state,
        quinenco_pertinencias_filtro_input: action.payload,
      };
    case QUINENCO_PERTINENCIAS_FILTROS_OBTENER:
      return {
        ...state,
        quinenco_pertinencias: action.payload.pertinencias,
        quinenco_filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case QUINENCO_PERTINENCIAS_FILTROS_FALLIDO:
      return {
        ...state,
        quinenco_expedientes_filtros: {},
        quinenco_filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_INPUT:
      return {
        ...state,
        quinenco_unidades_filtro_input: action.payload,
      };
    case QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_OBTENER:
      return {
        ...state,
        quinenco_unidades: action.payload.unidades,
        quinenco_filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_FALLIDO:
      return {
        ...state,
        quinenco_unidades_filtros: {},
        quinenco_filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case QUINENCO_EXPEDIENTES_FILTROS_INPUT:
      return {
        ...state,
        quinenco_expedientes_filtro_input: action.payload,
      };
    case QUINENCO_EXPEDIENTES_FILTROS_OBTENER:
      return {
        ...state,
        quinenco_expedientes: action.payload.expedientes,
        quinenco_filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case QUINENCO_EXPEDIENTES_FILTROS_FALLIDO:
      return {
        ...state,
        quinenco_expedientes_filtros: {},
        quinenco_filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case QUINENCO_PERTINENCIAS_OBTENER:
      return {
        ...state,
        quinenco_pertinencias: action.payload.pertinencias,
        quinenco_pertinencias_filtros: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case QUINENCO_PERTINENCIAS_FALLIDO:
      return {
        ...state,
        quinenco_pertinencias: {},
        is_loading_vigilancias: false,
      };
    case QUINENCO_DASHBOARD_OBTENER:
      return {
        ...state,
        quinenco_dashboard: action.payload.dashboard,
        is_loading_vigilancias: false,
      };
    case QUINENCO_DASHBOARD_FALLIDO:
      return {
        ...state,
        quinenco_dashboard: {},
        is_loading_vigilancias: false,
      };
    case QUINENCO_EXPEDIENTES_OBTENER:
      return {
        ...state,
        quinenco_expedientes: action.payload.expedientes,
        quinenco_expedientes_filtros: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case QUINENCO_EXPEDIENTES_FALLIDO:
      return {
        ...state,
        quinenco_expedientes: {},
        is_loading_vigilancias: false,
      };
    case LITE_OBTENER:
      return {
        ...state,
        lite_filtros: action.payload.hechos,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case LITE_FALLIDO:
    case LITE_LIMPIAR:
      return {
        ...state,
        lite_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_MAPA_OBTENER:
      return {
        ...state,
        expedientes_mapa: action.payload,
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_MAPA_FALLIDO:
      return {
        ...state,
        expedientes_mapa: [],
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_DOCUMENTOS_OBTENER:
      return {
        ...state,
        expedientes_documentos: action.payload,
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_DOCUMENTOS_FALLIDO:
      return {
        ...state,
        expedientes_documentos: {},
        is_loading_vigilancias: false,
      };
    case RCA_OBTENER:
      return {
        ...state,
        rca: action.payload,
        is_loading_vigilancias: false,
      };
    case RCA_FALLIDO:
      return {
        ...state,
        rca: {},
        is_loading_vigilancias: false,
      };
    case RCA_GUARDAR:
      return {
        ...state,
        rca_id: action.payload,
      };
    case RCAS_ESTADISTICAS_OBTENER:
      return {
        ...state,
        rcas_estadisticas: action.payload.rcas,
        is_loading_vigilancias: false,
      };
    case RCAS_ESTADISTICAS_FALLIDO:
      return {
        ...state,
        rcas_estadisticas: {},
        is_loading_vigilancias: false,
      };
    case RCAS_OBTENER:
      return {
        ...state,
        rcas: action.payload.rcas,
        rca_id: 0,
        filtros_rcas: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case RCAS_FALLIDO:
      return {
        ...state,
        rcas: {},
        rca_id: 0,
        filtros_rcas: {},
        is_loading_vigilancias: false,
      };
    case RCAS_FILTROS_OBTENER:
      return {
        ...state,
        rcas_filtros: action.payload.rcas,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case RCAS_FILTROS_FALLIDO:
      return {
        ...state,
        rcas_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case RCAS_FILTROS_INPUT:
      return {
        ...state,
        rcas_filtro_input: action.payload,
      };
    case LIMPIAR_RCAS_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        rcas_filtros: {},
        rcas_filtro_input: {},
        rcas: {},
      };
    case REQUERIMIENTOS_INGRESOS_OBTENER:
      return {
        ...state,
        requerimientos: action.payload.requerimientos,
        requerimiento_id: 0,
        filtros_requerimientos: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case REQUERIMIENTOS_INGRESOS_FALLIDO:
      return {
        ...state,
        requerimientos: {},
        documento_id: 0,
        filtros_requerimientos: {},
        is_loading_vigilancias: false,
      };
    case REQUERIMIENTOS_INGRESOS_FILTROS_OBTENER:
      return {
        ...state,
        requerimientos_filtros: action.payload.requerimientos,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case REQUERIMIENTOS_INGRESOS_FILTROS_FALLIDO:
      return {
        ...state,
        requerimientos_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case REQUERIMIENTOS_INGRESOS_FILTROS_INPUT:
      return {
        ...state,
        requerimientos_filtro_input: action.payload,
      };
    case EXPEDIENTES_FILTROS_INPUT:
      return {
        ...state,
        expedientes_filtro_input: action.payload,
      };
    case PROGRAMAS_FILTROS_OBTENER:
      return {
        ...state,
        programas_filtros: action.payload.programas,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case PROGRAMAS_FILTROS_FALLIDO:
      return {
        ...state,
        programas_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case PROGRAMAS_FILTROS_INPUT:
      return {
        ...state,
        programas_filtro_input: action.payload,
      };
    case PROCEDIMIENTOS_FILTROS_INPUT:
      return {
        ...state,
        procedimientos_filtro_input: action.payload,
      };
    case MEDIDAS_PROVISIONALES_FILTROS_INPUT:
      return {
        ...state,
        medidas_provisionales_filtro_input: action.payload,
      };
    case EXPEDIENTES_MAPA_FILTROS_INPUT:
      return {
        ...state,
        expedientes_mapa_filtro_input: action.payload,
      };
    case EXPEDIENTES_DOCUMENTOS_FILTROS_INPUT:
      return {
        ...state,
        expedientes_documentos_filtro_input: action.payload,
      };
    case REQUERIMIENTO_INGRESO_GUARDAR:
      return {
        ...state,
        requerimiento_id: action.payload.id,
        requerimiento_componente: action.payload.componente,
      };
    case REQUERIMIENTO_INGRESO_OBTENER:
      return {
        ...state,
        requerimiento: action.payload,
        is_loading_vigilancias: false,
      };
    case REQUERIMIENTO_INGRESO_FALLIDO:
      return {
        ...state,
        requerimiento: {},
        is_loading_vigilancias: false,
      };
    case ESTADISTICAS_OBTENER:
      return {
        ...state,
        estadisticas: action.payload,
        is_loading_vigilancias: false,
      };
    case CARGANDO_LIMPIAR_ESTADISTICAS:
      return {
        ...state,
        is_loading_vigilancias: true,
      };
    case ESTADISTICAS_FALLIDO:
      return {
        ...state,
        estadisticas: {},
        is_loading_vigilancias: false,
      };
    case NOTIFICACIONES_OBTENER:
      return {
        ...state,
        notificaciones: action.payload,
        is_loading_vigilancias: false,
      };
    case CARGANDO_LIMPIAR_NOTIFICACIONES:
      return {
        ...state,
        is_loading_vigilancias: true,
      };
    case NOTIFICACIONES_FALLIDO:
      return {
        ...state,
        notificaciones: [],
        is_loading_vigilancias: false,
      };
    case MEDIDAS_PROVISIONALES_FILTROS_FALLIDO:
      return {
        ...state,
        medidas_provisionales_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case MEDIDAS_PROVISIONALES_FILTROS_OBTENER:
      return {
        ...state,
        medidas_provisionales_filtros: action.payload.medidas,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case MEDIDA_PROVISIONAL_FALLIDO:
      return {
        ...state,
        medida_provisional: {},
        is_loading_vigilancias: false,
      };
    case MEDIDA_PROVISIONAL_OBTENER:
      return {
        ...state,
        medida_provisional: action.payload,
        is_loading_vigilancias: false,
      };
    case MEDIDAS_PROVISIONALES_ESTADISTICAS_FALLIDO:
      return {
        ...state,
        medidas_provisionales_estadisticas: {},
        is_loading_vigilancias: false,
      };
    case MEDIDAS_PROVISIONALES_ESTADISTICAS_OBTENER:
      return {
        ...state,
        medidas_provisionales_estadisticas: action.payload.medidas,
        is_loading_vigilancias: false,
      };
    case MEDIDAS_PROVISIONALES_FALLIDO:
      return {
        ...state,
        medidas_provisionales: {},
        filtros_medidas: {},
        is_loading_vigilancias: false,
      };
    case MEDIDAS_PROVISIONALES_OBTENER:
      return {
        ...state,
        medidas_provisionales: action.payload.medidas,
        filtros_medidas: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case SANCIONES_BUSQUEDA_OBTENER:
      return {
        ...state,
        sanciones: action.payload,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case SANCIONES_BUSQUEDA_FALLIDO:
      return {
        ...state,
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case SANCIONES_FALLIDO:
      return {
        ...state,
        is_loading_vigilancias: false,
        sanciones: {},
        sancion_id: 0,
        filtros_sanciones: {},
      };
    case SANCIONES_OBTENER:
      return {
        ...state,
        is_loading_vigilancias: false,
        sanciones: action.payload.sanciones,
        filtros_sanciones: action.payload.filtros,
        sancion_id: 0,
      };
    case SANCIONES_ESTADISTICAS_FALLIDO:
      return {
        ...state,
        sanciones_estadisticas: {},
        is_loading_vigilancias: false,
      };
    case SANCIONES_ESTADISTICAS_OBTENER:
      return {
        ...state,
        sanciones_estadisticas: action.payload.sanciones,
        is_loading_vigilancias: false,
      };
    case FAVORITOS_VIGILANCIA_OBTENER:
      return {
        ...state,
        is_loading_vigilancias: false,
        favoritos_vigilancia: action.payload,
      };
    case FAVORITOS_VIGILANCIA_FALLIDO:
      return {
        ...state,
        is_loading_vigilancias: false,
        favoritos_vigilancia: {},
      };
    case FAVORITO_GUARDAR_FALLIDO:
      return {
        ...state,
        is_loading_vigilancias: false,
        favorito_guardado: action.payload,
      };
    case FAVORITO_GUARDAR_OBTENER:
      return {
        ...state,
        is_loading_vigilancias: false,
        favorito_guardado: action.payload,
      };
    case FAVORITA_ELIMINAR_FALLIDO:
      return {
        ...state,
        is_loading_vigilancias: false,
        favorito_eliminar: action.payload,
      };
    case FAVORITA_ELIMINAR_OBTENER:
      return {
        ...state,
        is_loading_vigilancias: false,
        favorito_eliminar: action.payload,
      };
    case DOCUMENTO_GUARDAR:
      return {
        ...state,
        documento_id: action.payload,
      };
    case EXPEDIENTE_FALLIDO:
      return {
        ...state,
        expediente: {},
        is_loading_vigilancias: false,
      };
    case EXPEDIENTE_OBTENER:
      return {
        ...state,
        expediente: action.payload,
        is_loading_vigilancias: false,
      };
    case PERTINENCIA_GUARDAR:
      return {
        ...state,
        pertinencia_id: action.payload.id,
        pertinencia_componente: action.payload.componente,
      };
    case PERTINENCIA_OBTENER:
      return {
        ...state,
        pertinencia: action.payload,
        is_loading_vigilancias: false,
      };
    case PERTINENCIA_FALLIDO:
      return {
        ...state,
        pertinencia: {},
        is_loading_vigilancias: false,
      };
    case INSTRUMENTO_INFRINGIDO_OBTENER:
      return {
        ...state,
        instrumento_infringido: action.payload,
        is_loading_vigilancias: false,
      };
    case INSTRUMENTO_INFRINGIDO_FALLIDO:
      return {
        ...state,
        instrumento_infringido: {},
        is_loading_vigilancias: false,
      };
    case DOCUMENTO_OBTENER:
      return {
        ...state,
        documento: action.payload,
        is_loading_vigilancias: false,
      };
    case DOCUMENTO_FALLIDO:
      return {
        ...state,
        documento: {},
        is_loading_vigilancias: false,
      };
    case HECHO_OBTENER:
      return {
        ...state,
        hecho: action.payload,
        is_loading_vigilancias: false,
      };
    case FISCALIZACION_OBTENER:
      return {
        ...state,
        fiscalizacion: action.payload,
        is_loading_vigilancias: false,
      };
    case FISCALIZACION_FALLIDO:
      return {
        ...state,
        fiscalizacion: {},
        is_loading_vigilancias: false,
      };
    case HECHO_FALLIDO:
      return {
        ...state,
        hecho: {},
        is_loading_vigilancias: false,
      };
    case UNIDAD_FISCALIZABLE_OBTENER:
      return {
        ...state,
        unidad_fiscalizable: action.payload,
        is_loading_vigilancias: false,
      };
    case PROCEDIMIENTO_OBTENER:
      return {
        ...state,
        procedimiento: action.payload,
        is_loading_vigilancias: false,
      };
    case PROCEDIMIENTO_FALLIDO:
      return {
        ...state,
        procedimiento: {},
        is_loading_vigilancias: false,
      };
    case UNIDAD_FISCALIZABLE_FALLIDO:
      return {
        ...state,
        unidad_fiscalizable: {},
        is_loading_vigilancias: false,
      };
    case PROGRAMA_FALLIDO:
      return {
        ...state,
        programa: {},
        is_loading_vigilancias: false,
      };
    case PROGRAMA_OBTENER:
      return {
        ...state,
        programa: action.payload,
        is_loading_vigilancias: false,
      };
    case PROGRAMAS_ESTADISTICAS_FALLIDO:
      return {
        ...state,
        programas_estadisticas: {},
        is_loading_vigilancias: false,
      };
    case PROGRAMAS_ESTADISTICAS_OBTENER:
      return {
        ...state,
        programas_estadisticas: action.payload.programas,
        is_loading_vigilancias: false,
      };
    case PROGRAMAS_FALLIDO:
      return {
        ...state,
        programas: {},
        programa: {},
        programa_id: 0,
        filtros_programas: {},
        is_loading_vigilancias: false,
      };
    case PROGRAMAS_OBTENER:
      return {
        ...state,
        programas: action.payload.programas,
        programa: {},
        programa_id: 0,
        filtros_programas: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case ENCARGADOS_FILTRO_FALLIDO:
      return {
        ...state,
        encargados: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case ENCARGADOS_FILTRO_OBTENER:
      return {
        ...state,
        encargados: action.payload,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case INSTRUMENTOS_INFRINGIDOS_OBTENER:
      return {
        ...state,
        instrumentos_infringidos: action.payload.instrumentos_infringidos,
        instrumento_infringido_id: 0,
        filtros_instrumentos_infringidos: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case INSTRUMENTOS_INFRINGIDOS_FALLIDO:
      return {
        ...state,
        instrumentos_infringidos: {},
        instrumento_infringido_id: 0,
        filtros_instrumentos_infringidos: {},
        is_loading_vigilancias: false,
      };
    case DOCUMENTOS_OBTENER:
      return {
        ...state,
        documentos: action.payload.documentos,
        documento_id: 0,
        filtros_documentos: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case DOCUMENTOS_FALLIDO:
      return {
        ...state,
        documentos: {},
        documento_id: 0,
        filtros_documentos: {},
        is_loading_vigilancias: false,
      };
    case DOCUMENTOS_FILTROS_OBTENER:
      return {
        ...state,
        documentos_filtros: action.payload.documentos,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case DOCUMENTOS_FILTROS_FALLIDO:
      return {
        ...state,
        documentos_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case DOCUMENTOS_FILTROS_INPUT:
      return {
        ...state,
        documentos_filtro_input: action.payload,
      };
    case FISCALIZACIONES_ESTADISTICAS_OBTENER:
      return {
        ...state,
        fiscalizaciones_estadisticas: action.payload.fiscalizaciones,
        is_loading_vigilancias: false,
      };
    case FISCALIZACIONES_ESTADISTICAS_FALLIDO:
      return {
        ...state,
        fiscalizaciones: {},
        is_loading_vigilancias: false,
      };
    case FISCALIZACIONES_OBTENER:
      return {
        ...state,
        fiscalizaciones: action.payload.fiscalizaciones,
        fiscalizacion_id: 0,
        filtros_fiscalizaciones: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case FISCALIZACIONES_FALLIDO:
      return {
        ...state,
        fiscalizaciones: {},
        fiscalizacion_id: 0,
        filtros_fiscalizaciones: {},
        is_loading_vigilancias: false,
      };
    case FISCALIZACIONES_FILTROS_OBTENER:
      return {
        ...state,
        fiscalizaciones_filtros: action.payload.fiscalizaciones,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case FISCALIZACIONES_FILTROS_FALLIDO:
      return {
        ...state,
        fiscalizaciones_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case FISCALIZACION_FILTROS_INPUT:
      return {
        ...state,
        fiscalizaciones_filtro_input: action.payload,
      };
    case HECHOS_ESTADISTICAS_OBTENER:
      return {
        ...state,
        hechos_estadisticas: action.payload.hechos,
        is_loading_vigilancias: false,
      };
    case HECHOS_ESTADISTICAS_FALLIDO:
      return {
        ...state,
        hechos_estadisticas: {},
        is_loading_vigilancias: false,
      };
    case HECHOS_OBTENER:
      return {
        ...state,
        hechos: action.payload.hechos,
        hecho_id: 0,
        filtros_hechos: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case HECHOS_FALLIDO:
      return {
        ...state,
        hechos: {},
        hecho_id: 0,
        filtros_hechos: {},
        is_loading_vigilancias: false,
      };
    case HECHOS_FILTROS_INPUT:
      return {
        ...state,
        hechos_filtro_input: action.payload,
      };
    case PERTINENCIAS_FILTROS_INPUT:
      return {
        ...state,
        pertinencias_filtro_input: action.payload,
      };
    case UNIDADES_FISCALIZABLES_FILTROS_INPUT:
      return {
        ...state,
        unidades_filtro_input: action.payload,
      };
    case INSTRUMENTOS_INFRINGIDOS_FILTROS_INPUT:
      return {
        ...state,
        instrumentos_infringidos_filtro_input: action.payload,
      };
    case INSTRUMENTOS_INFRINGIDOS_FILTROS_OBTENER:
      return {
        ...state,
        instrumentos_infringidos_filtros:
          action.payload.instrumentos_infringidos,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case INSTRUMENTOS_INFRINGIDOS_FILTROS_FALLIDO:
      return {
        ...state,
        instrumentos_infringidos_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case HECHOS_FILTROS_OBTENER:
      return {
        ...state,
        hechos_filtros: action.payload.hechos,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case HECHOS_FILTROS_FALLIDO:
      return {
        ...state,
        hechos_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_ESTADISTICAS_OBTENER:
      return {
        ...state,
        expedientes_estadisticas: action.payload.expedientes,
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_ESTADISTICAS_FALLIDO:
      return {
        ...state,
        expedientes_estadisticas: {},
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_ESTADISTICAS_GENERADORA_OBTENER:
      return {
        ...state,
        expedientes_estadisticas_generadoras: action.payload.expedientes,
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_ESTADISTICAS_GENERADORA_FALLIDO:
      return {
        ...state,
        expedientes_estadisticas_generadoras: {},
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_OBTENER:
      return {
        ...state,
        expedientes: action.payload.expedientes,
        filtros_expedientes: action.payload.filtros,
        expediente_id: 0,
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_FALLIDO:
      return {
        ...state,
        expedientes: {},
        expediente_id: 0,
        filtros_expedientes: {},
        is_loading_vigilancias: false,
      };
    case SEGUIMIENTOS_OBTENER:
      return {
        ...state,
        seguimientos: action.payload.seguimientos,
        filtros_seguimientos: action.payload.filtros,
        seguimiento_id: 0,
        is_loading_vigilancias: false,
      };
    case SEGUIMIENTOS_FALLIDO:
      return {
        ...state,
        expedientes: {},
        expediente_id: 0,
        filtros_expedientes: {},
        is_loading_vigilancias: false,
      };
    case SEGUIMIENTOS_FILTROS_OBTENER:
      return {
        ...state,
        seguimientos: action.payload.seguimientos,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case SEGUIMIENTOS_FILTROS_FALLIDO:
      return {
        ...state,
        filtros_seguimientos: action.payload,
        is_loading_vigilancias: false,
      };
    case LIMPIAR_SEGUIMIENTOS_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        seguimientos_filtros: {},
        seguimientos_filtro_input: {},
        seguimientos: {},
      };
    case SEGUIMIENTO_OBTENER:
      return {
        ...state,
        seguimiento: action.payload,
        is_loading_vigilancias: false,
      };
    case SEGUIMIENTO_FALLIDO:
      return {
        ...state,
        seguimiento: {},
        is_loading_vigilancias: false,
      };
    case SEGUIMIENTOS_FILTROS_INPUT:
      return {
        ...state,
        seguimientos_filtro_input: action.payload,
      };
    case SEGUIMIENTO_GUARDAR:
      return {
        ...state,
        seguimiento_id: action.payload.id,
        seguimiento_componente: action.payload.componente,
      };
    case FILTROS_EXPEDIENTES_MAPA_OBTENER:
      return {
        ...state,
        filtros_expedientes_mapa: action.payload,
        is_loading_vigilancias: false,
      };
    case FILTROS_EXPEDIENTES_MAPA_FALLIDO:
      return {
        ...state,
        filtros_expedientes_mapa: {},
        is_loading_vigilancias: false,
      };
    case FILTROS_EXPEDIENTES_DOCUMENTOS_OBTENER:
      return {
        ...state,
        filtros_expedientes_documentos: action.payload,
        is_loading_vigilancias: false,
      };
    case FILTROS_EXPEDIENTES_DOCUMENTOS_FALLIDO:
      return {
        ...state,
        filtros_expedientes_documentos: {},
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_FILTROS_FALLIDO:
      return {
        ...state,
        expedientes_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case EXPEDIENTES_FILTROS_OBTENER:
      return {
        ...state,
        expedientes_filtros: action.payload.expedientes,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case TITULARES_OBTENER:
      return {
        ...state,
        titulares: action.payload,
        titular: {},
        titular_id: 0,
        is_loading_vigilancias: false,
      };
    case TITULARES_FALLIDO:
      return {
        ...state,
        titulares: {},
        titular: {},
        titular_id: 0,
        is_loading_vigilancias: false,
      };
    case PERTINENCIAS_FALLIDO:
      return {
        ...state,
        pertinencias: {},
        pertinencia_id: 0,
        filtros_pertinencias: {},
        is_loading_vigilancias: false,
      };
    case PERTINENCIAS_OBTENER:
      return {
        ...state,
        pertinencia_id: 0,
        pertinencias: action.payload.pertinencias,
        filtros_pertinencias: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case PERTINENCIAS_ESTADISTICAS_FALLIDO:
      return {
        ...state,
        pertinencias_estadisticas: {},
        is_loading_vigilancias: false,
      };
    case PERTINENCIAS_ESTADISTICAS_OBTENER:
      return {
        ...state,
        pertinencias_estadisticas: action.payload.pertinencias,
        is_loading_vigilancias: false,
      };
    case UNIDADES_FISCALIZABLES_OBTENER:
      return {
        ...state,
        unidades: action.payload.unidades,
        filtros_unidades: action.payload.filtros,
        is_loading_vigilancias: false,
        unidad_fiscalizable_id: 0,
      };
    case UNIDADES_FISCALIZABLES_FALLIDO:
      return {
        ...state,
        unidades: {},
        filtros_unidades: {},
        is_loading_vigilancias: false,
        unidad_fiscalizable_id: 0,
      };
    case UNIDADES_FISCALIZABLES_ESTADISTICAS_OBTENER:
      return {
        ...state,
        unidades_estadisticas: action.payload.unidades,
        is_loading_vigilancias: false,
      };
    case UNIDADES_FISCALIZABLES_ESTADISTICAS_FALLIDO:
      return {
        ...state,
        unidades_estadisticas: {},
        is_loading_vigilancias: false,
      };
    case TITULARES_FILTRO_OBTENER:
      return {
        ...state,
        titulares: action.payload,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case TITULARES_FILTRO_FALLIDO:
      return {
        ...state,
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case UNIDADES_FISCALIZABLES_FILTROS_OBTENER:
      return {
        ...state,
        unidades_filtros: action.payload.unidades,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case UNIDADES_FISCALIZABLES_FILTROS_FALLIDO:
      return {
        ...state,
        unidades_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case PROCEDIMIENTOS_ESTADISTICAS_OBTENER:
      return {
        ...state,
        procedimientos_estadisticas: action.payload.procedimientos,
        is_loading_vigilancias: false,
      };
    case PROCEDIMIENTOS_ESTADISTICAS_FALLIDO:
      return {
        ...state,
        procedimientos_estadisticas: {},
        is_loading_vigilancias: false,
      };
    case PROCEDIMIENTOS_OBTENER:
      return {
        ...state,
        procedimientos: action.payload.procedimientos,
        procedimiento_id: 0,
        filtros_procedimientos: action.payload.filtros,
        is_loading_vigilancias: false,
      };
    case PROCEDIMIENTOS_FALLIDO:
      return {
        ...state,
        procedimientos: {},
        procedimiento_id: 0,
        filtros_procedimientos: {},
        is_loading_vigilancias: false,
      };
    case PROCEDIMIENTOS_FILTROS_OBTENER:
      return {
        ...state,
        procedimientos_filtros: action.payload.procedimientos,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case PROCEDIMIENTOS_FILTROS_FALLIDO:
      return {
        ...state,
        procedimientos_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case ENCARGADOS_OBTENER:
      return {
        ...state,
        encargados: action.payload,
        encargado: {},
        encargado_id: 0,
        is_loading_vigilancias: false,
      };
    case ENCARGADOS_FALLIDO:
      return {
        ...state,
        encargados: {},
        encargado: {},
        encargado_id: 0,
        is_loading_vigilancias: false,
      };
    case CARGANDO_LIMPIAR_VIGILANCIAS:
      return {
        ...state,
        is_loading_vigilancias: true,
        favorito_guardado: {},
        favorito_eliminar: {},
      };
    case LIMPIAR_FISCALIZACIONES_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        fiscalizaciones_filtros: {},
        fiscalizaciones_filtro_input: {},
        fiscalizaciones: {},
      };
    case LIMPIAR_HECHOS_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        hechos_filtros: {},
        hechos_filtro_input: {},
        hechos: {},
      };
    case LIMPIAR_EXPEDIENTES_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        expedientes_filtros: {},
        expedientes_filtro_input: {},
        expedientes: {},
      };
    case LIMPIAR_PROGRAMA_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        programas_filtros: {},
        programas_filtro_input: {},
        programas: {},
      };
    case LIMPIAR_PROCEDIMIENTO_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        procedimientos_filtros: {},
        procedimientos_filtro_input: {},
        procedimientos: {},
      };
    case LIMPIAR_MEDIDAS_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        medidas_provisionales_filtros: {},
        medidas_provisionales_filtro_input: {},
        medidas_provisionales: {},
      };
    case LIMPIAR_EXPEDIENTES_MAPA_FILTROS:
      return {
        ...state,
        expedientes_mapa: [],
      };

    case LIMPIAR_EXPEDIENTES_DOCUMENTOS_FILTROS:
      return {
        ...state,
        expedientes_documentos: {},
      };
    case LIMPIAR_INSTRUMENTOS_INFRINGIDOS_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        instrumentos_infringidos_filtros: {},
        instrumentos_infringidos_filtro_input: {},
        instrumentos_infringidos: {},
      };
    case LIMPIAR_PERTINENCIAS_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        pertinencias_filtros: {},
        pertinencias_filtro_input: {},
        pertinencias: {},
      };
    case LIMPIAR_UNIDADES_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        unidades_filtros: {},
        unidades_filtro_input: {},
        unidades: {},
      };
    case LIMPIAR_DOCUMENTOS_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        documentos_filtros: {},
        documentos_filtro_input: {},
        documentos: {},
      };
    case LIMPIAR_REQUERIMIENTOS_INGRESO_FILTROS:
      return {
        ...state,
        is_loading_vigilancias: true,
        requerimientos_filtros: {},
        requerimientos_filtro_input: {},
        requerimientos: {},
      };
    case PERTINENCIAS_FILTROS_OBTENER:
      return {
        ...state,
        pertinencias_filtros: action.payload.pertinencias,
        filtros_errores: {},
        is_loading_vigilancias: false,
      };
    case PERTINENCIAS_FILTROS_FALLIDO:
      return {
        ...state,
        pertinencias_filtros: {},
        filtros_errores: action.payload,
        is_loading_vigilancias: false,
      };
    case ENCARGADO_OBTENER:
      return {
        ...state,
        encargado: action.payload,
        is_loading_vigilancias: false,
      };
    case ENCARGADO_FALLIDO:
      return {
        ...state,
        encargado: {},
        is_loading_vigilancias: false,
      };
    case TITULAR_OBTENER:
      return {
        ...state,
        titular: action.payload,
        is_loading_vigilancias: false,
      };
    case TITULAR_FALLIDO:
      return {
        ...state,
        titular: {},
        is_loading_vigilancias: false,
      };
    case ENCARGADO_GUARDAR:
      return {
        ...state,
        encargado_id: action.payload,
      };
    case TITULAR_GUARDAR:
      return {
        ...state,
        titular_id: action.payload,
      };
    case SANCIONES_GUARDAR:
      return {
        ...state,
        sancion_id: action.payload,
      };
    case PROGRAMA_GUARDAR:
      return {
        ...state,
        programa_id: action.payload,
      };
    case UNIDAD_FISCALIZABLE_GUARDAR:
      return {
        ...state,
        unidad_fiscalizable_id: action.payload.id,
        unidad_fiscalizable_componente: action.payload.componente,
      };
    case MEDIDA_PROVISIONAL_GUARDAR:
      return {
        ...state,
        medida_provisional_id: action.payload.id,
        medida_provisional_componente: action.payload.componente,
      };
    case PROCEDIMIENTO_GUARDAR:
      return {
        ...state,
        procedimiento_id: action.payload.id,
        procedimiento_componente: action.payload.componente,
      };
    case HECHO_GUARDAR:
      return {
        ...state,
        hecho_id: action.payload,
      };
    case EXPEDIENTE_GUARDAR:
      return {
        ...state,
        expediente_id: action.payload.id,
        expediente_componente: action.payload.componente,
      };
    case INSTRUMENTO_INFRINGIDO_GUARDAR:
      return {
        ...state,
        instrumento_infringido_id: action.payload,
      };
    case FISCALIZACION_GUARDAR:
      return {
        ...state,
        fiscalizacion_id: action.payload,
      };
    default:
      return state;
  }
}
