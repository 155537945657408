import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  crearEquipo,
  limpiarMensajes,
  obtenerEquipos,
  obtenerEquiposFiltros,
} from "../../../redux/actions/teams/equipos/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Tabla } from "./";

export class Listado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre_equipo: "",
    };
  }

  componentDidMount = () => {
    const { obtenerEquipos } = this.props;
    obtenerEquipos(1);
  };

  nuevaPagina = (pagina) => {
    const {
      obtenerEquipos,
      obtenerEquiposFiltros,
      equipos_filtros,
      equipos_filtro_input,
    } = this.props;
    if (Object.entries(equipos_filtros).length > 0) {
      obtenerEquiposFiltros({
        filtros: equipos_filtro_input,
        pagina: pagina,
      });
    } else {
      obtenerEquipos(pagina);
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  nuevoEquipo = () => {
    const { nombre_equipo } = this.state;
    const { crearEquipo } = this.props;
    if (nombre_equipo === "") {
      SweetAlert.fire({
        title: "Creación del Equipo",
        text: "El nombre del equipo no puede estar vacío",
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
    } else {
      crearEquipo({ nombre: nombre_equipo });
      SweetAlert.fire({
        title: "Creación del Equipo",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos validando la información, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    }
  };

  render = () => {
    const {
      equipos,
      equipos_filtros,
      is_loading_teams,
      equipo_crear,
      equipo_crear_error,
      limpiarMensajes,
      obtenerEquipos,
      user,
    } = this.props;
    const { nombre_equipo } = this.state;
    const perfil =
      user && user.modulos && user.modulos.sub_teams
        ? user.modulos.sub_teams.perfil
        : null;

    var equipos_result = equipos;
    if (Object.entries(equipos_filtros).length > 0) {
      equipos_result = equipos_filtros;
    }

    if (is_loading_teams) {
      SweetAlert.fire({
        title: "Equipos VGC",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los equipos, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (Object.entries(equipo_crear).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Equipo Creado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: equipo_crear.message,
        icon: "success",
        confirmButtonText: 'Aceptar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          document.getElementById("cerrar_modal_nuevo_equipo").click();
          this.setState({
            nombre_equipo: "",
          });
          obtenerEquipos();
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(equipo_crear_error).length > 0) {
      SweetAlert.close();
      if (equipo_crear_error.data && equipo_crear_error.data.message) {
        SweetAlert.fire({
          title: "Equipo no Creado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: equipo_crear_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="teams" menu_2="equipos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-users"></i> EQUIPOS
                  </h4>
                  {perfil && perfil in ["3", "2"] && (
                    <>
                      <div
                        className="modal fade"
                        id="nuevo_equipo"
                        tabIndex="-1"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="text-uppercase">Nuevo Equipo</h5>
                            </div>
                            <div className="modal-body">
                              <div className="form-group">
                                <label className="mb-1">
                                  Nombre del Equipo
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="nombre_equipo"
                                  onChange={this.onChange}
                                  value={nombre_equipo}
                                  placeholder="Escriba el nombre del Equipo"
                                />
                              </div>
                            </div>
                            <div className="modal-footer">
                              <div className="btn-group">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  data-bs-dismiss="modal"
                                  id="cerrar_modal_nuevo_equipo"
                                >
                                  Cancelar <i className="fa fa-trash"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={this.nuevoEquipo}
                                >
                                  Crear Equipo <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="float-end">
                        <button
                          className="btn btn-danger"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#nuevo_equipo"
                        >
                          <i className="fa fa-plus"></i> Crear Equipo
                        </button>
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                {/* <Filtros /> */}
                <div className="card">
                  <div className="card-body">
                    <Tabla
                      equipos={equipos_result}
                      obtenerEquipos={obtenerEquipos}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerEquipos: PropTypes.func.isRequired,
  obtenerEquiposFiltros: PropTypes.func.isRequired,
  crearEquipo: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  equipos: PropTypes.object.isRequired,
  equipos_filtros: PropTypes.object.isRequired,
  equipos_filtro_input: PropTypes.object.isRequired,
  equipo_crear: PropTypes.object.isRequired,
  equipo_crear_error: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_teams: state.teams.is_loading_teams,
  equipos: state.teams.equipos,
  equipos_filtros: state.teams.equipos_filtros,
  equipos_filtro_input: state.teams.equipos_filtro_input,
  equipo_crear: state.teams.equipo_crear,
  equipo_crear_error: state.teams.equipo_crear_error,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerEquipos,
      crearEquipo,
      obtenerEquiposFiltros,
      limpiarMensajes,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
