import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerFiscalizacion } from "../../../redux/actions/vigilancias/fiscalizaciones/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Tabs } from "./";

export class Detalle extends Component {
  componentDidMount = () => {
    const { obtenerFiscalizacion, fiscalizacion_id } = this.props;
    if (fiscalizacion_id > 0) {
      obtenerFiscalizacion({
        id: fiscalizacion_id,
      });
    }
  };

  render = () => {
    const { is_loading_vigilancias, fiscalizacion_id, fiscalizacion } =
      this.props;

    if (fiscalizacion_id === 0) {
      return <Navigate to="/compliance-analytics/fiscalizaciones/listado" />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Fiscalización",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo la fiscalización, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_fiscalizaciones" menu_2="listado" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-binoculars"></i> FISCALIZACIONES
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-lg-9">
                        <h5 className="float-start">
                          EXPEDIENTE:{" "}
                          {fiscalizacion && fiscalizacion.expediente}
                        </h5>
                      </div>
                      <div className="col-lg-3">
                        <div className="btn-group float-end">
                          <Link
                            className="btn btn-primary"
                            to="/compliance-analytics/fiscalizaciones/listado"
                          >
                            <i className="fa fa-angle-left"></i> Volver al
                            listado
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <Tabs fiscalizaciones={[fiscalizacion]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerFiscalizacion: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  fiscalizacion: PropTypes.object.isRequired,
  fiscalizacion_id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  fiscalizacion: state.vigilancias.fiscalizacion,
  fiscalizacion_id: state.vigilancias.fiscalizacion_id,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerFiscalizacion }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
