import React, { Component } from "react";

export class Footer extends Component {
  render = () => {
    const today = new Date();

    return (
      <footer className="footer text-center bg-footer">
        <span className="text-muted d-none d-sm-inline-block">
          <a
            className="me-1 text-danger border-bottom font-weight-bold"
            rel="noopener noreferrer"
            href="https://docs.google.com/document/d/19ETmULLsiwrHSD2GlI1M0bYeUe0Pm8UuTBi4o0pQEa8/edit?usp=sharing"
            target="_blank"
          >
            Política de Privacidad
          </a>
          |
          <a
            className="me-1 ms-1 text-danger border-bottom font-weight-bold"
            rel="noopener noreferrer"
            href="https://docs.google.com/document/d/1Zpa9-FRmFDEqBXxiQCsYDPU5wXW139DxtU3FgoL9pDU/edit?usp=sharing"
            target="_blank"
          >
            Términos y Condiciones
          </a>
          |
          <a
            className="me-1 ms-1 text-danger border-bottom font-weight-bold"
            rel="noopener noreferrer"
            href="https://wa.me/56930686476"
            target="_blank"
          >
            Soporte
          </a>
          |
        </span>
        <span>
          <a
            href="https://www.linkedin.com/company/cumplimientolean/"
            target="_blank"
            rel="noopener noreferrer"
            className="ms-1 text-primary"
          >
            <i className="fab fa-linkedin font-16"></i>
          </a>
        </span>
        <span className="ms-1">
          <span className="text-dark">
            {today.getFullYear()} Cumplimiento Lean
          </span>
          <i className="ms-1 fa fa-heart text-danger"></i>
        </span>
      </footer>
    );
  };
}

export default Footer;
