// CSS
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import "rsuite/CheckTreePicker/styles/index.css";
import "rsuite/SelectPicker/styles/index.css";
import "./css/app.css";
// JS
import "bootstrap/dist/js/bootstrap";

import React from "react";

import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_DEBUG === "on") {
  Sentry.init({
    dsn: "https://d6d4700c9f134be9be78afd830769513@o566837.ingest.us.sentry.io/5710125",
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
      }),
      Sentry.replayIntegration(),
    ],
    release: "cumplimiento-lean@" + process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENVIRONMENT,
    debug: false,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App tab="app" />);

serviceWorker.unregister();
