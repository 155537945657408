import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerQuinencoUnidades } from "../../../../redux/actions/vigilancias/quinenco/action";
import {
  Footer,
  Maps,
  Navbar,
  Paginador,
  SidebarInicio,
} from "../../../general";
import { Tabla } from "../../unidades";
import { Filtros } from "./";

export class QuinencoUnidadesFiscalizables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ver_mapa: false,
    };
  }

  componentDidMount = () => {
    const { obtenerQuinencoUnidades, quinenco_unidades } = this.props;
    if (Object.entries(quinenco_unidades).length === 0) {
      obtenerQuinencoUnidades(1);
    }
  };

  nuevaPagina = (pagina) => {
    const { obtenerQuinencoUnidades } = this.props;
    obtenerQuinencoUnidades(pagina);
  };

  verListadoMapa = (estado) => {
    this.setState({
      ver_mapa: estado ? estado : false,
    });
  };

  render = () => {
    const { quinenco_unidades, is_loading_vigilancias } = this.props;
    const { ver_mapa } = this.state;
    var markers = [];
    if (
      quinenco_unidades &&
      quinenco_unidades.result &&
      quinenco_unidades.result.length > 0
    ) {
      quinenco_unidades.result.forEach(function (unidad) {
        if (unidad.latitud && unidad.longitud) {
          markers.push({
            titulo: "SNIFA - Unidad Fiscalizable",
            nombre: unidad.nombre,
            position: [unidad.latitud, unidad.longitud],
          });
        }
      });
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Unidades Fiscalizables",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los unidades fiscalizables, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="empresa_quinenco" menu_2="unidades" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-leaf"></i> QUIÑENCO
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE UNIDADES FISCALIZABLES{" "}
                      <small>
                        (
                        {quinenco_unidades && quinenco_unidades.total
                          ? quinenco_unidades.total
                          : 0}
                        )
                      </small>
                    </h5>
                    <div className="float-end">
                      <button
                        onClick={this.verListadoMapa.bind(this, true)}
                        className={`btn btn-primary ${
                          ver_mapa ? "d-none" : ""
                        }`}
                      >
                        VER MAPA <i className="fa fa-map-marker-alt"></i>
                      </button>
                      <button
                        onClick={this.verListadoMapa.bind(this, false)}
                        className={`btn btn-danger ${
                          !ver_mapa ? "d-none" : ""
                        }`}
                      >
                        ESCONDER MAPA <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className={ver_mapa ? "d-none" : ""}>
                      <Paginador
                        paginador={quinenco_unidades}
                        nuevaPagina={this.nuevaPagina}
                      />
                      <Tabla
                        unidades_fiscalizables={quinenco_unidades}
                        componente="quinenco"
                      />
                    </div>
                    <div className={!ver_mapa ? "d-none" : ""}>
                      <Maps
                        center={[-33, -70]}
                        zoom={6}
                        markers={markers}
                        size={1000}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

QuinencoUnidadesFiscalizables.propTypes = {
  obtenerQuinencoUnidades: PropTypes.func.isRequired,
  quinenco_unidades: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  quinenco_unidades: state.vigilancias.quinenco_unidades,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerQuinencoUnidades }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuinencoUnidadesFiscalizables);
