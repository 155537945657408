import React, { Component } from "react";
import { Tabla as Acciones } from "../acciones";

export class Tabs extends Component {
  render = () => {
    const { reportes } = this.props;

    if (reportes && reportes.length < 1) {
      return <h6>No existen reportes registrados</h6>;
    } else {
      return (
        <div className="align-items-start">
          <div className="row">
            <div className="col-2">
              <div
                className="nav flex-column nav-pills me-3"
                role="tablist"
                aria-orientation="vertical"
              >
                {reportes.map((reporte, x) => (
                  <button
                    key={x}
                    className={`nav-link ${x === 0 ? "active" : ""} text-start`}
                    id={`reporte-tab-${reporte.id}`}
                    data-bs-toggle="pill"
                    data-bs-target={`#reporte-${reporte.id}`}
                    type="button"
                    role="tab"
                    aria-controls={`reporte-${reporte.id}`}
                    aria-selected={x === 0 ? "true" : "false"}
                  >
                    {reporte.fecha_envio} <span className="ms-1 me-1">|</span>{" "}
                    {reporte.tipo}
                  </button>
                ))}
              </div>
            </div>
            <div className="col-10">
              <div className="tab-content" id="v-pills-reportes">
                {reportes.map((reporte, x) => (
                  <div
                    key={x}
                    className={`tab-pane fade ${x === 0 ? "show active" : ""}`}
                    id={`reporte-${reporte.id}`}
                    role="tabpanel"
                    aria-labelledby={`reporte-tab-${reporte.id}`}
                    tabIndex="0"
                  >
                    <ul className="nav nav-pills nav-tabs nav-justified">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id={`general-tab-rep-${reporte.id}`}
                          data-bs-toggle="pill"
                          data-bs-target={`#general-rep-${reporte.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`general-rep-${reporte.id}`}
                          aria-selected="true"
                        >
                          Información del Reporte
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id={`acciones-tab-rep-${reporte.id}`}
                          data-bs-toggle="pill"
                          data-bs-target={`#acciones-rep-${reporte.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`acciones-rep-${reporte.id}`}
                          aria-selected="true"
                        >
                          Acciones (
                          {reporte.acciones.length > 0
                            ? reporte.acciones.length
                            : 0}
                          )
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show mt-3"
                        id={`general-rep-${reporte.id}`}
                        role="tabpanel"
                        aria-labelledby={`general-tab-rep-${reporte.id}`}
                      >
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td className="table-secondary w-20 text-dark">
                                  Fecha de envío
                                </td>
                                <td>
                                  {reporte.fecha_envio
                                    ? reporte.fecha_envio
                                    : "S/I"}
                                </td>
                              </tr>
                              <tr>
                                <td className="table-secondary w-20 text-dark">
                                  Tipo
                                </td>
                                <td>{reporte.tipo ? reporte.tipo : "S/I"}</td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Comprobante de envío
                                </td>
                                <td>
                                  <a
                                    className="btn btn-danger btn-xs"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={reporte.url_comprobante_envio}
                                  >
                                    Descargar <i className="fa fa-file-pdf"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Medios de verificación
                                </td>
                                <td>
                                  <a
                                    className="btn btn-danger btn-xs"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={reporte.url_medios_verificacion}
                                  >
                                    Descargar <i className="fa fa-file-pdf"></i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade mt-3"
                        id={`acciones-rep-${reporte.id}`}
                        role="tabpanel"
                        aria-labelledby={`acciones-tab-rep-${reporte.id}`}
                      >
                        <Acciones acciones={reporte.acciones} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}

export default Tabs;
