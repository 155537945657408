import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  LIMPIAR_PROGRAMA_FILTROS,
  PROGRAMA_FALLIDO,
  PROGRAMA_GUARDAR,
  PROGRAMA_OBTENER,
  PROGRAMAS_ESTADISTICAS_FALLIDO,
  PROGRAMAS_ESTADISTICAS_OBTENER,
  PROGRAMAS_FALLIDO,
  PROGRAMAS_FILTROS_FALLIDO,
  PROGRAMAS_FILTROS_INPUT,
  PROGRAMAS_FILTROS_OBTENER,
  PROGRAMAS_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerProgramasCumplimientoEstadistica =
  (pagina) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .get(
        `${url_api}/api/vigilances/programas/estadisticas/${pagina}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: PROGRAMAS_ESTADISTICAS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : PROGRAMAS_ESTADISTICAS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerProgramasCumplimiento =
  (pagina) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .get(
        `${url_api}/api/vigilances/programas/${pagina}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: PROGRAMAS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : PROGRAMAS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerProgramasCumplimientoFiltros =
  (data) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .post(
        `${url_api}/api/vigilances/programas/filtros/${data.pagina}`,
        data.filtros,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({ type: PROGRAMAS_FILTROS_INPUT, payload: data.filtros });
        dispatch({ type: PROGRAMAS_FILTROS_OBTENER, payload: res.data });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : PROGRAMAS_FILTROS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerProgramaCumplimiento = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(`${url_api}/api/vigilances/programa`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: PROGRAMA_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : PROGRAMA_FALLIDO,
        payload: error,
      });
    });
};

export const guardarPrograma = (id) => (dispatch) => {
  dispatch({
    type: PROGRAMA_GUARDAR,
    payload: id,
  });
};

export const limpiarFiltroPrograma = () => (dispatch) => {
  dispatch({ type: LIMPIAR_PROGRAMA_FILTROS });
};
