import React, { Component } from "react";

import { Modificacion, Partes } from "./";

export class Ficha extends Component {
  render = () => {
    const { norma } = this.props;
    var fecha_publicacion =
      norma && norma.fecha_publicacion
        ? norma.fecha_publicacion.split("/").join("-")
        : "";
    return (
      <>
        <ul className="nav nav-pills nav-tabs nav-justified">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="general-tab"
              data-bs-toggle="pill"
              data-bs-target="#general"
              type="button"
              role="tab"
              aria-controls="general"
              aria-selected="false"
            >
              Información general
            </button>
          </li>
          {norma && norma.partes && norma.partes.length > 0 && (
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="partes-tab"
                data-bs-toggle="pill"
                data-bs-target="#partes"
                type="button"
                role="tab"
                aria-controls="partes"
                aria-selected="false"
              >
                Norma
              </button>
            </li>
          )}
          {norma && norma.modifica_a && norma.modifica_a.length > 0 && (
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="modifica_a-tab"
                data-bs-toggle="pill"
                data-bs-target="#modifica_a"
                type="button"
                role="tab"
                aria-controls="modifica_a"
                aria-selected="false"
              >
                Modifica a ({norma.modifica_a.length})
              </button>
            </li>
          )}
          {norma && norma.modifica_por && norma.modifica_por.length > 0 && (
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="modifica_por-tab"
                data-bs-toggle="pill"
                data-bs-target="#modifica_por"
                type="button"
                role="tab"
                aria-controls="modifica_por"
                aria-selected="false"
              >
                Modificado por ({norma.modifica_por.length})
              </button>
            </li>
          )}
        </ul>
        <div className="tab-content">
          <div
            className="tab-pane fade active show"
            id="general"
            role="tabpanel"
            aria-labelledby="general-tab"
          >
            <h5 className="mb-3 mt-3 text-uppercase">
              Información general de la Norma
            </h5>
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {norma.titulo && (
                    <tr>
                      <td className="table-secondary w-15 text-dark">
                        Título de la Norma
                      </td>
                      <td>
                        <a
                          href={norma.url_ley}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {norma.titulo} <i className="fa fa-angle-right"></i>
                        </a>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="table-secondary w-15 text-dark">
                      Descargar
                    </td>
                    <td>
                      <div className="btn-group">
                        <a
                          rel="noreferrer"
                          target="_blank"
                          data-tip
                          data-tooltip-id="archivo_pdf"
                          href={norma.url_pdf}
                          className="btn btn-danger btn-sm"
                        >
                          PDF <i className="fa fa-file-pdf"></i>
                        </a>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          data-tip
                          data-tooltip-id="archivo_word"
                          href={norma.url_word}
                          className="btn btn-primary btn-sm"
                        >
                          WORD <i className="fa fa-file-word"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                  {norma.fecha_promulgacion && (
                    <tr>
                      <td className="table-secondary text-dark">
                        Fecha de Promulgación
                      </td>
                      <td>{norma.fecha_promulgacion}</td>
                    </tr>
                  )}
                  {norma.fecha_publicacion && (
                    <tr>
                      <td className="table-secondary text-dark">
                        Fecha de Publicación
                      </td>
                      <td>{norma.fecha_publicacion}</td>
                    </tr>
                  )}
                  {norma.fecha_vigencia && (
                    <tr>
                      <td className="table-secondary text-dark">
                        Fecha de Vigencia
                      </td>
                      <td>{norma.fecha_vigencia}</td>
                    </tr>
                  )}
                  <tr>
                    <td className="table-secondary text-dark">¿Derogado?</td>
                    <td>{norma.derogado ? "SI" : "NO"}</td>
                  </tr>
                  {norma.tipos && norma.tipos.length > 0 && (
                    <tr>
                      <td className="table-secondary text-dark">
                        Tipos de Norma
                      </td>
                      <td>
                        <div className="table-responsive">
                          <table className="table">
                            <thead className="table-secondary">
                              <tr>
                                <th className="w-5 text-center">#</th>
                                <th>Abreviación</th>
                                <th>Tipo</th>
                                <th>Número</th>
                              </tr>
                            </thead>
                            <tbody>
                              {norma.tipos.map((tipo, x) => (
                                <tr key={x}>
                                  <td className="text-center mb-0">{x + 1}</td>
                                  <td className="mb-0">{tipo.abreviacion}</td>
                                  <td className="mb-0">{tipo.tipo_display}</td>
                                  <td className="mb-0">{tipo.numero}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                  {norma.autoridad && norma.autoridad.length > 0 && (
                    <tr>
                      <td className="table-secondary text-dark">
                        Organismo Relacionado
                      </td>
                      <td>
                        <div className="table-responsive">
                          <table className="table">
                            <thead className="table-secondary">
                              <tr>
                                <th className="w-5 text-center">#</th>
                                <th>Nombre</th>
                              </tr>
                            </thead>
                            <tbody>
                              {norma.autoridad.map((autoridad, x) => (
                                <tr key={x}>
                                  <td className="text-center mb-0">{x + 1}</td>
                                  <td className="mb-0">{autoridad.nombre}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                  {norma.materia && norma.materia.length > 0 && (
                    <tr>
                      <td className="table-secondary text-dark">
                        Materia Relacionada
                      </td>
                      <td>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="w-5 text-center">#</th>
                                <th>Nombre</th>
                              </tr>
                            </thead>
                            <tbody>
                              {norma.materia.map((materia, x) => (
                                <tr key={x}>
                                  <td className="text-center mb-0">{x + 1}</td>
                                  <td className="mb-0">{materia.nombre}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                  {norma.usocomun && norma.usocomun.length > 0 && (
                    <tr>
                      <td className="table-secondary text-dark">Uso Común</td>
                      <td>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="w-5 text-center">#</th>
                                <th>Nombre</th>
                              </tr>
                            </thead>
                            <tbody>
                              {norma.usocomun.map((usocomun, x) => (
                                <tr key={x}>
                                  <td className="text-center mb-0">{x + 1}</td>
                                  <td className="mb-0">{usocomun.nombre}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                  {norma.fuente_nombre && (
                    <tr>
                      <td className="table-secondary text-dark">Fuente</td>
                      <td className="align-items-center">
                        {norma.fuente_nombre}
                        {norma.fuente_numero && (
                          <span className="ms-1">- {norma.fuente_numero}</span>
                        )}
                        {norma.fuente_nombre &&
                          norma.fuente_nombre === "Diario Oficial" &&
                          norma.fuente_numero &&
                          norma.fecha_publicacion && (
                            <a
                              href={`https://www.diariooficial.interior.gob.cl/edicionelectronica/index.php?date=${fecha_publicacion}&edition=${norma.fuente_numero}`}
                              target="_blank"
                              rel="noreferrer"
                              className="ms-2 btn btn-soft-danger btn-sm"
                            >
                              Ir a la edición del Diario Oficial{" "}
                              <i className="fa fa-angle-right"></i>
                            </a>
                          )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {norma && norma.modifica_a && norma.modifica_a.length > 0 && (
            <div
              className="tab-pane fade"
              id="modifica_a"
              role="tabpanel"
              aria-labelledby="modifica_a-tab"
            >
              <h5 className="text-uppercase mb-3 mt-3">
                Listado de normas que fueron modificadas por la norma
                seleccionada
              </h5>
              <Modificacion modificaciones={norma.modifica_a} />
            </div>
          )}
          {norma && norma.modifica_por && norma.modifica_por.length > 0 && (
            <div
              className="tab-pane fade"
              id="modifica_por"
              role="tabpanel"
              aria-labelledby="modifica_por-tab"
            >
              <h5 className="text-uppercase mb-3 mt-3">
                Listado de normas que han modificado a la norma seleccionada
              </h5>
              <Modificacion modificaciones={norma.modifica_por} />
            </div>
          )}
          <div
            className="tab-pane fade"
            id="partes"
            role="tabpanel"
            aria-labelledby="partes-tab"
          >
            <h5 className="text-uppercase mt-3 mb-3">Navegación de la Norma</h5>
            <Partes partes={norma.partes} />
          </div>
        </div>
      </>
    );
  };
}

export default Ficha;
