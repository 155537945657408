import React, { Component } from "react";
import { Tooltip } from "react-tooltip";
import { TablaPequena as Documentos } from "../documentos";
import { Tabs as Fiscalizaciones } from "../fiscalizaciones";
import { TablaMedia as Hechos } from "../hechos";
import { TablaPequena as Titulares } from "../titulares";
import { Tabs as UnidadesFiscalizables } from "../unidades";

export class Tabs extends Component {
  render = () => {
    const { procedimientos } = this.props;
    if (procedimientos && procedimientos.length < 1) {
      return (
        <tr className="text-center">
          <td colSpan="7">
            No existen procedimientos sancionatorios registrados
          </td>
        </tr>
      );
    } else {
      return (
        <div className="align-items-start">
          <div className="row">
            {procedimientos.length > 1 && (
              <div className="col-3">
                <div
                  className="nav flex-column nav-pills me-3"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {procedimientos.map((procedimiento, x) => (
                    <button
                      key={x}
                      className={`nav-link ${
                        x === 0 ? "active" : ""
                      } text-start`}
                      id={`procedimiento-tab-${procedimiento.expediente}`}
                      data-bs-toggle="pill"
                      data-bs-target={`#procedimiento-${procedimiento.expediente}`}
                      type="button"
                      role="tab"
                      aria-controls={`procedimiento-${procedimiento.expediente}`}
                      aria-selected={x === 0 ? "true" : "false"}
                    >
                      <>
                        {procedimiento.expediente}{" "}
                        <span className="ms-1 me-1">|</span>{" "}
                      </>{" "}
                      {procedimiento.fecha_inicio}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className={`col-${procedimientos.length > 1 ? 9 : 12}`}>
              <div className="tab-content" id="v-pills-procedimientos">
                {procedimientos.map((procedimiento, x) => (
                  <div
                    key={x}
                    className={`tab-pane fade ${x === 0 ? "show active" : ""}`}
                    id={`procedimiento-${procedimiento.expediente}`}
                    role="tabpanel"
                    aria-labelledby={`procedimiento-tab-${procedimiento.expediente}`}
                    tabIndex="0"
                  >
                    <ul className="nav nav-pills nav-justified">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id={`general-tab-pro-${procedimiento.id}`}
                          data-bs-toggle="pill"
                          data-bs-target={`#general-pro-${procedimiento.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`general-pro-${procedimiento.id}`}
                          aria-selected="true"
                        >
                          Información del Procedimiento
                        </button>
                      </li>
                      {procedimiento.unidad_fiscalizable &&
                        procedimiento.unidad_fiscalizable.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`unidad_fiscalizable-tab-pro-${procedimiento.id}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#unidad_fiscalizable-pro-${procedimiento.id}`}
                              type="button"
                              role="tab"
                              aria-controls={`unidad_fiscalizable-pro-${procedimiento.id}`}
                              aria-selected="true"
                            >
                              Unidades Fiscalizables (
                              {procedimiento.unidad_fiscalizable.length})
                            </button>
                          </li>
                        )}
                      {procedimiento.documentos &&
                        procedimiento.documentos.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`documentos-tab-pro-${procedimiento.id}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#documentos-pro-${procedimiento.id}`}
                              type="button"
                              role="tab"
                              aria-controls={`documentos-pro-${procedimiento.id}`}
                              aria-selected="true"
                            >
                              Documentos ({procedimiento.documentos.length})
                            </button>
                          </li>
                        )}
                      {procedimiento.hechos &&
                        procedimiento.hechos.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`hechos-tab-pro-${procedimiento.id}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#hechos-pro-${procedimiento.id}`}
                              type="button"
                              role="tab"
                              aria-controls={`hechos-pro-${procedimiento.id}`}
                              aria-selected="true"
                            >
                              Hechos Infraccionales (
                              {procedimiento.hechos.length})
                            </button>
                          </li>
                        )}
                      {procedimiento.fiscalizaciones &&
                        procedimiento.fiscalizaciones.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`fiscalizaciones-tab-pro-${procedimiento.id}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#fiscalizaciones-pro-${procedimiento.id}`}
                              type="button"
                              role="tab"
                              aria-controls={`fiscalizaciones-pro-${procedimiento.id}`}
                              aria-selected="true"
                            >
                              Fiscalizaciones (
                              {procedimiento.fiscalizaciones.length})
                            </button>
                          </li>
                        )}
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show"
                        id={`general-pro-${procedimiento.id}`}
                        role="tabpanel"
                        aria-labelledby={`general-tab-pro-${procedimiento.id}`}
                      >
                        <div className="table-responsive mt-3">
                          <table className="table table-bordered table-hover">
                            <tbody>
                              <tr>
                                <td className="table-secondary w-13 text-dark">
                                  Expediente
                                </td>
                                <td>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={procedimiento.url_snifa}
                                  >
                                    {procedimiento.expediente}{" "}
                                    <i className="fa fa-angle-right"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Estado
                                </td>
                                <td>{procedimiento.estado}</td>
                              </tr>
                              {procedimiento.fecha_inicio && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Fecha de inicio
                                  </td>
                                  <td>{procedimiento.fecha_inicio}</td>
                                </tr>
                              )}
                              {procedimiento.fecha_termino && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Fecha de término
                                  </td>
                                  <td>{procedimiento.fecha_termino}</td>
                                </tr>
                              )}
                              {procedimiento.multa && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Multa
                                    <i
                                      className="fa fa-exclamation-circle ms-1 text-warning font-11"
                                      data-tip
                                      data-tooltip-id="proce_multa"
                                    ></i>
                                    <Tooltip
                                      id="proce_multa"
                                      aria-haspopup="true"
                                    >
                                      UTA = Unidad Tributaria Anual (UTM x 12).
                                      Para más información visitar el sitio
                                      oficial de SII.
                                    </Tooltip>
                                  </td>
                                  <td>{procedimiento.multa}</td>
                                </tr>
                              )}
                              {procedimiento.pago && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Pago
                                  </td>
                                  <td>{procedimiento.pago}</td>
                                </tr>
                              )}
                              {procedimiento.titulares && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Titular
                                  </td>
                                  <td>
                                    <Titulares
                                      titulares={procedimiento.titulares}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {procedimiento.unidad_fiscalizable &&
                        procedimiento.unidad_fiscalizable.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`unidad_fiscalizable-pro-${procedimiento.id}`}
                            role="tabpanel"
                            aria-labelledby={`unidad_fiscalizable-tab-pro-${procedimiento.id}`}
                          >
                            <UnidadesFiscalizables
                              unidades_fiscalizables={
                                procedimiento.unidad_fiscalizable
                              }
                            />
                          </div>
                        )}
                      {procedimiento.documentos &&
                        procedimiento.documentos.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`documentos-pro-${procedimiento.id}`}
                            role="tabpanel"
                            aria-labelledby={`documentos-tab-pro-${procedimiento.id}`}
                          >
                            <Documentos documentos={procedimiento.documentos} />
                          </div>
                        )}
                      {procedimiento.hechos &&
                        procedimiento.hechos.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`hechos-pro-${procedimiento.id}`}
                            role="tabpanel"
                            aria-labelledby={`hechos-tab-pro-${procedimiento.id}`}
                          >
                            <Hechos hechos={procedimiento.hechos} />
                          </div>
                        )}
                      {procedimiento.fiscalizaciones &&
                        procedimiento.fiscalizaciones.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`fiscalizaciones-pro-${procedimiento.id}`}
                            role="tabpanel"
                            aria-labelledby={`fiscalizaciones-tab-pro-${procedimiento.id}`}
                          >
                            <Fiscalizaciones
                              fiscalizaciones={procedimiento.fiscalizaciones}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}

export default Tabs;
