import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerRequerimientoIngreso } from "../../../redux/actions/vigilancias/requerimientos/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Guardar as GuardarFavorito } from "../favoritos/";
import { Tabs } from "./";

export class Detalle extends Component {
  componentDidMount = () => {
    const { obtenerRequerimientoIngreso, requerimiento_id } = this.props;
    if (requerimiento_id > 0) {
      obtenerRequerimientoIngreso({
        id: requerimiento_id,
      });
    }
  };

  render = () => {
    const {
      is_loading_vigilancias,
      requerimiento_id,
      requerimiento,
      requerimiento_componente,
    } = this.props;

    const redirect =
      requerimiento_componente === "normal"
        ? "/compliance-analytics/requerimientos-ingreso"
        : "/teams/requerimientos";
    const menu1 =
      requerimiento_componente === "normal"
        ? "vigilancias_requerimientos_ingreso"
        : "teams";
    const menu2 =
      requerimiento_componente === "normal" ? "listado" : "requerimientos";

    if (requerimiento_id === 0) {
      return <Navigate to={redirect} />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Requerimiento de Ingreso",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo el requerimiento de ingreso, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1={menu1} menu_2={menu2} />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-arrow-alt-circle-right"></i>{" "}
                    REQUERIMIENTOS DE INGRESO
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-lg-9">
                        <h5 className="float-start">
                          {requerimiento && requerimiento.expediente}
                        </h5>
                      </div>
                      <div className="col-lg-3">
                        <div className="btn-group">
                          {requerimiento && (
                            <GuardarFavorito
                              tipo="requerimiento_ingreso"
                              id={requerimiento.id}
                            />
                          )}
                          <Link className="btn btn-primary" to={redirect}>
                            <i className="fa fa-angle-left"></i> Volver al
                            listado
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {requerimiento && <Tabs requerimientos={[requerimiento]} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerRequerimientoIngreso: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  requerimiento: PropTypes.object.isRequired,
  requerimiento_id: PropTypes.number.isRequired,
  requerimiento_componente: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  requerimiento: state.vigilancias.requerimiento,
  requerimiento_id: state.vigilancias.requerimiento_id,
  requerimiento_componente: state.vigilancias.requerimiento_componente,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerRequerimientoIngreso }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
