import React, { Component } from "react";

import { Tooltip } from "react-tooltip";

export class FichaPermiso extends Component {
  verArchivo = (archivo, permiso) => {
    this.props.verArchivo(archivo, permiso);
  };

  render = () => {
    const { permiso, ver_pas, is_externo } = this.props;
    const createMarkup = (htmlString) => ({ __html: htmlString });

    return (
      <div className="col-lg-12 user_select_none">
        <div className="card card-eco">
          <div className="card-header">
            <h5 className="float-start">FICHA TÉCNICA DEL PERMISO</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <Tooltip
                className="text-center"
                id="restriccion_acceso"
                aria-haspopup="true"
              >
                Restriccion de Acceso
                <br />
                <small>Información no disponible</small>
              </Tooltip>
              <table className="table table-sm table-bordered bg-white">
                <tbody>
                  <tr>
                    <td className="table-secondary w-25 text-dark">
                      Nombre del permiso
                    </td>
                    {permiso.nombre ? (
                      <td>{permiso.nombre}</td>
                    ) : permiso.hasOwnProperty("nombre") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">Código</td>
                    {permiso.id ? (
                      <td>CL-{permiso.id}</td>
                    ) : permiso.hasOwnProperty("id") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Plazo de tramitación (Días hábiles)
                    </td>
                    {permiso.dias_ley_19880 ||
                    permiso.dias_legal_tramitacion ? (
                      <td>
                        {permiso.dias_legal_tramitacion
                          ? permiso.dias_legal_tramitacion
                          : permiso.dias_ley_19880}
                      </td>
                    ) : permiso.hasOwnProperty("dias_ley_19880") ||
                      permiso.hasOwnProperty("dias_legal_tramitacion") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">Crítico</td>
                    {permiso.hasOwnProperty("critico") ? (
                      <td>{permiso.critico ? "SÍ" : "NO"}</td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Descripción del permiso
                    </td>
                    {permiso.descripcion ? (
                      <td>
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            permiso.descripcion
                          )}
                        />
                      </td>
                    ) : permiso.hasOwnProperty("descripcion") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Costo del permiso
                    </td>
                    {permiso.costo ? (
                      <td>
                        {permiso.costo.nombre}
                        <br />
                        {permiso.costo.descripcion}
                      </td>
                    ) : permiso.hasOwnProperty("costo") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Clasificación del permiso
                    </td>
                    {permiso.clasificacion ? (
                      <td>
                        {permiso.clasificacion.nombre}
                        <br />
                        {permiso.clasificacion.descripcion}
                      </td>
                    ) : permiso.hasOwnProperty("clasificacion") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Tipo del permiso
                    </td>
                    {permiso.tipo_display ? (
                      <td>{permiso.tipo_display}</td>
                    ) : permiso.hasOwnProperty("tipo_display") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">Titularidad</td>
                    {permiso.titularidad && permiso.titularidad.length > 0 ? (
                      <td>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <thead>
                              <tr>
                                <th className="text-center w-5">ID</th>
                                <th className="w-45">Nombre</th>
                                <th className="w-50">Descripción</th>
                              </tr>
                            </thead>
                            <tbody>
                              {permiso.titularidad.map((titularidad, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>{titularidad.nombre}</td>
                                  <td>{titularidad.descripcion}</td>
                                </tr>
                              ))}
                              ``
                            </tbody>
                          </table>
                        </div>
                      </td>
                    ) : permiso.hasOwnProperty("titularidad") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">Naturaleza</td>
                    {permiso.naturaleza && permiso.naturaleza.length > 0 ? (
                      <td>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <thead>
                              <tr>
                                <th className="text-center w-5">ID</th>
                                <th className="w-45">Nombre</th>
                                <th className="w-50">Descripción</th>
                              </tr>
                            </thead>
                            <tbody>
                              {permiso.naturaleza.map((naturaleza, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>{naturaleza.nombre}</td>
                                  <td>{naturaleza.descripcion}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    ) : permiso.hasOwnProperty("naturaleza") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Elemento regulado
                    </td>
                    {permiso.elemento_regulado &&
                    permiso.elemento_regulado.length > 0 ? (
                      <td>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <thead>
                              <tr>
                                <th className="text-center w-5">ID</th>
                                <th className="w-45">Nombre</th>
                                <th className="w-50">Descripción</th>
                              </tr>
                            </thead>
                            <tbody>
                              {permiso.elemento_regulado.map(
                                (elemento_regulado, x) => (
                                  <tr key={x}>
                                    <td className="text-center">{x + 1}</td>
                                    <td>{elemento_regulado.nombre}</td>
                                    <td>{elemento_regulado.descripcion}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    ) : permiso.hasOwnProperty("elemento_regulado") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  {/* <tr>
                    <td className="table-secondary text-dark">Permisos requeridos</td>
                    {permiso.permisos_requisitos && permiso.permisos_requisitos.length > 0 ?
                      <td>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <thead>
                              <tr>
                                <th className="text-center w-5">ID</th>
                                <th className="w-45">Nombre</th>
                                <th className="w-50">Descripción</th>
                              </tr>
                            </thead>
                            <tbody>
                              {permiso.permisos_requisitos.map((permisos_requisitos, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>{permisos_requisitos.nombre}</td>
                                  <td><div dangerouslySetInnerHTML={createMarkup(permisos_requisitos.descripcion)} /></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                      :
                      permiso.hasOwnProperty("permisos_requisitos") ?
                        <td><span className="badge badge-dark font-9">No Aplica</span></td>
                        :
                        <td><span className="badge badge-warning font-9"><i data-tip data-tooltip-id='restriccion_acceso' className="fa fa-question"></i></span></td>}
                  </tr> */}
                  <tr>
                    <td className="table-secondary text-dark">
                      Grupo de permiso
                    </td>
                    {permiso.grupo_permiso &&
                    permiso.grupo_permiso.length > 0 ? (
                      <td>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <thead>
                              <tr>
                                <th className="text-center w-5">ID</th>
                                <th className="w-45">Nombre</th>
                              </tr>
                            </thead>
                            <tbody>
                              {permiso.grupo_permiso.map((grupo_permiso, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>{grupo_permiso.nombre}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    ) : permiso.hasOwnProperty("grupo_permiso") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Información del permiso ambiental sectorial
                    </td>
                    <td>
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered bg-white">
                          <thead>
                            <tr>
                              <th className="w-40">Tipo de PAS</th>
                              <th className="w-30">DS 95 RSEIA</th>
                              <th className="w-30">DS 30 RSEIA</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ver_pas &&
                            permiso.pas &&
                            permiso.pas.length > 0 ? (
                              permiso.pas.map((pas, x) => (
                                <tr key={x}>
                                  <td>{pas.tipo}</td>
                                  <td>
                                    {pas.ds_95_rseia
                                      ? pas.ds_95_rseia
                                      : "No aplica"}
                                  </td>
                                  <td>
                                    {pas.ds_30_rseia
                                      ? pas.ds_30_rseia
                                      : "No aplica"}
                                  </td>
                                </tr>
                              ))
                            ) : permiso.hasOwnProperty("pas") ? (
                              <tr>
                                <td>
                                  <span className="badge badge-dark font-9">
                                    No Aplica
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-dark font-9">
                                    No Aplica
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-dark font-9">
                                    No Aplica
                                  </span>
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td>
                                  <span className="badge badge-warning font-9">
                                    <i
                                      data-tip
                                      data-tooltip-id="restriccion_acceso"
                                      className="fa fa-question"
                                    ></i>
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-warning font-9">
                                    <i
                                      data-tip
                                      data-tooltip-id="restriccion_acceso"
                                      className="fa fa-question"
                                    ></i>
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-warning font-9">
                                    <i
                                      data-tip
                                      data-tooltip-id="restriccion_acceso"
                                      className="fa fa-question"
                                    ></i>
                                  </span>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    {permiso.is_pas ? (
                      <td className="table-secondary text-dark">
                        Permiso sectorial relacionado
                      </td>
                    ) : (
                      <td className="table-secondary text-dark">
                        Permiso ambiental sectorial relacionado
                      </td>
                    )}
                    <td>
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered bg-white">
                          <thead>
                            <tr>
                              <th className="text-center w-5">ID</th>
                              <th className="w-45">Nombre del permiso</th>
                              <th>Descripción del permiso</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ver_pas &&
                            permiso.ps_pas &&
                            permiso.ps_pas.length > 0 ? (
                              permiso.ps_pas.map((ps, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>{ps.nombre}</td>
                                  <td>
                                    <div
                                      dangerouslySetInnerHTML={createMarkup(
                                        ps.descripcion
                                      )}
                                    />
                                  </td>
                                </tr>
                              ))
                            ) : permiso.hasOwnProperty("ps_pas") ? (
                              <tr>
                                <td>
                                  <span className="badge badge-dark font-9">
                                    No Aplica
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-dark font-9">
                                    No Aplica
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-dark font-9">
                                    No Aplica
                                  </span>
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td>
                                  <span className="badge badge-warning font-9">
                                    <i
                                      data-tip
                                      data-tooltip-id="restriccion_acceso"
                                      className="fa fa-question"
                                    ></i>
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-warning font-9">
                                    <i
                                      data-tip
                                      data-tooltip-id="restriccion_acceso"
                                      className="fa fa-question"
                                    ></i>
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-warning font-9">
                                    <i
                                      data-tip
                                      data-tooltip-id="restriccion_acceso"
                                      className="fa fa-question"
                                    ></i>
                                  </span>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Norma fundante que origina el permiso
                    </td>
                    {permiso.normasfundantes &&
                    permiso.normasfundantes.length > 0 ? (
                      <td>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <thead>
                              <tr>
                                <th className="text-center w-5">ID</th>
                                <th>Título</th>
                                <th>Fecha publicación</th>
                                <th className="text-center w-10">Acción</th>
                              </tr>
                            </thead>
                            <tbody>
                              {permiso.normasfundantes.map((norma, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>
                                    {norma.ley.tipos[0].tipo_display}{" "}
                                    {norma.ley.tipos[0].numero} |{" "}
                                    {norma.ley.titulo}, {norma.nombre}
                                  </td>
                                  <td>{norma.ley.fecha_publicacion}</td>
                                  <td className="text-center">
                                    <div className="btn-group">
                                      <a
                                        data-tip
                                        data-tooltip-id="url_ley"
                                        className="btn btn-primary btn-xs"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={norma.ley.url_ley}
                                      >
                                        <i className="fa fa-gavel"></i>
                                      </a>
                                      <Tooltip
                                        id="url_ley"
                                        aria-haspopup="true"
                                      >
                                        Ver en Ley Chile toda la norma fundante
                                      </Tooltip>
                                      <a
                                        data-tip
                                        data-tooltip-id="url_parte"
                                        className="btn btn-info btn-xs"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={norma.url_parte}
                                      >
                                        <i className="fa fa-gavel"></i>
                                      </a>
                                      <Tooltip
                                        id="url_parte"
                                        aria-haspopup="true"
                                      >
                                        Ver en Ley Chile solo el detalle
                                        (numero, articulo, etc) de la norma
                                        fundante
                                      </Tooltip>
                                      <a
                                        data-tip
                                        data-tooltip-id="pdf_ley"
                                        className="btn btn-danger btn-xs"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={norma.ley.url_pdf}
                                      >
                                        <i className="fa fa-file-pdf"></i>
                                      </a>
                                      <Tooltip
                                        id="pdf_ley"
                                        aria-haspopup="true"
                                      >
                                        Descargar en formato PDF la norma
                                        fundante
                                      </Tooltip>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    ) : permiso.hasOwnProperty("normasfundantes") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Etapa / Subetapa
                    </td>
                    <td>
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered bg-white">
                          <thead>
                            <tr>
                              <th className="text-center w-5">ID</th>
                              <th className="w-45">Etapa</th>
                              <th className="w-50">Subetapa</th>
                            </tr>
                          </thead>
                          <tbody>
                            {permiso.subetapa && permiso.subetapa.length > 0 ? (
                              permiso.subetapa.map((subetapa, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>{subetapa.etapa.nombre}</td>
                                  <td>{subetapa.nombre}</td>
                                </tr>
                              ))
                            ) : permiso.hasOwnProperty("subetapa") ? (
                              <tr>
                                <td>
                                  <span className="badge badge-dark font-9">
                                    No Aplica
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-dark font-9">
                                    No Aplica
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-dark font-9">
                                    No Aplica
                                  </span>
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td>
                                  <span className="badge badge-warning font-9">
                                    <i
                                      data-tip
                                      data-tooltip-id="restriccion_acceso"
                                      className="fa fa-question"
                                    ></i>
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-warning font-9">
                                    <i
                                      data-tip
                                      data-tooltip-id="restriccion_acceso"
                                      className="fa fa-question"
                                    ></i>
                                  </span>
                                </td>
                                <td>
                                  <span className="badge badge-warning font-9">
                                    <i
                                      data-tip
                                      data-tooltip-id="restriccion_acceso"
                                      className="fa fa-question"
                                    ></i>
                                  </span>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Autoridad que otorga el permiso
                    </td>
                    {permiso.autoridad && permiso.autoridad.length > 0 ? (
                      <td>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <thead>
                              <tr>
                                <th className="text-center w-5">ID</th>
                                <th className="w-20">Autoridad</th>
                                <th className="w-20">Ministerio</th>
                                <th>
                                  Enlaces de apoyo para la gestión del permiso
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {permiso.autoridad.map((autoridad, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>
                                    {autoridad.nombre} ({autoridad.sigla})
                                  </td>
                                  <td>{autoridad.ministerio.nombre}</td>
                                  <td>
                                    <div className="table-responsive">
                                      <table className="table table-sm table-bordered bg-white">
                                        <thead>
                                          <tr>
                                            <th className="text-center w-5">
                                              ID
                                            </th>
                                            <th>Nombre del enlace</th>
                                            <th className="text-center w-5">
                                              Acción
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {autoridad.enlaces.length > 0 ? (
                                            autoridad.enlaces.map(
                                              (enlace, x) => (
                                                <tr key={x}>
                                                  <td className="text-center">
                                                    {x + 1}
                                                  </td>
                                                  <td>{enlace.nombre}</td>
                                                  {is_externo ? (
                                                    <td className="text-center">
                                                      <a
                                                        data-tip
                                                        data-tooltip-id="url_enlace"
                                                        className="btn btn-primary btn-xs"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={enlace.url}
                                                      >
                                                        <i className="fa fa-link"></i>
                                                      </a>
                                                      <Tooltip
                                                        id="url_enlace"
                                                        aria-haspopup="true"
                                                      >
                                                        Ver enlace de la
                                                        autoridad
                                                      </Tooltip>
                                                    </td>
                                                  ) : (
                                                    <td className="text-center">
                                                      <span
                                                        className="badge badge-warning font-9"
                                                        data-tip
                                                        data-tooltip-id="restriccion_acceso"
                                                      >
                                                        <i className="fa fa-question"></i>
                                                      </span>
                                                    </td>
                                                  )}
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr className="text-center">
                                              <td colSpan="3">
                                                No existen enlaces para la
                                                autoridad listada
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    ) : permiso.hasOwnProperty("autoridad") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Enlace portal Chile Atiende
                    </td>
                    {permiso.link_chileatiende &&
                    permiso.link_chileatiende.length > 0 ? (
                      <td>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <thead>
                              <tr>
                                <th className="text-center w-5">ID</th>
                                <th className="text-center">Acción</th>
                              </tr>
                            </thead>
                            <tbody>
                              {permiso.link_chileatiende.map((link, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>
                                    <a
                                      data-tip
                                      data-tooltip-id="url_chileatiende"
                                      className="btn btn-primary btn-xs"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={link.url}
                                    >
                                      Ir a Chile Atiende{" "}
                                      <i className="fa fa-angle-right"></i>
                                    </a>
                                    <Tooltip
                                      id="url_chileatiende"
                                      aria-haspopup="true"
                                    >
                                      Ver enlace del portal de Chile Atiende
                                    </Tooltip>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    ) : permiso.hasOwnProperty("link_chileatiende") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Guías oficiales para la gestión del permiso
                    </td>
                    {permiso.archivo && permiso.archivo.length > 0 ? (
                      <td>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <thead>
                              <tr>
                                <th className="text-center w-5">ID</th>
                                <th>Nombre de la guía</th>
                                <th className="w-15">Tipo</th>
                                <th className="text-center w-7">Ver archivo</th>
                              </tr>
                            </thead>
                            <tbody>
                              {permiso.archivo.map((archivo, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>{archivo.nombre}</td>
                                  <td>{archivo.tipo_display}</td>
                                  {is_externo ? (
                                    <td className="text-center">
                                      <button
                                        data-tip
                                        data-tooltip-id="url_archivo"
                                        type="button"
                                        onClick={this.verArchivo.bind(
                                          this,
                                          archivo.id,
                                          permiso.id
                                        )}
                                        className="btn btn-danger btn-xs"
                                        target="_blank"
                                      >
                                        <i className="fa fa-file-pdf"></i>
                                      </button>
                                      <Tooltip
                                        id="url_archivo"
                                        aria-haspopup="true"
                                      >
                                        Ver archivo y/o descargar
                                      </Tooltip>
                                    </td>
                                  ) : (
                                    <td className="text-center">
                                      <span
                                        className="badge badge-warning font-9"
                                        data-tip
                                        data-tooltip-id="restriccion_acceso"
                                      >
                                        <i className="fa fa-question"></i>
                                      </span>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    ) : permiso.hasOwnProperty("archivo") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">Etiquetas</td>
                    {permiso.etiqueta && permiso.etiqueta.length > 0 ? (
                      <td>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <thead>
                              <tr>
                                <th className="text-center w-5">ID</th>
                                <th>Nombre</th>
                              </tr>
                            </thead>
                            <tbody>
                              {permiso.etiqueta.map((etiqueta, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>{etiqueta.nombre}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    ) : permiso.hasOwnProperty("etiqueta") ? (
                      <td>
                        <span className="badge badge-dark font-9">
                          No Aplica
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="badge badge-warning font-9">
                          <i
                            data-tip
                            data-tooltip-id="restriccion_acceso"
                            className="fa fa-question"
                          ></i>
                        </span>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default FichaPermiso;
