import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { guardarMedidaProvisional } from "../../../redux/actions/vigilancias/medidas/action";
import { Eliminar as EliminarFavorito } from "../favoritos";
import { TablaPequena as Hechos } from "../hechos";
import { TablaPequena as Titulares } from "../titulares";
import { TablaPequena as UnidadesFiscalizables } from "../unidades";

export class Tabla extends Component {
  setMedida = (id) => {
    const { guardarMedidaProvisional } = this.props;
    guardarMedidaProvisional(id, "normal");
  };

  render = () => {
    const { medidas_provisionales, eliminar_favorito } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th className="w-8">Expediente</th>
              <th className="text-center w5">
                Fecha
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="fecha_titulo"
                ></i>
                <Tooltip id="fecha_titulo" aria-haspopup="true">
                  Fecha de Creación
                </Tooltip>
              </th>
              <th>Hechos infraccionales</th>
              <th className="w-22">Titular</th>
              <th className="w-22">Unidad Fiscalizable</th>
              <th className="text-center w-3">Acción</th>
            </tr>
          </thead>
          <tbody>
            {medidas_provisionales &&
            medidas_provisionales.result &&
            medidas_provisionales.result.length > 0 ? (
              medidas_provisionales.result.map((medida, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {medidas_provisionales.page > 1
                      ? (medidas_provisionales.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td>
                    <a
                      href={medida.url_snifa}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {medida.expediente} <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td className="text-center">{medida.fecha}</td>
                  <td>
                    <Hechos hechos={medida.hechos} />
                  </td>
                  <td>
                    <Titulares titulares={medida.titulares} />
                  </td>
                  <td>
                    <UnidadesFiscalizables
                      unidades_fiscalizables={medida.unidad_fiscalizable}
                    />
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {eliminar_favorito && (
                        <EliminarFavorito
                          tipo="medida_provisional"
                          id={medida.id}
                        />
                      )}
                      <Link
                        to="/compliance-analytics/medida-provisional"
                        className="btn btn-soft-dark"
                        onClick={this.setMedida.bind(this, medida.id)}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="8">
                  No existen medidas provisionales
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarMedidaProvisional: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarMedidaProvisional }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
