import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { guardarTitular } from "../../../redux/actions/vigilancias/titulares/action";

export class Tabla extends Component {
  setTitular = (id) => {
    const { guardarTitular } = this.props;
    guardarTitular(id);
  };

  render = () => {
    const { titulares } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre</th>
              <th className="w-7">RUT</th>
              <th className="text-center w-10">Expedientes SEIA</th>
              <th className="text-center w-10">Pertinencias</th>
              <th className="text-center w-12">Unidades fiscalizables</th>
              <th className="text-center w-3">Acción</th>
            </tr>
          </thead>
          <tbody>
            {titulares && titulares.result && titulares.result.length > 0 ? (
              titulares.result.map((titular, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {titulares.page > 1
                      ? (titulares.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td className="text-uppercase">
                    {titular.nombre ? titular.nombre : "S/I"}
                  </td>
                  <td>{titular.rut}</td>
                  <td className="text-center">
                    {titular.expedientes ? titular.expedientes : 0}
                  </td>
                  <td className="text-center">
                    {titular.pertinencias ? titular.pertinencias : 0}
                  </td>
                  <td className="text-center">
                    {titular.unidades ? titular.unidades : 0}
                  </td>
                  <td className="text-center">
                    <Link
                      to="/compliance-analytics/titular"
                      className="btn btn-soft-dark"
                      onClick={this.setTitular.bind(this, titular.id)}
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="8">
                  No existen titulares
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarTitular: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarTitular }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
