export const TEAMS_PROCEDIMIENTOS_OBTENER = "TEAMS_PROCEDIMIENTOS_OBTENER";
export const TEAMS_PROCEDIMIENTOS_FALLIDO = "TEAMS_PROCEDIMIENTOS_FALLIDO";
export const TEAMS_PROCEDIMIENTOS_FILTROS_OBTENER =
  "TEAMS_PROCEDIMIENTOS_FILTROS_OBTENER";
export const TEAMS_PROCEDIMIENTOS_FILTROS_FALLIDO =
  "TEAMS_PROCEDIMIENTOS_FILTROS_FALLIDO";
export const TEAMS_PROCEDIMIENTOS_FILTROS_INPUT =
  "TEAMS_PROCEDIMIENTOS_FILTROS_INPUT";
export const TEAMS_LIMPIAR_PROCEDIMIENTOS_FILTROS =
  "TEAMS_LIMPIAR_PROCEDIMIENTO_FILTROS";
