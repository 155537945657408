import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { guardarExpediente } from "../../../redux/actions/vigilancias/expedientes/action";
import { Eliminar as EliminarFavorito } from "../favoritos";
import { TablaPequena } from "../titulares";

export class Tabla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_tags: 0,
    };
  }

  setExpediente = (id, componente) => {
    const { guardarExpediente } = this.props;
    guardarExpediente({ id: id, componente: componente });
  };

  setExpedienteTag = (id) => {
    this.setState({
      is_tags: id,
    });
  };

  render = () => {
    const { expedientes, componente, eliminar_favorito } = this.props;
    const { is_tags } = this.state;

    return (
      <div className="table-responsive">
        <Tooltip id="detalle_expediente" aria-haspopup="true">
          Ver detalle del expediente SEIA
        </Tooltip>
        <table className="table table-bordered table-hover table-sm">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre del proyecto</th>
              <th className="w-22">Fechas</th>
              <th className="w-12">Titular</th>
              <th className="text-center w-4">
                Tipo
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="inst_tipo"
                ></i>
                <Tooltip id="inst_tipo" aria-haspopup="true">
                  Tipo de instrumento del proyecto
                </Tooltip>
              </th>
              <th className="text-center w-6">Estado</th>
              <th className="text-center w-6">
                Inversión
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="inst_inversion"
                ></i>
                <Tooltip id="inst_inversion" aria-haspopup="true">
                  Inversión en millones de dolares del proyecto
                </Tooltip>
              </th>
              <th className="w-10">Región</th>
              <th className="w-3 text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            {expedientes &&
            expedientes.result &&
            expedientes.result.length > 0 ? (
              expedientes.result.map((expediente, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {expedientes.page > 1
                      ? (expedientes.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td>
                    {expediente.sociedad_quinenco && (
                      <>
                        <span className="badge badge-danger badge-md">
                          {expediente.sociedad_quinenco}
                        </span>{" "}
                      </>
                    )}
                    <a
                      href={expediente.url_seia}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-uppercase"
                    >
                      {expediente.nombre} <i className="fa fa-angle-right"></i>
                    </a>
                    {expediente.tag &&
                      expediente.tag.length > 0 &&
                      (is_tags === expediente.id ? (
                        <div className="mt-2">
                          {expediente.tag.map((tag, z) => (
                            <span
                              key={z}
                              className="badge badge-soft-danger p-1 me-1 font-12"
                            >
                              #{tag}
                            </span>
                          ))}
                        </div>
                      ) : (
                        <div className="mt-1">
                          <button
                            onClick={this.setExpedienteTag.bind(
                              this,
                              expediente.id
                            )}
                            type="button"
                            className="btn btn-sm btn-outline-danger btn-tags"
                          >
                            <i className="fa fa-hashtag"></i> TAG
                          </button>
                        </div>
                      ))}
                  </td>
                  <td>
                    <span>
                      <b>Último Movimiento en el Expediente:</b>{" "}
                      {expediente.fecha}
                    </span>
                    {expediente.fecha_ultimo_movimiento &&
                      expediente.nombre_ultimo_movimiento && (
                        <div className="mb-0">
                          <b>Último Movimiento en Folios:</b>{" "}
                          {expediente.fecha_ultimo_movimiento}
                          <div className="mb-0">
                            <span className="badge badge-danger font-11">
                              {expediente.nombre_ultimo_movimiento
                                ? expediente.nombre_ultimo_movimiento
                                : "S/I"}
                            </span>
                          </div>
                        </div>
                      )}
                  </td>
                  <td>
                    {expediente.titular && (
                      <TablaPequena titulares={[expediente.titular]} />
                    )}
                  </td>
                  <td className="text-center">
                    <span
                      className={`badge badge-${
                        expediente.tipo_display === "DIA" ? "warning" : "info"
                      } font-13`}
                    >
                      {expediente.tipo_display}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="badge badge-dark font-13 text-center">
                      {expediente.estado}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="badge badge-success font-13">
                      ${expediente.inversion.toString().replace(".", ",")}
                    </span>
                  </td>
                  <td>
                    <span className="badge badge-primary font-11">
                      {expediente.region_display}
                    </span>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {eliminar_favorito && (
                        <EliminarFavorito
                          tipo="expediente"
                          id={expediente.id}
                        />
                      )}
                      <Link
                        to="/compliance-analytics/expediente"
                        className="btn btn-soft-dark"
                        data-tip
                        data-tooltip-id="detalle_expediente"
                        onClick={this.setExpediente.bind(
                          this,
                          expediente.id,
                          componente
                        )}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="11">
                  No existen Expedientes SEIA
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarExpediente: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarExpediente }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
