export const NORMA_FAVORITA_CREAR_OBTENER = "NORMA_FAVORITA_CREAR_OBTENER";
export const NORMA_FAVORITA_CREAR_FALLIDO = "NORMA_FAVORITA_CREAR_FALLIDO";
export const MENSAJES_LIMPIAR = "MENSAJES_LIMPIAR";
export const NORMAS_FAVORITAS_OBTENER = "NORMAS_FAVORITAS_OBTENER";
export const NORMAS_FAVORITAS_FALLIDO = "NORMAS_FAVORITAS_FALLIDO";
export const CARGANDO_LIMPIAR_LEYES = "CARGANDO_LIMPIAR_LEYES";
export const NORMA_GUARDAR = "NORMA_GUARDAR";
export const NORMA_FAVORITA_ELIMINAR_OBTENER =
  "NORMA_FAVORITA_ELIMINAR_OBTENER";
export const NORMA_FAVORITA_ELIMINAR_FALLIDO =
  "NORMA_FAVORITA_ELIMINAR_FALLIDO";
export const TITULO_SELECCIONADO = "TITULO_SELECCIONADO";
export const ARTICULO_TAREA_SELECCIONADO = "ARTICULO_TAREA_SELECCIONADO";
export const NORMAS_FALLIDO = "NORMAS_FALLIDO";
export const NORMAS_OBTENER = "NORMAS_OBTENER";
export const NORMA_OBTENER = "NORMA_OBTENER";
export const NORMA_FALLIDO = "NORMA_FALLIDO";
export const NORMAS_FILTROS_INPUT = "NORMAS_FILTROS_INPUT";
export const NORMAS_FILTROS_OBTENER = "NORMAS_FILTROS_OBTENER";
export const NORMAS_FILTROS_FALLIDO = "NORMAS_FILTROS_FALLIDO";
export const REDIRECT_FAV = "REDIRECT_FAV";
export const LIMPIAR_NORMAS_FILTROS = "LIMPIAR_NORMAS_FILTROS";
export const NORMAS_PROMULGADAS_FALLIDO = "NORMAS_PROMULGADAS_FALLIDO";
export const NORMAS_PROMULGADAS_OBTENER = "NORMAS_PROMULGADAS_OBTENER";
export const NORMAS_PUBLICADAS_FALLIDO = "NORMAS_PUBLICADAS_FALLIDO";
export const NORMAS_PUBLICADAS_OBTENER = "NORMAS_PUBLICADAS_OBTENER";
export const NORMAS_ESTADISTICAS_OBTENER = "NORMAS_ESTADISTICAS_OBTENER";
export const NORMAS_ESTADISTICAS_FALLIDO = "NORMAS_ESTADISTICAS_FALLIDO";
export const NORMA_ALERTAS_OBTENER = "NORMA_ALERTAS_OBTENER";
export const NORMA_ALERTAS_FALLIDO = "NORMA_ALERTAS_FALLIDO";
export const NORMA_ALERTA_ELIMINAR_OBTENER = "NORMA_ALERTA_ELIMINAR_OBTENER";
export const NORMA_ALERTA_ELIMINAR_FALLIDO = "NORMA_ALERTA_ELIMINAR_FALLIDO";
export const NORMA_ALERTA_CREAR_OBTENER = "NORMA_ALERTA_CREAR_OBTENER";
export const NORMA_ALERTA_CREAR_FALLIDO = "NORMA_ALERTA_CREAR_FALLIDO";
