import {
  AUTENTIFICACION_FALLIDO,
  CERRAR_SESION_CORRECTO,
  CONTRASENATOKEN_FALLIDO,
  CONTRASENATOKEN_NUEVA,
  CONTRASENA_FALLIDO,
  CONTRASENA_NUEVA,
  INICIO_SESION_CORRECTO,
  INICIO_SESION_FALLIDO,
  MENSAJES_LIMPIAR,
  REGISTRATE_CORRECTO,
  REGISTRATE_FALLIDO,
  REGISTRO_CORRECTO,
  REGISTRO_FALLIDO,
  SIDEBAR_ESTADO,
  TOKEN_CORRECTO,
  TOKEN_FALLIDO,
  TOKEN_REGISTRO_CORRECTO,
  TOKEN_REGISTRO_FALLIDO,
  USUARIO_CARGADO,
  USUARIO_CARGANDO,
} from "../actions/auth/types";

const initialState = {
  token: localStorage.getItem("token"),
  is_authenticated: false,
  is_loading_auth: true,
  is_token_valido: false,
  siderbar_estado: false,
  user: {},
  errores: {},
  errores_pass: {},
  guardar: {},
  guardar_pass: {},
  guardar_registro: {},
  errores_registro: {},
  registro_valido: {},
  contrasena_nueva: {},
  contrasena_nueva_error: {},
  guardar_registrate: {},
  errores_registrate: {},
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case USUARIO_CARGANDO:
      return {
        ...state,
        is_loading_auth: true,
        errores: {},
      };
    case USUARIO_CARGADO:
      return {
        ...state,
        is_authenticated: true,
        is_loading_auth: false,
        user: action.payload,
        errores: {},
      };
    case REGISTRATE_CORRECTO:
      return {
        ...state,
        guardar_registrate: action.payload,
        errores_registrate: {},
      };
    case REGISTRATE_FALLIDO:
      return {
        ...state,
        guardar_registrate: {},
        errores_registrate: action.payload,
      };
    case REGISTRO_CORRECTO:
      return {
        ...state,
        guardar_registro: action.payload,
        errores_registro: {},
      };
    case REGISTRO_FALLIDO:
      return {
        ...state,
        guardar_registro: {},
        errores_registro: action.payload,
      };
    case INICIO_SESION_CORRECTO:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        is_authenticated: true,
        is_loading_auth: false,
        errores: {},
        user: action.payload.user,
      };
    case AUTENTIFICACION_FALLIDO:
    case INICIO_SESION_FALLIDO:
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
        is_authenticated: false,
        is_loading_auth: false,
        errores: action.payload,
      };
    case CERRAR_SESION_CORRECTO:
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
        is_authenticated: false,
        is_loading_auth: false,
        errores: {},
      };
    case CONTRASENATOKEN_NUEVA:
      return {
        ...state,
        guardar_pass: action.payload,
        errores_pass: {},
      };
    case CONTRASENATOKEN_FALLIDO:
      return {
        ...state,
        errores_pass: action.payload,
        guardar_pass: {},
      };
    case CONTRASENA_NUEVA:
      return {
        ...state,
        contrasena_nueva: action.payload,
        contrasena_nueva_error: {},
      };
    case CONTRASENA_FALLIDO:
      return {
        ...state,
        contrasena_nueva_error: action.payload,
        contrasena_nueva: {},
      };
    case TOKEN_CORRECTO:
      return {
        ...state,
        is_token_valido: action.payload.validacion,
      };
    case TOKEN_FALLIDO:
      return {
        ...state,
        is_token_valido: false,
      };
    case TOKEN_REGISTRO_FALLIDO:
      return {
        ...state,
        registro_valido: {},
      };
    case TOKEN_REGISTRO_CORRECTO:
      return {
        ...state,
        registro_valido: action.payload,
      };
    case SIDEBAR_ESTADO:
      return {
        ...state,
        siderbar_estado:
          action.payload === state.siderbar_estado ? false : action.payload,
      };
    case MENSAJES_LIMPIAR:
      return {
        ...state,
        errores: {},
        errores_pass: {},
        guardar: {},
        guardar_pass: {},
        guardar_registro: {},
        errores_registro: {},
        registro_valido: {},
        contrasena_nueva: {},
        contrasena_nueva_error: {},
        guardar_registrate: {},
        errores_registrate: {},
      };
    default:
      return state;
  }
}
