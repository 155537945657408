import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";

import { obtenerProyectos } from "../../../redux/actions/tareas/proyectos/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { withRouter } from "../../general/with_router";
import { Nueva as TareaNueva } from "../../tareas/tarea/";

export class Tarea extends Component {
  componentDidMount = () => {
    const { obtenerProyectos } = this.props;
    obtenerProyectos();
  };

  redirectVista = (url) => {
    this.props.navigate(url);
  };

  render = () => {
    const { permiso_tarea_seleccionado, inteligencia_seleccionada, proyectos } =
      this.props;

    if (
      Object.entries(inteligencia_seleccionada).length === 0 &&
      Object.entries(permiso_tarea_seleccionado).length === 0
    ) {
      return <Navigate to="/permitting-target/permisoteca/permisos" />;
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="permitting_permisoteca" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 text-end">
                <div className="page-title-box">
                  <Link
                    to="/permitting-target/permisoteca/permiso"
                    className="btn btn-white  waves-lights"
                  >
                    <i className="fa fa-angle-left"></i> Cancelar y volver al
                    permiso
                  </Link>
                </div>
              </div>
              {proyectos && proyectos.length > 0 ? (
                <TareaNueva
                  titulo="CREAR TAREA"
                  subtitulo={`Permiso ${permiso_tarea_seleccionado.nombre}`}
                  nombre={permiso_tarea_seleccionado.nombre}
                  btnCancelar={false}
                  permiso={permiso_tarea_seleccionado}
                  inteligencia={inteligencia_seleccionada}
                  redirectVista={this.redirectVista}
                />
              ) : (
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header bg-danger text-white">
                      <h5>
                        Hemos detectado que no tienes un proyecto creado en el
                        módulo QUALITY TASKING
                      </h5>
                    </div>
                    <div className="card-body">
                      <p className="font-12">
                        Para crear una tarea presiona el siguiente boton:
                      </p>
                      <p>
                        <Link
                          className="btn btn-danger"
                          to="/quality-tasking/proyectos"
                        >
                          Crear Proyecto <i className="fa fa-angle-right"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Tarea.propTypes = {
  obtenerProyectos: PropTypes.func.isRequired,
  permiso_tarea_seleccionado: PropTypes.object.isRequired,
  inteligencia_seleccionada: PropTypes.object.isRequired,
  proyectos: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  permiso_tarea_seleccionado: state.permitting.permiso_tarea_seleccionado,
  inteligencia_seleccionada: state.permitting.inteligencia_seleccionada,
  proyectos: state.tareas.proyectos,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerProyectos }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tarea));
