import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  obtenerPermisos,
  obtenerPermisosFiltros,
} from "../../../redux/actions/permitting/action";

export class Permisos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre: "",
      filtro_descripcion: "",
      filtro_critico: "",
      filtro_tipo: "",
      filtro_costo: "",
      filtro_clasificacion: "",
      filtro_autoridad: "",
      filtro_etapa: "",
      filtro_titularidad: "",
      filtro_naturaleza: "",
      filtro_elemento_regulado: "",
      filtro_grupo_permiso: "",
      filtro_etiqueta: "",
      filtro_pas: "",
      filtro_id: "",
      filtro_familia: "",
      filtros_avanzandos: false,
    };
  }

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  filtroPermisos = () => {
    const { obtenerPermisosFiltros, obtenerPermisos, consulta_seleccionada } =
      this.props;
    const {
      filtro_nombre,
      filtro_descripcion,
      filtro_critico,
      filtro_tipo,
      filtro_costo,
      filtro_clasificacion,
      filtro_autoridad,
      filtro_etapa,
      filtro_titularidad,
      filtro_naturaleza,
      filtro_elemento_regulado,
      filtro_grupo_permiso,
      filtro_etiqueta,
      filtro_pas,
      filtro_id,
      filtro_familia,
    } = this.state;
    if (
      filtro_nombre ||
      filtro_descripcion ||
      filtro_critico ||
      filtro_tipo ||
      filtro_costo ||
      filtro_clasificacion ||
      filtro_autoridad ||
      filtro_etapa ||
      filtro_titularidad ||
      filtro_naturaleza ||
      filtro_elemento_regulado ||
      filtro_grupo_permiso ||
      filtro_etiqueta ||
      filtro_pas ||
      filtro_id ||
      filtro_familia
    ) {
      obtenerPermisosFiltros({
        consulta: consulta_seleccionada,
        nombre: filtro_nombre ? filtro_nombre : null,
        descripcion: filtro_descripcion ? filtro_descripcion : null,
        critico: filtro_critico ? filtro_critico : null,
        tipo: filtro_tipo ? filtro_tipo : null,
        costo: filtro_costo ? filtro_costo : null,
        clasificacion: filtro_clasificacion ? filtro_clasificacion : null,
        autoridad: filtro_autoridad ? filtro_autoridad : null,
        etapa: filtro_etapa ? filtro_etapa : null,
        titularidad: filtro_titularidad ? filtro_titularidad : null,
        naturaleza: filtro_naturaleza ? filtro_naturaleza : null,
        elemento_regulado: filtro_elemento_regulado
          ? filtro_elemento_regulado
          : null,
        grupo_permiso: filtro_grupo_permiso ? filtro_grupo_permiso : null,
        etiqueta: filtro_etiqueta ? filtro_etiqueta : null,
        pas: filtro_pas ? filtro_pas : null,
        id: filtro_id ? filtro_id : null,
        familia: filtro_familia ? filtro_familia : null,
      });
    } else {
      obtenerPermisos();
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  borrarFiltros = () => {
    const { obtenerPermisos, consulta_seleccionada } = this.props;
    this.setState({
      filtro_nombre: "",
      filtro_descripcion: "",
      filtro_critico: "",
      filtro_tipo: "",
      filtro_costo: "",
      filtro_clasificacion: "",
      filtro_autoridad: "",
      filtro_etapa: "",
      filtro_titularidad: "",
      filtro_naturaleza: "",
      filtro_elemento_regulado: "",
      filtro_grupo_permiso: "",
      filtro_etiqueta: "",
      filtro_pas: "",
      filtro_id: "",
      filtro_familia: "",
    });
    obtenerPermisos({
      consulta: consulta_seleccionada,
    });
  };

  render = () => {
    const { permisos_filtro_error, filtros } = this.props;
    const {
      filtro_nombre,
      filtro_descripcion,
      filtro_critico,
      filtro_tipo,
      filtro_costo,
      filtro_clasificacion,
      filtro_autoridad,
      filtro_etapa,
      filtro_titularidad,
      filtro_elemento_regulado,
      filtro_etiqueta,
      filtro_pas,
      filtro_id,
      filtro_familia,
      filtros_avanzandos,
    } = this.state;

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">CONSOLA DE BÚSQUEDA Y FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4">
              <div className="form-group">
                <label>Código</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon3">
                      CL-
                    </span>
                  </div>
                  <input
                    type="text"
                    name="filtro_id"
                    placeholder="Ingrese un número"
                    value={filtro_id}
                    onChange={this.onChange}
                    className={`form-control ${
                      permisos_filtro_error &&
                      permisos_filtro_error.data &&
                      permisos_filtro_error.data.id
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.id &&
                    permisos_filtro_error.data.id.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4">
              <div className="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  name="filtro_nombre"
                  placeholder="Ingrese palabra"
                  value={filtro_nombre}
                  onChange={this.onChange}
                  className={`form-control ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.nombre
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.nombre &&
                  permisos_filtro_error.data.nombre.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Descripción</label>
                <input
                  type="text"
                  name="filtro_descripcion"
                  placeholder="Ingrese palabra"
                  value={filtro_descripcion}
                  onChange={this.onChange}
                  className={`form-control ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.descripcion
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.descripcion &&
                  permisos_filtro_error.data.descripcion.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Criticidad</label>
                <select
                  name="filtro_critico"
                  onChange={this.onChange}
                  value={filtro_critico}
                  className={`form-select ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.critico
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Selecciona una opción</option>
                  <option value="Si">Sí, permiso crítico</option>
                  <option value="No">No, permiso no crítico</option>
                </select>
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.critico &&
                  permisos_filtro_error.data.critico.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipo</label>
                <select
                  name="filtro_tipo"
                  onChange={this.onChange}
                  value={filtro_tipo}
                  className={`form-select ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.tipo
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Selecciona una opción</option>
                  {filtros &&
                    filtros.tipos &&
                    filtros.tipos.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                </select>
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.tipo &&
                  permisos_filtro_error.data.tipo.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Costo</label>
                <select
                  name="filtro_costo"
                  onChange={this.onChange}
                  value={filtro_costo}
                  className={`form-select ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.costo
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Selecciona una opción</option>
                  {filtros &&
                    filtros.costos &&
                    filtros.costos.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                </select>
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.costo &&
                  permisos_filtro_error.data.costo.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Clasificación</label>
                <select
                  name="filtro_clasificacion"
                  onChange={this.onChange}
                  value={filtro_clasificacion}
                  className={`form-select ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.clasificacion
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Selecciona una opción</option>
                  {filtros &&
                    filtros.clasificaciones &&
                    filtros.clasificaciones.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                </select>
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.clasificacion &&
                  permisos_filtro_error.data.clasificacion.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Autoridad</label>
                <select
                  name="filtro_autoridad"
                  onChange={this.onChange}
                  value={filtro_autoridad}
                  className={`form-select ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.autoridad
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Selecciona una opción</option>
                  {filtros &&
                    filtros.autoridades &&
                    filtros.autoridades.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                </select>
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.autoridad &&
                  permisos_filtro_error.data.autoridad.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Etapa</label>
                <select
                  name="filtro_etapa"
                  onChange={this.onChange}
                  value={filtro_etapa}
                  className={`form-select ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.etapa
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Selecciona una opción</option>
                  {filtros &&
                    filtros.etapas &&
                    filtros.etapas.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                </select>
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.etapa &&
                  permisos_filtro_error.data.etapa.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Titularidad</label>
                <select
                  name="filtro_titularidad"
                  onChange={this.onChange}
                  value={filtro_titularidad}
                  className={`form-select ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.titularidad
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Selecciona una opción</option>
                  {filtros &&
                    filtros.titularidades &&
                    filtros.titularidades.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                </select>
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.titularidad &&
                  permisos_filtro_error.data.titularidad.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Elemento regulado</label>
                <select
                  name="filtro_elemento_regulado"
                  onChange={this.onChange}
                  value={filtro_elemento_regulado}
                  className={`form-select ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.elemento_regulado
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Selecciona una opción</option>
                  {filtros &&
                    filtros.elementos_regulados &&
                    filtros.elementos_regulados.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                </select>
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.elemento_regulado &&
                  permisos_filtro_error.data.elemento_regulado.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Etiqueta</label>
                <select
                  name="filtro_etiqueta"
                  onChange={this.onChange}
                  value={filtro_etiqueta}
                  className={`form-select ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.etiqueta
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Selecciona una opción</option>
                  {filtros &&
                    filtros.etiquetas &&
                    filtros.etiquetas.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                </select>
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.etiqueta &&
                  permisos_filtro_error.data.etiqueta.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Permiso ambiental sectorial</label>
                <select
                  name="filtro_pas"
                  onChange={this.onChange}
                  value={filtro_pas}
                  className={`form-select ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.pas
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Selecciona una opción</option>
                  <option value="Si">Sí, es permiso ambiental sectorial</option>
                  <option value="No">No, es permiso ambiental sectorial</option>
                </select>
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.pas &&
                  permisos_filtro_error.data.pas.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Familia de permisos</label>
                <select
                  name="filtro_familia"
                  onChange={this.onChange}
                  value={filtro_familia}
                  className={`form-select ${
                    permisos_filtro_error &&
                    permisos_filtro_error.data &&
                    permisos_filtro_error.data.familia
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Selecciona una opción</option>
                  {filtros &&
                    filtros.familias &&
                    filtros.familias.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                </select>
                {permisos_filtro_error &&
                  permisos_filtro_error.data &&
                  permisos_filtro_error.data.familia &&
                  permisos_filtro_error.data.familia.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-sm-12 text-end">
              <div className="btn-group" role="group">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.filtroPermisos}
                >
                  <i className="fa fa-search"></i> Filtrar permisos
                </button>
                <button
                  type="button"
                  className="btn btn-soft-primary"
                  onClick={this.borrarFiltros}
                >
                  <i className="fa fa-trash"></i> Limpiar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

Permisos.propTypes = {
  obtenerPermisos: PropTypes.func.isRequired,
  obtenerPermisosFiltros: PropTypes.func.isRequired,
  filtros: PropTypes.object.isRequired,
  consulta_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  permisos_filtro_error: state.permitting.permisos_filtro_error,
  filtros: state.permitting.filtros,
  consulta_seleccionada: state.permitting.consulta_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerPermisos, obtenerPermisosFiltros },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Permisos);
