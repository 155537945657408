import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerProcedimiento } from "../../../redux/actions/vigilancias/procedimientos/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Guardar as GuardarFavorito } from "../favoritos/";
import { Tabs } from "./";

export class Procedimiento extends Component {
  componentDidMount = () => {
    const { obtenerProcedimiento, procedimiento_id } = this.props;
    if (procedimiento_id > 0) {
      obtenerProcedimiento({
        id: procedimiento_id,
      });
    }
  };

  render = () => {
    const {
      is_loading_vigilancias,
      procedimiento_id,
      procedimiento,
      procedimiento_componente,
    } = this.props;
    const redirect =
      procedimiento_componente === "normal"
        ? "/compliance-analytics/procedimientos/listado"
        : "/teams/procedimientos";
    const menu1 =
      procedimiento_componente === "normal"
        ? "vigilancias_procedimientos"
        : "teams";
    const menu2 =
      procedimiento_componente === "normal" ? "listado" : "procedimientos";

    if (procedimiento_id === 0) {
      return <Navigate to={redirect} />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Procedimiento Sancionatorio",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo el procedimniento sancionatorio, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1={menu1} menu_2={menu2} />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-bullhorn"></i> PROCEDIMIENTOS
                    SANCIONATORIOS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      Expediente {procedimiento.expediente}
                    </h5>
                    <div className="float-end btn-group">
                      <GuardarFavorito
                        tipo="procedimiento"
                        id={procedimiento.id}
                      />
                      <Link className="btn btn-primary" to={redirect}>
                        <i className="fa fa-angle-left"></i> Volver al listado
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <Tabs procedimientos={[procedimiento]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Procedimiento.propTypes = {
  obtenerProcedimiento: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  procedimiento: PropTypes.object.isRequired,
  procedimiento_id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  procedimiento: state.vigilancias.procedimiento,
  procedimiento_id: state.vigilancias.procedimiento_id,
  procedimiento_componente: state.vigilancias.procedimiento_componente,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerProcedimiento }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Procedimiento);
