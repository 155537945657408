import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  eliminarRequisito,
  limpiarMensajes,
} from "../../../redux/actions/tareas/action";
import { Nuevo as RequisitoNuevo } from "./";

export class Tabla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requisito_nuevo_mostrar: false,
    };
  }

  eliminacionRequisito = (requisito, entregable) => {
    const { eliminarRequisito, tarea_seleccionada } = this.props;
    SweetAlert.fire({
      title: "Eliminar requisito",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de eliminar el requisito?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarRequisito({
          tarea: tarea_seleccionada,
          entregable: entregable.id,
          requisito: requisito.id,
        });
        SweetAlert.fire({
          title: "Requisito",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos eliminando el requisito, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  cancelarCreacionRequisito = () => {
    const { limpiarMensajes } = this.props;
    this.setState({
      requisito_nuevo_mostrar: false,
    });
    limpiarMensajes();
  };

  mostrarCreacionRequisito = () => {
    this.setState({
      requisito_nuevo_mostrar: true,
    });
  };

  render = () => {
    const { requisito_nuevo_mostrar } = this.state;
    const {
      entregable,
      user_equipo,
      requisito_eliminar_error,
      requisito_eliminar,
    } = this.props;

    if (Object.entries(requisito_eliminar_error).length > 0) {
      SweetAlert.close();
      if (
        requisito_eliminar_error.data &&
        requisito_eliminar_error.data.message
      ) {
        SweetAlert.fire({
          title: "Requisito no eliminado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: requisito_eliminar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(requisito_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Requisito eliminado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: requisito_eliminar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.tareaSeleccionada();
          this.cancelarCreacionRequisito();
          limpiarMensajes();
        }
      });
    }

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="float-end mb-3">
            {!requisito_nuevo_mostrar &&
              entregable.estado_text !== "Finalizada" &&
              user_equipo.rol_text === "Propietario" && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.mostrarCreacionRequisito}
                >
                  Crear Requisito <i className="fa fa-plus-circle"></i>
                </button>
              )}
          </div>
        </div>
        {requisito_nuevo_mostrar && (
          <div className="col-lg-12">
            <RequisitoNuevo
              entregable={entregable}
              tareaSeleccionada={this.tareaSeleccionada}
              cancelarCreacionRequisito={this.cancelarCreacionRequisito}
            />
          </div>
        )}
        <div className="col-lg-12">
          {entregable.requisitos && entregable.requisitos.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-secondary">
                  <tr>
                    <th className="text-center w-3">ID</th>
                    <th>Nombre del Requisito</th>
                    <th className="w-40">Descripción del Requisito</th>
                    <th className="w-10">Link del Requisito</th>
                    {entregable.estado_text !== "Finalizada" &&
                      user_equipo.rol_text === "Propietario" && (
                        <th className="text-center w-10">Acción</th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {entregable.requisitos.map((requisito, a) => (
                    <tr key={a}>
                      <td className="text-center">{a + 1}</td>
                      <td>{requisito.nombre}</td>
                      <td>
                        {requisito.descripcion ? requisito.descripcion : "S/I"}
                      </td>
                      <td>
                        {requisito.link && (
                          <a
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-xs"
                            target="_blank"
                            href={requisito.link}
                          >
                            Ver link <i className="fa fa-angle-right"></i>
                          </a>
                        )}
                      </td>
                      {entregable.estado_text !== "Finalizada" &&
                        user_equipo.rol_text === "Propietario" && (
                          <td className="text-center">
                            <button
                              data-tip
                              data-tooltip-id="eliminar_requisitos"
                              onClick={this.eliminacionRequisito.bind(
                                this,
                                requisito,
                                entregable
                              )}
                              className="btn btn-xs btn-danger"
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                            <Tooltip
                              id="eliminar_requisitos"
                              aria-haspopup="true"
                            >
                              Eliminar requisito del entregable
                            </Tooltip>
                          </td>
                        )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="card-body text-center alert alert-outline-danger">
              <h5 className="text-uppercase">No existen requisitos creados</h5>
            </div>
          )}
        </div>
      </div>
    );
  };
}

Tabla.propTypes = {
  eliminarRequisito: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  requisito_eliminar: PropTypes.object.isRequired,
  requisito_eliminar_error: PropTypes.object.isRequired,
  tarea_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  requisito_eliminar: state.tareas.requisito_eliminar,
  requisito_eliminar_error: state.tareas.requisito_eliminar_error,
  tarea_seleccionada: state.tareas.tarea_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ eliminarRequisito, limpiarMensajes }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabla);
