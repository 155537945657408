import React, { Component } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerUnidadesFiscalizablesEstadistica } from "../../../redux/actions/vigilancias/unidades_fiscalizables/action";
import { Chart, Footer, Navbar, SidebarInicio } from "../../general";

export class Estadisticas extends Component {
  componentDidMount = () => {
    const { obtenerUnidadesFiscalizablesEstadistica, unidades_estadisticas } =
      this.props;
    if (Object.entries(unidades_estadisticas).length === 0) {
      obtenerUnidadesFiscalizablesEstadistica(1);
    }
  };

  render = () => {
    const { unidades_estadisticas, is_loading_vigilancias } = this.props;

    var regiones = _.countBy(
      _.orderBy(unidades_estadisticas.result, "region_display"),
      "region_display"
    );
    var categorias = _.countBy(
      _.orderBy(unidades_estadisticas.result, "categoria_display"),
      "categoria_display"
    );

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "SEIA",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las consultas de pertinencia, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_unidades" menu_2="estadistica" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-house-user"></i> UNIDADES FISCALIZABLES{" "}
                    <small>
                      (
                      {unidades_estadisticas &&
                        unidades_estadisticas.total &&
                        unidades_estadisticas.total}
                      )
                    </small>
                  </h4>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  is_vertical
                  data={regiones}
                  nombre="Unidades Fiscalizables"
                  titulo="Unidades Fiscalizables por región"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  data={categorias}
                  nombre="Unidades Fiscalizables"
                  titulo="Unidades Fiscalizables por categoría"
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Estadisticas.propTypes = {
  obtenerUnidadesFiscalizablesEstadistica: PropTypes.func.isRequired,
  unidades_estadisticas: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  unidades_estadisticas: state.vigilancias.unidades_estadisticas,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerUnidadesFiscalizablesEstadistica },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Estadisticas);
