import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

export class SidebarLeft extends Component {
  render = () => {
    const { menu_1, siderbar_estado } = this.props;
    return (
      <div className={`left-sidenav ${siderbar_estado ? "d-none" : ""}`}>
        <ul className="top100 left-sidenav-menu">
          <h5>PERMITTING TARGET</h5>
          <li className="mm-inactivo">
            <Link to="/permitting-target/proyectos">
              <i className="fa fa-angle-left"></i>
              <span>Cambiar de proyecto</span>
            </Link>
          </li>
          <li
            className={
              menu_1 && menu_1 === "permitting_parametrizacion"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link
              className="nav-link"
              to="/permitting-target/parametrizacion/secciones"
            >
              <i className="fa fa-toolbox"></i> Secciones
            </Link>
          </li>
          <li
            className={
              menu_1 && menu_1 === "permitting_indefinidas"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/permitting-target/parametrizacion/indefinidas">
              <i className="fa fa-question-circle"></i>
              <span>Respuestas Indefinidas</span>
            </Link>
          </li>
          <li
            className={
              menu_1 && menu_1 === "permitting_entregables"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/permitting-target/parametrizacion/generar-entregables">
              <i className="fa fa-cog"></i>
              <span>Generar entregables</span>
            </Link>
          </li>
        </ul>
      </div>
    );
  };
}

SidebarLeft.propTypes = {
  siderbar_estado: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  siderbar_estado: state.auth.siderbar_estado,
});

export default connect(mapStateToProps, null)(SidebarLeft);
