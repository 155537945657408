import axios from 'axios';

import {
  tokenConfig,
  tokenConfigArchivo,
} from '../auth/action';
import { AUTENTIFICACION_FALLIDO } from '../auth/types';
import {
  ARCHIVO_ELIMINAR_FALLIDO,
  ARCHIVO_ELIMINAR_OBTENER,
  ARCHIVO_ESTADO_FALLIDO,
  ARCHIVO_ESTADO_OBTENER,
  ARCHIVO_NUEVO_FALLIDO,
  ARCHIVO_NUEVO_OBTENER,
  CARGA_NUEVO_FALLIDO,
  CARGA_NUEVO_OBTENER,
  ENTREGABLE_DUPLICAR_FALLIDO,
  ENTREGABLE_DUPLICAR_OBTENER,
  ENTREGABLE_EDITAR_FALLIDO,
  ENTREGABLE_EDITAR_OBTENER,
  ENTREGABLE_ELIMINAR_FALLIDO,
  ENTREGABLE_ELIMINAR_OBTENER,
  ENTREGABLE_ESTADO_FALLIDO,
  ENTREGABLE_ESTADO_OBTENER,
  ENTREGABLE_NUEVO_FALLIDO,
  ENTREGABLE_NUEVO_OBTENER,
  ENTREGABLE_RESPONSABLE_FALLIDO,
  ENTREGABLE_RESPONSABLE_OBTENER,
  EQUIPO_ELIMINAR_FALLIDO,
  EQUIPO_ELIMINAR_OBTENER,
  EQUIPO_NUEVO_FALLIDO,
  EQUIPO_NUEVO_OBTENER,
  LOADING_TRUE,
  MENSAJES_LIMPIAR,
  RECURSO_NUEVO_FALLIDO,
  RECURSO_NUEVO_OBTENER,
  REQUISITO_ELIMINAR_FALLIDO,
  REQUISITO_ELIMINAR_OBTENER,
  REQUISITO_NUEVO_FALLIDO,
  REQUISITO_NUEVO_OBTENER,
  TAREA_EDITAR_FALLIDO,
  TAREA_EDITAR_OBTENER,
  TAREA_ELIMINAR_FALLIDO,
  TAREA_ELIMINAR_OBTENER,
  TAREA_ESTADO_FALLIDO,
  TAREA_ESTADO_OBTENER,
  TAREA_FALLIDO,
  TAREA_NUEVA_FALLIDO,
  TAREA_NUEVA_OBTENER,
  TAREA_OBTENER,
  TAREA_RESPONSABLE_FALLIDO,
  TAREA_RESPONSABLE_OBTENER,
  TAREA_SELECCIONADA,
} from './types';

var url_api = process.env.REACT_APP_API_URL;

export const TareaSeleccionada = tarea => (dispatch) => {
  dispatch({
    type: TAREA_SELECCIONADA,
    payload: tarea,
  });
};

export const obtenerTarea = data => (dispatch, getState) => {
  dispatch({ type: LOADING_TRUE });
  axios
    .post(`${url_api}/api/tasks/tarea`, data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: TAREA_OBTENER, payload: res.data });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : TAREA_FALLIDO,
        payload: error
      });
    });
};

export const loadingTrue = () => (dispatch) => {
  dispatch({ type: LOADING_TRUE });
};

export const limpiarMensajes = () => (dispatch) => {
  dispatch({ type: MENSAJES_LIMPIAR });
};

export const nuevoEquipo = data => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/tasks/equipo`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: EQUIPO_NUEVO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : EQUIPO_NUEVO_FALLIDO,
        payload: error
      });
    });
};

export const eliminarEquipo = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/equipo`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: EQUIPO_ELIMINAR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : EQUIPO_ELIMINAR_FALLIDO,
        payload: error
      });
    });
};

export const estadoTarea = data => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/tasks/tarea/estado`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TAREA_ESTADO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : TAREA_ESTADO_FALLIDO,
        payload: error
      });
    });
};


export const estadoEntregable = data => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/tasks/entregable/estado`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ENTREGABLE_ESTADO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ENTREGABLE_ESTADO_FALLIDO,
        payload: error
      });
    });
};

export const edicionEntregable = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/entregable`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ENTREGABLE_EDITAR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ENTREGABLE_EDITAR_FALLIDO,
        payload: error
      });
    });
};

export const eliminarEntregable = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/entregable/eliminar`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ENTREGABLE_ELIMINAR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ENTREGABLE_ELIMINAR_FALLIDO,
        payload: error
      });
    });
};

export const duplicarEntregable = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/entregable/duplicar`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ENTREGABLE_DUPLICAR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ENTREGABLE_DUPLICAR_FALLIDO,
        payload: error
      });
    });
};

export const creacionEntregable = data => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/tasks/entregable`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ENTREGABLE_NUEVO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ENTREGABLE_NUEVO_FALLIDO,
        payload: error
      });
    });
};

export const creacionRequisito = data => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/tasks/requisito`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: REQUISITO_NUEVO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : REQUISITO_NUEVO_FALLIDO,
        payload: error
      });
    });
};

export const eliminarRequisito = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/requisito`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: REQUISITO_ELIMINAR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : REQUISITO_ELIMINAR_FALLIDO,
        payload: error
      });
    });
};

export const archivoEstado = data => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/tasks/archivo`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ARCHIVO_ESTADO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ARCHIVO_ESTADO_FALLIDO,
        payload: error
      });
    });
};


export const agregarResponsableTarea = data => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/tasks/responsable-tarea`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TAREA_RESPONSABLE_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : TAREA_RESPONSABLE_FALLIDO,
        payload: error
      });
    });
};
export const agregarResponsableEntregable = data => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/tasks/responsable-entregable`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ENTREGABLE_RESPONSABLE_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ENTREGABLE_RESPONSABLE_FALLIDO,
        payload: error
      });
    });
};

export const eliminarTarea = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/tarea/eliminar`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TAREA_ELIMINAR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : TAREA_ELIMINAR_FALLIDO,
        payload: error
      });
    });
};

export const creacionTarea = data => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/tasks/tarea/nueva`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TAREA_NUEVA_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : TAREA_NUEVA_FALLIDO,
        payload: error
      });
    });
};

export const editarTarea = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/tarea`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TAREA_EDITAR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : TAREA_EDITAR_FALLIDO,
        payload: error
      });
    });
};

export const creacionCarga = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/archivo`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: CARGA_NUEVO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : CARGA_NUEVO_FALLIDO,
        payload: error
      });
    });
};

export const creacionRecurso = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/archivo`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: RECURSO_NUEVO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : RECURSO_NUEVO_FALLIDO,
        payload: error
      });
    });
};

export const creacionArchivo = (tarea, entregable, archivo, archivo_obj) => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/archivo-carga/${tarea}/${entregable}/${archivo}`, archivo_obj, tokenConfigArchivo(getState, archivo_obj))
    .then(res => {
      dispatch({
        type: ARCHIVO_NUEVO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ARCHIVO_NUEVO_FALLIDO,
        payload: error
      });
    });
};

export const eliminarArchivo = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/archivo/eliminar`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ARCHIVO_ELIMINAR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ARCHIVO_ELIMINAR_FALLIDO,
        payload: error
      });
    });
};
