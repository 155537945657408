import React, { Component } from "react";
import { Tooltip } from "react-tooltip";

export class Tabla extends Component {
  render = () => {
    const { hechos } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th className="w-20">
                Nombre
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="descripcion_titulo"
                ></i>
                <Tooltip id="descripcion_titulo" aria-haspopup="true">
                  Nombre del hecho infraccional
                </Tooltip>
              </th>
              <th className="text-center w-3">
                Clasificación
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="clasificacion_titulo"
                ></i>
                <Tooltip id="clasificacion_titulo" aria-haspopup="true">
                  Clasificación del hecho infraccional (Art.36 LOSMA)
                </Tooltip>
              </th>
              <th className="text-center w-20">
                Tipo
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="tipo_titulo"
                ></i>
                <Tooltip id="tipo_titulo" aria-haspopup="true">
                  Tipo de infracción (Art.35 LOSMA)
                </Tooltip>
              </th>
              <th className="w-5">
                Instrumentos
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="instrumentos_titulo"
                ></i>
                <Tooltip id="instrumentos_titulo" aria-haspopup="true">
                  Instrumentos de cumplimiento infringidos
                </Tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            {hechos && hechos.length > 0 ? (
              hechos.map((hecho, x) => (
                <tr key={x}>
                  <td className="text-center">{x + 1}</td>
                  <td>{hecho.nombre}</td>
                  <td className="text-center">
                    {hecho.clasificacion ? (
                      <>
                        {hecho.clasificacion}
                        <Tooltip
                          className="tooltip-limite"
                          id={`clasificacion_detalle_${x}`}
                          aria-haspopup="true"
                        >
                          {hecho.clasificacion_detalle}
                        </Tooltip>
                        <i
                          data-tip
                          data-tooltip-id={`clasificacion_detalle_${x}`}
                          className="fa fa-exclamation-circle text-danger ms-1 font-11"
                        ></i>
                      </>
                    ) : (
                      "S/I"
                    )}
                  </td>
                  <td>{hecho.infraccion}</td>
                  <td className="text-center p-4">
                    {hecho.instrumentos_infringidos &&
                    hecho.instrumentos_infringidos.length > 0
                      ? hecho.instrumentos_infringidos.map((inst, y) => (
                          <p className="mb-0 text-start" key={y}>
                            <a
                              href={inst.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {inst.nombre}{" "}
                              <i className="fa fa-angle-right"></i>
                            </a>
                          </p>
                        ))
                      : "S/I"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="7">
                  No existen hechos infraccionales
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

export default Tabla;
