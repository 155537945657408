import React, { Component } from "react";

import { Tabs as Fiscalizaciones } from "../fiscalizaciones";
import { Tabs as MedidasProvisional } from "../medidas";
import { Tabs as Procedimientos } from "../procedimientos";
import { Tabs as Programas } from "../programas";
import { Tabs as Rcas } from "../rcas";
import { Tabs as SeguimientosAmbientales } from "../seguimientos";
import { TablaMedia as UnidadFiscalizables } from "../unidades";

export class Tabs extends Component {
  render = () => {
    const { unidades_fiscalizables } = this.props;

    if (unidades_fiscalizables && unidades_fiscalizables.length < 1) {
      return <h6>No existen unidades fiscalizables</h6>;
    } else {
      return (
        <div className="align-items-start">
          <div className="row">
            {unidades_fiscalizables.length > 1 && (
              <div className="col-3">
                <div
                  className="nav flex-column nav-pills me-3"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {unidades_fiscalizables.map((unidad_fiscalizable, x) => (
                    <button
                      key={x}
                      className={`nav-link ${
                        x === 0 ? "active" : ""
                      } text-start`}
                      id={`unidad_fiscalizable-tab-${unidad_fiscalizable.id}`}
                      data-bs-toggle="pill"
                      data-bs-target={`#unidad_fiscalizable-${unidad_fiscalizable.id}`}
                      type="button"
                      role="tab"
                      aria-controls={`unidad_fiscalizable-${unidad_fiscalizable.id}`}
                      aria-selected={x === 0 ? "true" : "false"}
                    >
                      {unidad_fiscalizable.nombre}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div
              className={`col-${unidades_fiscalizables.length > 1 ? 9 : 12}`}
            >
              <div className="tab-content" id="v-pills-unidades_fiscalizables">
                {unidades_fiscalizables.map((unidad_fiscalizable, x) => (
                  <div
                    key={x}
                    className={`tab-pane fade ${x === 0 ? "show active" : ""}`}
                    id={`unidad_fiscalizable-${unidad_fiscalizable.id}`}
                    role="tabpanel"
                    aria-labelledby={`unidad_fiscalizable-tab-${unidad_fiscalizable.id}`}
                    tabIndex="0"
                  >
                    <>
                      <ul className="nav nav-pills nav-justified">
                        <li className="nav-item" role="presentation">
                          <button
                            key={x}
                            className="nav-link active"
                            id={`general-tab-und-${unidad_fiscalizable.id}`}
                            data-bs-toggle="pill"
                            data-bs-target={`#general-und-${unidad_fiscalizable.id}`}
                            type="button"
                            role="tab"
                            aria-controls={`general-und-${unidad_fiscalizable.id}`}
                            aria-selected="true"
                          >
                            Unidad Fiscalizable
                          </button>
                        </li>
                        {unidad_fiscalizable.procedimientos &&
                          unidad_fiscalizable.procedimientos.length > 0 && (
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id={`procedimientos-tab-und-${unidad_fiscalizable.id}`}
                                data-bs-toggle="pill"
                                data-bs-target={`#procedimientos-und-${unidad_fiscalizable.id}`}
                                type="button"
                                role="tab"
                                aria-controls={`procedimientos-und-${unidad_fiscalizable.id}`}
                                aria-selected="true"
                              >
                                Procedimientos Sancionatorios (
                                {unidad_fiscalizable.procedimientos.length > 0
                                  ? unidad_fiscalizable.procedimientos.length
                                  : 0}
                                )
                              </button>
                            </li>
                          )}
                        {unidad_fiscalizable.fiscalizaciones &&
                          unidad_fiscalizable.fiscalizaciones.length > 0 && (
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id={`fiscalizaciones-tab-und-${unidad_fiscalizable.id}`}
                                data-bs-toggle="pill"
                                data-bs-target={`#fiscalizaciones-und-${unidad_fiscalizable.id}`}
                                type="button"
                                role="tab"
                                aria-controls={`fiscalizaciones-und-${unidad_fiscalizable.id}`}
                                aria-selected="true"
                              >
                                Fiscalizaciones (
                                {unidad_fiscalizable.fiscalizaciones.length > 0
                                  ? unidad_fiscalizable.fiscalizaciones.length
                                  : 0}
                                )
                              </button>
                            </li>
                          )}
                        {unidad_fiscalizable.programas &&
                          unidad_fiscalizable.programas.length > 0 && (
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id={`programas-tab-und-${unidad_fiscalizable.id}`}
                                data-bs-toggle="pill"
                                data-bs-target={`#programas-und-${unidad_fiscalizable.id}`}
                                type="button"
                                role="tab"
                                aria-controls={`programas-und-${unidad_fiscalizable.id}`}
                                aria-selected="true"
                              >
                                Programas de Cumplimiento (
                                {unidad_fiscalizable.programas.length > 0
                                  ? unidad_fiscalizable.programas.length
                                  : 0}
                                )
                              </button>
                            </li>
                          )}
                        {unidad_fiscalizable.rcas &&
                          unidad_fiscalizable.rcas.length > 0 && (
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id={`rcas-tab-und-${unidad_fiscalizable.id}`}
                                data-bs-toggle="pill"
                                data-bs-target={`#rcas-und-${unidad_fiscalizable.id}`}
                                type="button"
                                role="tab"
                                aria-controls={`rcas-und-${unidad_fiscalizable.id}`}
                                aria-selected="true"
                              >
                                RCA (
                                {unidad_fiscalizable.rcas.length > 0
                                  ? unidad_fiscalizable.rcas.length
                                  : 0}
                                )
                              </button>
                            </li>
                          )}
                        {unidad_fiscalizable.medidas &&
                          unidad_fiscalizable.medidas.length > 0 && (
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id={`medidas-tab-und-${unidad_fiscalizable.id}`}
                                data-bs-toggle="pill"
                                data-bs-target={`#medidas-und-${unidad_fiscalizable.id}`}
                                type="button"
                                role="tab"
                                aria-controls={`medidas-und-${unidad_fiscalizable.id}`}
                                aria-selected="true"
                              >
                                Medidas Provisionales (
                                {unidad_fiscalizable.medidas.length > 0
                                  ? unidad_fiscalizable.medidas.length
                                  : 0}
                                )
                              </button>
                            </li>
                          )}
                        {unidad_fiscalizable.seguimientos_ambiental &&
                          unidad_fiscalizable.seguimientos_ambiental.length >
                            0 && (
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id={`seguimientos-tab-und-${unidad_fiscalizable.id}`}
                                data-bs-toggle="pill"
                                data-bs-target={`#seguimientos-und-${unidad_fiscalizable.id}`}
                                type="button"
                                role="tab"
                                aria-controls={`seguimientos-und-${unidad_fiscalizable.id}`}
                                aria-selected="true"
                              >
                                Seguimientos Ambientales (
                                {unidad_fiscalizable.seguimientos_ambiental
                                  .length > 0
                                  ? unidad_fiscalizable.seguimientos_ambiental
                                      .length
                                  : 0}
                                )
                              </button>
                            </li>
                          )}
                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show mt-3"
                          id={`general-und-${unidad_fiscalizable.id}`}
                          role="tabpanel"
                          aria-labelledby={`general-tab-und-${unidad_fiscalizable.id}`}
                        >
                          <UnidadFiscalizables
                            unidades_fiscalizables={[unidad_fiscalizable]}
                          />
                        </div>
                        {unidad_fiscalizable.procedimientos &&
                          unidad_fiscalizable.procedimientos.length > 0 && (
                            <div
                              className="tab-pane fade  mt-3"
                              id={`procedimientos-und-${unidad_fiscalizable.id}`}
                              role="tabpanel"
                              aria-labelledby={`procedimientos-tab-und-${unidad_fiscalizable.id}`}
                            >
                              <Procedimientos
                                procedimientos={
                                  unidad_fiscalizable.procedimientos
                                }
                              />
                            </div>
                          )}
                        {unidad_fiscalizable.fiscalizaciones &&
                          unidad_fiscalizable.fiscalizaciones.length > 0 && (
                            <div
                              className="tab-pane fade mt-3"
                              id={`fiscalizaciones-und-${unidad_fiscalizable.id}`}
                              role="tabpanel"
                              aria-labelledby={`fiscalizaciones-tab-und-${unidad_fiscalizable.id}`}
                            >
                              <Fiscalizaciones
                                fiscalizaciones={
                                  unidad_fiscalizable.fiscalizaciones
                                }
                              />
                            </div>
                          )}
                        {unidad_fiscalizable.programas &&
                          unidad_fiscalizable.programas.length > 0 && (
                            <div
                              className="tab-pane fade mt-3"
                              id={`programas-und-${unidad_fiscalizable.id}`}
                              role="tabpanel"
                              aria-labelledby={`programas-tab-und-${unidad_fiscalizable.id}`}
                            >
                              <Programas
                                programas={unidad_fiscalizable.programas}
                              />
                            </div>
                          )}
                        {unidad_fiscalizable.rcas &&
                          unidad_fiscalizable.rcas.length > 0 && (
                            <div
                              className="tab-pane fade mt-3"
                              id={`rcas-und-${unidad_fiscalizable.id}`}
                              role="tabpanel"
                              aria-labelledby={`rcas-tab-und-${unidad_fiscalizable.id}`}
                            >
                              <Rcas rcas={unidad_fiscalizable.rcas} />
                            </div>
                          )}
                        {unidad_fiscalizable.medidas &&
                          unidad_fiscalizable.medidas.length > 0 && (
                            <div
                              className="tab-pane fade mt-3"
                              id={`medidas-und-${unidad_fiscalizable.id}`}
                              role="tabpanel"
                              aria-labelledby={`medidas-tab-und-${unidad_fiscalizable.id}`}
                            >
                              <MedidasProvisional
                                medidas_provisionales={
                                  unidad_fiscalizable.medidas
                                }
                              />
                            </div>
                          )}
                        {unidad_fiscalizable.seguimientos_ambiental &&
                          unidad_fiscalizable.seguimientos_ambiental.length >
                            0 && (
                            <div
                              className="tab-pane fade mt-3"
                              id={`seguimientos-und-${unidad_fiscalizable.id}`}
                              role="tabpanel"
                              aria-labelledby={`seguimientos-tab-und-${unidad_fiscalizable.id}`}
                            >
                              <SeguimientosAmbientales
                                seguimientos={
                                  unidad_fiscalizable.seguimientos_ambiental
                                }
                              />
                            </div>
                          )}
                      </div>
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}

export default Tabs;
