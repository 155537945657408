import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import logo from "../../images/logo.png";
import {
  iniciarSesion,
  limpiarMensajes,
} from "../../redux/actions/auth/action";

export class InicioSesion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      cargando: false,
    };
  }

  componentDidMount = () => {
    SweetAlert.close();
  };

  componentWillUnmount = () => {
    const { limpiarMensajes } = this.props;
    limpiarMensajes();
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      cargando: true,
    });
    const { iniciarSesion } = this.props;
    iniciarSesion(this.state.email, this.state.password);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { is_authenticated, limpiarMensajes, errores } = this.props;
    const { email, password, cargando } = this.state;
    if (is_authenticated) {
      return <Navigate to="/user/inicio" />;
    }

    if (
      errores &&
      Object.entries(errores).length > 0 &&
      Object.entries(errores.data).length > 0 &&
      errores.data.inicio_sesion &&
      Object.entries(errores.data.inicio_sesion).length > 0
    ) {
      limpiarMensajes();
      var error = "";
      for (const err in errores.data.inicio_sesion) {
        error = error + `<h5>${errores.data.inicio_sesion[err]}</h5>`;
      }
      SweetAlert.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        title: "Inicio de sesión",
        html: error,
        icon: "error",
        confirmButtonText:
          'Volver a intentar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        this.setState({
          cargando: false,
        });
      });
    }

    return (
      <Fragment>
        <div className="account-body accountbg">
          <div className="row vh-100">
            <div className="col-lg-12 pe-0 align-self-center">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-6 col-8 mx-auto">
                  <div className="card auth-card">
                    <div className="card-body">
                      <div className="px-3">
                        <div className="text-center auth-logo-text">
                          <p>
                            <img
                              src={logo}
                              alt="logo"
                              className="img-fluid auth-logo"
                            />
                          </p>
                          <h5>INICIO SESIÓN DE CLIENTES</h5>
                        </div>
                        <form
                          onSubmit={this.onSubmit}
                          className="form-horizontal auth-form my-4"
                        >
                          <div className="form-group">
                            <label>
                              Email <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="email"
                                name="email"
                                placeholder="Ingresa tu email"
                                onChange={this.onChange}
                                value={email}
                                className={
                                  errores && errores.data && errores.data.email
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                              />
                              {errores &&
                                errores.data &&
                                errores.data.email &&
                                errores.data.email.map((err, x) => (
                                  <div key={x} className="invalid-feedback">
                                    {err}
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className="form-group">
                            <label>
                              Contraseña <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="password"
                                name="password"
                                placeholder="Ingrese tu contraseña"
                                onChange={this.onChange}
                                value={password}
                                className={
                                  errores &&
                                  errores.data &&
                                  errores.data.password
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                              />
                              {errores &&
                                errores.data &&
                                errores.data.password &&
                                errores.data.password.map((err, x) => (
                                  <div key={x} className="invalid-feedback">
                                    {err}
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className="form-group mb-0 row">
                            <div className="col-12 mt-2 d-grid gap-2">
                              <button
                                className={`btn btn-danger ${
                                  cargando && "disabled"
                                }`}
                                type="submit"
                              >
                                Iniciar Sesión
                                {cargando ? (
                                  <i className="fas fa-circle-notch fa-spin ms-1"></i>
                                ) : (
                                  <i className="fas fa-sign-in-alt ms-1"></i>
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-12 text-center mt-4">
                              <Link to="/restablecer-contrasena">
                                ¿Necesitas recuperar tu contraseña? Ingresa aquí
                              </Link>
                            </div>
                            <div className="col-sm-12 text-center mt-2">
                              <Link to="/registrate" className="text-danger">
                                ¿No tienes cuenta? Regístrate aquí
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
}

InicioSesion.propTypes = {
  iniciarSesion: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool.isRequired,
  errores: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_authenticated: state.auth.is_authenticated,
  errores: state.auth.errores,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ iniciarSesion, limpiarMensajes }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InicioSesion);
