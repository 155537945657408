import { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  creacionRequisito,
  limpiarMensajes,
} from "../../../redux/actions/tareas/action";

export class Nuevo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requisito_nombre: "",
      requisito_descripcion: "",
      requisito_link: "",
    };
  }

  nuevoRequisito = () => {
    const { creacionRequisito, tarea_seleccionada, entregable } = this.props;
    const { requisito_nombre, requisito_descripcion, requisito_link } =
      this.state;
    creacionRequisito({
      nombre: requisito_nombre,
      link: requisito_link,
      descripcion: requisito_descripcion,
      tarea: tarea_seleccionada,
      entregable: entregable.id,
    });
    SweetAlert.fire({
      title: "Nuevo Requisito",
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: "Estamos guardando el nuevo requisito, espere unos segundos",
      icon: "warning",
      showConfirmButton: false,
      didOpen: () => {
        SweetAlert.showLoading();
      },
    });
  };

  cancelarCreacionRequisito = () => {
    this.props.cancelarCreacionRequisito();
  };

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render = () => {
    const { requisito_nombre, requisito_link, requisito_descripcion } =
      this.props;
    const { requisito_nuevo_error, requisito_nuevo } = this.props;

    if (Object.entries(requisito_nuevo).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Requisito creado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: requisito_nuevo.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.tareaSeleccionada();
          this.cancelarCreacionRequisito();
        }
      });
    }

    if (Object.entries(requisito_nuevo_error).length > 0) {
      SweetAlert.close();
      if (requisito_nuevo_error.data && requisito_nuevo_error.data.message) {
        SweetAlert.fire({
          title: "Requisito no creado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: requisito_nuevo_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    return (
      <div className="card card-eco">
        <div className="card-header bg-primary text-white">
          <h5>Nuevo Requisito</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 text-end">
              <p className="text-danger">
                <i className="fa fa-asterisk"></i> datos obligatorios
              </p>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>
                  Nombre del requisito <small className="text-danger">*</small>
                </label>
                <input
                  type="text"
                  name="requisito_nombre"
                  onChange={this.onChange}
                  value={requisito_nombre}
                  placeholder="Ingrese un nombre"
                  className={
                    requisito_nuevo_error &&
                    requisito_nuevo_error.data &&
                    requisito_nuevo_error.data.nombre
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
                {requisito_nuevo_error &&
                  requisito_nuevo_error.data &&
                  requisito_nuevo_error.data.nombre &&
                  requisito_nuevo_error.data.nombre.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
              <div className="form-group">
                <label>Link</label>
                <input
                  type="text"
                  name="requisito_link"
                  onChange={this.onChange}
                  value={requisito_link}
                  placeholder="Ingrese un link"
                  className={
                    requisito_nuevo_error &&
                    requisito_nuevo_error.data &&
                    requisito_nuevo_error.data.link
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
                {requisito_nuevo_error &&
                  requisito_nuevo_error.data &&
                  requisito_nuevo_error.data.link &&
                  requisito_nuevo_error.data.link.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Descripción del requisito</label>
                <textarea
                  rows="4"
                  name="requisito_descripcion"
                  onChange={this.onChange}
                  value={requisito_descripcion}
                  placeholder="Ingrese una descripción"
                  className={
                    requisito_nuevo_error &&
                    requisito_nuevo_error.data &&
                    requisito_nuevo_error.data.descripcion
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                ></textarea>
                {requisito_nuevo_error &&
                  requisito_nuevo_error.data &&
                  requisito_nuevo_error.data.descripcion &&
                  requisito_nuevo_error.data.descripcion.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-sm-12 text-end">
              <div className="btn-group">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.nuevoRequisito}
                >
                  Crear nuevo requisito <i className="fa fa-plus-circle"></i>
                </button>
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={this.cancelarCreacionRequisito}
                >
                  Cancelar <i className="fa fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

Nuevo.propTypes = {
  creacionRequisito: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  requisito_nuevo: PropTypes.object.isRequired,
  requisito_nuevo_error: PropTypes.object.isRequired,
  tarea_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  requisito_nuevo: state.tareas.requisito_nuevo,
  requisito_nuevo_error: state.tareas.requisito_nuevo_error,
  tarea_seleccionada: state.tareas.tarea_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ creacionRequisito, limpiarMensajes }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Nuevo);
