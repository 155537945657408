import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { guardarSancion } from "../../../redux/actions/vigilancias/sanciones/action";
import { TablaPequena as Hechos } from "../hechos";
import { TablaPequena as Titulares } from "../titulares";

export class Tabla extends Component {
  setSancion = (id) => {
    const { guardarSancion } = this.props;
    guardarSancion(id);
  };

  render = () => {
    const { sanciones } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th className="text-center w-7">Expediente</th>
              <th className="text-center w-8">Fecha Inicio</th>
              <th className="text-center w-8">Fecha Término</th>
              <th className="text-center w-10">
                Multa
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="proce_multa"
                ></i>
                <Tooltip id="proce_multa" aria-haspopup="true">
                  UTA = Unidad Tributaria Anual (UTM x 12). Para más información
                  visitar el sitio oficial de SII.
                </Tooltip>
              </th>
              <th className="text-center">Hecho infraccional</th>
              <th className="text-center w-15">Titular</th>
              <th className="text-center w-3">Acción</th>
            </tr>
          </thead>
          <tbody>
            {sanciones && sanciones.result && sanciones.result.length > 0 ? (
              sanciones.result.map((procedimiento, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {sanciones.page > 1
                      ? (sanciones.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td className="text-center">
                    <a
                      href={procedimiento.url_snifa}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {procedimiento.expediente}
                      <i className="ms-1 fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td className="text-center">{procedimiento.fecha_inicio}</td>
                  <td className="text-center">{procedimiento.fecha_termino}</td>
                  <td className="text-center">
                    <span className="badge badge-success font-12">
                      {procedimiento.multa}
                    </span>
                  </td>
                  <td>
                    <Hechos hechos={procedimiento.hechos} />
                  </td>
                  <td>
                    <Titulares titulares={procedimiento.titulares} />
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      <Link
                        to="/compliance-analytics/sancion"
                        className="btn btn-soft-dark"
                        onClick={this.setSancion.bind(this, procedimiento.id)}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="8">
                  No existen registros de sanciones
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarSancion: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarSancion }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
