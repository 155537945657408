import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  LIMPIAR_SEGUIMIENTOS_FILTROS,
  SEGUIMIENTOS_FALLIDO,
  SEGUIMIENTOS_FILTROS_FALLIDO,
  SEGUIMIENTOS_FILTROS_INPUT,
  SEGUIMIENTOS_FILTROS_OBTENER,
  SEGUIMIENTOS_OBTENER,
  SEGUIMIENTO_FALLIDO,
  SEGUIMIENTO_GUARDAR,
  SEGUIMIENTO_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerSeguimientos = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/seguimientos-ambiental/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: SEGUIMIENTOS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : SEGUIMIENTOS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerSeguimiento = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/seguimientos-ambiental/detalle`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: SEGUIMIENTO_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : SEGUIMIENTO_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerSeguimientosFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/seguimientos-ambiental/filtro/${data.pagina}`,
      data.filtros,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: SEGUIMIENTOS_FILTROS_INPUT, payload: data.filtros });
      dispatch({ type: SEGUIMIENTOS_FILTROS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : SEGUIMIENTOS_FILTROS_FALLIDO,
        payload: error,
      });
    });
};

export const limpiarFiltroSeguimientos = () => (dispatch) => {
  dispatch({ type: LIMPIAR_SEGUIMIENTOS_FILTROS });
};

export const guardarSeguimiento = (data) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  dispatch({ type: SEGUIMIENTO_GUARDAR, payload: data });
};
