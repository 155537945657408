import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerEncargado } from "../../../redux/actions/vigilancias/encargados/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { TablaPequena } from "../encargados";
import { Tabs as Expedientes } from "../expedientes";
import { Tabs as Pertinencias } from "../pertinencias";

export class Detalle extends Component {
  componentDidMount = () => {
    const { obtenerEncargado, encargado_id } = this.props;
    if (encargado_id > 0) {
      obtenerEncargado(encargado_id);
    }
  };

  render = () => {
    const { encargado, is_loading_vigilancias, encargado_id } = this.props;

    if (encargado_id === 0) {
      return <Navigate to="/compliance-analytics/encargados" />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Encargado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo el encargado, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_encargados" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-user-edit"></i> ENCARGADOS DEL SEA
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start text-dark">
                      {encargado.nombre}
                    </h5>
                    <p className="float-end">
                      <Link
                        className="btn btn-primary"
                        to="/compliance-analytics/encargados"
                      >
                        <i className="fa fa-angle-left"></i> Volver al listado
                      </Link>
                    </p>
                  </div>
                  <div className="card-body">
                    <ul className="nav nav-pills nav-justified">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="encargado-general-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#encargado-general"
                          type="button"
                          role="tab"
                          aria-controls="encargado-general"
                          aria-selected="true"
                        >
                          Información General del Encargado
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="expedientes-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#expedientes"
                          type="button"
                          role="tab"
                          aria-controls="expedientes"
                          aria-selected="true"
                        >
                          Expedientes SEIA (
                          {encargado &&
                          encargado.expedientes &&
                          encargado.expedientes.length > 0
                            ? encargado.expedientes.length
                            : 0}
                          )
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pertinencias-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pertinencias"
                          type="button"
                          role="tab"
                          aria-controls="pertinencias"
                          aria-selected="true"
                        >
                          Pertinencias (
                          {encargado &&
                          encargado.pertinencias &&
                          encargado.pertinencias.length > 0
                            ? encargado.pertinencias.length
                            : 0}
                          )
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show mt-3"
                        id="encargado-general"
                        role="tabpanel"
                        aria-labelledby="encargado-general-tab"
                      >
                        <TablaPequena encargados={[encargado]} />
                      </div>
                      {encargado &&
                        encargado.expedientes &&
                        encargado.expedientes.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id="expedientes"
                            role="tabpanel"
                            aria-labelledby="expedientes-tab"
                          >
                            <Expedientes expedientes={encargado.expedientes} />
                          </div>
                        )}
                      {encargado &&
                        encargado.expedientes &&
                        encargado.expedientes.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id="pertinencias"
                            role="tabpanel"
                            aria-labelledby="pertinencias-tab"
                          >
                            <Pertinencias
                              pertinencias={encargado.pertinencias}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerEncargado: PropTypes.func.isRequired,
  encargado: PropTypes.object.isRequired,
  encargado_id: PropTypes.number.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  encargado: state.vigilancias.encargado,
  encargado_id: state.vigilancias.encargado_id,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerEncargado }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
