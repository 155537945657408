import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerFavoritos } from "../../../redux/actions/vigilancias/favoritos/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Tabla as Expedientes } from "../expedientes";
import { Tabla as Hechos } from "../hechos";
import { Tabla as MedidasProvisionales } from "../medidas";
import { Tabla as Pertinencias } from "../pertinencias";
import { Tabla as Procedimientos } from "../procedimientos";
import { Tabla as Programas } from "../programas";
import { Tabla as Requerimientos } from "../requerimientos";
import { Tabla as UnidadesFiscalizables } from "../unidades";

export class Listado extends Component {
  componentDidMount = () => {
    const { obtenerFavoritos } = this.props;
    obtenerFavoritos();
  };

  render = () => {
    const { is_loading_vigilancias, favoritos_vigilancia } = this.props;

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Favoritos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los favoritos, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_favoritos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-heart"></i> FAVORITOS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5>LISTADO DE FAVORITOS</h5>
                  </div>
                  <div className="card-body">
                    {favoritos_vigilancia &&
                    favoritos_vigilancia.expediente &&
                    favoritos_vigilancia.expediente.length === 0 &&
                    favoritos_vigilancia.pertinencia &&
                    favoritos_vigilancia.pertinencia.length === 0 &&
                    favoritos_vigilancia.procedimiento &&
                    favoritos_vigilancia.procedimiento.result.length === 0 &&
                    favoritos_vigilancia.hecho &&
                    favoritos_vigilancia.hecho.length === 0 &&
                    favoritos_vigilancia.unidad_fiscalizable &&
                    favoritos_vigilancia.unidad_fiscalizable.length === 0 &&
                    favoritos_vigilancia.medida_provisional &&
                    favoritos_vigilancia.medida_provisional.length === 0 &&
                    favoritos_vigilancia.programa_cumplimiento &&
                    favoritos_vigilancia.programa_cumplimiento.length === 0 ? (
                      <h6>No existen favoritos_vigilancia registrados</h6>
                    ) : (
                      <Fragment>
                        <ul className="nav nav-pills">
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.expediente &&
                            favoritos_vigilancia.expediente.result &&
                            favoritos_vigilancia.expediente.result.length >
                              0 && (
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active"
                                  id="seia-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#seia"
                                  type="button"
                                  role="tab"
                                  aria-controls="seia"
                                  aria-selected="true"
                                >
                                  Expedientes SEIA (
                                  {
                                    favoritos_vigilancia.expediente.result
                                      .length
                                  }
                                  )
                                </button>
                              </li>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.pertinencia &&
                            favoritos_vigilancia.pertinencia.result &&
                            favoritos_vigilancia.pertinencia.result.length >
                              0 && (
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="pertinencias-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pertinencias"
                                  type="button"
                                  role="tab"
                                  aria-controls="pertinencias"
                                  aria-selected="false"
                                >
                                  Consultas de pertinencias (
                                  {
                                    favoritos_vigilancia.pertinencia.result
                                      .length
                                  }
                                  )
                                </button>
                              </li>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.procedimiento &&
                            favoritos_vigilancia.procedimiento.result &&
                            favoritos_vigilancia.procedimiento.result.length >
                              0 && (
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="procedimientos-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#procedimientos"
                                  type="button"
                                  role="tab"
                                  aria-controls="procedimientos"
                                  aria-selected="false"
                                >
                                  Procedimientos sancionatorios (
                                  {
                                    favoritos_vigilancia.procedimiento.result
                                      .length
                                  }
                                  )
                                </button>
                              </li>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.hecho &&
                            favoritos_vigilancia.hecho.result &&
                            favoritos_vigilancia.hecho.result.length > 0 && (
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="hechos-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#hechos"
                                  type="button"
                                  role="tab"
                                  aria-controls="hechos"
                                  aria-selected="false"
                                >
                                  Hechos infraccionales (
                                  {favoritos_vigilancia.hecho.result.length})
                                </button>
                              </li>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.unidad_fiscalizable &&
                            favoritos_vigilancia.unidad_fiscalizable.result &&
                            favoritos_vigilancia.unidad_fiscalizable.result
                              .length > 0 && (
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="unidades-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#unidades"
                                  type="button"
                                  role="tab"
                                  aria-controls="unidades"
                                  aria-selected="false"
                                >
                                  Unidades fiscalizables (
                                  {
                                    favoritos_vigilancia.unidad_fiscalizable
                                      .result.length
                                  }
                                  )
                                </button>
                              </li>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.programa_cumplimiento &&
                            favoritos_vigilancia.programa_cumplimiento.result &&
                            favoritos_vigilancia.programa_cumplimiento.result
                              .length > 0 && (
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="programas-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#programas"
                                  type="button"
                                  role="tab"
                                  aria-controls="programas"
                                  aria-selected="false"
                                >
                                  Programa de cumplimiento (
                                  {
                                    favoritos_vigilancia.programa_cumplimiento
                                      .result.length
                                  }
                                  )
                                </button>
                              </li>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.medida_provisional &&
                            favoritos_vigilancia.medida_provisional.result &&
                            favoritos_vigilancia.medida_provisional.result
                              .length > 0 && (
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="medidas-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#medidas"
                                  type="button"
                                  role="tab"
                                  aria-controls="medidas"
                                  aria-selected="false"
                                >
                                  Medidas provisionales (
                                  {
                                    favoritos_vigilancia.medida_provisional
                                      .result.length
                                  }
                                  )
                                </button>
                              </li>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.requerimiento_ingreso &&
                            favoritos_vigilancia.requerimiento_ingreso.result &&
                            favoritos_vigilancia.requerimiento_ingreso.result
                              .length > 0 && (
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="requerimientos-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#requerimientos"
                                  type="button"
                                  role="tab"
                                  aria-controls="requerimientos"
                                  aria-selected="false"
                                >
                                  Requerimientos de Ingreso (
                                  {
                                    favoritos_vigilancia.requerimiento_ingreso
                                      .result.length
                                  }
                                  )
                                </button>
                              </li>
                            )}
                        </ul>
                        <div className="tab-content">
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.expediente &&
                            favoritos_vigilancia.expediente.result &&
                            favoritos_vigilancia.expediente.result.length >
                              0 && (
                              <div
                                className="tab-pane fade active show"
                                id="seia"
                                role="tabpanel"
                                aria-labelledby="seia-tab"
                              >
                                <h5 className="mb-3 mt-3 text-uppercase">
                                  Listado de expedientes SEIA
                                </h5>
                                <Expedientes
                                  eliminar_favorito
                                  expedientes={favoritos_vigilancia.expediente}
                                />
                              </div>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.pertinencia &&
                            favoritos_vigilancia.pertinencia.result &&
                            favoritos_vigilancia.pertinencia.result.length >
                              0 && (
                              <div
                                className="tab-pane fade"
                                id="pertinencias"
                                role="tabpanel"
                                aria-labelledby="pertinencias-tab"
                              >
                                <h5 className="mb-3 mt-3 text-uppercase">
                                  Listado de consultas de pertinencias
                                </h5>
                                <Pertinencias
                                  eliminar_favorito
                                  pertinencias={
                                    favoritos_vigilancia.pertinencia
                                  }
                                />
                              </div>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.procedimiento &&
                            favoritos_vigilancia.procedimiento.result &&
                            favoritos_vigilancia.procedimiento.result.length >
                              0 && (
                              <div
                                className="tab-pane fade"
                                id="procedimientos"
                                role="tabpanel"
                                aria-labelledby="procedimientos-tab"
                              >
                                <h5 className="mb-3 mt-3 text-uppercase">
                                  Listado de procedimientos sancionatorios
                                </h5>
                                <Procedimientos
                                  eliminar_favorito
                                  procedimientos={
                                    favoritos_vigilancia.procedimiento
                                  }
                                />
                              </div>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.hecho &&
                            favoritos_vigilancia.hecho.result &&
                            favoritos_vigilancia.hecho.result.length > 0 && (
                              <div
                                className="tab-pane fade"
                                id="hechos"
                                role="tabpanel"
                                aria-labelledby="hechos-tab"
                              >
                                <h5 className="mb-3 mt-3 text-uppercase">
                                  Listado de hechos infraccionales
                                </h5>
                                <Hechos
                                  eliminar_favorito
                                  hechos={favoritos_vigilancia.hecho}
                                />
                              </div>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.unidad_fiscalizable &&
                            favoritos_vigilancia.unidad_fiscalizable.result &&
                            favoritos_vigilancia.unidad_fiscalizable.result
                              .length > 0 && (
                              <div
                                className="tab-pane fade"
                                id="unidades"
                                role="tabpanel"
                                aria-labelledby="unidades-tab"
                              >
                                <h5 className="mb-3 mt-3 text-uppercase">
                                  Listado de unidades fiscalizables
                                </h5>
                                <UnidadesFiscalizables
                                  eliminar_favorito
                                  unidades_fiscalizables={
                                    favoritos_vigilancia.unidad_fiscalizable
                                  }
                                />
                              </div>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.programa_cumplimiento &&
                            favoritos_vigilancia.programa_cumplimiento.result &&
                            favoritos_vigilancia.programa_cumplimiento.result
                              .length > 0 && (
                              <div
                                className="tab-pane fade"
                                id="programas"
                                role="tabpanel"
                                aria-labelledby="programas-tab"
                              >
                                <h5 className="mb-3 mt-3 text-uppercase">
                                  Listado de progranas de cumplimiento
                                </h5>
                                <Programas
                                  eliminar_favorito
                                  programas={
                                    favoritos_vigilancia.programa_cumplimiento
                                  }
                                />
                              </div>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.medida_provisional &&
                            favoritos_vigilancia.medida_provisional.result &&
                            favoritos_vigilancia.medida_provisional.result
                              .length > 0 && (
                              <div
                                className="tab-pane fade"
                                id="medidas"
                                role="tabpanel"
                                aria-labelledby="medidas-tab"
                              >
                                <h5 className="mb-3 mt-3 text-uppercase">
                                  Listado de medidas provisionales
                                </h5>
                                <MedidasProvisionales
                                  eliminar_favorito
                                  medidas_provisionales={
                                    favoritos_vigilancia.medida_provisional
                                  }
                                />
                              </div>
                            )}
                          {favoritos_vigilancia &&
                            favoritos_vigilancia.requerimiento_ingreso &&
                            favoritos_vigilancia.requerimiento_ingreso.result &&
                            favoritos_vigilancia.requerimiento_ingreso.result
                              .length > 0 && (
                              <div
                                className="tab-pane fade"
                                id="requerimientos"
                                role="tabpanel"
                                aria-labelledby="requerimientos-tab"
                              >
                                <h5 className="mb-3 mt-3 text-uppercase">
                                  Listado de requerimientos de ingreso
                                </h5>
                                <Requerimientos
                                  eliminar_favorito
                                  requerimientos={
                                    favoritos_vigilancia.requerimiento_ingreso
                                  }
                                />
                              </div>
                            )}
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerFavoritos: PropTypes.func.isRequired,
  favoritos_vigilancia: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  favoritos_vigilancia: state.vigilancias.favoritos_vigilancia,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerFavoritos }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
