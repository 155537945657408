export const PERMISO_SELECCIONADO = 'PERMISO_SELECCIONADO';
export const PERMISO_OBTENER = 'PERMISO_OBTENER';
export const PERMISO_FALLIDO = 'PERMISO_FALLIDO';
export const PERMISOS_OBTENER = 'PERMISOS_OBTENER';
export const PERMISOS_FALLIDO = 'PERMISOS_FALLIDO';
export const PERMISOS_FILTROS_OBTENER = 'PERMISOS_FILTROS_OBTENER';
export const PERMISOS_FILTROS_FALLIDO = 'PERMISOS_FILTROS_FALLIDO';
export const CARGANDO_LIMPIAR_PERMITTING = 'CARGANDO_LIMPIAR_PERMITTING';
export const DASHBOARD_OBTENER = 'DASHBOARD_OBTENER';
export const DASHBOARD_FALLIDO = 'DASHBOARD_FALLIDO';
export const SECCIONES_OBTENER = 'SECCIONES_OBTENER';
export const SECCIONES_FALLIDO = 'SECCIONES_FALLIDO';
export const SECCION_SELECCIONADA = 'SECCION_SELECCIONADA';
export const CATEGORIAS_OBTENER = 'CATEGORIAS_OBTENER';
export const CATEGORIAS_FALLIDO = 'CATEGORIAS_FALLIDO';
export const PREGUNTAS_OBTENER = 'PREGUNTAS_OBTENER';
export const PREGUNTAS_FALLIDO = 'PREGUNTAS_FALLIDO';
export const CATEGORIA_SELECCIONADA = 'CATEGORIA_SELECCIONADA';
export const PROYECTOS_OBTENER = 'PROYECTOS_OBTENER';
export const PROYECTOS_FALLIDO = 'PROYECTOS_FALLIDO';
export const CONSULTA_CREAR_FALLIDO = 'CONSULTA_CREAR_FALLIDO';
export const CONSULTA_CREAR_OBTENER = 'CONSULTA_CREAR_OBTENER';
export const CONSULTA_DUPLICAR_FALLIDO = 'CONSULTA_DUPLICAR_FALLIDO';
export const CONSULTA_DUPLICAR_OBTENER = 'CONSULTA_DUPLICAR_OBTENER';
export const CONSULTA_ELIMINAR_FALLIDO = 'CONSULTA_ELIMINAR_FALLIDO';
export const CONSULTA_ELIMINAR_OBTENER = 'CONSULTA_ELIMINAR_OBTENER';
export const CONSULTA_SELECCIONADA = 'CONSULTA_SELECCIONADA';
export const RESPUESTAS_OBTENER = 'RESPUESTAS_OBTENER';
export const RESPUESTAS_FALLIDO = 'RESPUESTAS_FALLIDO';
export const GENERAR_ENTREGABLE_FALLIDO = 'GENERAR_ENTREGABLE_FALLIDO';
export const GENERAR_ENTREGABLE_OBTENER = 'GENERAR_ENTREGABLE_OBTENER';
export const ARCHIVO_PREGUNTA_FALLIDO = 'ARCHIVO_PREGUNTA_FALLIDO';
export const ARCHIVO_PERMISO_FALLIDO = 'ARCHIVO_PERMISO_FALLIDO';
export const CARGANDO_OK = 'CARGANDO_OK';
export const INTELIGENCIAS_FALLIDO = 'INTELIGENCIAS_FALLIDO';
export const INTELIGENCIAS_OBTENER = 'INTELIGENCIAS_OBTENER';
export const INTELIGENCIA_SELECCIONADA = 'INTELIGENCIA_SELECCIONADA';
export const INTELIGENCIA_CSV_FALLIDO = 'INTELIGENCIA_CSV_FALLIDO';
export const PROYECTO_CSV_FALLIDO = 'PROYECTO_CSV_FALLIDO';
export const PERMISO_TAREA_SELECCIONADO = 'PERMISO_TAREA_SELECCIONADO';
export const PERMISO_OMITIR_OBTENER = 'PERMISO_OMITIR_OBTENER';
export const PERMISO_OMITIR_FALLIDO = 'PERMISO_OMITIR_FALLIDO';
export const PERMISO_INCLUIDO_OBTENER = 'PERMISO_INCLUIDO_OBTENER';
export const PERMISO_INCLUIDO_FALLIDO = 'PERMISO_INCLUIDO_FALLIDO';
export const PERMISOS_REQUISITOS_OBTENER = 'PERMISOS_REQUISITOS_OBTENER';
export const PERMISOS_REQUISITOS_FALLIDO = 'PERMISOS_REQUISITOS_FALLIDO';
export const PERMISOS_REQUISITOS_TIMELINE_OBTENER = 'PERMISOS_REQUISITOS_TIMELINE_OBTENER';
export const PERMISOS_REQUISITOS_TIMELINE_FALLIDO = 'PERMISOS_REQUISITOS_TIMELINE_FALLIDO';
export const RESPUESTAS_INDEFINIDAS_OBTENER = 'RESPUESTAS_INDEFINIDAS_OBTENER';
export const RESPUESTAS_INDEFINIDAS_FALLIDO = 'RESPUESTAS_INDEFINIDAS_FALLIDO';
export const CATEGORIA_SECCION_SELECCIONADA = 'CATEGORIA_SECCION_SELECCIONADA';
