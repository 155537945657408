import React, { Component } from "react";

import { Link } from "react-router-dom";

export class Generadoras extends Component {
  render = () => {
    const { menu_1, menu_2, empresas } = this.props;
    return (
      empresas &&
      empresas.generadoras && (
        <>
          <h5 className="mt-3">GENERADORAS DE CHILE</h5>
          <li
            className={
              menu_1 &&
              menu_1 === "empresa_generadoras" &&
              menu_2 &&
              menu_2 === "dashboard"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/generadoras/estadistica">
              <i className="fa fa-chart-area"></i>
              <span> Dashboard</span>
            </Link>
          </li>
        </>
      )
    );
  };
}

export default Generadoras;
