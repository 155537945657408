import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import { format as formatRut } from "rut.js";
import {
  limpiarFiltroInstrumentosFiscalizados,
  obtenerInstrumentosInfringidos,
  obtenerInstrumentosInfringidosFiltros,
} from "../../../redux/actions/vigilancias/instrumentos_infringidos/action";

export class Instrumentos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_instrumento:
        this.props.instrumentos_infringidos_filtro_input &&
        this.props.instrumentos_infringidos_filtro_input.instrumento
          ? this.props.instrumentos_infringidos_filtro_input.instrumento
          : "",
      filtro_clasificacion:
        this.props.instrumentos_infringidos_filtro_input &&
        this.props.instrumentos_infringidos_filtro_input.clasificacion
          ? this.props.instrumentos_infringidos_filtro_input.clasificacion
          : "",
      filtro_clasificacion_detalle:
        this.props.instrumentos_infringidos_filtro_input &&
        this.props.instrumentos_infringidos_filtro_input.clasificacion_detalle
          ? this.props.instrumentos_infringidos_filtro_input
              .clasificacion_detalle
          : "",
      filtros_avanzandos:
        this.props.instrumentos_infringidos_filtro_input &&
        this.props.instrumentos_infringidos_filtro_input.filtros_avanzandos
          ? this.props.instrumentos_infringidos_filtro_input.filtros_avanzandos
          : false,
    };
  }

  borrarFiltros = () => {
    const {
      obtenerInstrumentosInfringidos,
      limpiarFiltroInstrumentosFiscalizados,
    } = this.props;
    obtenerInstrumentosInfringidos(1);
    limpiarFiltroInstrumentosFiscalizados();
    this.setState({
      filtro_instrumento: "",
      filtro_clasificacion: "",
      filtro_clasificacion_detalle: "",
    });
  };

  filtroHechos = () => {
    const {
      obtenerInstrumentosInfringidosFiltros,
      obtenerInstrumentosInfringidos,
    } = this.props;
    const {
      filtro_instrumento,
      filtro_clasificacion,
      filtro_clasificacion_detalle,
      filtros_avanzandos,
    } = this.state;

    if (
      filtro_instrumento ||
      filtro_clasificacion ||
      filtro_clasificacion_detalle
    ) {
      var filtros = {
        instrumento: filtro_instrumento ? filtro_instrumento : null,
        clasificacion: filtro_clasificacion ? filtro_clasificacion : null,
        clasificacion_detalle: filtro_clasificacion_detalle
          ? filtro_clasificacion_detalle
          : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerInstrumentosInfringidosFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerInstrumentosInfringidos(1);
    }
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (e.target.name === "filtro_titular_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  render = () => {
    const { filtros_instrumentos_infringidos, filtros_errores } = this.props;
    const {
      filtro_instrumento,
      filtro_clasificacion,
      filtro_clasificacion_detalle,
      filtros_avanzandos,
    } = this.state;

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Instrumento</label>
                <SelectPicker
                  name="filtro_instrumento"
                  data={
                    filtros_instrumentos_infringidos.instrumentos
                      ? filtros_instrumentos_infringidos.instrumentos
                      : []
                  }
                  onChange={(value) => {
                    this.setState({ filtro_instrumento: value });
                  }}
                  value={filtro_instrumento}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.instrumento &&
                  filtros_errores.data.instrumento.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Clasificación</label>
                <SelectPicker
                  name="filtro_clasificacion"
                  data={
                    filtros_instrumentos_infringidos.clasificacion
                      ? filtros_instrumentos_infringidos.clasificacion
                      : []
                  }
                  onChange={(value) => {
                    this.setState({ filtro_clasificacion: value });
                  }}
                  value={filtro_clasificacion}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.clasificacion &&
                  filtros_errores.data.clasificacion.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Clasificación detalle</label>
                <SelectPicker
                  name="filtro_clasificacion_detalle"
                  data={
                    filtros_instrumentos_infringidos.clasificacion_detalle
                      ? filtros_instrumentos_infringidos.clasificacion_detalle
                      : []
                  }
                  onChange={(value) => {
                    this.setState({ filtro_clasificacion_detalle: value });
                  }}
                  value={filtro_clasificacion_detalle}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.clasificacion_detalle &&
                  filtros_errores.data.clasificacion_detalle.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroHechos}
            >
              <i className="fa fa-search"></i> Filtrar instrumentos infringidos
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Instrumentos.propTypes = {
  obtenerInstrumentosInfringidos: PropTypes.func.isRequired,
  obtenerInstrumentosInfringidosFiltros: PropTypes.func.isRequired,
  limpiarFiltroInstrumentosFiscalizados: PropTypes.func.isRequired,
  filtros_instrumentos_infringidos: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  instrumentos_infringidos_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_instrumentos_infringidos:
    state.vigilancias.filtros_instrumentos_infringidos,
  filtros_errores: state.vigilancias.filtros_errores,
  instrumentos_infringidos_filtro_input:
    state.vigilancias.instrumentos_infringidos_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerInstrumentosInfringidos,
      obtenerInstrumentosInfringidosFiltros,
      limpiarFiltroInstrumentosFiscalizados,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Instrumentos);
