export const QUINENCO_UNIDADES_OBTENER = "QUINENCO_UNIDADES_OBTENER";
export const QUINENCO_UNIDADES_FALLIDO = "QUINENCO_UNIDADES_FALLIDO";
export const QUINENCO_EXPEDIENTES_OBTENER = "QUINENCO_EXPEDIENTES_OBTENER";
export const QUINENCO_EXPEDIENTES_FALLIDO = "QUINENCO_EXPEDIENTES_FALLIDO";
export const QUINENCO_PERTINENCIAS_OBTENER = "QUINENCO_PERTINENCIAS_OBTENER";
export const QUINENCO_PERTINENCIAS_FALLIDO = "QUINENCO_PERTINENCIAS_FALLIDO";
export const QUINENCO_DASHBOARD_OBTENER = "QUINENCO_DASHBOARD_OBTENER";
export const QUINENCO_DASHBOARD_FALLIDO = "QUINENCO_DASHBOARD_FALLIDO";
export const QUINENCO_PERTINENCIAS_FILTROS_INPUT =
  "QUINENCO_PERTINENCIAS_FILTROS_INPUT";
export const QUINENCO_PERTINENCIAS_FILTROS_OBTENER =
  "QUINENCO_PERTINENCIAS_FILTROS_OBTENER";
export const QUINENCO_PERTINENCIAS_FILTROS_FALLIDO =
  "QUINENCO_PERTINENCIAS_FILTROS_FALLIDO";
export const QUINENCO_LIMPIAR_PERTINENCIAS_FILTROS =
  "QUINENCO_LIMPIAR_PERTINENCIAS_FILTROS";
export const QUINENCO_EXPEDIENTES_FILTROS_FALLIDO =
  "QUINENCO_EXPEDIENTES_FILTROS_FALLIDO";
export const QUINENCO_EXPEDIENTES_FILTROS_OBTENER =
  "QUINENCO_EXPEDIENTES_FILTROS_OBTENER";
export const QUINENCO_EXPEDIENTES_FILTROS_INPUT =
  "QUINENCO_EXPEDIENTES_FILTROS_INPUT";
export const QUINENCO_LIMPIAR_EXPEDIENTES_FILTROS =
  "QUINENCO_LIMPIAR_EXPEDIENTES_FILTROS";
export const QUINENCO_LIMPIAR_UNIDADES_FILTROS =
  "QUINENCO_LIMPIAR_UNIDADES_FILTROS";
export const QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_FALLIDO =
  "QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_FALLIDO";
export const QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_INPUT =
  "QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_INPUT";
export const QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_OBTENER =
  "QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_OBTENER";
