import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import {
  limpiarFiltroExpedientesMapa,
  obtenerExpedientesMapaFiltros,
} from "../../../redux/actions/vigilancias/expedientes/action";

export class Expedientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_tipologia:
        this.props.expedientes_mapa_filtro_input &&
        this.props.expedientes_mapa_filtro_input.tipologia
          ? this.props.expedientes_mapa_filtro_input.tipologia
          : "",
      filtro_estado:
        this.props.expedientes_mapa_filtro_input &&
        this.props.expedientes_mapa_filtro_input.estado
          ? this.props.expedientes_mapa_filtro_input.estado
          : "",
      filtro_region:
        this.props.expedientes_mapa_filtro_input &&
        this.props.expedientes_mapa_filtro_input.region
          ? this.props.expedientes_mapa_filtro_input.region
          : "",
      filtro_comuna:
        this.props.expedientes_mapa_filtro_input &&
        this.props.expedientes_mapa_filtro_input.comuna
          ? this.props.expedientes_mapa_filtro_input.comuna
          : "",
      filtro_tipo:
        this.props.expedientes_mapa_filtro_input &&
        this.props.expedientes_mapa_filtro_input.tipo
          ? this.props.expedientes_mapa_filtro_input.tipo
          : "",
      filtro_recursos:
        this.props.expedientes_mapa_filtro_input &&
        this.props.expedientes_mapa_filtro_input.recursos
          ? this.props.expedientes_mapa_filtro_input.recursos
          : "",
      filtro_pas:
        this.props.expedientes_mapa_filtro_input &&
        this.props.expedientes_mapa_filtro_input.pas
          ? this.props.expedientes_mapa_filtro_input.pas
          : "",
    };
  }

  filtroExpedientes = () => {
    const { obtenerExpedientesMapaFiltros } = this.props;
    const {
      filtro_tipologia,
      filtro_estado,
      filtro_region,
      filtro_tipo,
      filtro_comuna,
      filtro_recursos,
      filtro_pas,
    } = this.state;

    if (
      filtro_tipologia ||
      filtro_estado ||
      filtro_region ||
      filtro_tipo ||
      filtro_comuna ||
      filtro_recursos ||
      filtro_pas
    ) {
      obtenerExpedientesMapaFiltros({
        tipologia: filtro_tipologia ? filtro_tipologia : null,
        estado: filtro_estado ? filtro_estado : null,
        region: filtro_region ? filtro_region : null,
        comuna: filtro_comuna ? filtro_comuna : null,
        tipo: filtro_tipo ? filtro_tipo : null,
        recursos: filtro_recursos ? filtro_recursos : null,
        pas: filtro_pas ? filtro_pas : null,
      });
    } else {
    }
  };

  borrarFiltros = () => {
    const { limpiarFiltroExpedientesMapa } = this.props;
    limpiarFiltroExpedientesMapa();
    this.setState({
      filtro_tipologia: "",
      filtro_estado: "",
      filtro_region: "",
      filtro_comuna: "",
      filtro_tipo: "",
      filtro_recursos: "",
      filtro_pas: "",
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render = () => {
    const { filtros_expedientes_mapa, filtros_errores } = this.props;
    const {
      filtro_tipologia,
      filtro_estado,
      filtro_region,
      filtro_tipo,
      filtro_comuna,
      filtro_recursos,
      filtro_pas,
    } = this.state;

    const tipologias_listado =
      filtros_expedientes_mapa && filtros_expedientes_mapa["tipologias"]
        ? _.orderBy(filtros_expedientes_mapa["tipologias"], "id")
        : [];
    const estados_listado =
      filtros_expedientes_mapa && filtros_expedientes_mapa["estados"]
        ? _.orderBy(filtros_expedientes_mapa["estados"], "nombre")
        : [];
    const regiones_listado =
      filtros_expedientes_mapa && filtros_expedientes_mapa["regiones"]
        ? _.orderBy(filtros_expedientes_mapa["regiones"], "nombre")
        : [];
    const comunas_listado =
      filtros_expedientes_mapa && filtros_expedientes_mapa["comunas"]
        ? _.orderBy(filtros_expedientes_mapa["comunas"]).map((comuna) => ({
            label: comuna,
            value: comuna,
          }))
        : [];
    const tipos_listado =
      filtros_expedientes_mapa && filtros_expedientes_mapa["tipos"]
        ? _.orderBy(filtros_expedientes_mapa["tipos"], "value")
        : [];
    const pas_listado =
      filtros_expedientes_mapa && filtros_expedientes_mapa["pas"]
        ? filtros_expedientes_mapa["pas"]
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5>FILTROS</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Tipología del proyecto</label>

                <SelectPicker
                  name="filtro_tipologia"
                  data={tipologias_listado}
                  onChange={(value) => {
                    this.setState({ filtro_tipologia: value });
                  }}
                  value={filtro_tipologia}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />

                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipologia &&
                  filtros_errores.data.tipologia.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Estado</label>
                <SelectPicker
                  name="filtro_estado"
                  data={estados_listado}
                  onChange={(value) => {
                    this.setState({ filtro_estado: value });
                  }}
                  value={filtro_estado}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.estado &&
                  filtros_errores.data.estado.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Región</label>
                <SelectPicker
                  name="filtro_region"
                  data={regiones_listado}
                  onChange={(value) => {
                    this.setState({ filtro_region: value });
                  }}
                  value={filtro_region}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.region &&
                  filtros_errores.data.region.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Comuna</label>
                <SelectPicker
                  name="filtro_comuna"
                  data={comunas_listado}
                  onChange={(value) => {
                    this.setState({ filtro_comuna: value });
                  }}
                  value={filtro_comuna}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.comuna &&
                  filtros_errores.data.comuna.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Tipo de instrumento</label>
                <div className="input-group">
                  <select
                    name="filtro_tipo"
                    onChange={this.onChange}
                    value={filtro_tipo}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.estado
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    {tipos_listado.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.tipo &&
                    filtros_errores.data.tipo.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>¿Tiene recursos administrativos?</label>
                <div className="input-group">
                  <select
                    name="filtro_recursos"
                    onChange={this.onChange}
                    value={filtro_recursos}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.recursos
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.recursos &&
                    filtros_errores.data.recursos.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>PAS</label>
                <SelectPicker
                  name="filtro_pas"
                  data={pas_listado}
                  onChange={(value) => {
                    this.setState({ filtro_pas: value });
                  }}
                  value={filtro_pas}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />

                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.pas &&
                  filtros_errores.data.pas.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroExpedientes}
            >
              <i className="fa fa-search"></i> Filtrar expedientes SEIA
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Expedientes.propTypes = {
  obtenerExpedientesMapaFiltros: PropTypes.func.isRequired,
  limpiarFiltroExpedientesMapa: PropTypes.func.isRequired,
  filtros_expedientes_mapa: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  expedientes_mapa_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_expedientes_mapa: state.vigilancias.filtros_expedientes_mapa,
  filtros_errores: state.vigilancias.filtros_errores,
  expedientes_mapa_filtro_input:
    state.vigilancias.expedientes_mapa_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerExpedientesMapaFiltros, limpiarFiltroExpedientesMapa },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Expedientes);
