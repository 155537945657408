import React, { Component } from "react";
import { Tabs as Expedientes } from "../expedientes";
import { TablaPequena as Titulares } from "../titulares";
import { Tabs as UnidadesFiscalizable } from "../unidades";

export class Tabs extends Component {
  render = () => {
    const { rcas } = this.props;
    if (rcas && rcas.length < 1) {
      return <h6>No existen documento</h6>;
    } else {
      return (
        <div className="align-items-start">
          <div className="row">
            {rcas.length > 1 && (
              <div className="col-4">
                <div
                  className="nav flex-column nav-pills me-3"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {rcas.map((rca, x) => (
                    <button
                      key={x}
                      className={`nav-link ${
                        x === 0 ? "active" : ""
                      } text-start`}
                      id={`rca-tab-${rca.id}`}
                      data-bs-toggle="pill"
                      data-bs-target={`#rca-${rca.id}`}
                      type="button"
                      role="tab"
                      aria-controls={`rca-${rca.id}`}
                      aria-selected={x === 0 ? "true" : "false"}
                    >
                      <>
                        {rca.anio} <span className="ms-1 me-1">|</span>{" "}
                      </>{" "}
                      <span className="text-uppercase">{rca.nombre}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className={`col-${rcas.length > 1 ? 8 : 12}`}>
              <div className="tab-content" id="v-pills-rcas">
                {rcas.map((rca, x) => (
                  <div
                    key={x}
                    className={`tab-pane fade ${x === 0 ? "show active" : ""}`}
                    id={`rca-${rca.id}`}
                    role="tabpanel"
                    aria-labelledby={`rca-tab-${rca.id}`}
                    tabIndex="0"
                  >
                    <ul className="nav nav-pills nav-justified">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id={`rca-general-tab-${rca.id}`}
                          data-bs-toggle="pill"
                          data-bs-target={`#rca-general-${rca.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`rca-general-${rca.id}`}
                          aria-selected="true"
                        >
                          Información RCA
                        </button>
                      </li>
                      {rca.unidad_fiscalizable &&
                        rca.unidad_fiscalizable.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`rca-unidades-tab-${rca.id}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#rca-unidades-${rca.id}`}
                              type="button"
                              role="tab"
                              aria-controls={`rca-unidades-${rca.id}`}
                              aria-selected="false"
                            >
                              Unidades Fiscalizables{" "}
                              <small>({rca.unidad_fiscalizable.length})</small>
                            </button>
                          </li>
                        )}
                      {rca.expediente && (
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id={`rca-expedientes-tab-${rca.id}`}
                            data-bs-toggle="pill"
                            data-bs-target={`#rca-expedientes-${rca.id}`}
                            type="button"
                            role="tab"
                            aria-controls={`rca-expedientes-${rca.id}`}
                            aria-selected="false"
                          >
                            Expediente SEIA
                          </button>
                        </li>
                      )}
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show mt-3"
                        id={`rca-general-${rca.id}`}
                        role="tabpanel"
                        aria-labelledby={`rca-general-tab-${rca.id}`}
                      >
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td className="table-secondary w-10 text-dark">
                                  Nombre
                                </td>
                                <td>{rca.nombre}</td>
                              </tr>
                              {rca.tipo && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Tipo
                                  </td>
                                  <td>{rca.tipo}</td>
                                </tr>
                              )}
                              {rca.fase && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Fase
                                  </td>
                                  <td>{rca.fase}</td>
                                </tr>
                              )}
                              {rca.anio && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Año
                                  </td>
                                  <td>{rca.anio}</td>
                                </tr>
                              )}
                              {rca.numero && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Número RCA
                                  </td>
                                  <td>{rca.numero}</td>
                                </tr>
                              )}
                              {rca.region && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Region
                                  </td>
                                  <td>{rca.region}</td>
                                </tr>
                              )}
                              {rca.titular && rca.titular.length > 0 && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Titular
                                  </td>
                                  <td>
                                    <Titulares titulares={rca.titular} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {rca.unidad_fiscalizable &&
                        rca.unidad_fiscalizable.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`rca-unidades-${rca.id}`}
                            role="tabpanel"
                            aria-labelledby={`rca-unidades-tab-${rca.id}`}
                          >
                            <UnidadesFiscalizable
                              unidades_fiscalizables={rca.unidad_fiscalizable}
                            />
                          </div>
                        )}
                      {rca.expediente && (
                        <div
                          className="tab-pane fade mt-3"
                          id={`rca-expedientes-${rca.id}`}
                          role="tabpanel"
                          aria-labelledby={`rca-expedientes-tab-${rca.id}`}
                        >
                          <Expedientes expedientes={[rca.expediente]} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}

export default Tabs;
