import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerNotificaciones } from "../../redux/actions/usuario/notificaciones/action";
import { Footer, Navbar, SidebarInicio } from "../general";

export class Notificaciones extends Component {
  componentDidMount = () => {
    const { obtenerNotificaciones, notificaciones, user } = this.props;
    if (
      Object.entries(notificaciones).length === 0 &&
      user.modulos &&
      (user.modulos.vigilances || user.modulos.laws)
    ) {
      obtenerNotificaciones();
    }
  };

  render = () => {
    const { notificaciones, is_loading_vigilancias } = this.props;
    const createMarkup = (htmlString) => ({ __html: htmlString });
    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Notificaciones",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las notificaciones, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="notificaciones" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-bell"></i> NOTIFICACIONES
                  </h4>
                </div>
              </div>
            </div>
            {notificaciones && (
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="mb-1">
                        LISTADO DE NOTIFICACIONES{" "}
                        <small>({notificaciones.length})</small>
                      </h5>
                      <p>
                        Últimos 30 días de Notificación enviado al correo por
                        parte de Cumplimiento Lean
                      </p>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-secondary">
                            <tr>
                              <th className="text-center w-3">ID</th>
                              <th className="w-12">Fecha de la Notificación</th>
                              <th className="w-25">Tipo de Notificación</th>
                              <th>Asunto de la Notificación</th>
                              <th className="w-5 text-center">Ver</th>
                            </tr>
                          </thead>
                          <tbody>
                            {notificaciones && notificaciones.length > 0 ? (
                              notificaciones.map((notificacion, x) => (
                                <tr key={x}>
                                  <td className="text-center">{x + 1}</td>
                                  <td>
                                    <span className="badge badge-soft-danger font-12">
                                      {notificacion.fecha}
                                    </span>
                                  </td>
                                  <td>{notificacion.tipo_display}</td>
                                  <td>{notificacion.nombre}</td>
                                  <td>
                                    <div className="text-center">
                                      <button
                                        className="btn btn-soft-dark"
                                        data-bs-toggle="modal"
                                        data-bs-target={
                                          "#modalNotificacion" + x
                                        }
                                      >
                                        <i className="fa fa fa-angle-right"></i>
                                      </button>
                                    </div>
                                    <div
                                      className="modal fade"
                                      id={"modalNotificacion" + x}
                                      tabIndex="-1"
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5 className="modal-title">
                                              {notificacion.nombre}
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <div
                                              dangerouslySetInnerHTML={createMarkup(
                                                notificacion.mensaje
                                              )}
                                            />
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              data-bs-dismiss="modal"
                                            >
                                              Cerrar
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4" className="text-center">
                                  Sin notificaciones registradas
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Notificaciones.propTypes = {
  notificaciones: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  notificaciones: state.vigilancias.notificaciones,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerNotificaciones }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Notificaciones);
