import React, { Component } from "react";
import { TablaPequena as Titulares } from "../titulares";
import { TablaPequena as Unidades } from "../unidades";

export class TablaMediaTeams extends Component {
  render = () => {
    const { procedimiento } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-borderless">
          <tbody>
            <tr>
              <td className="w-15 text-center">
                <a
                  href={procedimiento.url_snifa}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {procedimiento.expediente}
                  <i className="ms-1 fa fa-angle-right"></i>
                </a>
                <p className="mb-0">
                  <span className="badge badge-soft-danger text-center">
                    {procedimiento.estado}
                  </span>
                </p>
              </td>
              <td className="w-30">
                <Titulares titulares={procedimiento.titulares} />
              </td>
              <td className="w-30">
                <Unidades unidades_fiscalizables={procedimiento.unidades} />
              </td>
              <td className="w-30">
                <span className="badge badge-danger font-11">
                  Último Movimiento
                </span>
                <p className="mb-0">{procedimiento.ultimo_documento.fecha}</p>
                <p className="mb-0 small text-uppercase">
                  {procedimiento.ultimo_documento.nombre}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}

export default TablaMediaTeams;
