import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { guardarFavorito } from "../../../redux/actions/vigilancias/favoritos/action";

export class Guardar extends Component {
  setFavorito = () => {
    const { tipo, id, guardarFavorito } = this.props;
    guardarFavorito({
      tipo: tipo,
      id: id,
    });
  };

  render = () => {
    const { favorito_guardado } = this.props;

    if (Object.entries(favorito_guardado).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Favorito",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: favorito_guardado.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-success me-3",
        },
        buttonsStyling: false,
      });
    }
    return (
      <button onClick={this.setFavorito.bind(this)} className="btn btn-warning">
        Agregar como favorito <i className="fa fa-star"></i>
      </button>
    );
  };
}

Guardar.propTypes = {
  guardarFavorito: PropTypes.func.isRequired,
  favorito_guardado: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  favorito_guardado: state.vigilancias.favorito_guardado,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarFavorito }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Guardar);
