import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import { format as formatRut } from "rut.js";
import {
  limpiarFiltroFiscalizaciones,
  obtenerFiscalizaciones,
  obtenerFiscalizacionesFiltros,
} from "../../../redux/actions/vigilancias/fiscalizaciones/action";

export class Fiscalizaciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_expediente:
        this.props.fiscalizaciones_filtro_input &&
        this.props.fiscalizaciones_filtro_input.expediente
          ? this.props.fiscalizaciones_filtro_input.expediente
          : "",
      filtro_tipo:
        this.props.fiscalizaciones_filtro_input &&
        this.props.fiscalizaciones_filtro_input.tipo
          ? this.props.fiscalizaciones_filtro_input.tipo
          : "",
      filtro_instrumento_tipo:
        this.props.fiscalizaciones_filtro_input &&
        this.props.fiscalizaciones_filtro_input.instrumento_tipo
          ? this.props.fiscalizaciones_filtro_input.instrumento_tipo
          : "",
      filtro_instrumento_numero:
        this.props.fiscalizaciones_filtro_input &&
        this.props.fiscalizaciones_filtro_input.instrumento_numero
          ? this.props.fiscalizaciones_filtro_input.instrumento_numero
          : "",
      filtro_instrumento_anio:
        this.props.fiscalizaciones_filtro_input &&
        this.props.fiscalizaciones_filtro_input.instrumento_anio
          ? this.props.fiscalizaciones_filtro_input.instrumento_anio
          : "",
      filtro_estado:
        this.props.fiscalizaciones_filtro_input &&
        this.props.fiscalizaciones_filtro_input.estado
          ? this.props.fiscalizaciones_filtro_input.estado
          : "",
      filtro_anio:
        this.props.fiscalizaciones_filtro_input &&
        this.props.fiscalizaciones_filtro_input.anio
          ? this.props.fiscalizaciones_filtro_input.anio
          : "",
      filtros_avanzandos:
        this.props.fiscalizaciones_filtro_input &&
        this.props.fiscalizaciones_filtro_input.filtros_avanzandos
          ? this.props.fiscalizaciones_filtro_input.filtros_avanzandos
          : false,
    };
  }

  borrarFiltros = () => {
    const { obtenerFiscalizaciones, limpiarFiltroFiscalizaciones } = this.props;
    obtenerFiscalizaciones(1);
    limpiarFiltroFiscalizaciones();
    this.setState({
      filtro_expediente: "",
      filtro_tipo: "",
      filtro_instrumento_tipo: "",
      filtro_instrumento_numero: "",
      filtro_instrumento_anio: "",
      filtro_estado: "",
      filtro_anio: "",
    });
  };

  filtroHechos = () => {
    const { obtenerFiscalizacionesFiltros, obtenerFiscalizaciones } =
      this.props;
    const {
      filtro_expediente,
      filtro_tipo,
      filtro_instrumento_tipo,
      filtro_instrumento_numero,
      filtro_instrumento_anio,
      filtros_avanzandos,
      filtro_estado,
      filtro_anio,
    } = this.state;

    if (
      filtro_expediente ||
      filtro_tipo ||
      filtro_instrumento_tipo ||
      filtro_instrumento_numero ||
      filtro_instrumento_anio ||
      filtro_estado ||
      filtro_anio
    ) {
      var filtros = {
        expediente: filtro_expediente ? filtro_expediente : null,
        tipo: filtro_tipo ? filtro_tipo : null,
        instrumento_tipo: filtro_instrumento_tipo
          ? filtro_instrumento_tipo
          : null,
        instrumento_numero: filtro_instrumento_numero
          ? filtro_instrumento_numero
          : null,
        instrumento_anio: filtro_instrumento_anio
          ? filtro_instrumento_anio
          : null,
        estado: filtro_estado ? filtro_estado : null,
        anio: filtro_anio ? filtro_anio : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerFiscalizacionesFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerFiscalizaciones(1);
    }
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (e.target.name === "filtro_titular_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  render = () => {
    const { filtros_fiscalizaciones, filtros_errores } = this.props;
    const {
      filtro_expediente,
      filtro_tipo,
      filtro_instrumento_tipo,
      filtro_instrumento_numero,
      filtro_instrumento_anio,
      filtro_anio,
      filtro_estado,
      filtros_avanzandos,
    } = this.state;

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Expediente</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_expediente"
                    value={filtro_expediente}
                    onChange={this.onChange}
                    placeholder="Ingrese expediente"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.expediente
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.expediente &&
                    filtros_errores.data.expediente.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Año</label>
                <SelectPicker
                  name="filtro_anio"
                  data={
                    filtros_fiscalizaciones.anios
                      ? filtros_fiscalizaciones.anios
                      : []
                  }
                  onChange={(value) => {
                    this.setState({ filtro_anio: value });
                  }}
                  value={filtro_anio}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.anio &&
                  filtros_errores.data.anio.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipo</label>
                <SelectPicker
                  name="filtro_tipo"
                  data={
                    filtros_fiscalizaciones.tipos
                      ? filtros_fiscalizaciones.tipos
                      : []
                  }
                  onChange={(value) => {
                    this.setState({ filtro_tipo: value });
                  }}
                  value={filtro_tipo}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipo &&
                  filtros_errores.data.tipo.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Estado</label>
                <SelectPicker
                  name="filtro_estado"
                  data={
                    filtros_fiscalizaciones.estados
                      ? filtros_fiscalizaciones.estados
                      : []
                  }
                  onChange={(value) => {
                    this.setState({ filtro_estado: value });
                  }}
                  value={filtro_estado}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.estado &&
                  filtros_errores.data.estado.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-4 col-sm-6 col-12 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Instrumento Fiscalizado</label>
                <div className="input-group">
                  <SelectPicker
                    name="filtro_instrumento_tipo"
                    data={
                      filtros_fiscalizaciones.instrumentos_tipo
                        ? filtros_fiscalizaciones.instrumentos_tipo
                        : []
                    }
                    onChange={(value) => {
                      this.setState({ filtro_instrumento_tipo: value });
                    }}
                    value={filtro_instrumento_tipo}
                    locale={{
                      noResultsText: "Sin resultados",
                      placeholder: "Selecciona una opción",
                      searchPlaceholder: "Buscar",
                    }}
                    block
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.instrumento_tipo &&
                    filtros_errores.data.instrumento_tipo.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                  <SelectPicker
                    name="filtro_instrumento_anio"
                    data={
                      filtros_fiscalizaciones.instrumentos_anio
                        ? filtros_fiscalizaciones.instrumentos_anio
                        : []
                    }
                    onChange={(value) => {
                      this.setState({ filtro_instrumento_anio: value });
                    }}
                    value={filtro_instrumento_anio}
                    locale={{
                      noResultsText: "Sin resultados",
                      placeholder: "Selecciona una opción",
                      searchPlaceholder: "Buscar",
                    }}
                    block
                  />
                  <input
                    type="text"
                    placeholder="Número"
                    name="filtro_instrumento_numero"
                    value={filtro_instrumento_numero}
                    onChange={this.onChange}
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.instrumento_numero
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroHechos}
            >
              <i className="fa fa-search"></i> Filtrar fiscalizaciones
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Fiscalizaciones.propTypes = {
  obtenerFiscalizaciones: PropTypes.func.isRequired,
  obtenerFiscalizacionesFiltros: PropTypes.func.isRequired,
  limpiarFiltroFiscalizaciones: PropTypes.func.isRequired,
  filtros_fiscalizaciones: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  fiscalizaciones_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_fiscalizaciones: state.vigilancias.filtros_fiscalizaciones,
  filtros_errores: state.vigilancias.filtros_errores,
  fiscalizaciones_filtro_input: state.vigilancias.fiscalizaciones_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerFiscalizaciones,
      obtenerFiscalizacionesFiltros,
      limpiarFiltroFiscalizaciones,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Fiscalizaciones);
