import axios from 'axios';

import { tokenConfig } from '../../auth/action';
import { AUTENTIFICACION_FALLIDO } from '../../auth/types';
import { CARGANDO_LIMPIAR_TAREAS } from '../types';
import {
  PROYECTO_EDITAR_FALLIDO,
  PROYECTO_EDITAR_OBTENER,
  PROYECTO_ELIMINAR_FALLIDO,
  PROYECTO_ELIMINAR_OBTENER,
  PROYECTO_NUEVO_FALLIDO,
  PROYECTO_NUEVO_OBTENER,
  PROYECTOS_TAREAS_FALLIDO,
  PROYECTOS_TAREAS_OBTENER,
} from './types';

var url_api = process.env.REACT_APP_API_URL;


export const obtenerProyectos = () => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_TAREAS });
  axios
    .get(`${url_api}/api/tasks/proyectos`, tokenConfig(getState))
    .then(res => {
      dispatch({ type: PROYECTOS_TAREAS_OBTENER, payload: res.data });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PROYECTOS_TAREAS_FALLIDO,
        payload: error
      });
    });
};

export const creacionProyecto = data => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/tasks/proyecto`, data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: PROYECTO_NUEVO_OBTENER, payload: res.data });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PROYECTO_NUEVO_FALLIDO,
        payload: error
      });
    });
};

export const editarProyecto = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/proyecto`, data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: PROYECTO_EDITAR_OBTENER, payload: res.data });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PROYECTO_EDITAR_FALLIDO,
        payload: error
      });
    });
};

export const eliminarProyecto = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/tasks/proyecto/eliminar`, data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: PROYECTO_ELIMINAR_OBTENER, payload: res.data });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PROYECTO_ELIMINAR_FALLIDO,
        payload: error
      });
    });
};
