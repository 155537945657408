import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  obtenerProgramasCumplimiento,
  obtenerProgramasCumplimientoFiltros,
} from "../../../redux/actions/vigilancias/programas/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Filtros, Tabla } from "./";

export class Programas extends Component {
  componentDidMount = () => {
    const { obtenerProgramasCumplimiento, programas } = this.props;
    if (Object.entries(programas).length === 0) {
      obtenerProgramasCumplimiento(1);
    }
  };

  nuevaPagina = (pagina) => {
    const {
      obtenerProgramasCumplimiento,
      obtenerProgramasCumplimientoFiltros,
      programas_filtros,
      programas_filtro_input,
    } = this.props;
    if (Object.entries(programas_filtros).length > 0) {
      obtenerProgramasCumplimientoFiltros({
        filtros: programas_filtro_input,
        pagina: pagina,
      });
    } else {
      obtenerProgramasCumplimiento(pagina);
    }
  };

  render = () => {
    const { programas, programas_filtros, is_loading_vigilancias } = this.props;

    var programas_result = programas;
    if (Object.entries(programas_filtros).length > 0) {
      programas_result = programas_filtros;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Programas de Cumplimiento",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los programas de cumplimiento, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_programas" menu_2="listado" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-handshake"></i> PROGRAMAS DE
                    CUMPLIMIENTO
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE PROGRAMAS DE CUMPLIMIENTO{" "}
                      <small>
                        (
                        {programas_result && programas_result.total
                          ? programas_result.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={programas_result}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla programas={programas_result} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Programas.propTypes = {
  obtenerProgramasCumplimiento: PropTypes.func.isRequired,
  obtenerProgramasCumplimientoFiltros: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  programas: PropTypes.object.isRequired,
  programas_filtros: PropTypes.object.isRequired,
  programas_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  programas: state.vigilancias.programas,
  programas_filtros: state.vigilancias.programas_filtros,
  programas_filtro_input: state.vigilancias.programas_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerProgramasCumplimiento, obtenerProgramasCumplimientoFiltros },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Programas);
