import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format as formatRut } from "rut.js";

import {
  obtenerTitulares,
  obtenerTitularesFiltros,
} from "../../../redux/actions/vigilancias/titulares/action";

export class Titulares extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre: "",
      filtro_rut: "",
    };
  }

  filtroTitulares = () => {
    const { obtenerTitularesFiltros, obtenerTitulares } = this.props;
    const { filtro_nombre, filtro_rut } = this.state;

    if (filtro_nombre || filtro_rut) {
      obtenerTitularesFiltros({
        nombre: filtro_nombre ? filtro_nombre : null,
        rut: filtro_rut ? filtro_rut : null,
      });
    } else {
      obtenerTitulares(1);
    }
  };

  borrarFiltros = () => {
    const { obtenerTitulares } = this.props;
    obtenerTitulares(1);
    this.setState({
      filtro_nombre: "",
      filtro_rut: "",
    });
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (e.target.name === "filtro_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  render = () => {
    const { filtros_errores } = this.props;
    const { filtro_nombre, filtro_rut } = this.state;

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5>FILTROS</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  name="filtro_nombre"
                  onChange={this.onChange}
                  value={filtro_nombre}
                  placeholder="Ingrese nombre del titular"
                  className={`form-control ${
                    filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.nombre &&
                  filtros_errores.data.nombre.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>RUT</label>
                <input
                  type="text"
                  name="filtro_rut"
                  onChange={this.onChange}
                  value={filtro_rut}
                  placeholder="Ingrese rut del titular"
                  className={`form-control ${
                    filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.rut
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.rut &&
                  filtros_errores.data.rut.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroTitulares}
            >
              <i className="fa fa-search"></i> Filtrar titulares
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Titulares.propTypes = {
  obtenerTitulares: PropTypes.func.isRequired,
  obtenerTitularesFiltros: PropTypes.func.isRequired,
  filtros_errores: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  titulares: state.vigilancias.titulares,
  filtros_errores: state.vigilancias.filtros_errores,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerTitulares, obtenerTitularesFiltros },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Titulares);
