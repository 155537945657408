import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { limpiarMensajes } from "../../../redux/actions/tareas/action";
import {
  eliminarProyecto,
  obtenerProyectos,
} from "../../../redux/actions/tareas/proyectos/action";
import { Listado as TareaListado } from "../tarea/";
import { Editar as ProyectoEditar } from "./";

export class Ficha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proyecto_editar_mostrar: false,
      proyecto_seleccionado: {},
    };
  }

  cancelarNuevoProyecto = () => {
    const { limpiarMensajes } = this.props;
    this.setState({
      proyecto_editar_mostrar: false,
      proyecto_seleccionado: {},
    });
    limpiarMensajes();
  };

  mostrarEdicionProyecto = (proyecto) => {
    this.setState({
      proyecto_editar_mostrar: true,
      proyecto_seleccionado: proyecto,
    });
  };

  eliminacionProyecto = (proyecto) => {
    const { eliminarProyecto } = this.props;
    SweetAlert.fire({
      title: "Proyecto",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de eliminar el proyecto ${proyecto.nombre}?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarProyecto({ proyecto: proyecto.id });
        this.setState({
          usuario_loading: SweetAlert.fire({
            title: "Tarea",
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: "Estamos eliminando el proyecto, espere unos segundos",
            icon: "warning",
            showConfirmButton: false,
            didOpen: () => {
              SweetAlert.showLoading();
            },
          }),
        });
      }
    });
  };

  render = () => {
    const {
      proyectos,
      limpiarMensajes,
      obtenerProyectos,
      proyecto_eliminar_error,
      proyecto_eliminar,
    } = this.props;
    const { proyecto_editar_mostrar, proyecto_seleccionado } = this.state;

    if (Object.entries(proyecto_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Proyecto eliminado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: proyecto_eliminar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerProyectos();
          limpiarMensajes();
        }
      });
    }

    if (
      Object.entries(proyecto_eliminar_error).length > 0 &&
      proyecto_eliminar_error.data &&
      proyecto_eliminar_error.data.message
    ) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Proyecto no eliminado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: proyecto_eliminar_error.data.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
        }
      });
    }

    return (
      <Fragment>
        {proyecto_editar_mostrar && (
          <ProyectoEditar
            proyecto={proyecto_seleccionado}
            cancelarNuevoProyecto={this.cancelarNuevoProyecto}
            btnCancelar
          />
        )}
        {proyectos && proyectos.length > 0 ? (
          proyectos.map((proyecto, x) => (
            <div className="col-sm-12" key={x}>
              <div className="card card-eco">
                <div className="card-header">
                  <div className="float-start">
                    <h5>
                      <span className="badge badge-danger">ID: {x + 1}</span>{" "}
                      {proyecto.nombre}
                    </h5>
                    {proyecto.descripcion && (
                      <p className="mt-0">{proyecto.descripcion}</p>
                    )}
                  </div>
                  <div className="btn-group float-end">
                    {proyecto.link && (
                      <>
                        <a
                          data-tip
                          data-tooltip-id="link_proyecto"
                          rel="noopener noreferrer"
                          className="btn btn-light btn-xs"
                          target="_blank"
                          href={proyecto.link}
                        >
                          <i className="fa fa-link"></i>
                        </a>
                        <Tooltip id="link_proyecto" aria-haspopup="true">
                          Ver enlace del proyecto
                        </Tooltip>
                      </>
                    )}
                    <button
                      data-tip
                      data-tooltip-id="editar_proyecto"
                      className="btn btn-warning btn-xs"
                      onClick={this.mostrarEdicionProyecto.bind(this, proyecto)}
                    >
                      <i className="fa fa-pencil-alt"></i>
                    </button>
                    <Tooltip id="editar_proyecto" aria-haspopup="true">
                      Editar proyecto
                    </Tooltip>
                    <button
                      data-tip
                      data-tooltip-id="eliminar_proyecto"
                      className="btn btn-danger btn-xs"
                      onClick={this.eliminacionProyecto.bind(this, proyecto)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                    <Tooltip id="eliminar_proyecto" aria-haspopup="true">
                      Eliminar proyecto
                    </Tooltip>
                  </div>
                </div>
                <TareaListado
                  index={x}
                  proyecto={proyecto}
                  tareas={proyecto.tareas}
                />
              </div>
            </div>
          ))
        ) : (
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body text-center">
                <h5>
                  <i className="fa fa-exclamation-circle"></i> No tienes
                  proyectos asociados a tu cuenta
                </h5>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  };
}

Ficha.propTypes = {
  eliminarProyecto: PropTypes.func.isRequired,
  obtenerProyectos: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  proyecto_eliminar: PropTypes.object.isRequired,
  proyecto_eliminar_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  proyecto_eliminar: state.tareas.proyecto_eliminar,
  proyecto_eliminar_error: state.tareas.proyecto_eliminar_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { eliminarProyecto, obtenerProyectos, limpiarMensajes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Ficha);
