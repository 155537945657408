export const FISCALIZACIONES_OBTENER = 'FISCALIZACIONES_OBTENER';
export const FISCALIZACIONES_FALLIDO = 'FISCALIZACIONES_FALLIDO';
export const FISCALIZACIONES_FILTROS_OBTENER = 'FISCALIZACIONES_FILTROS_OBTENER';
export const FISCALIZACIONES_FILTROS_FALLIDO = 'FISCALIZACIONES_FILTROS_FALLIDO';
export const FISCALIZACION_GUARDAR = 'FISCALIZACION_GUARDAR';
export const FISCALIZACION_FILTROS_INPUT = 'FISCALIZACION_FILTROS_INPUT';
export const FISCALIZACION_OBTENER = 'FISCALIZACION_OBTENER';
export const FISCALIZACION_FALLIDO = 'FISCALIZACION_FALLIDO';
export const LIMPIAR_FISCALIZACIONES_FILTROS = 'LIMPIAR_FISCALIZACIONES_FILTROS';
export const FISCALIZACIONES_ESTADISTICAS_FALLIDO = 'FISCALIZACIONES_ESTADISTICAS_FALLIDO';
export const FISCALIZACIONES_ESTADISTICAS_OBTENER = 'FISCALIZACIONES_ESTADISTICAS_OBTENER';
