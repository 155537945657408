import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  SANCIONES_BUSQUEDA_FALLIDO,
  SANCIONES_BUSQUEDA_OBTENER,
  SANCIONES_ESTADISTICAS_FALLIDO,
  SANCIONES_ESTADISTICAS_OBTENER,
  SANCIONES_FALLIDO,
  SANCIONES_GUARDAR,
  SANCIONES_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerSancionesEstadistica = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/sanciones/estadisticas/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: SANCIONES_ESTADISTICAS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : SANCIONES_ESTADISTICAS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerSanciones = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(`${url_api}/api/vigilances/sanciones/${pagina}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: SANCIONES_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : SANCIONES_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerSancionesFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/sanciones/filtros/1`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: SANCIONES_BUSQUEDA_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : SANCIONES_BUSQUEDA_FALLIDO,
        payload: error,
      });
    });
};

export const guardarSancion = (id) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  dispatch({ type: SANCIONES_GUARDAR, payload: id });
};
