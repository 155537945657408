import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { guardarUnidad } from "../../../redux/actions/vigilancias/unidades_fiscalizables/action";
import { Eliminar as EliminarFavorito } from "../favoritos";
import { TablaPequena } from "../titulares";

export class Tabla extends Component {
  setUnidad = (id, componente) => {
    const { guardarUnidad } = this.props;
    guardarUnidad({ id: id, componente: componente });
  };

  render = () => {
    const { unidades_fiscalizables, componente, eliminar_favorito } =
      this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre</th>
              <th className="w-12">Región</th>
              <th className="w-12">Comuna</th>
              <th className="w-13">Categoría</th>
              <th className="w-20">Titular</th>
              <th className="text-center w-3">Acción</th>
            </tr>
          </thead>
          <tbody>
            {unidades_fiscalizables &&
            unidades_fiscalizables.result &&
            unidades_fiscalizables.result.length > 0 ? (
              unidades_fiscalizables.result.map((unidad, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {unidades_fiscalizables.page > 1
                      ? (unidades_fiscalizables.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td>
                    {unidad.sociedad_quinenco && (
                      <>
                        <span className="badge badge-danger badge-md">
                          {unidad.sociedad_quinenco}
                        </span>{" "}
                      </>
                    )}
                    <a
                      href={unidad.url_snifa}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-uppercase"
                    >
                      {unidad.nombre} <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td>
                    <span className="badge badge-primary font-11">
                      {unidad.region_display}
                    </span>
                  </td>
                  <td>
                    <span className="badge badge-primary font-11">
                      {unidad.comuna}
                    </span>
                  </td>
                  <td>{unidad.categoria_display}</td>
                  <td>
                    <TablaPequena titulares={unidad.titular} />
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {eliminar_favorito && (
                        <EliminarFavorito
                          tipo="unidad_fiscalizable"
                          id={unidad.id}
                        />
                      )}
                      <Link
                        data-tip
                        data-tooltip-id={`programa_${unidad.id}`}
                        to="/compliance-analytics/unidad"
                        className="btn btn-soft-dark"
                        onClick={this.setUnidad.bind(
                          this,
                          unidad.id,
                          componente
                        )}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="6">
                  No existen unidades fiscalizables
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarUnidad: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarUnidad }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
