import React, { Component } from "react";
import { Tooltip } from "react-tooltip";
import { TablaPequena as Acciones } from "../acciones";
import { TablaPequena as InstrumentosInfringido } from "../instrumentos_infringidos";
import { TablaMedia as Titulares } from "../titulares";
import { TablaPequena as UnidadesFiscalizables } from "../unidades";

export class Tabla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_tags: 0,
    };
  }

  setExpedienteTag = (id) => {
    this.setState({
      is_tags: id,
    });
  };

  render = () => {
    const { data } = this.props;
    const { is_tags } = this.state;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center">#</th>
              <th className="w-20">Nombre hecho infraccional</th>
              <th className="w-20">Implementacion comprometida</th>
              <th className="w-6">Clasificacion</th>
              <th className="w-8">Tipo</th>
              <th>Instrumento infringido</th>
              <th>Categoria</th>
              <th className="w-15">Titular</th>
              <th className="w-15">Unidad Fiscalizable</th>
            </tr>
          </thead>
          <tbody>
            {data && data.result && data.result.length > 0 ? (
              data.result.map((dat, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {data.page > 1 ? (data.page - 1) * 20 + (x + 1) : x + 1}
                  </td>
                  <td>
                    {dat.nombre}
                    {dat.tag && dat.tag.length > 0 && (
                      <p>
                        {is_tags === dat.id ? (
                          dat.tag.map((tag, z) => (
                            <span
                              key={z}
                              className="badge badge-soft-primary p-1 me-1 font-11"
                            >
                              #{tag}
                            </span>
                          ))
                        ) : (
                          <button
                            onClick={this.setExpedienteTag.bind(this, dat.id)}
                            type="button"
                            className="btn btn-sm btn-primary btn-tags"
                          >
                            Tags <i className="fa fa-angle-right"></i>
                          </button>
                        )}
                      </p>
                    )}
                  </td>
                  <td>
                    <Acciones acciones={dat.acciones} />
                  </td>
                  <td>
                    {dat.clasificacion ? (
                      <>
                        {dat.clasificacion}
                        <Tooltip
                          className="tooltip-limite"
                          id={`clasificacion_detalle_${x}`}
                          aria-haspopup="true"
                        >
                          {dat.clasificacion_detalle}
                        </Tooltip>
                        <i
                          data-tip
                          data-tooltip-id={`clasificacion_detalle_${x}`}
                          className="fa fa-exclamation-circle text-danger ms-1 font-11"
                        ></i>
                      </>
                    ) : (
                      "S/I"
                    )}
                  </td>
                  <td>{dat.infraccion}</td>
                  <td>
                    <InstrumentosInfringido
                      instrumentos={dat.instrumentos_infringidos}
                    />
                  </td>
                  <td>
                    {dat.procedimiento &&
                      dat.procedimiento.unidad_fiscalizable &&
                      dat.procedimiento.unidad_fiscalizable.length > 0 && (
                        <ul>
                          {dat.procedimiento.unidad_fiscalizable.map(
                            (und, x) => (
                              <li key={x}>{und.categoria_display}</li>
                            )
                          )}
                        </ul>
                      )}
                  </td>
                  <td>
                    <Titulares titulares={dat.titulares} />
                  </td>
                  <td>
                    <UnidadesFiscalizables
                      unidades_fiscalizables={
                        dat.procedimiento.unidad_fiscalizable
                      }
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="11">
                  No existen coincidencias con la busqueda realizada
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

export default Tabla;
