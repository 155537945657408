import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import slugify from "slugify";
import SweetAlert from "sweetalert2";
import {
  crearRespuestas,
  obtenerArchivoPregunta,
  obtenerPreguntas,
} from "../../../redux/actions/permitting/action";
import {
  Footer,
  Navbar,
  SiderbarPermittingParametrizacion,
} from "../../general";
import { withRouter } from "../../general/with_router";
import { FichaProyecto } from "../general";

export class Preguntas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      respuestas_list: [],
      estado: false,
      edicion_respuesta: false,
      ver_ayuda: 0,
    };
  }

  listadoPreguntas = () => {
    const {
      obtenerPreguntas,
      seccion_seleccionada,
      categoria_seleccionada,
      consulta_seleccionada,
    } = this.props;
    obtenerPreguntas({
      seccion: seccion_seleccionada.id,
      categoria: categoria_seleccionada.id,
      consulta: consulta_seleccionada,
    });
  };

  componentDidMount = () => {
    const { preguntas } = this.props;
    this.listadoPreguntas();
    if (preguntas.length > 0) {
      var resp = preguntas.filter((pregunta) => pregunta.respuesta !== null);
      if (resp.length > 0) {
        this.setState({ edicion_respuesta: true });
      }
    }
  };

  editarRespuestas = () => {
    this.setState({ edicion_respuesta: true });
  };

  cancelarEditarRespuestas = () => {
    this.setState({
      edicion_respuesta: false,
      respuestas_list: [],
    });
  };

  initRespuestas = () => {
    this.setState({
      respuestas_list: [],
    });
  };

  guardarRespuestas = () => {
    const { crearRespuestas, consulta_seleccionada } = this.props;
    const { respuestas_list } = this.state;
    if (respuestas_list.length > 0) {
      crearRespuestas({
        consulta: consulta_seleccionada,
        respuestas: respuestas_list,
      });
    }
  };

  onChange = (e) => {
    const { respuestas_list } = this.state;
    const res = respuestas_list.filter(
      (respuesta) => respuesta.pregunta !== e.target.name
    );
    res.push({
      pregunta: parseInt(e.target.name, 10),
      valor: e.target.value,
    });
    this.setState({ respuestas_list: res });
  };

  verAyuda = (id) => {
    const { ver_ayuda } = this.state;
    this.setState({
      ver_ayuda: ver_ayuda === id ? 0 : id,
    });
  };

  verArchivo = (archivo, pregunta) => {
    const {
      obtenerArchivoPregunta,
      seccion_seleccionada,
      categoria_seleccionada,
      consulta_seleccionada,
    } = this.props;
    obtenerArchivoPregunta({
      seccion: seccion_seleccionada.id,
      categoria: categoria_seleccionada.id,
      consulta: consulta_seleccionada,
      archivo: archivo,
      pregunta: pregunta,
    });
  };

  render = () => {
    const { edicion_respuesta, ver_ayuda } = this.state;
    const {
      preguntas,
      seccion_seleccionada,
      is_loading_permitting,
      categoria_seleccionada,
      consulta_seleccionada,
      respuestas,
      respuestas_error,
      estado,
      preguntas_error,
      archivo_pregunta_error,
    } = this.props;

    const createMarkup = (htmlString) => ({ __html: htmlString });

    if (!seccion_seleccionada && seccion_seleccionada.id === 0) {
      return <Navigate to="/permitting-target/parametrizacion/secciones" />;
    } else if (!categoria_seleccionada && categoria_seleccionada.id === 0) {
      return <Navigate to="/permitting-target/parametrizacion/categorias" />;
    } else if (consulta_seleccionada === 0) {
      return <Navigate to="/permitting-target/proyectos" />;
    }

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Parametrización",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las categorias, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (Object.entries(respuestas).length > 0 && respuestas.message) {
      SweetAlert.fire({
        title: "Respuestas guardadas",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: respuestas.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-thumbs-up"></i>',
        customClass: {
          confirmButton: "btn btn-primary me-3",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.listadoPreguntas();
          this.initRespuestas();
        }
      });
    }

    if (
      Object.entries(respuestas_error).length > 0 &&
      respuestas_error.message
    ) {
      SweetAlert.fire({
        title: "Respuestas no guardadas",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: respuestas_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }

    if (Object.entries(preguntas_error).length > 0 && preguntas_error.message) {
      SweetAlert.fire({
        title: "Parametrización",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: preguntas_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        this.props.navigate("/permitting-target/proyectos");
      });
    }

    if (
      Object.entries(archivo_pregunta_error).length > 0 &&
      archivo_pregunta_error.message
    ) {
      SweetAlert.fire({
        title: "Descarga Archivo",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: archivo_pregunta_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SiderbarPermittingParametrizacion menu_1="permitting_parametrizacion" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h4>PARAMETRIZACIÓN DE LA CONSULTA</h4>
                  </div>
                  <div className="card-body">
                    <ol className="breadcrumb mt-0 mb-0 font-14">
                      <li className="breadcrumb-item">
                        <Link to="/permitting-target/proyectos">Proyectos</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/permitting-target/parametrizacion/secciones">
                          Secciones
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/permitting-target/parametrizacion/categorias">
                          Categorías
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Preguntas</li>
                    </ol>
                    <h5 className="mt-0 mb-1">
                      {seccion_seleccionada.nombre}{" "}
                      <i className="fa fa-angle-right"></i>{" "}
                      {categoria_seleccionada.nombre}{" "}
                      <i className="fa fa-angle-right"></i>{" "}
                      {preguntas ? preguntas.length : 0}{" "}
                      {preguntas && preguntas.length > 1
                        ? "Preguntas"
                        : "Pregunta"}
                    </h5>
                    <FichaProyecto />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h5>ETAPA ACTUAL DEL PROCESO DE LA CONSULTA</h5>
                  </div>
                  <div className="card-body">
                    <ol className="c-progress-steps">
                      <li className="c-progress-steps__step current">
                        <span>1. Parametrización de la consulta</span>
                      </li>
                      <li className="c-progress-steps__step">
                        <span>2. Reporte de aplicabilidad</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mb-2 text-end">
                <div className="btn-group">
                  <Link
                    to="/permitting-target/parametrizacion/categorias"
                    className="btn btn-primary"
                  >
                    <i className="fa fa-angle-left"></i> Volver a las categorías
                  </Link>
                  {estado && (
                    <>
                      {!edicion_respuesta ? (
                        <button
                          type="button"
                          onClick={this.editarRespuestas}
                          className="btn btn-warning"
                        >
                          <i className="fa fa-pencil-alt"></i> Editar mis
                          respuestas
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={this.cancelarEditarRespuestas}
                          className="btn btn-danger"
                        >
                          <i className="fa fa-times"></i> Cancelar edición
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              {preguntas &&
                preguntas.length > 0 &&
                preguntas.map((pregunta, x) => (
                  <div className="col-sm-6 col-lg-12" key={x}>
                    <div
                      className={`card card-eco${
                        pregunta.respuesta
                          ? ` card-eco-${slugify(pregunta.respuesta, {
                              lower: true,
                            })}`
                          : ""
                      }`}
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-9">
                            <p className="mb-0 font-11">
                              <u>Pregunta {x + 1}</u>
                            </p>
                            <h5 className="mt-0">{pregunta.texto}</h5>
                            {pregunta.tooltip && (
                              <>
                                <p className="mb-2">
                                  <button
                                    onClick={this.verAyuda.bind(
                                      this,
                                      pregunta.id
                                    )}
                                    type="button"
                                    className={`btn btn-xs btn-${
                                      ver_ayuda === pregunta.id
                                        ? "dark"
                                        : "primary"
                                    }`}
                                  >
                                    <i className="fa fa-exclamation-circle"></i>{" "}
                                    Ayuda{" "}
                                    <i
                                      className={`fa fa-angle-${
                                        ver_ayuda === pregunta.id
                                          ? "down"
                                          : "right"
                                      }`}
                                    ></i>
                                  </button>
                                </p>
                                <div
                                  className={`bg-white border p-3${
                                    ver_ayuda === pregunta.id ? "" : " d-none"
                                  }`}
                                >
                                  <div
                                    dangerouslySetInnerHTML={createMarkup(
                                      pregunta.tooltip.texto
                                    )}
                                  />
                                  {pregunta.tooltip.archivo && (
                                    <>
                                      <p className="mb-1">
                                        {pregunta.tooltip.archivo.nombre}:
                                      </p>
                                      <button
                                        type="button"
                                        onClick={this.verArchivo.bind(
                                          this,
                                          pregunta.tooltip.archivo.id,
                                          pregunta.id
                                        )}
                                        className="mt-0 btn btn-danger btn-xs"
                                        target="_blank"
                                      >
                                        Ver archivo{" "}
                                        <i className="fa fa-file-pdf"></i>
                                      </button>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {pregunta.respuesta && !edicion_respuesta ? (
                            <p className="font-13 font-15 mb-0">
                              <span className="badge badge-dark">
                                Tu respuesta es: {pregunta.respuesta}
                              </span>
                            </p>
                          ) : (
                            <div className="col-lg-3">
                              {pregunta.respuesta && (
                                <p className="font-13 font-15 mb-0">
                                  <span className="badge badge-primary">
                                    Respuesta actual es: {pregunta.respuesta}
                                  </span>
                                </p>
                              )}
                              <p className="badge badge-dark font-11 mb-2">
                                Selecciona una opción:
                              </p>
                              <div className="form-check parametrizacion">
                                <input
                                  className="form-check-input"
                                  name={pregunta.id}
                                  type="radio"
                                  onChange={this.onChange}
                                  value="1"
                                />
                                <label className="form-check-label">Sí</label>
                              </div>
                              <div className="form-check parametrizacion">
                                <input
                                  className="form-check-input"
                                  name={pregunta.id}
                                  type="radio"
                                  onChange={this.onChange}
                                  value="2"
                                />
                                <label className="form-check-label">No</label>
                              </div>
                              <div className="form-check parametrizacion">
                                <input
                                  className="form-check-input"
                                  name={pregunta.id}
                                  type="radio"
                                  onChange={this.onChange}
                                  value="3"
                                />
                                <label className="form-check-label">
                                  En definición
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="col-lg-12 mb-3">
                <div className="card">
                  <div className="card-body">
                    <div className="btn-group float-end" role="group">
                      <button
                        onClick={this.guardarRespuestas}
                        type="button"
                        className="btn btn-primary"
                      >
                        {" "}
                        Guardar respuestas <i className="fas fa-check"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Preguntas.propTypes = {
  obtenerPreguntas: PropTypes.func.isRequired,
  crearRespuestas: PropTypes.func.isRequired,
  obtenerArchivoPregunta: PropTypes.func.isRequired,
  preguntas: PropTypes.array.isRequired,
  preguntas_error: PropTypes.object.isRequired,
  categoria_seleccionada: PropTypes.object.isRequired,
  seccion_seleccionada: PropTypes.object.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  consulta_seleccionada: PropTypes.number.isRequired,
  respuestas: PropTypes.object.isRequired,
  respuestas_error: PropTypes.object.isRequired,
  archivo_pregunta_error: PropTypes.object.isRequired,
  estado: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  preguntas: state.permitting.preguntas,
  preguntas_error: state.permitting.preguntas_error,
  categoria_seleccionada: state.permitting.categoria_seleccionada,
  seccion_seleccionada: state.permitting.seccion_seleccionada,
  is_loading_permitting: state.permitting.is_loading_permitting,
  consulta_seleccionada: state.permitting.consulta_seleccionada,
  respuestas: state.permitting.respuestas,
  respuestas_error: state.permitting.respuestas_error,
  estado: state.permitting.estado,
  archivo_pregunta_error: state.permitting.archivo_pregunta_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerPreguntas, crearRespuestas, obtenerArchivoPregunta },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Preguntas)
);
