import React, { Component, Fragment } from "react";

import { Link } from "react-router-dom";

export class TextoNorma extends Component {
  nuevaTarea = (articulo) => {
    const { nuevaTarea } = this.props;
    nuevaTarea(articulo);
  };

  render = () => {
    const { hijo, is_user_tasks } = this.props;
    const createMarkup = (htmlString) => ({ __html: htmlString });

    return (
      <Fragment>
        <div className="texto_norma" id={hijo.value.toString()}>
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-secondary">
                <tr>
                  <th className="w-100">
                    <h5 className="float-start text-dark">{hijo.label}</h5>
                    <div className="btn-group float-end">
                      {is_user_tasks && (
                        <Link
                          data-tip
                          data-tooltip-id="nueva_tarea"
                          to="/legal-tracking/norma/tarea"
                          onClick={this.nuevaTarea.bind(this, hijo)}
                          type="button"
                          className="btn btn-warning btn-xs"
                        >
                          Crea Tarea <i className="fa fa-plus"></i>
                        </Link>
                      )}
                      <a
                        rel="noreferrer"
                        data-tip
                        data-tooltip-id="leychile"
                        target="_blank"
                        href={hijo.url_parte}
                        className="btn btn-light btn-xs float-end"
                      >
                        <i className="fa fa-link"></i>
                      </a>
                      <a
                        rel="noreferrer"
                        data-tip
                        data-tooltip-id="archivo_pdf"
                        target="_blank"
                        href={hijo.url_pdf}
                        className="btn btn-danger btn-xs float-end"
                      >
                        <i className="fa fa-file-pdf"></i>
                      </a>
                      <a
                        rel="noreferrer"
                        data-tip
                        data-tooltip-id="archivo_word"
                        target="_blank"
                        href={hijo.url_word}
                        className="btn btn-primary btn-xs float-end"
                      >
                        <i className="fa fa-file-word"></i>
                      </a>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="2">
                    <div dangerouslySetInnerHTML={createMarkup(hijo.texto)} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {hijo &&
            hijo.hijos &&
            hijo.hijos.length > 0 &&
            hijo.hijos.map((h, x) => (
              <TextoNorma
                key={x}
                hijo={h}
                nuevaTarea={this.nuevaTarea}
                is_user_tasks={is_user_tasks}
              />
            ))}
        </div>
      </Fragment>
    );
  };
}

export default TextoNorma;
