import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  ComplianceAnalyticsInicio,
  Generadoras,
  LegalTrackingInicio,
  PermittingTargetInicio,
  QualityTaskingInicio,
  Quinenco,
  Teams,
} from "./";

export class SidebarLeft extends Component {
  render = () => {
    const { menu_1, menu_2, siderbar_estado, user } = this.props;
    return (
      <div className={`left-sidenav ${siderbar_estado ? "d-none" : ""}`}>
        <ul className="top100 left-sidenav-menu">
          <li
            className={
              menu_1 && menu_1 === "inicio" ? "mm-active" : "mm-inactivo"
            }
          >
            <Link className="nav-link" to="/user/inicio">
              <i className="fa fa-home"></i> Inicio
            </Link>
          </li>
          <li
            className={
              menu_1 && menu_1 === "estadisticas" ? "mm-active" : "mm-inactivo"
            }
          >
            <Link className="nav-link" to="/user/estadisticas">
              <i className="fa fa-chart-bar"></i> Estadísticas
            </Link>
          </li>
          <li
            className={
              menu_1 && menu_1 === "notificaciones"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link className="nav-link" to="/user/notificaciones">
              <i className="fa fa-bell"></i> Notificaciones
            </Link>
          </li>
          <li
            className={
              menu_1 && menu_1 === "contrasena" ? "mm-active" : "mm-inactivo"
            }
          >
            <Link className="nav-link" to="/user/cambio-contrasena">
              <i className="fa fa-lock"></i> Contraseña
            </Link>
          </li>
          <li className="mm-active-link">
            <a
              href="https://cumplimientolean.notion.site/Centro-de-ayuda-a8652f5d44464481829956c0d99ad059?pvs=4 "
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-handshake"></i>
              <span> Centro de Ayuda</span>
            </a>
          </li>
          <li className="mm-active-link">
            <a
              href="https://cumplimientolean.notion.site/Centro-de-recursos-967f0738116049d49b4ed36d0a2a6621?pvs=4 "
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-folder-tree"></i>
              <span> Centro de Recursos</span>
            </a>
          </li>
          {/* {user.is_staff && (
            <>
              <li
                className={
                  menu_1 && menu_1 === "vigilancias_lite"
                    ? "mm-active"
                    : "mm-inactivo"
                }
              >
                <Link to="/compliance-analytics/lite">
                  <i className="fa-brands fa-android"></i>
                  <span> AI</span>
                </Link>
              </li>
            </>
          )} */}
          <Quinenco
            menu_1={menu_1}
            menu_2={menu_2}
            empresas={
              user.modulos && user.modulos.empresas && user.modulos.empresas
            }
          />
          <Generadoras
            menu_1={menu_1}
            menu_2={menu_2}
            empresas={
              user.modulos && user.modulos.empresas && user.modulos.empresas
            }
          />
          <Teams
            menu_1={menu_1}
            menu_2={menu_2}
            teams={user.modulos && user.modulos.teams && user.modulos.teams}
            sub_teams={
              user.modulos && user.modulos.sub_teams && user.modulos.sub_teams
            }
          />
          <LegalTrackingInicio
            menu_1={menu_1}
            laws={user.modulos && user.modulos.laws && user.modulos.laws}
            sub_laws={
              user.modulos && user.modulos.sub_laws && user.modulos.sub_laws
            }
          />
          <PermittingTargetInicio
            menu_1={menu_1}
            permitting_target={
              user.modulos &&
              user.modulos.permitting_target &&
              user.modulos.permitting_target
            }
            sub_permitting_target={
              user.modulos &&
              user.modulos.sub_permitting_target &&
              user.modulos.sub_permitting_target
            }
          />
          <ComplianceAnalyticsInicio
            menu_1={menu_1}
            menu_2={menu_2}
            vigilances={
              user.modulos && user.modulos.vigilances && user.modulos.vigilances
            }
            sub_vigilances={
              user.modulos &&
              user.modulos.sub_vigilances &&
              user.modulos.sub_vigilances
            }
          />
          <QualityTaskingInicio
            menu_1={menu_1}
            tasks={user.modulos && user.modulos.tasks && user.modulos.tasks}
          />
        </ul>
      </div>
    );
  };
}

SidebarLeft.propTypes = {
  siderbar_estado: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  siderbar_estado: state.auth.siderbar_estado,
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(SidebarLeft);
