import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerRca } from "../../../redux/actions/vigilancias/rcas/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Tabs } from "./";

export class Detalle extends Component {
  componentDidMount = () => {
    const { obtenerRca, rca_id } = this.props;
    if (rca_id > 0) {
      obtenerRca({
        id: rca_id,
      });
    }
  };

  render = () => {
    const { is_loading_vigilancias, rca_id, rca } = this.props;

    if (rca_id === 0) {
      return <Navigate to="/compliance-analytics/rcas/listado" />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Resoluciones de Calificación Ambiental",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo RCA, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_rcas" menu_2="listado" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-arrow-alt-circle-right"></i>{" "}
                    RESOLUCIONES DE CALIFICACIÓN AMBIENTAL
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-lg-9">
                        <h5 className="float-start">{rca && rca.nombre}</h5>
                      </div>
                      <div className="col-lg-3 text-end">
                        <div className="btn-group">
                          <Link
                            className="btn btn-primary"
                            to="/compliance-analytics/rcas/listado"
                          >
                            <i className="fa fa-angle-left"></i> Volver al
                            listado
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {rca && <Tabs rcas={[rca]} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerRca: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  rca: PropTypes.object.isRequired,
  rca_id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  rca: state.vigilancias.rca,
  rca_id: state.vigilancias.rca_id,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerRca }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
