import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  obtenerProcedimientos,
  obtenerProcedimientosFiltros,
} from "../../../redux/actions/vigilancias/procedimientos/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Filtros, Tabla } from "./";

export class Listado extends Component {
  componentDidMount = () => {
    const { obtenerProcedimientos, procedimientos } = this.props;
    if (Object.entries(procedimientos).length === 0) {
      obtenerProcedimientos(1);
    }
  };

  nuevaPagina = (pagina) => {
    const {
      obtenerProcedimientos,
      obtenerProcedimientosFiltros,
      procedimientos_filtros,
      procedimientos_filtro_input,
    } = this.props;
    if (Object.entries(procedimientos_filtros).length > 0) {
      obtenerProcedimientosFiltros({
        filtros: procedimientos_filtro_input,
        pagina: pagina,
      });
    } else {
      obtenerProcedimientos(pagina);
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { procedimientos, procedimientos_filtros, is_loading_vigilancias } =
      this.props;

    var procedimientos_result = procedimientos;
    if (Object.entries(procedimientos_filtros).length > 0) {
      procedimientos_result = procedimientos_filtros;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Procedimientos Sancionatorios",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los procedimientos, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_procedimientos" menu_2="listado" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-bullhorn"></i> PROCEDIMIENTOS
                    SANCIONATORIOS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE PROCEDIMIENTOS SANCIONATORIOS{" "}
                      <small>
                        (
                        {procedimientos_result && procedimientos_result.total
                          ? procedimientos_result.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={procedimientos_result}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla procedimientos={procedimientos_result} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerProcedimientos: PropTypes.func.isRequired,
  obtenerProcedimientosFiltros: PropTypes.func.isRequired,
  procedimientos: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  procedimientos_filtros: PropTypes.object.isRequired,
  procedimientos_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  procedimientos: state.vigilancias.procedimientos,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  procedimientos_filtros: state.vigilancias.procedimientos_filtros,
  procedimientos_filtro_input: state.vigilancias.procedimientos_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerProcedimientos, obtenerProcedimientosFiltros },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
