import React, { Component } from "react";
import { Tooltip } from "react-tooltip";
import { TablaMediaTeams as Medida } from "../../vigilancias/medidas";
import { TablaMediaTeams as Procedimiento } from "../../vigilancias/procedimientos";
import { TablaMediaTeams as Requerimiento } from "../../vigilancias/requerimientos";
import { TablaPequena as Titulares } from "../../vigilancias/titulares";
import { TablaPequena as UnidadesFiscalizables } from "../../vigilancias/unidades";

export class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favorito_expediente: "",
      favorito_tipo: "",
      card_favorito: false,
    };
  }

  buscarExpediente = () => {
    const { favorito_expediente, favorito_tipo } = this.state;
    const { buscarExpediente } = this.props;
    buscarExpediente(favorito_expediente, favorito_tipo);
  };

  eliminacionFavorito = (id, componente) => {
    const { eliminacionFavorito } = this.props;
    eliminacionFavorito(id, componente);
  };

  agregarFavorito = (id, expediente) => {
    const { agregarFavorito } = this.props;
    const { favorito_tipo } = this.state;
    agregarFavorito(id, expediente, favorito_tipo);
  };

  render = () => {
    const { limpiarMensajes, equipo, equipo_buscar, perfil } = this.props;
    const { favorito_tipo, favorito_expediente, modal_buscar } = this.state;
    const tipos_expedientes = [
      { label: "Medida Provisional", value: "medida" },
      { label: "Procedimiento Sancionatorio", value: "procedimiento" },
      { label: "Requerimiento de Ingreso", value: "requerimiento" },
    ];

    return (
      <>
        {perfil && perfil in ["3", "2"] && modal_buscar && (
          <div className="card mb-3">
            <Tooltip id="agregar_a_favoritos" aria-haspopup="true">
              Agregar el Expediente a Favoritos
            </Tooltip>
            <Tooltip id="eliminar_de_favoritos" aria-haspopup="true">
              Eliminar de Favoritos
            </Tooltip>
            <div className="card-header bg-soft-danger">
              <h5 className="text-uppercase">Buscar</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-1">Tipo de Expediente</label>
                    <select
                      className="form-select"
                      name="tipo_expediente"
                      value={favorito_tipo}
                      onChange={(e) =>
                        this.setState({ favorito_tipo: e.target.value })
                      }
                    >
                      <option value="">Selecciona una opción</option>
                      {tipos_expedientes.map((tipo, x) => (
                        <option key={x} value={tipo.value}>
                          {tipo.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="mb-1">Expediente</label>
                    <input
                      type="text"
                      className="form-control"
                      name="favorito_expediente"
                      value={favorito_expediente}
                      onChange={(e) =>
                        this.setState({ favorito_expediente: e.target.value })
                      }
                      placeholder="Escriba el número de expediente"
                    />
                  </div>
                </div>
                <div className="col-12 text-end">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => {
                        limpiarMensajes();
                        this.setState({
                          favorito_expediente: "",
                          favorito_tipo: "",
                          modal_buscar: false,
                        });
                      }}
                    >
                      Cancelar <i className="fa fa-times"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={this.buscarExpediente}
                    >
                      Buscar <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              {equipo_buscar && equipo_buscar.length > 0 && (
                <div className="bg-light p-2 my-3">
                  <h5 className="pb-2 text-uppercase">
                    Resultados <small>({equipo_buscar.length})</small>
                  </h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="w-10 text-center">Expediente</th>
                        <th className="w-30">Titular</th>
                        <th>Unidad Fiscalizable</th>
                        <th className="w-5 text-center">Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {equipo_buscar.map((favorito, x) => (
                        <tr key={x}>
                          <td className="text-center">
                            <a
                              href={favorito.url_snifa}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {favorito.expediente}{" "}
                              <i className="fa fa-angle-right"></i>
                            </a>
                          </td>
                          <td>
                            <Titulares titulares={favorito.titulares} />
                          </td>
                          <td className="w-30">
                            <UnidadesFiscalizables
                              unidades_fiscalizables={
                                favorito.unidad_fiscalizable
                              }
                            />
                          </td>
                          <td className="text-center">
                            <button
                              className="btn btn-warning btn-sm"
                              data-tip
                              data-tooltip-id="agregar_a_favoritos"
                              onClick={() =>
                                this.agregarFavorito(
                                  favorito.id,
                                  favorito.expediente
                                )
                              }
                            >
                              <i className="fa fa-star"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <h5 className="text-uppercase float-start mt-3">
              Listado de Expedientes
            </h5>
            {perfil && perfil in ["3", "2"] && (
              <button
                className="btn btn-dark text-end btn-sm float-end"
                onClick={() => this.setState({ modal_buscar: true })}
              >
                Agregar Expediente <i className="fa fa-plus"></i>
              </button>
            )}
          </div>
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered mt-3">
                <thead className="table-secondary">
                  <tr>
                    <th>Expediente</th>
                    <th className="w-13">Tipo</th>
                    {perfil && perfil in ["3", "2"] && (
                      <th className="w-3 text-center">Acción</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {equipo.favoritos &&
                    equipo.favoritos.map((favorito, x) => (
                      <tr key={x}>
                        <td className="p-0">
                          {favorito.medida && (
                            <Medida medida={favorito.medida} />
                          )}
                          {favorito.procedimiento && (
                            <Procedimiento
                              procedimiento={favorito.procedimiento}
                            />
                          )}
                          {favorito.requerimiento && (
                            <Requerimiento
                              requerimiento={favorito.requerimiento}
                            />
                          )}
                        </td>
                        <td>
                          {favorito.medida && (
                            <span className="badge badge-soft-dark font-12">
                              Medida Provisional
                            </span>
                          )}
                          {favorito.procedimiento && (
                            <span className="badge badge-soft-danger font-12">
                              Procedimiento Sancionatorio
                            </span>
                          )}
                          {favorito.requerimiento && (
                            <span className="badge badge-soft-warning font-12">
                              Requerimiento de Ingreso
                            </span>
                          )}
                        </td>
                        {perfil && perfil in ["3", "2"] && (
                          <td className="text-center">
                            <button
                              className="btn btn-danger btn-sm"
                              data-tip
                              data-tooltip-id="eliminar_de_favoritos"
                              onClick={() =>
                                this.eliminacionFavorito(favorito.id)
                              }
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };
}

export default Tabs;
