import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  obtenerCsvProyecto,
  obtenerPermisos,
  PermisoIncluido,
  PermisoOmitir,
  PermisoSeleccionado,
} from "../../../redux/actions/permitting/action";
import { Footer, Navbar, SiderbarPermittingAplicabilidad } from "../../general";
import { withRouter } from "../../general/with_router";
import {
  FichaProyecto,
  ListadoPermisos,
  ListadoPermisosOmitidos,
} from "../general";
import { Filtros as FiltroPermisos } from "./";

export class Permisos extends Component {
  componentDidMount = () => {
    this.listadoPermisos();
  };

  listadoPermisos = () => {
    const { obtenerPermisos, consulta_seleccionada } = this.props;
    if (consulta_seleccionada > 0)
      obtenerPermisos({
        consulta: consulta_seleccionada,
      });
  };

  setPermiso = (id) => {
    const { PermisoSeleccionado } = this.props;
    PermisoSeleccionado({
      id: id ? id : 0,
      url: "/permitting-target/aplicabilidad/permisos",
    });
  };

  omitirPermiso = (permiso) => {
    const { PermisoOmitir, consulta_seleccionada } = this.props;

    SweetAlert.fire({
      title: "Omitir Permiso",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de omitir el permiso ${permiso.nombre}?`,
      icon: "question",
      confirmButtonText: 'Si, Omitir <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        PermisoOmitir({
          consulta: consulta_seleccionada,
          permiso: permiso.id,
        });
      }
    });
  };

  incluirPermiso = (permiso) => {
    const { PermisoIncluido, consulta_seleccionada } = this.props;
    SweetAlert.fire({
      title: "Incluir Permiso",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de volver a incluir el permiso ${permiso.nombre}?`,
      icon: "question",
      confirmButtonText: 'Si, Incluir <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        PermisoIncluido({
          consulta: consulta_seleccionada,
          permiso: permiso.id,
        });
      }
    });
  };

  descargarCsv = () => {
    const { consulta_seleccionada, obtenerCsvProyecto } = this.props;
    obtenerCsvProyecto({
      consulta: consulta_seleccionada,
    });
  };

  render = () => {
    const {
      permisos,
      is_descargar,
      omitidos,
      is_loading_permitting,
      consulta_seleccionada,
      permisos_error,
      permiso_omitido,
      permiso_omitido_error,
      permiso_incluido,
      permiso_incluido_error,
    } = this.props;

    if (consulta_seleccionada === 0) {
      return <Navigate to="/permitting-target/proyectos" />;
    }

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Plan maestro de permisos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos procesando información, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (Object.entries(permisos_error).length > 0 && permisos_error.message) {
      SweetAlert.fire({
        title: "Plan maestro de permisos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: permisos_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        this.props.navigate("/user/inicio");
      });
    }

    if (Object.entries(permiso_omitido_error).length > 0) {
      SweetAlert.fire({
        title: "Error Permiso Omitido",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: permiso_omitido_error.data.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }

    if (Object.entries(permiso_omitido).length > 0) {
      SweetAlert.fire({
        title: "Permiso Omitido",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: permiso_omitido.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        this.listadoPermisos();
      });
    }

    if (Object.entries(permiso_incluido_error).length > 0) {
      SweetAlert.fire({
        title: "Error Permiso Incluido",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: permiso_incluido_error.data.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }

    if (Object.entries(permiso_incluido).length > 0) {
      SweetAlert.fire({
        title: "Permiso Incluido",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: permiso_incluido.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        this.listadoPermisos();
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SiderbarPermittingAplicabilidad menu_1="permitting_permisos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h4>REPORTE DE APLICABILIDAD</h4>
                  </div>
                  <div className="card-body">
                    <ol className="breadcrumb mt-0 mb-0 font-14">
                      <li className="breadcrumb-item">
                        <Link to="/permitting-target/proyectos">Proyectos</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Plan maestro de permisos
                      </li>
                    </ol>
                    <FichaProyecto />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h5>ETAPA ACTUAL DEL PROCESO DE LA CONSULTA</h5>
                  </div>
                  <div className="card-body">
                    <ol className="c-progress-steps">
                      <li className="c-progress-steps__step">
                        <span>1. Parametrización de la consulta</span>
                      </li>
                      <li className="c-progress-steps__step current">
                        <span>2. Reporte de aplicabilidad</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <FiltroPermisos />
                <div className="card mb-4">
                  <div className="card-body">
                    <ul className="nav nav-pills nav-justified">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="aplicabilidad-permisos-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#aplicabilidad-permisos"
                          type="button"
                          role="tab"
                          aria-controls="aplicabilidad-permisos"
                          aria-selected="true"
                        >
                          Permisos Aplicables <small>({permisos.length})</small>
                        </button>
                      </li>
                      {omitidos && omitidos.length > 0 && (
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="aplicabilidad-permisos-omitidos-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#aplicabilidad-permisos-omitidos"
                            type="button"
                            role="tab"
                            aria-controls="aplicabilidad-permisos-omitidos"
                            aria-selected="true"
                          >
                            Permisos Omitidos <small>({omitidos.length})</small>
                          </button>
                        </li>
                      )}
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show"
                        id="aplicabilidad-permisos"
                        role="tabpanel"
                        aria-labelledby="aplicabilidad-permisos-tab"
                      >
                        <ListadoPermisos
                          permisos={permisos}
                          ulr_permiso="/permitting-target/aplicabilidad/permiso"
                          setPermiso={this.setPermiso}
                          omitirPermiso={this.omitirPermiso}
                          descargarCsv={this.descargarCsv}
                          is_descargar={is_descargar}
                          is_btn_omitir={true}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="aplicabilidad-permisos-omitidos"
                        role="tabpanel"
                        aria-labelledby="aplicabilidad-permisos-omitidos-tab"
                      >
                        <ListadoPermisosOmitidos
                          permisos={omitidos}
                          incluirPermiso={this.incluirPermiso}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Permisos.propTypes = {
  obtenerCsvProyecto: PropTypes.func.isRequired,
  obtenerPermisos: PropTypes.func.isRequired,
  PermisoSeleccionado: PropTypes.func.isRequired,
  PermisoOmitir: PropTypes.func.isRequired,
  PermisoIncluido: PropTypes.func.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  permisos: PropTypes.array.isRequired,
  permisos_error: PropTypes.object.isRequired,
  consulta_seleccionada: PropTypes.number.isRequired,
  is_descargar: PropTypes.bool.isRequired,
  omitidos: PropTypes.array.isRequired,
  permiso_omitido_error: PropTypes.object.isRequired,
  permiso_omitido: PropTypes.object.isRequired,
  permiso_incluido_error: PropTypes.object.isRequired,
  permiso_incluido: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_permitting: state.permitting.is_loading_permitting,
  permisos: state.permitting.permisos,
  permisos_error: state.permitting.permisos_error,
  consulta_seleccionada: state.permitting.consulta_seleccionada,
  is_descargar: state.permitting.is_descargar,
  omitidos: state.permitting.omitidos,
  permiso_omitido: state.permitting.permiso_omitido,
  permiso_omitido_error: state.permitting.permiso_omitido_error,
  permiso_incluido: state.permitting.permiso_incluido,
  permiso_incluido_error: state.permitting.permiso_incluido_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerPermisos,
      PermisoSeleccionado,
      obtenerCsvProyecto,
      PermisoOmitir,
      PermisoIncluido,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Permisos)
);
