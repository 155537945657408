import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerDashboard } from "../../../redux/actions/vigilancias/quinenco/action";
import { Chart, Footer, Navbar, Pie, SidebarInicio } from "../../general";

export class Listado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ver_expedientes: true,
      ver_mapa: false,
    };
  }

  componentDidMount = () => {
    const { obtenerDashboard, quinenco_dashboard } = this.props;
    if (Object.entries(quinenco_dashboard).length === 0) {
      obtenerDashboard();
    }
  };

  render = () => {
    const { quinenco_dashboard, is_loading_vigilancias } = this.props;

    var info = quinenco_dashboard.result;

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Dashboard Quiñenco",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Generando información, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="empresa_quinenco" menu_2="dashboard" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-leaf"></i> QUIÑENCO{" "}
                    <small>({info?.total && info.total})</small>
                  </h4>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">Unidades Fiscalizables Totales</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-danger">{info?.total}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">ENEX</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-purple">{info?.total_enex}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">CCU</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-purple">{info?.total_ccu}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">SAAM</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-purple">{info?.total_saam}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">NEXANS</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-purple">{info?.total_nexans}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <Pie data={info?.rcas_tipo} nombre="RCA por tipo" />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8">
                <Chart
                  is_vertical
                  data={info?.regiones}
                  nombre="Unidades Fiscalizables"
                  titulo="Unidades Fiscalizables por región"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  data={info?.categorias}
                  nombre="Unidades Fiscalizables"
                  titulo="Unidades Fiscalizables por categoría"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  is_vertical
                  data={info?.comunas}
                  nombre="Unidades Fiscalizables"
                  titulo="Unidades Fiscalizables ingresados por comunas"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  data={info?.fiscalizaciones_anio}
                  nombre="Fiscalizaciones"
                  titulo="Fiscalizaciones por año"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  data={info?.fiscalizaciones_tipo}
                  nombre="Fiscalizaciones"
                  titulo="Fiscalizaciones por tipo"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  data={info?.fiscalizaciones_instrumento}
                  nombre="Fiscalizaciones"
                  titulo="Fiscalizaciones por instrumento"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  data={info?.rcas_anio}
                  nombre="RCA"
                  titulo="RCA por año"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  data={info?.rcas_tipologia}
                  nombre="RCA"
                  titulo="RCA por tipología"
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerDashboard: PropTypes.func.isRequired,
  quinenco_dashboard: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  quinenco_dashboard: state.vigilancias.quinenco_dashboard,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerDashboard }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
