export const RCAS_OBTENER = 'RCAS_OBTENER';
export const RCAS_FALLIDO = 'RCAS_FALLIDO';
export const RCA_GUARDAR = 'RCA_GUARDAR';
export const RCAS_FILTROS_OBTENER = 'RCAS_FILTROS_OBTENER';
export const RCAS_FILTROS_FALLIDO = 'RCAS_FILTROS_FALLIDO';
export const RCAS_FILTROS_INPUT = 'RCAS_FILTROS_INPUT';
export const LIMPIAR_RCAS_FILTROS = 'LIMPIAR_RCAS_FILTROS';
export const RCA_OBTENER = 'RCA_OBTENER';
export const RCA_FALLIDO = 'RCA_FALLIDO';
export const RCAS_ESTADISTICAS_OBTENER = 'RCAS_ESTADISTICAS_OBTENER';
export const RCAS_ESTADISTICAS_FALLIDO = 'RCAS_ESTADISTICAS_FALLIDO';
