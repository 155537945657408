import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import { guardarEncargado } from "../../../redux/actions/vigilancias/encargados/action";

export class Tabla extends Component {
  setEncargado = (id) => {
    const { guardarEncargado } = this.props;
    guardarEncargado(id);
  };

  render = () => {
    const { encargados } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre</th>
              <th className="w-15">Email</th>
              <th className="text-center w-10">Expedientes SEIA</th>
              <th className="text-center w-10">Pertinencias</th>
              <th className="text-center w-3">Acción</th>
            </tr>
          </thead>
          <tbody>
            {encargados && encargados.result && encargados.result.length > 0 ? (
              encargados.result.map((encargado, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {encargados.page > 1
                      ? (encargados.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td className="text-uppercase">{encargado.nombre}</td>
                  <td>{encargado.email}</td>
                  <td className="text-center">
                    {encargado.expedientes ? encargado.expedientes : 0}
                  </td>
                  <td className="text-center">
                    {encargado.pertinencias ? encargado.pertinencias : 0}
                  </td>
                  <td className="text-center">
                    <Link
                      to="/compliance-analytics/encargado"
                      className="btn btn-soft-dark"
                      onClick={this.setEncargado.bind(this, encargado.id)}
                    >
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="6">
                  No existen encargados
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarEncargado: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarEncargado }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
