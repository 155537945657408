import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerPertinencia } from "../../../redux/actions/vigilancias/pertinencia/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Guardar as GuardarFavorito } from "../favoritos/";
import { Tabs as Pertinencias } from "../pertinencias/tabs";

export class Detalle extends Component {
  componentDidMount = () => {
    const { obtenerPertinencia, pertinencia_id } = this.props;
    if (pertinencia_id > 0) {
      obtenerPertinencia({
        id: pertinencia_id,
      });
    }
  };

  render = () => {
    const {
      is_loading_vigilancias,
      pertinencia_id,
      pertinencia,
      pertinencia_componente,
    } = this.props;

    if (pertinencia_id === 0) {
      return <Navigate to="/compliance-analytics/pertinencias/listado" />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Consulta de Pertinencia",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las consultas de pertinencia, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_pertinencias" menu_2="listado" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-calendar-check"></i> CONSULTAS DE
                    PERTINENCIAS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">{pertinencia.nombre}</h5>
                    <div className="float-end btn-group">
                      <GuardarFavorito tipo="pertinencia" id={pertinencia.id} />
                      <Link
                        className="btn btn-primary"
                        to={
                          pertinencia_componente === "pertinencias"
                            ? "/compliance-analytics/pertinencias/listado"
                            : "/quinenco/pertinencias"
                        }
                      >
                        <i className="fa fa-angle-left"></i> Volver al listado
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <Pertinencias pertinencias={[pertinencia]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerPertinencia: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  pertinencia: PropTypes.object.isRequired,
  pertinencia_id: PropTypes.number.isRequired,
  pertinencia_componente: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  pertinencia: state.vigilancias.pertinencia,
  pertinencia_id: state.vigilancias.pertinencia_id,
  pertinencia_componente: state.vigilancias.pertinencia_componente,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerPertinencia }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
