import React, { Component } from "react";

export class TablaPequena extends Component {
  render = () => {
    const { unidades_fiscalizables } = this.props;

    if (unidades_fiscalizables && unidades_fiscalizables.length > 1) {
      return unidades_fiscalizables.length < 1 ? (
        <span className="badge badge-soft-dark">Sin Información</span>
      ) : (
        <ul
          className={`ml--10 mb-0 ${
            unidades_fiscalizables.length > 8 ? "vh-30 overflow-auto" : ""
          }`}
        >
          {unidades_fiscalizables.map((titular, x) => (
            <li key={x}>
              {titular && titular.categoria_display && (
                <>
                  <span className="text-uppercase badge badge-danger me-2">
                    {titular.categoria_display}
                  </span>
                  <br />
                </>
              )}
              {titular && titular.nombre && (
                <span className="text-uppercase">{titular.nombre}</span>
              )}
              {titular && (titular.comuna || titular.region_display) && (
                <>
                  <span className="text-uppercase badge badge-soft-primary">
                    {titular.region_display && (
                      <span className="me-2">
                        Región {titular.region_display}
                      </span>
                    )}
                    {titular.comuna && titular.region_display && (
                      <span>Comuna: {titular.comuna}</span>
                    )}
                  </span>
                  <br />
                </>
              )}
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <>
          {unidades_fiscalizables && unidades_fiscalizables.length > 0 ? (
            unidades_fiscalizables.map((titular, x) => (
              <div key={x}>
                {titular && titular.categoria_display && (
                  <>
                    <span className="text-uppercase badge badge-danger">
                      {titular.categoria_display}
                    </span>
                    <br />
                  </>
                )}
                {titular && titular.nombre && (
                  <span className="text-uppercase">{titular.nombre}</span>
                )}
                {titular && (titular.comuna || titular.region_display) && (
                  <div>
                    <span className="text-uppercase badge badge-soft-primary">
                      {titular.region_display && (
                        <span className="me-2">
                          Región {titular.region_display}
                        </span>
                      )}
                      {titular.comuna && titular.region_display && (
                        <span>Comuna: {titular.comuna}</span>
                      )}
                    </span>
                  </div>
                )}
              </div>
            ))
          ) : (
            <span className="badge badge-soft-dark">Sin Información</span>
          )}
        </>
      );
    }
  };
}

export default TablaPequena;
