export const HECHOS_OBTENER = 'HECHOS_OBTENER';
export const HECHOS_FALLIDO = 'HECHOS_FALLIDO';
export const HECHOS_FILTROS_OBTENER = 'HECHOS_FILTROS_OBTENER';
export const HECHOS_FILTROS_FALLIDO = 'HECHOS_FILTROS_FALLIDO';
export const HECHO_GUARDAR = 'HECHO_GUARDAR';
export const HECHO_OBTENER = 'HECHO_OBTENER';
export const HECHO_FALLIDO = 'HECHO_FALLIDO';
export const LIMPIAR_HECHOS_FILTROS = 'LIMPIAR_HECHOS_FILTROS';
export const HECHOS_FILTROS_INPUT = 'HECHOS_FILTROS_INPUT';
export const HECHOS_ESTADISTICAS_OBTENER = 'HECHOS_ESTADISTICAS_OBTENER';
export const HECHOS_ESTADISTICAS_FALLIDO = 'HECHOS_ESTADISTICAS_FALLIDO';
