import React, { Component } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerProcedimientosEstadistica } from "../../../redux/actions/vigilancias/procedimientos/action";
import { Chart, Footer, Navbar, SidebarInicio } from "../../general";

export class Estadisticas extends Component {
  componentDidMount = () => {
    const { obtenerProcedimientosEstadistica, procedimientos_estadisticas } =
      this.props;
    if (Object.entries(procedimientos_estadisticas).length === 0) {
      obtenerProcedimientosEstadistica(1);
    }
  };

  render = () => {
    const { procedimientos_estadisticas, is_loading_vigilancias } = this.props;

    var estados = _.countBy(
      _.orderBy(procedimientos_estadisticas.result, "estado_display"),
      "estado_display"
    );

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "SEIA",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los procedimientos, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio
          menu_1="vigilancias_procedimientos"
          menu_2="estadistica"
        />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-bullhorn"></i> PROCEDIMIENTOS
                    SANCIONATORIOS{" "}
                    <small>
                      (
                      {procedimientos_estadisticas &&
                        procedimientos_estadisticas.total &&
                        procedimientos_estadisticas.total}
                      )
                    </small>
                  </h4>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  data={estados}
                  nombre="Procedimientos Sancionatorios"
                  titulo="Procedimientos Sancionatorios por estado"
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Estadisticas.propTypes = {
  obtenerProcedimientosEstadistica: PropTypes.func.isRequired,
  procedimientos_estadisticas: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  procedimientos_estadisticas: state.vigilancias.procedimientos_estadisticas,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerProcedimientosEstadistica }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Estadisticas);
