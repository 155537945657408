import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  LIMPIAR_PROCEDIMIENTO_FILTROS,
  PROCEDIMIENTO_FALLIDO,
  PROCEDIMIENTO_GUARDAR,
  PROCEDIMIENTO_OBTENER,
  PROCEDIMIENTOS_ESTADISTICAS_FALLIDO,
  PROCEDIMIENTOS_ESTADISTICAS_OBTENER,
  PROCEDIMIENTOS_FALLIDO,
  PROCEDIMIENTOS_FILTROS_FALLIDO,
  PROCEDIMIENTOS_FILTROS_INPUT,
  PROCEDIMIENTOS_FILTROS_OBTENER,
  PROCEDIMIENTOS_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerProcedimientosEstadistica =
  (pagina) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .get(
        `${url_api}/api/vigilances/procedimientos/estadisticas/${pagina}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: PROCEDIMIENTOS_ESTADISTICAS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : PROCEDIMIENTOS_ESTADISTICAS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerProcedimientos = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/procedimientos/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: PROCEDIMIENTOS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : PROCEDIMIENTOS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerProcedimiento = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/procedimiento`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: PROCEDIMIENTO_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : PROCEDIMIENTO_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerProcedimientosFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/procedimientos/filtros/${data.pagina}`,
      data.filtros,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: PROCEDIMIENTOS_FILTROS_INPUT, payload: data.filtros });
      dispatch({ type: PROCEDIMIENTOS_FILTROS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : PROCEDIMIENTOS_FILTROS_FALLIDO,
        payload: error,
      });
    });
};

export const guardarProcedimiento = (id, componente) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  dispatch({
    type: PROCEDIMIENTO_GUARDAR,
    payload: { id: id, componente: componente },
  });
};

export const limpiarFiltroProcedimiento = () => (dispatch) => {
  dispatch({ type: LIMPIAR_PROCEDIMIENTO_FILTROS });
};
