import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import {
  CARGANDO_LIMPIAR_VGC,
  LIMPIAR_TEAMS_MENSAJES,
  TEAMS_EQUIPOS_FALLIDO,
  TEAMS_EQUIPOS_FILTROS_FALLIDO,
  TEAMS_EQUIPOS_FILTROS_INPUT,
  TEAMS_EQUIPOS_FILTROS_OBTENER,
  TEAMS_EQUIPOS_OBTENER,
  TEAMS_EQUIPO_BUSCAR_FALLIDO,
  TEAMS_EQUIPO_BUSCAR_OBTENER,
  TEAMS_EQUIPO_CREAR_FALLIDO,
  TEAMS_EQUIPO_CREAR_OBTENER,
  TEAMS_EQUIPO_EDITAR_FALLIDO,
  TEAMS_EQUIPO_EDITAR_OBTENER,
  TEAMS_EQUIPO_ELIMINAR_FALLIDO,
  TEAMS_EQUIPO_ELIMINAR_OBTENER,
  TEAMS_EQUIPO_FALLIDO,
  TEAMS_EQUIPO_FAVORITO_CREAR_FALLIDO,
  TEAMS_EQUIPO_FAVORITO_CREAR_OBTENER,
  TEAMS_EQUIPO_FAVORITO_ELIMINAR_FALLIDO,
  TEAMS_EQUIPO_FAVORITO_ELIMINAR_OBTENER,
  TEAMS_EQUIPO_GUARDAR,
  TEAMS_EQUIPO_OBTENER,
  TEAMS_EQUIPO_USUARIO_AGREGAR_FALLIDO,
  TEAMS_EQUIPO_USUARIO_AGREGAR_OBTENER,
  TEAMS_EQUIPO_USUARIO_ELIMINAR_FALLIDO,
  TEAMS_EQUIPO_USUARIO_ELIMINAR_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerEquipos = () => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .get(`${url_api}/api/teams/equipos`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: TEAMS_EQUIPOS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_EQUIPOS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerEquipo = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .post(`${url_api}/api/teams/equipo`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: TEAMS_EQUIPO_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_EQUIPO_FALLIDO,
        payload: error,
      });
    });
};

export const buscarExpedienteEquipo = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .post(`${url_api}/api/teams/equipo/buscar`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: TEAMS_EQUIPO_BUSCAR_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_EQUIPO_BUSCAR_FALLIDO,
        payload: error,
      });
    });
};

export const crearEquipo = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .post(`${url_api}/api/teams/equipo/crear`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: TEAMS_EQUIPO_CREAR_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_EQUIPO_CREAR_FALLIDO,
        payload: error,
      });
    });
};

export const editarEquipo = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .post(`${url_api}/api/teams/equipo/editar`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: TEAMS_EQUIPO_EDITAR_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_EQUIPO_EDITAR_FALLIDO,
        payload: error,
      });
    });
};

export const eliminarEquipo = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .post(`${url_api}/api/teams/equipo/eliminar`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: TEAMS_EQUIPO_ELIMINAR_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_EQUIPO_ELIMINAR_FALLIDO,
        payload: error,
      });
    });
};

export const eliminarIntegrante = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .post(
      `${url_api}/api/teams/equipo/usuario/eliminar`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: TEAMS_EQUIPO_USUARIO_ELIMINAR_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_EQUIPO_USUARIO_ELIMINAR_FALLIDO,
        payload: error,
      });
    });
};

export const agregarIntegrante = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .post(
      `${url_api}/api/teams/equipo/usuario/agregar`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: TEAMS_EQUIPO_USUARIO_AGREGAR_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_EQUIPO_USUARIO_AGREGAR_FALLIDO,
        payload: error,
      });
    });
};

export const eliminarFavorito = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .post(`${url_api}/api/teams/favorito/eliminar`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: TEAMS_EQUIPO_FAVORITO_ELIMINAR_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_EQUIPO_FAVORITO_ELIMINAR_FALLIDO,
        payload: error,
      });
    });
};

export const crearFavorito = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .post(`${url_api}/api/teams/favorito/crear`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: TEAMS_EQUIPO_FAVORITO_CREAR_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_EQUIPO_FAVORITO_CREAR_FALLIDO,
        payload: error,
      });
    });
};

export const guardarEquipo = (data) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  dispatch({ type: TEAMS_EQUIPO_GUARDAR, payload: data });
};

export const limpiarMensajes = () => (dispatch) => {
  dispatch({ type: LIMPIAR_TEAMS_MENSAJES });
};

export const obtenerEquiposFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .post(
      `${url_api}/api/teams/equipos/filtros/${data.pagina}`,
      data.filtros,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: TEAMS_EQUIPOS_FILTROS_INPUT, payload: data.filtros });
      dispatch({ type: TEAMS_EQUIPOS_FILTROS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_EQUIPOS_FILTROS_FALLIDO,
        payload: error,
      });
    });
};
