import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import {
  obtenerRcas,
  obtenerRcasFiltro,
} from "../../../redux/actions/vigilancias/rcas/action";

export class Rcas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre:
        this.props.rcas_filtro_input && this.props.rcas_filtro_input.nombre
          ? this.props.rcas_filtro_input.nombre
          : "",
      filtro_tipo:
        this.props.rcas_filtro_input && this.props.rcas_filtro_input.tipo
          ? this.props.rcas_filtro_input.tipo
          : "",
      filtro_region:
        this.props.rcas_filtro_input && this.props.rcas_filtro_input.region
          ? this.props.rcas_filtro_input.region
          : "",
      filtro_numero:
        this.props.rcas_filtro_input && this.props.rcas_filtro_input.numero
          ? this.props.rcas_filtro_input.numero
          : "",
      filtro_anio:
        this.props.rcas_filtro_input && this.props.rcas_filtro_input.anio
          ? this.props.rcas_filtro_input.anio
          : "",
      filtro_fase:
        this.props.rcas_filtro_input && this.props.rcas_filtro_input.fase
          ? this.props.rcas_filtro_input.fase
          : "",
      filtro_fiscalizacion:
        this.props.rcas_filtro_input &&
        this.props.rcas_filtro_input.fiscalizacion
          ? this.props.rcas_filtro_input.fiscalizacion
          : "",
      filtro_sancionado:
        this.props.rcas_filtro_input && this.props.rcas_filtro_input.sancionado
          ? this.props.rcas_filtro_input.sancionado
          : "",
      filtros_avanzandos:
        this.props.rcas_filtro_input &&
        this.props.rcas_filtro_input.filtros_avanzandos
          ? this.props.rcas_filtro_input.filtros_avanzandos
          : false,
    };
  }

  filtroRcas = () => {
    const { obtenerRcasFiltro, obtenerRcas } = this.props;
    const {
      filtro_nombre,
      filtro_tipo,
      filtro_region,
      filtro_numero,
      filtro_anio,
      filtro_fase,
      filtro_fiscalizacion,
      filtro_sancionado,
      filtros_avanzandos,
    } = this.state;

    if (
      filtro_nombre ||
      filtro_tipo ||
      filtro_region ||
      filtro_numero ||
      filtro_anio ||
      filtro_fase ||
      filtro_fiscalizacion ||
      filtro_sancionado
    ) {
      var sancionado = null;
      if (filtro_sancionado === "") {
        sancionado = null;
      } else if (filtro_sancionado === "Si") {
        sancionado = true;
      } else if (filtro_sancionado === "No") {
        sancionado = false;
      }

      var fiscalizacion = null;
      if (filtro_fiscalizacion === "") {
        fiscalizacion = null;
      } else if (filtro_fiscalizacion === "Si") {
        fiscalizacion = true;
      } else if (filtro_fiscalizacion === "No") {
        fiscalizacion = false;
      }

      var filtros = {
        nombre: filtro_nombre ? filtro_nombre : null,
        tipo: filtro_tipo ? filtro_tipo : null,
        region: filtro_region ? filtro_region : null,
        numero: filtro_numero ? filtro_numero : null,
        anio: filtro_anio ? filtro_anio : null,
        fase: filtro_fase ? filtro_fase : null,
        fiscalizacion: fiscalizacion,
        sancionado: sancionado,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerRcasFiltro({ filtros: filtros, pagina: 1 });
    } else {
      obtenerRcas(1);
    }
  };

  borrarFiltros = () => {
    const { obtenerRcas } = this.props;
    this.setState({
      filtro_nombre: "",
      filtro_tipo: "",
      filtro_region: "",
      filtro_numero: "",
      filtro_anio: "",
      filtro_fase: "",
      filtro_fiscalizacion: "",
      filtro_sancionado: "",
    });
    obtenerRcas(1);
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render = () => {
    const { filtros_rcas, filtros_errores } = this.props;
    const {
      filtro_nombre,
      filtro_tipo,
      filtro_region,
      filtro_numero,
      filtro_anio,
      filtro_fase,
      filtro_fiscalizacion,
      filtro_sancionado,
      filtros_avanzandos,
    } = this.state;

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  name="filtro_nombre"
                  onChange={this.onChange}
                  value={filtro_nombre}
                  placeholder="Ingrese nombre del titular"
                  className={`form-control ${
                    filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.nombre &&
                  filtros_errores.data.nombre.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipo</label>
                <SelectPicker
                  name="filtro_tipo"
                  data={filtros_rcas.tipos ? filtros_rcas.tipos : []}
                  onChange={(value) => {
                    this.setState({ filtro_tipo: value });
                  }}
                  value={filtro_tipo}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipo &&
                  filtros_errores.data.tipo.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Número RCA</label>
                <input
                  type="text"
                  name="filtro_numero"
                  onChange={this.onChange}
                  value={filtro_numero}
                  placeholder="Ingrese número de la RCA"
                  className={`form-control ${
                    filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.numero
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.numero &&
                  filtros_errores.data.numero.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Año</label>
                <SelectPicker
                  name="filtro_anio"
                  data={filtros_rcas.anios ? filtros_rcas.anios : []}
                  onChange={(value) => {
                    this.setState({ filtro_anio: value });
                  }}
                  value={filtro_anio}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.anio &&
                  filtros_errores.data.anio.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Región</label>
                <SelectPicker
                  name="filtro_region"
                  data={filtros_rcas.regiones ? filtros_rcas.regiones : []}
                  onChange={(value) => {
                    this.setState({ filtro_region: value });
                  }}
                  value={filtro_region}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.region &&
                  filtros_errores.data.region.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fase</label>
                <SelectPicker
                  name="filtro_fase"
                  data={filtros_rcas.fases ? filtros_rcas.fases : []}
                  onChange={(value) => {
                    this.setState({ filtro_fase: value });
                  }}
                  value={filtro_fase}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.fase &&
                  filtros_errores.data.fase.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>¿Fiscalización?</label>
                <div className="input-group">
                  <select
                    name="filtro_fiscalizacion"
                    onChange={this.onChange}
                    value={filtro_fiscalizacion}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fiscalizacion
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fiscalizacion &&
                    filtros_errores.data.fiscalizacion.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>¿Sancionado?</label>
                <div className="input-group">
                  <select
                    name="filtro_sancionado"
                    onChange={this.onChange}
                    value={filtro_sancionado}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.sancionado
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.sancionado &&
                    filtros_errores.data.sancionado.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroRcas}
            >
              <i className="fa fa-search"></i> Filtrar RCA
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Rcas.propTypes = {
  obtenerRcas: PropTypes.func.isRequired,
  obtenerRcasFiltro: PropTypes.func.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  filtros_rcas: PropTypes.object.isRequired,
  rcas_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  titulares: state.vigilancias.titulares,
  filtros_errores: state.vigilancias.filtros_errores,
  filtros_rcas: state.vigilancias.filtros_rcas,
  rcas_filtro_input: state.vigilancias.rcas_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerRcas, obtenerRcasFiltro }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Rcas);
