import React, { Component } from "react";

import { Link } from "react-router-dom";

export class Inicio extends Component {
  render = () => {
    const { menu_1, tasks } = this.props;
    return (
      tasks && (
        <>
          <h5 className="mt-3">QUALITY TASKING</h5>
          <li
            className={
              menu_1 && menu_1 === "tareas_dashboard"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/quality-tasking/dashboard">
              <i className="fa fa-tachometer-alt"></i>
              <span> Dashboard</span>
            </Link>
          </li>
          <li
            className={
              menu_1 && menu_1 === "tareas_proyectos"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/quality-tasking/proyectos">
              <i className="fa fa-tasks"></i>
              <span> Proyectos</span>
            </Link>
          </li>
          {/* <li className={menu_1 && menu_1 === 'tareas_equipo' ? 'mm-active' : 'mm-inactivo'}>
            <Link to="/quality-tasking/equipo"><i className="fa fa-users"></i><span> Equipo</span></Link>
          </li> */}
        </>
      )
    );
  };
}

export default Inicio;
