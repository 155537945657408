import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import {
  CARGANDO_LIMPIAR_ESTADISTICAS,
  ESTADISTICAS_FALLIDO,
  ESTADISTICAS_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerEstadisticas = () => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_ESTADISTICAS });
  axios
    .get(`${url_api}/api/users/estadisticas`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ESTADISTICAS_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : ESTADISTICAS_FALLIDO,
        payload: error,
      });
    });
};
