import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  eliminarFavorito,
  obtenerFavoritos,
} from "../../../redux/actions/vigilancias/favoritos/action";

export class Eliminar extends Component {
  deleteFavorito = () => {
    const { tipo, id, eliminarFavorito } = this.props;
    eliminarFavorito({
      tipo: tipo,
      id: id,
    });
  };

  render = () => {
    const { favorito_eliminar, obtenerFavoritos } = this.props;

    if (Object.entries(favorito_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Favorito",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: favorito_eliminar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-success me-3",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerFavoritos();
        }
      });
    }
    return (
      <Fragment>
        <Tooltip id="eliminar_favorito" aria-haspopup="true">
          Eliminar de favoritos
        </Tooltip>
        <button
          onClick={this.deleteFavorito.bind(this)}
          className="btn btn-danger"
          data-tip
          data-tooltip-id="eliminar_favorito"
        >
          <i className="fa fa-trash"></i>
        </button>
      </Fragment>
    );
  };
}

Eliminar.propTypes = {
  eliminarFavorito: PropTypes.func.isRequired,
  obtenerFavoritos: PropTypes.func.isRequired,
  favorito_eliminar: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  favorito_eliminar: state.vigilancias.favorito_eliminar,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ eliminarFavorito, obtenerFavoritos }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Eliminar);
