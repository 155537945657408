import React, { Component } from "react";
import { Maps } from "../../general/";
import { TablaPequena as Encargados } from "../encargados";
import { TablaPequena as Titulares } from "../titulares";

export class Tabs extends Component {
  render = () => {
    const { pertinencias } = this.props;

    if (pertinencias && pertinencias.length < 1) {
      return <h6>No existen Consultas de Pertinencias registradas</h6>;
    } else {
      return (
        <div className="align-items-start">
          <div className="row">
            {pertinencias.length > 1 && (
              <div className="col-3">
                <div
                  className="nav flex-column nav-pills me-3"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {pertinencias.map((pertinencia, x) => (
                    <button
                      key={x}
                      className={`nav-link ${
                        x === 0 ? "active" : ""
                      } text-start`}
                      id={`pertinencia-tab-${pertinencia.id}`}
                      data-bs-toggle="pill"
                      data-bs-target={`#pertinencia-${pertinencia.id}`}
                      type="button"
                      role="tab"
                      aria-controls={`pertinencia-${pertinencia.id}`}
                      aria-selected={x === 0 ? "true" : "false"}
                    >
                      {pertinencia.nombre}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className={`col-${pertinencias.length > 1 ? 9 : 12}`}>
              <div className="tab-content" id="v-pills-unidades_fiscalizables">
                {pertinencias.map((per, x) => (
                  <div
                    key={x}
                    className={`tab-pane fade ${x === 0 ? "show active" : ""}`}
                    id={`pertinencia-${per.id}`}
                    role="tabpanel"
                    aria-labelledby={`pertinencia-tab-${per.id}`}
                    tabIndex="0"
                  >
                    <>
                      <ul className="nav nav-pills nav-justified">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id={`pertinencia-general-tab-und-${per.id}`}
                            data-bs-toggle="pill"
                            data-bs-target={`#pertinencia-general-und-${per.id}`}
                            type="button"
                            role="tab"
                            aria-controls={`pertinencia-general-und-${per.id}`}
                            aria-selected="true"
                          >
                            Información de la Consulta de Pertinencia
                          </button>
                        </li>
                        {per.procesos && per.procesos.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`pertinencias-tab-procesos${per.id}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#pertinencias-procesos${per.id}`}
                              type="button"
                              role="tab"
                              aria-controls={`pertinencias-procesos${per.id}`}
                              aria-selected="true"
                            >
                              Folios (
                              {per.procesos.length > 0
                                ? per.procesos.length
                                : 0}
                              )
                            </button>
                          </li>
                        )}
                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show mt-3"
                          id={`pertinencia-general-und-${per.id}`}
                          role="tabpanel"
                          aria-labelledby={`pertinencia-general-tab-und-${per.id}`}
                        >
                          <table className="table">
                            <tbody>
                              <tr>
                                <td className="table-secondary w-15 text-dark">
                                  Nombre
                                </td>
                                <td>{per.nombre}</td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Pertinencias
                                </td>
                                <td>
                                  <a
                                    href={per.url_sea}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Ver pertinencia en SEA{" "}
                                    <i className="fa fa-angle-right"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Estado
                                </td>
                                <td>{per.estado}</td>
                              </tr>
                              {per.fecha_presentacion && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Fecha de presentación
                                  </td>
                                  <td>{per.fecha_presentacion}</td>
                                </tr>
                              )}
                              {per.region && per.region.length > 0 && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Región
                                  </td>
                                  <td>
                                    <ul className="mb-0">
                                      {per.region.map((reg, x) => (
                                        <li key={x}>{reg.nombre}</li>
                                      ))}
                                    </ul>
                                  </td>
                                </tr>
                              )}
                              {per.comuna && per.comuna.length > 0 && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Comuna
                                  </td>
                                  <td>
                                    <ul className="mb-0">
                                      {per.comuna.map((com, x) => (
                                        <li key={x}>{com.nombre}</li>
                                      ))}
                                    </ul>
                                  </td>
                                </tr>
                              )}
                              {per.titular && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Titular
                                  </td>
                                  <td>
                                    <Titulares titulares={[per.titular]} />
                                  </td>
                                </tr>
                              )}
                              {per.encargado && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Encargado
                                  </td>
                                  <td>
                                    <Encargados encargados={[per.encargado]} />
                                  </td>
                                </tr>
                              )}
                              {per.ubicacion && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Ubicación
                                  </td>
                                  <td>{per.ubicacion}</td>
                                </tr>
                              )}
                              <tr>
                                <td className="table-secondary text-dark">
                                  Tipo
                                </td>
                                <td>{per.tipo}</td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Inversión
                                </td>
                                <td>{per.inversion ? per.inversion : "S/I"}</td>
                              </tr>
                              {per.descripcion && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Extracto
                                  </td>
                                  <td>{per.descripcion}</td>
                                </tr>
                              )}
                              {per.descripcion2 && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Descripción
                                  </td>
                                  <td>{per.descripcion2}</td>
                                </tr>
                              )}
                              {per.tipologias && per.tipologias.length > 0 && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Tipologías
                                  </td>
                                  <td>
                                    <ul className="mb-0 ml-n3">
                                      {per.tipologias.length > 0 &&
                                        per.tipologias.map((tipologia, x) => (
                                          <li key={x}>
                                            {tipologia.tipo_string}
                                          </li>
                                        ))}
                                    </ul>
                                  </td>
                                </tr>
                              )}
                              {per.latitud && per.longitud && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Mapa
                                  </td>
                                  <td>
                                    <Maps
                                      center={[per.latitud, per.longitud]}
                                      zoom={11}
                                      markers={[
                                        {
                                          titulo:
                                            "SEA - Consulta de Pertinencia",
                                          position: [per.latitud, per.longitud],
                                          nombre: per.nombre,
                                        },
                                      ]}
                                      size={400}
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {per.procesos && per.procesos.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`pertinencias-procesos${per.id}`}
                            role="tabpanel"
                            aria-labelledby={`pertinencias-tab-procesos${per.id}`}
                          >
                            <table className="table table-bordered table-hover">
                              <thead className="table-secondary">
                                <tr>
                                  <th className="w-3 text-center">ID</th>
                                  <th>Nombre</th>
                                  <th className="w-10">Fecha de generación</th>
                                  <th className="w-10">Fecha de publicación</th>
                                  <th className="w-11">Número de documento</th>
                                  <th className="w-25">Remitido</th>
                                  <th className="w-10">Destinatario</th>
                                </tr>
                              </thead>
                              <tbody>
                                {per.procesos.length > 0 &&
                                  per.procesos.map((proceso, x) => (
                                    <tr key={x}>
                                      <td className="text-center">{x + 1}</td>
                                      <td>
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={proceso.url_archivo}
                                        >
                                          {proceso.nombre}{" "}
                                          <i className="fa fa-angle-right"></i>
                                        </a>
                                      </td>
                                      <td>{proceso.doc_fecha_publicacion}</td>
                                      <td>{proceso.doc_fecha_generacion}</td>
                                      <td>{proceso.doc_numero}</td>
                                      <td>{proceso.remitido}</td>
                                      <td>{proceso.destinatario}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}

export default Tabs;
