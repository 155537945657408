import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  estadoTarea,
  limpiarMensajes,
  obtenerTarea,
} from "../../../redux/actions/tareas/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Detalle as Entregable } from "../entregable/";
import { Tabla as Equipo } from "../equipo/";
import { Tabla as PlanAccion } from "../planaccion/";
import { Ficha } from "./";

export class Detalle extends Component {
  componentDidMount = () => {
    this.tareaSeleccionada();
  };

  tareaSeleccionada = () => {
    const { obtenerTarea, tarea_seleccionada } = this.props;
    obtenerTarea({
      tarea: tarea_seleccionada,
    });
  };

  inicioTarea = () => {
    const { estadoTarea, tarea_seleccionada, tarea } = this.props;
    if (tarea.entregables.length === 0) {
      SweetAlert.fire({
        title: "Entregables",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Para iniciar la tarea es obligatorio crear un entregable",
        icon: "warning",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    } else if (!tarea.responsable) {
      SweetAlert.fire({
        title: "Responsable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Para iniciar la tarea es obligatorio asignar un responsable de la tarea",
        icon: "warning",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    } else if (tarea.equipo.length < 2) {
      SweetAlert.fire({
        title: "Responsable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Para iniciar la tarea el equipo debe tener al menos 2 miembros",
        icon: "warning",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    } else {
      SweetAlert.fire({
        title: "Iniciar la tarea",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        text: `¿Está seguro de iniciar la tarea?`,
        icon: "info",
        confirmButtonText: 'Si, Iniciar <i class="fa fa-thumbs-up"></i>',
        cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
        customClass: {
          confirmButton: "btn btn-primary me-2",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          estadoTarea({
            tarea: tarea_seleccionada,
            estado: "en_curso",
          });
          SweetAlert.fire({
            title: "Tarea",
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: "Estamos guardando la tarea, espere unos segundos",
            icon: "warning",
            showConfirmButton: false,
            didOpen: () => {
              SweetAlert.showLoading();
            },
          });
        }
      });
    }
  };

  terminoTarea = () => {
    const { estadoTarea, tarea_seleccionada } = this.props;
    SweetAlert.fire({
      title: "Finalizar la tarea",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de finalizar la tarea?`,
      icon: "info",
      confirmButtonText: 'Si, Finalizar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        estadoTarea({
          tarea: tarea_seleccionada,
          estado: "finalizada",
        });
        SweetAlert.fire({
          title: "Tarea",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos guardando la tarea, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  render = () => {
    const {
      tarea,
      user,
      tarea_seleccionada,
      is_loading_tareas,
      tarea_estado_error,
      tarea_estado,
      limpiarMensajes,
    } = this.props;

    if (tarea_seleccionada === 0) {
      return <Navigate to="/quality-tasking/proyectos" />;
    }

    var user_equipo =
      tarea && tarea.equipo
        ? tarea.equipo.find((usuario) => usuario.email_text === user.email)
        : {};

    if (is_loading_tareas) {
      SweetAlert.fire({
        title: "Tarea",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos cargando la tarea seleccionada, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (
      Object.entries(tarea_estado_error).length > 0 &&
      tarea_estado_error.data &&
      tarea_estado_error.data.message
    ) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Tarea",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: tarea_estado_error.data.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(tarea_estado).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Tarea",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: tarea_estado.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.tareaSeleccionada();
        }
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="tareas_proyectos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tasks"></i> TAREA
                  </h4>
                  <div className="btn-group float-end">
                    <Link
                      className="btn btn-primary"
                      to="/quality-tasking/proyectos"
                    >
                      <i className="fa fa-angle-left"></i> Volver al Listado
                    </Link>
                    {tarea.estado_text === "No Iniciada" &&
                      user_equipo.rol_text === "Propietario" && (
                        <button
                          onClick={this.inicioTarea}
                          className="btn btn-success"
                          type="button"
                        >
                          Iniciar Tarea <i className="fa fa-play-circle"></i>
                        </button>
                      )}
                    {tarea.estado_text === "En Curso" &&
                      user_equipo.rol_text === "Propietario" && (
                        <button
                          onClick={this.terminoTarea}
                          className="btn btn-danger"
                          type="button"
                        >
                          Finalizar Tarea <i className="fa fa-stop-circle"></i>
                        </button>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>{tarea.nombre}</h5>
                  </div>
                  <div className="card-body">
                    <div className="align-items-start">
                      <div className="row">
                        <div className="col-sm-12">
                          <ul className="nav nav-pills nav-justified">
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link active"
                                id="tarea-general-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#tarea-general"
                                type="button"
                                role="tab"
                                aria-controls="tarea-general"
                                aria-selected="true"
                              >
                                Parámetros de la Tarea
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="tarea-entregables-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#tarea-entregables"
                                type="button"
                                role="tab"
                                aria-controls="tarea-entregables"
                                aria-selected="false"
                              >
                                Entregables{" "}
                                <small>
                                  (
                                  {tarea.entregables
                                    ? tarea.entregables.length
                                    : 0}
                                  )
                                </small>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="tarea-plan-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#tarea-plan"
                                type="button"
                                role="tab"
                                aria-controls="tarea-plan"
                                aria-selected="false"
                              >
                                Plan de Acción{" "}
                                <small>
                                  (
                                  {tarea.planesaccion
                                    ? tarea.planesaccion.length
                                    : 0}
                                  )
                                </small>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="tarea-equipo-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#tarea-equipo"
                                type="button"
                                role="tab"
                                aria-controls="tarea-equipo"
                                aria-selected="false"
                              >
                                Equipo{" "}
                                <small>
                                  ({tarea.equipo ? tarea.equipo.length : 0})
                                </small>
                              </button>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              className="tab-pane fade active show mt-3"
                              id="tarea-general"
                              role="tabpanel"
                              aria-labelledby="tarea-general-tab"
                            >
                              <Ficha
                                tarea={tarea}
                                tareaSeleccionada={this.tareaSeleccionada}
                              />
                            </div>
                            <div
                              className="tab-pane fade mb-3"
                              id="tarea-entregables"
                              role="tabpanel"
                              aria-labelledby="tarea-entregables-tab"
                            >
                              <Tooltip id="entregables" aria-haspopup="true">
                                <p>ENTREGABLE, ENTENDIDO COMO:</p>
                                <ul>
                                  <li>
                                    EL PRODUCTO SOLICITADO A UN COLABORADOR
                                  </li>
                                  <li>
                                    UNA TAREA PUEDE TENER UNO O VARIOS
                                    ENTREGABLES
                                  </li>
                                </ul>
                              </Tooltip>
                              <div className="row">
                                <div className="col-sm-12 mt-2">
                                  <h5 className="float-start text-uppercase">
                                    Listado de entregables{" "}
                                  </h5>
                                  {tarea.estado_text !== "Finalizada" &&
                                    user_equipo.rol_text === "Propietario" && (
                                      <div className="float-end">
                                        <Link
                                          to="/quality-tasking/tarea/entregable"
                                          className="btn btn-primary"
                                          type="button"
                                        >
                                          Crear Entregable{" "}
                                          <i className="fa fa-plus-circle"></i>
                                        </Link>
                                      </div>
                                    )}
                                </div>
                              </div>
                              <Entregable
                                tarea={tarea}
                                user_equipo={user_equipo}
                                tareaSeleccionada={this.tareaSeleccionada}
                              />
                            </div>
                            <div
                              className="tab-pane fade mb-3"
                              id="tarea-plan"
                              role="tabpanel"
                              aria-labelledby="tarea-plan-tab"
                            >
                              <PlanAccion
                                tarea={tarea}
                                user_equipo={user_equipo}
                                tareaSeleccionada={this.tareaSeleccionada}
                              />
                            </div>
                            <div
                              className="tab-pane fade mb-3"
                              id="tarea-equipo"
                              role="tabpanel"
                              aria-labelledby="tarea-equipo-tab"
                            >
                              <Equipo
                                tarea={tarea}
                                user_equipo={user_equipo}
                                tareaSeleccionada={this.tareaSeleccionada}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerTarea: PropTypes.func.isRequired,
  estadoTarea: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  tarea: PropTypes.object.isRequired,
  tarea_seleccionada: PropTypes.number.isRequired,
  tarea_estado: PropTypes.object.isRequired,
  tarea_estado_error: PropTypes.object.isRequired,
  is_loading_tareas: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  tarea: state.tareas.tarea,
  tarea_seleccionada: state.tareas.tarea_seleccionada,
  tarea_estado: state.tareas.tarea_estado,
  tarea_estado_error: state.tareas.tarea_estado_error,
  is_loading_tareas: state.tareas.is_loading_tareas,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerTarea, estadoTarea, limpiarMensajes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
