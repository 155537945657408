import { AUTENTIFICACION_FALLIDO } from "../actions/auth/types";
import {
  CARGANDO_LIMPIAR_VGC,
  LIMPIAR_TEAMS_MENSAJES,
  TEAMS_EQUIPOS_FALLIDO,
  TEAMS_EQUIPOS_FILTROS_FALLIDO,
  TEAMS_EQUIPOS_FILTROS_INPUT,
  TEAMS_EQUIPOS_FILTROS_OBTENER,
  TEAMS_EQUIPOS_OBTENER,
  TEAMS_EQUIPO_BUSCAR_FALLIDO,
  TEAMS_EQUIPO_BUSCAR_OBTENER,
  TEAMS_EQUIPO_CREAR_FALLIDO,
  TEAMS_EQUIPO_CREAR_OBTENER,
  TEAMS_EQUIPO_EDITAR_FALLIDO,
  TEAMS_EQUIPO_EDITAR_OBTENER,
  TEAMS_EQUIPO_ELIMINAR_FALLIDO,
  TEAMS_EQUIPO_ELIMINAR_OBTENER,
  TEAMS_EQUIPO_FALLIDO,
  TEAMS_EQUIPO_FAVORITO_CREAR_FALLIDO,
  TEAMS_EQUIPO_FAVORITO_CREAR_OBTENER,
  TEAMS_EQUIPO_FAVORITO_ELIMINAR_FALLIDO,
  TEAMS_EQUIPO_FAVORITO_ELIMINAR_OBTENER,
  TEAMS_EQUIPO_GUARDAR,
  TEAMS_EQUIPO_OBTENER,
  TEAMS_EQUIPO_USUARIO_AGREGAR_FALLIDO,
  TEAMS_EQUIPO_USUARIO_AGREGAR_OBTENER,
  TEAMS_EQUIPO_USUARIO_ELIMINAR_FALLIDO,
  TEAMS_EQUIPO_USUARIO_ELIMINAR_OBTENER,
} from "../actions/teams/equipos/types";
import {
  TEAMS_LIMPIAR_MEDIDAS_FILTROS,
  TEAMS_MEDIDAS_PROVISIONALES_FALLIDO,
  TEAMS_MEDIDAS_PROVISIONALES_FILTROS_FALLIDO,
  TEAMS_MEDIDAS_PROVISIONALES_FILTROS_INPUT,
  TEAMS_MEDIDAS_PROVISIONALES_FILTROS_OBTENER,
  TEAMS_MEDIDAS_PROVISIONALES_OBTENER,
} from "../actions/teams/medidas/types";
import {
  TEAMS_LIMPIAR_PROCEDIMIENTOS_FILTROS,
  TEAMS_PROCEDIMIENTOS_FALLIDO,
  TEAMS_PROCEDIMIENTOS_FILTROS_FALLIDO,
  TEAMS_PROCEDIMIENTOS_FILTROS_INPUT,
  TEAMS_PROCEDIMIENTOS_FILTROS_OBTENER,
  TEAMS_PROCEDIMIENTOS_OBTENER,
} from "../actions/teams/procedimientos/types";
import {
  TEAMS_LIMPIAR_REQUERIMIENTOS_INGRESO_FILTROS,
  TEAMS_REQUERIMIENTOS_INGRESOS_FALLIDO,
  TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_FALLIDO,
  TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_INPUT,
  TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_OBTENER,
  TEAMS_REQUERIMIENTOS_INGRESOS_OBTENER,
} from "../actions/teams/requerimientos/types";

const initialState = {
  is_loading_teams: true,
  usuarios: [],
  // equipos
  equipo_id: 0,
  equipos: {},
  equipos_filtros: {},
  equipos_filtro_input: {},
  equipo: {},
  filtros_errores: {},
  filtros_equipos: {},
  equipo_crear: {},
  equipo_crear_error: {},
  equipo_eliminar: {},
  equipo_eliminar_error: {},
  equipo_editar: {},
  equipo_editar_error: {},
  equipo_buscar: [],
  equipo_buscar_error: {},
  equipo_usuario_eliminar: {},
  equipo_usuario_eliminar_error: {},
  equipo_usuario_agregar: {},
  equipo_usuario_agregar_error: {},

  // favorito
  favorito_eliminar: {},
  favorito_eliminar_error: {},
  favorito_crear: {},
  favorito_crear_error: {},
  // busqueda
  procedimientos_resultados: {},
  // procedimientos
  procedimientos: {},
  procedimientos_filtros: {},
  procedimientos_filtro_input: {},
  filtros_procedimientos: {},
  // medidas
  medidas_provisionales: {},
  medidas_provisionales_filtros: {},
  medidas_provisionales_filtro_input: {},
  filtros_medidas_provisionales: {},
  // requerimientos
  requerimientos: {},
  requerimientos_filtros: {},
  requerimientos_filtro_input: {},
  filtros_requerimientos: {},
};

export default function vigilancias(state = initialState, action) {
  switch (action.type) {
    case AUTENTIFICACION_FALLIDO:
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
        is_authenticated: false,
        is_loading_teams: false,
      };
    // requerimientos
    case TEAMS_REQUERIMIENTOS_INGRESOS_OBTENER:
      return {
        ...state,
        requerimientos: action.payload.requerimientos,
        requerimiento_id: 0,
        filtros_requerimientos: action.payload.filtros,
        is_loading_teams: false,
      };
    case TEAMS_REQUERIMIENTOS_INGRESOS_FALLIDO:
      return {
        ...state,
        requerimientos: {},
        documento_id: 0,
        filtros_requerimientos: {},
        is_loading_teams: false,
      };
    case TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_OBTENER:
      return {
        ...state,
        requerimientos_filtros: action.payload.requerimientos,
        filtros_errores: {},
        is_loading_teams: false,
      };
    case TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_FALLIDO:
      return {
        ...state,
        requerimientos_filtros: {},
        filtros_errores: action.payload,
        is_loading_teams: false,
      };
    case TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_INPUT:
      return {
        ...state,
        requerimientos_filtro_input: action.payload,
      };
    case TEAMS_LIMPIAR_REQUERIMIENTOS_INGRESO_FILTROS:
      return {
        ...state,
        is_loading_teams: true,
        requerimientos_filtros: {},
        requerimientos_filtro_input: {},
        requerimientos: {},
      };
    // medidas
    case TEAMS_MEDIDAS_PROVISIONALES_FILTROS_INPUT:
      return {
        ...state,
        medidas_provisionales_filtro_input: action.payload,
      };

    case TEAMS_MEDIDAS_PROVISIONALES_FILTROS_FALLIDO:
      return {
        ...state,
        medidas_provisionales_filtros: {},
        filtros_errores: action.payload,
        is_loading_teams: false,
      };
    case TEAMS_MEDIDAS_PROVISIONALES_FILTROS_OBTENER:
      return {
        ...state,
        medidas_provisionales_filtros: action.payload.medidas,
        filtros_errores: {},
        is_loading_teams: false,
      };

    case TEAMS_MEDIDAS_PROVISIONALES_FALLIDO:
      return {
        ...state,
        medidas_provisionales: {},
        filtros_medidas: {},
        is_loading_teams: false,
      };
    case TEAMS_MEDIDAS_PROVISIONALES_OBTENER:
      return {
        ...state,
        medidas_provisionales: action.payload.medidas,
        filtros_medidas: action.payload.filtros,
        is_loading_teams: false,
      };
    case TEAMS_LIMPIAR_MEDIDAS_FILTROS:
      return {
        ...state,
        is_loading_teams: true,
        medidas_provisionales: {},
        medidas_provisionales_filtros: {},
        medidas_provisionales_filtro_input: {},
      };
    // procedimientos
    case TEAMS_PROCEDIMIENTOS_OBTENER:
      return {
        ...state,
        procedimientos: action.payload.procedimientos,
        procedimiento_id: 0,
        filtros_procedimientos: action.payload.filtros,
        is_loading_teams: false,
      };
    case TEAMS_PROCEDIMIENTOS_FALLIDO:
      return {
        ...state,
        procedimientos: {},
        procedimiento_id: 0,
        filtros_procedimientos: {},
        is_loading_teams: false,
      };
    case TEAMS_PROCEDIMIENTOS_FILTROS_OBTENER:
      return {
        ...state,
        procedimientos_filtros: action.payload.procedimientos,
        filtros_errores: {},
        is_loading_teams: false,
      };
    case TEAMS_PROCEDIMIENTOS_FILTROS_FALLIDO:
      return {
        ...state,
        procedimientos_filtros: {},
        filtros_errores: action.payload,
        is_loading_teams: false,
      };
    case TEAMS_PROCEDIMIENTOS_FILTROS_INPUT:
      return {
        ...state,
        procedimientos_filtro_input: action.payload,
      };
    case TEAMS_LIMPIAR_PROCEDIMIENTOS_FILTROS:
      return {
        ...state,
        is_loading_teams: true,
        procedimientos_filtros: {},
        procedimientos_filtro_input: {},
        procedimientos: {},
      };
    // equipo - usuario
    case TEAMS_EQUIPO_USUARIO_AGREGAR_OBTENER:
      return {
        ...state,
        equipo_usuario_agregar: action.payload,
        equipo_usuario_agregar_error: {},
        is_loading_teams: false,
      };
    case TEAMS_EQUIPO_USUARIO_AGREGAR_FALLIDO:
      return {
        ...state,
        equipo_usuario_agregar: {},
        equipo_usuario_agregar_error: action.payload,
        is_loading_teams: false,
      };
    case TEAMS_EQUIPO_USUARIO_ELIMINAR_OBTENER:
      return {
        ...state,
        equipo_usuario_eliminar: action.payload,
        equipo_usuario_eliminar_error: {},
        is_loading_teams: false,
      };
    case TEAMS_EQUIPO_USUARIO_ELIMINAR_FALLIDO:
      return {
        ...state,
        equipo_usuario_eliminar: {},
        equipo_usuario_eliminar_error: action.payload,
        is_loading_teams: false,
      };
    // equipo - buscar expediente
    case TEAMS_EQUIPO_BUSCAR_OBTENER:
      return {
        ...state,
        equipo_buscar: action.payload.favoritos,
        equipo_buscar_error: {},
        is_loading_teams: false,
      };
    case TEAMS_EQUIPO_BUSCAR_FALLIDO:
      return {
        ...state,
        equipo_buscar: [],
        equipo_buscar_error: action.payload,
        is_loading_teams: false,
      };
    // equipo - editar
    case TEAMS_EQUIPO_EDITAR_OBTENER:
      return {
        ...state,
        equipo_editar: action.payload,
        equipo_editar_error: {},
        is_loading_teams: false,
      };
    case TEAMS_EQUIPO_EDITAR_FALLIDO:
      return {
        ...state,
        equipo_editar: {},
        equipo_editar_error: action.payload,
        is_loading_teams: false,
      };
    // equipo - favorito expediente
    case TEAMS_EQUIPO_FAVORITO_CREAR_OBTENER:
      return {
        ...state,
        favorito_crear: action.payload,
        favorito_crear_error: {},
        is_loading_teams: false,
      };
    case TEAMS_EQUIPO_FAVORITO_CREAR_FALLIDO:
      return {
        ...state,
        favorito_crear: {},
        favorito_crear_error: action.payload,
        is_loading_teams: false,
      };
    case TEAMS_EQUIPO_FAVORITO_ELIMINAR_OBTENER:
      return {
        ...state,
        favorito_eliminar: action.payload,
        favorito_eliminar_error: {},
        is_loading_teams: false,
      };
    case TEAMS_EQUIPO_FAVORITO_ELIMINAR_FALLIDO:
      return {
        ...state,
        favorito_eliminar: {},
        favorito_eliminar_error: action.payload,
        is_loading_teams: false,
      };
    // equipo - eliminar
    case TEAMS_EQUIPO_ELIMINAR_OBTENER:
      return {
        ...state,
        equipo_eliminar: action.payload,
        equipo_eliminar_error: {},
        is_loading_teams: false,
      };
    case TEAMS_EQUIPO_ELIMINAR_FALLIDO:
      return {
        ...state,
        equipo_eliminar: {},
        equipo_eliminar_error: action.payload,
        is_loading_teams: false,
      };
    // equipo - crear
    case TEAMS_EQUIPO_CREAR_OBTENER:
      return {
        ...state,
        equipo_crear: action.payload,
        equipo_crear_error: {},
        is_loading_teams: false,
      };
    case TEAMS_EQUIPO_CREAR_FALLIDO:
      return {
        ...state,
        equipo_crear: {},
        equipo_crear_error: action.payload,
        is_loading_teams: false,
      };
    // loading
    case CARGANDO_LIMPIAR_VGC:
      return {
        ...state,
        is_loading_teams: true,
      };
    // equipos
    case TEAMS_EQUIPOS_OBTENER:
      return {
        ...state,
        equipos: action.payload.equipos,
        filtros_equipos: action.payload.filtros,
        usuarios: action.payload.usuarios,
        is_loading_teams: false,
      };
    case TEAMS_EQUIPOS_FALLIDO:
      return {
        ...state,
        equipos: {},
        equipos_filtros: {},
        is_loading_teams: false,
      };
    // equipo
    case TEAMS_EQUIPO_GUARDAR:
      return {
        ...state,
        equipo_id: action.payload.id,
      };
    case TEAMS_EQUIPO_OBTENER:
      return {
        ...state,
        equipo: action.payload,
        is_loading_teams: false,
      };
    case TEAMS_EQUIPO_FALLIDO:
      return {
        ...state,
        equipo: {},
        is_loading_teams: false,
      };
    // equipo - filtros
    case TEAMS_EQUIPOS_FILTROS_INPUT:
      return {
        ...state,
        equipos_filtro_input: action.payload,
      };
    case TEAMS_EQUIPOS_FILTROS_OBTENER:
      return {
        ...state,
        equipos: action.payload.equipos,
        filtros_errores: {},
        is_loading_teams: false,
      };
    case TEAMS_EQUIPOS_FILTROS_FALLIDO:
      return {
        ...state,
        equipos_filtros: {},
        filtros_errores: action.payload,
        is_loading_teams: false,
      };
    case LIMPIAR_TEAMS_MENSAJES:
      return {
        ...state,
        equipo_crear: {},
        equipo_crear_error: {},
        equipo_eliminar: {},
        equipo_eliminar_error: {},
        favorito_eliminar: {},
        favorito_eliminar_error: {},
        favorito_crear: {},
        favorito_crear_error: {},
        equipo_editar: {},
        equipo_editar_error: {},
        equipo_buscar: [],
        equipo_buscar_error: {},
        equipo_usuario_eliminar: {},
        equipo_usuario_eliminar_error: {},
        equipo_usuario_agregar: {},
        equipo_usuario_agregar_error: {},
      };
    default:
      return state;
  }
}
