import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { Footer, Navbar, SidebarInicio } from "../../general";

import {
  eliminarAlerta,
  limpiarMensajes,
  obtenerAlertas,
} from "../../../redux/actions/vigilancias/alertas/action";

export class Listado extends Component {
  componentDidMount = () => {
    const { obtenerAlertas } = this.props;
    obtenerAlertas(1);
  };

  obtenerValueFiltro = (filtro, id) => {
    const { alertas_filtros } = this.props;
    let value = "";
    alertas_filtros[filtro].map((item) => {
      if (item.id === id) {
        value = item.nombre;
      }
      return null;
    });
    return value;
  };

  eliminacionAlerta = (alerta) => {
    const { eliminarAlerta } = this.props;
    SweetAlert.fire({
      title: "Alerta",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de eliminar la alerta Módulo ${alerta.modulo_display} con fecha de creacion ${alerta.fecha_creacion}?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarAlerta({ alerta: alerta.id });
        this.setState({
          usuario_loading: SweetAlert.fire({
            title: "Alerta",
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: "Estamos eliminando la alerta, espere unos segundos",
            icon: "warning",
            showConfirmButton: false,
            didOpen: () => {
              SweetAlert.showLoading();
            },
          }),
        });
      }
    });
  };

  render = () => {
    const {
      obtenerAlertas,
      limpiarMensajes,
      is_loading_vigilancias,
      alertas,
      alerta_eliminar,
      alerta_eliminar_error,
    } = this.props;

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Alerta",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las alertas, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (Object.entries(alerta_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Alerta eliminada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: alerta_eliminar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerAlertas(1);
          limpiarMensajes();
        }
      });
    }

    if (
      Object.entries(alerta_eliminar_error).length > 0 &&
      alerta_eliminar_error.data &&
      alerta_eliminar_error.data.message
    ) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Alerta no eliminada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: alerta_eliminar_error.data.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
        }
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_alertas" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tower-broadcast"></i> ALERTAS
                  </h4>
                  <p className="float-end btn-group">
                    <Link
                      className="btn btn-primary"
                      to="/compliance-analytics/alertas/nueva"
                    >
                      Crear Alerta <i className="fa fa-plus-circle"></i>
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5>LISTADO DE ALERTAS</h5>
                  </div>
                  <div className="card-body">
                    <table className="table table-bordered table-hover">
                      <thead className="table-secondary">
                        <tr>
                          <th className="text-center w-3">ID</th>
                          <th className="w-15">Módulo</th>
                          <th className="w-15">Fecha Creación</th>
                          <th className="w-25">Región</th>
                          <th>Condiciones</th>
                          <th className="w-5 text-center">Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {alertas &&
                        alertas.result &&
                        alertas.result.length > 0 ? (
                          alertas.result.map((alerta, x) => (
                            <tr className="table-responsive" key={x}>
                              <td className="text-center">{x + 1}</td>
                              <td>{alerta.modulo_display}</td>
                              <td>{alerta.fecha_creacion}</td>
                              <td>
                                {alerta.modulo === "expedientes" &&
                                  alerta.expedientes_regiones && (
                                    <table className="table table-bordered table-hover table-sm">
                                      <thead className="table-dark">
                                        <tr>
                                          <th>Región</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {alerta.expedientes_regiones.map(
                                          (region, y) => (
                                            <tr key={y}>
                                              <td>
                                                {this.obtenerValueFiltro(
                                                  "expedientes_regiones",
                                                  region
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  )}
                                {alerta.modulo === "hechos" &&
                                  alerta.hechos_regiones && (
                                    <table className="table table-bordered table-hover table-sm">
                                      <thead className="table-dark">
                                        <tr>
                                          <th>Región</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {alerta.hechos_regiones.map(
                                          (region, y) => (
                                            <tr key={y}>
                                              <td>
                                                {this.obtenerValueFiltro(
                                                  "hechos_regiones",
                                                  region
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  )}
                                {alerta.modulo === "pertinencias" &&
                                  alerta.pertinencias_regiones && (
                                    <table className="table table-bordered table-hover table-sm">
                                      <thead className="table-dark">
                                        <tr>
                                          <th>Región</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {alerta.pertinencias_regiones.map(
                                          (region, y) => (
                                            <tr key={y}>
                                              <td>
                                                {this.obtenerValueFiltro(
                                                  "pertinencias_regiones",
                                                  parseInt(region)
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  )}
                              </td>
                              <td>
                                {alerta.modulo === "expedientes" &&
                                  (alerta.expedientes_tipos ||
                                    alerta.expedientes_tipologias ||
                                    alerta.expedientes_estados) && (
                                    <table className="table table-bordered table-hover">
                                      <tbody>
                                        {alerta.expedientes_tipos &&
                                          alerta.expedientes_tipos.length >
                                            0 && (
                                            <tr>
                                              <td className="table-dark w-50">
                                                Tipo de instrumento
                                              </td>
                                              <td>
                                                <ul className="mb-0">
                                                  {alerta.expedientes_tipos.map(
                                                    (tipo, y) => (
                                                      <li key={y}>
                                                        {this.obtenerValueFiltro(
                                                          "expedientes_tipos",
                                                          tipo
                                                        )}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </td>
                                            </tr>
                                          )}
                                        {alerta.expedientes_tipologias &&
                                          alerta.expedientes_tipologias.length >
                                            0 && (
                                            <tr>
                                              <td className="table-dark">
                                                Tipología del proyecto
                                              </td>
                                              <td>
                                                <ul className="mb-0">
                                                  {alerta.expedientes_tipologias.map(
                                                    (tipo, y) => (
                                                      <li key={y}>
                                                        {this.obtenerValueFiltro(
                                                          "expedientes_tipologias",
                                                          tipo
                                                        )}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </td>
                                            </tr>
                                          )}
                                        {alerta.expedientes_estados &&
                                          alerta.expedientes_estados.length >
                                            0 && (
                                            <tr>
                                              <td className="table-dark">
                                                Estado del proyecto
                                              </td>
                                              <td>
                                                <ul className="mb-0">
                                                  {alerta.expedientes_estados.map(
                                                    (tipo, y) => (
                                                      <li key={y}>
                                                        {this.obtenerValueFiltro(
                                                          "expedientes_estados",
                                                          tipo
                                                        )}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </td>
                                            </tr>
                                          )}
                                      </tbody>
                                    </table>
                                  )}
                                {alerta.modulo === "pertinencias" &&
                                  alerta.pertinencias_tipologias && (
                                    <table className="table table-bordered table-hover">
                                      <tbody>
                                        <tr>
                                          <td className="table-dark w-50">
                                            Tipología del proyecto
                                          </td>
                                          <td>
                                            <ul className="mb-0">
                                              {alerta.pertinencias_tipologias.map(
                                                (tipo, y) => (
                                                  <li key={y}>
                                                    {this.obtenerValueFiltro(
                                                      "pertinencias_tipologias",
                                                      tipo
                                                    )}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  )}
                                {alerta.modulo === "hechos" &&
                                  alerta.hechos_categorias && (
                                    <table className="table table-bordered table-hover">
                                      <tbody>
                                        <tr>
                                          <td className="table-dark w-50">
                                            Categoría
                                          </td>
                                          <td>
                                            <ul className="mb-0">
                                              {alerta.hechos_categorias.map(
                                                (tipo, y) => (
                                                  <li key={y}>
                                                    {this.obtenerValueFiltro(
                                                      "hechos_categorias",
                                                      tipo
                                                    )}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  )}
                              </td>
                              <td className="text-center">
                                <button
                                  onClick={this.eliminacionAlerta.bind(
                                    this,
                                    alerta
                                  )}
                                  data-tip
                                  data-tooltip-id="eliminar_alerta"
                                  className="btn btn-danger btn-xs"
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                                <Tooltip
                                  id="eliminar_alerta"
                                  aria-haspopup="true"
                                >
                                  Eliminar la alerta
                                </Tooltip>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No hay alertas creadas
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerAlertas: PropTypes.func.isRequired,
  eliminarAlerta: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  alertas: PropTypes.object.isRequired,
  alertas_filtros: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  alerta_eliminar: PropTypes.object.isRequired,
  alerta_eliminar_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  alertas: state.vigilancias.alertas,
  alertas_filtros: state.vigilancias.alertas_filtros,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  alerta_eliminar: state.vigilancias.alerta_eliminar,
  alerta_eliminar_error: state.vigilancias.alerta_eliminar_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerAlertas, eliminarAlerta, limpiarMensajes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
