import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VGC } from "../equipos/types";
import {
  TEAMS_LIMPIAR_MEDIDAS_FILTROS,
  TEAMS_MEDIDAS_PROVISIONALES_FALLIDO,
  TEAMS_MEDIDAS_PROVISIONALES_FILTROS_FALLIDO,
  TEAMS_MEDIDAS_PROVISIONALES_FILTROS_INPUT,
  TEAMS_MEDIDAS_PROVISIONALES_FILTROS_OBTENER,
  TEAMS_MEDIDAS_PROVISIONALES_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerMedidasProvisionales = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VGC });
  axios
    .get(
      `${url_api}/api/teams/medidas-provisionales/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: TEAMS_MEDIDAS_PROVISIONALES_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TEAMS_MEDIDAS_PROVISIONALES_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerMedidasProvisionalesFiltros =
  (data) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VGC });
    axios
      .post(
        `${url_api}/api/teams/medidas-provisionales/${data.pagina}`,
        data.filtros,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: TEAMS_MEDIDAS_PROVISIONALES_FILTROS_INPUT,
          payload: data.filtros,
        });
        dispatch({
          type: TEAMS_MEDIDAS_PROVISIONALES_FILTROS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : TEAMS_MEDIDAS_PROVISIONALES_FILTROS_FALLIDO,
          payload: error,
        });
      });
  };

export const limpiarFiltroMedidas = () => (dispatch) => {
  dispatch({ type: TEAMS_LIMPIAR_MEDIDAS_FILTROS });
};
