import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  obtenerCsvInteligencia,
  obtenerPermisosInteligencia,
  PermisoSeleccionado,
} from "../../../redux/actions/permitting/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { withRouter } from "../../general/with_router";
import { ListadoPermisos } from "../general";
import { Filtros as FiltrosInteligencia } from "./";

export class Permisos extends Component {
  componentDidMount = () => {
    const { obtenerPermisosInteligencia, inteligencia_seleccionada } =
      this.props;
    if (
      Object.entries(inteligencia_seleccionada).length > 0 &&
      inteligencia_seleccionada.id > 0
    ) {
      obtenerPermisosInteligencia({
        proyecto: inteligencia_seleccionada.id,
      });
    }
  };

  setPermiso = (id) => {
    const { PermisoSeleccionado } = this.props;
    PermisoSeleccionado({
      id: id ? id : 0,
      url: "/permitting-target/permisoteca/permisos",
    });
  };

  descargarCsv = () => {
    const { inteligencia_seleccionada, obtenerCsvInteligencia } = this.props;
    obtenerCsvInteligencia({
      proyecto: inteligencia_seleccionada.id,
    });
  };

  render = () => {
    const {
      is_descargar,
      permisos,
      is_loading_permitting,
      permisos_error,
      inteligencia_seleccionada,
    } = this.props;

    if (
      !Object.entries(inteligencia_seleccionada).length > 0 ||
      inteligencia_seleccionada.id === 0
    ) {
      return <Navigate to="/permitting-target/permisoteca/proyectos" />;
    }

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Plan maestro de permisos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los permisos, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (Object.entries(permisos_error).length > 0 && permisos_error.message) {
      SweetAlert.fire({
        title: "Plan maestro de permisos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: permisos_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        this.props.navigate("/user/inicio");
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="permitting_permisoteca" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">PLAN MAESTRO DE PERMISOS</h4>
                  <p className="float-end">
                    <Link
                      to="/permitting-target/permisoteca/proyectos"
                      className="btn btn-primary"
                    >
                      <i className="fa fa-angle-left"></i> Volver al listado de
                      proyectos
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <FiltrosInteligencia />
                <div className="card mb-4">
                  <div className="card-body">
                    <ListadoPermisos
                      permisos={permisos}
                      ulr_permiso="/permitting-target/permisoteca/permiso"
                      setPermiso={this.setPermiso}
                      descargarCsv={this.descargarCsv}
                      is_descargar={is_descargar}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Permisos.propTypes = {
  obtenerPermisosInteligencia: PropTypes.func.isRequired,
  PermisoSeleccionado: PropTypes.func.isRequired,
  obtenerCsvInteligencia: PropTypes.func.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  permisos: PropTypes.array.isRequired,
  permisos_error: PropTypes.object.isRequired,
  inteligencia_seleccionada: PropTypes.object.isRequired,
  is_descargar: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_permitting: state.permitting.is_loading_permitting,
  permisos: state.permitting.permisos,
  permisos_error: state.permitting.permisos_error,
  inteligencia_seleccionada: state.permitting.inteligencia_seleccionada,
  is_descargar: state.permitting.is_descargar,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerPermisosInteligencia,
      PermisoSeleccionado,
      obtenerCsvInteligencia,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Permisos)
);
