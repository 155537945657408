import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerFavoritos } from "../../../redux/actions/leyes/action";
import {
  agregarFavoritoExpediente,
  eliminarFavoritoExpediente,
  guardarExpediente,
  limpiarMensajes,
} from "../../../redux/actions/mma/action";
import { withRouter } from "../../general/with_router";

export class Tabla extends Component {
  setExpediente = (id, componente) => {
    const { guardarExpediente } = this.props;
    guardarExpediente({ id: id, componente: componente });
  };

  agregarFavorito = (id, nombre) => {
    const { agregarFavoritoExpediente } = this.props;
    SweetAlert.fire({
      title: "Agregar a Favorito",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de agregar el expediente ${nombre} a favoritos?`,
      icon: "info",
      confirmButtonText: 'Si, Agregar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        agregarFavoritoExpediente({
          expediente: id,
        });
        SweetAlert.close();
        SweetAlert.fire({
          title: "Agregando a Favoritos",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos agregando a favoritos, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  eliminarFavorito = (id, nombre) => {
    const { eliminarFavoritoExpediente } = this.props;
    SweetAlert.fire({
      title: "Agregar a Favorito",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de eliminar el expediente ${nombre} de favoritos?`,
      icon: "info",
      confirmButtonText: 'Si, Agregar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarFavoritoExpediente({
          expediente: id,
        });
        SweetAlert.close();
        SweetAlert.fire({
          title: "Eliminando de Favoritos",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos eliminando de favoritos, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  render = () => {
    const {
      expedientes,
      componente,
      expediente_favorito,
      expediente_favorito_error,
      expediente_favorito_eliminar,
      expediente_favorito_eliminar_error,
      limpiarMensajes,
      obtenerFavoritos,
      eliminar_expediente = false,
    } = this.props;

    if (Object.entries(expediente_favorito_eliminar_error).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        title: "Expediente no Eliminado",
        text: expediente_favorito_eliminar_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        limpiarMensajes();
      });
    }

    if (Object.entries(expediente_favorito_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Expediente Favorito",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: expediente_favorito_eliminar.message,
        icon: "success",
        confirmButtonText:
          'Ver listado de Favoritos <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          obtenerFavoritos();
        }
      });
    }
    if (Object.entries(expediente_favorito_error).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        title: "Expediente no Guardado",
        text: expediente_favorito_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        limpiarMensajes();
      });
    }

    if (Object.entries(expediente_favorito).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Expediente Favorito",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: expediente_favorito.message,
        icon: "success",
        confirmButtonText:
          'Ver listado de Favoritos <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.props.navigate("/legal-tracking/favoritos");
        }
      });
    }

    return (
      <div className="table-responsive">
        <Tooltip id="detalle_expediente" aria-haspopup="true">
          Ver detalle del expediente MMA
        </Tooltip>
        <table className="table table-bordered table-hover table-sm">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre</th>
              <th className="w-12">Tipo</th>
              <th className="w-10">Estado</th>
              <th className="w-5 text-center">Tipología</th>
              <th className="w-3 text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            {expedientes &&
            expedientes.result &&
            expedientes.result.length > 0 ? (
              expedientes.result.map((expediente, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {expedientes.page > 1
                      ? (expedientes.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td>
                    <a
                      href={expediente.link_expediente_mma}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-uppercase"
                    >
                      {expediente.nombre} <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td>
                    <span className="badge badge-warning font-13">
                      {expediente.tipo}
                    </span>
                  </td>
                  <td>
                    <span className="badge badge-dark font-13">
                      {expediente.estado}
                    </span>
                  </td>
                  <td className="text-center">
                    <span
                      className={`font-12  badge ${
                        expediente.tipologia === "Planes"
                          ? "badge-success"
                          : "badge-danger"
                      }`}
                    >
                      {expediente.tipologia}
                    </span>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {eliminar_expediente ? (
                        <>
                          <Tooltip
                            id={`expediente_favorito_${x}`}
                            aria-haspopup="true"
                          >
                            Eliminar de Favoritos
                          </Tooltip>
                          <button
                            data-tip
                            data-tooltip-id={`expediente_favorito_${x}`}
                            onClick={this.eliminarFavorito.bind(
                              this,
                              expediente.id,
                              expediente.nombre
                            )}
                            className="btn btn-danger"
                            type="button"
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </>
                      ) : (
                        <>
                          <Tooltip
                            id={`expediente_favorito_${x}`}
                            aria-haspopup="true"
                          >
                            Agregar a Favoritos
                          </Tooltip>
                          <button
                            data-tip
                            data-tooltip-id={`expediente_favorito_${x}`}
                            onClick={this.agregarFavorito.bind(
                              this,
                              expediente.id,
                              expediente.nombre
                            )}
                            className="btn btn-warning"
                            type="button"
                          >
                            <i className="fa fa-star"></i>
                          </button>
                        </>
                      )}
                      <Link
                        to="/legal-tracking/mma/expediente"
                        className="btn btn-soft-dark"
                        data-tip
                        data-tooltip-id="detalle_expediente"
                        onClick={this.setExpediente.bind(
                          this,
                          expediente.id,
                          componente
                        )}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="6">
                  No existen Expedientes MMA
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarExpediente: PropTypes.func.isRequired,
  agregarFavoritoExpediente: PropTypes.func.isRequired,
  eliminarFavoritoExpediente: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  obtenerFavoritos: PropTypes.func.isRequired,
  expediente_favorito: PropTypes.object.isRequired,
  expediente_favorito_error: PropTypes.object.isRequired,
  expediente_favorito_eliminar: PropTypes.object.isRequired,
  expediente_favorito_eliminar_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  expediente_favorito: state.mma.expediente_favorito,
  expediente_favorito_error: state.mma.expediente_favorito_error,
  expediente_favorito_eliminar: state.mma.expediente_favorito_eliminar,
  expediente_favorito_eliminar_error:
    state.mma.expediente_favorito_eliminar_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      guardarExpediente,
      agregarFavoritoExpediente,
      eliminarFavoritoExpediente,
      limpiarMensajes,
      obtenerFavoritos,
    },
    dispatch
  );
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tabla));
