import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CheckTreePicker, SelectPicker } from "rsuite";
import {
  limpiarFiltroExpedientesDocumentos,
  obtenerExpedientesDocumentosFiltros,
} from "../../../redux/actions/vigilancias/expedientes/action";

export class Expedientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre:
        this.props.expedientes_documentos_filtro_input &&
        this.props.expedientes_documentos_filtro_input.nombre
          ? this.props.expedientes_documentos_filtro_input.nombre
          : "",
      filtro_nombre_tipo:
        this.props.expedientes_documentos_filtro_input &&
        this.props.expedientes_documentos_filtro_input.nombre_tipo
          ? this.props.expedientes_documentos_filtro_input.nombre_tipo
          : "",
      filtro_tipologia:
        this.props.expedientes_documentos_filtro_input &&
        this.props.expedientes_documentos_filtro_input.tipologia
          ? this.props.expedientes_documentos_filtro_input.tipologia
          : [],
      filtro_estado:
        this.props.expedientes_documentos_filtro_input &&
        this.props.expedientes_documentos_filtro_input.estado
          ? this.props.expedientes_documentos_filtro_input.estado
          : [],
      filtro_region:
        this.props.expedientes_documentos_filtro_input &&
        this.props.expedientes_documentos_filtro_input.region
          ? this.props.expedientes_documentos_filtro_input.region
          : [],
      filtro_comuna:
        this.props.expedientes_documentos_filtro_input &&
        this.props.expedientes_documentos_filtro_input.comuna
          ? this.props.expedientes_documentos_filtro_input.comuna
          : "",
      filtro_tipo:
        this.props.expedientes_documentos_filtro_input &&
        this.props.expedientes_documentos_filtro_input.tipo
          ? this.props.expedientes_documentos_filtro_input.tipo
          : "",
      filtro_recursos:
        this.props.expedientes_documentos_filtro_input &&
        this.props.expedientes_documentos_filtro_input.recursos
          ? this.props.expedientes_documentos_filtro_input.recursos
          : "",
      filtro_pas:
        this.props.expedientes_documentos_filtro_input &&
        this.props.expedientes_documentos_filtro_input.pas
          ? this.props.expedientes_documentos_filtro_input.pas
          : [],
      filtro_folio_remitido:
        this.props.expedientes_documentos_filtro_input &&
        this.props.expedientes_documentos_filtro_input.remitido
          ? this.props.expedientes_documentos_filtro_input.remitido
          : "",
      filtro_nombre_especiales:
        this.props.expedientes_documentos_filtro_input &&
        this.props.expedientes_documentos_filtro_input.nombre_especiales
          ? this.props.expedientes_documentos_filtro_input.nombre_especiales
          : "",
    };
  }

  filtroExpedientes = () => {
    const { obtenerExpedientesDocumentosFiltros } = this.props;
    const {
      filtro_tipologia,
      filtro_estado,
      filtro_region,
      filtro_tipo,
      filtro_comuna,
      filtro_recursos,
      filtro_pas,
      filtro_folio_remitido,
      filtro_nombre,
      filtro_nombre_tipo,
      filtro_nombre_especiales,
    } = this.state;

    if (
      filtro_tipologia ||
      filtro_estado ||
      filtro_region ||
      filtro_tipo ||
      filtro_comuna ||
      filtro_recursos ||
      filtro_pas ||
      filtro_folio_remitido ||
      filtro_nombre ||
      filtro_nombre_tipo ||
      filtro_nombre_especiales
    ) {
      obtenerExpedientesDocumentosFiltros({
        filtros: {
          tipologia: filtro_tipologia ? filtro_tipologia : null,
          estado: filtro_estado ? filtro_estado : null,
          region: filtro_region ? filtro_region : null,
          comuna: filtro_comuna ? filtro_comuna : null,
          tipo: filtro_tipo ? filtro_tipo : null,
          recursos: filtro_recursos ? filtro_recursos : null,
          pas: filtro_pas ? filtro_pas : null,
          remitido: filtro_folio_remitido ? filtro_folio_remitido : null,
          nombre: filtro_nombre ? filtro_nombre : null,
          nombre_tipo: filtro_nombre_tipo ? filtro_nombre_tipo : null,
          nombre_especiales: filtro_nombre_especiales
            ? filtro_nombre_especiales
            : null,
        },
        pagina: 1,
      });
    } else {
    }
  };

  borrarFiltros = () => {
    const { limpiarFiltroExpedientesDocumentos } = this.props;
    limpiarFiltroExpedientesDocumentos();
    this.setState({
      filtro_tipologia: [],
      filtro_estado: [],
      filtro_region: [],
      filtro_comuna: "",
      filtro_tipo: "",
      filtro_recursos: "",
      filtro_pas: [],
      filtro_folio_remitido: "",
      filtro_nombre: "",
      filtro_nombre_tipo: "",
      filtro_nombre_especiales: "",
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render = () => {
    const { filtros_expedientes_documentos, filtros_errores } = this.props;
    const {
      filtro_tipologia,
      filtro_estado,
      filtro_region,
      filtro_tipo,
      filtro_comuna,
      filtro_recursos,
      filtro_pas,
      filtro_folio_remitido,
      filtro_nombre,
      filtro_nombre_tipo,
      filtro_nombre_especiales,
    } = this.state;

    const tipologias_listado =
      filtros_expedientes_documentos &&
      filtros_expedientes_documentos["tipologias"]
        ? _.orderBy(filtros_expedientes_documentos["tipologias"], "id")
        : [];
    const estados_listado =
      filtros_expedientes_documentos &&
      filtros_expedientes_documentos["estados"]
        ? _.orderBy(filtros_expedientes_documentos["estados"], "nombre")
        : [];
    const regiones_listado =
      filtros_expedientes_documentos &&
      filtros_expedientes_documentos["regiones"]
        ? _.orderBy(filtros_expedientes_documentos["regiones"], "nombre")
        : [];
    const comunas_listado =
      filtros_expedientes_documentos &&
      filtros_expedientes_documentos["comunas"]
        ? _.orderBy(filtros_expedientes_documentos["comunas"]).map(
            (comuna) => ({
              label: comuna,
              value: comuna,
            })
          )
        : [];
    const tipos_listado =
      filtros_expedientes_documentos && filtros_expedientes_documentos["tipos"]
        ? _.orderBy(filtros_expedientes_documentos["tipos"], "value")
        : [];
    const pas_listado =
      filtros_expedientes_documentos && filtros_expedientes_documentos["pas"]
        ? filtros_expedientes_documentos["pas"]
        : [];
    const remitidos_listado =
      filtros_expedientes_documentos &&
      filtros_expedientes_documentos["remitidos"]
        ? filtros_expedientes_documentos["remitidos"]
        : [];
    const folios_listado =
      filtros_expedientes_documentos && filtros_expedientes_documentos["folios"]
        ? filtros_expedientes_documentos["folios"]
        : [];
    const especiales_listado =
      filtros_expedientes_documentos &&
      filtros_expedientes_documentos["especiales"]
        ? filtros_expedientes_documentos["especiales"]
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5>FILTROS</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>
                  <span>Nombre del Documento</span>
                </label>
                <input
                  type="text"
                  name="filtro_nombre"
                  value={filtro_nombre}
                  onChange={this.onChange}
                  placeholder="Ingrese un nombre"
                  className={`form-control ${
                    filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.remitido &&
                  filtros_errores.data.remitido.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>
                  <span>
                    <span>Tipos de Documentos</span>
                    <span className="ms-1 font-10">
                      ({folios_listado.length})
                    </span>
                  </span>
                </label>
                <SelectPicker
                  data={folios_listado}
                  onChange={(value) => {
                    this.setState({ filtro_nombre_tipo: value });
                  }}
                  value={filtro_nombre_tipo}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.remitido &&
                  filtros_errores.data.remitido.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>
                  <span>
                    <span>Casos Especiales</span>
                    <span className="ms-1 font-10">
                      ({especiales_listado.length})
                    </span>
                  </span>
                </label>
                <SelectPicker
                  data={especiales_listado}
                  onChange={(value) => {
                    this.setState({ filtro_nombre_especiales: value });
                  }}
                  value={filtro_nombre_especiales}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.remitido &&
                  filtros_errores.data.remitido.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>
                  <span>
                    <span>Organismo Emisor</span>
                    <span className="ms-1 font-10">
                      ({remitidos_listado.length})
                    </span>
                  </span>
                </label>
                <SelectPicker
                  data={remitidos_listado}
                  onChange={(value) => {
                    this.setState({ filtro_folio_remitido: value });
                  }}
                  value={filtro_folio_remitido}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.remitido &&
                  filtros_errores.data.remitido.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Tipología del Proyecto</label>
                <CheckTreePicker
                  name="filtro_tipologia"
                  data={tipologias_listado}
                  onChange={(value) => {
                    this.setState({ filtro_tipologia: value });
                  }}
                  value={filtro_tipologia}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipologia &&
                  filtros_errores.data.tipologia.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Estado del Proyecto</label>
                <CheckTreePicker
                  name="filtro_estado"
                  data={estados_listado}
                  onChange={(value) => {
                    this.setState({ filtro_estado: value });
                  }}
                  value={filtro_estado}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.estado &&
                  filtros_errores.data.estado.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Región del Proyecto</label>
                <CheckTreePicker
                  name="filtro_region"
                  data={regiones_listado}
                  onChange={(value) => {
                    this.setState({ filtro_region: value });
                  }}
                  value={filtro_region}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.region &&
                  filtros_errores.data.region.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Comuna del Proyecto</label>
                <SelectPicker
                  name="filtro_comuna"
                  data={comunas_listado}
                  onChange={(value) => {
                    this.setState({ filtro_comuna: value });
                  }}
                  value={filtro_comuna}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.comuna &&
                  filtros_errores.data.comuna.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Tipo de Instrumento</label>
                <div className="input-group">
                  <select
                    name="filtro_tipo"
                    onChange={this.onChange}
                    value={filtro_tipo}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.estado
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    {tipos_listado.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.tipo &&
                    filtros_errores.data.tipo.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>¿Tiene Recursos Administrativos?</label>
                <div className="input-group">
                  <select
                    name="filtro_recursos"
                    onChange={this.onChange}
                    value={filtro_recursos}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.recursos
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.recursos &&
                    filtros_errores.data.recursos.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>PAS</label>
                <CheckTreePicker
                  name="filtro_pas"
                  data={pas_listado}
                  onChange={(value) => {
                    this.setState({ filtro_pas: value });
                  }}
                  value={filtro_pas}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />

                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.pas &&
                  filtros_errores.data.pas.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroExpedientes}
            >
              <i className="fa fa-search"></i> Buscar Documentos
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Expedientes.propTypes = {
  obtenerExpedientesDocumentosFiltros: PropTypes.func.isRequired,
  limpiarFiltroExpedientesDocumentos: PropTypes.func.isRequired,
  filtros_expedientes_documentos: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  expedientes_documentos_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_expedientes_documentos:
    state.vigilancias.filtros_expedientes_documentos,
  filtros_errores: state.vigilancias.filtros_errores,
  expedientes_documentos_filtro_input:
    state.vigilancias.expedientes_documentos_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerExpedientesDocumentosFiltros, limpiarFiltroExpedientesDocumentos },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Expedientes);
