export const TEAMS_REQUERIMIENTOS_INGRESOS_OBTENER =
  "TEAMS_REQUERIMIENTOS_INGRESOS_OBTENER";
export const TEAMS_REQUERIMIENTOS_INGRESOS_FALLIDO =
  "TEAMS_REQUERIMIENTOS_INGRESOS_FALLIDO";
export const TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_FALLIDO =
  "TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_FALLIDO";
export const TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_OBTENER =
  "TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_OBTENER";
export const TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_INPUT =
  "REQUERIMIENTOS_INGRESOS_FILTROS_INPUT";
export const TEAMS_LIMPIAR_REQUERIMIENTOS_INGRESO_FILTROS =
  "TEAMS_LIMPIAR_REQUERIMIENTOS_INGRESO_FILTROS";
