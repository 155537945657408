import React, { Component } from "react";

import InputMask from "@mona-health/react-input-mask";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CheckTreePicker, SelectPicker } from "rsuite";
import { format as formatRut } from "rut.js";

import { Tooltip } from "react-tooltip";
import {
  limpiarFiltroQuinencoExpedientes,
  obtenerQuinencoExpedientes,
  obtenerQuinencoExpedientesFiltros,
} from "../../../../redux/actions/vigilancias/quinenco/action";

export class Expedientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.nombre
          ? this.props.expedientes_filtro_input.nombre
          : "",
      filtro_fecha_inicio:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.fecha_inicio
          ? this.props.expedientes_filtro_input.fecha_inicio
          : "",
      filtro_fecha_termino:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.fecha_termino
          ? this.props.expedientes_filtro_input.fecha_termino
          : "",
      filtro_tipologia:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.tipologia
          ? this.props.expedientes_filtro_input.tipologia
          : [],
      filtro_estado:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.estado
          ? this.props.expedientes_filtro_input.estado
          : "",
      filtro_region:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.region
          ? this.props.expedientes_filtro_input.region
          : "",
      filtro_comuna:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.comuna
          ? this.props.expedientes_filtro_input.comuna
          : "",
      filtro_tipo:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.tipo
          ? this.props.expedientes_filtro_input.tipo
          : "",
      filtro_titular_rut:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.titular_rut
          ? this.props.expedientes_filtro_input.titular_rut
          : "",
      filtro_titular_nombre:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.titular_nombre
          ? this.props.expedientes_filtro_input.titular_nombre
          : "",
      filtro_recursos:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.recursos
          ? this.props.expedientes_filtro_input.recursos
          : "",
      filtro_pas:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.pas
          ? this.props.expedientes_filtro_input.pas
          : [],
      filtro_sancionado:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.sancionado
          ? this.props.expedientes_filtro_input.sancionado
          : "",
      filtro_fiscalizacion:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.fiscalizacion
          ? this.props.expedientes_filtro_input.fiscalizacion
          : "",
      filtro_tag:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.tag
          ? this.props.expedientes_filtro_input.tag
          : "",
      filtro_sociedad:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.sociedad
          ? this.props.expedientes_filtro_input.sociedad
          : "",
      filtros_avanzandos:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.filtros_avanzandos
          ? this.props.expedientes_filtro_input.filtros_avanzandos
          : false,
    };
  }

  filtroExpedientes = () => {
    const { obtenerQuinencoExpedientesFiltros, obtenerQuinencoExpedientes } =
      this.props;
    const {
      filtro_fecha_inicio,
      filtro_fecha_termino,
      filtro_tipologia,
      filtro_estado,
      filtro_region,
      filtro_tipo,
      filtro_titular_nombre,
      filtro_titular_rut,
      filtro_nombre,
      filtro_comuna,
      filtro_recursos,
      filtro_pas,
      filtro_sancionado,
      filtro_fiscalizacion,
      filtro_tag,
      filtro_sociedad,
      filtros_avanzandos,
    } = this.state;

    if (
      filtro_fecha_inicio ||
      filtro_fecha_termino ||
      filtro_tipologia ||
      filtro_estado ||
      filtro_region ||
      filtro_tipo ||
      filtro_titular_nombre ||
      filtro_titular_rut ||
      filtro_nombre ||
      filtro_comuna ||
      filtro_recursos ||
      filtro_sancionado ||
      filtro_fiscalizacion ||
      filtro_tag ||
      filtro_sociedad ||
      filtro_pas
    ) {
      var sancionado = null;
      if (filtro_sancionado === "") {
        sancionado = null;
      } else if (filtro_sancionado === "Si") {
        sancionado = true;
      } else if (filtro_sancionado === "No") {
        sancionado = false;
      }

      var fiscalizacion = null;
      if (filtro_fiscalizacion === "") {
        fiscalizacion = null;
      } else if (filtro_fiscalizacion === "Si") {
        fiscalizacion = true;
      } else if (filtro_fiscalizacion === "No") {
        fiscalizacion = false;
      }

      const filtros = {
        nombre: filtro_nombre ? filtro_nombre : null,
        fecha_inicio: filtro_fecha_inicio ? filtro_fecha_inicio : null,
        fecha_termino: filtro_fecha_termino ? filtro_fecha_termino : null,
        tipologia: filtro_tipologia ? filtro_tipologia : null,
        estado: filtro_estado ? filtro_estado : null,
        region: filtro_region ? filtro_region : null,
        comuna: filtro_comuna ? filtro_comuna : null,
        tipo: filtro_tipo ? filtro_tipo : null,
        titular_rut: filtro_titular_rut ? filtro_titular_rut : null,
        titular_nombre: filtro_titular_nombre ? filtro_titular_nombre : null,
        recursos: filtro_recursos ? filtro_recursos : null,
        pas: filtro_pas ? filtro_pas : null,
        sancionado: sancionado,
        fiscalizacion: fiscalizacion,
        tag: filtro_tag ? filtro_tag : null,
        sociedad: filtro_sociedad ? filtro_sociedad : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerQuinencoExpedientesFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerQuinencoExpedientes(1);
    }
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  borrarFiltros = () => {
    const { obtenerQuinencoExpedientes, limpiarFiltroQuinencoExpedientes } =
      this.props;
    obtenerQuinencoExpedientes(1);
    limpiarFiltroQuinencoExpedientes();
    this.setState({
      filtro_fecha_inicio: "",
      filtro_fecha_termino: "",
      filtro_tipologia: [],
      filtro_estado: "",
      filtro_region: "",
      filtro_comuna: "",
      filtro_tipo: "",
      filtro_titular_nombre: "",
      filtro_titular_rut: "",
      filtro_nombre: "",
      filtro_recursos: "",
      filtro_pas: [],
      filtro_sancionado: "",
      filtro_fiscalizacion: "",
      filtro_tag: "",
      filtro_sociedad: "",
    });
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (
      (e.target.name === "filtro_pas" ||
        e.target.name === "filtro_tipologia") &&
      valor !== ""
    ) {
      valor = Array.from(e.target.selectedOptions, (item) => item.value);
    } else if (e.target.name === "filtro_titular_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  render = () => {
    const { quinenco_expedientes_filtros, filtros_errores } = this.props;
    const {
      filtro_fecha_inicio,
      filtro_fecha_termino,
      filtro_tipologia,
      filtro_estado,
      filtro_region,
      filtro_tipo,
      filtro_titular_rut,
      filtro_titular_nombre,
      filtro_nombre,
      filtro_comuna,
      filtro_recursos,
      filtro_pas,
      filtro_sancionado,
      filtro_fiscalizacion,
      filtro_tag,
      filtro_sociedad,
      filtros_avanzandos,
    } = this.state;

    const tipologias_listado =
      quinenco_expedientes_filtros && quinenco_expedientes_filtros["tipologias"]
        ? _.orderBy(quinenco_expedientes_filtros["tipologias"], "id")
        : [];
    const estados_listado =
      quinenco_expedientes_filtros && quinenco_expedientes_filtros["estados"]
        ? _.orderBy(quinenco_expedientes_filtros["estados"], "nombre")
        : [];
    const regiones_listado =
      quinenco_expedientes_filtros && quinenco_expedientes_filtros["regiones"]
        ? _.orderBy(quinenco_expedientes_filtros["regiones"], "nombre")
        : [];
    const comunas_listado =
      quinenco_expedientes_filtros && quinenco_expedientes_filtros["comunas"]
        ? _.orderBy(quinenco_expedientes_filtros["comunas"])
        : [];
    const tipos_listado =
      quinenco_expedientes_filtros && quinenco_expedientes_filtros["tipos"]
        ? _.orderBy(quinenco_expedientes_filtros["tipos"], "value")
        : [];
    const pas_listado =
      quinenco_expedientes_filtros && quinenco_expedientes_filtros["pas"]
        ? quinenco_expedientes_filtros["pas"]
        : [];
    const sociedades_listado =
      quinenco_expedientes_filtros && quinenco_expedientes_filtros["sociedades"]
        ? _.orderBy(quinenco_expedientes_filtros["sociedades"], "nombre")
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Nombre del proyecto</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_nombre"
                    value={filtro_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese un nombre"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre &&
                    filtros_errores.data.nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Tag</label>
                <div className="input-group">
                  <select
                    name="filtro_sociedad"
                    onChange={this.onChange}
                    value={filtro_sociedad}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.sociedad
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    {sociedades_listado.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.sociedad &&
                    filtros_errores.data.sociedad.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de inicio</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha_inicio"
                    value={filtro_fecha_inicio}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha de inicio"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_inicio
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_inicio &&
                    filtros_errores.data.fecha_inicio.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de término</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha_termino"
                    value={filtro_fecha_termino}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha de término"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_termino
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_termino &&
                    filtros_errores.data.fecha_termino.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Estado</label>
                <SelectPicker
                  name="filtro_estado"
                  data={estados_listado}
                  onChange={(value) => {
                    this.setState({ filtro_estado: value });
                  }}
                  value={filtro_estado}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.estado &&
                  filtros_errores.data.estado.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Región</label>
                <SelectPicker
                  name="filtro_region"
                  data={regiones_listado}
                  onChange={(value) => {
                    this.setState({ filtro_region: value });
                  }}
                  value={filtro_region}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.region &&
                  filtros_errores.data.region.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Comuna</label>
                <div className="input-group">
                  <select
                    name="filtro_comuna"
                    onChange={this.onChange}
                    value={filtro_comuna}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.comuna
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    {comunas_listado.map((option, x) => (
                      <option key={x} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.comuna &&
                    filtros_errores.data.comuna.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipo de instrumento</label>
                <div className="input-group">
                  <select
                    name="filtro_tipo"
                    onChange={this.onChange}
                    value={filtro_tipo}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.estado
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    {tipos_listado.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.tipo &&
                    filtros_errores.data.tipo.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Nombre del titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_nombre"
                    value={filtro_titular_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese nombre del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_nombre &&
                    filtros_errores.data.titular_nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>RUT del titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_rut"
                    value={filtro_titular_rut}
                    onChange={this.onChange}
                    placeholder="Ingrese rut del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_rut
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_rut &&
                    filtros_errores.data.titular_rut.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>¿Tiene recursos administrativos?</label>
                <div className="input-group">
                  <select
                    name="filtro_recursos"
                    onChange={this.onChange}
                    value={filtro_recursos}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.recursos
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.recursos &&
                    filtros_errores.data.recursos.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>¿Sancionado?</label>
                <div className="input-group">
                  <select
                    name="filtro_sancionado"
                    onChange={this.onChange}
                    value={filtro_sancionado}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.sancionado
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.sancionado &&
                    filtros_errores.data.sancionado.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>¿Fiscalización?</label>
                <div className="input-group">
                  <select
                    name="filtro_fiscalizacion"
                    onChange={this.onChange}
                    value={filtro_fiscalizacion}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fiscalizacion
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fiscalizacion &&
                    filtros_errores.data.fiscalizacion.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tag CL</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_tag"
                    value={filtro_tag}
                    onChange={this.onChange}
                    placeholder="Ingrese tag"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.tag
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.tag &&
                    filtros_errores.data.tag.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipología del proyecto</label>
                <CheckTreePicker
                  name="filtro_tipologia"
                  data={tipologias_listado}
                  onChange={(value) => {
                    this.setState({ filtro_tipologia: value });
                  }}
                  value={filtro_tipologia}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipologia &&
                  filtros_errores.data.tipologia.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-6 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>
                  PAS
                  <i
                    className="fa fa-exclamation-circle ms-1 text-warning font-11"
                    data-tip
                    data-tooltip-id="tooltip_pas"
                  ></i>
                  <Tooltip id="tooltip_pas" aria-haspopup="true">
                    <ul className="mb-0">
                      <li>
                        Para Windows: Mantenga presionado el botón de Control
                        (CTRL) para seleccionar múltiples opciones
                      </li>
                      <li>
                        Para Mac: mantenga presionado el botón de comando
                        (Command) para seleccionar múltiples opciones
                      </li>
                    </ul>
                  </Tooltip>
                </label>
                <div className="input-group">
                  <select
                    multiple={true}
                    size="8"
                    name="filtro_pas"
                    onChange={this.onChange}
                    value={filtro_pas}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.pas
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    {pas_listado.map((option, x) => (
                      <option key={x} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.pas &&
                    filtros_errores.data.pas.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroExpedientes}
            >
              <i className="fa fa-search"></i> Filtrar expedientes SEIA
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Expedientes.propTypes = {
  obtenerQuinencoExpedientes: PropTypes.func.isRequired,
  obtenerQuinencoExpedientesFiltros: PropTypes.func.isRequired,
  limpiarFiltroQuinencoExpedientes: PropTypes.func.isRequired,
  quinenco_expedientes_filtros: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  expedientes_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  quinenco_expedientes_filtros: state.vigilancias.quinenco_expedientes_filtros,
  filtros_errores: state.vigilancias.filtros_errores,
  expedientes_filtro_input: state.vigilancias.expedientes_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerQuinencoExpedientes,
      obtenerQuinencoExpedientesFiltros,
      limpiarFiltroQuinencoExpedientes,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Expedientes);
