import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import logo from "../../images/logo.png";
import {
  limpiarMensajes,
  nuevaContrasenaToken,
  validarToken,
} from "../../redux/actions/auth/action";
import { withRouter } from "../general/with_router";

class NuevaContrasena extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: "",
      password2: "",
      uid: "",
      token: "",
      loading_guardar: "",
    };
  }

  componentDidMount = () => {
    const { validarToken } = this.props;
    const { uid, token } = this.props.match.params;
    validarToken({
      uid: uid,
      token: token,
    });
    this.setState({
      uid: uid,
      token: token,
    });
  };

  onClickNuevaContrasena = (e) => {
    const { nuevaContrasenaToken } = this.props;
    const { password1, password2, uid, token } = this.state;
    var loading = SweetAlert.fire({
      title: "Nueva Contraseña",
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: "Validando la información ingresada, espere unos segundos",
      icon: "info",
      showConfirmButton: false,
      didOpen: () => {
        SweetAlert.showLoading();
      },
    });
    this.setState({
      loading_guardar: loading,
    });
    nuevaContrasenaToken({
      password1: password1,
      password2: password2,
      uid: uid,
      token: token,
    });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { loading_guardar } = this.state;
    const {
      is_authenticated,
      is_token_valido,
      limpiarMensajes,
      guardar_pass,
      errores_pass,
    } = this.props;
    if (is_authenticated) {
      return <Navigate to="/user/inicio" />;
    }
    if (Object.entries(guardar_pass).length > 0) {
      limpiarMensajes();
      loading_guardar.close();
      SweetAlert.fire({
        title: "Nueva contraseña",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: guardar_pass.message,
        icon: "success",
        confirmButtonText: 'Iniciar Sesión <i class="fas fa-sign-in-alt"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then(() => {
        this.props.navigate("/inicio-sesion");
      });
    }
    if (Object.entries(errores_pass).length > 0) {
      loading_guardar.close();
      if (errores_pass.data && errores_pass.data.message) {
        limpiarMensajes();
        SweetAlert.fire({
          title: "Nueva contraseña",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: errores_pass.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
    }

    return (
      <Fragment>
        <div className="account-body accountbg">
          <div className="row vh-100">
            <div className="col-lg-12 pe-0 align-self-center">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-6 col-8 mx-auto">
                  <div className="card auth-card">
                    <div className="card-body">
                      <div className="px-3">
                        <div className="text-center auth-logo-text">
                          <p>
                            <img
                              src={logo}
                              alt="logo"
                              className="auth-logo img-fluid"
                            />
                          </p>
                          <h5>CREA UNA NUEVA CONTRASEÑA</h5>
                        </div>
                        {is_token_valido ? (
                          <form className="form-horizontal auth-form my-4">
                            <div className="form-group">
                              <label>Contraseña</label>
                              <div className="input-group">
                                <input
                                  type="password"
                                  name="password1"
                                  placeholder="Ingresa su nueva contraseña"
                                  onChange={this.onChange}
                                  className={
                                    errores_pass &&
                                    errores_pass.data &&
                                    errores_pass.data.password1
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {errores_pass &&
                                  errores_pass.data &&
                                  errores_pass.data.password1 &&
                                  errores_pass.data.password1.map((err, x) => (
                                    <div key={x} className="invalid-feedback">
                                      {err}
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Repetir la contraseña</label>
                              <div className="input-group">
                                <input
                                  type="password"
                                  name="password2"
                                  placeholder="Repita su nueva contraseña"
                                  onChange={this.onChange}
                                  className={`form-control ${
                                    errores_pass &&
                                    errores_pass.data &&
                                    errores_pass.data.password2
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                {errores_pass &&
                                  errores_pass.data &&
                                  errores_pass.data.password2 &&
                                  errores_pass.data.password2.map((err, x) => (
                                    <div key={x} className="invalid-feedback">
                                      {err}
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="form-group mb-0 row">
                              <div className="col-12 mt-2 d-grid gap-2">
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  onClick={this.onClickNuevaContrasena}
                                >
                                  Guarda nueva contraseña{" "}
                                  <i className="fas fa-paper-plane ms-1"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        ) : (
                          <div className="alert alert-warning text-white">
                            <h4>URL NO VÁLIDA</h4>
                            <p>
                              La URL que acaba de ingresar para cambiar la
                              contraseña expiró o no es la válida pero puedes
                              solicitar nuevamente restablecer la contraseña en
                              el siguiente link:
                            </p>
                            <Link
                              to="/restablecer-contrasena"
                              className="btn btn-light"
                            >
                              Ingresa aquí
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
}

NuevaContrasena.propTypes = {
  nuevaContrasenaToken: PropTypes.func.isRequired,
  validarToken: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool,
  errores_pass: PropTypes.object,
  guardar_pass: PropTypes.object,
  is_token_valido: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  is_authenticated: state.auth.is_authenticated,
  guardar_pass: state.auth.guardar_pass,
  errores_pass: state.auth.errores_pass,
  is_token_valido: state.auth.is_token_valido,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { nuevaContrasenaToken, validarToken, limpiarMensajes },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NuevaContrasena)
);
