import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  limpiarMensajes,
  nuevoEquipo,
} from "../../../redux/actions/tareas/action";

export class Nuevo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario_nombre: "",
      usuario_email: "",
      usuario_rol: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  cancelarCreacionUsuario = () => {
    this.props.cancelarCreacionUsuario();
  };

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  crearNuevoEquipo = () => {
    const { nuevoEquipo, tarea_seleccionada } = this.props;
    const { usuario_nombre, usuario_email, usuario_rol } = this.state;
    nuevoEquipo({
      nombre: usuario_nombre,
      email: usuario_email,
      rol: usuario_rol,
      tarea: tarea_seleccionada,
    });
    SweetAlert.fire({
      title: "Nuevo Usuario",
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: "Estamos guardando el usuario ingresado, espere unos segundos",
      icon: "warning",
      showConfirmButton: false,
      didOpen: () => {
        SweetAlert.showLoading();
      },
    });
  };

  render = () => {
    const { limpiarMensajes, equipo_nuevo_error, equipo_nuevo } = this.props;
    const { usuario_nombre, usuario_email, usuario_rol } = this.state;

    if (Object.entries(equipo_nuevo_error).length > 0) {
      SweetAlert.close();
      if (equipo_nuevo_error.data && equipo_nuevo_error.data.message) {
        SweetAlert.fire({
          title: "Usuario no creado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: equipo_nuevo_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(equipo_nuevo).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Usuario creado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: equipo_nuevo.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.cancelarCreacionUsuario();
          this.tareaSeleccionada();
        }
      });
    }

    return (
      <div className="card card-eco">
        <div className="card-header bg-primary text-white">
          <h5>NUEVO MIEMBRO DEL EQUIPO</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 text-end">
              <p className="text-danger">
                <i className="fa fa-asterisk"></i> datos obligatorios
              </p>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>
                  Nombre <small className="text-danger">*</small>
                </label>
                <input
                  type="text"
                  name="usuario_nombre"
                  onChange={this.onChange}
                  defaultValue={usuario_nombre}
                  className={
                    equipo_nuevo_error &&
                    equipo_nuevo_error.data &&
                    equipo_nuevo_error.data.nombre
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ingrese un nombre"
                />
                {equipo_nuevo_error &&
                  equipo_nuevo_error.data &&
                  equipo_nuevo_error.data.nombre &&
                  equipo_nuevo_error.data.nombre.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>
                  Email <small className="text-danger">*</small>
                </label>
                <input
                  type="email"
                  name="usuario_email"
                  onChange={this.onChange}
                  defaultValue={usuario_email}
                  className={
                    equipo_nuevo_error &&
                    equipo_nuevo_error.data &&
                    equipo_nuevo_error.data.email
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ingrese un email"
                />
                {equipo_nuevo_error &&
                  equipo_nuevo_error.data &&
                  equipo_nuevo_error.data.email &&
                  equipo_nuevo_error.data.email.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>
                  Rol <small className="text-danger">*</small>
                </label>
                <select
                  name="usuario_rol"
                  onChange={this.onChange}
                  defaultValue={usuario_rol}
                  className={
                    equipo_nuevo_error &&
                    equipo_nuevo_error.data &&
                    equipo_nuevo_error.data.rol
                      ? "form-select is-invalid"
                      : "form-select"
                  }
                >
                  <option value="">Seleccione una opción</option>
                  <option value="colaborador">Colaborador</option>
                  <option value="visualizador">Visualizador</option>
                </select>
                {equipo_nuevo_error &&
                  equipo_nuevo_error.data &&
                  equipo_nuevo_error.data.tipo &&
                  equipo_nuevo_error.data.tipo.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-sm-12 text-end">
              <div className="btn-group">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.crearNuevoEquipo}
                >
                  Crear nuevo miembro <i className="fa fa-plus-circle"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.cancelarCreacionUsuario}
                >
                  Cancelar <i className="fa fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

Nuevo.propTypes = {
  nuevoEquipo: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  equipo_nuevo: PropTypes.object.isRequired,
  equipo_nuevo_error: PropTypes.object.isRequired,
  tarea_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  equipo_nuevo: state.tareas.equipo_nuevo,
  equipo_nuevo_error: state.tareas.equipo_nuevo_error,
  tarea_seleccionada: state.tareas.tarea_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ nuevoEquipo, limpiarMensajes }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Nuevo);
