import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  FAVORITA_ELIMINAR_FALLIDO,
  FAVORITA_ELIMINAR_OBTENER,
  FAVORITOS_VIGILANCIA_FALLIDO,
  FAVORITOS_VIGILANCIA_OBTENER,
  FAVORITO_GUARDAR_FALLIDO,
  FAVORITO_GUARDAR_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerFavoritos = () => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(`${url_api}/api/vigilances/favoritos`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: FAVORITOS_VIGILANCIA_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : FAVORITOS_VIGILANCIA_FALLIDO,
        payload: error,
      });
    });
};

export const guardarFavorito = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/favorito/crear`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: FAVORITO_GUARDAR_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : FAVORITO_GUARDAR_FALLIDO,
        payload: error,
      });
    });
};

export const eliminarFavorito = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .put(
      `${url_api}/api/vigilances/favorito/eliminar`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: FAVORITA_ELIMINAR_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : FAVORITA_ELIMINAR_FALLIDO,
        payload: error,
      });
    });
};
