import React, { Component } from "react";
import { TablaPequena as Documentos } from "../documentos";
import { Tabs as Expedientes } from "../expedientes";
import { Tabla as Normas } from "../normas";
import { Tabs as RCAS } from "../rcas";
import { Tabs as UnidadesFiscalizables } from "../unidades";

export class Tabs extends Component {
  render = () => {
    const { seguimientos } = this.props;
    if (seguimientos && seguimientos.length < 1) {
      return (
        <tr className="text-center">
          <td colSpan="7">No existen seguimientos ambientales registrados</td>
        </tr>
      );
    } else {
      return (
        <div className="align-items-start">
          <div className="row">
            {seguimientos.length > 1 && (
              <div className="col-3 vh-70 overflow-auto">
                <div
                  className="nav flex-column nav-pills"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <h5 className="text-uppercase mb-2">
                    Seguimientos Ambientales
                  </h5>
                  {seguimientos.map((seguimiento, x) => (
                    <button
                      key={x}
                      className={`nav-link ${
                        x === 0 ? "active" : ""
                      } text-start`}
                      id={`seguimiento-tab-${seguimiento.id}`}
                      data-bs-toggle="pill"
                      data-bs-target={`#seguimiento-${seguimiento.id}`}
                      type="button"
                      role="tab"
                      aria-controls={`seguimiento-${seguimiento.id}`}
                      aria-selected={x === 0 ? "true" : "false"}
                    >
                      <>
                        {seguimiento.fecha_informe}{" "}
                        <span className="ms-1 me-1">|</span>{" "}
                      </>{" "}
                      {seguimiento.nombre}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className={`col-${seguimientos.length > 1 ? 9 : 12}`}>
              <div className="tab-content" id="v-pills-seguimientos">
                {seguimientos.map((seguimiento, x) => (
                  <div
                    key={x}
                    className={`tab-pane fade ${x === 0 ? "show active" : ""}`}
                    id={`seguimiento-${seguimiento.id}`}
                    role="tabpanel"
                    aria-labelledby={`seguimiento-tab-${seguimiento.id}`}
                    tabIndex="0"
                  >
                    <ul className="nav nav-pills nav-justified">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id={`seguimiento-general-tab-${seguimiento.id}`}
                          data-bs-toggle="pill"
                          data-bs-target={`#seguimiento-general-${seguimiento.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`seguimiento-general-${seguimiento.id}`}
                          aria-selected="true"
                        >
                          Información del Seguimiento Ambiental
                        </button>
                      </li>
                      {seguimiento.documentos &&
                        seguimiento.documentos.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`seguimiento-documentos-tab-${seguimiento.id}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#seguimiento-documentos-${seguimiento.id}`}
                              type="button"
                              role="tab"
                              aria-controls={`seguimiento-documentos-${seguimiento.id}`}
                              aria-selected="false"
                            >
                              Documentos (
                              {seguimiento &&
                              seguimiento.documentos &&
                              seguimiento.documentos.length > 0
                                ? seguimiento.documentos.length
                                : 0}
                              )
                            </button>
                          </li>
                        )}
                      {seguimiento.unidad_fiscalizable &&
                        seguimiento.unidad_fiscalizable.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`seguimiento-unidades-tab-${seguimiento.id}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#seguimiento-unidades-${seguimiento.id}`}
                              type="button"
                              role="tab"
                              aria-controls={`seguimiento-unidades-${seguimiento.id}`}
                              aria-selected="false"
                            >
                              Unidades Fiscalizables (
                              {seguimiento &&
                              seguimiento.unidad_fiscalizable &&
                              seguimiento.unidad_fiscalizable.length > 0
                                ? seguimiento.unidad_fiscalizable.length
                                : 0}
                              )
                            </button>
                          </li>
                        )}
                      {seguimiento.expediente &&
                        seguimiento.expediente.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`seguimiento-expedientes-tab-${seguimiento.id}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#seguimiento-expedientes-${seguimiento.id}`}
                              type="button"
                              role="tab"
                              aria-controls={`seguimiento-expedientes-${seguimiento.id}`}
                              aria-selected="false"
                            >
                              Expedientes SEIA (
                              {seguimiento &&
                              seguimiento.expediente &&
                              seguimiento.expediente.length > 0
                                ? seguimiento.expediente.length
                                : 0}
                              )
                            </button>
                          </li>
                        )}
                      {seguimiento.rca && seguimiento.rca.length > 0 && (
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id={`seguimiento-rcas-tab-${seguimiento.id}`}
                            data-bs-toggle="pill"
                            data-bs-target={`#seguimiento-rcas-${seguimiento.id}`}
                            type="button"
                            role="tab"
                            aria-controls={`seguimiento-rcas-${seguimiento.id}`}
                            aria-selected="true"
                          >
                            RCA{" "}
                            <small>
                              ({seguimiento.rca ? seguimiento.rca.length : 0})
                            </small>
                          </button>
                        </li>
                      )}
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show mt-3"
                        id={`seguimiento-general-${seguimiento.id}`}
                        role="tabpanel"
                        aria-labelledby={`seguimiento-general-tab-${seguimiento.id}`}
                      >
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td className="table-secondary w-20 text-dark">
                                  Nombre del seguimiento
                                </td>
                                <td>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={seguimiento.url_snifa}
                                  >
                                    {seguimiento.nombre
                                      ? seguimiento.nombre
                                      : "-- Sin nombre asignado --"}
                                    <i className="ms-1 fa fa-angle-right"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Fecha de Informe
                                </td>
                                <td>{seguimiento.fecha_informe}</td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Periodo de Inicio
                                </td>
                                <td>{seguimiento.periodo_inicio}</td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Periodo de Término
                                </td>
                                <td>{seguimiento.periodo_termino}</td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Descripción
                                </td>
                                <td>{seguimiento.descripcion}</td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Frecuencia
                                </td>
                                <td>{seguimiento.frecuencia}</td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Subcomponente Ambiental
                                </td>
                                <td>
                                  {seguimiento.subcomponentes_ambiental &&
                                    seguimiento.subcomponentes_ambiental
                                      .length > 0 &&
                                    seguimiento.subcomponentes_ambiental.map(
                                      (sub, y) => <li key={y}>{sub.nombre}</li>
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td className="table-secondary text-dark">
                                  Instrumentos
                                </td>
                                <td>
                                  <Normas normas={seguimiento.norma} />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {seguimiento.documentos &&
                        seguimiento.documentos.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`seguimiento-documentos-${seguimiento.id}`}
                            role="tabpanel"
                            aria-labelledby={`seguimiento-documentos-tab-${seguimiento.id}`}
                          >
                            <Documentos documentos={seguimiento.documentos} />
                          </div>
                        )}
                      {seguimiento.unidad_fiscalizable &&
                        seguimiento.unidad_fiscalizable.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`seguimiento-unidades-${seguimiento.id}`}
                            role="tabpanel"
                            aria-labelledby={`seguimiento-unidades-tab-${seguimiento.id}`}
                          >
                            <UnidadesFiscalizables
                              unidades_fiscalizables={
                                seguimiento.unidad_fiscalizable
                              }
                            />
                          </div>
                        )}
                      {seguimiento.expediente &&
                        seguimiento.expediente.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`seguimiento-expedientes-${seguimiento.id}`}
                            role="tabpanel"
                            aria-labelledby={`seguimiento-expedientes-tab-${seguimiento.id}`}
                          >
                            <Expedientes expedientes={seguimiento.expediente} />
                          </div>
                        )}
                      {seguimiento.rca && seguimiento.rca.length > 0 && (
                        <div
                          className="tab-pane fade mt-3"
                          id={`seguimiento-rcas-${seguimiento.id}`}
                          role="tabpanel"
                          aria-labelledby={`seguimiento-rcas-tab-${seguimiento.id}`}
                        >
                          <RCAS rcas={seguimiento.rca} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}

export default Tabs;
