import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { limpiarMensajes } from "../../../redux/actions/tareas/action";
import {
  eliminarPlandeAccion,
  estadoPlandeAccion,
} from "../../../redux/actions/tareas/planaccion/action";
import { Nuevo as NuevoPlanAccion } from "./";

export class Tabla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planaccion_nuevo_mostrar: false,
    };
  }

  mostrarPlanAccion = () => {
    this.setState({ planaccion_nuevo_mostrar: true });
  };

  cancelarCreacionPlanAccion = () => {
    this.setState({ planaccion_nuevo_mostrar: false });
  };

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  eliminacionPlanAccion = (pa) => {
    const { tarea, eliminarPlandeAccion } = this.props;
    SweetAlert.fire({
      title: "Eliminar plan de acción",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de eliminar el plan de acción?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarPlandeAccion({
          tarea: tarea.id,
          planaccion: pa.id,
        });
        SweetAlert.fire({
          title: "Requisito",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos eliminando el plan de acción, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  planAccionEstado = (pda, estado) => {
    const { estadoPlandeAccion, tarea_seleccionada } = this.props;
    var data_alerta = {
      title: "Estado del plan de acción",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: "¿Está seguro de aceptar el plan de acción seleccionado?",
      icon: "info",
      confirmButtonText: 'Si, Aceptar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    };
    if (estado === "rechazado") {
      data_alerta["text"] =
        "¿Está seguro de rechazar el plan de pda seleccionado?";
      data_alerta["confirmButtonText"] =
        'Si, Rechazar <i class="fa fa-thumbs-up"></i>';
    }

    SweetAlert.fire(data_alerta).then((result) => {
      if (result.value) {
        estadoPlandeAccion({
          tarea: tarea_seleccionada,
          planaccion: pda.id,
          estado: estado,
        });
        SweetAlert.fire({
          title: "Estado del plan de acción",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos guardando el estado del plan de acción, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  render = () => {
    const { planaccion_nuevo_mostrar } = this.state;
    const {
      limpiarMensajes,
      tarea,
      user_equipo,
      planaccion_eliminar_error,
      planaccion_eliminar,
      planaccion_estado_error,
      planaccion_estado,
    } = this.props;

    if (Object.entries(planaccion_eliminar_error).length > 0) {
      SweetAlert.close();
      if (
        planaccion_eliminar_error.data &&
        planaccion_eliminar_error.data.message
      ) {
        SweetAlert.fire({
          title: "Plan de acción no eliminado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: planaccion_eliminar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(planaccion_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Plan de acción eliminado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: planaccion_eliminar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.tareaSeleccionada();
          this.cancelarCreacionPlanAccion();
        }
      });
    }

    if (Object.entries(planaccion_estado_error).length > 0) {
      SweetAlert.close();
      if (
        planaccion_estado_error.data &&
        planaccion_estado_error.data.message
      ) {
        SweetAlert.fire({
          title: "Plan de acción",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: planaccion_estado_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(planaccion_estado).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Plan de acción",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: planaccion_estado.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.tareaSeleccionada();
          this.cancelarCreacionPlanAccion();
        }
      });
    }

    return (
      <div className="row">
        <div className="col-lg-12 mt-2 mb-2">
          <h5 className="text-dark float-start text-uppercase">
            Listado de planes de acción
          </h5>
          {!planaccion_nuevo_mostrar &&
            tarea.estado_text !== "Finalizada" &&
            user_equipo.rol_text === "Propietario" && (
              <div className="float-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.mostrarPlanAccion}
                >
                  Crear Plan de Acción <i className="fa fa-plus-circle"></i>
                </button>
              </div>
            )}
        </div>
        {planaccion_nuevo_mostrar && (
          <div className="col-lg-12">
            <NuevoPlanAccion
              cancelarCreacionPlanAccion={this.cancelarCreacionPlanAccion}
              tareaSeleccionada={this.tareaSeleccionada}
            />
          </div>
        )}
        <div className="col-lg-12">
          {tarea && tarea.planesaccion && tarea.planesaccion.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-secondary">
                  <tr>
                    <th className="w-3 text-center">ID</th>
                    <th className="w-10">Fecha</th>
                    <th>Nombre</th>
                    <th className="w-10 text-center">Estado</th>
                    <th className="w-10 text-center">Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {tarea.planesaccion.map((pa, x) => (
                    <tr key={x}>
                      <td className="text-center">{x + 1}</td>
                      <td>{pa.fecha}</td>
                      <td>{pa.nombre}</td>
                      <td className="text-center">
                        {pa.estado ? (
                          pa.estado === "aprobado" ? (
                            <>
                              {" "}
                              Aprobado{" "}
                              <i className="fa fa-check-circle text-success"></i>
                            </>
                          ) : (
                            <>
                              Rechazado{" "}
                              <i className="fa fa-times-circle text-danger"></i>
                            </>
                          )
                        ) : (
                          "Sin estado actual"
                        )}
                      </td>

                      <td className="text-center">
                        <div className="btn-group">
                          {!pa.estado &&
                            user_equipo.rol_text === "Propietario" && (
                              <>
                                <button
                                  data-tip
                                  data-tooltip-id="aceptar_pda"
                                  onClick={this.planAccionEstado.bind(
                                    this,
                                    pa,
                                    "aprobado"
                                  )}
                                  className="btn btn-xs btn-success"
                                >
                                  <i className="fa fa-check"></i>
                                </button>
                                <Tooltip id="aceptar_pda" aria-haspopup="true">
                                  Aceptar el plan de acción
                                </Tooltip>
                                <button
                                  data-tip
                                  data-tooltip-id="rechazar_pda"
                                  onClick={this.planAccionEstado.bind(
                                    this,
                                    pa,
                                    "rechazado"
                                  )}
                                  className="btn btn-xs btn-danger"
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                                <Tooltip id="rechazar_pda" aria-haspopup="true">
                                  Rechazar el plan de acción
                                </Tooltip>
                                <button
                                  data-tip
                                  data-tooltip-id="eliminar_pda"
                                  onClick={this.eliminacionPlanAccion.bind(
                                    this,
                                    pa,
                                    tarea
                                  )}
                                  className="btn btn-outline-danger btn-xs"
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                                <Tooltip id="eliminar_pda" aria-haspopup="true">
                                  Eliminar el plan de acción
                                </Tooltip>
                              </>
                            )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="card-body text-center alert alert-outline-danger mt-2">
              <h5 className="text-uppercase">
                No existen planes de acción creados
              </h5>
            </div>
          )}
        </div>
      </div>
    );
  };
}

Tabla.propTypes = {
  eliminarPlandeAccion: PropTypes.func.isRequired,
  estadoPlandeAccion: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  planaccion_eliminar: PropTypes.object.isRequired,
  planaccion_eliminar_error: PropTypes.object.isRequired,
  tarea_seleccionada: PropTypes.number.isRequired,
  planaccion_estado: PropTypes.object.isRequired,
  planaccion_estado_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  planaccion_eliminar: state.tareas.planaccion_eliminar,
  planaccion_eliminar_error: state.tareas.planaccion_eliminar_error,
  tarea_seleccionada: state.tareas.tarea_seleccionada,
  planaccion_estado: state.tareas.planaccion_estado,
  planaccion_estado_error: state.tareas.planaccion_estado_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { eliminarPlandeAccion, estadoPlandeAccion, limpiarMensajes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabla);
