import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  obtenerInstrumentosInfringidos,
  obtenerInstrumentosInfringidosFiltros,
} from "../../../redux/actions/vigilancias/instrumentos_infringidos/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Filtros, Tabla } from "./";

export class Listado extends Component {
  componentDidMount = () => {
    const { obtenerInstrumentosInfringidos, instrumentos_infringidos } =
      this.props;
    if (Object.entries(instrumentos_infringidos).length === 0) {
      obtenerInstrumentosInfringidos(1);
    }
  };

  nuevaPagina = (pagina) => {
    const {
      obtenerInstrumentosInfringidos,
      obtenerInstrumentosInfringidosFiltros,
      instrumentos_infringidos_filtros,
      instrumentos_infringidos_filtro_input,
    } = this.props;
    if (Object.entries(instrumentos_infringidos_filtros).length > 0) {
      obtenerInstrumentosInfringidosFiltros({
        filtros: instrumentos_infringidos_filtro_input,
        pagina: pagina,
      });
    } else {
      obtenerInstrumentosInfringidos(pagina);
    }
  };

  render = () => {
    const {
      instrumentos_infringidos,
      instrumentos_infringidos_filtros,
      is_loading_vigilancias,
    } = this.props;

    var instrumentos_infringidos_result = instrumentos_infringidos;
    if (Object.entries(instrumentos_infringidos_filtros).length > 0) {
      instrumentos_infringidos_result = instrumentos_infringidos_filtros;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Instrumentos Infringidos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los instrumentos infringidos, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_instrumentos_infringidos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4
                    data-tip
                    data-tooltip-id="detalle_instrumento_infringido"
                    className="float-start"
                  >
                    <i className="fa fa-tools"></i> INSTRUMENTOS INFRINGIDOS{" "}
                    <i className="fa fa-exclamation-circle text-warning"></i>
                  </h4>
                  <Tooltip
                    id="detalle_instrumento_infringido"
                    aria-haspopup="true"
                  >
                    Hemos disponibilizado 3 tipos de instrumentos para efectos
                    de búsqueda: NE, PPDA y RSMA.
                  </Tooltip>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE INSTRUMENTOS INFRINGIDOS{" "}
                      <small>
                        (
                        {instrumentos_infringidos_result &&
                        instrumentos_infringidos_result.total
                          ? instrumentos_infringidos_result.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={instrumentos_infringidos_result}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla
                      instrumentos_infringidos={instrumentos_infringidos_result}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerInstrumentosInfringidos: PropTypes.func.isRequired,
  obtenerInstrumentosInfringidosFiltros: PropTypes.func.isRequired,
  instrumentos_infringidos: PropTypes.object.isRequired,
  instrumentos_infringidos_filtros: PropTypes.object.isRequired,
  instrumentos_infringidos_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  instrumentos_infringidos: state.vigilancias.instrumentos_infringidos,
  instrumentos_infringidos_filtros:
    state.vigilancias.instrumentos_infringidos_filtros,
  instrumentos_infringidos_filtro_input:
    state.vigilancias.instrumentos_infringidos_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerInstrumentosInfringidos, obtenerInstrumentosInfringidosFiltros },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
