import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";

import {
  guardarTitulo,
  setArticuloTarea,
} from "../../../redux/actions/leyes/action";
import { Navegacion, Texto } from "./";

export class Partes extends Component {
  seleccionarTitulo = (id, padre) => {
    const { guardarTitulo, titulo_seleccionado } = this.props;
    var nuevo = [id];
    if (padre !== null) {
      nuevo = titulo_seleccionado.concat([id, padre]);
    }
    guardarTitulo(nuevo);
  };

  nuevaTarea = (articulo) => {
    const { setArticuloTarea } = this.props;
    setArticuloTarea(articulo);
  };

  render = () => {
    const { partes, titulo_seleccionado, user } = this.props;

    return (
      <Fragment>
        {partes && partes.length > 0 && (
          <div className="row">
            <div className="col-lg-3 col-sm-3">
              <div className="sticky-top top100 arbol_norma">
                <ul className="list-unstyled">
                  {partes.map((parte, x) => (
                    <Navegacion
                      key={x}
                      hijo={parte}
                      padre={null}
                      seleccionarTitulo={this.seleccionarTitulo}
                      titulo_seleccionado={titulo_seleccionado}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <Tooltip id="leychile" aria-haspopup="true">
                Ver detalle de la fuente oficial
              </Tooltip>
              <Tooltip id="archivo_pdf" aria-haspopup="true">
                Descargar en formato PDF
              </Tooltip>
              <Tooltip id="archivo_word" aria-haspopup="true">
                Descargar en formato Word
              </Tooltip>
              <Tooltip id="nueva_tarea" aria-haspopup="true">
                Crear una tarea del artículo y gestiona con QUALITY TASKING
              </Tooltip>
              {partes.map((parte, x) => (
                <Texto
                  key={x}
                  hijo={parte}
                  nuevaTarea={this.nuevaTarea}
                  is_user_tasks={
                    user && user.modulos && user.modulos.tasks ? true : false
                  }
                />
              ))}
            </div>
          </div>
        )}
      </Fragment>
    );
  };
}

Partes.propTypes = {
  guardarTitulo: PropTypes.func.isRequired,
  setArticuloTarea: PropTypes.func.isRequired,
  titulo_seleccionado: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  titulo_seleccionado: state.leyes.titulo_seleccionado,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarTitulo, setArticuloTarea }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Partes);
