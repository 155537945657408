import React, { Component } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerFiscalizacionesEstadistica } from "../../../redux/actions/vigilancias/fiscalizaciones/action";
import { Chart, Footer, Navbar, Pie, SidebarInicio } from "../../general";

export class Estadisticas extends Component {
  componentDidMount = () => {
    const { obtenerFiscalizacionesEstadistica, fiscalizaciones_estadisticas } =
      this.props;
    if (Object.entries(fiscalizaciones_estadisticas).length === 0) {
      obtenerFiscalizacionesEstadistica(1);
    }
  };

  render = () => {
    const { fiscalizaciones_estadisticas, is_loading_vigilancias } = this.props;

    var estados = _.countBy(
      _.orderBy(fiscalizaciones_estadisticas.result, "estado"),
      "estado"
    );
    var tipos = _.countBy(
      _.orderBy(fiscalizaciones_estadisticas.result, "tipo"),
      "tipo"
    );
    var anios = _.countBy(
      _.orderBy(fiscalizaciones_estadisticas.result, "anio"),
      "anio"
    );

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "SEIA",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las fiscalizaciones, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio
          menu_1="vigilancias_fiscalizaciones"
          menu_2="estadistica"
        />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tools"></i> FISCALIZACIONES{" "}
                    <small>
                      (
                      {fiscalizaciones_estadisticas &&
                        fiscalizaciones_estadisticas.total &&
                        fiscalizaciones_estadisticas.total}
                      )
                    </small>
                  </h4>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <Pie data={tipos} nombre="Fiscalizaciones por tipos" />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Pie data={estados} nombre="Fiscalizaciones por estado" />
              </div>
              <div className="col-lg-12 col-sm-12">
                <Chart
                  is_vertical
                  data={anios}
                  nombre="Fiscalizaciones"
                  titulo="Fiscalizaciones por año"
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Estadisticas.propTypes = {
  obtenerFiscalizacionesEstadistica: PropTypes.func.isRequired,
  fiscalizaciones_estadisticas: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  fiscalizaciones_estadisticas: state.vigilancias.fiscalizaciones_estadisticas,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerFiscalizacionesEstadistica }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Estadisticas);
