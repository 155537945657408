import React, { Component, Fragment } from "react";

import InputMask from "@mona-health/react-input-mask";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import logo from "../../images/logo.png";
import {
  limpiarMensajes,
  registro,
  validarTokenRegistro,
} from "../../redux/actions/auth/action";
import { withRouter } from "../general/with_router";

class Registro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primer_nombre: "",
      segundo_nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      genero: "",
      email: "",
      password: "",
      loading_guardar: "",
      uid: "",
      token: "",
    };
  }

  componentDidMount = () => {
    const { validarTokenRegistro } = this.props;
    const { uid, token } = this.props.match.params;
    validarTokenRegistro({
      uid: uid,
      token: token,
    });
    this.setState({
      uid: uid,
      token: token,
    });
  };

  componentDidUpdate = (prevProps) => {
    const { registro_valido } = this.props;
    if (registro_valido.email !== prevProps.registro_valido.email) {
      this.setState({ email: registro_valido.email });
    }
  };

  componentWillUnmount = () => {
    const { limpiarMensajes } = this.props;
    limpiarMensajes();
  };

  onClickGuardarRegistro = () => {
    const { registro } = this.props;
    const {
      primer_nombre,
      segundo_nombre,
      apellido_paterno,
      apellido_materno,
      fecha_nacimiento,
      genero,
      email,
      password,
      uid,
      token,
    } = this.state;
    var loading = SweetAlert.fire({
      title: "Creación de la cuenta",
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: "Validando la información ingresada, espere unos segundos",
      icon: "info",
      showConfirmButton: false,
      didOpen: () => {
        SweetAlert.showLoading();
      },
    });
    this.setState({
      loading_guardar: loading,
    });
    registro({
      perfil: {
        primer_nombre: primer_nombre ? primer_nombre : null,
        segundo_nombre: segundo_nombre ? segundo_nombre : null,
        apellido_paterno: apellido_paterno ? apellido_paterno : null,
        apellido_materno: apellido_materno ? apellido_materno : null,
        fecha_nacimiento: fecha_nacimiento ? fecha_nacimiento : null,
        genero: genero ? genero : null,
      },
      email: email ? email : null,
      password: password ? password : null,
      uid: uid,
      token: token,
    });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const {
      is_authenticated,
      errores_registro,
      guardar_registro,
      registro_valido,
    } = this.props;
    const {
      email,
      password,
      primer_nombre,
      segundo_nombre,
      apellido_paterno,
      apellido_materno,
      fecha_nacimiento,
      genero,
      loading_guardar,
    } = this.state;

    if (is_authenticated) {
      return <Navigate to="/user/inicio" />;
    }
    if (Object.entries(errores_registro).length > 0) {
      loading_guardar.close();
    }
    if (Object.entries(guardar_registro).length > 0) {
      limpiarMensajes();
      loading_guardar.close();
      SweetAlert.fire({
        title: "Cuenta creada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: guardar_registro.message,
        icon: "success",
        confirmButtonText: 'Iniciar Sesión <i class="fas fa-sign-in-alt"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then(() => {
        this.props.navigate("/inicio-sesion");
      });
    }

    return (
      <Fragment>
        <div className="account-body accountbg">
          <div className="row vh-100">
            <div className="col-lg-12 pe-0 align-self-center">
              <div className="row">
                <div className="col-lg-6 col-md-8 col-9 mx-auto">
                  <div className="card auth-card">
                    <div className="card-body">
                      <div className="px-3">
                        <div className="text-center auth-logo-text">
                          <p>
                            <img
                              src={logo}
                              alt="logo"
                              className="auth-logo img-fluid"
                            />
                          </p>
                          <h5>CREAR CUENTA DE CUMPLIMIENTO LEAN</h5>
                        </div>
                        {registro_valido.validacion ? (
                          <form className="form-horizontal auth-form my-4 row">
                            <div className="col-sm-12 text-end">
                              <p className="text-danger">
                                * datos obligatorios
                              </p>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>
                                  Primer nombre{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="primer_nombre"
                                    placeholder="Ingresa tu primer nombre"
                                    onChange={this.onChange}
                                    value={primer_nombre}
                                    className={
                                      errores_registro &&
                                      errores_registro.data &&
                                      errores_registro.data.perfil &&
                                      errores_registro.data.perfil.primer_nombre
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                  />
                                  {errores_registro &&
                                    errores_registro.data &&
                                    errores_registro.data.perfil &&
                                    errores_registro.data.perfil
                                      .primer_nombre &&
                                    errores_registro.data.perfil.primer_nombre.map(
                                      (err, x) => (
                                        <div
                                          key={x}
                                          className="invalid-feedback"
                                        >
                                          {err}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Segundo nombre</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="segundo_nombre"
                                    placeholder="Ingresa tu segundo nombre"
                                    onChange={this.onChange}
                                    value={segundo_nombre}
                                    className={
                                      errores_registro &&
                                      errores_registro.data &&
                                      errores_registro.data.perfil &&
                                      errores_registro.data.perfil
                                        .segundo_nombre
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                  />
                                  {errores_registro &&
                                    errores_registro.data &&
                                    errores_registro.data.perfil &&
                                    errores_registro.data.perfil
                                      .segundo_nombre &&
                                    errores_registro.data.perfil.segundo_nombre.map(
                                      (err, x) => (
                                        <div
                                          key={x}
                                          className="invalid-feedback"
                                        >
                                          {err}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                              <div className="form-group">
                                <label>
                                  Apellido paterno{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="apellido_paterno"
                                    placeholder="Ingresa tu apellido paterno"
                                    onChange={this.onChange}
                                    value={apellido_paterno}
                                    className={
                                      errores_registro &&
                                      errores_registro.data &&
                                      errores_registro.data.perfil &&
                                      errores_registro.data.perfil
                                        .apellido_paterno
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                  />
                                  {errores_registro &&
                                    errores_registro.data &&
                                    errores_registro.data.perfil &&
                                    errores_registro.data.perfil
                                      .apellido_paterno &&
                                    errores_registro.data.perfil.apellido_paterno.map(
                                      (err, x) => (
                                        <div
                                          key={x}
                                          className="invalid-feedback"
                                        >
                                          {err}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Apellido materno</label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="apellido_materno"
                                    placeholder="Ingresa tu apellido materno"
                                    onChange={this.onChange}
                                    value={apellido_materno}
                                    className={
                                      errores_registro &&
                                      errores_registro.data &&
                                      errores_registro.data.perfil &&
                                      errores_registro.data.perfil
                                        .apellido_materno
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                  />
                                  {errores_registro &&
                                    errores_registro.data &&
                                    errores_registro.data.perfil &&
                                    errores_registro.data.perfil
                                      .apellido_materno &&
                                    errores_registro.data.perfil.apellido_materno.map(
                                      (err, x) => (
                                        <div
                                          key={x}
                                          className="invalid-feedback"
                                        >
                                          {err}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Fecha de nacimiento</label>
                                <div className="input-group">
                                  <InputMask
                                    name="fecha_nacimiento"
                                    value={fecha_nacimiento}
                                    onChange={this.onChange}
                                    mask="99/99/9999"
                                    placeholder="Ingresa tu fecha de nacimiento"
                                    className={`form-control ${
                                      errores_registro &&
                                      errores_registro.data &&
                                      errores_registro.data.perfil &&
                                      errores_registro.data.perfil
                                        .fecha_nacimiento
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  />
                                  {errores_registro &&
                                    errores_registro.data &&
                                    errores_registro.data.perfil &&
                                    errores_registro.data.perfil
                                      .fecha_nacimiento &&
                                    errores_registro.data.perfil.fecha_nacimiento.map(
                                      (err, x) => (
                                        <div
                                          key={x}
                                          className="invalid-feedback"
                                        >
                                          {err}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Género</label>
                                <div className="input-group">
                                  <select
                                    name="genero"
                                    onChange={this.onChange}
                                    value={genero}
                                    className={
                                      errores_registro &&
                                      errores_registro.data &&
                                      errores_registro.data.perfil &&
                                      errores_registro.data.perfil.genero
                                        ? "form-select is-invalid"
                                        : "form-select"
                                    }
                                  >
                                    <option value="">
                                      Selecciona una opción
                                    </option>
                                    <option value="Femenino">Femenino</option>
                                    <option value="Masculino">Masculino</option>
                                  </select>
                                  {errores_registro &&
                                    errores_registro.data &&
                                    errores_registro.data.perfil &&
                                    errores_registro.data.perfil.genero &&
                                    errores_registro.data.perfil.genero.map(
                                      (err, x) => (
                                        <div
                                          key={x}
                                          className="invalid-feedback"
                                        >
                                          {err}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                              <div className="form-group">
                                <label>
                                  Contraseña{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="input-group">
                                  <input
                                    type="password"
                                    name="password"
                                    placeholder="Crea tu contraseña"
                                    onChange={this.onChange}
                                    value={password}
                                    className={
                                      errores_registro &&
                                      errores_registro.data &&
                                      errores_registro.data.password
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                  />
                                  {errores_registro &&
                                    errores_registro.data &&
                                    errores_registro.data.password &&
                                    errores_registro.data.password.map(
                                      (err, x) => (
                                        <div
                                          key={x}
                                          className="invalid-feedback"
                                        >
                                          {err}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <div className="input-group">
                                  <input
                                    type="email"
                                    name="email"
                                    placeholder="Ingresa tu email"
                                    onChange={this.onChange}
                                    value={email}
                                    className={
                                      errores_registro &&
                                      errores_registro.data &&
                                      errores_registro.data.email
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    readOnly
                                  />
                                  {errores_registro &&
                                    errores_registro.data &&
                                    errores_registro.data.email &&
                                    errores_registro.data.email.map(
                                      (err, x) => (
                                        <div
                                          key={x}
                                          className="invalid-feedback"
                                        >
                                          {err}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 mt-3">
                              <div className="form-group mb-0">
                                <button
                                  className="btn btn-danger btn-block"
                                  type="button"
                                  onClick={this.onClickGuardarRegistro}
                                >
                                  Crea Tu Cuenta
                                  <i className="fas fa-sign-in-alt ms-1"></i>
                                </button>
                                <p className="mt-3">
                                  Al crear tu cuenta estás aceptando los
                                  <a
                                    className="text-danger border-bottom font-weight-bold ms-1"
                                    rel="noopener noreferrer"
                                    href="https://docs.google.com/document/d/1Zpa9-FRmFDEqBXxiQCsYDPU5wXW139DxtU3FgoL9pDU/edit?usp=sharing"
                                    target="_blank"
                                  >
                                    Términos y Condiciones
                                  </a>
                                  <br />y la
                                  <a
                                    className="text-danger border-bottom font-weight-bold ms-1 me-1"
                                    rel="noopener noreferrer"
                                    href="https://docs.google.com/document/d/19ETmULLsiwrHSD2GlI1M0bYeUe0Pm8UuTBi4o0pQEa8/edit?usp=sharing"
                                    target="_blank"
                                  >
                                    Política de Privacidad
                                  </a>
                                  del uso de nuestra plataforma y sus módulos.
                                </p>
                              </div>
                            </div>
                          </form>
                        ) : (
                          <div className="alert alert-warning text-white">
                            <h4>URL NO VÁLIDA</h4>
                            <p>
                              La URL que acabas de ingresar para crear tu cuenta
                              en CUMPLIMIENTO LEAN expiró o no es válida, puedes
                              solicitar nuevamente con el área de soporte
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
}

Registro.propTypes = {
  registro: PropTypes.func.isRequired,
  validarTokenRegistro: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool,
  errores_registro: PropTypes.object,
  guardar_registro: PropTypes.object,
  registro_valido: PropTypes.object,
};

const mapStateToProps = (state) => ({
  is_authenticated: state.auth.is_authenticated,
  errores_registro: state.auth.errores_registro,
  guardar_registro: state.auth.guardar_registro,
  registro_valido: state.auth.registro_valido,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { registro, limpiarMensajes, validarTokenRegistro },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Registro)
);
