import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerNormasPromulgadas } from "../../../redux/actions/leyes/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Tabla } from "../normas";

export class Promulgada extends Component {
  componentDidMount = () => {
    const { obtenerNormasPromulgadas, normas_promulgadas } = this.props;
    if (Object.entries(normas_promulgadas).length === 0) {
      obtenerNormasPromulgadas(1);
    }
  };

  nuevaPagina = (pagina) => {
    const { obtenerNormasPromulgadas } = this.props;
    obtenerNormasPromulgadas(pagina);
  };

  render = () => {
    const { normas_promulgadas, is_loading_leyes } = this.props;

    if (is_loading_leyes) {
      SweetAlert.fire({
        title: "Normas",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las normas, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="leyes_promulgadas" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-gavel"></i> NORMAS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      ÚLTIMOS 7 DÍAS DE NORMAS PROMULGADAS{" "}
                      <small>
                        (
                        {normas_promulgadas && normas_promulgadas.total
                          ? normas_promulgadas.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={normas_promulgadas}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla normas={normas_promulgadas} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Promulgada.propTypes = {
  obtenerNormasPromulgadas: PropTypes.func.isRequired,
  normas_promulgadas: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_leyes: state.leyes.is_loading_leyes,
  normas_promulgadas: state.leyes.normas_promulgadas,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerNormasPromulgadas }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Promulgada);
