import React, { Component } from "react";

export class Modificacion extends Component {
  render = () => {
    const { modificaciones } = this.props;
    return (
      <div className="table-responsive">
        {modificaciones && modificaciones.length > 0 && (
          <table className="table table-bordered table-hover">
            <thead className="table-secondary">
              <tr>
                <th className="text-center w-3">ID</th>
                <th className="w-7">Publicación</th>
                <th>Norma que ha modificado</th>
              </tr>
            </thead>
            <tbody>
              {modificaciones.map((modificacion, x) => (
                <tr key={x}>
                  <td className="text-center">{x + 1}</td>
                  <td>{modificacion.nueva_ley.fecha_publicacion}</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={modificacion.nueva_ley.url_ley}
                    >
                      <span className="badge badge-dark me-1">
                        {modificacion.nueva_ley.tipo}
                      </span>
                      {modificacion.nueva_ley.titulo}{" "}
                      <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  };
}

export default Modificacion;
