import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  eliminarAlerta,
  limpiarMensajes,
  obtenerAlertas,
} from "../../../redux/actions/leyes/action";
import { Footer, Navbar, SidebarInicio } from "../../general";

export class Listado extends Component {
  componentDidMount = () => {
    const { obtenerAlertas } = this.props;
    obtenerAlertas(1);
  };

  obtenerValueFiltro = (filtro, id) => {
    const { normas_alertas_filtros } = this.props;
    let value = "";
    normas_alertas_filtros[filtro].map((item) => {
      if (item.id === id) {
        value = item.nombre;
      }
      return null;
    });
    return value;
  };

  eliminacionAlerta = (alerta) => {
    const { eliminarAlerta } = this.props;
    SweetAlert.fire({
      title: "Alerta",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de eliminar la alerta Módulo ${alerta.modulo_display} con fecha de creacion ${alerta.fecha_creacion}?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarAlerta({ alerta: alerta.id });
        this.setState({
          usuario_loading: SweetAlert.fire({
            title: "Alerta",
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: "Estamos eliminando la alerta, espere unos segundos",
            icon: "warning",
            showConfirmButton: false,
            didOpen: () => {
              SweetAlert.showLoading();
            },
          }),
        });
      }
    });
  };

  render = () => {
    const {
      obtenerAlertas,
      limpiarMensajes,
      is_loading_leyes,
      normas_alertas,
      normas_alerta_eliminar,
      normas_alerta_eliminar_error,
    } = this.props;

    if (is_loading_leyes) {
      SweetAlert.fire({
        title: "Alerta",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las alertas, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (Object.entries(normas_alerta_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Alerta eliminada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: normas_alerta_eliminar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerAlertas(1);
          limpiarMensajes();
        }
      });
    }

    if (
      Object.entries(normas_alerta_eliminar_error).length > 0 &&
      normas_alerta_eliminar_error.data &&
      normas_alerta_eliminar_error.data.message
    ) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Alerta no eliminada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: normas_alerta_eliminar_error.data.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
        }
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="leyes_alertas" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tower-broadcast"></i> ALERTAS
                  </h4>
                  <p className="float-end btn-group">
                    <Link
                      className="btn btn-primary"
                      to="/legal-tracking/alertas/nueva"
                    >
                      Crear Alerta <i className="fa fa-plus-circle"></i>
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5>LISTADO DE ALERTAS</h5>
                  </div>
                  <div className="card-body">
                    <table className="table table-bordered table-hover table-sm">
                      <thead className="table-secondary">
                        <tr>
                          <th className="text-center w-3">ID</th>
                          <th className="w-10">Fecha Creación</th>
                          <th>Organismo</th>
                          <th className="w-3 text-center">Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {normas_alertas &&
                        normas_alertas.result &&
                        normas_alertas.result.length > 0 ? (
                          normas_alertas.result.map((alerta, x) => (
                            <tr className="table-responsive" key={x}>
                              <td className="text-center">{x + 1}</td>
                              <td>{alerta.fecha_creacion}</td>
                              <td>
                                {alerta.autoridad.map((autoridad, y) => (
                                  <span
                                    className="badge badge-danger font-12 ms-2"
                                    key={y}
                                  >
                                    {autoridad.nombre}
                                  </span>
                                ))}
                              </td>
                              <td className="text-center">
                                <button
                                  onClick={this.eliminacionAlerta.bind(
                                    this,
                                    alerta
                                  )}
                                  data-tip
                                  data-tooltip-id="eliminar_alerta"
                                  className="btn btn-danger"
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                                <Tooltip
                                  id="eliminar_alerta"
                                  aria-haspopup="true"
                                >
                                  Eliminar la alerta
                                </Tooltip>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No hay alertas creadas
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerAlertas: PropTypes.func.isRequired,
  eliminarAlerta: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  normas_alertas: PropTypes.object.isRequired,
  normas_alertas_filtros: PropTypes.object.isRequired,
  is_loading_leyes: PropTypes.bool.isRequired,
  normas_alerta_eliminar: PropTypes.object.isRequired,
  normas_alerta_eliminar_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  normas_alertas: state.leyes.normas_alertas,
  normas_alertas_filtros: state.leyes.normas_alertas_filtros,
  is_loading_leyes: state.leyes.is_loading_leyes,
  normas_alerta_eliminar: state.leyes.normas_alerta_eliminar,
  normas_alerta_eliminar_error: state.leyes.normas_alerta_eliminar_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerAlertas, eliminarAlerta, limpiarMensajes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
