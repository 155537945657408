import React, { Component } from "react";

import { Link } from "react-router-dom";

export class Teams extends Component {
  render = () => {
    const { menu_1, menu_2, teams, sub_teams } = this.props;
    return (
      teams && (
        <>
          <h5 className="mt-3">
            {sub_teams.grupo ? sub_teams.grupo : "CL TEAMS"}
          </h5>
          <li
            className={
              menu_1 && menu_1 === "teams" && menu_2 && menu_2 === "equipos"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/teams/equipos">
              <i className="fa fa-users"></i>
              <span> Equipos</span>
            </Link>
          </li>
          <li
            className={
              menu_1 &&
              menu_1 === "teams" &&
              menu_2 &&
              menu_2 === "procedimientos"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/teams/procedimientos">
              <i className="fa fa-bullhorn"></i>
              <span> Procedimientos Sancionatorios</span>
            </Link>
          </li>
          <li
            className={
              menu_1 && menu_1 === "teams" && menu_2 && menu_2 === "medidas"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/teams/medidas">
              <i className="fa fa-cogs"></i>
              <span> Medidas Provisionales</span>
            </Link>
          </li>
          <li
            className={
              menu_1 &&
              menu_1 === "teams" &&
              menu_2 &&
              menu_2 === "requerimientos"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/teams/requerimientos">
              <i className="fa fa-arrow-alt-circle-right"></i>
              <span> Requerimientos de Ingreso</span>
            </Link>
          </li>
        </>
      )
    );
  };
}

export default Teams;
