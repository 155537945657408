import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  agregarResponsableTarea,
  limpiarMensajes,
} from "../../../redux/actions/tareas/action";

export class Responsable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tarea_responsable: "",
    };
  }

  cancelarResponsableTarea = () => {
    this.props.cancelarResponsableTarea();
  };

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  asignarResponsableTarea = () => {
    const { agregarResponsableTarea, tarea } = this.props;
    const { tarea_responsable } = this.state;

    if (!tarea_responsable) {
      SweetAlert.fire({
        title: "Responsable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Seleccione un responsable para asignar a la tarea",
        icon: "warning",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    } else {
      SweetAlert.fire({
        title: "Asignar Responsable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true,
        text: `¿Está seguro de asignar el responsable de la tarea?`,
        icon: "info",
        confirmButtonText: 'Si, asignar <i class="fa fa-thumbs-up"></i>',
        cancelButtonText: 'No, cancelar <i class="fa fa-times"></i>',
        customClass: {
          confirmButton: "btn btn-primary me-2",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          agregarResponsableTarea({
            tarea: tarea.id,
            responsable: tarea_responsable,
          });
          SweetAlert.fire({
            title: "Responsable",
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: "Estamos agregando el responsable a la tarea, espere unos segundos",
            icon: "warning",
            showConfirmButton: false,
            didOpen: () => {
              SweetAlert.showLoading();
            },
          });
        }
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render = () => {
    const { tarea_responsable } = this.state;
    const {
      limpiarMensajes,
      tarea,
      tarea_responsable_asignar,
      tarea_responsable_asignar_error,
    } = this.props;

    if (Object.entries(tarea_responsable_asignar_error).length > 0) {
      SweetAlert.close();
      if (
        tarea_responsable_asignar_error.data &&
        tarea_responsable_asignar_error.data.message
      ) {
        SweetAlert.fire({
          title: "Responsable",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: tarea_responsable_asignar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(tarea_responsable_asignar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Responsable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: tarea_responsable_asignar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.tareaSeleccionada();
          this.cancelarResponsableTarea();
        }
      });
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="card card-eco">
            <div className="card-header bg-primary text-white">
              <h5>Asignar responsable de la tarea</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-12 mb-2">
                  <label>Seleccione un usuario:</label>
                </div>
                <div className="form-group col-xs-12 col-lg-8 mt-0">
                  <select
                    name="tarea_responsable"
                    onChange={this.onChange}
                    defaultValue={tarea_responsable}
                    className={
                      tarea_responsable_asignar_error &&
                      tarea_responsable_asignar_error.data &&
                      tarea_responsable_asignar_error.data.rol
                        ? "form-select is-invalid"
                        : "form-select"
                    }
                  >
                    <option value="">Seleccione una opción</option>
                    {tarea.equipo &&
                      tarea.equipo.map((eq, x) => (
                        <option key={x} value={eq.usuario_id_text}>
                          {eq.usuario_text}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-xs-12 col-lg-4 text-start">
                  <Tooltip id="cancelar_responsable" aria-haspopup="true">
                    Cancelar asignar el responsable de la tarea
                  </Tooltip>
                  <div className="btn-group">
                    <button
                      onClick={this.asignarResponsableTarea}
                      className="btn btn-primary btn-xs"
                      type="button"
                    >
                      Asignar <i className="fa fa-check"></i>
                    </button>
                    <button
                      data-tip
                      data-tooltip-id="cancelar_responsable"
                      onClick={this.cancelarResponsableTarea}
                      className="btn btn-danger btn-xs"
                      type="button"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

Responsable.propTypes = {
  agregarResponsableTarea: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  tarea: PropTypes.object.isRequired,
  tarea_responsable_asignar: PropTypes.object.isRequired,
  tarea_responsable_asignar_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  tarea: state.tareas.tarea,
  tarea_responsable_asignar: state.tareas.tarea_responsable_asignar,
  tarea_responsable_asignar_error: state.tareas.tarea_responsable_asignar_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { agregarResponsableTarea, limpiarMensajes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Responsable);
