export const PROCEDIMIENTO_FALLIDO = 'PROCEDIMIENTO_FALLIDO';
export const PROCEDIMIENTO_OBTENER = 'PROCEDIMIENTO_OBTENER';
export const PROCEDIMIENTO_GUARDAR = 'PROCEDIMIENTO_GUARDAR';
export const PROCEDIMIENTOS_OBTENER = 'PROCEDIMIENTOS_OBTENER';
export const PROCEDIMIENTOS_FALLIDO = 'PROCEDIMIENTOS_FALLIDO';
export const PROCEDIMIENTOS_FILTROS_OBTENER = 'PROCEDIMIENTOS_FILTROS_OBTENER';
export const PROCEDIMIENTOS_FILTROS_FALLIDO = 'PROCEDIMIENTOS_FILTROS_FALLIDO';
export const PROCEDIMIENTOS_FILTROS_INPUT = 'PROCEDIMIENTOS_FILTROS_INPUT';
export const LIMPIAR_PROCEDIMIENTO_FILTROS = 'LIMPIAR_PROCEDIMIENTO_FILTROS';
export const PROCEDIMIENTOS_ESTADISTICAS_OBTENER = 'PROCEDIMIENTOS_ESTADISTICAS_OBTENER';
export const PROCEDIMIENTOS_ESTADISTICAS_FALLIDO = 'PROCEDIMIENTOS_ESTADISTICAS_FALLIDO';
