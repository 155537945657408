import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  HECHO_FALLIDO,
  HECHO_GUARDAR,
  HECHO_OBTENER,
  HECHOS_ESTADISTICAS_FALLIDO,
  HECHOS_ESTADISTICAS_OBTENER,
  HECHOS_FALLIDO,
  HECHOS_FILTROS_FALLIDO,
  HECHOS_FILTROS_INPUT,
  HECHOS_FILTROS_OBTENER,
  HECHOS_OBTENER,
  LIMPIAR_HECHOS_FILTROS,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const guardarHecho = (id) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  dispatch({
    type: HECHO_GUARDAR,
    payload: id,
  });
};

export const obtenerHechosEstadisticas = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/hechos/estadisticas/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: HECHOS_ESTADISTICAS_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : HECHOS_ESTADISTICAS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerHechos = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(`${url_api}/api/vigilances/hechos/${pagina}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: HECHOS_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : HECHOS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerHecho = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(`${url_api}/api/vigilances/hecho`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: HECHO_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : HECHO_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerHechosFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: LIMPIAR_HECHOS_FILTROS });
  axios
    .post(
      `${url_api}/api/vigilances/hechos/filtros/${data.pagina}`,
      data.filtros,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: HECHOS_FILTROS_INPUT, payload: data.filtros });
      dispatch({ type: HECHOS_FILTROS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : HECHOS_FILTROS_FALLIDO,
        payload: error,
      });
    });
};

export const limpiarFiltroHechos = () => (dispatch) => {
  dispatch({ type: LIMPIAR_HECHOS_FILTROS });
};
