import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import {
  limpiarFiltroNormas,
  obtenerNormas,
  obtenerNormasFiltros,
} from "../../../redux/actions/leyes/action";

export class Filtros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_fecha_publicacion_inicio:
        this.props.normas_filtro_input &&
        this.props.normas_filtro_input.fecha_publicacion_inicio
          ? this.props.normas_filtro_input.fecha_publicacion_inicio
          : "",
      filtro_fecha_publicacion_termino:
        this.props.normas_filtro_input &&
        this.props.normas_filtro_input.fecha_publicacion_termino
          ? this.props.normas_filtro_input.fecha_publicacion_termino
          : "",
      filtro_fecha_promulgacion_inicio:
        this.props.normas_filtro_input &&
        this.props.normas_filtro_input.fecha_promulgacion_inicio
          ? this.props.normas_filtro_input.fecha_promulgacion_inicio
          : "",
      filtro_fecha_promulgacion_termino:
        this.props.normas_filtro_input &&
        this.props.normas_filtro_input.fecha_promulgacion_termino
          ? this.props.normas_filtro_input.fecha_promulgacion_termino
          : "",
      filtro_fecha_vigencia_inicio:
        this.props.normas_filtro_input &&
        this.props.normas_filtro_input.fecha_vigencia_inicio
          ? this.props.normas_filtro_input.fecha_vigencia_inicio
          : "",
      filtro_fecha_vigencia_termino:
        this.props.normas_filtro_input &&
        this.props.normas_filtro_input.fecha_vigencia_termino
          ? this.props.normas_filtro_input.fecha_vigencia_termino
          : "",
      filtro_organismo:
        this.props.normas_filtro_input &&
        this.props.normas_filtro_input.organismo
          ? this.props.normas_filtro_input.organismo
          : "",
      filtro_nombre:
        this.props.normas_filtro_input && this.props.normas_filtro_input.nombre
          ? this.props.normas_filtro_input.nombre
          : "",
      filtro_tipo:
        this.props.normas_filtro_input && this.props.normas_filtro_input.tipo
          ? this.props.normas_filtro_input.tipo
          : "",
      filtro_derogado:
        this.props.normas_filtro_input &&
        this.props.normas_filtro_input.derogado
          ? this.props.normas_filtro_input.derogado
          : "",
      filtro_fuente:
        this.props.normas_filtro_input && this.props.normas_filtro_input.fuente
          ? this.props.normas_filtro_input.fuente
          : "",
      filtros_avanzandos:
        this.props.normas_filtro_input &&
        this.props.normas_filtro_input.filtros_avanzandos
          ? this.props.normas_filtro_input.filtros_avanzandos
          : false,
    };
  }

  filtroPertinencias = () => {
    const { obtenerNormasFiltros, obtenerNormas } = this.props;
    const {
      filtro_fecha_publicacion_inicio,
      filtro_fecha_publicacion_termino,
      filtro_fecha_promulgacion_inicio,
      filtro_fecha_promulgacion_termino,
      filtro_fecha_vigencia_inicio,
      filtro_fecha_vigencia_termino,
      filtro_organismo,
      filtro_nombre,
      filtro_tipo,
      filtro_derogado,
      filtro_fuente,
      filtros_avanzandos,
    } = this.state;
    if (
      filtro_fecha_publicacion_inicio ||
      filtro_fecha_publicacion_termino ||
      filtro_fecha_promulgacion_inicio ||
      filtro_fecha_promulgacion_termino ||
      filtro_fecha_vigencia_inicio ||
      filtro_fecha_vigencia_termino ||
      filtro_organismo ||
      filtro_nombre ||
      filtro_tipo ||
      filtro_derogado ||
      filtro_fuente ||
      filtros_avanzandos
    ) {
      var filtros = {
        fecha_publicacion_inicio: filtro_fecha_publicacion_inicio
          ? filtro_fecha_publicacion_inicio
          : null,
        fecha_publicacion_termino: filtro_fecha_publicacion_termino
          ? filtro_fecha_publicacion_termino
          : null,
        fecha_promulgacion_inicio: filtro_fecha_promulgacion_inicio
          ? filtro_fecha_promulgacion_inicio
          : null,
        fecha_promulgacion_termino: filtro_fecha_promulgacion_termino
          ? filtro_fecha_promulgacion_termino
          : null,
        fecha_vigencia_inicio: filtro_fecha_vigencia_inicio
          ? filtro_fecha_vigencia_inicio
          : null,
        fecha_vigencia_termino: filtro_fecha_vigencia_termino
          ? filtro_fecha_vigencia_termino
          : null,
        organismo: filtro_organismo ? filtro_organismo : null,
        nombre: filtro_nombre ? filtro_nombre : null,
        tipo: filtro_tipo ? filtro_tipo : null,
        derogado: filtro_derogado ? filtro_derogado : null,
        fuente: filtro_fuente ? filtro_fuente : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerNormasFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerNormas(1);
    }
  };

  borrarFiltros = () => {
    const { obtenerNormas, limpiarFiltroNormas } = this.props;
    limpiarFiltroNormas();
    obtenerNormas(1);
    this.setState({
      filtro_fecha_publicacion_inicio: "",
      filtro_fecha_publicacion_termino: "",
      filtro_fecha_promulgacion_inicio: "",
      filtro_fecha_promulgacion_termino: "",
      filtro_fecha_vigencia_inicio: "",
      filtro_fecha_vigencia_termino: "",
      filtro_organismo: "",
      filtro_nombre: "",
      filtro_tipo: "",
      filtro_derogado: "",
      filtro_fuente: "",
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  render = () => {
    const { filtros_errores, filtros_normas } = this.props;
    const {
      filtro_fecha_publicacion_inicio,
      filtro_fecha_publicacion_termino,
      filtro_fecha_promulgacion_inicio,
      filtro_fecha_promulgacion_termino,
      filtro_fecha_vigencia_inicio,
      filtro_fecha_vigencia_termino,
      filtro_organismo,
      filtro_nombre,
      filtro_tipo,
      filtro_derogado,
      filtro_fuente,
      filtros_avanzandos,
    } = this.state;

    const tipos_listado =
      filtros_normas && filtros_normas["tipos"]
        ? _.orderBy(filtros_normas["tipos"], "label")
        : [];
    const organismos_listado =
      filtros_normas && filtros_normas["organismos"]
        ? _.orderBy(filtros_normas["organismos"], "label")
        : [];
    const fuentes_listado =
      filtros_normas && filtros_normas["fuentes"]
        ? _.orderBy(filtros_normas["fuentes"], "label")
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Título de la norma</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_nombre"
                    value={filtro_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese un nombre"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre &&
                    filtros_errores.data.titular.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Tipo</label>
                <SelectPicker
                  name="filtro_tipo"
                  data={tipos_listado}
                  onChange={(value) => {
                    this.setState({ filtro_tipo: value });
                  }}
                  value={filtro_tipo}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipo &&
                  filtros_errores.data.tipo.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Organismos</label>
                <SelectPicker
                  name="filtro_organismo"
                  data={organismos_listado}
                  onChange={(value) => {
                    this.setState({ filtro_organismo: value });
                  }}
                  value={filtro_organismo}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.organismo &&
                  filtros_errores.data.organismo.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Fuente</label>
                <SelectPicker
                  name="filtro_fuente"
                  data={fuentes_listado}
                  onChange={(value) => {
                    this.setState({ filtro_fuente: value });
                  }}
                  value={filtro_fuente}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />

                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.fuente &&
                  filtros_errores.data.fuente.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-6 col-sm-8 col-12 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de Publicación</label>
                <div className="input-group">
                  <span className="input-group-text">Desde/Hasta</span>
                  <input
                    type="date"
                    name="filtro_fecha_publicacion_inicio"
                    value={filtro_fecha_publicacion_inicio}
                    onChange={this.onChange}
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_publicacion_inicio
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <input
                    type="date"
                    name="filtro_fecha_publicacion_termino"
                    value={filtro_fecha_publicacion_termino}
                    onChange={this.onChange}
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_publicacion_termino
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_publicacion_inicio &&
                    filtros_errores.data.fecha_publicacion_inicio.map(
                      (err, x) => (
                        <div key={x} className="invalid-feedback">
                          {err}
                        </div>
                      )
                    )}
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_publicacion_termino &&
                    filtros_errores.data.fecha_publicacion_termino.map(
                      (err, x) => (
                        <div key={x} className="invalid-feedback">
                          {err}
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-6 col-sm-8 col-12 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de Promulgación</label>
                <div className="input-group">
                  <span className="input-group-text">Desde/Hasta</span>
                  <input
                    type="date"
                    name="filtro_fecha_promulgacion_inicio"
                    value={filtro_fecha_promulgacion_inicio}
                    onChange={this.onChange}
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_promulgacion_inicio
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <input
                    type="date"
                    name="filtro_fecha_promulgacion_termino"
                    value={filtro_fecha_promulgacion_termino}
                    onChange={this.onChange}
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_promulgacion_termino
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_promulgacion_inicio &&
                    filtros_errores.data.fecha_promulgacion_inicio.map(
                      (err, x) => (
                        <div key={x} className="invalid-feedback">
                          {err}
                        </div>
                      )
                    )}
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_promulgacion_termino &&
                    filtros_errores.data.fecha_promulgacion_termino.map(
                      (err, x) => (
                        <div key={x} className="invalid-feedback">
                          {err}
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-6 col-sm-8 col-12 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de Vigencia</label>
                <div className="input-group">
                  <span className="input-group-text">Desde/Hasta</span>
                  <input
                    type="date"
                    name="filtro_fecha_vigencia_inicio"
                    value={filtro_fecha_vigencia_inicio}
                    onChange={this.onChange}
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_vigencia_inicio
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <input
                    type="date"
                    name="filtro_fecha_vigencia_termino"
                    value={filtro_fecha_vigencia_termino}
                    onChange={this.onChange}
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_vigencia_termino
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_vigencia_inicio &&
                    filtros_errores.data.fecha_vigencia_inicio.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_vigencia_termino &&
                    filtros_errores.data.fecha_vigencia_termino.map(
                      (err, x) => (
                        <div key={x} className="invalid-feedback">
                          {err}
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Norma Derogada?</label>
                <div className="input-group">
                  <select
                    name="filtro_derogado"
                    onChange={this.onChange}
                    value={filtro_derogado}
                    className="form-select"
                  >
                    <option value="">Selecciona una opción</option>
                    <option value="Si">Sí</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroPertinencias}
            >
              <i className="fa fa-search"></i> Filtrar Normas
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Filtros.propTypes = {
  obtenerNormas: PropTypes.func.isRequired,
  obtenerNormasFiltros: PropTypes.func.isRequired,
  limpiarFiltroNormas: PropTypes.func.isRequired,
  filtros_normas: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  normas_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_normas: state.leyes.filtros_normas,
  filtros_errores: state.leyes.filtros_errores,
  normas_filtro_input: state.leyes.normas_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerNormas,
      obtenerNormasFiltros,
      limpiarFiltroNormas,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
