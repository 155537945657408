import React, { Fragment } from "react";

import { Link } from "react-router-dom";

// import { useHistory } from 'react-router-dom';
import logo from "../../images/logo.png";

const Error404 = ({ history }) => (
  <Fragment>
    <div className="account-body accountbg">
      <div className="row vh-100">
        <div className="col-lg-12 pe-0 align-self-center">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-8 mx-auto">
              <div className="card auth-card">
                <div className="card-body">
                  <div className="px-3">
                    <div className="auth-logo-text text-center d-grid gap-2">
                      <p className="mb-0">
                        <img
                          src={logo}
                          alt="logo"
                          className="auth-logo img-fluid"
                        />
                      </p>
                      <h5 className="mb-1">URL NO ENCONTRADA</h5>
                      <p>
                        La página que buscas puede haber sido removida, cambiado
                        de nombre o temporalmente no está disponible..
                      </p>
                      <Link className="btn btn-danger mb-4" to="/">
                        <i className="fa fa-angle-left"></i> Volver al inicio
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

export default Error404;
