import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import { format as formatRut } from "rut.js";
import {
  limpiarFiltroUnidadesFiscalizables,
  obtenerUnidadesFiscalizables,
  obtenerUnidadesFiscalizablesFiltros,
} from "../../../redux/actions/vigilancias/unidades_fiscalizables/action";

export class Unidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_region:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.region
          ? this.props.unidades_filtro_input.region
          : "",
      filtro_categoria:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.categoria
          ? this.props.unidades_filtro_input.categoria
          : "",
      filtro_nombre:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.nombre
          ? this.props.unidades_filtro_input.nombre
          : "",
      filtro_titular_nombre:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.titular_nombre
          ? this.props.unidades_filtro_input.titular_nombre
          : "",
      filtro_titular_rut:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.titular_rut
          ? this.props.unidades_filtro_input.titular_rut
          : "",
      ver_unidades:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.ver_unidades
          ? this.props.unidades_filtro_input.ver_unidades
          : false,
      filtros_avanzandos:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.filtros_avanzandos
          ? this.props.unidades_filtro_input.filtros_avanzandos
          : false,
    };
  }

  filtroUnidades = () => {
    const {
      obtenerUnidadesFiscalizablesFiltros,
      obtenerUnidadesFiscalizables,
    } = this.props;
    const {
      filtro_region,
      filtro_categoria,
      filtro_nombre,
      filtro_titular_nombre,
      filtro_titular_rut,
      filtros_avanzandos,
      ver_unidades,
    } = this.state;
    if (
      filtro_region ||
      filtro_categoria ||
      filtro_nombre ||
      filtro_titular_nombre ||
      filtro_titular_rut
    ) {
      var filtros = {
        region: filtro_region ? filtro_region : null,
        categoria: filtro_categoria ? filtro_categoria : null,
        nombre: filtro_nombre ? filtro_nombre : null,
        titular_nombre: filtro_titular_nombre ? filtro_titular_nombre : null,
        titular_rut: filtro_titular_rut ? filtro_titular_rut : null,
        filtros_avanzandos: filtros_avanzandos,
        ver_unidades: ver_unidades,
      };
      obtenerUnidadesFiscalizablesFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerUnidadesFiscalizables(1);
    }
  };

  borrarFiltros = () => {
    const { obtenerUnidadesFiscalizables, limpiarFiltroUnidadesFiscalizables } =
      this.props;
    this.setState({
      filtro_region: "",
      filtro_categoria: "",
      filtro_nombre: "",
      filtro_titular_nombre: "",
      filtro_titular_rut: "",
    });
    limpiarFiltroUnidadesFiscalizables();
    obtenerUnidadesFiscalizables(1);
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (e.target.name === "filtro_titular_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  render = () => {
    const { filtros_errores, filtros_unidades } = this.props;
    const {
      filtro_region,
      filtro_categoria,
      filtro_nombre,
      filtro_titular_nombre,
      filtro_titular_rut,
      filtros_avanzandos,
    } = this.state;
    const regiones_listado =
      filtros_unidades && filtros_unidades["regiones"]
        ? _.orderBy(filtros_unidades["regiones"], "nombre")
        : [];
    const categorias_listado =
      filtros_unidades && filtros_unidades["categorias"]
        ? _.orderBy(filtros_unidades["categorias"], "nombre")
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Nombre</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_nombre"
                    value={filtro_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese nombre"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre &&
                    filtros_errores.data.nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Categoría</label>
                <SelectPicker
                  name="filtro_categoria"
                  data={categorias_listado}
                  onChange={(value) => {
                    this.setState({ filtro_categoria: value });
                  }}
                  value={filtro_categoria}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.region &&
                  filtros_errores.data.region.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Nombre del titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_nombre"
                    value={filtro_titular_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese nombre del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_nombre &&
                    filtros_errores.data.titular_nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>RUT del titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_rut"
                    value={filtro_titular_rut}
                    onChange={this.onChange}
                    placeholder="Ingrese rut del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_rut
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_rut &&
                    filtros_errores.data.titular_rut.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Región</label>
                <SelectPicker
                  name="filtro_region"
                  data={regiones_listado}
                  onChange={(value) => {
                    this.setState({ filtro_region: value });
                  }}
                  value={filtro_region}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.region &&
                  filtros_errores.data.region.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroUnidades}
            >
              <i className="fa fa-search"></i> Filtrar unidades fiscalizables
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Unidades.propTypes = {
  obtenerUnidadesFiscalizables: PropTypes.func.isRequired,
  obtenerUnidadesFiscalizablesFiltros: PropTypes.func.isRequired,
  limpiarFiltroUnidadesFiscalizables: PropTypes.func.isRequired,
  filtros_unidades: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  unidades_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_unidades: state.vigilancias.filtros_unidades,
  filtros_errores: state.vigilancias.filtros_errores,
  unidades_filtro_input: state.vigilancias.unidades_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerUnidadesFiscalizables,
      obtenerUnidadesFiscalizablesFiltros,
      limpiarFiltroUnidadesFiscalizables,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Unidades);
