import React, { Component } from "react";
import { Tooltip } from "react-tooltip";
import { TablaPequena as Titulares } from "../titulares";
import { TablaPequena as UnidadesFiscalizables } from "../unidades";

export class Tabla extends Component {
  render = () => {
    const { requerimiento } = this.props;

    return (
      <div className="table-responsive">
        <Tooltip id="detalle_requerimiento" aria-haspopup="true">
          Ver detalle del requerimiento de ingreso
        </Tooltip>
        <table className="table table-borderless">
          <tbody>
            <tr>
              <td className="w-15 text-center">
                <a
                  href={requerimiento.url_snifa}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {requerimiento.expediente}{" "}
                  <i className="fa fa-angle-right"></i>
                </a>
              </td>
              <td className="w-30">
                <Titulares titulares={requerimiento.titulares} />
              </td>
              <td className="w-30">
                <UnidadesFiscalizables
                  unidades_fiscalizables={requerimiento.unidad_fiscalizable}
                />
              </td>
              <td className="w-30">
                <span className="badge badge-danger font-11">
                  Último Movimiento
                </span>
                <p className="mb-0">{requerimiento.ultimo_documento.fecha}</p>
                <p className="mb-0 small text-uppercase">
                  {requerimiento.ultimo_documento.nombre}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}
export default Tabla;
