import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  EXPEDIENTES_DOCUMENTOS_FALLIDO,
  EXPEDIENTES_DOCUMENTOS_FILTROS_INPUT,
  EXPEDIENTES_DOCUMENTOS_OBTENER,
  EXPEDIENTES_ESTADISTICAS_FALLIDO,
  EXPEDIENTES_ESTADISTICAS_GENERADORA_FALLIDO,
  EXPEDIENTES_ESTADISTICAS_GENERADORA_OBTENER,
  EXPEDIENTES_ESTADISTICAS_OBTENER,
  EXPEDIENTES_FALLIDO,
  EXPEDIENTES_FILTROS_FALLIDO,
  EXPEDIENTES_FILTROS_INPUT,
  EXPEDIENTES_FILTROS_OBTENER,
  EXPEDIENTES_MAPA_FALLIDO,
  EXPEDIENTES_MAPA_FILTROS_INPUT,
  EXPEDIENTES_MAPA_OBTENER,
  EXPEDIENTES_OBTENER,
  EXPEDIENTE_FALLIDO,
  EXPEDIENTE_GUARDAR,
  EXPEDIENTE_OBTENER,
  FILTROS_EXPEDIENTES_DOCUMENTOS_FALLIDO,
  FILTROS_EXPEDIENTES_DOCUMENTOS_OBTENER,
  FILTROS_EXPEDIENTES_MAPA_FALLIDO,
  FILTROS_EXPEDIENTES_MAPA_OBTENER,
  LIMPIAR_EXPEDIENTES_DOCUMENTOS_FILTROS,
  LIMPIAR_EXPEDIENTES_FILTROS,
  LIMPIAR_EXPEDIENTES_MAPA_FILTROS,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerExpedientesMapaFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/expedientes/mapa`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: EXPEDIENTES_MAPA_FILTROS_INPUT, payload: data });
      dispatch({ type: EXPEDIENTES_MAPA_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : EXPEDIENTES_MAPA_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerFiltrosExpedientesMapa = () => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(`${url_api}/api/vigilances/expedientes/mapa`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: FILTROS_EXPEDIENTES_MAPA_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : FILTROS_EXPEDIENTES_MAPA_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerExpedientesDocumentosFiltros =
  (data) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .post(
        `${url_api}/api/vigilances/expedientes/documentos/${data.pagina}`,
        data.filtros,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: EXPEDIENTES_DOCUMENTOS_FILTROS_INPUT,
          payload: data.filtros,
        });
        dispatch({ type: EXPEDIENTES_DOCUMENTOS_OBTENER, payload: res.data });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : EXPEDIENTES_DOCUMENTOS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerFiltrosExpedientesDocumentos =
  () => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .get(
        `${url_api}/api/vigilances/expedientes/documentos/1`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: FILTROS_EXPEDIENTES_DOCUMENTOS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : FILTROS_EXPEDIENTES_DOCUMENTOS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerExpedientesEstadistica =
  (pagina) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .get(
        `${url_api}/api/vigilances/expedientes/estadisticas/${pagina}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({ type: EXPEDIENTES_ESTADISTICAS_OBTENER, payload: res.data });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : EXPEDIENTES_ESTADISTICAS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerExpedientesEstadisticaGeneradoras =
  (pagina) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .get(
        `${url_api}/api/vigilances/expedientes/estadisticas/generadoras/${pagina}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: EXPEDIENTES_ESTADISTICAS_GENERADORA_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : EXPEDIENTES_ESTADISTICAS_GENERADORA_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerExpedientes = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/expedientes/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: EXPEDIENTES_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : EXPEDIENTES_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerExpediente = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(`${url_api}/api/vigilances/expediente`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: EXPEDIENTE_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : EXPEDIENTE_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerExpedientesFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/expedientes/filtros/${data.pagina}`,
      data.filtros,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: EXPEDIENTES_FILTROS_INPUT, payload: data.filtros });
      dispatch({ type: EXPEDIENTES_FILTROS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : EXPEDIENTES_FILTROS_FALLIDO,
        payload: error,
      });
    });
};

export const limpiarFiltroExpedientes = () => (dispatch) => {
  dispatch({ type: LIMPIAR_EXPEDIENTES_FILTROS });
};

export const limpiarFiltroExpedientesMapa = () => (dispatch) => {
  dispatch({ type: LIMPIAR_EXPEDIENTES_MAPA_FILTROS });
};

export const limpiarFiltroExpedientesDocumentos = () => (dispatch) => {
  dispatch({ type: LIMPIAR_EXPEDIENTES_DOCUMENTOS_FILTROS });
};

export const guardarExpediente = (data) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  dispatch({ type: EXPEDIENTE_GUARDAR, payload: data });
};
