import React, { Component } from "react";

export class TablaPequena extends Component {
  render = () => {
    const { documentos } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="w-3 text-center">ID</th>
              <th>Nombre</th>
              <th className="w-35">Tipo</th>
              <th className="w-7">Fecha</th>
            </tr>
          </thead>
          <tbody>
            {documentos.length > 0 &&
              documentos.map((doc, x) => (
                <tr key={x}>
                  <td className="text-center">{x + 1}</td>
                  <td>
                    <a
                      href={doc.url_doc}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {doc.nombre} <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td>{doc.tipo ? doc.tipo : "N/A"}</td>
                  <td>{doc.fecha}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };
}

export default TablaPequena;
