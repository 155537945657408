import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerEstadistica } from "../../../redux/actions/leyes/action";
import { Chart, Footer, Navbar, Pie, SidebarInicio } from "../../general";

export class Estadisticas extends Component {
  componentDidMount = () => {
    const { obtenerEstadistica, normas_estadisticas } = this.props;
    if (Object.entries(normas_estadisticas).length === 0) {
      obtenerEstadistica(1);
    }
  };

  render = () => {
    const { normas_estadisticas, is_loading_leyes } = this.props;

    var derogados = {};
    var fuentes = {};
    var anios = {};
    if (normas_estadisticas && normas_estadisticas.result) {
      normas_estadisticas.result.derogado.map((derogado) => {
        derogados[derogado.derogado ? "Derogado" : "No Derogado"] =
          derogado.count;
        return null;
      });
      normas_estadisticas.result.fuente_nombre.map((fuente) => {
        fuentes[
          fuente.fuente_nombre === "" || fuente.fuente_nombre === null
            ? "S/I"
            : fuente.fuente_nombre
        ] = fuente.count;
        return null;
      });
      normas_estadisticas.result.fecha_vigencia.map((fecha) => {
        anios[fecha.fecha_vigencia__year] = fecha.count;
        return null;
      });
    }

    if (is_loading_leyes) {
      SweetAlert.fire({
        title: "Normas",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las estadisticas, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="leyes_estadisticas" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tools"></i> NORMAS{" "}
                    <small>
                      (
                      {normas_estadisticas &&
                        normas_estadisticas.total &&
                        normas_estadisticas.total}
                      )
                    </small>
                  </h4>
                </div>
              </div>
              <div className="col-lg-12 col-sm-12">
                <Chart
                  data={fuentes}
                  nombre="Normas por Fuente"
                  titulo="Normas por Fuente"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Pie
                  data={derogados}
                  nombre="Normas Derogadas"
                  titulo="Normas Derogadas"
                />
              </div>
              <div className="col-lg-12 col-sm-12">
                <Chart
                  is_vertical
                  data={anios}
                  nombre="Fecha de Vigencia por año"
                  titulo="Fecha de Vigencia por año"
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Estadisticas.propTypes = {
  obtenerEstadistica: PropTypes.func.isRequired,
  normas_estadisticas: PropTypes.object.isRequired,
  is_loading_leyes: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  normas_estadisticas: state.leyes.normas_estadisticas,
  is_loading_leyes: state.leyes.is_loading_leyes,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerEstadistica }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Estadisticas);
