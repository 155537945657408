import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { guardarRca } from "../../../redux/actions/vigilancias/rcas/action";
import { TablaPequena as Titulares } from "../titulares";
import { TablaPequena as UnidadFiscalizables } from "../unidades";

export class Tabla extends Component {
  setRca = (id) => {
    const { guardarRca } = this.props;
    guardarRca(id);
  };

  render = () => {
    const { rcas } = this.props;

    return (
      <div className="table-responsive">
        <Tooltip id="detalle_rca" aria-haspopup="true">
          Ver detalle de la rca
        </Tooltip>
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre del proyecto</th>
              <th className="text-center w-3">Tipo</th>
              <th className="text-center w-10">Fase</th>
              <th className="text-center w-20">Unidad Fiscalizable</th>
              <th className="text-center w-18">Titular</th>
              <th className="text-center w-3">Acción</th>
            </tr>
          </thead>
          <tbody>
            {rcas && rcas.result && rcas.result.length > 0 ? (
              rcas.result.map((rca, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {rcas.page > 1 ? (rcas.page - 1) * 20 + (x + 1) : x + 1}
                  </td>
                  <td>
                    <span className="badge badge-dark font-13 text-center me-1">
                      {rca.anio} / {rca.numero}
                    </span>
                    <span className="text-uppercase">{rca.nombre}</span>
                  </td>
                  <td>
                    <span
                      className={`badge badge-${
                        rca.tipo === "DIA" ? "warning" : "info"
                      }  font-13`}
                    >
                      {rca.tipo}
                    </span>
                  </td>
                  <td>{rca.fase}</td>
                  <td>
                    <UnidadFiscalizables
                      unidades_fiscalizables={rca.unidad_fiscalizable}
                    />
                  </td>
                  <td>
                    <Titulares titulares={rca.titular} />
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      <Link
                        data-tip
                        data-tooltip-id="detalle_documento"
                        to="/compliance-analytics/rca"
                        onClick={this.setRca.bind(this, rca.id)}
                        className="btn btn-soft-dark"
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="7">
                  No existen RCA
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarRca: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarRca }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
