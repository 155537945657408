import React, { Component } from "react";
import { Tabla as InstrumentosFiscalizados } from "../instrumentos_fiscalizados";
import { Tabla as FichaProcedimientos } from "../procedimientos";
import { Tabs as FichaUnidadFiscalizable } from "../unidades";

export class Tabs extends Component {
  render = () => {
    const { fiscalizaciones } = this.props;

    if (fiscalizaciones && fiscalizaciones.length < 1) {
      return (
        <tr className="text-center">
          <td colSpan="7">Sin fiscalizaciones registradas</td>
        </tr>
      );
    } else {
      return (
        <div className="align-items-start">
          <div className="row">
            {fiscalizaciones.length > 1 && (
              <div className="col-3 vh-70 overflow-auto">
                <div
                  className="nav flex-column nav-pills"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <h5 className="text-uppercase mb-2">Fiscalizaciones</h5>
                  {fiscalizaciones.map((fisca, x) => (
                    <button
                      key={x}
                      className={`nav-link ${
                        x === 0 ? "active" : ""
                      } text-start`}
                      id={`fiscalizacion-tab-${fisca.expediente}`}
                      data-bs-toggle="pill"
                      data-bs-target={`#fiscalizacion-${fisca.expediente}`}
                      type="button"
                      role="tab"
                      aria-controls={`fiscalizacion-${fisca.expediente}`}
                      aria-selected={x === 0 ? "true" : "false"}
                    >
                      <>
                        {fisca.anio} <span className="ms-1 me-1">|</span>{" "}
                      </>{" "}
                      {fisca.expediente}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className={`col-${fiscalizaciones.length > 1 ? 9 : 12}`}>
              <div className="tab-content" id="v-pills-fiscalizaciones">
                {fiscalizaciones.map((fisca, x) => (
                  <div
                    key={x}
                    className={`tab-pane fade ${x === 0 ? "show active" : ""}`}
                    id={`fiscalizacion-${fisca.expediente}`}
                    role="tabpanel"
                    aria-labelledby={`fiscalizacion-tab-${fisca.expediente}`}
                    tabIndex="0"
                  >
                    <>
                      <ul className="nav nav-pills nav-justified">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="fiscalizacion-general-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#fiscalizacion-general"
                            type="button"
                            role="tab"
                            aria-controls="fiscalizacion-general"
                            aria-selected="true"
                          >
                            Información de la Fiscalización
                          </button>
                        </li>
                        {fisca.procedimiento &&
                          fisca.procedimiento.length > 0 && (
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id="fiscalizacion-procedimiento-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#fiscalizacion-procedimiento"
                                type="button"
                                role="tab"
                                aria-controls="fiscalizacion-procedimiento"
                                aria-selected="false"
                              >
                                Procedimiento Sancionatorio Asociado{" "}
                                <small>
                                  (
                                  {fisca.procedimiento
                                    ? fisca.procedimiento.length
                                    : 0}
                                  )
                                </small>
                              </button>
                            </li>
                          )}
                        {fisca.unidad_fiscalizable && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="fiscalizacion-unidad_fiscalizable-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#fiscalizacion-unidad_fiscalizable"
                              type="button"
                              role="tab"
                              aria-controls="fiscalizacion-unidad_fiscalizable"
                              aria-selected="false"
                            >
                              Unidad Fiscalizable
                            </button>
                          </li>
                        )}
                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show mt-3"
                          id="fiscalizacion-general"
                          role="tabpanel"
                          aria-labelledby="fiscalizacion-general-tab"
                        >
                          <div className="table-responsive" key={x}>
                            <table className="table table-bordered table-hover">
                              <tbody>
                                <tr>
                                  <td className="table-secondary w-15 text-dark">
                                    Expediente
                                  </td>
                                  <td>
                                    {fisca.url_snifa ? (
                                      <a
                                        href={fisca.url_snifa}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {fisca.expediente
                                          ? fisca.expediente
                                          : "S/N"}{" "}
                                        <i className="fa fa-angle-right"></i>
                                      </a>
                                    ) : fisca.expediente ? (
                                      fisca.expediente
                                    ) : (
                                      "S/N"
                                    )}
                                  </td>
                                </tr>
                                {fisca.instrumento && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Instrumento
                                    </td>
                                    <td>{fisca.instrumento}</td>
                                  </tr>
                                )}
                                {fisca.tipo && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Tipo
                                    </td>
                                    <td>{fisca.tipo}</td>
                                  </tr>
                                )}
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Año
                                  </td>
                                  <td>{fisca.anio}</td>
                                </tr>
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Estado
                                  </td>
                                  <td>{fisca.estado}</td>
                                </tr>
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Organismos participantes
                                  </td>
                                  <td>
                                    <table className="table table-bordered table-hover">
                                      <thead className="table-secondary">
                                        <tr>
                                          <th className="text-center w-3">
                                            ID
                                          </th>
                                          <th>Nombre</th>
                                          <th className="w-25">Sigla</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {fisca.organismo &&
                                        fisca.organismo.length > 0 ? (
                                          fisca.organismo.map((org, x) => (
                                            <tr key={x}>
                                              <td className="text-center">
                                                {x + 1}
                                              </td>
                                              <td>
                                                <a
                                                  href={org.url}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {org.nombre}{" "}
                                                  <i className="fa fa-angle-right"></i>
                                                </a>
                                              </td>
                                              <td>{org.sigla}</td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td
                                              className="text-center"
                                              colSpan="3"
                                            >
                                              Sin organismos registrados
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Documentos
                                  </td>
                                  <td>
                                    <table className="table table-bordered table-hover">
                                      <thead className="table-secondary">
                                        <tr>
                                          <th className="w-3">ID</th>
                                          <th>Nombre</th>
                                          <th className="w-40">Tipo</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {fisca.organismo &&
                                        fisca.documentos.length > 0 ? (
                                          fisca.documentos.map((doc, x) => (
                                            <tr key={x}>
                                              <td className="text-center">
                                                {x + 1}
                                              </td>
                                              <td>
                                                <a
                                                  href={doc.url_snifa}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {doc.nombre}{" "}
                                                  <i className="fa fa-angle-right"></i>
                                                </a>
                                              </td>
                                              <td>{doc.tipo}</td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td
                                              className="text-center"
                                              colSpan="3"
                                            >
                                              Sin documentos registrados
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                {fisca.instrumentos &&
                                  fisca.instrumentos.length > 0 && (
                                    <tr>
                                      <td className="table-secondary text-dark">
                                        Instrumentos fiscalizados
                                      </td>
                                      <td>
                                        <InstrumentosFiscalizados
                                          instrumentos={fisca.instrumentos}
                                        />
                                      </td>
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {fisca.procedimiento &&
                          fisca.procedimiento.length > 0 && (
                            <div
                              className="tab-pane fade mt-3"
                              id="fiscalizacion-procedimiento"
                              role="tabpanel"
                              aria-labelledby="fiscalizacion-procedimiento-tab"
                            >
                              <FichaProcedimientos
                                procedimientos={fisca.procedimiento}
                              />
                            </div>
                          )}
                        {fisca.unidad_fiscalizable && (
                          <div
                            className="tab-pane fade mt-3"
                            id="fiscalizacion-unidad_fiscalizable"
                            role="tabpanel"
                            aria-labelledby="fiscalizacion-unidad_fiscalizable-tab"
                          >
                            <FichaUnidadFiscalizable
                              unidades_fiscalizables={[
                                fisca.unidad_fiscalizable,
                              ]}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}

export default Tabs;
