import React, { Component } from "react";

import { Tooltip } from "react-tooltip";

import { Nuevo as NuevoEquipo } from "./";

export class Tabla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario_nuevo_mostrar: false,
    };
  }

  mostrarCrearUsuario = () => {
    this.setState({ usuario_nuevo_mostrar: true });
  };

  cancelarCreacionUsuario = () => {
    this.setState({ usuario_nuevo_mostrar: false });
  };

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  render = () => {
    const { usuario_nuevo_mostrar } = this.state;
    const { tarea, user_equipo } = this.props;

    return (
      <div className="row">
        <div className="col-lg-12 mt-2 mb-2">
          <h5 className="text-dark float-start text-uppercase">
            Equipo de la Tarea
          </h5>
          {!usuario_nuevo_mostrar &&
            tarea.estado_text !== "Finalizada" &&
            user_equipo.rol_text === "Propietario" && (
              <div className="float-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.mostrarCrearUsuario}
                >
                  Agregar Colaborador <i className="fa fa-plus-circle"></i>
                </button>
              </div>
            )}
        </div>
        {usuario_nuevo_mostrar && (
          <div className="col-lg-12">
            <NuevoEquipo
              cancelarCreacionUsuario={this.cancelarCreacionUsuario}
              tareaSeleccionada={this.tareaSeleccionada}
            />
          </div>
        )}
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-secondary">
                <tr>
                  <th className="text-center">ID</th>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th className="w-15">Rol</th>
                </tr>
              </thead>
              <tbody>
                {tarea.equipo && tarea.equipo.length > 0 ? (
                  tarea.equipo.map((eq, x) => (
                    <tr key={x}>
                      <td className="text-center">{x + 1}</td>
                      <td>{eq.usuario_text}</td>
                      <td>{eq.email_text}</td>
                      <td>
                        {eq.rol_text}
                        {eq.rol_text === "Propietario" && (
                          <>
                            <i
                              className="fa fa-exclamation-circle text-warning ms-1"
                              data-tip
                              data-tooltip-id="propietario"
                            ></i>
                            <Tooltip id="propietario" aria-haspopup="true">
                              <p>PROPIETARIO, ENTENDIDO COMO:</p>
                              <ul>
                                <li>EL DUEÑO DE LA TAREA</li>
                                <li>EL QUE ORIGINA EL ENCARGO</li>
                                <li>EL QUE RECIBE Y APRUEBA LOS ENTREGABLES</li>
                              </ul>
                            </Tooltip>
                          </>
                        )}
                        {eq.rol_text === "Colaborador" && (
                          <>
                            <i
                              className="fa fa-exclamation-circle text-warning ms-1"
                              data-tip
                              data-tooltip-id="colaborador"
                            ></i>
                            <Tooltip id="colaborador" aria-haspopup="true">
                              <p>COLABORADOR, ENTENDIDO COMO:</p>
                              <ul>
                                <li>
                                  EL RESPONSABLE DE APORTAR AL PROPIETARIO EL
                                  ENTREGABLE SOLICITADO
                                </li>
                                <li>
                                  ESTÁ A CARGO DE CUMPLIR EL ENCARGO REALIZADO
                                  POR EL PROPIETARIO CUMPLIENDO LOS REQUISITOS
                                  DEFINIDOS PARA ELLO
                                </li>
                              </ul>
                            </Tooltip>
                          </>
                        )}
                        {eq.rol_text === "Visualizador" && (
                          <>
                            <i
                              data-tip
                              data-tooltip-id="visualizador"
                              className="fa fa-exclamation-circle text-warning ms-1"
                            ></i>
                            <Tooltip id="visualizador" aria-haspopup="true">
                              <p>VISUALIZADOR, ENTENDIDO COMO:</p>
                              <ul>
                                <li>
                                  AQUEL MIEMBRO DEL EQUIPO DE TRABAJO QUE SOLO
                                  PUEDE VISUALIZAR LA TAREA
                                </li>
                                <li>
                                  NO PUEDE EDITAR, CREAR NI ELIMINAR INFORMACIÓN
                                </li>
                              </ul>
                            </Tooltip>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No existen miembros
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
}

export default Tabla;
