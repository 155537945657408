import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";

import { guardarDocumento } from "../../../redux/actions/vigilancias/documentos/action";
import { Eliminar as EliminarFavorito } from "../favoritos";
import { TablaPequena as Titulares } from "../titulares";
import { TablaPequena as Unidades } from "../unidades";

export class Tabla extends Component {
  setFiscalizacion = (id) => {
    const { guardarDocumento } = this.props;
    guardarDocumento(id);
  };

  render = () => {
    const { documentos, eliminar_favorito } = this.props;

    return (
      <div className="table-responsive">
        <Tooltip id="detalle_documento" aria-haspopup="true">
          Ver detalle del documento
        </Tooltip>
        <Tooltip id="link_documento" aria-haspopup="true">
          Descargar o ver documento
        </Tooltip>
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre del documento</th>
              <th className="w-15">Tipo</th>
              <th className="w-5">Fecha</th>
              <th className="w-20">Unidad Fiscalizable</th>
              <th className="w-20">Titular</th>
              <th className="text-center w-3">Acción</th>
            </tr>
          </thead>
          <tbody>
            {documentos && documentos.result && documentos.result.length > 0 ? (
              documentos.result.map((doc, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {documentos.page > 1
                      ? (documentos.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td className="text-uppercase">{doc.nombre}</td>
                  <td>
                    <span className="badge badge-warning font-12">
                      {doc.tipo}
                    </span>
                  </td>
                  <td>{doc.fecha}</td>
                  <td>
                    <Unidades
                      unidades_fiscalizables={doc.unidades_fiscalizables}
                    />
                  </td>
                  <td>
                    <Titulares titulares={doc.titulares} />
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {doc.url_snifa && (
                        <a
                          data-tip
                          data-tooltip-id="link_documento"
                          target="_blank"
                          rel="noreferrer"
                          href={doc.url_snifa}
                          className="btn btn-dark btn-xs"
                        >
                          <i className="fa fa-link"></i>
                        </a>
                      )}
                      {eliminar_favorito && (
                        <EliminarFavorito tipo="fiscalizacion" id={doc.id} />
                      )}
                      <Link
                        data-tip
                        data-tooltip-id="detalle_documento"
                        to="/compliance-analytics/documento"
                        className="btn btn-soft-dark"
                        onClick={this.setFiscalizacion.bind(this, doc.id)}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="7">
                  No existen documentos
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarDocumento: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarDocumento }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
