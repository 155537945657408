import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, createStore } from "redux";
import { thunk } from "redux-thunk";

import rootReducer from "./reducers";

var environment = process.env.REACT_APP_ENVIRONMENT;

var th =
  environment !== "prod"
    ? composeWithDevTools(applyMiddleware(thunk))
    : applyMiddleware(thunk);

const store = createStore(rootReducer, th);

export default store;
