import React, { Component, Fragment } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  eliminarTarea,
  limpiarMensajes,
  TareaSeleccionada,
} from "../../../redux/actions/tareas/action";
import { obtenerProyectos } from "../../../redux/actions/tareas/proyectos/action";
import { Editar as TareaEditar, Nueva as TareaNueva } from "./";

export class Listado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tarea_crear_mostrar: false,
      tarea_editar_mostrar: false,
      proyecto_seleccionado: {},
      tarea_seleccionada: {},
      listado_tareas: [],
    };
  }

  verDetalleTarea = (tarea) => {
    const { TareaSeleccionada } = this.props;
    TareaSeleccionada(tarea);
  };

  mostrarNuevaTarea = (proyecto) => {
    this.setState({
      tarea_crear_mostrar: true,
      tarea_editar_mostrar: false,
      proyecto_seleccionado: proyecto,
    });
  };

  cancelarNuevaTarea = () => {
    const { limpiarMensajes } = this.props;
    this.setState({
      tarea_crear_mostrar: false,
      tarea_editar_mostrar: false,
      proyecto_seleccionado: {},
    });
    limpiarMensajes();
  };

  mostrarEdicionTarea = (tarea) => {
    const { proyectos, proyecto } = this.props;
    var listado_tareas = [];
    proyectos.forEach(function (proyecto) {
      proyecto.tareas.forEach(function (tr) {
        if (
          _.findIndex(listado_tareas, { id: tr.id }) === -1 &&
          tr.id !== tarea.id
        ) {
          listado_tareas.push(tr);
        }
      });
    });

    this.setState({
      tarea_seleccionada: tarea,
      proyecto_seleccionado: proyecto,
      tarea_editar_mostrar: true,
      tarea_crear_mostrar: false,
      listado_tareas: listado_tareas,
    });
  };

  eliminacionTarea = (tarea) => {
    const { eliminarTarea } = this.props;
    SweetAlert.fire({
      title: "Tarea",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de eliminar la tarea ${tarea.nombre}?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarTarea({ tarea: tarea.id });
        this.setState({
          usuario_loading: SweetAlert.fire({
            title: "Tarea",
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: "Estamos eliminando la tarea, espere unos segundos",
            icon: "warning",
            showConfirmButton: false,
            didOpen: () => {
              SweetAlert.showLoading();
            },
          }),
        });
      }
    });
  };

  render = () => {
    const {
      tarea_crear_mostrar,
      tarea_editar_mostrar,
      tarea_seleccionada,
      proyecto_seleccionado,
      listado_tareas,
    } = this.state;
    const {
      tareas,
      index,
      proyecto,
      tarea_eliminar_error,
      tarea_eliminar,
      limpiarMensajes,
      obtenerProyectos,
    } = this.props;

    if (
      Object.entries(tarea_eliminar_error).length > 0 &&
      tarea_eliminar_error.data &&
      tarea_eliminar_error.data.message
    ) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Tarea no eliminada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: tarea_eliminar_error.data.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(tarea_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Tarea eliminada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: tarea_eliminar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerProyectos();
          limpiarMensajes();
          this.cancelarNuevaTarea();
        }
      });
    }

    return (
      <div className="card-body">
        <div className="row">
          {tarea_crear_mostrar && (
            <TareaNueva
              titulo="CREA UNA NUEVA TAREA"
              cancelarNuevaTarea={this.cancelarNuevaTarea}
              proyecto={proyecto}
              btnCancelar
            />
          )}
        </div>
        <div className="row">
          {tarea_editar_mostrar && (
            <TareaEditar
              cancelarNuevaTarea={this.cancelarNuevaTarea}
              tarea={tarea_seleccionada}
              proyecto={proyecto_seleccionado}
              listado_tareas={listado_tareas}
              btnCancelar
            />
          )}
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="float-start">
              <h6 className="text-uppercase">
                Listado de Tareas <small>({proyecto.tareas.length})</small>
              </h6>
            </div>
            <div className="float-end mb-2">
              <button
                onClick={this.mostrarNuevaTarea.bind(this, proyecto)}
                className="btn btn-outline-danger"
                data-tip
                data-tooltip-id="crear_consulta"
              >
                Tarea <i className="fa fa-plus-circle"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-secondary">
                  <tr className="bg-dark">
                    <th className="w-3 text-center">ID</th>
                    <th>Nombre de la Tarea</th>
                    <th>Tarea Dependiente</th>
                    <th className="w-10">Estado</th>
                    <th className="w-10">Categoría</th>
                    <th className="w-10">Fecha de Entrega</th>
                    <th className="w-10 text-center">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {tareas && tareas.length > 0 ? (
                    tareas.map((tarea, y) => (
                      <tr key={y}>
                        <td className="text-center">
                          {index + 1}.{y + 1}
                        </td>
                        <td>
                          <Link
                            onClick={this.verDetalleTarea.bind(this, tarea.id)}
                            data-tip
                            data-tooltip-id="ver_tarea"
                            to="/quality-tasking/tarea"
                          >
                            {tarea.nombre} <i className="fa fa-angle-right"></i>
                          </Link>
                        </td>

                        <td>{tarea.padre && tarea.padre.nombre}</td>
                        <td>{tarea.estado_text}</td>
                        <td>{tarea.categoria.nombre}</td>
                        <td className="text-center">
                          {tarea.fecha_entrega}
                          {tarea.n_dias >= 0 ? (
                            <Fragment>
                              <Tooltip
                                id={`tarea_${tarea.id}`}
                                aria-haspopup="true"
                              >
                                Te quedan {tarea.n_dias}{" "}
                                {tarea.n_dias === 1 ? "día" : "días"} para
                                completar la tarea
                              </Tooltip>
                              <span
                                data-tip
                                data-tooltip-id={`tarea_${tarea.id}`}
                                className="text-success small ms-2"
                              >
                                (+{tarea.n_dias})
                              </span>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Tooltip
                                id={`tarea_${tarea.id}`}
                                aria-haspopup="true"
                              >
                                La tarea se encuentra atrasada en {tarea.n_dias}{" "}
                                {tarea.n_dias === 1 ? "día" : "días"}
                              </Tooltip>
                              <span
                                data-tip
                                data-tooltip-id={`tarea_${tarea.id}`}
                                className="text-danger small ms-2"
                              >
                                ({tarea.n_dias})
                              </span>
                            </Fragment>
                          )}
                        </td>
                        <td className="text-center">
                          <div className="btn-group">
                            <Link
                              onClick={this.verDetalleTarea.bind(
                                this,
                                tarea.id
                              )}
                              data-tip
                              data-tooltip-id="ver_tarea"
                              className="btn btn-primary btn-xs"
                              to="/quality-tasking/tarea"
                            >
                              <i className="fa fa-eye"></i>
                            </Link>
                            <Tooltip id="ver_tarea" aria-haspopup="true">
                              Ver detalle de la tarea
                            </Tooltip>
                            <button
                              onClick={this.mostrarEdicionTarea.bind(
                                this,
                                tarea
                              )}
                              data-tip
                              data-tooltip-id="editar_tarea"
                              className="btn btn-warning btn-xs"
                            >
                              <i className="fa fa-pencil-alt"></i>
                            </button>
                            <Tooltip id="editar_tarea" aria-haspopup="true">
                              Editar la tarea
                            </Tooltip>
                            <button
                              onClick={this.eliminacionTarea.bind(this, tarea)}
                              data-tip
                              data-tooltip-id="eliminar_tarea"
                              className="btn btn-danger btn-xs"
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                            <Tooltip id="eliminar_tarea" aria-haspopup="true">
                              Eliminar la tarea
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No existen tareas creadas
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  TareaSeleccionada: PropTypes.func.isRequired,
  eliminarTarea: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  obtenerProyectos: PropTypes.func.isRequired,
  tarea_eliminar_error: PropTypes.object.isRequired,
  tarea_eliminar: PropTypes.object.isRequired,
  proyectos: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  tarea_eliminar_error: state.tareas.tarea_eliminar_error,
  tarea_eliminar: state.tareas.tarea_eliminar,
  proyectos: state.tareas.proyectos,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { TareaSeleccionada, eliminarTarea, limpiarMensajes, obtenerProyectos },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
