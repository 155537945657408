import { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  eliminarArchivo,
  limpiarMensajes,
} from "../../../redux/actions/tareas/action";
import { Nuevo as RecursoNuevo } from "./";

export class Tabla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recurso_nuevo_mostrar: false,
    };
  }

  mostrarCreacionRecursoApoyo = () => {
    this.setState({ recurso_nuevo_mostrar: true });
  };

  cancelarCreacionRecursoApoyo = () => {
    this.setState({ recurso_nuevo_mostrar: false });
  };

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  eliminacionArchivo = (archivo, tipo) => {
    const { eliminarArchivo, tarea_seleccionada, entregable } = this.props;
    var mensaje = "";
    if (tipo === "carga") {
      mensaje = "de la carga del entregable";
    } else {
      mensaje = "del recurso de apoyo";
    }
    SweetAlert.fire({
      title: `Eliminación ${mensaje}`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de eliminar ${
        tipo === "carga" ? "la carga del entregable" : "el recurso de apoyo"
      } seleccionado?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarArchivo({
          tarea: tarea_seleccionada,
          entregable: entregable.id,
          archivo: archivo.id,
          tipo: tipo,
        });
        SweetAlert.fire({
          title: `Eliminación ${mensaje}`,
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos eliminando lo seleccionado, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  render = () => {
    const { recurso_nuevo_mostrar } = this.state;
    const {
      entregable,
      user_equipo,
      archivo_eliminar,
      archivo_eliminar_error,
      limpiarMensajes,
    } = this.props;

    if (Object.entries(archivo_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Eliminado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: archivo_eliminar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.tareaSeleccionada();
        }
      });
    }

    if (Object.entries(archivo_eliminar_error).length > 0) {
      SweetAlert.close();
      if (archivo_eliminar_error.data && archivo_eliminar_error.data.message) {
        SweetAlert.fire({
          title: "Eliminación",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: archivo_eliminar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="float-end mb-3">
            {!recurso_nuevo_mostrar &&
              entregable.estado_text !== "Finalizada" &&
              user_equipo.rol_text === "Propietario" && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.mostrarCreacionRecursoApoyo}
                >
                  Crear Recurso de Apoyo <i className="fa fa-plus-circle"></i>
                </button>
              )}
          </div>
        </div>
        {recurso_nuevo_mostrar && (
          <RecursoNuevo
            entregable={entregable}
            tareaSeleccionada={this.tareaSeleccionada}
            cancelarCreacionRecursoApoyo={this.cancelarCreacionRecursoApoyo}
          />
        )}
        <div className="col-lg-12">
          {entregable.cargas && entregable.recursos.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-secondary">
                  <tr>
                    <th className="text-center w-3">ID</th>
                    <th className="w-15">Nombre</th>
                    <th>Descripción</th>
                    <th className="text-center w-12">Originador de la Carga</th>
                    <th className="text-center w-5">Documento</th>
                    <th className="text-center w-5">Link</th>
                    {entregable.estado_text !== "Finalizada" &&
                      user_equipo.rol_text === "Propietario" && (
                        <th className="text-center w-5">Acción</th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {entregable.recursos.map((archivo, b) => (
                    <tr key={b}>
                      <td className="text-center">{b + 1}</td>
                      <td>{archivo.nombre}</td>
                      <td>{archivo.descripcion} </td>
                      <td className="text-center">{archivo.user_equipo}</td>
                      <td className="text-center">
                        {archivo.documento_nombre && (
                          <a
                            className="btn btn-xs btn-primary"
                            target="_blank"
                            rel="noreferrer"
                            href={`/static/documentos/${archivo.documento_nombre}`}
                          >
                            Ver <i className="fa fa-angle-right"></i>
                          </a>
                        )}
                      </td>
                      <td className="text-center">
                        {archivo.link && (
                          <a
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-xs"
                            target="_blank"
                            href={archivo.link}
                          >
                            Ver <i className="fa fa-angle-right"></i>
                          </a>
                        )}
                      </td>
                      {entregable.estado_text !== "Finalizada" && (
                        <td className="text-center">
                          <button
                            onClick={this.eliminacionArchivo.bind(
                              this,
                              archivo,
                              "recurso"
                            )}
                            data-tip
                            data-tooltip-id="eliminar_recurso"
                            className="btn btn-xs btn-danger"
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          <Tooltip id="eliminar_recurso" aria-haspopup="true">
                            Eliminar recurso de apoyo
                          </Tooltip>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="card-body text-center alert alert-outline-danger">
              <h5 className="text-uppercase">
                No existen recursos de apoyo creados
              </h5>
            </div>
          )}
        </div>
      </div>
    );
  };
}

Tabla.propTypes = {
  eliminarArchivo: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  archivo_eliminar: PropTypes.object.isRequired,
  archivo_eliminar_error: PropTypes.object.isRequired,
  tarea_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  archivo_eliminar: state.tareas.archivo_eliminar,
  archivo_eliminar_error: state.tareas.archivo_eliminar_error,
  tarea_seleccionada: state.tareas.tarea_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ eliminarArchivo, limpiarMensajes }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabla);
