import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  agregarLeyUsuario,
  guardarNorma,
  limpiarMensajes,
} from "../../../redux/actions/leyes/action";
import { withRouter } from "../../general/with_router";

export class Tabla extends Component {
  verDetalleNorma = (id) => {
    const { guardarNorma } = this.props;
    guardarNorma(id, "normas");
  };

  agregarFavoritaNorma = (norma_id, norma_nombre) => {
    const { agregarLeyUsuario } = this.props;
    SweetAlert.fire({
      title: "Agregar Favorito",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de agregar la norma ${norma_nombre} a tu página de favoritos?`,
      icon: "info",
      confirmButtonText: 'Si, Agregar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        agregarLeyUsuario({
          ley: norma_id,
        });
        SweetAlert.close();
        SweetAlert.fire({
          title: "Agregando a Favoritos",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos agregar a favoritos, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  render = () => {
    const { normas, norma_favorita, norma_favorita_error, limpiarMensajes } =
      this.props;

    if (Object.entries(norma_favorita_error).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        title: "Norma Favorita",
        text: norma_favorita_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        limpiarMensajes();
      });
    }

    if (Object.entries(norma_favorita).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Norma Favorita",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: norma_favorita.message,
        icon: "success",
        confirmButtonText:
          'Ver listado de Favorito <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.props.navigate("/legal-tracking/favoritos");
        }
      });
    }

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover table-sm">
          <thead className="table-secondary">
            <tr>
              <th className="w-3 text-center">ID</th>
              <th>Título</th>
              <th className="w-10">Tipo</th>
              <th className="w-25">Organismo</th>
              <th className="w-6 text-center">Promulgación</th>
              <th className="w-6 text-center">Publicación</th>
              <th className="w-6 text-center">Vigencia</th>
              <th className="w-5 text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            {normas && normas.result && normas.result.length > 0 ? (
              normas.result.map((norma, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {normas.page > 1 ? (normas.page - 1) * 20 + (x + 1) : x + 1}
                  </td>
                  <td>
                    <a
                      href={`https://www.bcn.cl/leychile/navegar?idNorma=${norma.norma}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-uppercase"
                    >
                      {norma.tipo && (
                        <span className="badge badge-dark me-1">
                          {norma.tipo}
                        </span>
                      )}
                      {norma.titulo} <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td>
                    <span
                      className={`badge badge-${
                        norma.last_tipo ? "warning " : "info"
                      } font-12`}
                    >
                      {norma.last_tipo ? norma.last_tipo : "S/I"}
                    </span>
                  </td>
                  <td>
                    {norma.autoridad &&
                      norma.autoridad.length > 0 &&
                      norma.autoridad.map((org, x) => (
                        <span
                          key={x}
                          className="badge badge-soft-danger font-11 mb-1"
                        >
                          {org.nombre}
                        </span>
                      ))}
                  </td>
                  <td className="text-center">
                    {norma.fecha_promulgacion
                      ? norma.fecha_promulgacion
                      : "S/I"}
                  </td>
                  <td className="text-center">
                    {norma.fecha_publicacion ? norma.fecha_publicacion : "S/I"}
                  </td>
                  <td className="text-center">
                    {norma.fecha_vigencia ? norma.fecha_vigencia : "S/I"}
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      <Tooltip id={`norma_favorita_${x}`} aria-haspopup="true">
                        Agregar a Favoritos
                      </Tooltip>
                      <button
                        data-tip
                        data-tooltip-id={`norma_favorita_${x}`}
                        onClick={this.agregarFavoritaNorma.bind(
                          this,
                          norma.id,
                          norma.titulo
                        )}
                        className="btn btn-warning"
                        type="button"
                      >
                        <i className="fa fa-star"></i>
                      </button>
                      <Tooltip id={`norma_detalle_${x}`} aria-haspopup="true">
                        Ver detalle de la Norma
                      </Tooltip>
                      <Link
                        data-tip
                        data-tooltip-id={`norma_detalle_${x}`}
                        onClick={this.verDetalleNorma.bind(this, norma.id)}
                        to="/legal-tracking/norma"
                        className="btn btn-soft-dark"
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="9">
                  No existen normas
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarNorma: PropTypes.func.isRequired,
  agregarLeyUsuario: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  norma_favorita: PropTypes.object.isRequired,
  norma_favorita_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  norma_favorita: state.leyes.norma_favorita,
  norma_favorita_error: state.leyes.norma_favorita_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { guardarNorma, agregarLeyUsuario, limpiarMensajes },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tabla));
