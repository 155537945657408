import React, { Component, Fragment } from "react";

import { Tooltip } from "react-tooltip";
import SweetAlert from "sweetalert2";
import Responsable from "./responsable";

export class Ficha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responsable_tarea_mostrar: false,
    };
  }

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  cancelarResponsableTarea = () => {
    this.setState({
      responsable_tarea_mostrar: false,
    });
  };

  mostrarAsignarResponsableTarea = () => {
    const { tarea } = this.props;
    if (tarea.equipo.length > 1) {
      this.setState({
        responsable_tarea_mostrar: true,
      });
    } else {
      SweetAlert.fire({
        title: "Responsable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Para asignar un responsable de la tarea el equipo debe tener al menos 2 miembros del equipo",
        icon: "warning",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    }
  };

  render = () => {
    const { responsable_tarea_mostrar } = this.state;
    const { tarea } = this.props;
    const createMarkup = (htmlString) => ({ __html: htmlString });

    return (
      <>
        <div className="table-responsive">
          <table className="table bg-white">
            <tbody>
              <tr>
                <td className="table-secondary text-dark w-20">
                  Nombre del Proyecto
                </td>
                <td>
                  {tarea && tarea.proyecto && tarea.proyecto.nombre
                    ? tarea.proyecto.nombre
                    : "S/I"}
                </td>
              </tr>
              {tarea.nombre && (
                <tr>
                  <td className="table-secondary text-dark">
                    Nombre de la Tarea
                  </td>
                  <td>{tarea.nombre}</td>
                </tr>
              )}
              {tarea.estado_text !== "Finalizada" && (
                <tr>
                  <td className="table-secondary text-dark">Responsable</td>
                  <td>
                    {tarea.responsable ? (
                      tarea.responsable
                    ) : (
                      <button
                        onClick={this.mostrarAsignarResponsableTarea}
                        className="btn btn-warning btn-xs"
                      >
                        Asignar Responsable <i className="fa fa-user-plus"></i>
                      </button>
                    )}
                  </td>
                </tr>
              )}
              {tarea.usuario_text && (
                <tr>
                  <td className="table-secondary text-dark">
                    Originador de la Tarea
                  </td>
                  <td>{tarea.usuario_text}</td>
                </tr>
              )}
              {tarea.fecha_creacion && (
                <tr>
                  <td className="table-secondary text-dark">
                    Fecha de Creación
                  </td>
                  <td>{tarea.fecha_creacion}</td>
                </tr>
              )}
              <tr>
                <td className="table-secondary text-dark">Fecha de Entrega</td>
                <td>
                  {tarea.fecha_entrega}
                  {tarea.n_dias >= 0 ? (
                    <Fragment>
                      <Tooltip id={`tarea_${tarea.id}`} aria-haspopup="true">
                        Te quedan {tarea.n_dias}{" "}
                        {tarea.n_dias === 1 ? "día" : "días"} para completar la
                        tarea
                      </Tooltip>
                      <span
                        data-tip
                        data-tooltip-id={`tarea_${tarea.id}`}
                        className="text-success small ms-2"
                      >
                        (+{tarea.n_dias})
                      </span>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Tooltip id={`tarea_${tarea.id}`} aria-haspopup="true">
                        La tarea se encuentra atrasada en {tarea.n_dias}{" "}
                        {tarea.n_dias === 1 ? "DÍA" : "DÍAS"}
                      </Tooltip>
                      <span
                        data-tip
                        data-tooltip-id={`tarea_${tarea.id}`}
                        className="text-danger small ms-2"
                      >
                        ({tarea.n_dias})
                      </span>
                    </Fragment>
                  )}
                </td>
              </tr>
              {tarea && tarea.proyecto && tarea.proyecto.link && (
                <tr>
                  <td className="table-secondary text-dark">
                    Link del Proyecto
                  </td>
                  <td>
                    <a
                      rel="noopener noreferrer"
                      className="btn btn-primary btn-xs"
                      target="_blank"
                      href={tarea.proyecto.link}
                    >
                      Ver link <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                </tr>
              )}
              {tarea.link && (
                <tr>
                  <td className="table-secondary text-dark">
                    Link de la Tarea
                  </td>
                  <td>
                    <a
                      rel="noopener noreferrer"
                      className="btn btn-primary btn-xs"
                      target="_blank"
                      href={tarea.link}
                    >
                      Ver link <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                </tr>
              )}
              {tarea.descripcion && (
                <tr>
                  <td className="table-secondary text-dark">
                    Descripción de la Tarea
                  </td>
                  <td>{tarea.descripcion}</td>
                </tr>
              )}
              {tarea.estado_text && (
                <tr>
                  <td className="table-secondary text-dark">Estado</td>
                  <td>{tarea.estado_text}</td>
                </tr>
              )}
              {tarea.categoria_text && (
                <tr>
                  <td className="table-secondary text-dark">Categoría</td>
                  <td>{tarea.categoria_text}</td>
                </tr>
              )}
              {tarea.fecha_inicio && (
                <tr>
                  <td className="table-secondary text-dark">Fecha de Inicio</td>
                  <td>{tarea.fecha_inicio}</td>
                </tr>
              )}
              {tarea.fecha_termino && (
                <tr>
                  <td className="table-secondary text-dark">
                    Fecha de Término
                  </td>
                  <td>{tarea.fecha_termino}</td>
                </tr>
              )}
              {tarea.padre && (
                <tr>
                  <td className="table-secondary text-dark">
                    Tarea Dependiente
                  </td>
                  <td>
                    {tarea.padre.nombre} - {tarea.padre.estado_text}
                  </td>
                </tr>
              )}
              {tarea.parte && (
                <Fragment>
                  <tr>
                    <td className="table-secondary text-dark">
                      Norma Relacionada
                    </td>
                    <td>
                      {tarea.parte.ley.titulo}
                      <div className="btn-group float-end">
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={tarea.parte.ley.url_ley}
                          className="btn btn-light float-end btn-norma"
                        >
                          <i className="fa fa-link"></i>
                        </a>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={tarea.parte.ley.url_pdf}
                          className="btn btn-danger float-end btn-norma"
                        >
                          <i className="fa fa-file-pdf"></i>
                        </a>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={tarea.parte.ley.url_word}
                          className="btn btn-primary float-end btn-norma"
                        >
                          <i className="fa fa-file-word"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="table-secondary text-dark">
                      Artículo Relacionado
                    </td>
                    <td>
                      {tarea.parte.nombre}
                      <div className="btn-group float-end">
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={tarea.parte.url_parte}
                          className="btn btn-light float-end btn-norma"
                        >
                          <i className="fa fa-link"></i>
                        </a>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={tarea.parte.url_pdf}
                          className="btn btn-danger float-end btn-norma"
                        >
                          <i className="fa fa-file-pdf"></i>
                        </a>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={tarea.parte.url_word}
                          className="btn btn-primary float-end btn-norma"
                        >
                          <i className="fa fa-file-word"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              )}
              {tarea.permiso && (
                <tr>
                  <td className="table-secondary text-dark">Permiso</td>
                  <td>
                    <table className="table">
                      <tbody>
                        <tr>
                          <th className="table-secondary w-10 text-dark">
                            Código CL
                          </th>
                          <td>CL-{tarea.permiso.id}</td>
                        </tr>
                        <tr>
                          <th className="table-secondary text-dark">Nombre</th>
                          <td>{tarea.permiso.nombre}</td>
                        </tr>
                        <tr>
                          <th className="table-secondary text-dark">
                            Descripción
                          </th>
                          <td>
                            <div
                              dangerouslySetInnerHTML={createMarkup(
                                tarea.permiso.descripcion
                              )}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
              {tarea.consulta && (
                <tr>
                  <td className="table-secondary text-dark">Consulta</td>
                  <td>
                    <table className="table">
                      <tbody>
                        <tr>
                          <th className="table-secondary w-20 text-dark">
                            Fecha Inicio
                          </th>
                          <td>{tarea.consulta.fecha_inicio}</td>
                        </tr>
                        <tr>
                          <th className="table-secondary text-dark">
                            Fecha Término
                          </th>
                          <td>{tarea.consulta.fecha_termino}</td>
                        </tr>
                        <tr>
                          <th className="table-secondary text-dark">
                            Nombre del Proyecto
                          </th>
                          <td>{tarea.consulta.proyecto.nombre}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
              {tarea.inteligencia && (
                <tr>
                  <td className="table-secondary text-dark">
                    Proyecto de Inteligencia
                  </td>
                  <td>{tarea.inteligencia.nombre}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {responsable_tarea_mostrar && !tarea.responsable && (
          <div className="col-lg-4">
            <Responsable
              tareaSeleccionada={this.tareaSeleccionada}
              cancelarResponsableTarea={this.cancelarResponsableTarea}
            />
          </div>
        )}
      </>
    );
  };
}

export default Ficha;
