import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerEncargados } from "../../../redux/actions/vigilancias/encargados/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Filtros, Tabla } from "./";

export class Listado extends Component {
  componentDidMount = () => {
    const { obtenerEncargados, encargados } = this.props;
    if (Object.entries(encargados).length === 0) {
      obtenerEncargados(1);
    }
  };

  nuevaPagina = (pagina) => {
    const { obtenerEncargados } = this.props;
    obtenerEncargados(pagina);
  };

  render = () => {
    const { encargados, is_loading_vigilancias } = this.props;

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Encargados",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los encargados, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_encargados" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-user-edit"></i> ENCARGADOS DEL SEA
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5>
                      LISTADO DE ENCARGADOS{" "}
                      <small>({encargados.total ? encargados.total : 0})</small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={encargados}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla encargados={encargados} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerEncargados: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  encargados: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  encargados: state.vigilancias.encargados,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerEncargados }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
