import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import logo from "../../images/logo.png";
import {
  cambioContrasena,
  limpiarMensajes,
} from "../../redux/actions/auth/action";

export class RecuperarContrasena extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading_guardar: "",
    };
  }

  componentWillUnmount = () => {
    const { limpiarMensajes } = this.props;
    limpiarMensajes();
  };

  onClickRecuperarContrasena = (e) => {
    const { cambioContrasena } = this.props;
    const { email } = this.state;
    var loading = SweetAlert.fire({
      title: "Restablecer Contraseña",
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: "Validando la información ingresada, espere unos segundos",
      icon: "info",
      showConfirmButton: false,
      didOpen: () => {
        SweetAlert.showLoading();
      },
    });
    this.setState({
      loading_guardar: loading,
    });
    cambioContrasena(email);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { is_authenticated, errores_pass, guardar_pass, limpiarMensajes } =
      this.props;
    const { email, loading_guardar } = this.state;
    if (is_authenticated) {
      return <Navigate to="/user/inicio" />;
    }
    if (Object.entries(guardar_pass).length > 0) {
      limpiarMensajes();
      loading_guardar.close();
      SweetAlert.fire({
        title: "Restablecer contraseña",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: guardar_pass.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-thumbs-up"></i>',
        customClass: {
          confirmButton: "btn btn-primary me-3",
        },
        buttonsStyling: false,
      });
    }
    if (Object.entries(errores_pass).length > 0) {
      limpiarMensajes();
      loading_guardar.close();
      SweetAlert.fire({
        title: "Restablecer contraseña",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: errores_pass.data.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }

    return (
      <Fragment>
        <div className="account-body accountbg">
          <div className="row vh-100">
            <div className="col-lg-12 pe-0 align-self-center">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-6 col-8 mx-auto">
                  <div className="card auth-card">
                    <div className="card-body">
                      <div className="px-3">
                        <div className="text-center auth-logo-text">
                          <p>
                            <img
                              src={logo}
                              alt="logo"
                              className="auth-logo img-fluid"
                            />
                          </p>
                          <h5>RESTABLECER CONTRASEÑA</h5>
                          <h6>
                            Ingresa tu email y recibirás un correo a tu bandeja
                            de entrada para restablecer tu contraseña de tu
                            cuenta Cumplimiento Lean
                          </h6>
                        </div>
                        <form className="form-horizontal auth-form my-4">
                          <div className="form-group">
                            <label>
                              Email <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="email"
                                name="email"
                                placeholder="Ingresa tu email"
                                onChange={this.onChange}
                                value={email}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-0 row">
                            <div className="col-12 mt-2 d-grid gap-2">
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={this.onClickRecuperarContrasena}
                              >
                                Recuperar Contraseña{" "}
                                <i className="fas fa-unlock-alt ms-1"></i>
                              </button>
                            </div>
                          </div>
                          <div className="form-group row mt-4">
                            <div className="col-sm-12 text-center">
                              <Link to="/inicio-sesion">
                                <i className="fa fa-angle-left"></i> Volver a
                                iniciar sesión
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
}

RecuperarContrasena.propTypes = {
  cambioContrasena: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool,
  errores_pass: PropTypes.object,
  guardar_pass: PropTypes.object,
};

const mapStateToProps = (state) => ({
  is_authenticated: state.auth.is_authenticated,
  errores_pass: state.auth.errores_pass,
  guardar_pass: state.auth.guardar_pass,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ cambioContrasena, limpiarMensajes }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecuperarContrasena);
