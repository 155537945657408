import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  obtenerArchivoPermiso,
  obtenerPermiso,
  setPermisoTarea,
} from "../../../redux/actions/permitting/action";
import { Footer, Navbar, SiderbarPermittingAplicabilidad } from "../../general";
import { FichaPermiso, FichaProyecto } from "../general";

export class Permiso extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.permisoSeleccionado();
  };

  nuevaTarea = (permiso) => {
    const { setPermisoTarea } = this.props;
    setPermisoTarea(permiso);
  };

  permisoSeleccionado = () => {
    const { obtenerPermiso, permiso_seleccionado, consulta_seleccionada } =
      this.props;
    if (permiso_seleccionado && consulta_seleccionada) {
      obtenerPermiso({
        permiso: permiso_seleccionado,
        consulta: consulta_seleccionada,
      });
    }
  };

  verArchivo = (archivo, permiso) => {
    const { obtenerArchivoPermiso, consulta_seleccionada, is_externo } =
      this.props;
    if (!is_externo) {
      SweetAlert.fire({
        title: "Restriccion de Acceso",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "No puede ver el archivo seleccionado",
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    } else {
      obtenerArchivoPermiso({
        consulta: consulta_seleccionada,
        archivo: archivo,
        permiso: permiso,
      });
    }
  };

  render = () => {
    const {
      permiso,
      is_loading_permitting,
      permiso_seleccionado,
      is_externo,
      consulta_seleccionada,
      archivo_permiso_error,
      permiso_url,
      user,
    } = this.props;

    if (permiso_seleccionado === 0) {
      return <Navigate to="/permitting-target/aplicabilidad/permisos" />;
    } else if (consulta_seleccionada === 0) {
      return <Navigate to="/permitting-target/proyectos" />;
    }

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Permiso",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo el permiso, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (
      Object.entries(archivo_permiso_error).length > 0 &&
      archivo_permiso_error.message
    ) {
      SweetAlert.fire({
        title: "Descarga Archivo",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: archivo_permiso_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SiderbarPermittingAplicabilidad menu_1="permitting_permisos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h4>REPORTE DE APLICABILIDAD</h4>
                  </div>
                  <div className="card-body">
                    <ol className="breadcrumb mt-0 mb-0 font-14">
                      <li className="breadcrumb-item">
                        <Link to="/permitting-target/proyectos">Proyecto</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/permitting-target/aplicabilidad/permisos">
                          Plan maestro de permisos
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Permiso</li>
                    </ol>
                    <FichaProyecto />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h5>ETAPA ACTUAL DEL PROCESO DE LA CONSULTA</h5>
                  </div>
                  <div className="card-body">
                    <ol className="c-progress-steps">
                      <li className="c-progress-steps__step">
                        <span>1. Parametrización de la consulta</span>
                      </li>
                      <li className="c-progress-steps__step current">
                        <span>2. Reporte de aplicabilidad</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 text-end mb-2">
                <div className="btn-group">
                  <Link
                    to={
                      permiso_url
                        ? permiso_url
                        : "/permitting-target/aplicabilidad/permisos"
                    }
                    className="btn btn-primary"
                  >
                    <i className="fa fa-angle-left"></i> Volver al Listado
                  </Link>
                  {user && user.modulos && user.modulos.tasks && (
                    <>
                      <Link
                        data-tip
                        data-tooltip-id="nueva_tarea"
                        to="/permitting-target/aplicabilidad/permiso/tarea"
                        onClick={this.nuevaTarea.bind(this, permiso)}
                        type="button"
                        className="btn btn-warning float-end"
                      >
                        Crea una Tarea <i className="fa fa-plus"></i>
                      </Link>
                      <Tooltip id="nueva_tarea" aria-haspopup="true">
                        Crear una tarea del permiso y gestiona con QUALITY
                        TASKING
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
              <FichaPermiso
                permiso={permiso}
                verArchivo={this.verArchivo}
                ver_pas={true}
                is_externo={is_externo}
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Permiso.propTypes = {
  obtenerPermiso: PropTypes.func.isRequired,
  obtenerArchivoPermiso: PropTypes.func.isRequired,
  setPermisoTarea: PropTypes.func.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  permiso: PropTypes.object.isRequired,
  permiso_seleccionado: PropTypes.number.isRequired,
  consulta_seleccionada: PropTypes.number.isRequired,
  permiso_url: PropTypes.string.isRequired,
  archivo_permiso_error: PropTypes.object.isRequired,
  is_externo: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_permitting: state.permitting.is_loading_permitting,
  permiso: state.permitting.permiso,
  permiso_seleccionado: state.permitting.permiso_seleccionado,
  consulta_seleccionada: state.permitting.consulta_seleccionada,
  permiso_url: state.permitting.permiso_url,
  archivo_permiso_error: state.permitting.archivo_permiso_error,
  is_externo: state.permitting.is_externo,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerPermiso, obtenerArchivoPermiso, setPermisoTarea },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Permiso);
