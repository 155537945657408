export const PROGRAMAS_OBTENER = 'PROGRAMAS_OBTENER';
export const PROGRAMAS_FALLIDO = 'PROGRAMAS_FALLIDO';
export const PROGRAMA_GUARDAR = 'PROGRAMA_GUARDAR';
export const PROGRAMA_OBTENER = 'PROGRAMA_OBTENER';
export const PROGRAMA_FALLIDO = 'PROGRAMA_FALLIDO';
export const PROGRAMAS_FILTROS_OBTENER = 'PROGRAMAS_FILTROS_OBTENER';
export const PROGRAMAS_FILTROS_FALLIDO = 'PROGRAMAS_FILTROS_FALLIDO';
export const PROGRAMAS_FILTROS_INPUT = 'PROGRAMAS_FILTROS_INPUT';
export const LIMPIAR_PROGRAMA_FILTROS = 'LIMPIAR_PROGRAMA_FILTROS';
export const PROGRAMAS_ESTADISTICAS_OBTENER = 'PROGRAMAS_ESTADISTICAS_OBTENER';
export const PROGRAMAS_ESTADISTICAS_FALLIDO = 'PROGRAMAS_ESTADISTICAS_FALLIDO';
