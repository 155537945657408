import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import { guardarPrograma } from "../../../redux/actions/vigilancias/programas/action";
import { Eliminar as EliminarFavorito } from "../favoritos";
import { TablaPequena as Titulares } from "../titulares";

export class Tabla extends Component {
  setPrograma = (id) => {
    const { guardarPrograma } = this.props;
    guardarPrograma(id);
  };

  render = () => {
    const { programas, eliminar_favorito } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th className="w-10">Expediente</th>
              <th>Titular</th>
              <th className="w-13">Tipología</th>
              <th className="w-14">Región</th>
              <th className="w-24">Estado</th>
              <th className="text-center w-5">Acción</th>
            </tr>
          </thead>
          <tbody>
            {programas && programas.result && programas.result.length > 0 ? (
              programas.result.map((programa, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {programas.page > 1
                      ? (programas.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td>
                    <a
                      href={programa.url_snifa}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {programa.expediente}
                      <i className="ms-1 fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td>
                    <Titulares titulares={programa.titulares} />
                  </td>
                  <td>{programa.categoria}</td>
                  <td>
                    <span key={x} className="badge badge-primary font-11 m-1">
                      {programa.region_display}
                    </span>
                  </td>
                  <td>
                    <span className="badge badge-dark font-13 text-center">
                      {programa.estado_display
                        ? programa.estado_display
                        : "S/I"}
                    </span>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {eliminar_favorito && (
                        <EliminarFavorito
                          tipo="programa_cumplimiento"
                          id={programa.id}
                        />
                      )}
                      <Link
                        to="/compliance-analytics/programa"
                        className="btn btn-soft-dark"
                        onClick={this.setPrograma.bind(this, programa.id)}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="9">
                  No existen programas de cumplimiento
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarPrograma: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarPrograma }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
