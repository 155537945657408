import { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { limpiarMensajes } from "../../../redux/actions/tareas/action";
import {
  editarProyecto,
  obtenerProyectos,
} from "../../../redux/actions/tareas/proyectos/action";

export class Editar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proyecto_nombre:
        this.props.proyecto && this.props.proyecto.nombre
          ? this.props.proyecto.nombre
          : "",
      proyecto_descripcion:
        this.props.proyecto && this.props.proyecto.descripcion
          ? this.props.proyecto.descripcion
          : "",
      proyecto_link:
        this.props.proyecto && this.props.proyecto.link
          ? this.props.proyecto.link
          : "",
      proyecto_id:
        this.props.proyecto && this.props.proyecto.id
          ? this.props.proyecto.id
          : "",
    };
  }

  edicionProyecto = () => {
    const {
      proyecto_nombre,
      proyecto_descripcion,
      proyecto_link,
      proyecto_id,
    } = this.state;
    const { editarProyecto } = this.props;
    editarProyecto({
      id_proyecto: proyecto_id,
      nombre: proyecto_nombre,
      link: proyecto_link ? proyecto_link : null,
      descripcion: proyecto_descripcion ? proyecto_descripcion : null,
    });
    SweetAlert.fire({
      title: "Edición proyecto",
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: "Estamos validando la información, espere unos segundos",
      icon: "warning",
      showConfirmButton: false,
      didOpen: () => {
        SweetAlert.showLoading();
      },
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  cancelarNuevoProyecto = () => {
    const { cancelarNuevoProyecto, btnCancelar } = this.props;
    if (btnCancelar) {
      cancelarNuevoProyecto();
    }
  };

  render = () => {
    const { proyecto_link, proyecto_nombre, proyecto_descripcion } = this.state;
    const {
      limpiarMensajes,
      obtenerProyectos,
      proyecto_editar_error,
      proyecto_editar,
    } = this.props;

    if (Object.entries(proyecto_editar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Proyecto editado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: proyecto_editar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerProyectos();
          this.cancelarNuevoProyecto();
          limpiarMensajes();
        }
      });
    }

    if (
      proyecto_editar_error &&
      Object.entries(proyecto_editar_error).length > 0 &&
      proyecto_editar_error.data &&
      proyecto_editar_error.data.message
    ) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Proyecto no editado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: proyecto_editar_error.data.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
        }
      });
    }

    return (
      <div className="col-lg-12 mb-4">
        <div className="card">
          <div className="card-header bg-primary text-white">
            <h5>EDITAR PROYECTO</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 text-end">
                <p className="text-danger">
                  <i className="fa fa-asterisk"></i> datos obligatorios
                </p>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Nombre <small className="text-danger">*</small>
                  </label>
                  <input
                    type="text"
                    name="proyecto_nombre"
                    onChange={this.onChange}
                    value={proyecto_nombre}
                    placeholder="Ingrese un nombre"
                    className={
                      proyecto_editar_error &&
                      proyecto_editar_error.data &&
                      proyecto_editar_error.data.nombre
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {proyecto_editar_error &&
                    proyecto_editar_error.data &&
                    proyecto_editar_error.data.nombre &&
                    proyecto_editar_error.data.nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
                <div className="form-group">
                  <label>Link</label>
                  <input
                    type="text"
                    name="proyecto_link"
                    onChange={this.onChange}
                    value={proyecto_link}
                    placeholder="Ingrese un link o url"
                    className={
                      proyecto_editar_error &&
                      proyecto_editar_error.data &&
                      proyecto_editar_error.data.link
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {proyecto_editar_error &&
                    proyecto_editar_error.data &&
                    proyecto_editar_error.data.link &&
                    proyecto_editar_error.data.link.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Descripción</label>
                  <textarea
                    rows="5"
                    name="proyecto_descripcion"
                    onChange={this.onChange}
                    value={proyecto_descripcion}
                    placeholder="Ingrese una descripción"
                    className={
                      proyecto_editar_error &&
                      proyecto_editar_error.data &&
                      proyecto_editar_error.data.descripcion
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  ></textarea>
                  {proyecto_editar_error &&
                    proyecto_editar_error.data &&
                    proyecto_editar_error.data.descripcion &&
                    proyecto_editar_error.data.descripcion.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-sm-12 text-end">
                <div className="btn-group">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.edicionProyecto}
                  >
                    Editar Proyecto <i className="fa fa-save"></i>
                  </button>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={this.cancelarNuevoProyecto}
                  >
                    Cancelar <i className="fa fa-times-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

Editar.propTypes = {
  editarProyecto: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  obtenerProyectos: PropTypes.func.isRequired,
  proyecto_editar: PropTypes.object.isRequired,
  proyecto_editar_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  proyecto_editar: state.tareas.proyecto_editar,
  proyecto_editar_error: state.tareas.proyecto_editar_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { editarProyecto, limpiarMensajes, obtenerProyectos },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Editar);
