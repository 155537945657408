import React, { Component } from "react";

import { Link } from "react-router-dom";

export class Inicio extends Component {
  render = () => {
    const { menu_1, menu_2, vigilances, sub_vigilances } = this.props;
    return (
      vigilances && (
        <>
          <h5 className="mt-3">COMPLIANCE ANALYTICS</h5>
          <li className="mm-active-link">
            <a
              href="https://web.cumplimientolean.com/software-compliance-analytics"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-laptop"></i>
              <span> Centro de Descubrimiento</span>
            </a>
          </li>
          <li className="mm-active-link">
            <a
              href="https://cumplimientolean.notion.site/Tutoriales-Software-Compliance-Analytics-3bd3b6c1232e42c587bcc81bb77f2196?pvs=4 "
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-video"></i>
              <span> Tutoriales</span>
            </a>
          </li>
          {sub_vigilances.alertas && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_alertas"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link to="/compliance-analytics/alertas">
                <i className="fa fa-tower-broadcast"></i>
                <span> Alertas</span>
              </Link>
            </li>
          )}
          <li
            className={
              menu_1 && menu_1 === "vigilancias_favoritos"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/compliance-analytics/favoritos">
              <i className="fa fa-heart"></i>
              <span> Mis Favoritos</span>
            </Link>
          </li>
          {sub_vigilances.seia && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_expedientes"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExpedientes"
                aria-expanded={
                  menu_1 && menu_1 === "vigilancias_expedientes"
                    ? "true"
                    : "false"
                }
                aria-controls="collapseExpedientes"
              >
                <i className="fa fa-seedling"></i>
                <span className="ms-1">
                  Expedientes SEIA{" "}
                  <i
                    className={`fa fa-angle-${
                      menu_1 && menu_1 === "vigilancias_expedientes"
                        ? "down"
                        : "right"
                    } mt-1 float-end`}
                  ></i>
                </span>
              </Link>
              <ul
                className={`nav flex-column collapse ${
                  menu_1 && menu_1 === "vigilancias_expedientes" && "show"
                }`}
                id="collapseExpedientes"
              >
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/expedientes/listado"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_expedientes" &&
                      menu_2 &&
                      menu_2 === "listado" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Listado
                  </Link>
                </li>
                {sub_vigilances.seia_folios && (
                  <li>
                    <Link
                      to="/compliance-analytics/expedientes/documentos"
                      className={`nav-link ${
                        menu_1 &&
                        menu_1 === "vigilancias_expedientes" &&
                        menu_2 &&
                        menu_2 === "documentos" &&
                        "text-danger"
                      }`}
                    >
                      <i className="fa fa-minus"></i> Documentos
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/expedientes/mapa"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_expedientes" &&
                      menu_2 &&
                      menu_2 === "mapa" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Mapa
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/expedientes/estadistica"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_expedientes" &&
                      menu_2 &&
                      menu_2 === "estadistica" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Estadísticas
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {sub_vigilances.pertinencias && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_pertinencias"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapsePertinencias"
                aria-expanded={
                  menu_1 && menu_1 === "vigilancias_pertinencias"
                    ? "true"
                    : "false"
                }
                aria-controls="collapsePertinencias"
              >
                <i className="fa fa-calendar-check"></i>
                <span className="ms-1">
                  Consultas de Pertinencias{" "}
                  <i
                    className={`fa fa-angle-${
                      menu_1 && menu_1 === "vigilancias_pertinencias"
                        ? "down"
                        : "right"
                    } mt-1 float-end`}
                  ></i>
                </span>
              </Link>
              <ul
                className={`nav flex-column collapse ${
                  menu_1 && menu_1 === "vigilancias_pertinencias" && "show"
                }`}
                id="collapsePertinencias"
              >
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/pertinencias/listado"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_pertinencias" &&
                      menu_2 &&
                      menu_2 === "listado" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Listado
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/pertinencias/estadistica"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_pertinencias" &&
                      menu_2 &&
                      menu_2 === "estadistica" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Estadísticas
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {sub_vigilances.procedimientos && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_procedimientos"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseProcedimientos"
                aria-expanded={
                  menu_1 && menu_1 === "vigilancias_procedimientos"
                    ? "true"
                    : "false"
                }
                aria-controls="collapseProcedimientos"
              >
                <i className="fa fa-bullhorn"></i>
                <span className="ms-1">
                  Procedimientos Sancionatorios{" "}
                  <i
                    className={`fa fa-angle-${
                      menu_1 && menu_1 === "vigilancias_procedimientos"
                        ? "down"
                        : "right"
                    } mt-1 float-end`}
                  ></i>
                </span>
              </Link>
              <ul
                className={`nav flex-column collapse ${
                  menu_1 && menu_1 === "vigilancias_procedimientos" && "show"
                }`}
                id="collapseProcedimientos"
              >
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/procedimientos/listado"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_procedimientos" &&
                      menu_2 &&
                      menu_2 === "listado" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Listado
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/procedimientos/estadistica"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_procedimientos" &&
                      menu_2 &&
                      menu_2 === "estadistica" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Estadísticas
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {sub_vigilances.hechos && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_hechos"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseHechosInfraccionales"
                aria-expanded={
                  menu_1 && menu_1 === "vigilancias_hechos" ? "true" : "false"
                }
                aria-controls="collapseHechosInfraccionales"
              >
                <i className="fa fa-university"></i>
                <span className="ms-1">
                  Hechos Infraccionales{" "}
                  <i
                    className={`fa fa-angle-${
                      menu_1 && menu_1 === "vigilancias_hechos"
                        ? "down"
                        : "right"
                    } mt-1 float-end`}
                  ></i>
                </span>
              </Link>
              <ul
                className={`nav flex-column collapse ${
                  menu_1 && menu_1 === "vigilancias_hechos" && "show"
                }`}
                id="collapseHechosInfraccionales"
              >
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/hechos-infraccionales/listado"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_hechos" &&
                      menu_2 &&
                      menu_2 === "listado" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Listado
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/hechos-infraccionales/estadistica"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_hechos" &&
                      menu_2 &&
                      menu_2 === "estadistica" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Estadísticas
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {sub_vigilances.programas && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_programas"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseProgramasCumplimiento"
                aria-expanded={
                  menu_1 && menu_1 === "vigilancias_programas"
                    ? "true"
                    : "false"
                }
                aria-controls="collapseProgramasCumplimiento"
              >
                <i className="fa fa-handshake"></i>
                <span className="ms-1">
                  Programas de Cumplimiento{" "}
                  <i
                    className={`fa fa-angle-${
                      menu_1 && menu_1 === "vigilancias_programas"
                        ? "down"
                        : "right"
                    } mt-1 float-end`}
                  ></i>
                </span>
              </Link>
              <ul
                className={`nav flex-column collapse ${
                  menu_1 && menu_1 === "vigilancias_programas" && "show"
                }`}
                id="collapseProgramasCumplimiento"
              >
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/programas/listado"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_programas" &&
                      menu_2 &&
                      menu_2 === "listado" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Listado
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/programas/estadistica"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_programas" &&
                      menu_2 &&
                      menu_2 === "estadistica" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Estadísticas
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {sub_vigilances.medidas && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_medidas"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseMedidas"
                aria-expanded={
                  menu_1 && menu_1 === "vigilancias_medidas" ? "true" : "false"
                }
                aria-controls="collapseMedidas"
              >
                <i className="fa fa-cogs"></i>
                <span className="ms-1">
                  Medidas Provisionales{" "}
                  <i
                    className={`fa fa-angle-${
                      menu_1 && menu_1 === "vigilancias_medidas"
                        ? "down"
                        : "right"
                    } mt-1 float-end`}
                  ></i>
                </span>
              </Link>
              <ul
                className={`nav flex-column collapse ${
                  menu_1 && menu_1 === "vigilancias_medidas" && "show"
                }`}
                id="collapseMedidas"
              >
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/medidas-provisionales/listado"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_medidas" &&
                      menu_2 &&
                      menu_2 === "listado" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Listado
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/medidas-provisionales/estadistica"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_medidas" &&
                      menu_2 &&
                      menu_2 === "estadistica" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Estadísticas
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {sub_vigilances.seguimientos_ambiental && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_seguimientos_ambiental"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeguimientoAmbiental"
                aria-expanded={
                  menu_1 && menu_1 === "vigilancias_seguimientos_ambiental"
                    ? "true"
                    : "false"
                }
                aria-controls="collapseSeguimientoAmbiental"
              >
                <i className="fa-solid fa-calendar-days"></i>
                <span className="ms-1">
                  Seguimientos Ambientales{" "}
                  <i
                    className={`fa fa-angle-${
                      menu_1 && menu_1 === "vigilancias_seguimientos_ambiental"
                        ? "down"
                        : "right"
                    } mt-1 float-end`}
                  ></i>
                </span>
              </Link>
              <ul
                className={`nav flex-column collapse ${
                  menu_1 &&
                  menu_1 === "vigilancias_seguimientos_ambiental" &&
                  "show"
                }`}
                id="collapseSeguimientoAmbiental"
              >
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/seguimientos-ambiental/listado"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_seguimientos_ambiental" &&
                      menu_2 &&
                      menu_2 === "listado" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Listado
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {sub_vigilances.procedimientos && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_sanciones"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSanciones"
                aria-expanded={
                  menu_1 && menu_1 === "vigilancias_sanciones"
                    ? "true"
                    : "false"
                }
                aria-controls="collapseSanciones"
              >
                <i className="fa fa-exclamation-triangle"></i>
                <span className="ms-1">
                  Registro de Sanciones{" "}
                  <i
                    className={`fa fa-angle-${
                      menu_1 && menu_1 === "vigilancias_sanciones"
                        ? "down"
                        : "right"
                    } mt-1 float-end`}
                  ></i>
                </span>
              </Link>
              <ul
                className={`nav flex-column collapse ${
                  menu_1 && menu_1 === "vigilancias_sanciones" && "show"
                }`}
                id="collapseSanciones"
              >
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/sanciones/listado"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_sanciones" &&
                      menu_2 &&
                      menu_2 === "listado" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Listado
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/sanciones/estadistica"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_sanciones" &&
                      menu_2 &&
                      menu_2 === "estadistica" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Estadísticas
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {sub_vigilances.instrumentos_infringidos && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_instrumentos_infringidos"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link to="/compliance-analytics/instrumentos-infringidos">
                <i className="fa fa-tools"></i>
                <span> Instrumentos Infringidos</span>
              </Link>
            </li>
          )}
          {sub_vigilances.fiscalizaciones && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_fiscalizaciones"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFiscalizaciones"
                aria-expanded={
                  menu_1 && menu_1 === "vigilancias_fiscalizaciones"
                    ? "true"
                    : "false"
                }
                aria-controls="collapseFiscalizaciones"
              >
                <i className="fa fa-binoculars"></i>
                <span className="ms-1">
                  Fiscalizaciones{" "}
                  <i
                    className={`fa fa-angle-${
                      menu_1 && menu_1 === "vigilancias_fiscalizaciones"
                        ? "down"
                        : "right"
                    } mt-1 float-end`}
                  ></i>
                </span>
              </Link>
              <ul
                className={`nav flex-column collapse ${
                  menu_1 && menu_1 === "vigilancias_fiscalizaciones" && "show"
                }`}
                id="collapseFiscalizaciones"
              >
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/fiscalizaciones/listado"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_fiscalizaciones" &&
                      menu_2 &&
                      menu_2 === "listado" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Listado
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/fiscalizaciones/estadistica"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_fiscalizaciones" &&
                      menu_2 &&
                      menu_2 === "estadistica" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Estadísticas
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {sub_vigilances.documentos && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_documentos"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link to="/compliance-analytics/documentos">
                <i className="fa fa-file-archive"></i>
                <span> Documentos</span>
              </Link>
            </li>
          )}
          {sub_vigilances.requerimientos && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_requerimientos_ingreso"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link to="/compliance-analytics/requerimientos-ingreso">
                <i className="fa fa-arrow-alt-circle-right"></i>
                <span> Requerimientos de Ingreso</span>
              </Link>
            </li>
          )}
          {sub_vigilances.rcas && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_rcas"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseRcas"
                aria-expanded={
                  menu_1 && menu_1 === "vigilancias_rcas" ? "true" : "false"
                }
                aria-controls="collapseRcas"
              >
                <i className="fa fa-calendar-check"></i>
                <span className="ms-1">
                  RCA{" "}
                  <i
                    className={`fa fa-angle-${
                      menu_1 && menu_1 === "vigilancias_rcas" ? "down" : "right"
                    } mt-1 float-end`}
                  ></i>
                </span>
              </Link>
              <ul
                className={`nav flex-column collapse ${
                  menu_1 && menu_1 === "vigilancias_rcas" && "show"
                }`}
                id="collapseRcas"
              >
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/rcas/listado"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_rcas" &&
                      menu_2 &&
                      menu_2 === "listado" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Listado
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/rcas/estadistica"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_rcas" &&
                      menu_2 &&
                      menu_2 === "estadistica" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Estadísticas
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {sub_vigilances.unidades && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_unidades"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                to="#"
                data-bs-toggle="collapse"
                data-bs-target="#collapseUnidadesFiscalizables"
                aria-expanded={
                  menu_1 && menu_1 === "vigilancias_unidades" ? "true" : "false"
                }
                aria-controls="collapseUnidadesFiscalizables"
              >
                <i className="fa fa-house-user"></i>
                <span className="ms-1">
                  Unidades Fiscalizables{" "}
                  <i
                    className={`fa fa-angle-${
                      menu_1 && menu_1 === "vigilancias_unidades"
                        ? "down"
                        : "right"
                    } mt-1 float-end`}
                  ></i>
                </span>
              </Link>
              <ul
                className={`nav flex-column collapse ${
                  menu_1 && menu_1 === "vigilancias_unidades" && "show"
                }`}
                id="collapseUnidadesFiscalizables"
              >
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/unidades/listado"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_unidades" &&
                      menu_2 &&
                      menu_2 === "listado" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Listado
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/compliance-analytics/unidades/estadistica"
                    className={`nav-link ${
                      menu_1 &&
                      menu_1 === "vigilancias_unidades" &&
                      menu_2 &&
                      menu_2 === "estadistica" &&
                      "text-danger"
                    }`}
                  >
                    <i className="fa fa-minus"></i> Estadísticas
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {sub_vigilances.titulares && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_titulares"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link to="/compliance-analytics/titulares">
                <i className="fa fa-user-tie"></i>
                <span> Titulares de Proyectos</span>
              </Link>
            </li>
          )}
          {sub_vigilances.encargados && (
            <li
              className={
                menu_1 && menu_1 === "vigilancias_encargados"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link to="/compliance-analytics/encargados">
                <i className="fa fa-user-edit"></i>
                <span> Encargados del SEA</span>
              </Link>
            </li>
          )}
        </>
      )
    );
  };
}

export default Inicio;
