import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import slugify from "slugify";
import SweetAlert from "sweetalert2";

import {
  obtenerSecciones,
  seccionSeleccionada,
} from "../../../redux/actions/permitting/action";
import {
  Footer,
  Navbar,
  SiderbarPermittingParametrizacion,
} from "../../general";
import { withRouter } from "../../general/with_router";
import { FichaProyecto } from "../general";

export class Secciones extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { obtenerSecciones, consulta_seleccionada } = this.props;
    if (consulta_seleccionada > 0) {
      obtenerSecciones({
        consulta: consulta_seleccionada,
      });
    }
  };

  verDetalleSeccion = (id, nombre) => {
    const { seccionSeleccionada } = this.props;
    seccionSeleccionada({
      id: id,
      nombre: nombre,
    });
  };

  render = () => {
    const {
      secciones,
      secciones_error,
      is_loading_permitting,
      consulta_seleccionada,
    } = this.props;

    if (consulta_seleccionada === 0) {
      return <Navigate to="/permitting-target/proyectos" />;
    }

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Parametrización",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las secciones, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (Object.entries(secciones_error).length > 0 && secciones_error.message) {
      SweetAlert.fire({
        title: "Parametrización",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: secciones_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        this.props.navigate("/permitting-target/proyectos");
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SiderbarPermittingParametrizacion menu_1="permitting_parametrizacion" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h4>PARAMETRIZACIÓN DE LA CONSULTA</h4>
                  </div>
                  <div className="card-body">
                    <ol className="breadcrumb mt-0 mb-0 font-14">
                      <li className="breadcrumb-item">
                        <Link to="/permitting-target/proyectos">Proyectos</Link>
                      </li>
                      <li className="breadcrumb-item active">Secciones</li>
                    </ol>
                    <h5 className="mt-0 mb-1">
                      {secciones ? secciones.length : 0} Secciones
                    </h5>
                    <FichaProyecto />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h5>ETAPA ACTUAL DEL PROCESO DE LA CONSULTA</h5>
                  </div>
                  <div className="card-body">
                    <ol className="c-progress-steps">
                      <li className="c-progress-steps__step current">
                        <span>1. Parametrización de la consulta</span>
                      </li>
                      <li className="c-progress-steps__step">
                        <span>2. Reporte de aplicabilidad</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              {secciones &&
                secciones.map((seccion, x) => (
                  <div className="col-lg-12 col-sm-6" key={x}>
                    <div className="card card-eco">
                      <div className="row">
                        <div className="col-lg-2 align-self-center">
                          <div
                            className={`ml--4 mr--4 img-parametrizacion ${slugify(
                              seccion.nombre,
                              { lower: true }
                            )}`}
                          ></div>
                        </div>
                        <div className="col-lg-3 align-self-center">
                          <p className="mb-0 font-11">Sección {x + 1}</p>
                          <h5 className="mt-0">
                            <i
                              className="fa fa-exclamation-circle text-warning font-10"
                              data-tip
                              data-tooltip-id={`seccion_${seccion.id}`}
                            ></i>{" "}
                            {seccion.nombre}
                          </h5>
                          <Tooltip
                            className="w-45 text-start"
                            id={`seccion_${seccion.id}`}
                            aria-haspopup="true"
                          >
                            {seccion.descripcion}
                          </Tooltip>
                        </div>
                        <div className="col-lg-2 align-self-center text-center font-14">
                          {seccion.estado && seccion.estado.estado ? (
                            <span className="badge badge-soft-success px-1">
                              <i className="fa fa-check-double"></i> Preguntas
                              contestadas
                            </span>
                          ) : (
                            <span className="badge badge-soft-danger px-1">
                              <i className="fa fa-times"></i> Preguntas no
                              contestadas
                            </span>
                          )}
                        </div>
                        <div className="col-lg-2 align-self-center text-center">
                          <p className="mb-0">
                            <i className="fa fa-list"></i> {seccion.n_preguntas}{" "}
                            {seccion.n_preguntas > 1 ? "Preguntas" : "Pregunta"}{" "}
                            <i
                              className="fa fa-exclamation-circle text-warning font-10"
                              data-tip
                              data-tooltip-id={`preguntas_total_${seccion.id}`}
                            ></i>
                          </p>
                          <Tooltip
                            className="w-20 text-start"
                            id={`preguntas_total_${seccion.id}`}
                            aria-haspopup="true"
                          >
                            Total de preguntas para contestar para la categoría{" "}
                            {seccion.nombre}
                          </Tooltip>
                        </div>
                        <div className="col-lg-1 align-self-center text-end">
                          <p className="mb-0">
                            {seccion.estado.respuestas_porcentaje}%{" "}
                            <i
                              className="fa fa-exclamation-circle text-warning font-10"
                              data-tip
                              data-tooltip-id={`preguntas_porcentaje_${seccion.id}`}
                            ></i>
                          </p>
                          <Tooltip
                            className="w-20 text-start"
                            id={`preguntas_porcentaje_${seccion.id}`}
                            aria-haspopup="true"
                          >
                            Porcentaje de preguntas contestadas para la
                            categoría {seccion.nombre}
                          </Tooltip>
                        </div>
                        <div className="col-lg-2 align-self-center text-center mb-1">
                          <Link
                            onClick={this.verDetalleSeccion.bind(
                              this,
                              seccion.id,
                              seccion.nombre
                            )}
                            to="/permitting-target/parametrizacion/categorias"
                            className="btn-xs btn btn-dark"
                          >
                            <i className="fa fa-list"></i> Ver preguntas{" "}
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Secciones.propTypes = {
  obtenerSecciones: PropTypes.func.isRequired,
  seccionSeleccionada: PropTypes.func.isRequired,
  secciones_error: PropTypes.object.isRequired,
  secciones: PropTypes.array.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  consulta_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  secciones_error: state.permitting.secciones_error,
  secciones: state.permitting.secciones,
  is_loading_permitting: state.permitting.is_loading_permitting,
  consulta_seleccionada: state.permitting.consulta_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerSecciones, seccionSeleccionada },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Secciones)
);
