import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerMedidaProvisional } from "../../../redux/actions/vigilancias/medidas/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Guardar as GuardarFavorito } from "../favoritos/";
import { Tabs } from "./";

export class Medida extends Component {
  componentDidMount = () => {
    const { obtenerMedidaProvisional, medida_provisional_id } = this.props;
    if (medida_provisional_id > 0) {
      obtenerMedidaProvisional({
        id: medida_provisional_id,
      });
    }
  };

  render = () => {
    const {
      is_loading_vigilancias,
      medida_provisional_id,
      medida_provisional_componente,
      medida_provisional,
    } = this.props;

    const redirect =
      medida_provisional_componente === "normal"
        ? "/compliance-analytics/medidas-provisionales/listado"
        : "/teams/medidas";
    const menu1 =
      medida_provisional_componente === "normal"
        ? "vigilancias_medidas"
        : "teams";
    const menu2 =
      medida_provisional_componente === "normal" ? "listado" : "medidas";

    if (medida_provisional_id === 0) {
      return <Navigate to={redirect} />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Medida Provisional",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo la medida provisional, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1={menu1} menu_2={menu2} />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-cogs"></i> MEDIDAS PROVISIONALES
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      Expediente{" "}
                      {medida_provisional && medida_provisional.expediente}
                    </h5>
                    <div className="float-end btn-group">
                      <GuardarFavorito
                        tipo="medida_provisional"
                        id={medida_provisional.id}
                      />
                      <Link className="btn btn-primary" to={redirect}>
                        <i className="fa fa-angle-left"></i> Volver al listado
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    {medida_provisional && (
                      <Tabs medidas_provisionales={[medida_provisional]} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Medida.propTypes = {
  obtenerMedidaProvisional: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  medida_provisional: PropTypes.object.isRequired,
  medida_provisional_id: PropTypes.number.isRequired,
  medida_provisional_componente: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  medida_provisional: state.vigilancias.medida_provisional,
  medida_provisional_id: state.vigilancias.medida_provisional_id,
  medida_provisional_componente:
    state.vigilancias.medida_provisional_componente,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerMedidaProvisional }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Medida);
