import React, { Component } from "react";

import { Footer, Navbar, SidebarInicio } from "../general";

export class Inicio extends Component {
  render = () => {
    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="inicio" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-home"></i> INICIO
                  </h4>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body mb-4">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-lg-6">
                        <p>
                          ¡Hola! 👋 Soy Pablo Soto Bevilacqua, CEO y fundador de
                          Cumplimiento Lean. Junto con nuestro equipo, te damos
                          una calurosa bienvenida a nuestra plataforma.
                        </p>
                        <p>
                          Queremos que te sientas como en casa desde el primer
                          día, así que hemos preparado algunas herramientas y
                          recursos para ayudarte a aprovechar al máximo nuestra
                          tecnología:
                        </p>
                        <ul>
                          <li>
                            Echa un vistazo al video “Navegación en Cumplimiento
                            Lean” donde te explicamos las diferentes vistas y
                            espacios de trabajo de la plataforma.
                          </li>
                          <li>
                            Sigue estos pasos para facilitar el uso y aplicación
                            de los software a tus proyectos:
                            <ul>
                              <li>
                                Descubre los beneficios y prestaciones en la
                                sección “Centro de descubrimiento” del menú.
                              </li>
                              <li>
                                Mira ejemplos de uso y aplicaciones prácticas en
                                la sección “Tutoriales” del menú.
                              </li>
                            </ul>
                          </li>
                          <li>
                            Si quieres consultar el material educativo, puedes
                            encontrarlo en la sección “Centro de recursos” del
                            menú.
                          </li>
                          <li>
                            Si tienes alguna duda, visita la sección “Centro de
                            ayuda” del menú.
                          </li>
                          <li>
                            Conoce los beneficios y el alcance de una cuenta
                            gratuita{" "}
                            <a
                              style={{ color: "#f77439", fontWeight: "bold" }}
                              href="https://www.notion.so/cumplimientolean/Beneficios-y-alcance-de-la-Cuenta-gratuita-226d97b7d73b4054b366efe776e261d2"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              haciendo clic aquí.
                            </a>
                          </li>
                        </ul>
                        <p>
                          Te invitamos a seguirnos en nuestra{" "}
                          <a
                            style={{ color: "#f77439", fontWeight: "bold" }}
                            href="https://www.linkedin.com/company/cumplimientolean/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            página de Linkedin{" "}
                          </a>
                          y a visitar{" "}
                          <a
                            style={{ color: "#f77439", fontWeight: "bold" }}
                            href="https://cumplimientolean.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            nuestro sitio web
                          </a>
                          , donde encontrarás información educativa sobre
                          nuestra propuesta de valor.
                        </p>
                        <p>
                          Si tienes alguna pregunta, estamos a solo un clic de
                          distancia. Pulsa el botón de WhatsApp o Soporte en la
                          plataforma.{" "}
                        </p>
                        <p>
                          Esperamos que tu experiencia con nosotros sea muy útil
                          para tus proyectos.
                        </p>
                        <p>
                          Un cordial saludo de mi parte, y recuerda que siempre
                          estamos aquí para ayudarte,
                          <br />{" "}
                          <b>
                            Pablo Soto Bevilacqua, CEO y Fundador de
                            Cumplimiento Lean
                          </b>
                        </p>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-lg-6">
                        <ul className="nav nav-pills nav-tabs nav-justified">
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="inicio-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#inicio"
                              type="button"
                              role="tab"
                              aria-controls="inicio"
                              aria-selected="true"
                            >
                              Bienvenido a Cumplimiento Lean
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="navegacion-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#navegacion"
                              type="button"
                              role="tab"
                              aria-controls="navegacion"
                              aria-selected="true"
                            >
                              Navegación en Cumplimiento Lean
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane fade active show mt-3"
                            id="inicio"
                            role="tabpanel"
                            aria-labelledby="inicio-tab"
                          >
                            <div className="ratio ratio-16x9">
                              <iframe
                                src="https://www.loom.com/embed/cb742a3206bc4d7aa59ede87cc87cbe7?hideEmbedTopBar=true"
                                title="Bienvenido a Cumplimiento Lean"
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade mt-3"
                            id="navegacion"
                            role="tabpanel"
                            aria-labelledby="navegacion-tab"
                          >
                            <div className="ratio ratio-16x9">
                              <iframe
                                src="https://www.loom.com/embed/327229255fed4b2a8453c1c630449087?hideEmbedTopBar=true"
                                title="Navegación en Cumplimiento Lean"
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

export default Inicio;
