import { Component } from "react";

import InputMask from "@mona-health/react-input-mask";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  creacionEntregable,
  limpiarMensajes,
} from "../../../redux/actions/tareas/action";
import { Navbar, SidebarInicio } from "../../general";
import { withRouter } from "../../general/with_router";

export class Nuevo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entregable_nombre: "",
      entregable_tipo: "",
      entregable_descripcion: "",
      entregable_fecha_entrega: "",
      entregable_nuevo_mostrar: false,
      entregable_editar_mostrar: false,
      entregable_responsable: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  nuevoEntregable = () => {
    const { creacionEntregable, tarea_seleccionada } = this.props;
    const {
      entregable_nombre,
      entregable_tipo,
      entregable_fecha_entrega,
      entregable_descripcion,
    } = this.state;
    creacionEntregable({
      nombre: entregable_nombre,
      tipo: entregable_tipo,
      fecha_entrega: entregable_fecha_entrega,
      descripcion: entregable_descripcion,
      tarea: tarea_seleccionada,
    });
    SweetAlert.fire({
      title: "Nuevo Entregable",
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: "Estamos guardando el nuevo entregable, espere unos segundos",
      icon: "warning",
      showConfirmButton: false,
      didOpen: () => {
        SweetAlert.showLoading();
      },
    });
  };

  render = () => {
    const {
      entregable_nombre,
      entregable_tipo,
      entregable_descripcion,
      entregable_fecha_entrega,
    } = this.state;
    const { limpiarMensajes, entregable_nuevo_error, entregable_nuevo } =
      this.props;

    if (Object.entries(entregable_nuevo_error).length > 0) {
      SweetAlert.close();
      if (entregable_nuevo_error.data && entregable_nuevo_error.data.message) {
        SweetAlert.fire({
          title: "Entregable no creado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: entregable_nuevo_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(entregable_nuevo).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Entregable creado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: entregable_nuevo.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.props.navigate("/quality-tasking/tarea");
        }
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="tareas_proyectos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tasks"></i> Nuevo Entregable
                  </h4>
                  <div className="btn-group float-end">
                    <Link
                      className="btn btn-primary"
                      to="/quality-tasking/tarea"
                    >
                      <i className="fa fa-angle-left"></i> Volver al Listado
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="card card-eco">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 text-end">
                        <p className="text-danger">
                          <i className="fa fa-asterisk"></i> datos obligatorios
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Nombre del entregable{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <input
                            type="text"
                            name="entregable_nombre"
                            onChange={this.onChange}
                            value={entregable_nombre}
                            placeholder="Ingrese un nombre"
                            className={
                              entregable_nuevo_error &&
                              entregable_nuevo_error.data &&
                              entregable_nuevo_error.data.nombre
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {entregable_nuevo_error &&
                            entregable_nuevo_error.data &&
                            entregable_nuevo_error.data.nombre &&
                            entregable_nuevo_error.data.nombre.map((err, x) => (
                              <div key={x} className="invalid-feedback">
                                {err}
                              </div>
                            ))}
                        </div>
                        <div className="form-group">
                          <label>
                            Tipo de entregable{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <select
                            name="entregable_tipo"
                            onChange={this.onChange}
                            value={entregable_tipo}
                            className={
                              entregable_nuevo_error &&
                              entregable_nuevo_error.data &&
                              entregable_nuevo_error.data.tipo
                                ? "form-select is-invalid"
                                : "form-select"
                            }
                          >
                            <option value="">Seleccione una opción</option>
                            <option value="archivo">Archivo</option>
                            <option value="texto">Texto</option>
                          </select>
                          {entregable_nuevo_error &&
                            entregable_nuevo_error.data &&
                            entregable_nuevo_error.data.tipo &&
                            entregable_nuevo_error.data.tipo.map((err, x) => (
                              <div key={x} className="invalid-feedback">
                                {err}
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Fecha de entrega del entregable{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <InputMask
                            name="entregable_fecha_entrega"
                            value={entregable_fecha_entrega}
                            onChange={this.onChange}
                            mask="99/99/9999"
                            placeholder="Ingrese una fecha de término"
                            className={
                              entregable_nuevo_error &&
                              entregable_nuevo_error.data &&
                              entregable_nuevo_error.data.fecha_entrega
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {entregable_nuevo_error &&
                            entregable_nuevo_error.data &&
                            entregable_nuevo_error.data.fecha_entrega &&
                            entregable_nuevo_error.data.fecha_entrega.map(
                              (err, x) => (
                                <div key={x} className="invalid-feedback">
                                  {err}
                                </div>
                              )
                            )}
                        </div>
                        <div className="form-group">
                          <label>
                            Descripción del entregable{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <textarea
                            rows="4"
                            name="entregable_descripcion"
                            onChange={this.onChange}
                            value={entregable_descripcion}
                            placeholder="Ingrese una descripción"
                            className={
                              entregable_nuevo_error &&
                              entregable_nuevo_error.data &&
                              entregable_nuevo_error.data.descripcion
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          ></textarea>
                          {entregable_nuevo_error &&
                            entregable_nuevo_error.data &&
                            entregable_nuevo_error.data.descripcion &&
                            entregable_nuevo_error.data.descripcion.map(
                              (err, x) => (
                                <div key={x} className="invalid-feedback">
                                  {err}
                                </div>
                              )
                            )}
                        </div>
                      </div>
                      <div className="col-sm-12 text-end">
                        <div className="btn-group">
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={this.nuevoEntregable}
                          >
                            Crear nuevo entregable{" "}
                            <i className="fa fa-plus-circle"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

Nuevo.propTypes = {
  creacionEntregable: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  entregable_nuevo: PropTypes.object.isRequired,
  entregable_nuevo_error: PropTypes.object.isRequired,
  tarea_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  entregable_nuevo: state.tareas.entregable_nuevo,
  entregable_nuevo_error: state.tareas.entregable_nuevo_error,
  tarea_seleccionada: state.tareas.tarea_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ creacionEntregable, limpiarMensajes }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nuevo));
