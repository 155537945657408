import React, { Component } from "react";
import { TablaPequena as Procedimientos } from "../procedimientos";
import { Tabs as Reportes } from "../reportes";
import { TablaPequena as Titulares } from "../titulares";
import { Tabs as UnidadesFiscalizables } from "../unidades";

export class Tabs extends Component {
  render = () => {
    const { programas } = this.props;

    return programas && programas.length > 0 ? (
      programas.map((programa, x) => (
        <div key={x}>
          <ul className="nav nav-pills nav-justified">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="programa-general-tab"
                data-bs-toggle="pill"
                data-bs-target="#programa-general"
                type="button"
                role="tab"
                aria-controls="programa-general"
                aria-selected="true"
              >
                Información del Programa de Cumplimiento
              </button>
            </li>
            {programa.reportes && programa.reportes.length > 0 && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="reportes-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#reportes"
                  type="button"
                  role="tab"
                  aria-controls="reportes"
                  aria-selected="false"
                >
                  Reportes (
                  {programa && programa.reportes && programa.reportes.length > 0
                    ? programa.reportes.length
                    : 0}
                  )
                </button>
              </li>
            )}
            {programa.unidad_fiscalizable &&
              programa.unidad_fiscalizable.length > 0 && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="unidades-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#unidades"
                    type="button"
                    role="tab"
                    aria-controls="unidades"
                    aria-selected="false"
                  >
                    Unidades Fiscalizables (
                    {programa &&
                    programa.unidad_fiscalizable &&
                    programa.unidad_fiscalizable.length > 0
                      ? programa.unidad_fiscalizable.length
                      : 0}
                    )
                  </button>
                </li>
              )}
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show mt-3"
              id="programa-general"
              role="tabpanel"
              aria-labelledby="programa-general-tab"
            >
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="table-secondary w-20 text-dark">
                        Expediente
                      </td>
                      <td>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={programa.url_snifa}
                        >
                          {programa.expediente}{" "}
                          <i className="fa fa-angle-right"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">Tipología</td>
                      <td>{programa.categoria ? programa.categoria : "S/I"}</td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">Estado</td>
                      <td>{programa.estado ? programa.estado : "S/I"}</td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">Tipo</td>
                      <td>{programa.tipo ? programa.tipo : "S/I"}</td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">Región</td>
                      <td>
                        {programa.region_display
                          ? programa.region_display
                          : "S/I"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">
                        Fecha de resolución
                      </td>
                      <td>
                        {programa.fecha_resolucion
                          ? programa.fecha_resolucion
                          : "S/I"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">
                        Fecha de término
                      </td>
                      <td>
                        {programa.fecha_termino
                          ? programa.fecha_termino
                          : "S/I"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">
                        Frecuencia de reporte
                      </td>
                      <td>
                        {programa.frecuencia_reporte_display
                          ? programa.frecuencia_reporte_display
                          : "S/I"}
                      </td>
                    </tr>
                    {programa.titulares && programa.titulares.length > 0 && (
                      <tr>
                        <td className="table-secondary text-dark">Titular</td>
                        <td>
                          <Titulares titulares={programa.titulares} />
                        </td>
                      </tr>
                    )}
                    {programa.procedimiento &&
                      programa.procedimiento.length > 0 && (
                        <tr>
                          <td className="table-secondary text-dark">
                            Procedimiento
                          </td>
                          <td>
                            <Procedimientos
                              procedimientos={programa.procedimiento}
                            />
                          </td>
                        </tr>
                      )}
                    {programa.url_aprobado && (
                      <tr>
                        <td className="table-secondary text-dark">
                          Programa de cumplimiento aprobado
                        </td>
                        <td>
                          <a
                            className="btn btn-danger btn-xs"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={programa.url_aprobado}
                          >
                            Descargar <i className="fa fa-file-pdf"></i>
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {programa.reportes && programa.reportes.length > 0 && (
              <div
                className="tab-pane fade mt-3"
                id="reportes"
                role="tabpanel"
                aria-labelledby="reportes-tab"
              >
                <Reportes reportes={programa.reportes} />
              </div>
            )}
            {programa.unidad_fiscalizable &&
              programa.unidad_fiscalizable.length > 0 && (
                <div
                  className="tab-pane fade mt-3"
                  id="unidades"
                  role="tabpanel"
                  aria-labelledby="unidades-tab"
                >
                  <UnidadesFiscalizables
                    unidades_fiscalizables={programa.unidad_fiscalizable}
                  />
                </div>
              )}
          </div>
        </div>
      ))
    ) : (
      <h6>No existen programas de cumplimiento</h6>
    );
  };
}

export default Tabs;
