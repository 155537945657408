import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  guardarExpediente,
  obtenerFiltrosExpedientesMapa,
} from "../../../redux/actions/vigilancias/expedientes/action";
import { Footer, Maps, Navbar, SidebarInicio } from "../../general";
import { FiltrosMapa as Filtros } from "./";

export class Mapa extends Component {
  componentDidMount = () => {
    const { obtenerFiltrosExpedientesMapa, filtros_expedientes_mapa } =
      this.props;
    if (Object.entries(filtros_expedientes_mapa).length === 0) {
      obtenerFiltrosExpedientesMapa();
    }
  };

  setExpediente = (id) => {
    const { guardarExpediente } = this.props;
    guardarExpediente({ id: id, componente: "expedientes" });
  };

  render = () => {
    const { expedientes_mapa, is_loading_vigilancias } = this.props;

    var markers = [];
    if (expedientes_mapa && expedientes_mapa.length > 0) {
      expedientes_mapa.forEach(function (expediente) {
        markers.push({
          titulo: "SEIA - Expediente",
          url: "/compliance-analytics/expediente",
          url_nombre: "Ver Expediente",
          nombre: expediente.nombre,
          id: expediente.id,
          estado: expediente.estado,
          titular: expediente.titular,
          position: [expediente.latitud, expediente.longitud],
        });
      });
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "SEIA",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los expedientes seia, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_expedientes" menu_2="mapa" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-map"></i> MAPA EXPEDIENTES SEIA
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      MAPA DE EXPEDIENTES{" "}
                      <small>({expedientes_mapa.length})</small>
                    </h5>
                  </div>
                  <div className="card-body">
                    {markers.length > 0 ? (
                      <Maps
                        center={[-33, -70]}
                        zoom={6}
                        markers={markers}
                        size={1000}
                        setExpediente={this.setExpediente}
                      />
                    ) : (
                      <h5>No existe busqueda realizada</h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Mapa.propTypes = {
  obtenerFiltrosExpedientesMapa: PropTypes.func.isRequired,
  guardarExpediente: PropTypes.func.isRequired,
  expedientes_mapa: PropTypes.array.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  expedientes_mapa: state.vigilancias.expedientes_mapa,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  filtros_expedientes_mapa: state.vigilancias.filtros_expedientes_mapa,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerFiltrosExpedientesMapa, guardarExpediente },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapa);
