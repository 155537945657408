export const TEAMS_EQUIPOS_OBTENER = "TEAMS_EQUIPOS_OBTENER";
export const TEAMS_EQUIPOS_FALLIDO = "TEAMS_EQUIPOS_FALLIDO";
export const TEAMS_EQUIPO_OBTENER = "TEAMS_EQUIPO_OBTENER";
export const TEAMS_EQUIPO_FALLIDO = "TEAMS_EQUIPO_FALLIDO";
export const CARGANDO_LIMPIAR_VGC = "CARGANDO_LIMPIAR_VGC";
export const TEAMS_EQUIPO_GUARDAR = "TEAMS_EQUIPO_GUARDAR";
export const TEAMS_EQUIPOS_FILTROS_INPUT = "TEAMS_EQUIPOS_FILTROS_INPUT";
export const TEAMS_EQUIPOS_FILTROS_OBTENER = "TEAMS_EQUIPOS_FILTROS_OBTENER";
export const TEAMS_EQUIPOS_FILTROS_FALLIDO = "TEAMS_EQUIPOS_FILTROS_FALLIDO";
export const TEAMS_EQUIPO_CREAR_OBTENER = "TEAMS_EQUIPO_CREAR_OBTENER";
export const TEAMS_EQUIPO_CREAR_FALLIDO = "TEAMS_EQUIPO_CREAR_FALLIDO";
export const TEAMS_EQUIPO_ELIMINAR_OBTENER = "TEAMS_EQUIPO_ELIMINAR_OBTENER";
export const TEAMS_EQUIPO_ELIMINAR_FALLIDO = "TEAMS_EQUIPO_ELIMINAR_FALLIDO";
export const LIMPIAR_TEAMS_MENSAJES = "LIMPIAR_TEAMS_MENSAJES";
export const TEAMS_EQUIPO_FAVORITO_ELIMINAR_OBTENER =
  "TEAMS_EQUIPO_FAVORITO_ELIMINAR_OBTENER";
export const TEAMS_EQUIPO_FAVORITO_ELIMINAR_FALLIDO =
  "TEAMS_EQUIPO_FAVORITO_ELIMINAR_FALLIDO";
export const TEAMS_EQUIPO_FAVORITO_CREAR_OBTENER =
  "TEAMS_EQUIPO_FAVORITO_CREAR_OBTENER";
export const TEAMS_EQUIPO_FAVORITO_CREAR_FALLIDO =
  "TEAMS_EQUIPO_FAVORITO_CREAR_FALLIDO";
export const TEAMS_EQUIPO_EDITAR_OBTENER = "TEAMS_EQUIPO_EDITAR_OBTENER";
export const TEAMS_EQUIPO_EDITAR_FALLIDO = "TEAMS_EQUIPO_EDITAR_FALLIDO";
export const TEAMS_EQUIPO_BUSCAR_OBTENER = "TEAMS_EQUIPO_BUSCAR_OBTENER";
export const TEAMS_EQUIPO_BUSCAR_FALLIDO = "TEAMS_EQUIPO_BUSCAR_FALLIDO";
export const TEAMS_EQUIPO_USUARIO_ELIMINAR_OBTENER =
  "TEAMS_EQUIPO_USUARIO_ELIMINAR_OBTENER";
export const TEAMS_EQUIPO_USUARIO_ELIMINAR_FALLIDO =
  "TEAMS_EQUIPO_USUARIO_ELIMINAR_FALLIDO";
export const TEAMS_EQUIPO_USUARIO_AGREGAR_OBTENER =
  "TEAMS_EQUIPO_USUARIO_AGREGAR_OBTENER";
export const TEAMS_EQUIPO_USUARIO_AGREGAR_FALLIDO =
  "TEAMS_EQUIPO_USUARIO_AGREGAR_FALLIDO";
