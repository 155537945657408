import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  obtenerNormas,
  obtenerNormasFiltros,
} from "../../../redux/actions/leyes/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Filtros, Tabla } from "./";

export class Listado extends Component {
  componentDidMount = () => {
    const { obtenerNormas, normas } = this.props;
    if (Object.entries(normas).length === 0) {
      obtenerNormas(1);
    }
  };

  nuevaPagina = (pagina) => {
    const {
      obtenerNormas,
      obtenerNormasFiltros,
      normas_filtros,
      normas_filtro_input,
    } = this.props;
    if (normas_filtros && Object.entries(normas_filtros).length > 0) {
      obtenerNormasFiltros({
        filtros: normas_filtro_input,
        pagina: pagina,
      });
    } else {
      obtenerNormas(pagina);
    }
  };

  render = () => {
    const { normas, is_loading_leyes, normas_filtros } = this.props;
    var normas_result = normas;
    if (Object.entries(normas_filtros).length > 0) {
      normas_result = normas_filtros;
    }

    if (is_loading_leyes) {
      SweetAlert.fire({
        title: "Normas",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las normas, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="leyes_normas" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-gavel"></i> NORMAS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE NORMAS{" "}
                      <small>
                        (
                        {normas_result && normas_result.total
                          ? normas_result.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={normas_result}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla normas={normas_result} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerNormas: PropTypes.func.isRequired,
  obtenerNormasFiltros: PropTypes.func.isRequired,
  normas: PropTypes.object.isRequired,
  normas_filtros: PropTypes.object.isRequired,
  normas_filtro_input: PropTypes.object.isRequired,
  is_loading_leyes: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  normas: state.leyes.normas,
  is_loading_leyes: state.leyes.is_loading_leyes,
  normas_filtros: state.leyes.normas_filtros,
  normas_filtro_input: state.leyes.normas_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerNormas, obtenerNormasFiltros }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
