import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { guardarRequerimientoIngreso } from "../../../redux/actions/vigilancias/requerimientos/action";
import { TablaPequena as Titulares } from "../../vigilancias/titulares";
import { TablaPequena as UnidadesFiscalizables } from "../../vigilancias/unidades";

export class Tabla extends Component {
  setRequerimiento = (id) => {
    const { guardarRequerimientoIngreso } = this.props;
    guardarRequerimientoIngreso(id, "teams");
  };

  render = () => {
    const { requerimientos } = this.props;

    return (
      <div className="table-responsive">
        <Tooltip id="detalle_requerimiento" aria-haspopup="true">
          Ver detalle del requerimiento de ingreso
        </Tooltip>
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th className="w-10">Expediente</th>
              <th>Equipo</th>
              <th className="w-25">Unidad Fiscalizable</th>
              <th className="w-25">Titular</th>
              <th className="w-7">
                Fecha
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="fecha_hecho"
                ></i>
                <Tooltip id="fecha_hecho" aria-haspopup="true">
                  Fecha del primer documento del hecho infraccional
                </Tooltip>
              </th>
              <th className="text-center w-3">Acción</th>
            </tr>
          </thead>
          <tbody>
            {requerimientos &&
            requerimientos.result &&
            requerimientos.result.length > 0 ? (
              requerimientos.result.map((req, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {requerimientos.page > 1
                      ? (requerimientos.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td>
                    <a
                      href={req.url_snifa}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {req.expediente} <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td>
                    {req.equipo &&
                      req.equipo.map((equipo, x) => (
                        <>
                          <div
                            key={x}
                            className="badge bg-soft-danger me-1 font-12 mb-2"
                          >
                            <i className="fa fa-users"></i> {equipo.nombre}
                          </div>
                          <p>
                            {equipo.integrantes &&
                              equipo.integrantes.map((integrante, y) => {
                                return (
                                  <span
                                    key={y}
                                    className="badge bg-soft-primary me-1 font-11"
                                  >
                                    <i className="fa fa-user-tie"></i>{" "}
                                    {integrante}
                                  </span>
                                );
                              })}
                          </p>
                        </>
                      ))}
                  </td>
                  <td>
                    <UnidadesFiscalizables
                      unidades_fiscalizables={req.unidad_fiscalizable}
                    />
                  </td>
                  <td>
                    <Titulares titulares={req.titulares} />
                  </td>
                  <td>{req.fecha_inicio}</td>
                  <td className="text-center">
                    <div className="btn-group">
                      <Link
                        data-tip
                        data-tooltip-id="detalle_requerimiento"
                        to="/compliance-analytics/requerimiento-ingreso"
                        className="btn btn-soft-dark"
                        onClick={this.setRequerimiento.bind(this, req.id)}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="7">
                  No existen requerimiento de ingreso
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarRequerimientoIngreso: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarRequerimientoIngreso }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
