import React, { Component } from "react";

export class FichaPequena extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_tags: 0,
    };
  }

  setExpedienteTag = (id) => {
    this.setState({
      is_tags: id,
    });
  };

  render = () => {
    const { acciones } = this.props;
    const { is_tags } = this.state;

    return (
      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Implementación comprometida</th>
            </tr>
          </thead>
          <tbody>
            {acciones && acciones.length > 0 ? (
              acciones.map((accion, a) => (
                <tr key={a}>
                  <td className="text-center">{a + 1}</td>
                  <td>
                    {accion.implementacion_comprometida}
                    {accion.tag && accion.tag.length > 0 && (
                      <p>
                        {is_tags === accion.accion ? (
                          accion.tag.map((tag, z) => (
                            <span
                              key={z}
                              className="badge badge-soft-primary p-1 me-1 font-11"
                            >
                              #{tag}
                            </span>
                          ))
                        ) : (
                          <button
                            onClick={this.setExpedienteTag.bind(
                              this,
                              accion.accion
                            )}
                            type="button"
                            className="btn btn-sm btn-primary btn-tags"
                          >
                            Tags <i className="fa fa-angle-right"></i>
                          </button>
                        )}
                      </p>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="2">No existen acciones</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

export default FichaPequena;
