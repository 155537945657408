import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import slugify from "slugify";
import SweetAlert from "sweetalert2";

import {
  categoriaSeccionSeleccionada,
  obtenerArchivoPregunta,
  obtenerRespuestasIndefinidas,
} from "../../../redux/actions/permitting/action";
import {
  Footer,
  Navbar,
  SiderbarPermittingParametrizacion,
} from "../../general";
import { FichaProyecto } from "../general";

export class Indefinidas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ver_ayuda: 0,
    };
  }

  componentDidMount = () => {
    const { obtenerRespuestasIndefinidas, consulta_seleccionada } = this.props;
    if (consulta_seleccionada > 0) {
      obtenerRespuestasIndefinidas({
        consulta: consulta_seleccionada,
      });
    }
  };

  verAyuda = (id) => {
    const { ver_ayuda } = this.state;
    this.setState({
      ver_ayuda: ver_ayuda === id ? 0 : id,
    });
  };

  verArchivo = (archivo, pregunta) => {
    const { obtenerArchivoPregunta, consulta_seleccionada } = this.props;

    obtenerArchivoPregunta({
      seccion: pregunta.seccion.id,
      categoria: pregunta.categoria.id,
      consulta: consulta_seleccionada,
      archivo: archivo,
      pregunta: pregunta.id,
    });
  };

  verDetalleCategoria = (pregunta) => {
    const { categoriaSeccionSeleccionada } = this.props;
    categoriaSeccionSeleccionada(pregunta);
  };

  render = () => {
    const {
      is_loading_permitting,
      consulta_seleccionada,
      respuestas_indefinidas,
    } = this.props;
    const { ver_ayuda } = this.state;

    if (consulta_seleccionada === 0) {
      return <Navigate to="/permitting-target/proyectos" />;
    }

    const createMarkup = (htmlString) => ({ __html: htmlString });

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Respuestas Indefinidas",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las respuestas, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SiderbarPermittingParametrizacion menu_1="permitting_indefinidas" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h4>PARAMETRIZACIÓN DE LA CONSULTA</h4>
                  </div>
                  <div className="card-body">
                    <ol className="breadcrumb mt-0 mb-0 font-14">
                      <li className="breadcrumb-item">
                        <Link to="/permitting-target/proyectos">Proyectos</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Preguntas Indefinidas
                      </li>
                    </ol>
                    <FichaProyecto />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h5>ETAPA ACTUAL DEL PROCESO DE LA CONSULTA</h5>
                  </div>
                  <div className="card-body">
                    <ol className="c-progress-steps">
                      <li className="c-progress-steps__step current">
                        <span>1. Parametrización de la consulta</span>
                      </li>
                      <li className="c-progress-steps__step">
                        <span>2. Reporte de aplicabilidad</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-12">
                {respuestas_indefinidas && respuestas_indefinidas.length > 0 ? (
                  respuestas_indefinidas.map((res, x) => (
                    <div
                      key={x}
                      className={`card card-eco card-eco-${slugify(res.valor, {
                        lower: true,
                      })}`}
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-12">
                            <h5 className="mt-0 mb-0">
                              {x + 1}. {res.pregunta.texto}
                            </h5>
                            {res && res.pregunta && res.pregunta.seccion && (
                              <p className="mt-2 mb-0">
                                <strong>
                                  <u>Sección:</u>
                                </strong>{" "}
                                {res.pregunta.seccion.nombre}
                              </p>
                            )}
                            {res && res.pregunta && res.pregunta.categoria && (
                              <p className="mt-0">
                                <strong>
                                  <u>Categoría:</u>
                                </strong>{" "}
                                {res.pregunta.categoria.nombre}
                              </p>
                            )}
                            {res &&
                              res.pregunta &&
                              res.pregunta.categoria &&
                              res.pregunta.seccion && (
                                <Link
                                  onClick={this.verDetalleCategoria.bind(
                                    this,
                                    res.pregunta
                                  )}
                                  to="/permitting-target/parametrizacion/preguntas"
                                  className="float-end btn btn-xs btn-warning"
                                >
                                  Cambiar respuesta{" "}
                                  <i className="fa fa-angle-right"></i>
                                </Link>
                              )}
                            {res.pregunta.tooltip && (
                              <>
                                <p className="mb-2">
                                  <button
                                    onClick={this.verAyuda.bind(
                                      this,
                                      res.pregunta.id
                                    )}
                                    type="button"
                                    className={`btn btn-xs btn-${
                                      ver_ayuda === res.pregunta.id
                                        ? "dark"
                                        : "primary"
                                    }`}
                                  >
                                    <i className="fa fa-exclamation-circle"></i>{" "}
                                    Ayuda{" "}
                                    <i
                                      className={`fa fa-angle-${
                                        ver_ayuda === res.pregunta.id
                                          ? "down"
                                          : "right"
                                      }`}
                                    ></i>
                                  </button>
                                </p>
                                <div
                                  className={`bg-white border p-3${
                                    ver_ayuda === res.pregunta.id
                                      ? ""
                                      : " d-none"
                                  }`}
                                >
                                  <div
                                    dangerouslySetInnerHTML={createMarkup(
                                      res.pregunta.tooltip.texto
                                    )}
                                  />
                                  {res.pregunta.tooltip.archivo && (
                                    <>
                                      <p className="mb-1">
                                        {res.pregunta.tooltip.archivo.nombre}:
                                      </p>
                                      <button
                                        type="button"
                                        onClick={this.verArchivo.bind(
                                          this,
                                          res.pregunta.tooltip.archivo.id,
                                          res.pregunta
                                        )}
                                        className="mt-0 btn btn-danger btn-xs"
                                        target="_blank"
                                      >
                                        Ver archivo{" "}
                                        <i className="fa fa-file-pdf"></i>
                                      </button>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card card-eco">
                    <div className="card-body">
                      <h5>No existen respuestas indefinidas</h5>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Indefinidas.propTypes = {
  obtenerRespuestasIndefinidas: PropTypes.func.isRequired,
  categoriaSeccionSeleccionada: PropTypes.func.isRequired,
  obtenerArchivoPregunta: PropTypes.func.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  consulta_seleccionada: PropTypes.number.isRequired,
  respuestas_indefinidas: PropTypes.array.isRequired,
  respuestas_indefinidas_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_permitting: state.permitting.is_loading_permitting,
  consulta_seleccionada: state.permitting.consulta_seleccionada,
  respuestas_indefinidas: state.permitting.respuestas_indefinidas,
  respuestas_indefinidas_error: state.permitting.respuestas_indefinidas_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerRespuestasIndefinidas,
      categoriaSeccionSeleccionada,
      obtenerArchivoPregunta,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Indefinidas);
