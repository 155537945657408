import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerInstrumentoInfringido } from "../../../redux/actions/vigilancias/instrumentos_infringidos/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Tabs } from "./";

export class Detalle extends Component {
  componentDidMount = () => {
    const { obtenerInstrumentoInfringido, instrumento_infringido_id } =
      this.props;
    if (instrumento_infringido_id > 0) {
      obtenerInstrumentoInfringido({
        id: instrumento_infringido_id,
      });
    }
  };

  render = () => {
    const {
      is_loading_vigilancias,
      instrumento_infringido_id,
      instrumento_infringido,
    } = this.props;

    if (instrumento_infringido_id === 0) {
      return <Navigate to="/compliance-analytics/instrumentos-infringidos" />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Instrumento Infringido",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo el instrumento infringido, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_instrumentos_infringidos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tools"></i> INSTRUMENTOS INFRINGIDOS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-lg-9">
                        <h5 className="float-start">
                          {instrumento_infringido
                            ? instrumento_infringido.nombre
                            : "S/N"}
                        </h5>
                      </div>
                      <div className="col-lg-3">
                        <div className="btn-group float-end">
                          <Link
                            className="btn btn-primary"
                            to="/compliance-analytics/instrumentos-infringidos"
                          >
                            <i className="fa fa-angle-left"></i> Volver al
                            listado
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <Tabs instrumentos_infringidos={[instrumento_infringido]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerInstrumentoInfringido: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  instrumento_infringido: PropTypes.object.isRequired,
  instrumento_infringido_id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  instrumento_infringido: state.vigilancias.instrumento_infringido,
  instrumento_infringido_id: state.vigilancias.instrumento_infringido_id,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerInstrumentoInfringido }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
