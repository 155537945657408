import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { CheckTreePicker } from "rsuite";
import SweetAlert from "sweetalert2";
import {
  agregarIntegrante,
  buscarExpedienteEquipo,
  crearFavorito,
  editarEquipo,
  eliminarFavorito,
  eliminarIntegrante,
  limpiarMensajes,
  obtenerEquipo,
} from "../../../redux/actions/teams/equipos/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Tabs } from "./";

export class Detalle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editar_integrante: false,
      editar_nombre_equipo: false,
      integrantes: [],
      equipo_nombre: "",
    };
  }

  componentDidMount = () => {
    const { obtenerEquipo, equipo_id } = this.props;
    if (equipo_id > 0) {
      obtenerEquipo({
        id: equipo_id,
      });
    }
  };

  buscarExpediente = (expediente, tipo) => {
    const { buscarExpedienteEquipo } = this.props;
    buscarExpedienteEquipo({
      expediente: expediente,
      tipo: tipo,
    });
    this.setState({
      usuario_loading: SweetAlert.fire({
        title: "Nuevo Expediente",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: `Estamos buscando los expediente, espere unos segundos`,
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      }),
    });
  };

  ediccionEquipo = () => {
    const { equipo_id, editarEquipo } = this.props;
    const { equipo_nombre } = this.state;
    editarEquipo({ nombre: equipo_nombre, id: equipo_id });
    this.setState({
      usuario_loading: SweetAlert.fire({
        title: "Favorito",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: `Estamos editando al equipo, espere unos segundos`,
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      }),
    });
  };

  agregarIntegrantes = () => {
    const { equipo_id, agregarIntegrante } = this.props;
    const { integrantes } = this.state;
    if (integrantes.length === 0) {
      SweetAlert.fire({
        title: "Integrante",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: `Debes seleccionar al menos un integrante para agregar al equipo`,
        icon: "info",
        confirmButtonText: 'Aceptar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      return;
    } else {
      agregarIntegrante({ integrantes: integrantes, id: equipo_id });
      this.setState({
        usuario_loading: SweetAlert.fire({
          title: "Integrante",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: `Estamos guardando  el Equipo, espere unos segundos`,
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        }),
      });
    }
  };

  eliminacionIntegrante = (usuario) => {
    const { eliminarIntegrante, equipo_id } = this.props;
    SweetAlert.fire({
      title: "Favorito",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de eliminar ${usuario.nombre_corto} del equipo?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarIntegrante({
          integrante: usuario.pk,
          equipo: equipo_id,
        });
        this.setState({
          usuario_loading: SweetAlert.fire({
            title: "Integrante",
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: `Estamos eliminando el integrante del equipo, espere unos segundos`,
            icon: "warning",
            showConfirmButton: false,
            didOpen: () => {
              SweetAlert.showLoading();
            },
          }),
        });
      }
    });
  };

  eliminacionFavorito = (id) => {
    const { eliminarFavorito, equipo_id } = this.props;
    SweetAlert.fire({
      title: "Favorito",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de eliminar el expediente seleccionado?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarFavorito({
          favorito: id,
          equipo: equipo_id,
        });
        this.setState({
          usuario_loading: SweetAlert.fire({
            title: "Favorito",
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: `Estamos eliminando el expediente del equipo, espere unos segundos`,
            icon: "warning",
            showConfirmButton: false,
            didOpen: () => {
              SweetAlert.showLoading();
            },
          }),
        });
      }
    });
  };

  agregarFavorito = (id, expediente, tipo) => {
    const { crearFavorito, equipo_id } = this.props;
    SweetAlert.fire({
      title: "Favorito",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de agregar el expediente ${expediente}?`,
      icon: "info",
      confirmButtonText: 'Si, Agregar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        crearFavorito({
          expediente: id,
          tipo: tipo,
          equipo: equipo_id,
        });
        this.setState({
          usuario_loading: SweetAlert.fire({
            title: "Favorito",
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: `Estamos agregando el expediente ${expediente} al equipo, espere unos segundos`,
            icon: "warning",
            showConfirmButton: false,
            didOpen: () => {
              SweetAlert.showLoading();
            },
          }),
        });
      }
    });
  };

  render = () => {
    const {
      obtenerEquipo,
      limpiarMensajes,
      is_loading_teams,
      equipo_id,
      equipo,
      favorito_eliminar,
      favorito_eliminar_error,
      favorito_crear,
      favorito_crear_error,
      usuarios,
      equipo_editar,
      equipo_editar_error,
      equipo_buscar,
      equipo_buscar_error,
      equipo_usuario_eliminar,
      equipo_usuario_eliminar_error,
      equipo_usuario_agregar,
      equipo_usuario_agregar_error,
      user,
    } = this.props;
    const { editar_integrante, editar_nombre_equipo } = this.state;
    const usuarios_list = usuarios.map((usuario) => ({
      label: usuario.nombre_corto,
      value: usuario.pk,
    }));
    const perfil =
      user && user.modulos && user.modulos.sub_teams
        ? user.modulos.sub_teams.perfil
        : null;

    if (equipo_id === 0) {
      return <Navigate to="/teams/equipos" />;
    }

    if (is_loading_teams) {
      SweetAlert.fire({
        title: "Equipo VGC",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo el detalle del equipo, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (Object.entries(equipo_buscar_error).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Nuevo Expediente",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: equipo_buscar_error.message,
        icon: "success",
        confirmButtonText: 'Aceptar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    }

    if (Object.entries(favorito_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Favorito Eliminado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: favorito_eliminar.message,
        icon: "success",
        confirmButtonText: 'Aceptar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerEquipo({
            id: equipo_id,
          });
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(favorito_eliminar_error).length > 0) {
      SweetAlert.close();
      if (
        favorito_eliminar_error.data &&
        favorito_eliminar_error.data.message
      ) {
        SweetAlert.fire({
          title: "Favorito no Eliminado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: favorito_eliminar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    }

    if (Object.entries(equipo_usuario_agregar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Equipo",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: equipo_usuario_agregar.message,
        icon: "success",
        confirmButtonText: 'Aceptar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerEquipo({
            id: equipo_id,
          });
          limpiarMensajes();
          this.setState({
            editar_integrante: false,
          });
        }
      });
    }

    if (Object.entries(equipo_usuario_agregar_error).length > 0) {
      SweetAlert.close();
      if (
        equipo_usuario_agregar_error.data &&
        equipo_usuario_agregar_error.data.message
      ) {
        SweetAlert.fire({
          title: "Equipo",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: equipo_usuario_agregar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    }

    if (Object.entries(equipo_usuario_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Integrante Eliminado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: equipo_usuario_eliminar.message,
        icon: "success",
        confirmButtonText: 'Aceptar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerEquipo({
            id: equipo_id,
          });
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(equipo_usuario_eliminar_error).length > 0) {
      SweetAlert.close();
      if (
        equipo_usuario_eliminar_error.data &&
        equipo_usuario_eliminar_error.data.message
      ) {
        SweetAlert.fire({
          title: "Integrante no Eliminado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: equipo_usuario_eliminar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    }

    if (Object.entries(favorito_crear).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Favorito Agregado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: favorito_crear.message,
        icon: "success",
        confirmButtonText: 'Aceptar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerEquipo({
            id: equipo_id,
          });
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(favorito_crear_error).length > 0) {
      SweetAlert.close();
      if (favorito_crear_error.data && favorito_crear_error.data.message) {
        SweetAlert.fire({
          title: "Favorito no Eliminado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: favorito_crear_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    }

    if (Object.entries(equipo_editar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Equipo Editado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: equipo_editar.message,
        icon: "success",
        confirmButtonText: 'Aceptar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerEquipo({
            id: equipo_id,
          });
          limpiarMensajes();
          this.setState({
            editar_nombre_equipo: false,
          });
        }
      });
    }

    if (Object.entries(equipo_editar_error).length > 0) {
      SweetAlert.close();
      if (equipo_editar_error.data && equipo_editar_error.data.message) {
        SweetAlert.fire({
          title: "Equipo no Editado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: equipo_editar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="teams" menu_2="equipos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-users"></i> EQUIPOS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-body">
                    <div className="float-end btn-group">
                      <Link className="btn btn-primary" to="/teams/equipos">
                        <i className="fa fa-angle-left"></i> Volver al listado
                      </Link>
                      {perfil && perfil in ["3", "2"] && !editar_integrante && (
                        <button
                          className="btn btn-danger"
                          onClick={() =>
                            this.setState({
                              editar_integrante: true,
                              editar_nombre_equipo: false,
                            })
                          }
                        >
                          Agregar Integrantes{" "}
                          <i className="fa fa-user-plus"></i>
                        </button>
                      )}
                    </div>
                    <h5 className="text-uppercase">Nombre del Equipo</h5>
                    <p className="text-uppercase mt-2">
                      {perfil &&
                      perfil in ["3", "2"] &&
                      !editar_nombre_equipo ? (
                        <>
                          <button
                            className="btn btn-norma"
                            onClick={() =>
                              this.setState({
                                editar_nombre_equipo: true,
                                editar_integrante: false,
                              })
                            }
                          >
                            <i className="text-warning fa fa-pen"></i>
                          </button>
                          {equipo.nombre}
                        </>
                      ) : (
                        equipo.nombre
                      )}
                    </p>
                    {perfil && perfil in ["3", "2"] && editar_nombre_equipo && (
                      <div className="w-30 mt-3 mb-4 ms-3">
                        <label>Editar el nombre del Equipo</label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={equipo.nombre}
                          onChange={(e) => {
                            this.setState({
                              equipo_nombre: e.target.value,
                            });
                          }}
                        />
                        <div className="btn-group mt-2">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() =>
                              this.setState({
                                editar_nombre_equipo: false,
                              })
                            }
                          >
                            <i className="fa fa-times"></i> Cancelar
                          </button>
                          <button
                            className="btn btn-dark btn-sm"
                            onClick={() => this.ediccionEquipo()}
                          >
                            <i className="fa fa-save"></i> Guardar Equipo
                          </button>
                        </div>
                      </div>
                    )}
                    {perfil && perfil in ["3", "2"] && editar_integrante && (
                      <div>
                        <h5 className="text-uppercase mb-3">Integrantes</h5>
                        <div className="mt-3 mb-4 ms-3">
                          <div className="form-group mb-2">
                            <label>
                              Selecciona uno o más integrantes al Equipo
                            </label>
                            <br />
                            <CheckTreePicker
                              name="integrantes"
                              data={usuarios_list}
                              defaultValue={equipo.usuario.map(
                                (usuario) => usuario.pk
                              )}
                              onChange={(value) => {
                                this.setState({
                                  integrantes: value,
                                });
                              }}
                              locale={{
                                noResultsText: "Sin resultados",
                                placeholder: "Selecciona una opción",
                                searchPlaceholder: "Buscar",
                              }}
                              className="w-50"
                            />
                          </div>
                          <div className="btn-group">
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() =>
                                this.setState({
                                  editar_integrante: false,
                                  integrantes: [],
                                })
                              }
                            >
                              <i className="fa fa-times"></i> Cancelar
                            </button>
                            <button
                              className="btn btn-dark btn-sm"
                              onClick={() => {
                                this.agregarIntegrantes();
                              }}
                            >
                              <i className="fa fa-save"></i> Guardar Equipo
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {!editar_integrante && (
                      <>
                        <Tooltip id="eliminar_integrante" aria-haspopup="true">
                          Eliminar del Equipo
                        </Tooltip>
                        <h5 className="text-uppercase mb-2">Integrantes</h5>
                        {equipo.usuario && equipo.usuario.length > 0 ? (
                          equipo.usuario.map((usuario, x) => (
                            <p key={x} className="mb-0 ms-2">
                              <Tooltip
                                id={`eliminar_integrante_${usuario.email}`}
                                aria-haspopup="true"
                              >
                                {usuario.email}
                              </Tooltip>
                              <i
                                className="fa fa-envelope text-danger me-2"
                                data-tip
                                data-tooltip-place="right"
                                data-tooltip-id={`eliminar_integrante_${usuario.email}`}
                              ></i>
                              {perfil && perfil in ["3", "2"] && (
                                <button
                                  type="button"
                                  className="btn btn-norma"
                                  data-tip
                                  data-tooltip-place="right"
                                  data-tooltip-id="eliminar_integrante"
                                  onClick={() =>
                                    this.eliminacionIntegrante(usuario)
                                  }
                                >
                                  <i className="fa fa-times text-danger me-2"></i>
                                </button>
                              )}
                              <span className="text-uppercase">
                                {usuario.nombre_corto}
                              </span>
                            </p>
                          ))
                        ) : (
                          <p className="text-uppercase ms-2 small">
                            -- No hay integrantes en el equipo --
                          </p>
                        )}
                      </>
                    )}
                    {equipo && (
                      <Tabs
                        equipo={equipo}
                        eliminacionFavorito={this.eliminacionFavorito}
                        agregarFavorito={this.agregarFavorito}
                        buscarExpediente={this.buscarExpediente}
                        limpiarMensajes={limpiarMensajes}
                        equipo_buscar={equipo_buscar}
                        perfil={perfil}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerEquipo: PropTypes.func.isRequired,
  crearFavorito: PropTypes.func.isRequired,
  eliminarFavorito: PropTypes.func.isRequired,
  buscarExpedienteEquipo: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  editarEquipo: PropTypes.func.isRequired,
  eliminarIntegrante: PropTypes.func.isRequired,
  agregarIntegrante: PropTypes.func.isRequired,
  is_loading_teams: PropTypes.bool.isRequired,
  equipo: PropTypes.object.isRequired,
  equipo_id: PropTypes.number.isRequired,
  usuarios: PropTypes.array.isRequired,
  equipo_editar: PropTypes.object.isRequired,
  equipo_editar_error: PropTypes.object.isRequired,
  equipo_buscar: PropTypes.array.isRequired,
  equipo_buscar_error: PropTypes.object.isRequired,
  equipo_usuario_eliminar: PropTypes.object.isRequired,
  equipo_usuario_eliminar_error: PropTypes.object.isRequired,
  equipo_usuario_agregar: PropTypes.object.isRequired,
  equipo_usuario_agregar_error: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_teams: state.teams.is_loading_teams,
  equipo_id: state.teams.equipo_id,
  favorito_eliminar: state.teams.favorito_eliminar,
  favorito_eliminar_error: state.teams.favorito_eliminar_error,
  favorito_crear: state.teams.favorito_crear,
  favorito_crear_error: state.teams.favorito_crear_error,
  equipo: state.teams.equipo,
  usuarios: state.teams.usuarios,
  equipo_editar: state.teams.equipo_editar,
  equipo_editar_error: state.teams.equipo_editar_error,
  equipo_buscar: state.teams.equipo_buscar,
  equipo_buscar_error: state.teams.equipo_buscar_error,
  equipo_usuario_eliminar: state.teams.equipo_usuario_eliminar,
  equipo_usuario_eliminar_error: state.teams.equipo_usuario_eliminar_error,
  equipo_usuario_agregar: state.teams.equipo_usuario_agregar,
  equipo_usuario_agregar_error: state.teams.equipo_usuario_agregar_error,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerEquipo,
      eliminarFavorito,
      limpiarMensajes,
      crearFavorito,
      editarEquipo,
      buscarExpedienteEquipo,
      eliminarIntegrante,
      agregarIntegrante,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
