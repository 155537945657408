import React, { Component } from "react";

import { Link } from "react-router-dom";

export class Inicio extends Component {
  render = () => {
    const { menu_1, permitting_target, sub_permitting_target } = this.props;
    return (
      permitting_target && (
        <>
          <h5 className="mt-3">PERMITTING TARGET</h5>
          {/* <Tooltip id='app_proximamente' aria-haspopup='true'>Módulo en Desarrollo</Tooltip> */}
          {sub_permitting_target.consultas && (
            <li
              className={
                menu_1 && menu_1 === "permitting_proyectos"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link className="nav-link" to="/permitting-target/proyectos">
                <i className="fa fa-server"></i> Consultas de Aplicabilidad
              </Link>
            </li>
          )}
          {sub_permitting_target.permisoteca && (
            <li
              className={
                menu_1 && menu_1 === "permitting_permisoteca"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                className="nav-link"
                to="/permitting-target/permisoteca/proyectos"
              >
                <i className="fab fa-react"></i> Permisoteca
              </Link>
            </li>
          )}
          {/* <li className="mm-inactivo">
            <Link data-tip data-tooltip-id='app_proximamente' className="nav-link" to="#"><i className="fa fa-undo-alt"></i> Permisos Previos</Link>
          </li>
          <li className="mm-inactivo">
            <Link data-tip data-tooltip-id='app_proximamente' className="nav-link" to="#"><i className="fa fa-map-marked-alt"></i> Roadmap</Link>
          </li> */}
        </>
      )
    );
  };
}

export default Inicio;
