import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { guardarProcedimiento } from "../../../redux/actions/vigilancias/procedimientos/action";
import { Eliminar as EliminarFavorito } from "../favoritos";
import { TablaPequena as Titulares } from "../titulares";
import { TablaPequena as Unidades } from "../unidades";

export class Tabla extends Component {
  setProcedimiento = (id) => {
    const { guardarProcedimiento } = this.props;
    guardarProcedimiento(id, "normal");
  };

  render = () => {
    const { procedimientos, eliminar_favorito } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover table-sm">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th className="text-center w-8">Expediente</th>
              <th className="text-center w-7">Fecha de inicio</th>
              <th className="w-20">Estado</th>
              <th className="w-25">Titular</th>
              <th>Unidad Fiscalizable</th>
              <th className="text-center w-3">Acción</th>
            </tr>
          </thead>
          <tbody>
            {procedimientos &&
            procedimientos.result &&
            procedimientos.result.length > 0 ? (
              procedimientos.result.map((procedimiento, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {procedimientos.page > 1
                      ? (procedimientos.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td className="text-center">
                    <a
                      href={procedimiento.url_snifa}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {procedimiento.expediente}
                      <i className="ms-1 fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td className="text-center">{procedimiento.fecha_inicio}</td>
                  <td>
                    <span className="badge badge-dark font-13 text-center">
                      {procedimiento.estado}
                    </span>
                  </td>
                  <td>
                    <Titulares titulares={procedimiento.titulares} />
                  </td>
                  <td>
                    <Unidades unidades_fiscalizables={procedimiento.unidades} />
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {eliminar_favorito && (
                        <EliminarFavorito
                          tipo="procedimiento"
                          id={procedimiento.id}
                        />
                      )}
                      <Link
                        to="/compliance-analytics/procedimiento"
                        className="btn btn-soft-dark"
                        onClick={this.setProcedimiento.bind(
                          this,
                          procedimiento.id
                        )}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="8">
                  No existen procedimientos sancionatorios
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarProcedimiento: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarProcedimiento }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
