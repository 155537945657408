export const TEAMS_MEDIDAS_PROVISIONALES_OBTENER =
  "TEAMS_MEDIDAS_PROVISIONALES_OBTENER";
export const TEAMS_MEDIDAS_PROVISIONALES_FALLIDO =
  "TEAMS_MEDIDAS_PROVISIONALES_FALLIDO";
export const TEAMS_MEDIDAS_PROVISIONALES_FILTROS_OBTENER =
  "TEAMS_MEDIDAS_PROVISIONALES_FILTROS_OBTENER";
export const TEAMS_MEDIDAS_PROVISIONALES_FILTROS_FALLIDO =
  "TEAMS_MEDIDAS_PROVISIONALES_FILTROS_FALLIDO";
export const TEAMS_MEDIDAS_PROVISIONALES_FILTROS_INPUT =
  "TEAMS_MEDIDAS_PROVISIONALES_FILTROS_INPUT";
export const TEAMS_LIMPIAR_MEDIDAS_FILTROS = "TEAMS_LIMPIAR_MEDIDAS_FILTROS";
