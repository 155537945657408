import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { CheckTreePicker } from "rsuite";
import SweetAlert from "sweetalert2";
import {
  crearAlerta,
  limpiarMensajes,
} from "../../../redux/actions/leyes/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { withRouter } from "../../general/with_router";

export class Nueva extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_organismos: [],
    };
  }

  creacionAlerta = () => {
    const { crearAlerta } = this.props;
    const { filtro_organismos } = this.state;
    if (filtro_organismos.length === 0) {
      SweetAlert.fire({
        title: "Alerta",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Debes seleccionar la región para crear la alerta",
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    } else {
      crearAlerta({
        autoridad: filtro_organismos,
      });
      SweetAlert.fire({
        title: "Alerta",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos validando la información, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "modulo") {
      this.setState({
        expedientes_regiones: [],
        expedientes_tipologias: [],
        expedientes_tipos: [],
        expedientes_estados: [],
        hechos_categorias: [],
        hechos_regiones: [],
        pertinencias_regiones: [],
        pertinencias_tipologias: [],
      });
    } else {
      var valor = e.target.value;
      if (
        (e.target.name === "expedientes_regiones" ||
          e.target.name === "expedientes_tipologias" ||
          e.target.name === "expedientes_tipos" ||
          e.target.name === "expedientes_estados" ||
          e.target.name === "hechos_categorias" ||
          e.target.name === "hechos_regiones" ||
          e.target.name === "pertinencias_regiones" ||
          e.target.name === "pertinencias_tipologias") &&
        valor !== ""
      ) {
        valor = Array.from(e.target.selectedOptions, (item) => item.value);
      }
      this.setState({ [e.target.name]: valor });
    }
  };

  render = () => {
    const {
      limpiarMensajes,
      normas_alertas_filtros,
      normas_alerta_crear,
      normas_alerta_crear_error,
    } = this.props;

    if (
      !normas_alertas_filtros ||
      !normas_alertas_filtros.organismos ||
      normas_alertas_filtros.organismos.length === 0
    ) {
      return <Navigate to="/legal-tracking/alertas" />;
    }

    if (Object.entries(normas_alerta_crear).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Alerta creada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: normas_alerta_crear.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.props.navigate("/legal-tracking/alertas");
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(normas_alerta_crear_error).length > 0) {
      SweetAlert.close();
      if (
        normas_alerta_crear_error.data &&
        normas_alerta_crear_error.data.message
      ) {
        SweetAlert.fire({
          title: "Alerta no creada",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: normas_alerta_crear_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    const { filtro_organismos } = this.state;

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="leyes_alertas" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tower-broadcast"></i> ALERTAS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5>NUEVA ALERTA</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Selecciona uno o más Organismos</label>
                          <CheckTreePicker
                            name="filtro_organismos"
                            data={normas_alertas_filtros.organismos}
                            onChange={(value) => {
                              this.setState({ filtro_organismos: value });
                            }}
                            value={filtro_organismos}
                            locale={{
                              noResultsText: "Sin resultados",
                              placeholder: "Selecciona una opción",
                              searchPlaceholder: "Buscar",
                            }}
                            block
                          />
                          {normas_alerta_crear_error &&
                            normas_alerta_crear_error.data &&
                            normas_alerta_crear_error.data.tipo &&
                            normas_alerta_crear_error.data.tipo.map(
                              (err, x) => (
                                <div key={x} className="invalid-feedback">
                                  {err}
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Link
                        className="btn btn-danger"
                        to="/legal-tracking/alertas"
                      >
                        <i className="fa fa-trash"></i> Cancelar
                      </Link>
                      <button
                        className="btn btn-primary"
                        onClick={this.creacionAlerta}
                      >
                        <i className="fa fa-save"></i> Crear nueva alerta
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Nueva.propTypes = {
  crearAlerta: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  normas_alerta_crear: PropTypes.object.isRequired,
  normas_alerta_crear_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  normas_alertas_filtros: state.leyes.normas_alertas_filtros,
  normas_alerta_crear: state.leyes.normas_alerta_crear,
  normas_alerta_crear_error: state.leyes.normas_alerta_crear_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ crearAlerta, limpiarMensajes }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nueva));
