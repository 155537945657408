import React, { Component } from "react";

import currencyformatter from "currencyformatter.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerEstadisticas } from "../../redux/actions/usuario/estadisticas/action";
import { Footer, Navbar, SidebarInicio } from "../general";

export class Estadisticas extends Component {
  componentDidMount = () => {
    const { obtenerEstadisticas, estadisticas, user } = this.props;
    if (
      Object.entries(estadisticas).length === 0 &&
      user.modulos &&
      (user.modulos.vigilances || user.modulos.laws)
    ) {
      obtenerEstadisticas();
    }
  };

  render = () => {
    const { user, estadisticas, is_loading_vigilancias } = this.props;
    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Estadisticas",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las estadisticas, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="estadisticas" />
        <div className="page-content">
          <div className="container-fluid">
            {user.modulos && (user.modulos.vigilances || user.modulos.laws) && (
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="float-start">
                      <i className="fa fa-chart-bar"></i> ESTADISTICAS
                    </h4>
                  </div>
                </div>
                <div className="col-12">
                  {estadisticas &&
                    estadisticas.leychile &&
                    estadisticas.leychile.length > 0 && (
                      <div className="card mb-3">
                        <div className="card-header">
                          <h5 className="float-start">
                            BIBLIOTECA DEL CONGRESO NACIONAL DE CHILE (LEYCHILE)
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            {estadisticas.leychile.map((valor, y) => (
                              <div className="col-md-4 col-lg-4" key={y}>
                                <div className="card-eco bg-soft-danger">
                                  <div className="card-body">
                                    <div className="row d-flex justify-content-center">
                                      <div className="col-10">
                                        <p className="text-dark mb-0 fw-semibold">
                                          {valor.nombre}
                                        </p>
                                        <h3 className="my-1 font-20 fw-bold">
                                          {currencyformatter.format(
                                            valor.cantidad,
                                            {
                                              locale: "es_CL",
                                              pattern: "#,##0",
                                            }
                                          )}
                                        </h3>
                                      </div>
                                      <div className="col-2 align-self-center">
                                        <div className="d-flex justify-content-center align-items-center mx-auto">
                                          <i className="fa fa-gavel font-24 align-self-center text-danger"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  {estadisticas &&
                    estadisticas.seia &&
                    estadisticas.seia.length > 0 && (
                      <div className="card mb-3">
                        <div className="card-header">
                          <h5 className="float-start">
                            SISTEMA DE EVALUACIÓN DE IMPACTO AMBIENTAL (SEIA)
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            {estadisticas.seia.map((valor, y) => (
                              <div className="col-md-4 col-lg-4" key={y}>
                                <div className="card-eco bg-soft-danger">
                                  <div className="card-body">
                                    <div className="row d-flex justify-content-center">
                                      <div className="col-10">
                                        <p className="text-dark mb-0 fw-semibold">
                                          {valor.nombre}
                                        </p>
                                        <h3 className="my-1 font-20 fw-bold">
                                          {currencyformatter.format(
                                            valor.cantidad,
                                            {
                                              locale: "es_CL",
                                              pattern: "#,##0",
                                            }
                                          )}
                                        </h3>
                                      </div>
                                      <div className="col-2 align-self-center">
                                        <div className="d-flex justify-content-center align-items-center mx-auto">
                                          <i className="fa fa-seedling font-24 align-self-center text-danger"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  {estadisticas &&
                    estadisticas.sea &&
                    estadisticas.sea.length > 0 && (
                      <div className="card mb-3">
                        <div className="card-header">
                          <h5 className="float-start">
                            SERVICIO DE EVALUACIÓN AMBIENTAL (SEA)
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            {estadisticas.sea.map((valor, y) => (
                              <div className="col-md-4 col-lg-4" key={y}>
                                <div className="card-eco bg-soft-danger">
                                  <div className="card-body">
                                    <div className="row d-flex justify-content-center">
                                      <div className="col-10">
                                        <p className="text-dark mb-0 fw-semibold">
                                          {valor.nombre}
                                        </p>
                                        <h3 className="my-1 font-20 fw-bold">
                                          {currencyformatter.format(
                                            valor.cantidad,
                                            {
                                              locale: "es_CL",
                                              pattern: "#,##0",
                                            }
                                          )}
                                        </h3>
                                      </div>
                                      <div className="col-2 align-self-center">
                                        <div className="d-flex justify-content-center align-items-center mx-auto">
                                          <i className="fa fa-calendar-check font-24 align-self-center text-danger"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  {estadisticas &&
                    estadisticas.snifa &&
                    estadisticas.snifa.length > 0 && (
                      <div className="card mb-3">
                        <div className="card-header">
                          <h5 className="float-start">
                            SISTEMA NACIONAL DE INFORMACIÓN DE FISCALIZACIÓN
                            AMBIENTAL (SNIFA)
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            {estadisticas.snifa.map((valor, y) => (
                              <div className="col-md-4 col-lg-4" key={y}>
                                <div className="card-eco bg-soft-danger">
                                  <div className="card-body">
                                    <div className="row d-flex justify-content-center">
                                      <div className="col-10">
                                        <p className="text-dark mb-0 fw-semibold">
                                          {valor.nombre}
                                        </p>
                                        <h3 className="my-1 font-20 fw-bold">
                                          {currencyformatter.format(
                                            valor.cantidad,
                                            {
                                              locale: "es_CL",
                                              pattern: "#,##0",
                                            }
                                          )}
                                        </h3>
                                      </div>
                                      <div className="col-2 align-self-center">
                                        <div className="d-flex justify-content-center align-items-center mx-auto">
                                          <i className="fa fa-bullhorn font-24 align-self-center text-danger"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Estadisticas.propTypes = {
  obtenerEstadisticas: PropTypes.func.isRequired,
  estadisticas: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  estadisticas: state.vigilancias.estadisticas,
  user: state.auth.user,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerEstadisticas }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Estadisticas);
