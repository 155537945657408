import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  generarEntregable,
  limpiarGenerarEntregable,
} from "../../../redux/actions/permitting/action";
import {
  Footer,
  Navbar,
  SiderbarPermittingParametrizacion,
} from "../../general";
import { withRouter } from "../../general/with_router";

export class GenerarEntregable extends Component {
  componentDidMount = () => {
    const { limpiarGenerarEntregable } = this.props;
    limpiarGenerarEntregable();
  };

  generacionEntregable = () => {
    const { generarEntregable, consulta_seleccionada } = this.props;
    if (consulta_seleccionada > 0) {
      generarEntregable({
        consulta: consulta_seleccionada,
      });

      SweetAlert.fire({
        title: "Generación de Entregables",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos validando los datos ingresados, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    }
  };

  render = () => {
    const {
      generar_entregables,
      generar_entregables_error,
      consulta_seleccionada,
    } = this.props;

    if (consulta_seleccionada === 0) {
      return <Navigate to="/permitting-target/proyectos" />;
    }

    if (Object.entries(generar_entregables).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Generación de Entregables",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: generar_entregables.message,
        icon: generar_entregables.entregables ? "success" : "warning",
        confirmButtonText: generar_entregables.entregables
          ? 'Ir a entregables <i class="fa fa-thumbs-up"></i>'
          : 'Ir al listado de Proyectos <i class="fa fa-thumbs-up"></i>',
        customClass: {
          confirmButton: "btn btn-primary me-3",
        },
        buttonsStyling: false,
      }).then(() => {
        this.props.navigate(
          generar_entregables.entregables
            ? "/permitting-target/aplicabilidad/dashboard"
            : "/permitting-target/proyectos"
        );
      });
    }
    if (Object.entries(generar_entregables_error).length > 0) {
      SweetAlert.fire({
        title: "Generación de Entregables",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: generar_entregables_error.data
          ? generar_entregables_error.data.message
          : generar_entregables_error.message,
        icon: "error",
        confirmButtonText: 'Volver <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SiderbarPermittingParametrizacion menu_1="permitting_entregables" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box float-start">
                  <h4 className="mb-1">
                    <i className="fa fa-cog"></i> GENERAR ENTREGABLES
                  </h4>
                </div>
              </div>
            </div>
            <div className="card card-eco">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h4>
                      ¿Estás seguro que quieres avanzar a generar el resultado
                      de tu consulta?
                    </h4>
                    <p>
                      Este paso es irreversible y no podrás editar nuevamente la
                      parametrización de esta consulta.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-footer text-center">
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    onClick={this.generacionEntregable}
                    className="btn btn-primary"
                  >
                    <i className="fa fa-check"></i> Sí, Generar entregables
                  </button>
                  <Link
                    className="btn btn-soft-primary"
                    to="/permitting-target/parametrizacion/secciones"
                  >
                    <i className="fa fa-times"></i> No, Cancelar
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

GenerarEntregable.propTypes = {
  generarEntregable: PropTypes.func.isRequired,
  limpiarGenerarEntregable: PropTypes.func.isRequired,
  generar_entregables: PropTypes.object.isRequired,
  generar_entregables_error: PropTypes.object.isRequired,
  consulta_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  generar_entregables: state.permitting.generar_entregables,
  generar_entregables_error: state.permitting.generar_entregables_error,
  consulta_seleccionada: state.permitting.consulta_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { generarEntregable, limpiarGenerarEntregable },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GenerarEntregable)
);
