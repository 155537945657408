import React, { Component } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerRcasEstadistica } from "../../../redux/actions/vigilancias/rcas/action";
import { Chart, Footer, Navbar, SidebarInicio } from "../../general";

export class Estadisticas extends Component {
  componentDidMount = () => {
    const { obtenerRcasEstadistica, rcas_estadisticas } = this.props;
    if (Object.entries(rcas_estadisticas).length === 0) {
      obtenerRcasEstadistica(1);
    }
  };

  render = () => {
    const { rcas_estadisticas, is_loading_vigilancias } = this.props;

    var regiones = _.countBy(
      _.orderBy(rcas_estadisticas.result, "region_display"),
      "region_display"
    );
    var tipos = _.countBy(
      _.orderBy(rcas_estadisticas.result, "tipo_display"),
      "tipo_display"
    );
    var fases = _.countBy(_.orderBy(rcas_estadisticas.result, "fase"), "fase");
    var tipologias = _.countBy(
      _.orderBy(rcas_estadisticas.result, "tipologia"),
      "tipologia"
    );

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "SEIA",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo RCA, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_rcas" menu_2="estadistica" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-calendar-check"></i> RESOLUCIONES DE
                    CALIFICACIÓN AMBIENTAL{" "}
                    <small>
                      (
                      {rcas_estadisticas &&
                        rcas_estadisticas.total &&
                        rcas_estadisticas.total}
                      )
                    </small>
                  </h4>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  is_vertical
                  data={regiones}
                  nombre="Rcas"
                  titulo="Rcas por región"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  is_vertical
                  data={tipos}
                  nombre="Rcas"
                  titulo="Rcas por categoría"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart data={fases} nombre="Rcas" titulo="Rcas por fase" />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  data={tipologias}
                  nombre="Rcas"
                  titulo="Rcas por tipologia"
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Estadisticas.propTypes = {
  obtenerRcasEstadistica: PropTypes.func.isRequired,
  rcas_estadisticas: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  rcas_estadisticas: state.vigilancias.rcas_estadisticas,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerRcasEstadistica }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Estadisticas);
