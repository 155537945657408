import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VGC } from "../equipos/types";
import {
  TEAMS_LIMPIAR_REQUERIMIENTOS_INGRESO_FILTROS,
  TEAMS_REQUERIMIENTOS_INGRESOS_FALLIDO,
  TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_FALLIDO,
  TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_INPUT,
  TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_OBTENER,
  TEAMS_REQUERIMIENTOS_INGRESOS_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerRequerimientosIngresos =
  (pagina) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VGC });
    axios
      .get(
        `${url_api}/api/teams/requerimientos-ingresos/${pagina}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: TEAMS_REQUERIMIENTOS_INGRESOS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : TEAMS_REQUERIMIENTOS_INGRESOS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerRequerimientosIngresosFiltros =
  (data) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VGC });
    axios
      .post(
        `${url_api}/api/teams/requerimientos-ingresos/${data.pagina}`,
        data.filtros,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_INPUT,
          payload: data.filtros,
        });
        dispatch({
          type: TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : TEAMS_REQUERIMIENTOS_INGRESOS_FILTROS_FALLIDO,
          payload: error,
        });
      });
  };

export const limpiarFiltroRequerimientosIngreso = () => (dispatch) => {
  dispatch({ type: TEAMS_LIMPIAR_REQUERIMIENTOS_INGRESO_FILTROS });
};
