import React, { Component } from "react";

import { Tooltip } from "react-tooltip";

export class Paginador extends Component {
  nuevaPagina = (x) => {
    const { nuevaPagina } = this.props;
    nuevaPagina(x);
  };

  render = () => {
    const { paginador } = this.props;
    if (paginador.range) {
      var pagina_limit = paginador.page > 9 ? paginador.page + 5 : 10;
      var pagina_inicio = paginador.range[0];
      var pagina_fin = paginador.range[paginador.range.length - 1];
      var pagina_anterior = paginador.page - 9 > 0 ? paginador.page - 5 : 0;
    }

    return (
      paginador.range &&
      paginador.range.length > 0 &&
      paginador.total > 0 && (
        <div className="d-flex align-items-center">
          <ul className="pagination pagination-sm">
            {paginador.page > pagina_inicio && (
              <li
                data-tip
                data-tooltip-id="pagina_inicio"
                onClick={this.nuevaPagina.bind(this, 1)}
                key={0}
                className={`page-item`}
              >
                <Tooltip id="pagina_inicio" aria-haspopup="true">
                  Volver al principio
                </Tooltip>
                <button className="page-link" type="button">
                  <i className="fa fa-angle-double-left"></i>
                </button>
              </li>
            )}
            {paginador.range.map(
              (x, y) =>
                pagina_anterior <= x &&
                pagina_limit >= x && (
                  <li
                    onClick={this.nuevaPagina.bind(this, x)}
                    key={y}
                    className={`page-item${
                      x === paginador.page ? " active" : ""
                    }`}
                  >
                    <button className="page-link" type="button">
                      {x}
                    </button>
                  </li>
                )
            )}
            {paginador.page < pagina_fin && (
              <li
                data-tip
                data-tooltip-id="pagina_fin"
                onClick={this.nuevaPagina.bind(this, pagina_fin)}
                key={pagina_fin}
                className={`page-item`}
              >
                <Tooltip id="pagina_fin" aria-haspopup="true">
                  Ir al final
                </Tooltip>
                <button className="page-link" type="button">
                  <i className="fa fa-angle-double-right"></i>
                </button>
              </li>
            )}
          </ul>
        </div>
      )
    );
  };
}

export default Paginador;
