import React, { Component, Fragment } from "react";

import { Maps } from "../../general";
import { Tabs as Rcas } from "../rcas";

export class Tabs extends Component {
  render = () => {
    const { expedientes } = this.props;

    if (expedientes && expedientes.length < 1) {
      return (
        <tr className="text-center">
          <td colSpan="7">No existen Expedientes SEIA registrados</td>
        </tr>
      );
    } else {
      return (
        <div className="align-items-start">
          <div className="row">
            {expedientes.length > 1 && (
              <div className="col-3">
                <div
                  className="nav flex-column nav-pills me-3"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {expedientes.map((expediente, x) => (
                    <button
                      key={x}
                      className={`nav-link ${
                        x === 0 ? "active" : ""
                      } text-start`}
                      id={`expediente-tab-${expediente.expediente}`}
                      data-bs-toggle="pill"
                      data-bs-target={`#expediente-${expediente.expediente}`}
                      type="button"
                      role="tab"
                      aria-controls={`expediente-${expediente.expediente}`}
                      aria-selected={x === 0 ? "true" : "false"}
                    >
                      {expediente.nombre}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className={`col-${expedientes.length > 1 ? 9 : 12}`}>
              <div className="tab-content" id="v-pills-expedientes">
                {expedientes.map((expediente, x) => (
                  <div
                    key={x}
                    className={`tab-pane fade ${x === 0 ? "show active" : ""}`}
                    id={`expediente-${expediente.expediente}`}
                    role="tabpanel"
                    aria-labelledby={`expediente-tab-${expediente.expediente}`}
                    tabIndex="0"
                  >
                    <>
                      <ul className="nav nav-pills nav-justified">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id={`expediente-general-tab-${expediente.expediente}`}
                            data-bs-toggle="pill"
                            data-bs-target={`#expediente-general-${expediente.expediente}`}
                            type="button"
                            role="tab"
                            aria-controls={`expediente-general-${expediente.expediente}`}
                            aria-selected="true"
                          >
                            Información del Expediente
                          </button>
                        </li>

                        {expediente.folios && expediente.folios.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`expediente-folios-tab-${expediente.expediente}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#expediente-folios-${expediente.expediente}`}
                              type="button"
                              role="tab"
                              aria-controls={`expediente-folios-${expediente.expediente}`}
                              aria-selected="true"
                            >
                              Folios{" "}
                              <small>
                                (
                                {expediente.folios
                                  ? expediente.folios.length
                                  : 0}
                                )
                              </small>
                            </button>
                          </li>
                        )}
                        {expediente.recursos &&
                          expediente.recursos.length > 0 && (
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id={`expediente-recursos-tab-${expediente.expediente}`}
                                data-bs-toggle="pill"
                                data-bs-target={`#expediente-recursos-${expediente.expediente}`}
                                type="button"
                                role="tab"
                                aria-controls={`expediente-recursos-${expediente.expediente}`}
                                aria-selected="true"
                              >
                                Recursos Administrativos{" "}
                                <small>
                                  (
                                  {expediente.recursos
                                    ? expediente.recursos.length
                                    : 0}
                                  )
                                </small>
                              </button>
                            </li>
                          )}
                        {expediente.pas && expediente.pas.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`expediente-pas-tab-${expediente.expediente}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#expediente-pas-${expediente.expediente}`}
                              type="button"
                              role="tab"
                              aria-controls={`expediente-pas-${expediente.expediente}`}
                              aria-selected="true"
                            >
                              PAS{" "}
                              <small>
                                ({expediente.pas ? expediente.pas.length : 0})
                              </small>
                            </button>
                          </li>
                        )}
                        {expediente.rcas && expediente.rcas.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`expediente-rcas-tab-${expediente.expediente}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#expediente-rcas-${expediente.expediente}`}
                              type="button"
                              role="tab"
                              aria-controls={`expediente-rcas-${expediente.expediente}`}
                              aria-selected="true"
                            >
                              RCA{" "}
                              <small>
                                ({expediente.rcas ? expediente.rcas.length : 0})
                              </small>
                            </button>
                          </li>
                        )}
                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show mt-3"
                          id={`expediente-general-${expediente.expediente}`}
                          aria-labelledby={`expediente-general-tab-${expediente.expediente}`}
                          role="tabpanel"
                        >
                          <div className="table-responsive">
                            <Fragment key={x}>
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td className="table-secondary w-10 text-dark">
                                      Nombre
                                    </td>
                                    <td>{expediente.nombre}</td>
                                  </tr>
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Expediente
                                    </td>
                                    <td>
                                      <a
                                        href={`https://seia.sea.gob.cl/expediente/ficha/fichaPrincipal.php?modo=normal&id_expediente=${expediente.expediente}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Ver expediente en SEIA{" "}
                                        <i className="fa fa-angle-right"></i>
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Registros de Estado
                                    </td>
                                    <td>
                                      <table className="table table-bordered table-hover table-sm">
                                        <thead className="table-secondary">
                                          <tr>
                                            <th className="w-10">Fecha</th>
                                            <th>Estado</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {expediente.registros &&
                                            expediente.registros.map(
                                              (registro, x) => (
                                                <tr key={x}>
                                                  <td>{registro.fecha}</td>
                                                  <td>
                                                    {registro.estado}
                                                    {registro.nombre && (
                                                      <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={registro.url}
                                                      >
                                                        {registro.nombre}{" "}
                                                        <i className="fa fa-angle-right"></i>
                                                      </a>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Estado
                                    </td>
                                    <td>{expediente.estado}</td>
                                  </tr>
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Región
                                    </td>
                                    <td>{expediente.region_display}</td>
                                  </tr>
                                  {expediente.comuna &&
                                    expediente.comuna.length > 0 && (
                                      <tr>
                                        <td className="table-secondary text-dark">
                                          Comuna ({expediente.comuna.length})
                                        </td>
                                        <td>
                                          <ul className="mb-0">
                                            {expediente.comuna.map((com, x) => (
                                              <li key={x}>{com}</li>
                                            ))}
                                          </ul>
                                        </td>
                                      </tr>
                                    )}
                                  {expediente.inversion && (
                                    <tr>
                                      <td className="table-secondary text-dark">
                                        Inversión
                                      </td>
                                      <td>
                                        {expediente.inversion
                                          .toString()
                                          .replace(".", ",")}{" "}
                                        millones de dolares
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Tipo
                                    </td>
                                    <td>{expediente.tipo}</td>
                                  </tr>
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Tipología
                                    </td>
                                    <td>{expediente.tipologia_display}</td>
                                  </tr>
                                  {expediente.descripcion && (
                                    <tr>
                                      <td className="table-secondary text-dark">
                                        Descripcion
                                      </td>
                                      <td>{expediente.descripcion}</td>
                                    </tr>
                                  )}
                                  {expediente.tag &&
                                    expediente.tag.length > 0 && (
                                      <tr>
                                        <td className="table-secondary text-dark">
                                          Tag
                                        </td>
                                        <td>
                                          {expediente.tag.map((tag, x) => (
                                            <span
                                              key={x}
                                              className="badge badge-soft-primary p-1 me-1 mt-1 font-11"
                                            >
                                              #{tag}
                                            </span>
                                          ))}
                                        </td>
                                      </tr>
                                    )}
                                  {expediente.latitud &&
                                    expediente.longitud && (
                                      <tr>
                                        <td className="table-secondary text-dark">
                                          Mapa
                                        </td>
                                        <td>
                                          <Maps
                                            center={[
                                              expediente.latitud,
                                              expediente.longitud,
                                            ]}
                                            zoom={11}
                                            markers={[
                                              {
                                                titulo: "SEIA - Expediente",
                                                position: [
                                                  expediente.latitud,
                                                  expediente.longitud,
                                                ],
                                                nombre: expediente.nombre,
                                              },
                                            ]}
                                            size={400}
                                          />
                                        </td>
                                      </tr>
                                    )}
                                </tbody>
                              </table>
                            </Fragment>
                          </div>
                        </div>
                        {expediente.folios && expediente.folios.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`expediente-folios-${expediente.expediente}`}
                            aria-labelledby={`expediente-folios-tab-${expediente.expediente}`}
                            role="tabpanel"
                          >
                            <table className="table table-bordered table-hover">
                              <thead className="table-secondary">
                                <tr>
                                  <th className="text-center w-3">ID</th>
                                  <th className="w-10">Número</th>
                                  <th className="w-10">Folio</th>
                                  <th className="w-20">Remitido por</th>
                                  <th className="w-20">Destinatario</th>
                                  <th className="w-10">Fecha</th>
                                  <th>Documentos</th>
                                </tr>
                              </thead>
                              <tbody>
                                {expediente.folios.map((folio, z) => (
                                  <tr key={z}>
                                    <td className="text-center">{z + 1}</td>
                                    <td>{folio.numero}</td>
                                    <td>{folio.folio}</td>
                                    <td className="text-uppercase">
                                      {folio.remitido}
                                    </td>
                                    <td className="text-uppercase">
                                      {folio.destinatario_url ? (
                                        <a
                                          target="_blank"
                                          href={folio.destinatario_url}
                                          rel="noreferrer"
                                        >
                                          <i className="fa fa-link me-1"></i>
                                          {folio.destinatario_nombre}
                                        </a>
                                      ) : (
                                        folio.destinatario_nombre
                                      )}
                                    </td>
                                    <td>{folio.fecha}</td>
                                    <td>
                                      <div className="row align-items-center">
                                        {folio.documentos &&
                                          folio.documentos.length > 0 && (
                                            <div
                                              className={`col-${
                                                folio.respuestas_ai &&
                                                folio.respuestas_ai.length > 0
                                                  ? "10"
                                                  : "12"
                                              }`}
                                            >
                                              {folio.documentos.map(
                                                (doc, y) => (
                                                  <p key={y} className="mb-0">
                                                    <a
                                                      href={doc.url}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {doc.nombre}{" "}
                                                      <i className="fa fa-angle-right"></i>
                                                    </a>
                                                  </p>
                                                )
                                              )}
                                            </div>
                                          )}
                                        {folio.documentos &&
                                          folio.documentos.length < 1 && (
                                            <span className="badge badge-soft-dark">
                                              Sin Información
                                            </span>
                                          )}
                                        {folio.respuestas_ai &&
                                          folio.respuestas_ai.length > 0 && (
                                            <>
                                              <div className="col-2 text-end">
                                                <button
                                                  type="button"
                                                  className="btn btn-danger btn-sm "
                                                  data-bs-toggle="modal"
                                                  data-bs-target={`#modal-ai${z}`}
                                                >
                                                  <i className="fa fa-robot"></i>
                                                </button>
                                              </div>
                                              <div
                                                className="modal fade"
                                                id={`modal-ai${z}`}
                                                tabIndex="-1"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                              >
                                                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                                                  <div className="modal-content">
                                                    <div className="modal-header">
                                                      <h5
                                                        className="text-danger"
                                                        id="exampleModalLabel"
                                                      >
                                                        <i className="fa fa-robot"></i>{" "}
                                                        Modelos AI
                                                      </h5>
                                                      <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                      ></button>
                                                    </div>
                                                    <div className="modal-body">
                                                      <table className="table table-sm">
                                                        <thead className="bg-dark">
                                                          <tr>
                                                            <th className="w-15">
                                                              Modelo
                                                            </th>
                                                            <th>
                                                              Articulos
                                                              Obtenidos
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {folio.respuestas_ai &&
                                                            folio.respuestas_ai
                                                              .length > 0 &&
                                                            folio.respuestas_ai.map(
                                                              (ai, x) => (
                                                                <tr key={x}>
                                                                  <td>
                                                                    {
                                                                      ai.proveedor
                                                                    }{" "}
                                                                    <br />{" "}
                                                                    {ai.modelo}
                                                                  </td>
                                                                  <td>
                                                                    {ai.mensaje_eval &&
                                                                      ai
                                                                        .mensaje_eval
                                                                        .length >
                                                                        0 && (
                                                                        <ul>
                                                                          {ai.mensaje_eval.map(
                                                                            (
                                                                              mensaje,
                                                                              y
                                                                            ) => (
                                                                              <li
                                                                                key={
                                                                                  y
                                                                                }
                                                                              >
                                                                                <b>
                                                                                  {
                                                                                    mensaje.articulo
                                                                                  }
                                                                                </b>{" "}
                                                                                <br />
                                                                                {
                                                                                  mensaje.razones_justificaciones_motivos
                                                                                }
                                                                              </li>
                                                                            )
                                                                          )}
                                                                        </ul>
                                                                      )}
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="modal-footer">
                                                      <button
                                                        type="button"
                                                        className="btn btn-soft-dark"
                                                        data-bs-dismiss="modal"
                                                      >
                                                        Cerrar
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {expediente.recursos &&
                          expediente.recursos.length > 0 && (
                            <div
                              className="tab-pane fade mt-3"
                              id={`expediente-recursos-${expediente.expediente}`}
                              aria-labelledby={`expediente-recursos-tab-${expediente.expediente}`}
                              role="tabpanel"
                            >
                              <table className="table table-bordered table-hover">
                                <thead className="table-secondary">
                                  <tr>
                                    <th className="text-center w-3">ID</th>
                                    <th>Nombre</th>
                                    <th className="w-10">Fecha</th>
                                    <th className="w-20">Estado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {expediente.recursos.map((recurso, z) => (
                                    <tr key={z}>
                                      <td className="text-center">{z + 1}</td>
                                      <td>
                                        <a
                                          target="_blank"
                                          href={recurso.url}
                                          rel="noreferrer"
                                        >
                                          {recurso.nombre}{" "}
                                          <i className="fa fa-angle-right"></i>
                                        </a>
                                      </td>
                                      <td>{recurso.fecha}</td>
                                      <td>{recurso.estado}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        {expediente.pas && expediente.pas.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`expediente-pas-${expediente.expediente}`}
                            aria-labelledby={`expediente-pas-tab-${expediente.expediente}`}
                            role="tabpanel"
                          >
                            <table className="table table-bordered table-hover">
                              <thead className="table-secondary">
                                <tr>
                                  <th className="text-center w-3">ID</th>
                                  <th>Nombre</th>
                                  <th className="w-10">Número</th>
                                  <th className="w-10">Tipo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {expediente.pas.map((ps, x) => (
                                  <tr key={x}>
                                    <td className="text-center">{x + 1}</td>
                                    <td>{ps.nombre}</td>
                                    <td>{ps.numero}</td>
                                    <td>{ps.tipo}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {expediente.rcas && expediente.rcas.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`expediente-rcas-${expediente.expediente}`}
                            aria-labelledby={`expediente-rcas-tab-${expediente.expediente}`}
                            role="tabpanel"
                          >
                            <Rcas rcas={expediente.rcas} />
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}

export default Tabs;
