import React, { Component } from "react";

import { Maps } from "../../general/";
import { TablaPequena as Titulares } from "../titulares";

export class TablaMedia extends Component {
  render = () => {
    const { unidades_fiscalizables } = this.props;

    return unidades_fiscalizables && unidades_fiscalizables.length > 0 ? (
      unidades_fiscalizables.map((unidad_fiscalizable, x) => (
        <div className="table-responsive" key={x}>
          <table className="table table-sm table-bordered">
            <tbody>
              <tr>
                <td className="table-secondary w-10 text-dark">Nombre</td>
                <td>{unidad_fiscalizable.nombre}</td>
              </tr>
              <tr>
                <td className="table-secondary text-dark">Ficha</td>
                <td>
                  <a
                    href={unidad_fiscalizable.url_snifa}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver ficha <i className="fa fa-angle-right"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td className="table-secondary text-dark">Región</td>
                <td>{unidad_fiscalizable.region_display}</td>
              </tr>
              <tr>
                <td className="table-secondary text-dark">Comuna</td>
                <td>{unidad_fiscalizable.comuna}</td>
              </tr>
              {unidad_fiscalizable.categoria_display && (
                <tr>
                  <td className="table-secondary text-dark">Categoría</td>
                  <td>{unidad_fiscalizable.categoria_display}</td>
                </tr>
              )}
              {unidad_fiscalizable.titular &&
                unidad_fiscalizable.titular.length > 0 && (
                  <tr>
                    <td className="table-secondary text-dark">Titular</td>
                    <td>
                      <Titulares titulares={unidad_fiscalizable.titular} />
                    </td>
                  </tr>
                )}
              {unidad_fiscalizable.latitud !== 0 &&
                unidad_fiscalizable.longitud !== 0 &&
                unidad_fiscalizable.latitud !== undefined &&
                unidad_fiscalizable.longitud !== undefined && (
                  <tr>
                    <td className="table-secondary text-dark">Mapa</td>
                    <td>
                      <Maps
                        center={[
                          unidad_fiscalizable.latitud,
                          unidad_fiscalizable.longitud,
                        ]}
                        zoom={14}
                        markers={[
                          {
                            titulo: "SNIFA - Unidad Fiscalizable",
                            position: [
                              unidad_fiscalizable.latitud,
                              unidad_fiscalizable.longitud,
                            ],
                            nombre: unidad_fiscalizable.nombre,
                          },
                        ]}
                        size={400}
                      />
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      ))
    ) : (
      <h6>No existen unidades fiscalizables</h6>
    );
  };
}

export default TablaMedia;
