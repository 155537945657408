import React, { Component } from "react";

export class TablaPequena extends Component {
  render = () => {
    const { encargados } = this.props;

    return (
      encargados.length > 0 &&
      encargados.map((encargado, x) => (
        <div className="table-responsive" key={x}>
          <table className="table">
            <tbody>
              <tr>
                <td className="table-secondary w-10 text-dark">Nombre</td>
                <td>{encargado.nombre}</td>
              </tr>
              <tr>
                <td className="table-secondary text-dark">Email</td>
                <td>{encargado.email ? encargado.email : "S/I"}</td>
              </tr>
              <tr>
                <td className="table-secondary text-dark">Teléfono</td>
                <td>{encargado.telefono ? encargado.telefono : "S/I"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ))
    );
  };
}

export default TablaPequena;
