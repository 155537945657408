import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  duplicarEntregable,
  eliminarEntregable,
  estadoEntregable,
  limpiarMensajes,
  obtenerTarea,
} from "../../../redux/actions/tareas/action";
import { Tabla as CargaTabla } from "../carga/";
import { Tabla as RecursoTabla } from "../recurso/";
import { Tabla as RequisitoTabla } from "../requisito/";
import { Editar as EditarEntregable } from "./";
import Ficha from "./ficha";

export class Detalle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_collapse: 0,
      entregable_editar_mostrar: false,
    };
  }

  cancelarEdicionEntregable = () => {
    const { limpiarMensajes } = this.props;
    this.setState({
      entregable_editar_mostrar: false,
    });
    limpiarMensajes();
  };

  mostrarEdicionEntregable = (x) => {
    this.setState({
      id_collapse: x,
      entregable_editar_mostrar: true,
    });
  };

  inicioEntregable = (entregable) => {
    const { estadoEntregable, tarea_seleccionada } = this.props;
    SweetAlert.fire({
      title: "Iniciar entregable",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de iniciar el entregable?`,
      icon: "info",
      confirmButtonText: 'Si, Iniciar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        estadoEntregable({
          entregable: entregable.id,
          estado: "en_curso",
          tarea: tarea_seleccionada,
        });
        SweetAlert.fire({
          title: "Entregable",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos guardando el entregable, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  duplicacionEntregable = (entregable) => {
    const { duplicarEntregable, tarea_seleccionada } = this.props;
    SweetAlert.fire({
      title: "Duplicar entregable",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de duplicar todo el entregable y su contenido?`,
      icon: "info",
      confirmButtonText: 'Si, Duplicar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        duplicarEntregable({
          tarea: tarea_seleccionada,
          entregable: entregable.id,
        });
        SweetAlert.fire({
          title: "Entregable",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos duplicando el entregable, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  eliminacionEntregable = (entregable) => {
    const { eliminarEntregable, tarea_seleccionada } = this.props;
    SweetAlert.fire({
      title: "Eliminar entregable",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de eliminar el entregable?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarEntregable({
          tarea: tarea_seleccionada,
          entregable: entregable.id,
        });
        SweetAlert.fire({
          title: "Entregable",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos eliminando el entregable, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  terminoEntregable = (entregable) => {
    const { estadoEntregable, tarea_seleccionada } = this.props;
    SweetAlert.fire({
      title: "Finalizar entregable",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de finalizar el entregable?`,
      icon: "info",
      confirmButtonText: 'Si, Finalizar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        estadoEntregable({
          entregable: entregable.id,
          estado: "finalizada",
          tarea: tarea_seleccionada,
        });
        SweetAlert.fire({
          title: "Tarea",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos guardando el entregable, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  tareaSeleccionada = () => {
    const { obtenerTarea, tarea_seleccionada } = this.props;
    obtenerTarea({
      tarea: tarea_seleccionada,
    });
  };

  collapseIn = (id, editar) => {
    const { id_collapse } = this.state;

    if (id_collapse === id && !editar) {
      id = 0;
    }
    this.setState({
      id_collapse: id,
    });
  };

  render = () => {
    const {
      tarea,
      user_equipo,
      entregable_estado,
      entregable_estado_error,
      limpiarMensajes,
      entregable_duplicar,
      entregable_duplicar_error,
      entregable_eliminar,
      entregable_eliminar_error,
    } = this.props;
    const { id_collapse, entregable_editar_mostrar } = this.state;

    if (Object.entries(entregable_estado).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Entregable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: entregable_estado.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.tareaSeleccionada();
        }
      });
    }

    if (Object.entries(entregable_estado_error).length > 0) {
      SweetAlert.close();
      if (
        entregable_estado_error.data &&
        entregable_estado_error.data.message
      ) {
        SweetAlert.fire({
          title: "Entregable",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: entregable_estado_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(entregable_duplicar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Entregable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: entregable_duplicar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.tareaSeleccionada();
        }
      });
    }

    if (Object.entries(entregable_duplicar_error).length > 0) {
      SweetAlert.close();
      if (
        entregable_duplicar_error.data &&
        entregable_duplicar_error.data.message
      ) {
        SweetAlert.fire({
          title: "Entregable",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: entregable_duplicar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(entregable_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Entregable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: entregable_eliminar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.tareaSeleccionada();
        }
      });
    }

    if (Object.entries(entregable_eliminar_error).length > 0) {
      SweetAlert.close();
      if (
        entregable_eliminar_error.data &&
        entregable_eliminar_error.data.message
      ) {
        SweetAlert.fire({
          title: "Entregable",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: entregable_eliminar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    return tarea.entregables && tarea.entregables.length > 0 ? (
      tarea.entregables.map((entregable, x) => (
        <div className="card card-eco m-1" key={x}>
          <div
            className={`card-header ${
              id_collapse === x + 1 ? "bg-soft-danger" : ""
            }`}
          >
            <div className="float-end">
              <div className="btn-group">
                {user_equipo.rol_text === "Propietario" && (
                  <>
                    {entregable.estado_text === "No Iniciada" && (
                      <button
                        onClick={this.inicioEntregable.bind(this, entregable)}
                        className="btn btn-success"
                        type="button"
                      >
                        Iniciar Entregable <i className="fa fa-play-circle"></i>
                      </button>
                    )}
                    {entregable.estado_text === "En Curso" && (
                      <button
                        onClick={this.terminoEntregable.bind(this, entregable)}
                        className="btn btn-danger"
                        type="button"
                      >
                        Finalizar Entregable{" "}
                        <i className="fa fa-stop-circle"></i>
                      </button>
                    )}
                    {!entregable_editar_mostrar &&
                      entregable.estado_text !== "Finalizada" && (
                        <>
                          <button
                            data-tip
                            data-tooltip-id="editar_entregable"
                            onClick={this.mostrarEdicionEntregable.bind(
                              this,
                              x + 1
                            )}
                            className="btn btn-warning btn-xs"
                            type="button"
                          >
                            <i className="fa fa-pencil-alt"></i>
                          </button>
                          <Tooltip
                            className="text-center"
                            id="editar_entregable"
                            aria-haspopup="true"
                          >
                            Editar entregable
                          </Tooltip>
                        </>
                      )}
                    {entregable.estado_text !== "Finalizada" && (
                      <>
                        <button
                          data-tip
                          data-tooltip-id="duplicar_entregable"
                          onClick={this.duplicacionEntregable.bind(
                            this,
                            entregable
                          )}
                          className="btn btn-primary btn-xs"
                          type="button"
                        >
                          <i className="fa fa-clone"></i>
                        </button>
                        <Tooltip
                          className="text-center"
                          id="duplicar_entregable"
                          aria-haspopup="true"
                        >
                          Duplicar entregable
                        </Tooltip>
                        <button
                          data-tip
                          data-tooltip-id="eliminar_entregable"
                          onClick={this.eliminacionEntregable.bind(
                            this,
                            entregable
                          )}
                          className="btn btn-danger btn-xs"
                          type="button"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                        <Tooltip
                          className="text-center"
                          id="eliminar_entregable"
                          aria-haspopup="true"
                        >
                          Eliminar entregable
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
                {id_collapse === x + 1 ? (
                  <>
                    <button
                      data-tip
                      data-tooltip-id="esconder_entregable"
                      onClick={this.collapseIn.bind(this, x + 1, false)}
                      type="button"
                      className="btn btn-light btn-xs"
                    >
                      <i className="fa fa-angle-up"></i>
                    </button>
                    <Tooltip
                      className="text-center"
                      id="esconder_entregable"
                      aria-haspopup="true"
                    >
                      Esconde detalle
                      <br />
                      del entregable
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <button
                      data-tip
                      data-tooltip-id="ver_entregable"
                      onClick={this.collapseIn.bind(this, x + 1, false)}
                      type="button"
                      className="btn btn-light btn-xs"
                    >
                      <i className="fa fa-angle-down"></i>
                    </button>
                    <Tooltip
                      className="text-center"
                      id="ver_entregable"
                      aria-haspopup="true"
                    >
                      Ver detalle
                      <br />
                      del entregable
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
            <h5 className="mb-1">
              Entregable {x + 1}: {entregable.nombre}
            </h5>
          </div>
          <div className={`card-body ${id_collapse === x + 1 ? "" : "d-none"}`}>
            {entregable_editar_mostrar && id_collapse === x + 1 && (
              <EditarEntregable
                entregable={entregable}
                tareaSeleccionada={this.tareaSeleccionada}
                cancelarEdicionEntregable={this.cancelarEdicionEntregable}
              />
            )}
            <ul className="nav nav-pills nav-justified mt-2 mb-4">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id={`nav-general-tab-${x}`}
                  data-bs-toggle="pill"
                  data-bs-target={`#nav-general-${x}`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-general-${x}`}
                  aria-selected="false"
                >
                  Datos Generales
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id={`nav-requisitos-tab-${x}`}
                  data-bs-toggle="pill"
                  data-bs-target={`#nav-requisitos-${x}`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-requisitos-${x}`}
                  aria-selected="false"
                >
                  Requisitos <small>({entregable.requisitos.length})</small>
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id={`nav-recursos-tab-${x}`}
                  data-bs-toggle="pill"
                  data-bs-target={`#nav-recursos-${x}`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-recursos-${x}`}
                  aria-selected="false"
                >
                  Recurso de Apoyo <small>({entregable.recursos.length})</small>
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id={`nav-cargas-tab-${x}`}
                  data-bs-toggle="pill"
                  data-bs-target={`#nav-cargas-${x}`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-cargas-${x}`}
                  aria-selected="false"
                >
                  Cargas <small>({entregable.cargas.length})</small>
                </button>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id={`nav-general-${x}`}
                role="tabpanel"
                aria-labelledby={`nav-general-tab-${x}`}
              >
                <Ficha
                  tarea={tarea}
                  entregable={entregable}
                  user_equipo={user_equipo}
                  tareaSeleccionada={this.tareaSeleccionada}
                />
              </div>
              <div
                className="tab-pane fade"
                id={`nav-requisitos-${x}`}
                role="tabpanel"
                aria-labelledby={`nav-requisitos-tab-${x}`}
              >
                <RequisitoTabla
                  entregable={entregable}
                  user_equipo={user_equipo}
                  tareaSeleccionada={this.tareaSeleccionada}
                />
              </div>
              <div
                className="tab-pane fade"
                id={`nav-recursos-${x}`}
                role="tabpanel"
                aria-labelledby={`nav-recursos-tab-${x}`}
              >
                <RecursoTabla
                  user_equipo={user_equipo}
                  entregable={entregable}
                  tareaSeleccionada={this.tareaSeleccionada}
                />
              </div>
              <div
                className="tab-pane fade"
                id={`nav-cargas-${x}`}
                role="tabpanel"
                aria-labelledby={`nav-cargas-tab-${x}`}
              >
                <CargaTabla
                  user_equipo={user_equipo}
                  entregable={entregable}
                  tareaSeleccionada={this.tareaSeleccionada}
                />
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <div className="card-body text-center alert alert-outline-danger mt-3">
        <h5 className="text-uppercase">No existen entregables creados</h5>
      </div>
    );
  };
}

Detalle.propTypes = {
  estadoEntregable: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  obtenerTarea: PropTypes.func.isRequired,
  duplicarEntregable: PropTypes.func.isRequired,
  eliminarEntregable: PropTypes.func.isRequired,
  entregable_estado: PropTypes.object.isRequired,
  entregable_estado_error: PropTypes.object.isRequired,
  tarea_seleccionada: PropTypes.number.isRequired,
  entregable_duplicar: PropTypes.object.isRequired,
  entregable_duplicar_error: PropTypes.object.isRequired,
  entregable_eliminar: PropTypes.object.isRequired,
  entregable_eliminar_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  entregable_estado: state.tareas.entregable_estado,
  entregable_estado_error: state.tareas.entregable_estado_error,
  tarea_seleccionada: state.tareas.tarea_seleccionada,
  entregable_duplicar: state.tareas.entregable_duplicar,
  entregable_duplicar_error: state.tareas.entregable_duplicar_error,
  entregable_eliminar: state.tareas.entregable_eliminar,
  entregable_eliminar_error: state.tareas.entregable_eliminar_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      estadoEntregable,
      limpiarMensajes,
      obtenerTarea,
      duplicarEntregable,
      eliminarEntregable,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
