import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  LIMPIAR_UNIDADES_FILTROS,
  UNIDAD_FISCALIZABLE_FALLIDO,
  UNIDAD_FISCALIZABLE_GUARDAR,
  UNIDAD_FISCALIZABLE_OBTENER,
  UNIDADES_FISCALIZABLES_ESTADISTICAS_FALLIDO,
  UNIDADES_FISCALIZABLES_ESTADISTICAS_OBTENER,
  UNIDADES_FISCALIZABLES_FALLIDO,
  UNIDADES_FISCALIZABLES_FILTROS_FALLIDO,
  UNIDADES_FISCALIZABLES_FILTROS_INPUT,
  UNIDADES_FISCALIZABLES_FILTROS_OBTENER,
  UNIDADES_FISCALIZABLES_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerUnidadesFiscalizablesEstadistica =
  (pagina) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .get(
        `${url_api}/api/vigilances/unidades-fiscalizables/estadisticas/${pagina}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: UNIDADES_FISCALIZABLES_ESTADISTICAS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : UNIDADES_FISCALIZABLES_ESTADISTICAS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerUnidadesFiscalizables =
  (pagina) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .get(
        `${url_api}/api/vigilances/unidades-fiscalizables/${pagina}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({ type: UNIDADES_FISCALIZABLES_OBTENER, payload: res.data });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : UNIDADES_FISCALIZABLES_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerUnidadesFiscalizablesFiltros =
  (data) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .post(
        `${url_api}/api/vigilances/unidades-fiscalizables/filtros/${data.pagina}`,
        data.filtros,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: UNIDADES_FISCALIZABLES_FILTROS_INPUT,
          payload: data.filtros,
        });
        dispatch({
          type: UNIDADES_FISCALIZABLES_FILTROS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : UNIDADES_FISCALIZABLES_FILTROS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerUnidadFiscalizable = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/unidad-fiscalizable`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: UNIDAD_FISCALIZABLE_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : UNIDAD_FISCALIZABLE_FALLIDO,
        payload: error,
      });
    });
};

export const guardarUnidad = (data) => (dispatch) => {
  dispatch({ type: UNIDAD_FISCALIZABLE_GUARDAR, payload: data });
};

export const limpiarFiltroUnidadesFiscalizables = () => (dispatch) => {
  dispatch({ type: LIMPIAR_UNIDADES_FILTROS });
};
