import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { CheckTreePicker } from "rsuite";
import { format as formatRut } from "rut.js";
import {
  limpiarFiltroHechos,
  obtenerHechos,
  obtenerHechosFiltros,
} from "../../../redux/actions/vigilancias/hechos/action";

export class Hechos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre:
        this.props.hechos_filtro_input && this.props.hechos_filtro_input.nombre
          ? this.props.hechos_filtro_input.nombre
          : "",
      filtro_clasificacion:
        this.props.hechos_filtro_input &&
        this.props.hechos_filtro_input.clasificacion
          ? this.props.hechos_filtro_input.clasificacion
          : [],
      filtro_infraccion:
        this.props.hechos_filtro_input &&
        this.props.hechos_filtro_input.infraccion
          ? this.props.hechos_filtro_input.infraccion
          : [],
      filtro_procedimiento_numero:
        this.props.hechos_filtro_input &&
        this.props.hechos_filtro_input.procedimiento_numero
          ? this.props.hechos_filtro_input.procedimiento_numero
          : "",
      filtro_procedimiento_estado:
        this.props.hechos_filtro_input &&
        this.props.hechos_filtro_input.procedimiento_estado
          ? this.props.hechos_filtro_input.procedimiento_estado
          : [],
      filtro_titular_nombre:
        this.props.hechos_filtro_input &&
        this.props.hechos_filtro_input.titular_nombre
          ? this.props.hechos_filtro_input.titular_nombre
          : "",
      filtro_titular_rut:
        this.props.hechos_filtro_input &&
        this.props.hechos_filtro_input.titular_rut
          ? this.props.hechos_filtro_input.titular_rut
          : "",
      filtro_region:
        this.props.hechos_filtro_input && this.props.hechos_filtro_input.region
          ? this.props.hechos_filtro_input.region
          : [],
      filtro_categoria:
        this.props.hechos_filtro_input &&
        this.props.hechos_filtro_input.categoria
          ? this.props.hechos_filtro_input.categoria
          : [],
      filtro_clasificacion_detalle:
        this.props.hechos_filtro_input &&
        this.props.hechos_filtro_input.clasificacion_detalle
          ? this.props.hechos_filtro_input.clasificacion_detalle
          : [],
      filtro_fecha_inicio_desde:
        this.props.hechos_filtro_input &&
        this.props.hechos_filtro_input.fecha_inicio_desde
          ? this.props.hechos_filtro_input.fecha_inicio_desde
          : "",
      filtro_fecha_inicio_hasta:
        this.props.hechos_filtro_input &&
        this.props.hechos_filtro_input.fecha_inicio_hasta
          ? this.props.hechos_filtro_input.fecha_inicio_hasta
          : "",
      filtro_tag:
        this.props.hechos_filtro_input && this.props.hechos_filtro_input.tag
          ? this.props.hechos_filtro_input.tag
          : "",
      filtros_avanzandos:
        this.props.hechos_filtro_input &&
        this.props.hechos_filtro_input.filtros_avanzandos
          ? this.props.hechos_filtro_input.filtros_avanzandos
          : false,
    };
  }

  borrarFiltros = () => {
    const { obtenerHechos, limpiarFiltroHechos } = this.props;
    obtenerHechos(1);
    limpiarFiltroHechos();
    this.setState({
      filtro_nombre: "",
      filtro_clasificacion: [],
      filtro_infraccion: [],
      filtro_procedimiento_numero: "",
      filtro_procedimiento_estado: [],
      filtro_titular_nombre: "",
      filtro_titular_rut: "",
      filtro_region: [],
      filtro_categoria: [],
      filtro_clasificacion_detalle: [],
      filtro_fecha_inicio_desde: "",
      filtro_fecha_inicio_hasta: "",
      filtro_tag: "",
    });
  };

  filtroHechos = () => {
    const { obtenerHechosFiltros, obtenerHechos } = this.props;
    const {
      filtro_nombre,
      filtro_clasificacion,
      filtro_infraccion,
      filtro_procedimiento_numero,
      filtro_procedimiento_estado,
      filtro_titular_nombre,
      filtro_titular_rut,
      filtro_region,
      filtro_categoria,
      filtro_clasificacion_detalle,
      filtros_avanzandos,
      filtro_fecha_inicio_desde,
      filtro_fecha_inicio_hasta,
      filtro_tag,
    } = this.state;

    if (
      filtro_nombre ||
      filtro_clasificacion ||
      filtro_infraccion ||
      filtro_procedimiento_numero ||
      filtro_procedimiento_estado ||
      filtro_titular_nombre ||
      filtro_titular_rut ||
      filtro_categoria ||
      filtro_region ||
      filtro_clasificacion_detalle ||
      filtro_fecha_inicio_desde ||
      filtro_fecha_inicio_hasta ||
      filtro_tag
    ) {
      var filtros = {
        nombre: filtro_nombre ? filtro_nombre : null,
        clasificacion: filtro_clasificacion ? filtro_clasificacion : null,
        infraccion: filtro_infraccion ? filtro_infraccion : null,
        procedimiento_numero: filtro_procedimiento_numero
          ? filtro_procedimiento_numero
          : null,
        procedimiento_estado: filtro_procedimiento_estado
          ? filtro_procedimiento_estado
          : null,
        titular_nombre: filtro_titular_nombre ? filtro_titular_nombre : null,
        titular_rut: filtro_titular_rut ? filtro_titular_rut : null,
        categoria: filtro_categoria ? filtro_categoria : null,
        region: filtro_region ? filtro_region : null,
        clasificacion_detalle: filtro_clasificacion_detalle
          ? filtro_clasificacion_detalle
          : null,
        fecha_inicio_desde: filtro_fecha_inicio_desde
          ? filtro_fecha_inicio_desde
          : null,
        fecha_inicio_hasta: filtro_fecha_inicio_hasta
          ? filtro_fecha_inicio_hasta
          : null,
        tag: filtro_tag ? filtro_tag : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerHechosFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerHechos(1);
    }
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (e.target.name === "filtro_titular_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  render = () => {
    const { filtros_hechos, filtros_errores, user } = this.props;
    const {
      filtro_nombre,
      filtro_clasificacion,
      filtro_infraccion,
      filtro_procedimiento_numero,
      filtro_procedimiento_estado,
      filtro_titular_nombre,
      filtros_avanzandos,
      filtro_titular_rut,
      filtro_categoria,
      filtro_region,
      filtro_clasificacion_detalle,
      filtro_fecha_inicio_desde,
      filtro_fecha_inicio_hasta,
      filtro_tag,
    } = this.state;
    const categorias_listado =
      filtros_hechos && filtros_hechos["categorias"]
        ? _.orderBy(filtros_hechos["categorias"], "nombre")
        : [];
    const regiones_listado =
      filtros_hechos && filtros_hechos["regiones"]
        ? _.orderBy(filtros_hechos["regiones"], "nombre")
        : [];
    const clasificacion_detalle_listado =
      filtros_hechos && filtros_hechos["clasificacion_detalle"]
        ? filtros_hechos["clasificacion_detalle"]
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>
                  Nombre del hecho infraccional
                  <i
                    className="fa fa-exclamation-circle ms-1 text-warning font-11"
                    data-tip
                    data-tooltip-id="nombre_hecho"
                  ></i>
                  <Tooltip
                    className="w-30"
                    id="nombre_hecho"
                    aria-haspopup="true"
                  >
                    En este filtro puedes anotar frases o palabras que quieres
                    que estén presentes en el hecho infraccional. Ejemplo: la
                    frase aguas servidas; o la palabra suelo; o la combinación
                    aguas servidas norma
                  </Tooltip>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_nombre"
                    value={filtro_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese una palabra"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre &&
                    filtros_errores.data.nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Región</label>
                <CheckTreePicker
                  name="filtro_region"
                  data={regiones_listado ? regiones_listado : []}
                  onChange={(value) => {
                    this.setState({ filtro_region: value });
                  }}
                  value={filtro_region}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.region &&
                  filtros_errores.data.region.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-6 col-sm-8 col-12 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de inicio del procedimiento sancionatorio</label>
                <div className="input-group">
                  <span className="input-group-text">Desde/Hasta</span>
                  <input
                    type="date"
                    name="filtro_fecha_inicio_desde"
                    value={filtro_fecha_inicio_desde}
                    onChange={this.onChange}
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_inicio_desde
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <input
                    type="date"
                    name="filtro_fecha_inicio_hasta"
                    value={filtro_fecha_inicio_hasta}
                    onChange={this.onChange}
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_inicio_hasta
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_inicio_desde &&
                    filtros_errores.data.fecha_inicio_desde.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_inicio_hasta &&
                    filtros_errores.data.fecha_inicio_hasta.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Clasificación</label>
                <CheckTreePicker
                  name="filtro_clasificacion"
                  data={
                    filtros_hechos.clasificacion
                      ? filtros_hechos.clasificacion
                      : []
                  }
                  onChange={(value) => {
                    this.setState({ filtro_clasificacion: value });
                  }}
                  value={filtro_clasificacion}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.clasificacion &&
                  filtros_errores.data.clasificacion.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            {user && !user.is_freemium && (
              <>
                <div
                  className={`col-lg-3 col-sm-4 col-6 ${
                    filtros_avanzandos ? "" : "d-none"
                  }`}
                >
                  <div className="form-group">
                    <label>Detalle de la Clasificación</label>
                    <CheckTreePicker
                      name="filtro_clasificacion_detalle"
                      data={
                        clasificacion_detalle_listado
                          ? clasificacion_detalle_listado
                          : []
                      }
                      onChange={(value) => {
                        this.setState({ filtro_clasificacion_detalle: value });
                      }}
                      value={filtro_clasificacion_detalle}
                      locale={{
                        noResultsText: "Sin resultados",
                        placeholder: "Selecciona una opción",
                        searchPlaceholder: "Buscar",
                      }}
                      block
                    />
                    {filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.clasificacion_detalle &&
                      filtros_errores.data.clasificacion_detalle.map(
                        (err, x) => (
                          <div key={x} className="invalid-feedback">
                            {err}
                          </div>
                        )
                      )}
                  </div>
                </div>
                <div
                  className={`col-lg-3 col-sm-4 col-6 ${
                    filtros_avanzandos ? "" : "d-none"
                  }`}
                >
                  <div className="form-group">
                    <label>Tipo</label>
                    <CheckTreePicker
                      name="filtro_infraccion"
                      data={
                        filtros_hechos.infraccion
                          ? filtros_hechos.infraccion
                          : []
                      }
                      onChange={(value) => {
                        this.setState({ filtro_infraccion: value });
                      }}
                      value={filtro_infraccion}
                      locale={{
                        noResultsText: "Sin resultados",
                        placeholder: "Selecciona una opción",
                        searchPlaceholder: "Buscar",
                      }}
                      block
                    />
                    {filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.infraccion &&
                      filtros_errores.data.infraccion.map((err, x) => (
                        <div key={x} className="invalid-feedback">
                          {err}
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Número del Expediente Sancionatorio</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_procedimiento_numero"
                    value={filtro_procedimiento_numero}
                    onChange={this.onChange}
                    placeholder="Ingrese un nº de expediente"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.procedimiento_numero
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.procedimiento_numero &&
                    filtros_errores.data.procedimiento_numero.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Estado del Expediente Sancionatorio</label>
                <CheckTreePicker
                  name="filtro_procedimiento_estado"
                  data={filtros_hechos.estado ? filtros_hechos.estado : []}
                  onChange={(value) => {
                    this.setState({ filtro_procedimiento_estado: value });
                  }}
                  value={filtro_procedimiento_estado}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.procedimiento_estado &&
                  filtros_errores.data.procedimiento_estado.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Categoría</label>
                <CheckTreePicker
                  name="filtro_categoria"
                  data={categorias_listado ? categorias_listado : []}
                  onChange={(value) => {
                    this.setState({ filtro_categoria: value });
                  }}
                  value={filtro_categoria}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.categoria &&
                  filtros_errores.data.categoria.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Nombre del Titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_nombre"
                    value={filtro_titular_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese nombre del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_nombre &&
                    filtros_errores.data.titular_nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>RUT del Titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_rut"
                    value={filtro_titular_rut}
                    onChange={this.onChange}
                    placeholder="Ingrese rut del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_rut
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_rut &&
                    filtros_errores.data.titular_rut.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tag</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_tag"
                    value={filtro_tag}
                    onChange={this.onChange}
                    placeholder="Ingrese tag"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.tag
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.tag &&
                    filtros_errores.data.tag.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroHechos}
            >
              <i className="fa fa-search"></i> Filtrar hechos
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Hechos.propTypes = {
  obtenerHechos: PropTypes.func.isRequired,
  obtenerHechosFiltros: PropTypes.func.isRequired,
  limpiarFiltroHechos: PropTypes.func.isRequired,
  filtros_hechos: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  hechos_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_hechos: state.vigilancias.filtros_hechos,
  filtros_errores: state.vigilancias.filtros_errores,
  hechos_filtro_input: state.vigilancias.hechos_filtro_input,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerHechos, obtenerHechosFiltros, limpiarFiltroHechos },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Hechos);
