import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { Tabla as Expedientes } from "../../mma/expedientes/";

import {
  EliminacionFavorito,
  guardarNorma,
  limpiarMensajes,
  loadingTrue,
  obtenerFavoritos,
} from "../../../redux/actions/leyes/action";
import { Footer, Navbar, SidebarInicio } from "../../general";

export class Listado extends Component {
  componentDidMount = () => {
    const { obtenerFavoritos } = this.props;
    obtenerFavoritos();
  };

  verDetalleNorma = (id) => {
    const { guardarNorma } = this.props;
    guardarNorma(id, "favoritos");
  };

  eliminarFavorito = (id) => {
    const { EliminacionFavorito } = this.props;
    SweetAlert.fire({
      title: "Eliminar Favorito",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de eliminar como norma ?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-trash"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-danger me-2",
        cancelButton: "btn btn-primary",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        EliminacionFavorito({
          id: id,
        });
        SweetAlert.fire({
          title: "Eliminando de Favoritos",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos eliminando de favoritos, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  render = () => {
    const {
      normas_favoritas,
      is_loading_leyes,
      norma_favorita_eliminar,
      norma_favorita_eliminar_error,
      limpiarMensajes,
      obtenerFavoritos,
    } = this.props;
    if (is_loading_leyes) {
      SweetAlert.fire({
        title: "Normas Favoritas",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos cargando tus normas favoritas, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (Object.entries(norma_favorita_eliminar).length > 0) {
      SweetAlert.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        title: "Eliminado de Favoritos",
        text: norma_favorita_eliminar.message,
        icon: "success",
        confirmButtonText:
          'Volver al Listado <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-success",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          obtenerFavoritos();
        }
      });
    }

    if (Object.entries(norma_favorita_eliminar_error).length > 0) {
      SweetAlert.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        title: "Problema al eliminar de Favoritos",
        text: norma_favorita_eliminar_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        limpiarMensajes();
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="leyes_favoritos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-heart"></i> MIS FAVORITOS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="text-uppercase">Listado de Favoritos</h5>
                  </div>
                  <div className="card-body">
                    {normas_favoritas &&
                    ((normas_favoritas.normas &&
                      normas_favoritas.normas.result &&
                      normas_favoritas.normas.result.length > 0) ||
                      (normas_favoritas.expedientes &&
                        normas_favoritas.expedientes.result &&
                        normas_favoritas.expedientes.result.length > 0)) ? (
                      <>
                        <ul className="nav nav-pills">
                          {normas_favoritas &&
                            normas_favoritas.normas &&
                            normas_favoritas.normas.result &&
                            normas_favoritas.normas.result.length > 0 && (
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active"
                                  id="legal-normas-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#legal-normas"
                                  type="button"
                                  role="tab"
                                  aria-controls="legal-normas"
                                  aria-selected="true"
                                >
                                  Normas (
                                  {normas_favoritas.normas.result.length})
                                </button>
                              </li>
                            )}
                          {normas_favoritas &&
                            normas_favoritas.expedientes &&
                            normas_favoritas.expedientes.result &&
                            normas_favoritas.expedientes.result.length > 0 && (
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="legal-expedientes-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#legal-expedientes"
                                  type="button"
                                  role="tab"
                                  aria-controls="legal-expedientes"
                                  aria-selected="true"
                                >
                                  Expedientes MMA (
                                  {normas_favoritas.expedientes.result.length})
                                </button>
                              </li>
                            )}
                        </ul>
                        <div className="tab-content mt-2">
                          {normas_favoritas.normas &&
                            normas_favoritas.normas.result &&
                            normas_favoritas.normas.result.length > 0 && (
                              <div
                                className="tab-pane fade active show"
                                id="legal-normas"
                                role="tabpanel"
                                aria-labelledby="legal-normas-tab"
                              >
                                <h5 className="mb-2">LISTADO DE NORMAS</h5>
                                <div className="table-responsive">
                                  <table className="table table-sm table-bordered table-sm">
                                    <thead className="table-secondary">
                                      <tr>
                                        <th className="text-center w-3">ID</th>
                                        <th>Título</th>
                                        <th className="w-15">Tipo</th>
                                        <th className="text-center w-7">
                                          Promulgación
                                        </th>
                                        <th className="text-center w-7">
                                          Publicación
                                        </th>
                                        <th className="text-center w-3">
                                          Acción
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {normas_favoritas.normas.result.map(
                                        (norma, x) => (
                                          <tr key={x}>
                                            <td className="text-center">
                                              {x + 1}
                                            </td>
                                            <td>
                                              <a
                                                href={`https://www.bcn.cl/leychile/navegar?idNorma=${norma.norma}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <b className="text-uppercase">
                                                  {norma.tipo} |{" "}
                                                </b>
                                                {norma.titulo}{" "}
                                                <i className="fa fa-angle-right"></i>
                                              </a>
                                            </td>
                                            <td>{norma.last_tipo}</td>
                                            <td className="text-center">
                                              {norma.fecha_promulgacion
                                                ? norma.fecha_promulgacion
                                                : "---"}
                                            </td>
                                            <td className="text-center">
                                              {norma.fecha_publicacion}
                                            </td>
                                            <td className="text-center">
                                              <div className="btn-group">
                                                <Tooltip
                                                  id={`norma_eliminar_${x}`}
                                                  aria-haspopup="true"
                                                >
                                                  Eliminar de Favoritos
                                                </Tooltip>
                                                <button
                                                  data-tip
                                                  data-tooltip-id={`norma_eliminar_${x}`}
                                                  onClick={this.eliminarFavorito.bind(
                                                    this,
                                                    norma.id
                                                  )}
                                                  className="btn btn-danger"
                                                >
                                                  <i className="fa fa-trash"></i>
                                                </button>
                                                <Tooltip
                                                  id={`norma_favorita_${x}`}
                                                  aria-haspopup="true"
                                                >
                                                  Ver detalle de la Norma
                                                </Tooltip>
                                                <Link
                                                  data-tip
                                                  data-tooltip-id={`norma_favorita_${x}`}
                                                  onClick={this.verDetalleNorma.bind(
                                                    this,
                                                    norma.id
                                                  )}
                                                  to="/legal-tracking/norma"
                                                  className="btn btn-soft-dark"
                                                >
                                                  <i className="fa fa-angle-right"></i>
                                                </Link>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            )}
                          {normas_favoritas.expedientes &&
                            normas_favoritas.expedientes.result &&
                            normas_favoritas.expedientes.result.length > 0 && (
                              <div
                                className="tab-pane fade"
                                id="legal-expedientes"
                                role="tabpanel"
                                aria-labelledby="legal-expedientes-tab"
                              >
                                <h5 className="mb-2">
                                  LISTADO DE EXPEDIENTES MMA
                                </h5>
                                <Expedientes
                                  expedientes={normas_favoritas.expedientes}
                                  eliminar_expediente
                                />
                              </div>
                            )}
                        </div>
                      </>
                    ) : (
                      <h6 className="text-uppercase">
                        No tienes favoritos registrados
                      </h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerFavoritos: PropTypes.func.isRequired,
  loadingTrue: PropTypes.func.isRequired,
  guardarNorma: PropTypes.func.isRequired,
  EliminacionFavorito: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  normas_favoritas: PropTypes.object.isRequired,
  is_loading_leyes: PropTypes.bool.isRequired,
  norma_favorita_eliminar: PropTypes.object.isRequired,
  norma_favorita_eliminar_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  normas_favoritas: state.leyes.normas_favoritas,
  is_loading_leyes: state.leyes.is_loading_leyes,
  norma_favorita_eliminar: state.leyes.norma_favorita_eliminar,
  norma_favorita_eliminar_error: state.leyes.norma_favorita_eliminar_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerFavoritos,
      loadingTrue,
      guardarNorma,
      EliminacionFavorito,
      limpiarMensajes,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
