import React, { Component } from "react";

import { Link } from "react-router-dom";

export class Quinenco extends Component {
  render = () => {
    const { menu_1, menu_2, empresas } = this.props;
    return (
      empresas &&
      empresas.quinenco && (
        <>
          <h5 className="mt-3">QUIÑENCO</h5>
          <li
            className={
              menu_1 &&
              menu_1 === "empresa_quinenco" &&
              menu_2 &&
              menu_2 === "dashboard"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/quinenco/dashboard">
              <i className="fa fa-chart-area"></i>
              <span> Dashboard</span>
            </Link>
          </li>
          <li
            className={
              menu_1 &&
              menu_1 === "empresa_quinenco" &&
              menu_2 &&
              menu_2 === "unidades"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/quinenco/unidades">
              <i className="fa fa-house-user"></i>
              <span> Unidades Fiscalizables</span>
            </Link>
          </li>
          <li
            className={
              menu_1 &&
              menu_1 === "empresa_quinenco" &&
              menu_2 &&
              menu_2 === "expedientes"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/quinenco/expedientes">
              <i className="fa fa-seedling"></i>
              <span> Expedientes SEIA</span>
            </Link>
          </li>
          <li
            className={
              menu_1 &&
              menu_1 === "empresa_quinenco" &&
              menu_2 &&
              menu_2 === "pertinencias"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link to="/quinenco/pertinencias">
              <i className="fa fa-calendar-check"></i>
              <span> Consultas de Pertinencias</span>
            </Link>
          </li>
        </>
      )
    );
  };
}

export default Quinenco;
