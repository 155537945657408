import React, { Component } from "react";
import { TablaPequena as Documentos } from "../documentos";
import { Tabs as Fiscalizaciones } from "../fiscalizaciones";
import { Tabs as Procedimientos } from "../procedimientos";
import { TablaPequena as Titulares } from "../titulares";
import { Tabs as UnidadFiscalizable } from "../unidades";

export class Tabs extends Component {
  render = () => {
    const { requerimientos } = this.props;

    return requerimientos && requerimientos.length > 0 ? (
      requerimientos.map((req, x) => (
        <div key={x}>
          <ul className="nav nav-pills nav-justified">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="req-general-tab"
                data-bs-toggle="pill"
                data-bs-target="#req-general"
                type="button"
                role="tab"
                aria-controls="req-general"
                aria-selected="true"
              >
                Información del Requerimiento de Ingreso
              </button>
            </li>
            {req.procedimiento && req.procedimiento.length > 0 && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="req-procedimiento-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#req-procedimiento"
                  type="button"
                  role="tab"
                  aria-controls="req-procedimiento"
                  aria-selected="true"
                >
                  Procedimiento Sancionatorio{" "}
                  <small>({req.procedimiento.length})</small>
                </button>
              </li>
            )}
            {req.fiscalizacion && req.fiscalizacion.length > 0 && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="req-fiscalizacion-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#req-fiscalizacion"
                  type="button"
                  role="tab"
                  aria-controls="req-fiscalizacion"
                  aria-selected="true"
                >
                  Fiscalización <small>({req.fiscalizacion.length})</small>
                </button>
              </li>
            )}
            {req.documentos && req.documentos.length > 0 && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="req-documentos-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#req-documentos"
                  type="button"
                  role="tab"
                  aria-controls="req-documentos"
                  aria-selected="false"
                >
                  Documentos <small>({req.documentos.length})</small>
                </button>
              </li>
            )}
            {req.unidad_fiscalizable && req.unidad_fiscalizable.length > 0 && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="req-unidades-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#req-unidades"
                  type="button"
                  role="tab"
                  aria-controls="req-unidades"
                  aria-selected="false"
                >
                  Unidades Fiscalizables{" "}
                  <small>({req.unidad_fiscalizable.length})</small>
                </button>
              </li>
            )}
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show mt-3"
              id="req-general"
              role="tabpanel"
              aria-labelledby="req-general-tab"
            >
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="table-secondary w-20 text-dark">
                        Expediente
                      </td>
                      <td>
                        <a
                          href={req.url_snifa}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {req.expediente} <i className="fa fa-angle-right"></i>
                        </a>
                      </td>
                    </tr>
                    {req.titulares && req.titulares.length > 0 && (
                      <tr>
                        <td className="table-secondary text-dark">Titular</td>
                        <td>
                          <Titulares titulares={req.titulares} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {req.procedimiento && req.procedimiento.length > 0 && (
              <div
                className="tab-pane fade mt-3"
                id="req-procedimiento"
                role="tabpanel"
                aria-labelledby="req-procedimiento-tab"
              >
                <Procedimientos procedimientos={req.procedimiento} />
              </div>
            )}
            {req.fiscalizacion && req.fiscalizacion.length > 0 && (
              <div
                className="tab-pane fade mt-3"
                id="req-fiscalizacion"
                role="tabpanel"
                aria-labelledby="req-fiscalizacion-tab"
              >
                <Fiscalizaciones fiscalizaciones={req.fiscalizacion} />
              </div>
            )}
            {req.documentos && req.documentos.length > 0 && (
              <div
                className="tab-pane fade mt-3"
                id="req-documentos"
                role="tabpanel"
                aria-labelledby="req-documentos-tab"
              >
                <Documentos documentos={req.documentos} />
              </div>
            )}
            {req.unidad_fiscalizable && req.unidad_fiscalizable.length > 0 && (
              <div
                className="tab-pane fade mt-3"
                id="req-unidades"
                role="tabpanel"
                aria-labelledby="req-unidades-tab"
              >
                <UnidadFiscalizable
                  unidades_fiscalizables={req.unidad_fiscalizable}
                />
              </div>
            )}
          </div>
        </div>
      ))
    ) : (
      <h6>No existen documento</h6>
    );
  };
}

export default Tabs;
