import React, { Component } from "react";

export class TablaPequena extends Component {
  render = () => {
    const { procedimientos } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th className="w-20">Expediente</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {procedimientos && procedimientos.length > 0 ? (
              procedimientos.map((procedimiento, a) => (
                <tr key={a}>
                  <td className="text-center">{a + 1}</td>
                  <td>
                    <a
                      href={procedimiento.url_snifa}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {procedimiento.expediente}{" "}
                      <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td>{procedimiento.estado}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No existen titulares</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

export default TablaPequena;
