import React, { Component } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerHechosEstadisticas } from "../../../redux/actions/vigilancias/hechos/action";
import { Chart, Footer, Navbar, SidebarInicio } from "../../general";

export class Estadisticas extends Component {
  componentDidMount = () => {
    const { obtenerHechosEstadisticas, hechos_estadisticas } = this.props;
    if (Object.entries(hechos_estadisticas).length === 0) {
      obtenerHechosEstadisticas(1);
    }
  };

  render = () => {
    const { hechos_estadisticas, is_loading_vigilancias } = this.props;

    var clasificaciones = _.countBy(
      _.orderBy(hechos_estadisticas.result, "clasificacion"),
      "clasificacion"
    );
    var infracciones = _.countBy(
      _.orderBy(hechos_estadisticas.result, "infraccion"),
      "infraccion"
    );
    var fechas = {};
    if (
      hechos_estadisticas &&
      hechos_estadisticas.result &&
      hechos_estadisticas.result.length > 0
    ) {
      var anios = [];
      hechos_estadisticas.result.forEach(function (hecho) {
        if (hecho.procedimiento.fecha_inicio) {
          var fecha = hecho.procedimiento.fecha_inicio.split("/");
          anios.push(fecha[2]);
        }
      });
      fechas = _.countBy(anios);
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Hechos Infraccionales",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los hechos infraccionales, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_hechos" menu_2="estadistica" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-university"></i> HECHOS INFRACCIONALES{" "}
                    <small>
                      (
                      {hechos_estadisticas &&
                        hechos_estadisticas.total &&
                        hechos_estadisticas.total}
                      )
                    </small>
                  </h4>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  is_vertical
                  data={clasificaciones}
                  nombre="Hechos infraccionales"
                  titulo="Hechos infraccionales por clasificación"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  is_vertical
                  data={fechas}
                  nombre="Hechos infraccionales"
                  titulo="Hechos infraccionales por año"
                />
              </div>
              <div className="col-lg-12 col-sm-12">
                <Chart
                  data={infracciones}
                  nombre="Hechos infraccionales"
                  titulo="Hechos infraccionales por infracciones"
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Estadisticas.propTypes = {
  obtenerHechosEstadisticas: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  hechos_estadisticas: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  hechos_estadisticas: state.vigilancias.hechos_estadisticas,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerHechosEstadisticas }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Estadisticas);
