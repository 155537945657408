import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  obtenerSeguimientos,
  obtenerSeguimientosFiltros,
} from "../../../redux/actions/vigilancias/seguimientos/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Filtros, Tabla } from "./";

export class Listado extends Component {
  componentDidMount = () => {
    const { obtenerSeguimientos, seguimientos } = this.props;
    if (Object.entries(seguimientos).length === 0) {
      obtenerSeguimientos(1);
    }
  };

  nuevaPagina = (pagina) => {
    const {
      obtenerSeguimientos,
      obtenerSeguimientosFiltros,
      seguimientos_filtros,
      seguimientos_filtro_input,
    } = this.props;
    if (Object.entries(seguimientos_filtros).length > 0) {
      obtenerSeguimientosFiltros({
        filtros: seguimientos_filtro_input,
        pagina: pagina,
      });
    } else {
      obtenerSeguimientos(pagina);
    }
  };

  render = () => {
    const { seguimientos, seguimientos_filtros, is_loading_vigilancias } =
      this.props;
    var seguimientos_result = seguimientos;
    if (Object.entries(seguimientos_filtros).length > 0) {
      seguimientos_result = seguimientos_filtros;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Seguimientos Ambientales",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los seguimientos ambiental, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio
          menu_1="vigilancias_seguimientos_ambiental"
          menu_2="listado"
        />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa-solid fa-calendar-days"></i> SEGUIMIENTOS
                    AMBIENTAL
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE SEGUIMIENTOS AMBIENTAL{" "}
                      <small>
                        (
                        {seguimientos_result && seguimientos_result.total
                          ? seguimientos_result.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={seguimientos_result}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla
                      seguimientos={seguimientos_result}
                      componente="seguimientos"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerSeguimientos: PropTypes.func.isRequired,
  obtenerSeguimientosFiltros: PropTypes.func.isRequired,
  seguimientos: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  seguimientos_filtro_input: PropTypes.object.isRequired,
  seguimientos_filtros: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  seguimientos: state.vigilancias.seguimientos,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  seguimientos_filtro_input: state.vigilancias.seguimientos_filtro_input,
  seguimientos_filtros: state.vigilancias.seguimientos_filtros,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerSeguimientos, obtenerSeguimientosFiltros },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
