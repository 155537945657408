import React, { Component } from "react";

import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

export class Permisos extends Component {
  setPermiso = (permiso) => {
    this.props.setPermiso(permiso);
  };

  omitirPermiso = (permiso) => {
    this.props.omitirPermiso(permiso);
  };

  descargarCsv = () => {
    this.props.descargarCsv();
  };

  render = () => {
    const { permisos, ulr_permiso, is_descargar, is_btn_omitir } = this.props;
    const createMarkup = (htmlString) => ({ __html: htmlString });

    return (
      <div className="card card-eco user_select_none">
        <div className="card-header">
          <h5 className="float-start">
            LISTADO DE PERMISOS Y FICHAS TÉCNICAS{" "}
            <small>({permisos.length})</small>
          </h5>
          <div className="float-end">
            {is_descargar && (
              <p className="float-end">
                <i
                  onClick={this.descargarCsv}
                  data-tip
                  data-tooltip-id="formato_excel"
                  className="fa fa-2x fa-file-excel text-success cursor-pointer"
                ></i>

                <Tooltip id="formato_excel" aria-haspopup="true">
                  Descargar listado de permisos (formato Excel)
                </Tooltip>
              </p>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-sm table-bordered mb-0">
              <thead>
                <tr>
                  <th className="text-center w-5">ID</th>
                  <th className="w-30">Nombre del permiso</th>
                  <th>Descripción del permiso</th>
                  <th className="text-center w-5">Código</th>
                  <th className="text-center w-5">Acción</th>
                </tr>
              </thead>
              <tbody>
                {permisos && permisos.length > 0 ? (
                  permisos.map((permiso, x) => (
                    <tr key={x}>
                      <td className="text-center">{x + 1}</td>
                      <td>{permiso.nombre}</td>
                      <td>
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            permiso.descripcion
                          )}
                        />
                      </td>
                      <td className="text-center">CL-{permiso.id}</td>
                      <td className="text-center">
                        <div className="btn-group">
                          {is_btn_omitir && (
                            <>
                              <Tooltip
                                id={`permiso_omitir_${permiso.id}`}
                                aria-haspopup="true"
                              >
                                Omitir permiso{" "}
                                <span className="text-lowercase">
                                  {permiso.nombre}
                                </span>
                              </Tooltip>
                              <button
                                onClick={this.omitirPermiso.bind(this, permiso)}
                                data-tip
                                data-tooltip-id={`permiso_omitir_${permiso.id}`}
                                className="btn btn-info btn-xs"
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </>
                          )}
                          <Tooltip
                            id={`permiso_detalle_${permiso.id}`}
                            aria-haspopup="true"
                          >
                            Ver detalle del permiso{" "}
                            <span className="text-lowercase">
                              {permiso.nombre}
                            </span>
                          </Tooltip>
                          <Link
                            data-tip
                            data-tooltip-id={`permiso_detalle_${permiso.id}`}
                            className="btn btn-primary btn-xs"
                            onClick={this.setPermiso.bind(this, permiso.id)}
                            to={ulr_permiso}
                          >
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="5">
                      No existen permisos
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
}

export default Permisos;
