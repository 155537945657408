import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import {
  limpiarFiltroExpedientes,
  obtenerExpedientes,
  obtenerExpedientesFiltros,
} from "../../../redux/actions/mma/action";

export class Filtros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.nombre
          ? this.props.expedientes_filtro_input.nombre
          : undefined,
      filtro_estado:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.estado
          ? this.props.expedientes_filtro_input.estado
          : null,
      filtro_tema_ambiental:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.tema_ambiental
          ? this.props.expedientes_filtro_input.tema_ambiental
          : null,
      filtro_tipo:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.tipo
          ? this.props.expedientes_filtro_input.tipo
          : null,
      filtro_tipologia:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.tipologia
          ? this.props.expedientes_filtro_input.tipologia
          : null,
      filtro_tipos_parametros_elementos_sustancias:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input
          .tipos_parametros_elementos_sustancias
          ? this.props.expedientes_filtro_input
              .tipos_parametros_elementos_sustancias
          : null,
      filtro_parametros_elementos_sustancias:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.parametros_elementos_sustancias
          ? this.props.expedientes_filtro_input.parametros_elementos_sustancias
          : null,
      filtro_fiscalizador:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.fiscalizador
          ? this.props.expedientes_filtro_input.fiscalizador
          : null,
      filtro_tipos_contaminantes_regulados:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.tipos_contaminantes_regulados
          ? this.props.expedientes_filtro_input.tipos_contaminantes_regulados
          : null,
      filtro_contaminantes_regulados:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.contaminantes_regulados
          ? this.props.expedientes_filtro_input.contaminantes_regulados
          : null,
      filtro_tipos_fuentes_reguladas:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.tipos_fuentes_reguladas
          ? this.props.expedientes_filtro_input.tipos_fuentes_reguladas
          : null,
      filtro_detalle_fuentes_reguladas:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.detalle_fuentes_reguladas
          ? this.props.expedientes_filtro_input.detalle_fuentes_reguladas
          : null,
      filtros_avanzandos:
        this.props.expedientes_filtro_input &&
        this.props.expedientes_filtro_input.filtros_avanzandos
          ? this.props.expedientes_filtro_input.filtros_avanzandos
          : false,
    };
  }

  filtroExpedientes = () => {
    const { obtenerExpedientesFiltros, obtenerExpedientes } = this.props;
    const {
      filtro_nombre,
      filtro_estado,
      filtro_tema_ambiental,
      filtro_tipo,
      filtro_tipologia,
      filtro_tipos_parametros_elementos_sustancias,
      filtro_parametros_elementos_sustancias,
      filtro_fiscalizador,
      filtro_tipos_contaminantes_regulados,
      filtro_contaminantes_regulados,
      filtro_tipos_fuentes_reguladas,
      filtro_detalle_fuentes_reguladas,
      filtros_avanzandos,
    } = this.state;

    if (
      filtro_nombre ||
      filtro_estado ||
      filtro_tema_ambiental ||
      filtro_tipo ||
      filtro_tipologia ||
      filtro_tipos_parametros_elementos_sustancias ||
      filtro_parametros_elementos_sustancias ||
      filtro_fiscalizador ||
      filtro_tipos_contaminantes_regulados ||
      filtro_contaminantes_regulados ||
      filtro_tipos_fuentes_reguladas ||
      filtro_detalle_fuentes_reguladas ||
      filtros_avanzandos
    ) {
      const filtros = {
        nombre: filtro_nombre ? filtro_nombre : null,
        estado: filtro_estado ? filtro_estado : null,
        tema_ambiental: filtro_tema_ambiental ? filtro_tema_ambiental : null,
        tipo: filtro_tipo ? filtro_tipo : null,
        tipologia: filtro_tipologia ? filtro_tipologia : null,
        tipos_parametros_elementos_sustancias:
          filtro_tipos_parametros_elementos_sustancias
            ? filtro_tipos_parametros_elementos_sustancias
            : null,
        parametros_elementos_sustancias: filtro_parametros_elementos_sustancias
          ? filtro_parametros_elementos_sustancias
          : null,
        fiscalizador: filtro_fiscalizador ? filtro_fiscalizador : null,
        tipos_contaminantes_regulados: filtro_tipos_contaminantes_regulados
          ? filtro_tipos_contaminantes_regulados
          : null,
        contaminantes_regulados: filtro_contaminantes_regulados
          ? filtro_contaminantes_regulados
          : null,
        tipos_fuentes_reguladas: filtro_tipos_fuentes_reguladas
          ? filtro_tipos_fuentes_reguladas
          : null,
        detalle_fuentes_reguladas: filtro_detalle_fuentes_reguladas
          ? filtro_detalle_fuentes_reguladas
          : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerExpedientesFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerExpedientes(1);
    }
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  borrarFiltros = () => {
    const { obtenerExpedientes, limpiarFiltroExpedientes } = this.props;
    obtenerExpedientes(1);
    limpiarFiltroExpedientes();
    this.setState({
      filtro_nombre: null,
      filtro_estado: null,
      filtro_tema_ambiental: null,
      filtro_tipo: null,
      filtro_tipologia: null,
      filtro_tipos_parametros_elementos_sustancias: null,
      filtro_parametros_elementos_sustancias: null,
      filtro_fiscalizador: null,
      filtro_tipos_contaminantes_regulados: null,
      filtro_contaminantes_regulados: null,
      filtro_tipos_fuentes_reguladas: null,
      filtro_detalle_fuentes_reguladas: null,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render = () => {
    const { filtros_expedientes, filtros_errores } = this.props;
    const {
      filtro_nombre,
      filtro_estado,
      filtro_tema_ambiental,
      filtro_tipo,
      filtro_tipologia,
      filtro_tipos_parametros_elementos_sustancias,
      filtro_parametros_elementos_sustancias,
      filtro_fiscalizador,
      filtro_tipos_contaminantes_regulados,
      filtro_contaminantes_regulados,
      filtro_tipos_fuentes_reguladas,
      filtro_detalle_fuentes_reguladas,
      filtros_avanzandos,
    } = this.state;

    const estados_listado =
      filtros_expedientes && filtros_expedientes["estado"]
        ? _.orderBy(filtros_expedientes["estado"], "nombre")
        : [];
    const tema_ambiental_listado =
      filtros_expedientes && filtros_expedientes["tema_ambiental"]
        ? _.orderBy(filtros_expedientes["tema_ambiental"], "id")
        : [];
    const tipos_listado =
      filtros_expedientes && filtros_expedientes["tipo"]
        ? _.orderBy(filtros_expedientes["tipo"], "value")
        : [];
    const tipologias_listado =
      filtros_expedientes && filtros_expedientes["tipologia"]
        ? _.orderBy(filtros_expedientes["tipologia"], "id")
        : [];
    const tipos_parametros_elementos_sustancias_listado =
      filtros_expedientes &&
      filtros_expedientes["tipos_parametros_elementos_sustancias"]
        ? _.orderBy(
            filtros_expedientes["tipos_parametros_elementos_sustancias"],
            "id"
          )
        : [];
    const parametros_elementos_sustancias_listado =
      filtros_expedientes &&
      filtros_expedientes["parametros_elementos_sustancias"]
        ? _.orderBy(
            filtros_expedientes["parametros_elementos_sustancias"],
            "id"
          )
        : [];
    const fiscalizadores_listado =
      filtros_expedientes && filtros_expedientes["fiscalizador"]
        ? _.orderBy(filtros_expedientes["fiscalizador"], "id")
        : [];
    const tipos_contaminantes_regulados_listado =
      filtros_expedientes &&
      filtros_expedientes["tipos_contaminantes_regulados"]
        ? _.orderBy(filtros_expedientes["tipos_contaminantes_regulados"], "id")
        : [];
    const contaminantes_regulados_listado =
      filtros_expedientes && filtros_expedientes["contaminantes_regulados"]
        ? _.orderBy(filtros_expedientes["contaminantes_regulados"], "id")
        : [];
    const tipos_fuentes_reguladas_listado =
      filtros_expedientes && filtros_expedientes["tipos_fuentes_reguladas"]
        ? _.orderBy(filtros_expedientes["tipos_fuentes_reguladas"], "id")
        : [];
    const detalle_fuentes_reguladas_listado =
      filtros_expedientes && filtros_expedientes["detalle_fuentes_reguladas"]
        ? _.orderBy(filtros_expedientes["detalle_fuentes_reguladas"], "id")
        : [];
    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Nombre del proyecto</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_nombre"
                    value={filtro_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese un nombre"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre &&
                    filtros_errores.data.nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Estado</label>
                <SelectPicker
                  name="filtro_estado"
                  data={estados_listado}
                  onChange={(value) => {
                    this.setState({ filtro_estado: value });
                  }}
                  value={filtro_estado}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.estado &&
                  filtros_errores.data.estado.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipo</label>
                <SelectPicker
                  name="filtro_tipo"
                  data={tipos_listado}
                  onChange={(value) => {
                    this.setState({ filtro_tipo: value });
                  }}
                  value={filtro_tipo}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipo &&
                  filtros_errores.data.tipo.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipología</label>
                <SelectPicker
                  name="filtro_tipologia"
                  data={tipologias_listado}
                  onChange={(value) => {
                    this.setState({ filtro_tipologia: value });
                  }}
                  value={filtro_tipologia}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipologia &&
                  filtros_errores.data.tipologia.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tema Ambiental</label>
                <SelectPicker
                  name="filtro_tema_ambiental"
                  data={tema_ambiental_listado}
                  onChange={(value) => {
                    this.setState({ filtro_tema_ambiental: value });
                  }}
                  value={filtro_tema_ambiental}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tema_ambiental &&
                  filtros_errores.data.tema_ambiental.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>
                  Tipos de Parametros, Elementos y Sustancias Regulados
                </label>
                <SelectPicker
                  name="filtro_tipos_parametros_elementos_sustancias"
                  data={tipos_parametros_elementos_sustancias_listado}
                  onChange={(value) => {
                    this.setState({
                      filtro_tipos_parametros_elementos_sustancias: value,
                    });
                  }}
                  value={filtro_tipos_parametros_elementos_sustancias}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipos_parametros_elementos_sustancias &&
                  filtros_errores.data.tipos_parametros_elementos_sustancias.map(
                    (err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    )
                  )}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Parametros, Elementos y Sustancias Regulados</label>
                <SelectPicker
                  name="filtro_parametros_elementos_sustancias"
                  data={parametros_elementos_sustancias_listado}
                  onChange={(value) => {
                    this.setState({
                      filtro_parametros_elementos_sustancias: value,
                    });
                  }}
                  value={filtro_parametros_elementos_sustancias}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.parametros_elementos_sustancias &&
                  filtros_errores.data.parametros_elementos_sustancias.map(
                    (err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    )
                  )}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fiscalizador</label>
                <SelectPicker
                  name="filtro_fiscalizador"
                  data={fiscalizadores_listado}
                  onChange={(value) => {
                    this.setState({
                      filtro_fiscalizador: value,
                    });
                  }}
                  value={filtro_fiscalizador}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.fiscalizador &&
                  filtros_errores.data.fiscalizador.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipos de Contaminantes Regulados</label>
                <SelectPicker
                  name="filtro_tipos_contaminantes_regulados"
                  data={tipos_contaminantes_regulados_listado}
                  onChange={(value) => {
                    this.setState({
                      filtro_tipos_contaminantes_regulados: value,
                    });
                  }}
                  value={filtro_tipos_contaminantes_regulados}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipos_contaminantes_regulados &&
                  filtros_errores.data.tipos_contaminantes_regulados.map(
                    (err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    )
                  )}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Contaminantes Regulados</label>
                <SelectPicker
                  name="filtro_contaminantes_regulados"
                  data={contaminantes_regulados_listado}
                  onChange={(value) => {
                    this.setState({
                      filtro_contaminantes_regulados: value,
                    });
                  }}
                  value={filtro_contaminantes_regulados}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.contaminantes_regulados &&
                  filtros_errores.data.contaminantes_regulados.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipos de Fuentes Reguladas</label>
                <SelectPicker
                  name="filtro_tipos_fuentes_reguladas"
                  data={tipos_fuentes_reguladas_listado}
                  onChange={(value) => {
                    this.setState({
                      filtro_tipos_fuentes_reguladas: value,
                    });
                  }}
                  value={filtro_tipos_fuentes_reguladas}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipos_fuentes_reguladas &&
                  filtros_errores.data.tipos_fuentes_reguladas.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fuentes Reguladas</label>
                <SelectPicker
                  name="filtro_detalle_fuentes_reguladas"
                  data={detalle_fuentes_reguladas_listado}
                  onChange={(value) => {
                    this.setState({
                      filtro_detalle_fuentes_reguladas: value,
                    });
                  }}
                  value={filtro_detalle_fuentes_reguladas}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.detalle_fuentes_reguladas &&
                  filtros_errores.data.detalle_fuentes_reguladas.map(
                    (err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroExpedientes}
            >
              <i className="fa fa-search"></i> Filtrar Expedientes MMA
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Filtros.propTypes = {
  obtenerExpedientes: PropTypes.func.isRequired,
  obtenerExpedientesFiltros: PropTypes.func.isRequired,
  limpiarFiltroExpedientes: PropTypes.func.isRequired,
  filtros_expedientes: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  expedientes_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_expedientes: state.mma.filtros_expedientes,
  filtros_errores: state.mma.filtros_errores,
  expedientes_filtro_input: state.mma.expedientes_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerExpedientes, obtenerExpedientesFiltros, limpiarFiltroExpedientes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
