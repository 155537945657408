import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  DOCUMENTO_FALLIDO,
  DOCUMENTO_GUARDAR,
  DOCUMENTO_OBTENER,
  DOCUMENTOS_FALLIDO,
  DOCUMENTOS_FILTROS_FALLIDO,
  DOCUMENTOS_FILTROS_INPUT,
  DOCUMENTOS_FILTROS_OBTENER,
  DOCUMENTOS_OBTENER,
  LIMPIAR_DOCUMENTOS_FILTROS,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerDocumentos = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/documentos/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: DOCUMENTOS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : DOCUMENTOS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerDocumentosFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/documentos/filtros/${data.pagina}`,
      data.filtros,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: DOCUMENTOS_FILTROS_INPUT, payload: data.filtros });
      dispatch({ type: DOCUMENTOS_FILTROS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : DOCUMENTOS_FILTROS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerDocumento = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(`${url_api}/api/vigilances/documento`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: DOCUMENTO_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : DOCUMENTO_FALLIDO,
        payload: error,
      });
    });
};

export const guardarDocumento = (id) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  dispatch({ type: DOCUMENTO_GUARDAR, payload: id });
};

export const limpiarFiltroDocumentos = () => (dispatch) => {
  dispatch({ type: LIMPIAR_DOCUMENTOS_FILTROS });
};
