import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import { format as formatRut } from "rut.js";
import {
  limpiarFiltroRequerimientosIngreso,
  obtenerRequerimientosIngresos,
  obtenerRequerimientosIngresosFiltros,
} from "../../../redux/actions/vigilancias/requerimientos/action";

export class Requerimientos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_categoria:
        this.props.requerimientos_filtro_input &&
        this.props.requerimientos_filtro_input.clasificacion
          ? this.props.requerimientos_filtro_input.clasificacion
          : "",
      filtro_fecha_inicio_hasta:
        this.props.requerimientos_filtro_input &&
        this.props.requerimientos_filtro_input.fecha_inicio_hasta
          ? this.props.requerimientos_filtro_input.fecha_inicio_hasta
          : "",
      filtro_fecha_inicio_desde:
        this.props.requerimientos_filtro_input &&
        this.props.requerimientos_filtro_input.fecha_inicio_desde
          ? this.props.requerimientos_filtro_input.fecha_inicio_desde
          : "",
      filtro_expediente:
        this.props.requerimientos_filtro_input &&
        this.props.requerimientos_filtro_input.expediente
          ? this.props.requerimientos_filtro_input.expediente
          : "",
      filtros_avanzandos:
        this.props.requerimientos_filtro_input &&
        this.props.requerimientos_filtro_input.filtros_avanzandos
          ? this.props.requerimientos_filtro_input.filtros_avanzandos
          : false,
    };
  }

  borrarFiltros = () => {
    const {
      obtenerRequerimientosIngresos,
      limpiarFiltroRequerimientosIngreso,
    } = this.props;
    obtenerRequerimientosIngresos(1);
    limpiarFiltroRequerimientosIngreso();
    this.setState({
      filtro_categoria: "",
      filtro_expediente: "",
      filtro_fecha_inicio_desde: "",
      filtro_fecha_inicio_hasta: "",
    });
  };

  filtroRequerimientos = () => {
    const {
      obtenerRequerimientosIngresosFiltros,
      obtenerRequerimientosIngresos,
    } = this.props;
    const {
      filtro_categoria,
      filtro_expediente,
      filtros_avanzandos,
      filtro_fecha_inicio_desde,
      filtro_fecha_inicio_hasta,
    } = this.state;

    if (
      filtro_categoria ||
      filtro_expediente ||
      filtro_fecha_inicio_desde ||
      filtro_fecha_inicio_hasta
    ) {
      var filtros = {
        categoria: filtro_categoria ? filtro_categoria : null,
        expediente: filtro_expediente ? filtro_expediente : null,
        fecha_inicio_desde: filtro_fecha_inicio_desde
          ? filtro_fecha_inicio_desde
          : null,
        fecha_inicio_hasta: filtro_fecha_inicio_hasta
          ? filtro_fecha_inicio_hasta
          : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerRequerimientosIngresosFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerRequerimientosIngresos(1);
    }
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (e.target.name === "filtro_titular_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  render = () => {
    const { filtros_requerimientos, filtros_errores } = this.props;
    const {
      filtro_categoria,
      filtro_expediente,
      filtros_avanzandos,
      filtro_fecha_inicio_desde,
      filtro_fecha_inicio_hasta,
    } = this.state;

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Expediente</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_expediente"
                    value={filtro_expediente}
                    onChange={this.onChange}
                    placeholder="Ingrese expediente"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.expediente
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.expediente &&
                    filtros_errores.data.expediente.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Categoría</label>
                <SelectPicker
                  name="filtro_categoria"
                  data={
                    filtros_requerimientos.categorias
                      ? filtros_requerimientos.categorias
                      : []
                  }
                  onChange={(value) => {
                    this.setState({ filtro_categoria: value });
                  }}
                  value={filtro_categoria}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.categoria &&
                  filtros_errores.data.categoria.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-6 col-sm-8 col-12 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de inicio </label>
                <div className="input-group">
                  <span className="input-group-text">Desde/Hasta</span>
                  <input
                    type="date"
                    name="filtro_fecha_inicio_desde"
                    value={filtro_fecha_inicio_desde}
                    onChange={this.onChange}
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_inicio_desde
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <input
                    type="date"
                    name="filtro_fecha_inicio_hasta"
                    value={filtro_fecha_inicio_hasta}
                    onChange={this.onChange}
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_inicio_hasta
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_inicio_desde &&
                    filtros_errores.data.fecha_inicio_desde.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_inicio_hasta &&
                    filtros_errores.data.fecha_inicio_hasta.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroRequerimientos}
            >
              <i className="fa fa-search"></i> Filtrar requerimientos de ingreso
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Requerimientos.propTypes = {
  obtenerRequerimientosIngresos: PropTypes.func.isRequired,
  obtenerRequerimientosIngresosFiltros: PropTypes.func.isRequired,
  limpiarFiltroRequerimientosIngreso: PropTypes.func.isRequired,
  filtros_requerimientos: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  requerimientos_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_requerimientos: state.vigilancias.filtros_requerimientos,
  filtros_errores: state.vigilancias.filtros_errores,
  requerimientos_filtro_input: state.vigilancias.requerimientos_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerRequerimientosIngresos,
      obtenerRequerimientosIngresosFiltros,
      limpiarFiltroRequerimientosIngreso,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Requerimientos);
