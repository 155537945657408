import axios from "axios";

import { tokenConfig } from "../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../auth/types";
import {
  CARGANDO_LIMPIAR_MMA,
  EXPEDIENTES_MMA_FALLIDO,
  EXPEDIENTES_MMA_FAVORITO_ELIMINAR_FALLIDO,
  EXPEDIENTES_MMA_FAVORITO_ELIMINAR_OBTENER,
  EXPEDIENTES_MMA_FAVORITO_FALLIDO,
  EXPEDIENTES_MMA_FAVORITO_OBTENER,
  EXPEDIENTES_MMA_FILTROS_FALLIDO,
  EXPEDIENTES_MMA_FILTROS_INPUT,
  EXPEDIENTES_MMA_FILTROS_OBTENER,
  EXPEDIENTES_MMA_OBTENER,
  EXPEDIENTE_MMA_FALLIDO,
  EXPEDIENTE_MMA_GUARDAR,
  EXPEDIENTE_MMA_OBTENER,
  LIMPIAR_EXPEDIENTES_MMA_FILTROS,
  MENSAJES_LIMPIAR,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const limpiarMensajes = () => (dispatch) => {
  dispatch({ type: MENSAJES_LIMPIAR });
};

export const obtenerExpedientes = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_MMA });
  axios
    .get(`${url_api}/api/mma/expedientes/${pagina}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: EXPEDIENTES_MMA_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : EXPEDIENTES_MMA_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerExpediente = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_MMA });
  axios
    .post(`${url_api}/api/mma/expediente`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: EXPEDIENTE_MMA_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : EXPEDIENTE_MMA_FALLIDO,
        payload: error,
      });
    });
};

export const guardarExpediente = (data) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_MMA });
  dispatch({ type: EXPEDIENTE_MMA_GUARDAR, payload: data });
};

export const limpiarFiltroExpedientes = () => (dispatch) => {
  dispatch({ type: LIMPIAR_EXPEDIENTES_MMA_FILTROS });
};

export const obtenerExpedientesFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_MMA });
  axios
    .post(
      `${url_api}/api/mma/expedientes/filtros/${data.pagina}`,
      data.filtros,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: EXPEDIENTES_MMA_FILTROS_INPUT, payload: data.filtros });
      dispatch({ type: EXPEDIENTES_MMA_FILTROS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : EXPEDIENTES_MMA_FILTROS_FALLIDO,
        payload: error,
      });
    });
};

export const agregarFavoritoExpediente = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_MMA });
  axios
    .post(`${url_api}/api/mma/expediente/favorito`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: EXPEDIENTES_MMA_FAVORITO_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : EXPEDIENTES_MMA_FAVORITO_FALLIDO,
        payload: error,
      });
    });
};

export const eliminarFavoritoExpediente = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_MMA });
  axios
    .put(`${url_api}/api/mma/expediente/favorito`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: EXPEDIENTES_MMA_FAVORITO_ELIMINAR_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : EXPEDIENTES_MMA_FAVORITO_ELIMINAR_FALLIDO,
        payload: error,
      });
    });
};
