import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import logo from "../../images/logo.png";
import { limpiarMensajes, registrate } from "../../redux/actions/auth/action";

export class InicioSesion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      cargando: false,
    };
  }

  componentDidMount = () => {
    SweetAlert.close();
  };

  componentWillUnmount = () => {
    const { limpiarMensajes } = this.props;
    limpiarMensajes();
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      cargando: true,
    });
    const { registrate } = this.props;
    registrate(this.state.email);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const {
      is_authenticated,
      limpiarMensajes,
      errores_registrate,
      guardar_registrate,
    } = this.props;
    const { email, cargando } = this.state;
    if (is_authenticated) {
      return <Navigate to="/user/inicio" />;
    }

    if (Object.entries(guardar_registrate).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Registro",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: guardar_registrate.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.setState({
            cargando: false,
          });
        }
      });
    }

    if (Object.entries(errores_registrate).length > 0) {
      SweetAlert.close();
      if (errores_registrate.data && errores_registrate.data.message) {
        SweetAlert.fire({
          title: "Registro Fallido",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: errores_registrate.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
            this.setState({
              cargando: false,
            });
          }
        });
      }
    }

    return (
      <Fragment>
        <div className="account-body accountbg">
          <div className="row vh-100">
            <div className="col-lg-12 pe-0 align-self-center">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-6 col-8 mx-auto">
                  <div className="card auth-card">
                    <div className="card-body">
                      <div className="px-3">
                        <div className="text-center auth-logo-text">
                          <p>
                            <img
                              src={logo}
                              alt="logo"
                              className="img-fluid auth-logo"
                            />
                          </p>
                          <h5>CREA TU CUENTA</h5>
                          <p className="mt-2">
                            Ingresa tu email y te enviaremos un link para crear
                            tu cuenta
                          </p>
                        </div>
                        <form
                          onSubmit={this.onSubmit}
                          className="form-horizontal auth-form my-4"
                        >
                          <div className="form-group">
                            <label>
                              Email <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="email"
                                name="email"
                                placeholder="Ingresa tu email"
                                onChange={this.onChange}
                                value={email}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="form-group mb-0 row">
                            <div className="col-12 mt-2 d-grid gap-2">
                              <button
                                className={`btn btn-danger ${
                                  cargando && "disabled"
                                }`}
                                type="submit"
                              >
                                Enviar Link de Registro
                                {cargando ? (
                                  <i className="fas fa-circle-notch fa-spin ms-1"></i>
                                ) : (
                                  <i className="fas fa-paper-plane ms-1"></i>
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-12 text-center mt-4">
                              <Link to="/inicio-sesion">
                                ¿Ya tienes una cuenta? Inicia Sesión aquí
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
}

InicioSesion.propTypes = {
  registrate: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool.isRequired,
  errores_registrate: PropTypes.object.isRequired,
  guardar_registrate: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_authenticated: state.auth.is_authenticated,
  errores_registrate: state.auth.errores_registrate,
  guardar_registrate: state.auth.guardar_registrate,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ registrate, limpiarMensajes }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InicioSesion);
