import React, { Component, Fragment } from "react";
import { Tooltip } from "react-tooltip";
import { Tabs as Procedimientos } from "../procedimientos";

export class Tabs extends Component {
  render = () => {
    const { hechos } = this.props;

    return hechos && hechos.length > 0 ? (
      hechos.map((hecho, x) => (
        <Fragment key={x}>
          <ul className="nav nav-pills nav-justified">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="hecho-general-tab"
                data-bs-toggle="pill"
                data-bs-target="#hecho-general"
                type="button"
                role="tab"
                aria-controls="hecho-general"
                aria-selected="true"
              >
                Información del Hecho Infraccional
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="hecho-procedimiento-tab"
                data-bs-toggle="pill"
                data-bs-target="#hecho-procedimiento"
                type="button"
                role="tab"
                aria-controls="hecho-procedimiento"
                aria-selected="false"
              >
                Procedimiento Sancionatorio
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show mt-3"
              id="hecho-general"
              role="tabpanel"
              aria-labelledby="hecho-general-tab"
            >
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="table-secondary w-20 text-dark">
                        Descripción{" "}
                        <i
                          className="fa fa-exclamation-circle ms-1 text-warning font-11"
                          data-tip
                          data-tooltip-id="descripcion_titulo"
                        ></i>
                        <Tooltip id="descripcion_titulo" aria-haspopup="true">
                          Descripción del hecho infraccional
                        </Tooltip>
                      </td>
                      <td>{hecho.nombre}</td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">
                        Clasificación{" "}
                        <i
                          className="fa fa-exclamation-circle ms-1 text-warning font-11"
                          data-tip
                          data-tooltip-id="clasificacion_titulo"
                        ></i>
                        <Tooltip id="clasificacion_titulo" aria-haspopup="true">
                          Clasificación del hecho infraccional (Art.36 LOSMA)
                        </Tooltip>
                      </td>
                      <td>
                        {hecho.clasificacion ? (
                          <Fragment>
                            {hecho.clasificacion}
                            <Tooltip
                              className="tooltip-limite"
                              id={`clasificacion_detalle_${x}`}
                              aria-haspopup="true"
                            >
                              {hecho.clasificacion_detalle}
                            </Tooltip>
                            <i
                              data-tip
                              data-tooltip-id={`clasificacion_detalle_${x}`}
                              className="fa fa-exclamation-circle text-danger ms-1 font-11"
                            ></i>
                          </Fragment>
                        ) : (
                          "S/I"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-secondary w-20 text-dark">
                        Tipo{" "}
                        <i
                          className="fa fa-exclamation-circle ms-1 text-warning font-11"
                          data-tip
                          data-tooltip-id="tipo_titulo"
                        ></i>
                        <Tooltip id="tipo_titulo" aria-haspopup="true">
                          Tipo de infracción (Art.35 LOSMA)
                        </Tooltip>
                      </td>
                      <td>{hecho.infraccion}</td>
                    </tr>
                    <tr>
                      <td className="table-secondary w-20 text-dark">
                        Instrumentos{" "}
                        <i
                          className="fa fa-exclamation-circle ms-1 text-warning font-11"
                          data-tip
                          data-tooltip-id="instrumentos_titulo"
                        ></i>
                        <Tooltip id="instrumentos_titulo" aria-haspopup="true">
                          Instrumentos de cumplimiento infringidos
                        </Tooltip>
                      </td>
                      <td>
                        {hecho.instrumentos_infringidos &&
                        hecho.instrumentos_infringidos.length > 0
                          ? hecho.instrumentos_infringidos.map((inst, y) => (
                              <p className="mb-0 text-start" key={y}>
                                <a
                                  href={inst.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {inst.nombre}{" "}
                                  <i className="fa fa-angle-right"></i>
                                </a>
                              </p>
                            ))
                          : "S/I"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {hecho.procedimiento && (
              <div
                className="tab-pane fade mt-3"
                id="hecho-procedimiento"
                role="tabpanel"
                aria-labelledby="hecho-procedimiento-tab"
              >
                <Procedimientos procedimientos={[hecho.procedimiento]} />
              </div>
            )}
          </div>
        </Fragment>
      ))
    ) : (
      <h6>No existen hechos infraccionales</h6>
    );
  };
}

export default Tabs;
