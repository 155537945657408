import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  limpiarMensajes,
  nuevaContrasena,
} from "../../redux/actions/auth/action";
import { Footer, Navbar, SidebarInicio } from "../general";

export class Contrasena extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pass_actual: "",
      pass_nueva: "",
      pass_repetida: "",
      pass_actual_mostrar: false,
      pass_nueva_mostrar: false,
      pass_repetida_mostrar: false,
    };
  }

  cambiarContrasena = () => {
    const { pass_actual, pass_nueva, pass_repetida } = this.state;
    const { nuevaContrasena } = this.props;
    nuevaContrasena({
      pass_actual: pass_actual,
      pass_nueva: pass_nueva,
      pass_repetida: pass_repetida,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  cambioCompleto = () => {
    // TODO: mejorar el limpiado de mensajes
    const { limpiarMensajes } = this.props;
    limpiarMensajes();
    this.setState({ pass_actual: "", pass_nueva: "", pass_repetida: "" });
  };

  render = () => {
    const {
      pass_actual,
      pass_nueva,
      pass_repetida,
      pass_actual_mostrar,
      pass_nueva_mostrar,
      pass_repetida_mostrar,
    } = this.state;
    const { contrasena_nueva_error, contrasena_nueva } = this.props;

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="contrasena" />
        <div className="page-content">
          {Object.entries(contrasena_nueva).length > 0 && (
            <SweetAlert
              success
              customButtons={
                <Fragment>
                  <button
                    className="btn btn-primary me-3"
                    onClick={this.cambioCompleto}
                  >
                    Cerrar <i className="fa fa-thumbs-up"></i>
                  </button>
                </Fragment>
              }
              title="Nueva Contraseña"
              onConfirm={this.cambioCompleto}
            >
              {contrasena_nueva.message}
            </SweetAlert>
          )}
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-lock"></i> CONTRASEÑA
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="text-uppercase mb-1">
                      Cambia tu Contraseña
                    </h5>
                    <p className="mb-0">
                      Cambia tu contraseña para mantener tu cuenta segura.
                    </p>
                    <p>
                      Para cambiar tu contraseña, ingresa tu contraseña actual y
                      luego tu nueva contraseña.
                    </p>
                    <div className="row mt-4">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            Contraseña Actual{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <div className="input-group">
                            <input
                              type={pass_actual_mostrar ? "text" : "password"}
                              name="pass_actual"
                              onChange={this.onChange}
                              value={pass_actual}
                              className={
                                contrasena_nueva_error &&
                                contrasena_nueva_error.data &&
                                contrasena_nueva_error.data.pass_actual
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            <span className="input-group-text">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  this.setState({
                                    pass_actual_mostrar: !pass_actual_mostrar,
                                  })
                                }
                              />
                            </span>
                          </div>
                          <small className="text-muted">
                            Contraseña actual que ocupas para iniciar sesión.
                          </small>
                          {contrasena_nueva_error &&
                            contrasena_nueva_error.data &&
                            contrasena_nueva_error.data.pass_actual &&
                            contrasena_nueva_error.data.pass_actual.map(
                              (err, x) => (
                                <div key={x} className="small error">
                                  {err}
                                </div>
                              )
                            )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            Nueva Contraseña{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <div className="input-group">
                            <input
                              type={pass_nueva_mostrar ? "text" : "password"}
                              name="pass_nueva"
                              onChange={this.onChange}
                              value={pass_nueva}
                              className={
                                contrasena_nueva_error &&
                                contrasena_nueva_error.data &&
                                contrasena_nueva_error.data.pass_nueva
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            <span className="input-group-text">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  this.setState({
                                    pass_nueva_mostrar: !pass_nueva_mostrar,
                                  })
                                }
                              />
                            </span>
                          </div>
                          <small className="text-muted">
                            La contraseña debe tener al menos 8 caracteres.
                          </small>
                          {contrasena_nueva_error &&
                            contrasena_nueva_error.data &&
                            contrasena_nueva_error.data.pass_nueva &&
                            contrasena_nueva_error.data.pass_nueva.map(
                              (err, x) => (
                                <div key={x} className="small error">
                                  {err}
                                </div>
                              )
                            )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            Repite la Contraseña{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <div className="input-group">
                            <input
                              type={pass_repetida_mostrar ? "text" : "password"}
                              name="pass_repetida"
                              onChange={this.onChange}
                              value={pass_repetida}
                              className={
                                contrasena_nueva_error &&
                                contrasena_nueva_error.data &&
                                contrasena_nueva_error.data.pass_repetida
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            <span className="input-group-text">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  this.setState({
                                    pass_repetida_mostrar:
                                      !pass_repetida_mostrar,
                                  })
                                }
                              />
                            </span>
                          </div>
                          <small className="text-muted">
                            Repite nuevamente la contraseña.
                          </small>
                          {contrasena_nueva_error &&
                            contrasena_nueva_error.data &&
                            contrasena_nueva_error.data.pass_repetida &&
                            contrasena_nueva_error.data.pass_repetida.map(
                              (err, x) => (
                                <div key={x} className="small error">
                                  {err}
                                </div>
                              )
                            )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <button
                          type="button"
                          onClick={this.cambiarContrasena}
                          className="btn btn-danger"
                        >
                          Guardar Contraseña <i className="fas fa-save"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Contrasena.propTypes = {
  nuevaContrasena: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  contrasena_nueva: PropTypes.object.isRequired,
  contrasena_nueva_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  contrasena_nueva: state.auth.contrasena_nueva,
  contrasena_nueva_error: state.auth.contrasena_nueva_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ nuevaContrasena, limpiarMensajes }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Contrasena);
