import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import SweetAlert from "sweetalert2";
import {
  eliminarEquipo,
  guardarEquipo,
  limpiarMensajes,
} from "../../../redux/actions/teams/equipos/action";

export class Tabla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_integrante: "",
    };
  }

  setEquipo = (id) => {
    const { guardarEquipo } = this.props;
    guardarEquipo({ id: id });
  };

  borrarEquipo = (equipo) => {
    const { eliminarEquipo } = this.props;
    SweetAlert.fire({
      title: "Equipo",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de eliminar el Equipo ${equipo.nombre}?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarEquipo({ equipo_id: equipo.id });
        this.setState({
          usuario_loading: SweetAlert.fire({
            title: "Equipo",
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: "Estamos eliminando el equipo, espere unos segundos",
            icon: "warning",
            showConfirmButton: false,
            didOpen: () => {
              SweetAlert.showLoading();
            },
          }),
        });
      }
    });
  };

  render = () => {
    const {
      limpiarMensajes,
      obtenerEquipos,
      equipos,
      equipo_eliminar,
      equipo_eliminar_error,
      user,
      usuarios,
    } = this.props;
    const { filtro_integrante } = this.state;
    const perfil =
      user && user.modulos && user.modulos.sub_teams
        ? user.modulos.sub_teams.perfil
        : null;
    var equipos_result = equipos && equipos.result ? equipos.result : [];
    if (filtro_integrante) {
      const integrantes = equipos_result.filter((equipo) => {
        const usuario = equipo.usuario.find(
          (usuario) => usuario.pk === filtro_integrante
        );
        return usuario ? true : false;
      });
      equipos_result = integrantes;
    }
    const usuarios_listado = _.orderBy(usuarios, ["nombre_corto"], ["asc"]).map(
      (usuario) => ({
        label: usuario.nombre_corto,
        value: usuario.pk,
      })
    );

    if (Object.entries(equipo_eliminar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Equipo Eliminado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: equipo_eliminar.message,
        icon: "success",
        confirmButtonText: 'Aceptar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          document.getElementById("cerrar_modal_nuevo_equipo").click();
          this.setState({
            nombre_equipo: "",
          });
          obtenerEquipos();
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(equipo_eliminar_error).length > 0) {
      SweetAlert.close();
      if (equipo_eliminar_error.data && equipo_eliminar_error.data.message) {
        SweetAlert.fire({
          title: "Equipo no Eliminado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: equipo_eliminar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    }

    return (
      <>
        <div className="row mb-2">
          <div className="col-auto">
            <h5 className="text-uppercase">Filtrar Integrante</h5>
          </div>
          <div className="col-auto">
            <SelectPicker
              name="filtro_integrante"
              data={usuarios_listado}
              onChange={(value) => {
                this.setState({ filtro_integrante: value });
              }}
              value={filtro_integrante}
              locale={{
                noResultsText: "Sin resultados",
                placeholder: "Selecciona una opción",
                searchPlaceholder: "Buscar",
              }}
            />
            <button
              className="btn btn-soft-danger btn-sm ms-1"
              type="button"
              onClick={() => this.setState({ filtro_integrante: "" })}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-sm table-hover">
            <thead className="table-secondary">
              <tr>
                <th className="w-3 text-center">ID</th>
                <th className="w-33">Nombre del Equipo</th>
                <th>Integrantes</th>
                <th className="w-17">Expedientes</th>
                <th className="w-5 text-center">Acción</th>
              </tr>
            </thead>
            <tbody>
              {equipos_result.length > 0 &&
                equipos_result.map((equipo, x) => (
                  <tr key={x}>
                    <td className="text-center">{x + 1}</td>
                    <td>{equipo.nombre}</td>
                    <td className="align-middle">
                      {equipo.usuario.map((usuario, y) => (
                        <p
                          className="ms-1 badge badge-soft-primary font-12"
                          key={y}
                        >
                          <i className="fa fa-user-tie"></i>{" "}
                          {usuario.nombre_corto}
                        </p>
                      ))}
                    </td>
                    <td>
                      {equipo.n_medidas > 0 && (
                        <p className="ms-1 mb-1 badge font-12 badge-soft-dark">
                          <i className="fa fa-cogs"></i> Medidas Provisionales:{" "}
                          {equipo.n_medidas}
                        </p>
                      )}
                      {equipo.n_procedimientos > 0 && (
                        <p className="ms-1 mb-1 badge font-12 badge-soft-danger">
                          <i className="fa fa-bullhorn"></i> Procedimientos
                          Sancionatorios: {equipo.n_procedimientos}
                        </p>
                      )}
                      {equipo.n_requerimientos > 0 && (
                        <p className="ms-1 mb-1 badge font-12 badge-soft-warning">
                          <i className="fa fa-arrow-alt-circle-right"></i>{" "}
                          Requerimientos de Ingreso: {equipo.n_requerimientos}
                        </p>
                      )}
                    </td>
                    <td className="text-center">
                      <div className="btn-group">
                        {perfil && perfil in ["3", "2"] && (
                          <button
                            className="btn btn-soft-danger"
                            onClick={this.borrarEquipo.bind(this, equipo)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        )}
                        <Link
                          to="/teams/equipo"
                          className="btn btn-danger"
                          onClick={this.setEquipo.bind(this, equipo.id)}
                        >
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };
}

Tabla.propTypes = {
  guardarEquipo: PropTypes.func.isRequired,
  eliminarEquipo: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  equipo_eliminar: PropTypes.object.isRequired,
  equipo_eliminar_error: PropTypes.object.isRequired,
  usuarios: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_teams: state.teams.is_loading_teams,
  equipo_eliminar: state.teams.equipo_eliminar,
  equipo_eliminar_error: state.teams.equipo_eliminar_error,
  usuarios: state.teams.usuarios,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { guardarEquipo, eliminarEquipo, limpiarMensajes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabla);
