import React, { Component } from "react";
import { TablaPequena as Documentos } from "../documentos";
import { Tabs as Procedimientos } from "../procedimientos";
import { Tabs as UnidadesFiscalizables } from "../unidades";

export class Tabs extends Component {
  render = () => {
    const { medidas_provisionales } = this.props;

    return medidas_provisionales && medidas_provisionales.length > 0 ? (
      medidas_provisionales.map((medida_provisional, x) => (
        <div key={x}>
          <ul className="nav nav-pills nav-justified">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="medida-general-tab"
                data-bs-toggle="pill"
                data-bs-target="#medida-general"
                type="button"
                role="tab"
                aria-controls="medida-general"
                aria-selected="true"
              >
                Información de la Medida Provisional
              </button>
            </li>
            {medida_provisional.procedimiento &&
              medida_provisional.procedimiento.length > 0 && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="procedimiento-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#procedimiento"
                    type="button"
                    role="tab"
                    aria-controls="procedimiento"
                    aria-selected="true"
                  >
                    Procedimiento Sancionatorio{" "}
                    <small>
                      (
                      {medida_provisional.procedimiento
                        ? medida_provisional.procedimiento.length
                        : 0}
                      )
                    </small>
                  </button>
                </li>
              )}
            {medida_provisional.documentos &&
              medida_provisional.documentos.length > 0 && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="documentos-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#documentos"
                    type="button"
                    role="tab"
                    aria-controls="documentos"
                    aria-selected="false"
                  >
                    Documentos{" "}
                    <small>({medida_provisional.documentos.length})</small>
                  </button>
                </li>
              )}
            {medida_provisional.unidad_fiscalizable &&
              medida_provisional.unidad_fiscalizable.length > 0 && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="unidades-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#unidades"
                    type="button"
                    role="tab"
                    aria-controls="unidades"
                    aria-selected="false"
                  >
                    Unidades Fiscalizables{" "}
                    <small>
                      ({medida_provisional.unidad_fiscalizable.length})
                    </small>
                  </button>
                </li>
              )}
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show mt-3"
              id="medida-general"
              role="tabpanel"
              aria-labelledby="medida-general-tab"
            >
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="table-secondary w-20 text-dark">
                        Expediente
                      </td>
                      <td>
                        {medida_provisional.expediente &&
                          medida_provisional.expediente}
                      </td>
                    </tr>
                    {medida_provisional.fecha && (
                      <tr>
                        <td className="table-secondary text-dark">
                          Fecha de creación
                        </td>
                        <td>
                          {medida_provisional.fecha && medida_provisional.fecha}
                        </td>
                      </tr>
                    )}
                    {medida_provisional.fecha_inicio && (
                      <tr>
                        <td className="table-secondary text-dark">
                          Fecha de inicio
                        </td>
                        <td>
                          {medida_provisional.fecha_inicio &&
                            medida_provisional.fecha_inicio}
                        </td>
                      </tr>
                    )}
                    {medida_provisional.estado && (
                      <tr>
                        <td className="table-secondary text-dark">Estado</td>
                        <td>
                          {medida_provisional.estado &&
                            medida_provisional.estado}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {medida_provisional.procedimiento &&
              medida_provisional.procedimiento.length > 0 && (
                <div
                  className="tab-pane fade  mt-3"
                  id="procedimiento"
                  role="tabpanel"
                  aria-labelledby="procedimiento-tab"
                >
                  {medida_provisional.procedimiento && (
                    <Procedimientos
                      procedimientos={medida_provisional.procedimiento}
                    />
                  )}
                </div>
              )}
            {medida_provisional.documentos &&
              medida_provisional.documentos.length > 0 && (
                <div
                  className="tab-pane fade  mt-3"
                  id="documentos"
                  role="tabpanel"
                  aria-labelledby="documentos-tab"
                >
                  <Documentos documentos={medida_provisional.documentos} />
                </div>
              )}
            {medida_provisional.unidad_fiscalizable &&
              medida_provisional.unidad_fiscalizable.length > 0 && (
                <div
                  className="tab-pane fade mt-3"
                  id="unidades"
                  role="tabpanel"
                  aria-labelledby="unidades-tab"
                >
                  <UnidadesFiscalizables
                    unidades_fiscalizables={
                      medida_provisional.unidad_fiscalizable
                    }
                  />
                </div>
              )}
          </div>
        </div>
      ))
    ) : (
      <h6>NO existen medidas provisionales</h6>
    );
  };
}

export default Tabs;
