import { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerProyectos } from "../../../redux/actions/tareas/proyectos/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Ficha } from "./";

export class Editar extends Component {
  componentDidMount = () => {
    const { obtenerProyectos } = this.props;
    obtenerProyectos();
  };

  render = () => {
    const { is_loading_tareas, proyectos } = this.props;
    if (is_loading_tareas) {
      SweetAlert.fire({
        title: "Proyectos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos cargando tus proyectos, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="tareas_proyectos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tasks"></i> PROYECTOS
                  </h4>
                  <p className="float-end">
                    <Link
                      to="/quality-tasking/proyecto/nuevo"
                      className="ms-2 btn btn-primary btn-xs"
                    >
                      Crear nuevo proyecto <i className="fa fa-plus-circle"></i>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <Ficha proyectos={proyectos} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}

Editar.propTypes = {
  obtenerProyectos: PropTypes.func.isRequired,
  is_loading_tareas: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  proyectos: state.tareas.proyectos,
  is_loading_tareas: state.tareas.is_loading_tareas,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerProyectos }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Editar);
