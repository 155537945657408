import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  QUINENCO_DASHBOARD_FALLIDO,
  QUINENCO_DASHBOARD_OBTENER,
  QUINENCO_EXPEDIENTES_FALLIDO,
  QUINENCO_EXPEDIENTES_FILTROS_FALLIDO,
  QUINENCO_EXPEDIENTES_FILTROS_INPUT,
  QUINENCO_EXPEDIENTES_FILTROS_OBTENER,
  QUINENCO_EXPEDIENTES_OBTENER,
  QUINENCO_LIMPIAR_EXPEDIENTES_FILTROS,
  QUINENCO_LIMPIAR_PERTINENCIAS_FILTROS,
  QUINENCO_LIMPIAR_UNIDADES_FILTROS,
  QUINENCO_PERTINENCIAS_FALLIDO,
  QUINENCO_PERTINENCIAS_FILTROS_FALLIDO,
  QUINENCO_PERTINENCIAS_FILTROS_INPUT,
  QUINENCO_PERTINENCIAS_FILTROS_OBTENER,
  QUINENCO_PERTINENCIAS_OBTENER,
  QUINENCO_UNIDADES_FALLIDO,
  QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_FALLIDO,
  QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_INPUT,
  QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_OBTENER,
  QUINENCO_UNIDADES_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerQuinencoUnidades = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/quinenco/unidades/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: QUINENCO_UNIDADES_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : QUINENCO_UNIDADES_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerQuinencoUnidadesFiltros =
  (data) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .post(
        `${url_api}/api/vigilances/quinenco/unidades/${data.pagina}`,
        data.filtros,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_INPUT,
          payload: data.filtros,
        });
        dispatch({
          type: QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : QUINENCO_UNIDADES_FISCALIZABLES_FILTROS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerQuinencoExpedientes = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/quinenco/expedientes/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: QUINENCO_EXPEDIENTES_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : QUINENCO_EXPEDIENTES_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerQuinencoExpedientesFiltros =
  (data) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .post(
        `${url_api}/api/vigilances/quinenco/expedientes/${data.pagina}`,
        data.filtros,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: QUINENCO_EXPEDIENTES_FILTROS_INPUT,
          payload: data.filtros,
        });
        dispatch({
          type: QUINENCO_EXPEDIENTES_FILTROS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : QUINENCO_EXPEDIENTES_FILTROS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerQuinencoPertinencias = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/quinenco/pertinencias/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: QUINENCO_PERTINENCIAS_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : QUINENCO_PERTINENCIAS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerQuinencoPertinenciasFiltros =
  (data) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .post(
        `${url_api}/api/vigilances/quinenco/pertinencias/${data.pagina}`,
        data.filtros,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: QUINENCO_PERTINENCIAS_FILTROS_INPUT,
          payload: data.filtros,
        });
        dispatch({
          type: QUINENCO_PERTINENCIAS_FILTROS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : QUINENCO_PERTINENCIAS_FILTROS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerDashboard = () => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(`${url_api}/api/vigilances/quinenco/dashboard`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: QUINENCO_DASHBOARD_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : QUINENCO_DASHBOARD_FALLIDO,
        payload: error,
      });
    });
};

export const limpiarFiltroQuinencoUnidades = () => (dispatch) => {
  dispatch({ type: QUINENCO_LIMPIAR_UNIDADES_FILTROS });
};

export const limpiarFiltroQuinencoExpedientes = () => (dispatch) => {
  dispatch({ type: QUINENCO_LIMPIAR_EXPEDIENTES_FILTROS });
};

export const limpiarFiltroQuinencoPertinencias = () => (dispatch) => {
  dispatch({ type: QUINENCO_LIMPIAR_PERTINENCIAS_FILTROS });
};
