import { Component, createRef } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  creacionArchivo,
  creacionCarga,
  limpiarMensajes,
} from "../../../redux/actions/tareas/action";

export class Nuevo extends Component {
  constructor(props) {
    super(props);
    this.fileInput = createRef();
    this.state = {
      archivo_nombre: "",
      archivo_link: "",
      archivo_descripcion: "",
      archivo_recurso_error: "",
    };
  }

  cancelarCreacionCargaEntregable = (e) => {
    this.props.cancelarCreacionCargaEntregable();
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  creaArchivo = (entregable, archivo) => {
    const { creacionArchivo, tarea_seleccionada } = this.props;
    if (this.fileInput.current.files.length > 0) {
      creacionArchivo(
        tarea_seleccionada,
        entregable,
        archivo,
        this.fileInput.current.files[0]
      );
    }
  };

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  nuevaCarga = () => {
    const { creacionCarga, tarea_seleccionada, entregable } = this.props;
    const { archivo_nombre, archivo_descripcion, archivo_link } = this.state;

    creacionCarga({
      nombre: archivo_nombre,
      descripcion: archivo_descripcion,
      link: archivo_link,
      tipo: "carga",
      entregable: entregable.id,
      tarea: tarea_seleccionada,
    });
    SweetAlert.fire({
      title: "Nueva carga del entregable",
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: "Estamos validando los datos enviados, espere unos segundos",
      icon: "warning",
      showConfirmButton: false,
      didOpen: () => {
        SweetAlert.showLoading();
      },
    });
  };

  render = () => {
    const {
      archivo_nombre,
      archivo_link,
      archivo_descripcion,
      archivo_recurso_error,
    } = this.state;
    const {
      limpiarMensajes,
      archivo_carga_error,
      archivo_carga,
      archivo_nuevo,
      archivo_nuevo_error,
    } = this.props;

    if (Object.entries(archivo_carga_error).length > 0) {
      SweetAlert.close();
      if (archivo_carga_error.data && archivo_carga_error.data.message) {
        SweetAlert.fire({
          title: "Carga del entregable no guardado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: archivo_carga_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(archivo_carga).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Carga del entregable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: archivo_carga.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.creaArchivo(archivo_carga.entregable, archivo_carga.archivo);
          this.cancelarCreacionCargaEntregable();
          this.tareaSeleccionada();
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(archivo_nuevo).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Archivo",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: archivo_nuevo.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
          this.tareaSeleccionada();
        }
      });
    }

    if (
      Object.entries(archivo_nuevo_error).length > 0 &&
      archivo_nuevo_error.data &&
      archivo_nuevo_error.data.message
    ) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Archivo",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: archivo_nuevo_error.data.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
        }
      });
    }

    return (
      <div className="col-lg-12">
        <div className="card card-eco">
          <div className="card-header bg-primary text-white">
            <h5>NUEVA CARGA DEL ENTREGABLE</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 text-end">
                <p>
                  <span className="text-danger">*</span> datos obligatorios
                  <br />
                  <span className="text-danger">**</span> como mínimo debe ir
                  una descripción o un documento
                </p>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Nombre <small className="text-danger">*</small>
                  </label>
                  <input
                    type="text"
                    name="archivo_nombre"
                    onChange={this.onChange}
                    value={archivo_nombre}
                    placeholder="Ingrese un nombre"
                    className={
                      archivo_carga_error &&
                      archivo_carga_error.data &&
                      archivo_carga_error.data.nombre
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {archivo_carga_error &&
                    archivo_carga_error.data &&
                    archivo_carga_error.data.nombre &&
                    archivo_carga_error.data.nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
                <div className="form-group">
                  <label>Documento</label>
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx"
                    name="archivo_pdf"
                    ref={this.fileInput}
                    placeholder="Seleccione un archivo"
                    className={
                      archivo_carga_error &&
                      archivo_carga_error.data &&
                      archivo_carga_error.data.documento
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {archivo_carga_error &&
                    archivo_carga_error.data &&
                    archivo_carga_error.data.documento &&
                    archivo_carga_error.data.documento.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Link</label>
                  <input
                    type="text"
                    name="archivo_link"
                    onChange={this.onChange}
                    value={archivo_link}
                    placeholder="Ingrese un link"
                    className={
                      archivo_recurso_error &&
                      archivo_recurso_error.data &&
                      archivo_recurso_error.data.link
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {archivo_recurso_error &&
                    archivo_recurso_error.data &&
                    archivo_recurso_error.data.link &&
                    archivo_recurso_error.data.link.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
                <div className="form-group">
                  <label>Descripción</label>
                  <textarea
                    rows="4"
                    name="archivo_descripcion"
                    onChange={this.onChange}
                    value={archivo_descripcion}
                    placeholder="Ingrese una descripción"
                    className={
                      archivo_carga_error &&
                      archivo_carga_error.data &&
                      archivo_carga_error.data.descripcion
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  ></textarea>
                  {archivo_carga_error &&
                    archivo_carga_error.data &&
                    archivo_carga_error.data.descripcion &&
                    archivo_carga_error.data.descripcion.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-sm-12 text-end">
                <div className="btn-group">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.nuevaCarga}
                  >
                    Crear nueva carga del entregable{" "}
                    <i className="fa fa-plus-circle"></i>
                  </button>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={this.cancelarCreacionCargaEntregable}
                  >
                    Cancelar <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

Nuevo.propTypes = {
  creacionCarga: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  creacionArchivo: PropTypes.func.isRequired,
  archivo_carga: PropTypes.object.isRequired,
  archivo_carga_error: PropTypes.object.isRequired,
  archivo_nuevo: PropTypes.object.isRequired,
  archivo_nuevo_error: PropTypes.object.isRequired,
  tarea_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  archivo_carga: state.tareas.archivo_carga,
  archivo_carga_error: state.tareas.archivo_carga_error,
  archivo_nuevo: state.tareas.archivo_nuevo,
  archivo_nuevo_error: state.tareas.archivo_nuevo_error,
  tarea_seleccionada: state.tareas.tarea_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { creacionCarga, limpiarMensajes, creacionArchivo },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Nuevo);
