import React, { Component } from "react";

import currencyformatter from "currencyformatter.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerExpedientesEstadisticaGeneradoras } from "../../../redux/actions/vigilancias/expedientes/action";
import { Chart, Footer, Navbar, SidebarInicio } from "../../general";

export class Estadisticas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ver_expedientes: true,
      ver_mapa: false,
    };
  }

  componentDidMount = () => {
    const {
      obtenerExpedientesEstadisticaGeneradoras,
      expedientes_estadisticas_generadoras,
    } = this.props;
    if (Object.entries(expedientes_estadisticas_generadoras).length === 0) {
      obtenerExpedientesEstadisticaGeneradoras(1);
    }
  };

  render = () => {
    const { expedientes_estadisticas_generadoras, is_loading_vigilancias } =
      this.props;

    var info = expedientes_estadisticas_generadoras.result;
    var total_inversion =
      info?.inversion && info.inversion > 0
        ? currencyformatter.format(info.inversion, {
            currency: "CLP",
            locale: "es_CL",
          })
        : "$0";

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Dashboard Generadoras",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Generando información, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="empresa_generadoras" menu_2="dashboard" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-chart-area"></i> DASHBOARD{" "}
                    <small>({info?.total && info.total})</small>
                  </h4>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">Proyectos</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-purple">{info?.total}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">Tipo DIA</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-purple">
                            <span className="me-5">{info?.dia}</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">Tipo EIA</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-purple">
                            <span className="me-5">{info?.eia}</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">
                          Inversión total{" "}
                          <span className="small">(millones de dólares)</span>
                        </h5>
                        <div className="d-flex justify-content-between">
                          <p className="small mt-0">
                            Desde {info?.fecha_min} {" - "} Hasta{" "}
                            {info?.fecha_max}
                          </p>

                          <h2 className="text-purple">
                            {total_inversion}
                            <small className="ms-1">mm</small>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <Chart
                      data={{
                        DIA: info?.dia,
                        EIA: info?.eia,
                      }}
                      nombre="Proyectos"
                      titulo="Proyectos por tipos"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8">
                <Chart
                  is_vertical
                  data={info?.regiones}
                  nombre="Proyectos"
                  titulo="Proyectos por región"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  data={info?.estados}
                  nombre="Proyectos"
                  titulo="Proyectos por estado"
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Chart
                  is_vertical
                  data={info?.anios}
                  nombre="Proyectos"
                  titulo="Proyectos ingresados por años"
                />
              </div>
              <div className="col-lg-12 col-sm-12">
                <Chart
                  is_vertical
                  data={info?.meses}
                  nombre="Proyectos"
                  titulo="Proyectos ingresados por mes"
                />
              </div>
              <div className="col-lg-12 col-sm-12">
                <Chart data={info?.pas} nombre="Proyectos" titulo="PAS" />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Estadisticas.propTypes = {
  obtenerExpedientesEstadisticaGeneradoras: PropTypes.func.isRequired,
  expedientes_estadisticas_generadoras: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  expedientes_estadisticas_generadoras:
    state.vigilancias.expedientes_estadisticas_generadoras,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerExpedientesEstadisticaGeneradoras },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Estadisticas);
