import axios from 'axios';
import download from 'downloadjs';

import {
  tokenArchivoDescargaConfig,
  tokenConfig,
} from '../auth/action';
import { AUTENTIFICACION_FALLIDO } from '../auth/types';
import {
  ARCHIVO_PERMISO_FALLIDO,
  ARCHIVO_PREGUNTA_FALLIDO,
  CARGANDO_LIMPIAR_PERMITTING,
  CARGANDO_OK,
  CATEGORIA_SECCION_SELECCIONADA,
  CATEGORIA_SELECCIONADA,
  CATEGORIAS_FALLIDO,
  CATEGORIAS_OBTENER,
  CONSULTA_CREAR_FALLIDO,
  CONSULTA_CREAR_OBTENER,
  CONSULTA_DUPLICAR_FALLIDO,
  CONSULTA_DUPLICAR_OBTENER,
  CONSULTA_ELIMINAR_FALLIDO,
  CONSULTA_ELIMINAR_OBTENER,
  CONSULTA_SELECCIONADA,
  DASHBOARD_FALLIDO,
  DASHBOARD_OBTENER,
  GENERAR_ENTREGABLE_FALLIDO,
  GENERAR_ENTREGABLE_OBTENER,
  INTELIGENCIA_CSV_FALLIDO,
  INTELIGENCIA_SELECCIONADA,
  INTELIGENCIAS_FALLIDO,
  INTELIGENCIAS_OBTENER,
  PERMISO_FALLIDO,
  PERMISO_INCLUIDO_FALLIDO,
  PERMISO_INCLUIDO_OBTENER,
  PERMISO_OBTENER,
  PERMISO_OMITIR_FALLIDO,
  PERMISO_OMITIR_OBTENER,
  PERMISO_SELECCIONADO,
  PERMISO_TAREA_SELECCIONADO,
  PERMISOS_FALLIDO,
  PERMISOS_FILTROS_FALLIDO,
  PERMISOS_FILTROS_OBTENER,
  PERMISOS_OBTENER,
  PERMISOS_REQUISITOS_FALLIDO,
  PERMISOS_REQUISITOS_OBTENER,
  PERMISOS_REQUISITOS_TIMELINE_FALLIDO,
  PERMISOS_REQUISITOS_TIMELINE_OBTENER,
  PREGUNTAS_FALLIDO,
  PREGUNTAS_OBTENER,
  PROYECTO_CSV_FALLIDO,
  PROYECTOS_FALLIDO,
  PROYECTOS_OBTENER,
  RESPUESTAS_FALLIDO,
  RESPUESTAS_INDEFINIDAS_FALLIDO,
  RESPUESTAS_INDEFINIDAS_OBTENER,
  RESPUESTAS_OBTENER,
  SECCION_SELECCIONADA,
  SECCIONES_FALLIDO,
  SECCIONES_OBTENER,
} from './types';

var url_api = process.env.REACT_APP_API_URL;


export const obtenerRespuestasIndefinidas = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/respuestas-indefinidas`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: RESPUESTAS_INDEFINIDAS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : RESPUESTAS_INDEFINIDAS_FALLIDO,
        payload: error
      });
    });
};


export const obtenerPermisosRequisitos = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/requisitos`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PERMISOS_REQUISITOS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PERMISOS_REQUISITOS_FALLIDO,
        payload: error
      });
    });
};

export const obtenerPermisosRequisitosTimeline = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/requisitos/timeline`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PERMISOS_REQUISITOS_TIMELINE_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PERMISOS_REQUISITOS_TIMELINE_FALLIDO,
        payload: error
      });
    });
};

export const obtenerCsvInteligencia = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/inteligencia-csv`, data, tokenArchivoDescargaConfig(getState))
    .then(res => {
      dispatch({ type: CARGANDO_OK });
      const content = res.headers['content-type'];
      download(res.data, 'permisos.xlsx', content)
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : INTELIGENCIA_CSV_FALLIDO,
        payload: error
      });
    });
};

export const obtenerCsvProyecto = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/proyecto-csv`, data, tokenArchivoDescargaConfig(getState))
    .then(res => {
      dispatch({ type: CARGANDO_OK });
      const content = res.headers['content-type'];
      download(res.data, 'permisos.xlsx', content)
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PROYECTO_CSV_FALLIDO,
        payload: error
      });
    });
};

export const obtenerPermisos = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/permisos`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PERMISOS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PERMISOS_FALLIDO,
        payload: error
      });
    });
};

export const obtenerPermisosInteligencia = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/permisos-inteligencia`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PERMISOS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PERMISOS_FALLIDO,
        payload: error
      });
    });
};

export const obtenerPermisosFiltros = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .put(`${url_api}/api/permitting/permisos`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PERMISOS_FILTROS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PERMISOS_FILTROS_FALLIDO,
        payload: error
      });
    });
};

export const obtenerPermisosFiltrosTodos = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .put(`${url_api}/api/permitting/permisos-inteligencia`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PERMISOS_FILTROS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PERMISOS_FILTROS_FALLIDO,
        payload: error
      });
    });
};

export const obtenerPermiso = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/permiso`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PERMISO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PERMISO_FALLIDO,
        payload: error
      });
    });
};

export const PermisoOmitir = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/permiso-omitido`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PERMISO_OMITIR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PERMISO_OMITIR_FALLIDO,
        payload: error
      });
    });
};

export const PermisoIncluido = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .put(`${url_api}/api/permitting/permiso-omitido`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PERMISO_INCLUIDO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PERMISO_INCLUIDO_FALLIDO,
        payload: error
      });
    });
};

export const obtenerPermisoInteligencia = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/permiso-inteligencia`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PERMISO_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PERMISO_FALLIDO,
        payload: error
      });
    });
};

export const PermisoSeleccionado = data => (dispatch) => {
  dispatch({
    type: PERMISO_SELECCIONADO,
    payload: data,
  });
};

export const obtenerDashboard = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/dashboard`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: DASHBOARD_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : DASHBOARD_FALLIDO,
        payload: error
      });
    });
};

export const obtenerSecciones = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/secciones`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: SECCIONES_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : SECCIONES_FALLIDO,
        payload: error
      });
    });
};

export const seccionSeleccionada = seccion => (dispatch) => {
  dispatch({
    type: SECCION_SELECCIONADA,
    payload: seccion,
  });
};

export const categoriaSeleccionada = categoria => (dispatch) => {
  dispatch({
    type: CATEGORIA_SELECCIONADA,
    payload: categoria,
  });
};

export const categoriaSeccionSeleccionada = pregunta => (dispatch) => {
  dispatch({
    type: CATEGORIA_SECCION_SELECCIONADA,
    payload: pregunta,
  });
};

export const obtenerCategorias = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/categorias`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: CATEGORIAS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : CATEGORIAS_FALLIDO,
        payload: error
      });
    });
};

export const obtenerPreguntas = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/preguntas`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PREGUNTAS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PREGUNTAS_FALLIDO,
        payload: error
      });
    });
};

export const obtenerProyectos = () => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .get(`${url_api}/api/permitting/proyectos`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PROYECTOS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PROYECTOS_FALLIDO,
        payload: error
      });
    });
};

export const obtenerInteligencias = () => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .get(`${url_api}/api/permitting/inteligencias`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: INTELIGENCIAS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : INTELIGENCIAS_FALLIDO,
        payload: error
      });
    });
};

export const crearConsulta = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/consulta/crear`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: CONSULTA_CREAR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : CONSULTA_CREAR_FALLIDO,
        payload: error
      });
    });
};

export const eliminarConsulta = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/consulta/eliminar`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: CONSULTA_ELIMINAR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : CONSULTA_ELIMINAR_FALLIDO,
        payload: error
      });
    });
};

export const duplicarConsulta = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/consulta/duplicar`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: CONSULTA_DUPLICAR_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : CONSULTA_DUPLICAR_FALLIDO,
        payload: error
      });
    });
};

export const consultaSeleccionada = data => (dispatch) => {
  dispatch({
    type: CONSULTA_SELECCIONADA,
    payload: data,
  });
};

export const inteligenciaSeleccionada = data => (dispatch) => {
  dispatch({
    type: INTELIGENCIA_SELECCIONADA,
    payload: data,
  });
};

export const crearRespuestas = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/respuestas`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: RESPUESTAS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : RESPUESTAS_FALLIDO,
        payload: error
      });
    });
};

export const generarEntregable = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/generar-entregables`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GENERAR_ENTREGABLE_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : GENERAR_ENTREGABLE_FALLIDO,
        payload: error
      });
    });
};

export const obtenerArchivoPregunta = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/archivo-pregunta`, data, tokenArchivoDescargaConfig(getState))
    .then(res => {
      dispatch({ type: CARGANDO_OK });
      const content = res.headers['content-type'];
      download(res.data, res.file_name, content)
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ARCHIVO_PREGUNTA_FALLIDO,
        payload: error
      });
    });
};

export const obtenerArchivoPermiso = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/archivo-permiso`, data, tokenArchivoDescargaConfig(getState))
    .then(res => {
      dispatch({ type: CARGANDO_OK });
      const content = res.headers['content-type'];
      download(res.data, res.file_name, content)
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ARCHIVO_PERMISO_FALLIDO,
        payload: error
      });
    });
};

export const obtenerArchivoPermisoInteligencia = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
  axios
    .post(`${url_api}/api/permitting/archivo-permiso-inteligencia`, data, tokenArchivoDescargaConfig(getState))
    .then(res => {
      dispatch({ type: CARGANDO_OK });
      const content = res.headers['content-type'];
      download(res.data, res.file_name, content)
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : ARCHIVO_PERMISO_FALLIDO,
        payload: error
      });
    });
};

export const limpiarGenerarEntregable = () => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_PERMITTING });
};


export const setPermisoTarea = permiso => (dispatch) => {
  dispatch({
    type: PERMISO_TAREA_SELECCIONADO,
    payload: permiso,
  });
};
