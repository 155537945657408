import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";

import { guardarInstrumentoInfringido } from "../../../redux/actions/vigilancias/instrumentos_infringidos/action";
import { Eliminar as EliminarFavorito } from "../favoritos";
import { TablaPequena as Hechos } from "../hechos";
import { TablaPequena as Titulares } from "../titulares";
import { TablaPequena as UnidadesFiscalizables } from "../unidades";

export class Tabla extends Component {
  setInstrumentoInfringido = (id) => {
    const { guardarInstrumentoInfringido } = this.props;
    guardarInstrumentoInfringido(id);
  };

  render = () => {
    const { instrumentos_infringidos, eliminar_favorito } = this.props;

    return (
      <div className="table-responsive">
        <Tooltip id="link_instrumento" aria-haspopup="true">
          Ir al enlace del instrumento infringido
        </Tooltip>
        <Tooltip id="detalle_instrumento" aria-haspopup="true">
          Ver detalle del instrumento infringido
        </Tooltip>
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th className="w-10">Instrumento</th>
              <th>Hecho Infraccional</th>
              <th className="w-20">Titular</th>
              <th className="w-20">Unidad Fiscalizable</th>
              <th className="text-center w-5">Acción</th>
            </tr>
          </thead>
          <tbody>
            {instrumentos_infringidos &&
            instrumentos_infringidos.result &&
            instrumentos_infringidos.result.length > 0 ? (
              instrumentos_infringidos.result.map((inst, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {instrumentos_infringidos.page > 1
                      ? (instrumentos_infringidos.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td>{inst.nombre}</td>
                  <td>
                    <Hechos hechos={[inst.hecho]} />
                  </td>
                  <td>
                    <Titulares titulares={inst.hecho.procedimiento.titulares} />
                  </td>
                  <td>
                    <UnidadesFiscalizables
                      unidades_fiscalizables={
                        inst.hecho.procedimiento.unidad_fiscalizable
                      }
                    />
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      <a
                        data-tip
                        data-tooltip-id="link_instrumento"
                        target="_blank"
                        rel="noreferrer"
                        href={inst.url}
                        className="btn btn-dark"
                      >
                        <i className="fa fa-link"></i>
                      </a>
                      {eliminar_favorito && (
                        <EliminarFavorito tipo="inst" id={inst.id} />
                      )}
                      <Link
                        data-tip
                        data-tooltip-id="detalle_instrumento"
                        to="/compliance-analytics/instrumento-infringido"
                        className="btn btn-soft-dark"
                        onClick={this.setInstrumentoInfringido.bind(
                          this,
                          inst.id
                        )}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="7">
                  No existen hechos infraccionales
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarInstrumentoInfringido: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarInstrumentoInfringido }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
