import React, { Component } from "react";

import { Tabs as Fiscalizaciones } from "../fiscalizaciones";
import { Tabs as MedidasProvisional } from "../medidas";
import { Tabs as Procedimientos } from "../procedimientos";
import { Tabs as Requerimientos } from "../requerimientos";

export class Tabs extends Component {
  render = () => {
    const { documentos } = this.props;

    return documentos && documentos.length > 0 ? (
      documentos.map((documento, x) => (
        <div key={x}>
          <ul className="nav nav-pills nav-justified">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="documento-general-tab"
                data-bs-toggle="pill"
                data-bs-target="#documento-general"
                type="button"
                role="tab"
                aria-controls="documento-general"
                aria-selected="true"
              >
                Información del Documento
              </button>
            </li>
            {documento.procedimiento && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="documento-procedimiento-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#documento-procedimiento"
                  type="button"
                  role="tab"
                  aria-controls="documento-procedimiento"
                  aria-selected="true"
                >
                  Procedimiento Sancionatorio
                </button>
              </li>
            )}
            {documento.fiscalizacion && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="documento-fiscalizacion-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#documento-fiscalizacion"
                  type="button"
                  role="tab"
                  aria-controls="documento-fiscalizacion"
                  aria-selected="true"
                >
                  Fiscalización
                </button>
              </li>
            )}
            {documento.medida_provisional && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="documento-medida_provisional-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#documento-medida_provisional"
                  type="button"
                  role="tab"
                  aria-controls="documento-medida_provisional"
                  aria-selected="true"
                >
                  Medida Provisional
                </button>
              </li>
            )}
            {documento.requerimiento_ingreso && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="documento-requerimiento_ingreso-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#documento-requerimiento_ingreso"
                  type="button"
                  role="tab"
                  aria-controls="documento-requerimiento_ingreso"
                  aria-selected="true"
                >
                  Requerimiento de Ingreso
                </button>
              </li>
            )}
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show mt-3"
              id="documento-general"
              role="tabpanel"
              aria-labelledby="documento-general-tab"
            >
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="table-secondary w-20 text-dark">Nombre</td>
                      <td>{documento.nombre}</td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">Tipo</td>
                      <td>{documento.tipo}</td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">Enlace</td>
                      <td>
                        <a
                          data-tip
                          data-tooltip-id="link_documento"
                          target="_blank"
                          rel="noreferrer"
                          href={documento.url_doc}
                          className="btn btn-primary btn-xs"
                        >
                          Ver documento <i className="fa fa-angle-right"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {documento.procedimiento && (
              <div
                className="tab-pane fade mt-3"
                id="documento-procedimiento"
                role="tabpanel"
                aria-labelledby="documento-procedimiento-tab"
              >
                <Procedimientos procedimientos={[documento.procedimiento]} />
              </div>
            )}
            {documento.fiscalizacion && (
              <div
                className="tab-pane fade mt-3"
                id="documento-fiscalizacion"
                role="tabpanel"
                aria-labelledby="documento-fiscalizacion-tab"
              >
                <Fiscalizaciones fiscalizaciones={[documento.fiscalizacion]} />
              </div>
            )}
            {documento.medida_provisional && (
              <div
                className="tab-pane fade mt-3"
                id="documento-medida_provisional"
                role="tabpanel"
                aria-labelledby="documento-medida_provisional-tab"
              >
                <MedidasProvisional
                  medidas_provisionales={[documento.medida_provisional]}
                />
              </div>
            )}
            {documento.requerimiento_ingreso && (
              <div
                className="tab-pane fade mt-3"
                id="documento-requerimiento_ingreso"
                role="tabpanel"
                aria-labelledby="documento-requerimiento_ingreso-tab"
              >
                <Requerimientos
                  requerimientos={[documento.requerimiento_ingreso]}
                />
              </div>
            )}
          </div>
        </div>
      ))
    ) : (
      <h6>No existen documento</h6>
    );
  };
}

export default Tabs;
