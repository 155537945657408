import React, { Component } from "react";

import InputMask from "@mona-health/react-input-mask";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  editarTarea,
  limpiarMensajes,
} from "../../../redux/actions/tareas/action";
import { obtenerProyectos } from "../../../redux/actions/tareas/proyectos/action";

export class Editar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tarea_nombre:
        this.props.tarea && this.props.tarea.nombre
          ? this.props.tarea.nombre
          : "",
      tarea_descripcion:
        this.props.tarea && this.props.tarea.descripcion
          ? this.props.tarea.descripcion
          : "",
      tarea_link:
        this.props.tarea && this.props.tarea.link ? this.props.tarea.link : "",
      tarea_categoria:
        this.props.tarea && this.props.tarea.categoria
          ? this.props.tarea.categoria.id
          : "",
      tarea_categoria_nueva: "",
      tarea_padre:
        this.props.tarea && this.props.tarea.padre
          ? this.props.tarea.padre.id
          : "",
      tarea_fecha_entrega:
        this.props.tarea && this.props.tarea.fecha_entrega
          ? this.props.tarea.fecha_entrega
          : "",
      tarea_proyecto: this.props.proyecto ? this.props.proyecto.id : "",
      tarea_nueva_categoria_mostrar: false,
    };
  }

  mostrarNuevaCategoria = () => {
    this.setState({
      tarea_nueva_categoria_mostrar: true,
      tarea_categoria: "",
    });
  };

  cancelarNuevaCategoria = () => {
    this.setState({
      tarea_nueva_categoria_mostrar: false,
      tarea_categoria_nueva: "",
    });
  };

  obtenerProyectos = () => {
    const { obtenerProyectos } = this.props;
    obtenerProyectos();
  };

  cancelarNuevaTarea = () => {
    const { cancelarNuevaTarea, btnCancelar } = this.props;
    if (btnCancelar) {
      cancelarNuevaTarea();
    }
  };

  redirectVista = (url) => {
    const { redirectVista, btnCancelar } = this.props;
    if (!btnCancelar) {
      redirectVista(url);
    }
  };

  edicionTarea = () => {
    const {
      tarea_nombre,
      tarea_categoria,
      tarea_padre,
      tarea_fecha_entrega,
      tarea_descripcion,
      tarea_categoria_nueva,
      tarea_proyecto,
      tarea_link,
    } = this.state;
    const { editarTarea, tarea } = this.props;
    editarTarea({
      nombre: tarea_nombre,
      link: tarea_link,
      categoria: tarea_categoria ? tarea_categoria : null,
      padre: tarea_padre ? tarea_padre : "",
      fecha_entrega: tarea_fecha_entrega ? tarea_fecha_entrega : null,
      categoria_nueva: tarea_categoria_nueva ? tarea_categoria_nueva : null,
      descripcion: tarea_descripcion,
      proyecto: tarea_proyecto,
      tarea: tarea.id,
    });
    this.setState({
      usuario_loading: SweetAlert.fire({
        title: "Edición de Tarea",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos validando la información, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      }),
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "tarea_proyecto") {
      this.setState({
        tarea_categoria: "",
        tarea_padre: "",
      });
      this.cancelarNuevaCategoria();
    }
  };

  render = () => {
    const {
      tarea_nombre,
      tarea_categoria,
      tarea_padre,
      tarea_descripcion,
      tarea_fecha_entrega,
      tarea_categoria_nueva,
      tarea_nueva_categoria_mostrar,
      tarea_proyecto,
      tarea_link,
    } = this.state;
    const {
      proyectos,
      tarea_editar_error,
      tarea_editar,
      btnCancelar,
      listado_tareas,
    } = this.props;

    var listado_categorias = [];
    if (proyectos && proyectos.length > 0) {
      var proyecto = proyectos.find(
        (p) => p.id === parseInt(tarea_proyecto, 10)
      );
      if (proyecto) {
        proyecto.tareas.forEach(function (tr) {
          if (_.findIndex(listado_categorias, { id: tr.categoria.id }) === -1) {
            listado_categorias.push(tr.categoria);
          }
        });
      }
    }

    if (
      Object.entries(tarea_editar_error).length > 0 &&
      tarea_editar_error.data
    ) {
      SweetAlert.close();
      if (tarea_editar_error.data.message) {
        SweetAlert.fire({
          title: "Tarea no editada",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: tarea_editar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    if (Object.entries(tarea_editar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Tarea editada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: tarea_editar.message,
        icon: "success",
        confirmButtonText: `${
          btnCancelar
            ? "Cerrar"
            : "Ir al listado de tareas en el QUALITY TASKING"
        } <i class="fa fa-angle-right"></i>`,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.obtenerProyectos();
          this.cancelarNuevaTarea();
          this.redirectVista("/quality-tasking/proyectos");
          limpiarMensajes();
        }
      });
    }

    return (
      <div className="col-lg-12 mb-4">
        <div className="card">
          <div className="card-header bg-primary text-white">
            <h5>EDITAR TAREA</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 text-end">
                <p className="text-danger">
                  <i className="fa fa-asterisk"></i> datos obligatorios
                </p>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Nombre <small className="text-danger">*</small>
                  </label>
                  <input
                    type="text"
                    name="tarea_nombre"
                    onChange={this.onChange}
                    value={tarea_nombre}
                    placeholder="Ingrese un nombre"
                    className={
                      tarea_editar_error &&
                      tarea_editar_error.data &&
                      tarea_editar_error.data.nombre
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {tarea_editar_error &&
                    tarea_editar_error.data &&
                    tarea_editar_error.data.nombre &&
                    tarea_editar_error.data.nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
                <div className="form-group">
                  <label>
                    Categoría <small className="text-danger">*</small>
                  </label>
                  {listado_categorias.length > 0 &&
                  !tarea_nueva_categoria_mostrar ? (
                    <p>
                      Seleccione una categoría o{" "}
                      <button
                        className="btn btn-xs btn-secondary pb-0 pt-0"
                        type="button"
                        onClick={this.mostrarNuevaCategoria}
                      >
                        Cree una nueva aquí{" "}
                        <i className="fa fa-plus-circle"></i>
                      </button>
                    </p>
                  ) : (
                    <p>
                      <button
                        className="btn btn-xs btn-danger pb-0 pt-0"
                        type="button"
                        onClick={this.cancelarNuevaCategoria}
                      >
                        Cancelar nueva categoría
                      </button>
                    </p>
                  )}
                  {listado_categorias.length > 0 &&
                    !tarea_nueva_categoria_mostrar && (
                      <>
                        <select
                          name="tarea_categoria"
                          onChange={this.onChange}
                          value={tarea_categoria}
                          className={
                            tarea_editar_error &&
                            tarea_editar_error.data &&
                            tarea_editar_error.data.categoria
                              ? "form-select is-invalid"
                              : "form-select"
                          }
                        >
                          <option value="">Seleccione una opción</option>
                          {listado_categorias.map((tr, x) => (
                            <option value={tr.id} key={x}>
                              {tr.nombre}
                            </option>
                          ))}
                        </select>
                        {tarea_editar_error &&
                          tarea_editar_error.data &&
                          tarea_editar_error.data.categoria &&
                          tarea_editar_error.data.categoria.map((err, x) => (
                            <div key={x} className="invalid-feedback">
                              {err}
                            </div>
                          ))}
                      </>
                    )}
                  {tarea_nueva_categoria_mostrar && (
                    <>
                      <input
                        type="text"
                        name="tarea_categoria_nueva"
                        onChange={this.onChange}
                        value={tarea_categoria_nueva}
                        placeholder="Ingrese una nueva categoria"
                        className={
                          tarea_editar_error &&
                          tarea_editar_error.data &&
                          tarea_editar_error.data.categoria_nueva
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {tarea_editar_error &&
                        tarea_editar_error.data &&
                        tarea_editar_error.data.categoria_nueva &&
                        tarea_editar_error.data.categoria_nueva.map(
                          (err, x) => (
                            <div key={x} className="invalid-feedback">
                              {err}
                            </div>
                          )
                        )}
                    </>
                  )}
                </div>
                <div className="form-group">
                  <label>Tarea dependiente</label>
                  {listado_tareas && listado_tareas.length > 0 && (
                    <select
                      name="tarea_padre"
                      onChange={this.onChange}
                      value={tarea_padre}
                      className={
                        tarea_editar_error &&
                        tarea_editar_error.data &&
                        tarea_editar_error.data.padre
                          ? "form-select is-invalid"
                          : "form-select"
                      }
                    >
                      <option value="">Seleccione una opción</option>
                      {listado_tareas.map((tr, x) => (
                        <option value={tr.id} key={x}>
                          {tr.nombre}
                        </option>
                      ))}
                    </select>
                  )}
                  {tarea_editar_error &&
                    tarea_editar_error.data &&
                    tarea_editar_error.data.padre &&
                    tarea_editar_error.data.padre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
                <div className="form-group">
                  <label>Proyecto</label>
                  <select
                    name="tarea_proyecto"
                    onChange={this.onChange}
                    value={tarea_proyecto}
                    className={
                      tarea_editar_error &&
                      tarea_editar_error.data &&
                      tarea_editar_error.data.proyecto
                        ? "form-select is-invalid"
                        : "form-select"
                    }
                  >
                    <option value="">Seleccione una opción</option>
                    {proyectos.map((proyecto, x) => (
                      <option value={proyecto.id} key={x}>
                        {proyecto.nombre}
                      </option>
                    ))}
                  </select>
                  {tarea_editar_error &&
                    tarea_editar_error.data &&
                    tarea_editar_error.data.proyecto &&
                    tarea_editar_error.data.proyecto.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Link</label>
                  <input
                    type="text"
                    name="tarea_link"
                    onChange={this.onChange}
                    value={tarea_link}
                    placeholder="Ingrese un link"
                    className={
                      tarea_editar_error &&
                      tarea_editar_error.data &&
                      tarea_editar_error.data.link
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {tarea_editar_error &&
                    tarea_editar_error.data &&
                    tarea_editar_error.data.link &&
                    tarea_editar_error.data.link.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
                <div className="form-group">
                  <label>
                    Fecha de entrega <small className="text-danger">*</small>
                  </label>
                  <InputMask
                    name="tarea_fecha_entrega"
                    value={tarea_fecha_entrega}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha de entrega"
                    className={
                      tarea_editar_error &&
                      tarea_editar_error.data &&
                      tarea_editar_error.data.fecha_entrega
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {tarea_editar_error &&
                    tarea_editar_error.data &&
                    tarea_editar_error.data.fecha_entrega &&
                    tarea_editar_error.data.fecha_entrega.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
                <div className="form-group">
                  <label>Descripción</label>
                  <textarea
                    rows="5"
                    name="tarea_descripcion"
                    onChange={this.onChange}
                    value={tarea_descripcion}
                    placeholder="Ingrese una descripción"
                    className={
                      tarea_editar_error &&
                      tarea_editar_error.data &&
                      tarea_editar_error.data.descripcion
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  ></textarea>
                  {tarea_editar_error &&
                    tarea_editar_error.data &&
                    tarea_editar_error.data.descripcion &&
                    tarea_editar_error.data.descripcion.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-sm-12 text-end">
                <div className="btn-group">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={this.edicionTarea}
                  >
                    Editar tarea <i className="fa fa-save"></i>
                  </button>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={this.cancelarNuevaTarea}
                  >
                    Cancelar <i className="fa fa-times-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

Editar.propTypes = {
  editarTarea: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  obtenerProyectos: PropTypes.func.isRequired,
  tarea_editar: PropTypes.object.isRequired,
  tarea_editar_error: PropTypes.object.isRequired,
  proyectos: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  proyectos: state.tareas.proyectos,
  tarea_editar: state.tareas.tarea_editar,
  tarea_editar_error: state.tareas.tarea_editar_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { editarTarea, limpiarMensajes, obtenerProyectos },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Editar);
