import InputMask from "@mona-health/react-input-mask";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import {
  limpiarFiltroSeguimientos,
  obtenerSeguimientos,
  obtenerSeguimientosFiltros,
} from "../../../redux/actions/vigilancias/seguimientos/action";

export class Filtros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre:
        this.props.seguimientos_filtro_input &&
        this.props.seguimientos_filtro_input.nombre
          ? this.props.seguimientos_filtro_input.nombre
          : "",
      filtro_fecha_informe_inicio:
        this.props.seguimientos_filtro_input &&
        this.props.seguimientos_filtro_input.fecha_informe_inicio
          ? this.props.seguimientos_filtro_input.fecha_informe_inicio
          : "",
      filtro_fecha_informe_termino:
        this.props.seguimientos_filtro_input &&
        this.props.seguimientos_filtro_input.fecha_informe_termino
          ? this.props.seguimientos_filtro_input.fecha_informe_termino
          : "",
      filtro_norma:
        this.props.seguimientos_filtro_input &&
        this.props.seguimientos_filtro_input.norma
          ? this.props.seguimientos_filtro_input.norma
          : "",
      filtro_frecuencia:
        this.props.seguimientos_filtro_input &&
        this.props.seguimientos_filtro_input.frecuencia
          ? this.props.seguimientos_filtro_input.frecuencia
          : "",
      filtro_unidad_fiscalizable_categoria:
        this.props.seguimientos_filtro_input &&
        this.props.seguimientos_filtro_input.unidad_fiscalizable_categoria
          ? this.props.seguimientos_filtro_input.unidad_fiscalizable_categoria
          : "",
      filtro_unidad_fiscalizable_region:
        this.props.seguimientos_filtro_input &&
        this.props.seguimientos_filtro_input.unidad_fiscalizable_region
          ? this.props.seguimientos_filtro_input.unidad_fiscalizable_region
          : "",
      filtro_subcomponente:
        this.props.seguimientos_filtro_input &&
        this.props.seguimientos_filtro_input.subcomponente
          ? this.props.seguimientos_filtro_input.subcomponente
          : "",
      filtros_avanzandos:
        this.props.seguimientos_filtro_input &&
        this.props.seguimientos_filtro_input.filtros_avanzandos
          ? this.props.seguimientos_filtro_input.filtros_avanzandos
          : false,
    };
  }

  filtrosSeguimientos = () => {
    const { obtenerSeguimientosFiltros, obtenerSeguimientos } = this.props;
    const {
      filtro_nombre,
      filtro_fecha_informe_inicio,
      filtro_fecha_informe_termino,
      filtro_norma,
      filtro_frecuencia,
      filtro_unidad_fiscalizable_categoria,
      filtro_unidad_fiscalizable_region,
      filtro_subcomponente,
      filtros_avanzandos,
    } = this.state;

    if (
      filtro_nombre ||
      filtro_fecha_informe_inicio ||
      filtro_fecha_informe_termino ||
      filtro_norma ||
      filtro_frecuencia ||
      filtro_unidad_fiscalizable_categoria ||
      filtro_unidad_fiscalizable_region ||
      filtro_subcomponente
    ) {
      var filtros = {
        nombre: filtro_nombre ? filtro_nombre : null,
        fecha_informe_inicio: filtro_fecha_informe_inicio
          ? filtro_fecha_informe_inicio
          : null,
        fecha_informe_termino: filtro_fecha_informe_termino
          ? filtro_fecha_informe_termino
          : null,
        norma: filtro_norma ? filtro_norma : null,
        frecuencia: filtro_frecuencia ? filtro_frecuencia : null,
        unidad_fiscalizable_categoria: filtro_unidad_fiscalizable_categoria
          ? filtro_unidad_fiscalizable_categoria
          : null,
        unidad_fiscalizable_region: filtro_unidad_fiscalizable_region
          ? filtro_unidad_fiscalizable_region
          : null,
        subcomponente: filtro_subcomponente ? filtro_subcomponente : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerSeguimientosFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerSeguimientos(1);
    }
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  borrarFiltros = () => {
    const { obtenerSeguimientos, limpiarFiltroSeguimientos } = this.props;
    this.setState({
      filtro_nombre: "",
      filtro_fecha_informe_inicio: "",
      filtro_fecha_informe_termino: "",
      filtro_norma: "",
      filtro_frecuencia: "",
      filtro_unidad_fiscalizable_categoria: "",
      filtro_unidad_fiscalizable_region: "",
      filtro_subcomponente: "",
    });
    limpiarFiltroSeguimientos();
    obtenerSeguimientos(1);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render = () => {
    const { filtros_errores, filtros_seguimientos } = this.props;
    const {
      filtro_nombre,
      filtro_fecha_informe_inicio,
      filtro_fecha_informe_termino,
      filtro_norma,
      filtro_frecuencia,
      filtro_unidad_fiscalizable_categoria,
      filtro_unidad_fiscalizable_region,
      filtro_subcomponente,
      filtros_avanzandos,
    } = this.state;
    const normas_listado =
      filtros_seguimientos && filtros_seguimientos["normas"]
        ? _.orderBy(filtros_seguimientos["normas"], "nombre")
        : [];
    const frecuencias_listado =
      filtros_seguimientos && filtros_seguimientos["frecuencias"]
        ? _.orderBy(filtros_seguimientos["frecuencias"], "nombre")
        : [];
    const categorias_listado =
      filtros_seguimientos && filtros_seguimientos["categorias"]
        ? _.orderBy(filtros_seguimientos["categorias"], "nombre")
        : [];
    const regiones_listado =
      filtros_seguimientos && filtros_seguimientos["regiones"]
        ? _.orderBy(filtros_seguimientos["regiones"], "nombre")
        : [];
    const subcomponentes_listado =
      filtros_seguimientos && filtros_seguimientos["subcomponentes"]
        ? _.orderBy(filtros_seguimientos["subcomponentes"], "nombre")
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Nombre del seguimiento</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_nombre"
                    value={filtro_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese palabra clave"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre &&
                    filtros_errores.data.nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Subcomponente Ambiental</label>
                <SelectPicker
                  name="filtro_subcomponente"
                  data={subcomponentes_listado}
                  onChange={(value) => {
                    this.setState({ filtro_subcomponente: value });
                  }}
                  value={filtro_subcomponente}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.subcomponente &&
                  filtros_errores.data.subcomponente.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Frecuencia</label>
                <SelectPicker
                  name="filtro_frecuencia"
                  data={frecuencias_listado}
                  onChange={(value) => {
                    this.setState({ filtro_frecuencia: value });
                  }}
                  value={filtro_frecuencia}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.frecuencia &&
                  filtros_errores.data.frecuencia.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Norma</label>
                <SelectPicker
                  name="filtro_norma"
                  data={normas_listado}
                  onChange={(value) => {
                    this.setState({ filtro_norma: value });
                  }}
                  value={filtro_norma}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.norma &&
                  filtros_errores.data.norma.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de informe (inicio)</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha_informe_inicio"
                    value={filtro_fecha_informe_inicio}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_informe_inicio
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_informe_inicio &&
                    filtros_errores.data.fecha_informe_inicio.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de informe (término)</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha_informe_termino"
                    value={filtro_fecha_informe_termino}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_informe_termino
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_informe_termino &&
                    filtros_errores.data.fecha_informe_termino.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Categoría</label>
                <SelectPicker
                  name="filtro_unidad_fiscalizable_categoria"
                  data={categorias_listado}
                  onChange={(value) => {
                    this.setState({
                      filtro_unidad_fiscalizable_categoria: value,
                    });
                  }}
                  value={filtro_unidad_fiscalizable_categoria}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.unidad_fiscalizable_categoria &&
                  filtros_errores.data.unidad_fiscalizable_categoria.map(
                    (err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    )
                  )}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Región</label>
                <SelectPicker
                  name="filtro_unidad_fiscalizable_region"
                  data={regiones_listado}
                  onChange={(value) => {
                    this.setState({
                      filtro_unidad_fiscalizable_region: value,
                    });
                  }}
                  value={filtro_unidad_fiscalizable_region}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.unidad_fiscalizable_region &&
                  filtros_errores.data.unidad_fiscalizable_region.map(
                    (err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtrosSeguimientos}
            >
              <i className="fa fa-search"></i> Filtrar seguimientos ambientales
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Filtros.propTypes = {
  obtenerSeguimientos: PropTypes.func.isRequired,
  obtenerSeguimientosFiltros: PropTypes.func.isRequired,
  limpiarFiltroSeguimientos: PropTypes.func.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  filtros_seguimientos: PropTypes.object.isRequired,
  seguimientos_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_errores: state.vigilancias.filtros_errores,
  filtros_seguimientos: state.vigilancias.filtros_seguimientos,
  seguimientos_filtro_input: state.vigilancias.seguimientos_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerSeguimientos,
      obtenerSeguimientosFiltros,
      limpiarFiltroSeguimientos,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
