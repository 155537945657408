import React, { Component } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerProyectos } from "../../redux/actions/tareas/proyectos/action";
import { Footer, Navbar, SidebarInicio } from "../general";

export class Dashboard extends Component {
  componentDidMount = () => {
    const { obtenerProyectos } = this.props;
    obtenerProyectos();
  };

  render = () => {
    const { proyectos, is_loading_tareas } = this.props;

    if (is_loading_tareas) {
      SweetAlert.fire({
        title: "Dashboard",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo la información, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }
    var n_tareas = 0;
    var n_entregables = 0;

    _.forEach(proyectos, function (proyecto) {
      n_tareas += proyecto.tareas.length;
      _.forEach(proyecto.tareas, function (tarea) {
        n_entregables += tarea.n_entregables;
      });
    });

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="tareas_dashboard" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tachometer-alt"></i> DASHBOARD
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-6 col-md-4 col-lg-4">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">Número de Proyectos</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-dark">
                            {proyectos ? proyectos.length : 0}
                          </h2>
                          <i className="fa fa-list-ol card-eco-icon bg-info-gradient align-self-center"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-4">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">Número de Tareas</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-dark">{n_tareas}</h2>
                          <i className="fa fa-tasks card-eco-icon bg-info-gradient align-self-center"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-4">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">Número de Entregables</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-dark">{n_entregables}</h2>
                          <i className="fa fa-file-archive card-eco-icon bg-info-gradient align-self-center"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="text-uppercase">Listado de Tareas</h5>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover">
                            <thead className="table-secondary">
                              <tr>
                                <th className="text-center">ID</th>
                                <th>Nombre del Proyecto</th>
                                <th>Nombre de la Tarea</th>
                                <th>Originador de la Tarea</th>
                                <th>Responsable de la Tarea</th>
                                <th>Estado de la Tarea</th>
                                <th>Equipo</th>
                                {/*
                                <th>
                                  Tarea <i className="fa fa-angle-right"></i> Tiempo <i data-tip data-tooltip-id='dias_restantes' className="fa fa-exclamation-circle text-warning"></i>
                                  <Tooltip id='dias_restantes' aria-haspopup='true'>Días restantes para la fecha de término</Tooltip>
                                </th>
                                <th>Tarea <i className="fa fa-angle-right"></i> Avance</th>
                                */}
                              </tr>
                            </thead>
                            <tbody>
                              {proyectos &&
                                proyectos.length > 0 &&
                                proyectos.map((proyecto, x) =>
                                  proyecto.tareas.map((tarea, y) => (
                                    <tr key={`${x}_${y}`}>
                                      <td className="text-center">
                                        {x + 1}.{y + 1}
                                      </td>
                                      <td>{proyecto.nombre}</td>
                                      <td>{tarea.nombre}</td>
                                      <td>{tarea.usuario_text}</td>
                                      <td>{tarea.responsable}</td>
                                      <td>{tarea.estado_text}</td>
                                      <td>
                                        <ul className="mb-0">
                                          {tarea.equipo.map((user, z) => (
                                            <li key={z}>
                                              {user.usuario_text}:{" "}
                                              {user.rol_text}
                                            </li>
                                          ))}
                                        </ul>
                                      </td>
                                      {/*
                                    <td>{tarea.n_dias}</td>
                                    <td>{tarea.porcentaje_avance ? tarea.porcentaje_avance : 0} <i className="fa fa-percentage"></i></td>
                                    */}
                                    </tr>
                                  ))
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Dashboard.propTypes = {
  obtenerProyectos: PropTypes.func.isRequired,
  proyectos: PropTypes.array.isRequired,
  is_loading_tareas: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  proyectos: state.tareas.proyectos,
  is_loading_tareas: state.tareas.is_loading_tareas,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerProyectos,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
