import { combineReducers } from "redux";

import auth from "./auth";
import leyes from "./leyes";
import mma from "./mma";
import permitting from "./permitting";
import tareas from "./tareas";
import teams from "./teams";
import vigilancias from "./vigilancias";
// import soporte from './soporte';

export default combineReducers({
  auth,
  leyes,
  permitting,
  tareas,
  vigilancias,
  mma,
  teams,
  // soporte,
});
