import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

export class SidebarLeft extends Component {
  render = () => {
    const { menu_1, siderbar_estado } = this.props;
    return (
      <div className={`left-sidenav ${siderbar_estado ? "d-none" : ""}`}>
        <ul className="left-sidenav-menu">
          <li className="mm-active proyectos">
            <Link to="/proyectos">
              <i className="fa fa-angle-left"></i> Volver al inicio
            </Link>
          </li>
          <hr />
          <li
            className={
              menu_1 && menu_1 === "base_conocimiento"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link className="nav-link" to="/soporte/base-conocimiento">
              <i className="fa fa-book"></i> Base de conocimiento
            </Link>
          </li>
          <li
            className={
              menu_1 && menu_1 === "tickets" ? "mm-active" : "mm-inactivo"
            }
          >
            <Link className="nav-link" to="/soporte/tickets">
              <i className="fa fa-list-ol"></i> Soporte
            </Link>
          </li>
        </ul>
      </div>
    );
  };
}

SidebarLeft.propTypes = {
  siderbar_estado: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  siderbar_estado: state.auth.siderbar_estado,
});

export default connect(mapStateToProps, null)(SidebarLeft);
