import React, { Component } from "react";

import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { Link } from "react-router-dom";

import pin from "../../images/pin.png";

function ChangeView({ center, zoom }) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

export class Maps extends Component {
  constructor() {
    super();
    this.state = {
      readyToRender: false,
      icon: L.icon({
        iconUrl: pin,
        iconRetinaUrl: pin,
        iconSize: new L.Point(32, 32),
      }),
    };
  }

  setExpediente = (id) => {
    this.props.setExpediente(id);
  };

  render = () => {
    const { center, zoom, markers, size } = this.props;
    const { icon } = this.state;

    setTimeout(() => {
      this.setState({ readyToRender: true });
    }, 200);

    return (
      <div>
        {this.state.readyToRender && (
          <MapContainer
            center={center}
            zoom={zoom}
            scrollWheelZoom={false}
            style={{ height: `${size}px` }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markers.map((marker, x) => (
              <Marker key={x} position={marker.position} icon={icon}>
                <Popup>
                  {marker.titulo && (
                    <p className="text-center mb-1">
                      <strong>
                        <u>{marker.titulo}</u>
                      </strong>
                    </p>
                  )}
                  {marker.nombre && (
                    <p className="m-0">
                      <strong>Nombre:</strong> {marker.nombre}
                    </p>
                  )}
                  {marker.estado && (
                    <p className="m-0">
                      <strong>Estado:</strong>{" "}
                      {marker.estado ? marker.estado : "S/I"}
                    </p>
                  )}
                  {marker.titular && (
                    <p className="m-0">
                      <strong>Titular:</strong>{" "}
                      {marker.titular ? marker.titular : "S/I"}
                    </p>
                  )}
                  {marker.url && marker.url_nombre && (
                    <p className="m-0 text-end">
                      <Link
                        onClick={this.setExpediente.bind(this, marker.id)}
                        to={marker.url}
                        className="btn btn-primary btn-xs text-white"
                      >
                        {marker.url_nombre}{" "}
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </p>
                  )}
                </Popup>
              </Marker>
            ))}
            <ChangeView center={center} zoom={zoom} />
          </MapContainer>
        )}
      </div>
    );
  };
}

export default Maps;
