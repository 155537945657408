import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  obtenerHechos,
  obtenerHechosFiltros,
} from "../../../redux/actions/vigilancias/hechos/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Filtros, Tabla } from "./";

export class Hechos extends Component {
  nuevaPagina = (pagina) => {
    const {
      obtenerHechos,
      obtenerHechosFiltros,
      hechos_filtros,
      hechos_filtro_input,
    } = this.props;
    if (Object.entries(hechos_filtros).length > 0) {
      obtenerHechosFiltros({ filtros: hechos_filtro_input, pagina: pagina });
    } else {
      obtenerHechos(pagina);
    }
  };

  componentDidMount = () => {
    const { obtenerHechos, hechos } = this.props;
    if (Object.entries(hechos).length === 0) {
      obtenerHechos(1);
    }
  };

  render = () => {
    const { hechos, hechos_filtros, is_loading_vigilancias } = this.props;

    var hechos_result = hechos;
    if (Object.entries(hechos_filtros).length > 0) {
      hechos_result = hechos_filtros;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Hechos Infraccionales",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los hechos infraccionales, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_hechos" menu_2="listado" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-university"></i> HECHOS INFRACCIONALES
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE HECHOS INFRACCIONALES{" "}
                      <small>
                        (
                        {hechos_result && hechos_result.total
                          ? hechos_result.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={hechos_result}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla hechos={hechos_result} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Hechos.propTypes = {
  obtenerHechos: PropTypes.func.isRequired,
  obtenerHechosFiltros: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  hechos: PropTypes.object.isRequired,
  hechos_filtros: PropTypes.object.isRequired,
  hechos_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  hechos: state.vigilancias.hechos,
  hechos_filtros: state.vigilancias.hechos_filtros,
  hechos_filtro_input: state.vigilancias.hechos_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerHechos, obtenerHechosFiltros }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Hechos);
