import React, { Component } from "react";

import InputMask from "@mona-health/react-input-mask";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CheckTreePicker } from "rsuite";
import { format as formatRut } from "rut.js";
import {
  limpiarFiltroProcedimiento,
  obtenerProcedimientos,
  obtenerProcedimientosFiltros,
} from "../../../redux/actions/vigilancias/procedimientos/action";

export class Procedimientos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_fecha_inicio:
        this.props.procedimientos_filtro_input &&
        this.props.procedimientos_filtro_input.fecha_inicio
          ? this.props.procedimientos_filtro_input.fecha_inicio
          : "",
      filtro_fecha_termino:
        this.props.procedimientos_filtro_input &&
        this.props.procedimientos_filtro_input.fecha_termino
          ? this.props.procedimientos_filtro_input.fecha_termino
          : "",
      filtro_estado:
        this.props.procedimientos_filtro_input &&
        this.props.procedimientos_filtro_input.estado
          ? this.props.procedimientos_filtro_input.estado
          : [],
      filtro_expediente:
        this.props.procedimientos_filtro_input &&
        this.props.procedimientos_filtro_input.expediente
          ? this.props.procedimientos_filtro_input.expediente
          : "",
      filtro_titular_nombre:
        this.props.procedimientos_filtro_input &&
        this.props.procedimientos_filtro_input.titular_nombre
          ? this.props.procedimientos_filtro_input.titular_nombre
          : "",
      filtro_titular_rut:
        this.props.procedimientos_filtro_input &&
        this.props.procedimientos_filtro_input.titular_rut
          ? this.props.procedimientos_filtro_input.titular_rut
          : "",
      filtros_avanzandos:
        this.props.procedimientos_filtro_input &&
        this.props.procedimientos_filtro_input.filtros_avanzandos
          ? this.props.procedimientos_filtro_input.filtros_avanzandos
          : false,
    };
  }

  filtroProcedimientos = () => {
    const { obtenerProcedimientosFiltros, obtenerProcedimientos } = this.props;
    const {
      filtro_fecha_inicio,
      filtro_fecha_termino,
      filtro_estado,
      filtro_expediente,
      filtro_titular_nombre,
      filtro_titular_rut,
      filtros_avanzandos,
    } = this.state;

    if (
      filtro_fecha_inicio ||
      filtro_fecha_termino ||
      filtro_estado ||
      filtro_expediente ||
      filtro_titular_nombre ||
      filtro_titular_rut
    ) {
      var filtros = {
        fecha_inicio: filtro_fecha_inicio ? filtro_fecha_inicio : null,
        fecha_termino: filtro_fecha_termino ? filtro_fecha_termino : null,
        estado: filtro_estado ? filtro_estado : null,
        expediente: filtro_expediente ? filtro_expediente : null,
        titular_nombre: filtro_titular_nombre ? filtro_titular_nombre : null,
        titular_rut: filtro_titular_rut ? filtro_titular_rut : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerProcedimientosFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerProcedimientos(1);
    }
  };

  borrarFiltros = () => {
    const { obtenerProcedimientos, limpiarFiltroProcedimiento } = this.props;
    this.setState({
      filtro_estado: [],
      filtro_fecha_inicio: "",
      filtro_fecha_termino: "",
      filtro_expediente: "",
      filtro_titular_nombre: "",
      filtro_titular_rut: "",
    });
    limpiarFiltroProcedimiento();
    obtenerProcedimientos(1);
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (e.target.name === "filtro_titular_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  render = () => {
    const { filtros_errores, filtros_procedimientos } = this.props;
    const {
      filtro_fecha_inicio,
      filtro_fecha_termino,
      filtro_estado,
      filtro_expediente,
      filtro_titular_nombre,
      filtro_titular_rut,
      filtros_avanzandos,
    } = this.state;
    const estados_listado =
      filtros_procedimientos && filtros_procedimientos["estados"]
        ? _.orderBy(filtros_procedimientos["estados"], "nombre")
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Expediente</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_expediente"
                    value={filtro_expediente}
                    onChange={this.onChange}
                    placeholder="Ingrese expediente"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.expediente
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.expediente &&
                    filtros_errores.data.expediente.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de inicio</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha_inicio"
                    value={filtro_fecha_inicio}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha de inicio"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_inicio
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_inicio &&
                    filtros_errores.data.fecha_inicio.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de término</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha_termino"
                    value={filtro_fecha_termino}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha de término"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_termino
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_termino &&
                    filtros_errores.data.fecha_termino.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Estado</label>
                <CheckTreePicker
                  name="filtro_estado"
                  data={estados_listado}
                  onChange={(value) => {
                    this.setState({ filtro_estado: value });
                  }}
                  value={filtro_estado}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />

                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.estado &&
                  filtros_errores.data.estado.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Nombre del titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_nombre"
                    value={filtro_titular_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese nombre del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_nombre &&
                    filtros_errores.data.titular_nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>RUT del titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_rut"
                    value={filtro_titular_rut}
                    onChange={this.onChange}
                    placeholder="Ingrese rut del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_rut
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_rut &&
                    filtros_errores.data.titular_rut.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroProcedimientos}
            >
              <i className="fa fa-search"></i> Filtrar procedimientos
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Procedimientos.propTypes = {
  obtenerProcedimientos: PropTypes.func.isRequired,
  obtenerProcedimientosFiltros: PropTypes.func.isRequired,
  limpiarFiltroProcedimiento: PropTypes.func.isRequired,
  filtros_procedimientos: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  procedimientos_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_procedimientos: state.vigilancias.filtros_procedimientos,
  filtros_errores: state.vigilancias.filtros_errores,
  procedimientos_filtro_input: state.vigilancias.procedimientos_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerProcedimientos,
      obtenerProcedimientosFiltros,
      limpiarFiltroProcedimiento,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Procedimientos);
