import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  obtenerRequerimientosIngresos,
  obtenerRequerimientosIngresosFiltros,
} from "../../../redux/actions/vigilancias/requerimientos/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Filtros, Tabla } from "./";

export class Listado extends Component {
  componentDidMount = () => {
    const { obtenerRequerimientosIngresos, requerimientos } = this.props;
    if (Object.entries(requerimientos).length === 0) {
      obtenerRequerimientosIngresos(1);
    }
  };

  nuevaPagina = (pagina) => {
    const {
      obtenerRequerimientosIngresos,
      obtenerRequerimientosIngresosFiltros,
      requerimientos_filtros,
      requerimientos_filtro_input,
    } = this.props;
    if (Object.entries(requerimientos_filtros).length > 0) {
      obtenerRequerimientosIngresosFiltros({
        filtros: requerimientos_filtro_input,
        pagina: pagina,
      });
    } else {
      obtenerRequerimientosIngresos(pagina);
    }
  };

  render = () => {
    const { requerimientos, requerimientos_filtros, is_loading_vigilancias } =
      this.props;

    var requerimientos_result = requerimientos;
    if (Object.entries(requerimientos_filtros).length > 0) {
      requerimientos_result = requerimientos_filtros;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Requerimientos de Ingreso",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo requerimientos de ingreso, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_requerimientos_ingreso" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-arrow-alt-circle-right"></i>{" "}
                    REQUERIMIENTOS DE INGRESO
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE REQUERIMIENTOS DE INGRESO{" "}
                      <small>
                        (
                        {requerimientos_result && requerimientos_result.total
                          ? requerimientos_result.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={requerimientos_result}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla requerimientos={requerimientos_result} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerRequerimientosIngresos: PropTypes.func.isRequired,
  obtenerRequerimientosIngresosFiltros: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  requerimientos: PropTypes.object.isRequired,
  requerimientos_filtros: PropTypes.object.isRequired,
  requerimientos_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  requerimientos: state.vigilancias.requerimientos,
  requerimientos_filtros: state.vigilancias.requerimientos_filtros,
  requerimientos_filtro_input: state.vigilancias.requerimientos_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerRequerimientosIngresos, obtenerRequerimientosIngresosFiltros },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
