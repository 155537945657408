import React, { Component } from "react";
import { Tooltip } from "react-tooltip";
import { Navbar, SidebarInicio } from "../../general";

export class Listado extends Component {
  render = () => {
    const { equipo } = this.props;

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="tareas_equipo" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-users"></i> EQUIPO DE TRABAJO
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered mb-0 table-sm">
                        <thead>
                          <tr>
                            <th className="w-5 text-center">ID</th>
                            <th className="w-15">Nombre</th>
                            <th className="w-15">Email</th>
                            <th>Proyectos</th>
                            <th className="w-10">Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          {equipo && equipo.length > 0 ? (
                            equipo.map((eq, x) => (
                              <tr key={x}>
                                <td className="text-center">{x + 1}</td>
                                <td>{eq.usuario_text}</td>
                                <td>{eq.email_text}</td>
                                <td>
                                  {eq.rol_text}
                                  {eq.rol_text === "Propietario" && (
                                    <>
                                      <i
                                        className="fa fa-exclamation-circle text-warning ms-1"
                                        data-tip
                                        data-tooltip-id="propietario"
                                      ></i>
                                      <Tooltip
                                        id="propietario"
                                        aria-haspopup="true"
                                      >
                                        <p>PROPIETARIO, ENTENDIDO COMO:</p>
                                        <ul>
                                          <li>EL DUEÑO DE LA TAREA</li>
                                          <li>EL QUE ORIGINA EL ENCARGO</li>
                                          <li>
                                            EL QUE RECIBE Y APRUEBA LOS
                                            ENTREGABLES
                                          </li>
                                        </ul>
                                      </Tooltip>
                                    </>
                                  )}
                                  {eq.rol_text === "Colaborador" && (
                                    <>
                                      <i
                                        className="fa fa-exclamation-circle text-warning ms-1"
                                        data-tip
                                        data-tooltip-id="colaborador"
                                      ></i>
                                      <Tooltip
                                        id="colaborador"
                                        aria-haspopup="true"
                                      >
                                        <p>COLABORADOR, ENTENDIDO COMO:</p>
                                        <ul>
                                          <li>
                                            EL RESPONSABLE DE APORTAR AL
                                            PROPIETARIO EL ENTREGABLE SOLICITADO
                                          </li>
                                          <li>
                                            ESTÁ A CARGO DE CUMPLIR EL ENCARGO
                                            REALIZADO POR EL PROPIETARIO
                                            CUMPLIENDO LOS REQUISITOS DEFINIDOS
                                            PARA ELLO
                                          </li>
                                        </ul>
                                      </Tooltip>
                                    </>
                                  )}
                                  {eq.rol_text === "Visualizador" && (
                                    <>
                                      <i
                                        data-tip
                                        data-tooltip-id="visualizador"
                                        className="fa fa-exclamation-circle text-warning ms-1"
                                      ></i>
                                      <Tooltip
                                        id="visualizador"
                                        aria-haspopup="true"
                                      >
                                        <p>VISUALIZADOR, ENTENDIDO COMO:</p>
                                        <ul>
                                          <li>
                                            AQUEL MIEMBRO DEL EQUIPO DE TRABAJO
                                            QUE SOLO PUEDE VISUALIZAR LA TAREA
                                          </li>
                                          <li>
                                            NO PUEDE EDITAR, CREAR NI ELIMINAR
                                            INFORMACIÓN
                                          </li>
                                        </ul>
                                      </Tooltip>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5" className="text-center">
                                No existen miembros
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default Listado;
