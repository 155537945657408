import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerTitular } from "../../../redux/actions/vigilancias/titulares/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Tabs as Expedientes } from "../expedientes";
import { Tabs as Pertinencias } from "../pertinencias";
import { Tabs as UnidadesFiscalizable } from "../unidades";
import { TablaMedia } from "./";

export class Detalle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre: "",
    };
  }

  componentDidMount = () => {
    const { obtenerTitular, titular_id } = this.props;
    if (titular_id > 0) {
      obtenerTitular(titular_id);
    }
  };

  render = () => {
    const { titular, is_loading_vigilancias, titular_id } = this.props;

    if (titular_id === 0) {
      return <Navigate to="/compliance-analytics/titulares" />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Titular",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo el titular, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_titulares" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-user-tie"></i> TITULARES DE PROYECTOS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <Link
                      className="btn btn-primary float-end"
                      to="/compliance-analytics/titulares"
                    >
                      <i className="fa fa-angle-left"></i> Volver al listado
                    </Link>
                  </div>
                  <div className="card-body">
                    <ul className="nav nav-pills nav-justified">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="titular-general-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#titular-general"
                          type="button"
                          role="tab"
                          aria-controls="titular-general"
                          aria-selected="true"
                        >
                          Información del Titular
                        </button>
                      </li>
                      {titular &&
                        titular.expedientes &&
                        titular.expedientes.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="expedientes-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#expedientes"
                              type="button"
                              role="tab"
                              aria-controls="expedientes"
                              aria-selected="true"
                            >
                              Expedientes SEIA ({titular.expedientes.length})
                            </button>
                          </li>
                        )}
                      {titular &&
                        titular.pertinencias &&
                        titular.pertinencias.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pertinencias-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pertinencias"
                              type="button"
                              role="tab"
                              aria-controls="pertinencias"
                              aria-selected="true"
                            >
                              Pertinencias ({titular.pertinencias.length})
                            </button>
                          </li>
                        )}
                      {titular &&
                        titular.unidad_fiscalizable &&
                        titular.unidad_fiscalizable.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="unidades-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#unidades"
                              type="button"
                              role="tab"
                              aria-controls="unidades"
                              aria-selected="true"
                            >
                              Unidades Fiscalizables (
                              {titular.unidad_fiscalizable.length})
                            </button>
                          </li>
                        )}
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show mt-3"
                        id="titular-general"
                        role="tabpanel"
                        aria-labelledby="titular-general-tab"
                      >
                        <TablaMedia titular={titular} />
                      </div>
                      {titular &&
                        titular.expedientes &&
                        titular.expedientes.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id="expedientes"
                            role="tabpanel"
                            aria-labelledby="expedientes-tab"
                          >
                            <Expedientes expedientes={titular.expedientes} />
                          </div>
                        )}
                      {titular &&
                        titular.pertinencias &&
                        titular.pertinencias.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id="pertinencias"
                            role="tabpanel"
                            aria-labelledby="pertinencias-tab"
                          >
                            <Pertinencias pertinencias={titular.pertinencias} />
                          </div>
                        )}
                      {titular &&
                        titular.unidad_fiscalizable &&
                        titular.unidad_fiscalizable.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id="unidades"
                            role="tabpanel"
                            aria-labelledby="unidades-tab"
                          >
                            <UnidadesFiscalizable
                              unidades_fiscalizables={
                                titular.unidad_fiscalizable
                              }
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerTitular: PropTypes.func.isRequired,
  titular: PropTypes.object.isRequired,
  titular_id: PropTypes.number.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  titular: state.vigilancias.titular,
  titular_id: state.vigilancias.titular_id,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerTitular }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
