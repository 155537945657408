export const EXPEDIENTES_MAPA_FILTROS_INPUT = "EXPEDIENTES_MAPA_FILTROS_INPUT";
export const EXPEDIENTES_MAPA_FALLIDO = "EXPEDIENTES_MAPA_FALLIDO";
export const EXPEDIENTES_MAPA_OBTENER = "EXPEDIENTES_MAPA_OBTENER";
export const EXPEDIENTES_OBTENER = "EXPEDIENTES_OBTENER";
export const EXPEDIENTES_FALLIDO = "EXPEDIENTES_FALLIDO";
export const EXPEDIENTES_FILTROS_OBTENER = "EXPEDIENTES_FILTROS_OBTENER";
export const EXPEDIENTES_FILTROS_FALLIDO = "EXPEDIENTES_FILTROS_FALLIDO";
export const EXPEDIENTE_OBTENER = "EXPEDIENTE_OBTENER";
export const EXPEDIENTE_FALLIDO = "EXPEDIENTE_FALLIDO";
export const EXPEDIENTES_FILTROS_INPUT = "EXPEDIENTES_FILTROS_INPUT";
export const EXPEDIENTE_GUARDAR = "EXPEDIENTE_GUARDAR";
export const LIMPIAR_EXPEDIENTES_FILTROS = "LIMPIAR_EXPEDIENTES_FILTROS";
export const LIMPIAR_EXPEDIENTES_DOCUMENTOS_FILTROS =
  "LIMPIAR_EXPEDIENTES_DOCUMENTOS_FILTROS";
export const LIMPIAR_EXPEDIENTES_MAPA_FILTROS =
  "LIMPIAR_EXPEDIENTES_MAPA_FILTROS";
export const FILTROS_EXPEDIENTES_MAPA_FALLIDO =
  "FILTROS_EXPEDIENTES_MAPA_FALLIDO";
export const FILTROS_EXPEDIENTES_MAPA_OBTENER =
  "FILTROS_EXPEDIENTES_MAPA_OBTENER";
export const EXPEDIENTES_ESTADISTICAS_OBTENER =
  "EXPEDIENTES_ESTADISTICAS_OBTENER";
export const EXPEDIENTES_ESTADISTICAS_FALLIDO =
  "EXPEDIENTES_ESTADISTICAS_FALLIDO";
export const EXPEDIENTES_ESTADISTICAS_GENERADORA_FALLIDO =
  "EXPEDIENTES_ESTADISTICAS_GENERADORA_FALLIDO";
export const EXPEDIENTES_ESTADISTICAS_GENERADORA_OBTENER =
  "EXPEDIENTES_ESTADISTICAS_GENERADORA_OBTENER";
export const EXPEDIENTES_DOCUMENTOS_FALLIDO = "EXPEDIENTES_DOCUMENTOS_FALLIDO";
export const EXPEDIENTES_DOCUMENTOS_FILTROS_INPUT =
  "EXPEDIENTES_DOCUMENTOS_FILTROS_INPUT";
export const EXPEDIENTES_DOCUMENTOS_OBTENER = "EXPEDIENTES_DOCUMENTOS_OBTENER";
export const FILTROS_EXPEDIENTES_DOCUMENTOS_FALLIDO =
  "FILTROS_EXPEDIENTES_DOCUMENTOS_FALLIDO";
export const FILTROS_EXPEDIENTES_DOCUMENTOS_OBTENER =
  "FILTROS_EXPEDIENTES_DOCUMENTOS_OBTENER";
