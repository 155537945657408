import currencyformatter from "currencyformatter.js";
import React, { Component } from "react";

export class Tabs extends Component {
  render = () => {
    const { expedientes } = this.props;

    if (expedientes && expedientes.length < 1) {
      return (
        <tr className="text-center">
          <td colSpan="7">No existen Expedientes MMA registrados</td>
        </tr>
      );
    } else {
      return (
        <div className="align-items-start">
          <div className="row">
            {expedientes.length > 1 && (
              <div className="col-3">
                <div
                  className="nav flex-column nav-pills me-3"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {expedientes.map((expediente, x) => (
                    <button
                      key={x}
                      className={`nav-link ${
                        x === 0 ? "active" : ""
                      } text-start`}
                      id={`expediente-tab-${expediente.ficha}`}
                      data-bs-toggle="pill"
                      data-bs-target={`#expediente-${expediente.ficha}`}
                      type="button"
                      role="tab"
                      aria-controls={`expediente-${expediente.ficha}`}
                      aria-selected={x === 0 ? "true" : "false"}
                    >
                      {expediente.nombre}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <div className={`col-${expedientes.length > 1 ? 9 : 12}`}>
              <div className="tab-content" id="v-pills-expedientes">
                {expedientes.map((expediente, x) => (
                  <div
                    key={x}
                    className={`tab-pane fade ${x === 0 ? "show active" : ""}`}
                    id={`expediente-${expediente.ficha}`}
                    role="tabpanel"
                    aria-labelledby={`expediente-tab-${expediente.ficha}`}
                    tabIndex="0"
                  >
                    <>
                      <ul className="nav nav-pills nav-justified">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id={`expediente-general-tab-${expediente.ficha}`}
                            data-bs-toggle="pill"
                            data-bs-target={`#expediente-general-${expediente.ficha}`}
                            type="button"
                            role="tab"
                            aria-controls={`expediente-general-${expediente.ficha}`}
                            aria-selected="true"
                          >
                            Información del Expediente
                          </button>
                        </li>
                        {expediente.folios && expediente.folios.length > 0 && (
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id={`expediente-folios-tab-${expediente.ficha}`}
                              data-bs-toggle="pill"
                              data-bs-target={`#expediente-folios-${expediente.ficha}`}
                              type="button"
                              role="tab"
                              aria-controls={`expediente-folios-${expediente.ficha}`}
                              aria-selected="true"
                            >
                              Folios{" "}
                              <small>
                                (
                                {expediente.folios
                                  ? expediente.folios.length
                                  : 0}
                                )
                              </small>
                            </button>
                          </li>
                        )}
                        {expediente.documentos &&
                          expediente.documentos.length > 0 && (
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id={`expediente-documentos-tab-${expediente.ficha}`}
                                data-bs-toggle="pill"
                                data-bs-target={`#expediente-documentos-${expediente.ficha}`}
                                type="button"
                                role="tab"
                                aria-controls={`expediente-documentos-${expediente.ficha}`}
                                aria-selected="true"
                              >
                                Documentos{" "}
                                <small>
                                  (
                                  {expediente.documentos
                                    ? expediente.documentos.length
                                    : 0}
                                  )
                                </small>
                              </button>
                            </li>
                          )}
                        {expediente.limites &&
                          expediente.limites.length > 0 && (
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id={`expediente-limites-tab-${expediente.ficha}`}
                                data-bs-toggle="pill"
                                data-bs-target={`#expediente-limites-${expediente.ficha}`}
                                type="button"
                                role="tab"
                                aria-controls={`expediente-limites-${expediente.ficha}`}
                                aria-selected="true"
                              >
                                Límites Geográficos{" "}
                                <small>
                                  (
                                  {expediente.limites
                                    ? expediente.limites.length
                                    : 0}
                                  )
                                </small>
                              </button>
                            </li>
                          )}
                        {expediente.expediente_relacionado &&
                          expediente.expediente_relacionado.length > 0 && (
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link"
                                id={`expediente-expediente_relacionado-tab-${expediente.ficha}`}
                                data-bs-toggle="pill"
                                data-bs-target={`#expediente-expediente_relacionado-${expediente.ficha}`}
                                type="button"
                                role="tab"
                                aria-controls={`expediente-expediente_relacionado-${expediente.ficha}`}
                                aria-selected="true"
                              >
                                Expedientes Relacionados{" "}
                                <small>
                                  (
                                  {expediente.expediente_relacionado
                                    ? expediente.expediente_relacionado.length
                                    : 0}
                                  )
                                </small>
                              </button>
                            </li>
                          )}
                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show mt-3"
                          id={`expediente-general-${expediente.ficha}`}
                          aria-labelledby={`expediente-general-tab-${expediente.ficha}`}
                          role="tabpanel"
                        >
                          <div className="table-responsive" key={x}>
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td className="table-secondary w-15 text-dark">
                                    Nombre
                                  </td>
                                  <td>{expediente.nombre}</td>
                                </tr>
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Expediente
                                  </td>
                                  <td>
                                    <a
                                      href={expediente.link_expediente_mma}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Ver expediente en MMA{" "}
                                      <i className="fa fa-angle-right"></i>
                                    </a>
                                  </td>
                                </tr>
                                {expediente.link_norma_vigente && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Link Norma Vigente
                                    </td>
                                    <td>
                                      <a
                                        href={expediente.link_norma_vigente}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Ver Norma{" "}
                                        <i className="fa fa-angle-right"></i>
                                      </a>
                                    </td>
                                  </tr>
                                )}
                                {expediente.texto_plan_vigente && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Link Norma Vigente
                                    </td>
                                    <td>
                                      {expediente.texto_plan_vigente &&
                                      expediente.link_plan_vigente ? (
                                        <a
                                          href={expediente.link_plan_vigente}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {expediente.texto_plan_vigente}{" "}
                                          <i className="fa fa-angle-right"></i>
                                        </a>
                                      ) : (
                                        expediente.texto_plan_vigente
                                      )}
                                    </td>
                                  </tr>
                                )}
                                {expediente.link_pac && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Link PAC
                                    </td>
                                    <td>
                                      <a
                                        href={expediente.link_pac}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Ver PAC{" "}
                                        <i className="fa fa-angle-right"></i>
                                      </a>
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Estado
                                  </td>
                                  <td>{expediente.estado}</td>
                                </tr>
                                {expediente.etapa_actual && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Etapa Actual
                                    </td>
                                    <td>{expediente.etapa_actual}</td>
                                  </tr>
                                )}
                                {expediente.tipo && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Tipo
                                    </td>
                                    <td>{expediente.tipo}</td>
                                  </tr>
                                )}
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Tipología
                                  </td>
                                  <td>{expediente.tipologia}</td>
                                </tr>
                                {expediente.gestiones_pendientes && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Gestiones Pendientes
                                    </td>
                                    <td>{expediente.gestiones_pendientes}</td>
                                  </tr>
                                )}
                                {expediente.tema_ambiental && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Tema Ambiental
                                    </td>
                                    <td>{expediente.tema_ambiental}</td>
                                  </tr>
                                )}
                                {expediente.decreto_supremo && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Decreto Supremo
                                    </td>
                                    <td>{expediente.decreto_supremo}</td>
                                  </tr>
                                )}
                                {expediente.fecha_publicacion && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Fecha de Publicación
                                    </td>
                                    <td>{expediente.fecha_publicacion}</td>
                                  </tr>
                                )}
                                {expediente.entrada_vigencia && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Entrada en Vigencia
                                    </td>
                                    <td>{expediente.entrada_vigencia}</td>
                                  </tr>
                                )}
                                {expediente.ambito_territorial && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Ambito Territorial
                                    </td>
                                    <td>{expediente.ambito_territorial}</td>
                                  </tr>
                                )}
                                {expediente.limite_geografico && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Límite Geográfico
                                    </td>
                                    <td>{expediente.limite_geografico}</td>
                                  </tr>
                                )}
                                {expediente.rol_ubicacion && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Rol de Expediente y Ubicación
                                    </td>
                                    <td>{expediente.rol_ubicacion}</td>
                                  </tr>
                                )}
                                {expediente.objetivo && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Objetivo
                                    </td>
                                    <td>{expediente.objetivo}</td>
                                  </tr>
                                )}
                                {expediente.tipos_parametros_elementos_sustancias &&
                                  expediente
                                    .tipos_parametros_elementos_sustancias
                                    .length > 0 && (
                                    <tr>
                                      <td className="table-secondary text-dark">
                                        Tipos de Parametros, Elementos y
                                        Sustancias Regulados
                                      </td>
                                      <td>
                                        <ul className="mb-0">
                                          {expediente.tipos_parametros_elementos_sustancias.map(
                                            (tipo, y) => (
                                              <li key={y}>{tipo}</li>
                                            )
                                          )}
                                        </ul>
                                      </td>
                                    </tr>
                                  )}
                                {expediente.parametros_elementos_sustancias &&
                                  expediente.parametros_elementos_sustancias
                                    .length > 0 && (
                                    <tr>
                                      <td className="table-secondary text-dark">
                                        Parametros, Elementos y Sustancias
                                        Regulados
                                      </td>
                                      <td>
                                        <ul className="mb-0">
                                          {expediente.parametros_elementos_sustancias.map(
                                            (tipo, y) => (
                                              <li key={y}>{tipo}</li>
                                            )
                                          )}
                                        </ul>
                                      </td>
                                    </tr>
                                  )}
                                {expediente.fiscalizador &&
                                  expediente.fiscalizador.length > 0 && (
                                    <tr>
                                      <td className="table-secondary text-dark">
                                        Fiscalizador
                                      </td>
                                      <td>
                                        <ul className="mb-0">
                                          {expediente.fiscalizador.map(
                                            (tipo, y) => (
                                              <li key={y}>{tipo}</li>
                                            )
                                          )}
                                        </ul>
                                      </td>
                                    </tr>
                                  )}
                                {expediente.tipos_contaminantes_regulados &&
                                  expediente.tipos_contaminantes_regulados
                                    .length > 0 && (
                                    <tr>
                                      <td className="table-secondary text-dark">
                                        Tipos de Contaminantes Regulados
                                      </td>
                                      <td>
                                        <ul className="mb-0">
                                          {expediente.tipos_contaminantes_regulados.map(
                                            (tipo, y) => (
                                              <li key={y}>{tipo}</li>
                                            )
                                          )}
                                        </ul>
                                      </td>
                                    </tr>
                                  )}
                                {expediente.contaminantes_regulados &&
                                  expediente.contaminantes_regulados.length >
                                    0 && (
                                    <tr>
                                      <td className="table-secondary text-dark">
                                        Contaminantes Regulados
                                      </td>
                                      <td>
                                        <ul className="mb-0">
                                          {expediente.contaminantes_regulados.map(
                                            (tipo, y) => (
                                              <li key={y}>{tipo}</li>
                                            )
                                          )}
                                        </ul>
                                      </td>
                                    </tr>
                                  )}
                                {expediente.tipos_fuentes_reguladas &&
                                  expediente.tipos_fuentes_reguladas.length >
                                    0 && (
                                    <tr>
                                      <td className="table-secondary text-dark">
                                        Tipos de Fuentes Reguladas
                                      </td>
                                      <td>
                                        <ul className="mb-0">
                                          {expediente.tipos_fuentes_reguladas.map(
                                            (tipo, y) => (
                                              <li key={y}>{tipo}</li>
                                            )
                                          )}
                                        </ul>
                                      </td>
                                    </tr>
                                  )}
                                {expediente.detalle_fuentes_reguladas &&
                                  expediente.detalle_fuentes_reguladas.length >
                                    0 && (
                                    <tr>
                                      <td className="table-secondary text-dark">
                                        Fuentes Reguladas
                                      </td>
                                      <td>
                                        <ul className="mb-0">
                                          {expediente.detalle_fuentes_reguladas.map(
                                            (tipo, y) => (
                                              <li key={y}>{tipo}</li>
                                            )
                                          )}
                                        </ul>
                                      </td>
                                    </tr>
                                  )}
                                {expediente.procedimiento_medicion && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Procedimiento de Medición
                                    </td>
                                    <td>{expediente.procedimiento_medicion}</td>
                                  </tr>
                                )}
                                {expediente.resultado_esperado && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Resultado Esperado
                                    </td>
                                    <td>{expediente.resultado_esperado}</td>
                                  </tr>
                                )}
                                {expediente.detalle_fiscalizacion && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Detalle Fiscalización
                                    </td>
                                    <td>{expediente.detalle_fiscalizacion}</td>
                                  </tr>
                                )}
                                {expediente.implementacion && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Implementación
                                    </td>
                                    <td>{expediente.implementacion}</td>
                                  </tr>
                                )}
                                {expediente.monitoreo && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Monitoreo
                                    </td>
                                    <td>{expediente.monitoreo}</td>
                                  </tr>
                                )}
                                {expediente.seguimiento && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Seguimiento
                                    </td>
                                    <td>{expediente.seguimiento}</td>
                                  </tr>
                                )}
                                {expediente.plazo_seguimiento && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Plazo de Seguimiento
                                    </td>
                                    <td>{expediente.plazo_seguimiento}</td>
                                  </tr>
                                )}
                                {expediente.instancias_control && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Instancias de Control
                                    </td>
                                    <td>{expediente.instancias_control}</td>
                                  </tr>
                                )}
                                {expediente.resolucion_inicio_proceso && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      N° de Resolución inicio proceso
                                    </td>
                                    <td>
                                      {expediente.resolucion_inicio_proceso}
                                    </td>
                                  </tr>
                                )}
                                {expediente.fecha_resolucion_inicio_proceso && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Fecha de Resolución inicio proceso
                                    </td>
                                    <td>
                                      {
                                        expediente.fecha_resolucion_inicio_proceso
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {expediente.folios && expediente.folios.length > 0 && (
                          <div
                            className="tab-pane fade mt-3"
                            id={`expediente-folios-${expediente.ficha}`}
                            aria-labelledby={`expediente-folios-tab-${expediente.ficha}`}
                            role="tabpanel"
                          >
                            <table className="table table-bordered table-hover">
                              <thead className="table-secondary">
                                <tr>
                                  <th className="text-center w-3">ID</th>
                                  <th className="w-7">Número</th>
                                  <th>Nombre</th>
                                  <th className="w-25">Materia</th>
                                  <th className="w-20">Remitido por</th>
                                  <th className="w-10">Fecha de Publicación</th>
                                  <th className="w-6 text-center">Link</th>
                                </tr>
                              </thead>
                              <tbody>
                                {expediente.folios.map((folio, z) => (
                                  <tr key={z}>
                                    <td className="text-center">{z + 1}</td>
                                    <td>{folio.numero}</td>
                                    <td>{folio.nombre}</td>
                                    <td className="text-uppercase">
                                      {folio.materia}
                                    </td>
                                    <td className="text-uppercase">
                                      {folio.remitido}
                                    </td>
                                    <td>{folio.fecha_publicacion}</td>
                                    <td>
                                      <a
                                        href={folio.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Ver Folio{" "}
                                        <i className="fa fa-angle-right"></i>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {expediente.documentos &&
                          expediente.documentos.length > 0 && (
                            <div
                              className="tab-pane fade mt-3"
                              id={`expediente-documentos-${expediente.ficha}`}
                              aria-labelledby={`expediente-documentos-tab-${expediente.ficha}`}
                              role="tabpanel"
                            >
                              <table className="table table-bordered table-hover">
                                <thead className="table-secondary">
                                  <tr>
                                    <th className="text-center w-3">ID</th>
                                    <th>Nombre</th>
                                    <th className="w-20">Link</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {expediente.documentos.map((documento, z) => (
                                    <tr key={z}>
                                      <td className="text-center">{z + 1}</td>
                                      <td>{documento.nombre}</td>
                                      <td>
                                        {documento.link ? (
                                          <a
                                            href={documento.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Ver Documento{" "}
                                            <i className="fa fa-angle-right"></i>
                                          </a>
                                        ) : (
                                          <span>Sin Información</span>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        {expediente.limites &&
                          expediente.limites.length > 0 && (
                            <div
                              className="tab-pane fade mt-3"
                              id={`expediente-limites-${expediente.ficha}`}
                              aria-labelledby={`expediente-limites-tab-${expediente.ficha}`}
                              role="tabpanel"
                            >
                              <table className="table table-bordered table-hover">
                                <thead className="table-secondary">
                                  <tr>
                                    <th className="text-center w-3">ID</th>
                                    <th>Datum</th>
                                    <th>Huso</th>
                                    <th>Coordenada UTM Norte</th>
                                    <th>Coordenada UTM Este</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {expediente.limites.map((documento, z) => (
                                    <tr key={z}>
                                      <td className="text-center">{z + 1}</td>
                                      <td>{documento.datum}</td>
                                      <td>{documento.huso}</td>
                                      <td>
                                        {currencyformatter.format(
                                          documento.coordenada_utm_norte,
                                          {
                                            symbol: "",
                                            decimal: ",",
                                            group: ".",
                                          }
                                        )}
                                      </td>
                                      <td>
                                        {currencyformatter.format(
                                          documento.coordenada_utm_este,
                                          {
                                            symbol: "",
                                            decimal: ",",
                                            group: ".",
                                          }
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        {expediente.expediente_relacionado &&
                          expediente.expediente_relacionado.length > 0 && (
                            <div
                              className="tab-pane fade mt-3"
                              id={`expediente-expediente_relacionado-${expediente.ficha}`}
                              aria-labelledby={`expediente-expediente_relacionado-tab-${expediente.ficha}`}
                              role="tabpanel"
                            >
                              <table className="table table-bordered table-hover">
                                <thead className="table-secondary">
                                  <tr>
                                    <th className="text-center w-3">ID</th>
                                    <th>Nombre</th>
                                    <th>Expediente</th>
                                    <th>Tipo</th>
                                    <th>Tipología</th>
                                    <th>Estado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {expediente.expediente_relacionado.map(
                                    (exp, z) => (
                                      <tr key={z}>
                                        <td className="text-center">{z + 1}</td>
                                        <td>{exp.nombre}</td>
                                        <td>
                                          <a
                                            href={exp.link_expediente_mma}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Ver expediente en MMA{" "}
                                            <i className="fa fa-angle-right"></i>
                                          </a>
                                        </td>
                                        <td>{exp.tipo}</td>
                                        <td>{exp.tipologia}</td>
                                        <td>{exp.estado}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                      </div>
                    </>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}

export default Tabs;
