export const UNIDADES_FISCALIZABLES_OBTENER = 'UNIDADES_FISCALIZABLES_OBTENER';
export const UNIDADES_FISCALIZABLES_FALLIDO = 'UNIDADES_FISCALIZABLES_FALLIDO';
export const UNIDADES_FISCALIZABLES_FILTROS_OBTENER = 'UNIDADES_FISCALIZABLES_FILTROS_OBTENER';
export const UNIDADES_FISCALIZABLES_FILTROS_FALLIDO = 'UNIDADES_FISCALIZABLES_FILTROS_FALLIDO';
export const UNIDAD_FISCALIZABLE_GUARDAR = 'UNIDAD_FISCALIZABLE_GUARDAR';
export const UNIDAD_FISCALIZABLE_OBTENER = 'UNIDAD_FISCALIZABLE_OBTENER';
export const UNIDAD_FISCALIZABLE_FALLIDO = 'UNIDAD_FISCALIZABLE_FALLIDO';
export const UNIDADES_FISCALIZABLES_FILTROS_INPUT = 'UNIDADES_FISCALIZABLES_FILTROS_INPUT';
export const LIMPIAR_UNIDADES_FILTROS = 'LIMPIAR_UNIDADES_FILTROS';
export const UNIDADES_FISCALIZABLES_ESTADISTICAS_OBTENER = 'UNIDADES_FISCALIZABLES_ESTADISTICAS_OBTENER';
export const UNIDADES_FISCALIZABLES_ESTADISTICAS_FALLIDO = 'UNIDADES_FISCALIZABLES_ESTADISTICAS_FALLIDO';
