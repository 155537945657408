import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import slugify from "slugify";
import SweetAlert from "sweetalert2";

import {
  categoriaSeleccionada,
  obtenerCategorias,
} from "../../../redux/actions/permitting/action";
import {
  Footer,
  Navbar,
  SiderbarPermittingParametrizacion,
} from "../../general";
import { withRouter } from "../../general/with_router";
import { FichaProyecto } from "../general";

export class Categorias extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { obtenerCategorias, seccion_seleccionada, consulta_seleccionada } =
      this.props;
    obtenerCategorias({
      seccion: seccion_seleccionada.id,
      consulta: consulta_seleccionada,
    });
  };

  verDetalleCategoria = (id, nombre) => {
    const { categoriaSeleccionada } = this.props;
    categoriaSeleccionada({
      id: id,
      nombre: nombre,
    });
  };

  render = () => {
    const {
      categorias,
      categorias_error,
      seccion_seleccionada,
      is_loading_permitting,
      consulta_seleccionada,
    } = this.props;

    if (!seccion_seleccionada && seccion_seleccionada.id === 0) {
      return <Navigate to="/permitting-target/parametrizacion/secciones" />;
    } else if (consulta_seleccionada === 0) {
      return <Navigate to="/permitting-target/proyectos" />;
    }

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Parametrización",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las categorias, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (
      Object.entries(categorias_error).length > 0 &&
      categorias_error.message
    ) {
      SweetAlert.fire({
        title: "Parametrización",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: categorias_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then(() => {
        this.props.navigate("/permitting-target/proyectos");
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SiderbarPermittingParametrizacion menu_1="permitting_parametrizacion" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h4>PARAMETRIZACIÓN DE LA CONSULTA</h4>
                  </div>
                  <div className="card-body">
                    <ol className="breadcrumb mt-0 mb-0 font-14">
                      <li className="breadcrumb-item">
                        <Link to="/permitting-target/proyectos">Proyectos</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/permitting-target/parametrizacion/secciones">
                          Secciones
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Categoría</li>
                    </ol>
                    <h5 className="mt-0 mb-1">
                      {seccion_seleccionada.nombre}{" "}
                      <i className="fa fa-angle-right"></i>{" "}
                      {categorias ? categorias.length : 0}{" "}
                      {categorias && categorias.length > 1
                        ? "Categorías"
                        : "Categoría"}
                    </h5>
                    <FichaProyecto />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h5>ETAPA ACTUAL DEL PROCESO DE LA CONSULTA</h5>
                  </div>
                  <div className="card-body">
                    <ol className="c-progress-steps">
                      <li className="c-progress-steps__step current">
                        <span>1. Parametrización de la consulta</span>
                      </li>
                      <li className="c-progress-steps__step">
                        <span>2. Reporte de aplicabilidad</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 mb-2 text-end">
                <p>
                  <Link
                    to="/permitting-target/parametrizacion/secciones"
                    className="btn btn-primary float-end"
                  >
                    <i className="fa fa-angle-left"></i> Volver a las secciones
                  </Link>
                </p>
              </div>
              {categorias &&
                categorias.map((cat, x) => (
                  <div className="col-lg-12 col-sm-4" key={x}>
                    <div className="card card-eco">
                      <div className="row">
                        <div className="col-lg-2 align-self-center">
                          <div
                            className={`ml--4 mr--4 img-parametrizacion ${slugify(
                              cat.nombre,
                              { lower: true }
                            )}`}
                          ></div>
                        </div>
                        <div className="col-lg-4 align-self-center">
                          <p className="mb-0 font-11">Categoría {x + 1}</p>
                          <h5 className="mt-0">{cat.nombre}</h5>
                        </div>
                        <div className="col-lg-2 align-self-center text-center font-14">
                          {cat.estado && cat.estado.estado ? (
                            <span className="badge badge-soft-success px-1">
                              <i className="fa fa-check-double"></i> Preguntas
                              contestadas
                            </span>
                          ) : (
                            <span className="badge badge-soft-danger px-1">
                              <i className="fa fa-times"></i> Preguntas no
                              contestadas
                            </span>
                          )}
                        </div>
                        <div className="col-lg-2 align-self-center text-center">
                          <p className="mb-0">
                            <i className="fa fa-list"></i> {cat.n_preguntas}{" "}
                            {cat.n_preguntas > 1 ? "Preguntas" : "Pregunta"}{" "}
                            <i
                              className="fa fa-exclamation-circle text-warning font-10"
                              data-tip
                              data-tooltip-id={`preguntas_total_${cat.id}`}
                            ></i>
                          </p>
                          <Tooltip
                            id={`preguntas_total_${cat.id}`}
                            aria-haspopup="true"
                          >
                            Total de preguntas para contestar para la categoría{" "}
                            {cat.nombre}
                          </Tooltip>
                        </div>
                        <div className="col-lg-2 align-self-center text-center mb-1">
                          <Link
                            onClick={this.verDetalleCategoria.bind(
                              this,
                              cat.id,
                              cat.nombre
                            )}
                            to="/permitting-target/parametrizacion/preguntas"
                            className="btn btn-xs btn-dark"
                          >
                            <i className="fa fa-list"></i> Ver preguntas{" "}
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Categorias.propTypes = {
  obtenerCategorias: PropTypes.func.isRequired,
  categoriaSeleccionada: PropTypes.func.isRequired,
  categorias: PropTypes.array.isRequired,
  categorias_error: PropTypes.object.isRequired,
  seccion_seleccionada: PropTypes.object.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  consulta_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  categorias: state.permitting.categorias,
  categorias_error: state.permitting.categorias_error,
  seccion_seleccionada: state.permitting.seccion_seleccionada,
  is_loading_permitting: state.permitting.is_loading_permitting,
  consulta_seleccionada: state.permitting.consulta_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerCategorias, categoriaSeleccionada },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Categorias)
);
