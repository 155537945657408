import React, { Component } from "react";

import { Tooltip } from "react-tooltip";

export class Permisos extends Component {
  incluirPermiso = (permiso) => {
    this.props.incluirPermiso(permiso);
  };

  render = () => {
    const { permisos } = this.props;
    const createMarkup = (htmlString) => ({ __html: htmlString });

    return (
      <div className="card card-eco user_select_none">
        <div className="card-header">
          <h5 className="float-start">LISTADO DE PERMISOS OMITIDOS</h5>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-sm table-bordered mb-0">
              <thead>
                <tr>
                  <th className="text-center w-5">ID</th>
                  <th className="w-30">Nombre del permiso</th>
                  <th>Descripción del permiso</th>
                  <th className="text-center w-5">Código</th>
                  <th className="text-center w-5">Acción</th>
                </tr>
              </thead>
              <tbody>
                {permisos && permisos.length > 0 ? (
                  permisos.map((permiso, x) => (
                    <tr key={x}>
                      <td className="text-center">{x + 1}</td>
                      <td>{permiso.nombre}</td>
                      <td>
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            permiso.descripcion
                          )}
                        />
                      </td>
                      <td className="text-center">CL-{permiso.id}</td>
                      <td className="text-center">
                        <div className="btn-group">
                          <Tooltip
                            id={`permiso_incluir_${permiso.id}`}
                            aria-haspopup="true"
                          >
                            Volver a incluir permiso{" "}
                            <span className="text-lowercase">
                              {permiso.nombre}
                            </span>{" "}
                            al listado aplicable
                          </Tooltip>
                          <button
                            onClick={this.incluirPermiso.bind(this, permiso)}
                            data-tip
                            data-tooltip-id={`permiso_incluir_${permiso.id}`}
                            className="btn btn-success btn-xs"
                          >
                            <i className="fa fa-undo-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="5">
                      No existen permisos
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
}

export default Permisos;
