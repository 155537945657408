import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { guardarPertinencia } from "../../../redux/actions/vigilancias/pertinencia/action";
import { Eliminar as EliminarFavorito } from "../favoritos";
import { TablaPequena as Titulares } from "../titulares";

export class Tabla extends Component {
  setPertinencia = (id, componente) => {
    const { guardarPertinencia } = this.props;
    guardarPertinencia({ id: id, componente: componente });
  };

  render = () => {
    const { pertinencias, eliminar_favorito, componente } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover table-sm">
          <thead className="table-secondary ">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre del proyecto consultado</th>
              <th className="w-15">Titular</th>
              <th className="w-8">Tipo</th>
              <th className="w-12">Región</th>
              <th className="w-8">Estado</th>
              <th className="w-16" colSpan={2}>
                Fechas
              </th>
              <th className="w-3 text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            {pertinencias &&
            pertinencias.result &&
            pertinencias.result.length > 0 ? (
              pertinencias.result.map((pertinencia, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {pertinencias.page > 1
                      ? (pertinencias.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td>
                    {pertinencia.sociedad_quinenco && (
                      <>
                        <span className="badge badge-danger badge-md">
                          {pertinencia.sociedad_quinenco}
                        </span>{" "}
                      </>
                    )}
                    <a
                      href={pertinencia.url_sea}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-uppercase"
                    >
                      {pertinencia.nombre} <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td>
                    <Titulares
                      titulares={
                        pertinencia.titular ? [pertinencia.titular] : []
                      }
                    />
                  </td>
                  <td>
                    <span
                      className={`badge badge-${
                        (pertinencia.tipo === "Proyecto nuevo" && "warning") ||
                        (pertinencia.tipo === "Modificación con RCA" &&
                          "info") ||
                        (pertinencia.tipo === "Modificación sin RCA" &&
                          "danger")
                      }  font-13`}
                    >
                      {pertinencia.tipo}
                    </span>
                  </td>
                  <td>
                    {pertinencia.region.length < 1
                      ? "S / I"
                      : pertinencia.region.map((reg, x) => (
                          <span
                            key={x}
                            className="badge badge-primary font-11 m-1"
                          >
                            {reg.nombre}
                          </span>
                        ))}
                  </td>
                  <td className="text-center">
                    <span className="badge badge-dark font-13 text-center">
                      {pertinencia.estado}
                    </span>
                  </td>
                  <td colSpan={2}>
                    <b>Ingreso:</b> {pertinencia.fecha_presentacion} <br />
                    <b>Último Movimiento:</b>{" "}
                    {pertinencia.fecha_ultimo_movimiento}{" "}
                    <div className="mb-0">
                      <span className="badge badge-danger font-11">
                        {pertinencia.nombre_ultimo_movimiento
                          ? pertinencia.nombre_ultimo_movimiento
                          : "S/I"}
                      </span>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {eliminar_favorito && (
                        <EliminarFavorito
                          tipo="pertinencia"
                          id={pertinencia.id}
                        />
                      )}
                      <Link
                        to="/compliance-analytics/pertinencia"
                        className="btn btn-soft-dark"
                        onClick={this.setPertinencia.bind(
                          this,
                          pertinencia.id,
                          componente
                        )}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="10">
                  No existen pertinencias
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarPertinencia: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarPertinencia }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
