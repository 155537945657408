import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerProcedimiento } from "../../../redux/actions/vigilancias/procedimientos/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Guardar as GuardarFavorito } from "../favoritos/";
import { Tabs } from "../procedimientos";

export class Sancion extends Component {
  componentDidMount = () => {
    const { obtenerProcedimiento, sancion_id } = this.props;
    if (sancion_id > 0) {
      obtenerProcedimiento({
        id: sancion_id,
      });
    }
  };

  render = () => {
    const { is_loading_vigilancias, sancion_id, procedimiento } = this.props;

    if (sancion_id === 0) {
      return <Navigate to="/compliance-analytics/sanciones/listado" />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Procedimiento Sancionatorio",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo el procedimniento sancionatorio, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_sanciones" menu_2="listado" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-exclamation-triangle"></i> REGISTRO DE
                    SANCIONES
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      Expediente {procedimiento.expediente}
                    </h5>
                    <div className="float-end btn-group">
                      <GuardarFavorito
                        tipo="procedimiento"
                        id={procedimiento.id}
                      />
                      <Link
                        className="btn btn-primary"
                        to="/compliance-analytics/sanciones/listado"
                      >
                        <i className="fa fa-angle-left"></i> Volver al listado
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <Tabs procedimientos={[procedimiento]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Sancion.propTypes = {
  obtenerProcedimiento: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  procedimiento: PropTypes.object.isRequired,
  sancion_id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  procedimiento: state.vigilancias.procedimiento,
  sancion_id: state.vigilancias.sancion_id,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerProcedimiento }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Sancion);
