import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  consultaSeleccionada,
  crearConsulta,
  duplicarConsulta,
  eliminarConsulta,
  obtenerProyectos,
} from "../../redux/actions/permitting/action";
import { Footer, Navbar, SidebarInicio } from "../general";

export class Proyectos extends Component {
  componentDidMount = () => {
    const { obtenerProyectos } = this.props;
    obtenerProyectos();
  };

  verParametrizacion = (consulta, proyecto) => {
    const { consultaSeleccionada } = this.props;
    consultaSeleccionada({
      consulta: consulta.id,
      proyecto: proyecto,
    });
  };

  creacionConsulta = (proyecto) => {
    const { crearConsulta } = this.props;
    SweetAlert.fire({
      title: "Crear nueva consulta",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de crear una nueva consulta para tu proyecto ${proyecto.nombre}?`,
      icon: "info",
      confirmButtonText: 'Si, Crear <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        crearConsulta({
          proyecto: proyecto.id,
        });
      }
    });
  };

  eliminacionConsulta = (consulta, proyecto) => {
    const { eliminarConsulta } = this.props;
    SweetAlert.fire({
      title: "Eliminar consulta",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de eliminar la consulta de tu proyecto ${proyecto.nombre}?`,
      icon: "info",
      confirmButtonText: 'Si, Crear <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarConsulta({
          consulta: consulta.id,
        });
      }
    });
  };

  duplicacionConsulta = (consulta, proyecto) => {
    const { duplicarConsulta } = this.props;
    SweetAlert.fire({
      title: "Duplicar consulta",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Estás seguro de duplicar la consulta de tu proyecto ${proyecto.nombre}?`,
      icon: "info",
      confirmButtonText: 'Si, Crear <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-minus-circle"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        duplicarConsulta({
          consulta: consulta.id,
        });
      }
    });
  };

  render = () => {
    const {
      obtenerProyectos,
      proyectos,
      user,
      is_loading_permitting,
      consulta_crear,
      consulta_crear_error,
      consulta_eliminar,
      consulta_eliminar_error,
      consulta_duplicar,
      consulta_duplicar_error,
    } = this.props;

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Proyectos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Cargando proyectos, espere unos segundos",
        icon: "info",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (Object.entries(consulta_crear).length > 0 && consulta_crear.message) {
      SweetAlert.fire({
        title: "Consulta creada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: consulta_crear.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-thumbs-up"></i>',
        customClass: {
          confirmButton: "btn btn-primary me-3",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerProyectos();
        }
      });
    }

    if (
      Object.entries(consulta_crear_error).length > 0 &&
      consulta_crear_error.message
    ) {
      SweetAlert.fire({
        title: "Consulta no creada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: consulta_crear_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }

    if (
      Object.entries(consulta_eliminar).length > 0 &&
      consulta_eliminar.message
    ) {
      SweetAlert.fire({
        title: "Consulta eliminada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: consulta_eliminar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-thumbs-up"></i>',
        customClass: {
          confirmButton: "btn btn-primary me-3",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerProyectos();
        }
      });
    }

    if (
      Object.entries(consulta_eliminar_error).length > 0 &&
      consulta_eliminar_error.message
    ) {
      SweetAlert.fire({
        title: "Consulta no eliminada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: consulta_eliminar_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }

    if (
      Object.entries(consulta_duplicar).length > 0 &&
      consulta_duplicar.message
    ) {
      SweetAlert.fire({
        title: "Consulta duplicada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: consulta_duplicar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-thumbs-up"></i>',
        customClass: {
          confirmButton: "btn btn-primary me-3",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          obtenerProyectos();
        }
      });
    }

    if (
      Object.entries(consulta_duplicar_error).length > 0 &&
      consulta_duplicar_error.message
    ) {
      SweetAlert.fire({
        title: "Consulta no duplicada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: consulta_duplicar_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="permitting_proyectos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-server"></i> PROYECTOS Y CONSULTAS{" "}
                    <small>({proyectos ? proyectos.length : 0})</small>
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              {proyectos && proyectos.length > 0 ? (
                proyectos.map((proyecto, x) => (
                  <div className="col-sm-12 col-lg-6" key={x}>
                    <div className="card card-eco">
                      <div className="card-header">
                        <h5 className="float-start">
                          <span className="badge badge-soft-dark">
                            ID: {x + 1}
                          </span>{" "}
                          {proyecto.nombre}
                        </h5>
                        {user.is_staff && (
                          <>
                            <h4 data-tip data-tooltip-id="cliente">
                              <span className="badge badge-warning font-12 float-end">
                                <i className="fa fa-crown"></i> Cliente:{" "}
                                {proyecto.user.nombre_completo}
                              </span>
                            </h4>
                            <Tooltip id="cliente" aria-haspopup="true">
                              Email: {proyecto.user.email}
                            </Tooltip>
                          </>
                        )}
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered bg-white">
                            <tbody>
                              <tr>
                                <td className="table-secondary w-45 text-dark">
                                  Nombre de la empresa
                                </td>
                                <td>{proyecto.empresa}</td>
                              </tr>
                              {proyecto.tipo_display && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Tipo
                                  </td>
                                  <td>{proyecto.tipo_display}</td>
                                </tr>
                              )}
                              {proyecto.elemento_tipo &&
                                proyecto.elemento_tipo.length > 0 && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Elemento consultado
                                    </td>
                                    <td>
                                      <ul className="ps-4 mb-0">
                                        {proyecto.elemento_tipo.map(
                                          (elemento, x) => (
                                            <li key={x}>{elemento.nombre}</li>
                                          )
                                        )}
                                      </ul>
                                    </td>
                                  </tr>
                                )}
                              {proyecto.elemento_nombre && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Nombre del elemento consultado
                                  </td>
                                  <td>{proyecto.elemento_nombre}</td>
                                </tr>
                              )}
                              {proyecto.titular && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Titular del elemento consultado
                                  </td>
                                  <td>{proyecto.titular}</td>
                                </tr>
                              )}
                              {proyecto.comuna_display &&
                                proyecto.region_display && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Ubicación geográfica del elemento
                                      consultado
                                    </td>
                                    <td>
                                      {proyecto.comuna_display} /{" "}
                                      {proyecto.region_display}
                                    </td>
                                  </tr>
                                )}
                              {proyecto.estado &&
                                proyecto.estado.length > 0 && (
                                  <tr>
                                    <td className="table-secondary text-dark">
                                      Estado del elemento consultado
                                    </td>
                                    <td>
                                      <ul className="ps-4 mb-0">
                                        {proyecto.estado.map((estado, x) => (
                                          <li key={x}>{estado.nombre}</li>
                                        ))}
                                      </ul>
                                    </td>
                                  </tr>
                                )}
                              {proyecto.tipologia && (
                                <tr>
                                  <td className="table-secondary text-dark">
                                    Tipología del proyecto
                                  </td>
                                  <td>{proyecto.tipologia.nombre}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="card card-eco">
                          <div className="card-header">
                            <h5 className="float-start">
                              LISTADO DE CONSULTAS{" "}
                              <small>({proyecto.consultas.length})</small>
                            </h5>
                            <div className="float-end">
                              <button
                                onClick={this.creacionConsulta.bind(
                                  this,
                                  proyecto
                                )}
                                className="btn btn-primary btn-xs"
                                data-tip
                                data-tooltip-id="crear_consulta"
                              >
                                Crear consulta <i className="fa fa-plus"></i>{" "}
                              </button>
                              <Tooltip id="crear_consulta" aria-haspopup="true">
                                {" "}
                                Crea una nueva consulta para tu proyecto
                              </Tooltip>
                            </div>
                          </div>
                          <div className="card-body">
                            <h6 className="mt-0">
                              Consultas disponibles para crear:{" "}
                              <span className="badge badge-dark">
                                {proyecto.consultas_disponibles}
                              </span>
                            </h6>
                            <div className="table-responsive">
                              <table className="table table-bordered mb-0 table-sm">
                                <thead>
                                  <tr className="text-center">
                                    <th className="text-center">ID</th>
                                    <th className="w-45">Fechas</th>
                                    <th className="w-25">Estado</th>
                                    <th className="w-25 text-center">Acción</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {proyecto.consultas &&
                                  proyecto.consultas.length > 0 ? (
                                    proyecto.consultas.map((consulta, y) => (
                                      <tr key={y} className="text-center">
                                        <td className="text-center">
                                          {x + 1}.{y + 1}
                                        </td>
                                        <td>
                                          <p className="mb-0">
                                            <span className="badge badge-soft-primary font-12">
                                              Inicio: {consulta.fecha_inicio}
                                            </span>
                                          </p>
                                          {consulta.fecha_termino && (
                                            <p className="mb-0">
                                              <span className="badge badge-soft-secondary font-12">
                                                Término:{" "}
                                                {consulta.fecha_termino}
                                              </span>
                                            </p>
                                          )}
                                        </td>
                                        <td className="">
                                          {consulta.is_finalizada ? (
                                            <>
                                              <span className="badge badge-success">
                                                <i className="fa fa-check-double"></i>{" "}
                                                Finalizada
                                              </span>
                                              <br />
                                              {consulta.is_valida ? (
                                                <>
                                                  {consulta.ver_entregables ? (
                                                    <span className="badge badge-soft-primary">
                                                      Caduca en{" "}
                                                      {
                                                        consulta.delta_entregables
                                                      }{" "}
                                                      {consulta.delta_entregables >
                                                      1
                                                        ? "días"
                                                        : "día"}
                                                    </span>
                                                  ) : (
                                                    <span className="badge badge-soft-danger">
                                                      Entregables caducados
                                                    </span>
                                                  )}
                                                </>
                                              ) : (
                                                <span className="badge badge-soft-success">
                                                  Generando entregables
                                                </span>
                                              )}
                                            </>
                                          ) : (
                                            <span className="badge badge-warning">
                                              <i className="fa fa-clock"></i> En
                                              proceso
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <div
                                            className="btn-group"
                                            role="group"
                                          >
                                            {consulta.is_valida ? (
                                              <>
                                                {consulta.is_finalizada ? (
                                                  <>
                                                    {consulta.ver_entregables &&
                                                      !user.is_staff && (
                                                        <>
                                                          <Link
                                                            className="btn btn-success btn-xs"
                                                            data-tip
                                                            data-tooltip-id="ver_entregables"
                                                            to="/permitting-target/aplicabilidad/dashboard"
                                                            onClick={this.verParametrizacion.bind(
                                                              this,
                                                              consulta,
                                                              proyecto
                                                            )}
                                                          >
                                                            <i className="fa fa-chart-pie"></i>
                                                          </Link>
                                                          <Tooltip
                                                            id="ver_entregables"
                                                            aria-haspopup="true"
                                                          >
                                                            Ver entregables de
                                                            la consulta
                                                          </Tooltip>
                                                        </>
                                                      )}
                                                    <button
                                                      type="button"
                                                      onClick={this.duplicacionConsulta.bind(
                                                        this,
                                                        consulta,
                                                        proyecto
                                                      )}
                                                      className="btn btn-primary btn-xs"
                                                      data-tip
                                                      data-tooltip-id="duplicar_consulta"
                                                    >
                                                      <i className="fa fa-clone"></i>
                                                    </button>
                                                    <Tooltip
                                                      id="duplicar_consulta"
                                                      aria-haspopup="true"
                                                    >
                                                      Duplicar la consulta de tu
                                                      proyecto
                                                    </Tooltip>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Link
                                                      to="/permitting-target/parametrizacion/secciones"
                                                      onClick={this.verParametrizacion.bind(
                                                        this,
                                                        consulta,
                                                        proyecto
                                                      )}
                                                      className="btn btn-warning btn-xs"
                                                      data-tip
                                                      data-tooltip-id="ver_parametrizacion"
                                                    >
                                                      <i className="fa fa-eye"></i>
                                                    </Link>
                                                    <Tooltip
                                                      id="ver_parametrizacion"
                                                      aria-haspopup="true"
                                                    >
                                                      Ir a parametrización de la
                                                      consulta
                                                    </Tooltip>
                                                    <button
                                                      type="button"
                                                      onClick={this.eliminacionConsulta.bind(
                                                        this,
                                                        consulta,
                                                        proyecto
                                                      )}
                                                      className="btn btn-danger btn-xs"
                                                      data-tip
                                                      data-tooltip-id="borrar_consulta"
                                                    >
                                                      <i className="fa fa-trash"></i>
                                                    </button>
                                                    <Tooltip
                                                      id="borrar_consulta"
                                                      aria-haspopup="true"
                                                    >
                                                      Borar consulta de tu
                                                      proyecto
                                                    </Tooltip>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <Tooltip
                                                  id="generando_entregables"
                                                  aria-haspopup="true"
                                                >
                                                  Generando entregables
                                                </Tooltip>
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-primary btn-xs"
                                                  data-tip
                                                  data-tooltip-id="generando_entregables"
                                                >
                                                  <i className="fas fa-circle-notch fa-spin"></i>
                                                </button>
                                              </>
                                            )}
                                            {user.is_staff &&
                                              consulta.is_finalizada && (
                                                <>
                                                  <Link
                                                    className="btn btn-warning btn-xs"
                                                    data-tip
                                                    data-tooltip-id="ver_parametrizacion_staff"
                                                    to="/permitting-target/parametrizacion/secciones"
                                                    onClick={this.verParametrizacion.bind(
                                                      this,
                                                      consulta,
                                                      proyecto
                                                    )}
                                                  >
                                                    <i className="fa fa-eye"></i>
                                                  </Link>
                                                  <Tooltip
                                                    id="ver_parametrizacion_staff"
                                                    aria-haspopup="true"
                                                  >
                                                    Ir a parametrización de la
                                                    consulta (Staff CL)
                                                  </Tooltip>
                                                  <Link
                                                    className="btn btn-success btn-xs"
                                                    data-tip
                                                    data-tooltip-id="ver_entregables_staff"
                                                    to="/permitting-target/aplicabilidad/dashboard"
                                                    onClick={this.verParametrizacion.bind(
                                                      this,
                                                      consulta,
                                                      proyecto
                                                    )}
                                                  >
                                                    <i className="fa fa-chart-pie"></i>
                                                  </Link>
                                                  <Tooltip
                                                    id="ver_entregables_staff"
                                                    aria-haspopup="true"
                                                  >
                                                    Ver entregables de la
                                                    consulta (Staff CL)
                                                  </Tooltip>
                                                </>
                                              )}
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="5" className="text-center">
                                        No tiene consultas asociadas al proyecto{" "}
                                        {proyecto.nombre}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body text-center">
                      <h5>
                        <i className="fa fa-exclamation-circle"></i> No tienes
                        proyectos asociados a tu cuenta
                      </h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Proyectos.propTypes = {
  obtenerProyectos: PropTypes.func.isRequired,
  crearConsulta: PropTypes.func.isRequired,
  eliminarConsulta: PropTypes.func.isRequired,
  duplicarConsulta: PropTypes.func.isRequired,
  consultaSeleccionada: PropTypes.func.isRequired,
  proyectos: PropTypes.array.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  consulta_crear: PropTypes.object.isRequired,
  consulta_crear_error: PropTypes.object.isRequired,
  consulta_eliminar: PropTypes.object.isRequired,
  consulta_eliminar_error: PropTypes.object.isRequired,
  consulta_duplicar: PropTypes.object.isRequired,
  consulta_duplicar_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  proyectos: state.permitting.proyectos,
  is_loading_permitting: state.permitting.is_loading_permitting,
  user: state.auth.user,
  consulta_crear: state.permitting.consulta_crear,
  consulta_crear_error: state.permitting.consulta_crear_error,
  consulta_eliminar: state.permitting.consulta_eliminar,
  consulta_eliminar_error: state.permitting.consulta_eliminar_error,
  consulta_duplicar: state.permitting.consulta_duplicar,
  consulta_duplicar_error: state.permitting.consulta_duplicar_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerProyectos,
      crearConsulta,
      eliminarConsulta,
      duplicarConsulta,
      consultaSeleccionada,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Proyectos);
