import InputMask from "@mona-health/react-input-mask";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import { format as formatRut } from "rut.js";
import {
  obtenerSanciones,
  obtenerSancionesFiltros,
} from "../../../redux/actions/vigilancias/sanciones/action";

export class Sanciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_fecha_inicio: "",
      filtro_fecha_termino: "",
      filtro_expediente: "",
      filtro_titular_nombre: "",
      filtro_titular_rut: "",
      filtro_palabra_clave: "",
      filtro_categoria: "",
      filtro_region: "",
      filtros_avanzandos: false,
    };
  }

  filtrosSanciones = () => {
    const { obtenerSancionesFiltros, obtenerSanciones } = this.props;
    const {
      filtro_fecha_inicio,
      filtro_fecha_termino,
      filtro_expediente,
      filtro_titular_nombre,
      filtro_titular_rut,
      filtro_palabra_clave,
      filtro_categoria,
      filtro_region,
    } = this.state;

    if (
      filtro_fecha_inicio ||
      filtro_fecha_termino ||
      filtro_expediente ||
      filtro_titular_nombre ||
      filtro_titular_rut ||
      filtro_palabra_clave ||
      filtro_categoria ||
      filtro_region
    ) {
      obtenerSancionesFiltros({
        fecha_inicio: filtro_fecha_inicio ? filtro_fecha_inicio : null,
        fecha_termino: filtro_fecha_termino ? filtro_fecha_termino : null,
        expediente: filtro_expediente ? filtro_expediente : null,
        titular_nombre: filtro_titular_nombre ? filtro_titular_nombre : null,
        titular_rut: filtro_titular_rut ? filtro_titular_rut : null,
        palabra_clave: filtro_palabra_clave ? filtro_palabra_clave : null,
        categoria: filtro_categoria ? filtro_categoria : null,
        region: filtro_region ? filtro_region : null,
      });
    } else {
      obtenerSanciones(1);
    }
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  borrarFiltros = () => {
    const { obtenerSanciones } = this.props;
    this.setState({
      filtro_fecha_inicio: "",
      filtro_fecha_termino: "",
      filtro_expediente: "",
      filtro_titular_nombre: "",
      filtro_titular_rut: "",
      filtro_palabra_clave: "",
      filtro_categoria: "",
      filtro_region: "",
    });
    obtenerSanciones(1);
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (e.target.name === "filtro_titular_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  render = () => {
    const { filtros_errores, filtros_sanciones } = this.props;
    const {
      filtro_fecha_inicio,
      filtro_fecha_termino,
      filtro_palabra_clave,
      filtro_expediente,
      filtro_titular_nombre,
      filtro_titular_rut,
      filtro_categoria,
      filtro_region,
      filtros_avanzandos,
    } = this.state;

    const categorias_listado =
      filtros_sanciones && filtros_sanciones["categorias"]
        ? _.orderBy(filtros_sanciones["categorias"], "nombre")
        : [];
    const regiones_listado =
      filtros_sanciones && filtros_sanciones["regiones"]
        ? _.orderBy(filtros_sanciones["regiones"], "nombre")
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>
                  Búsqueda por palabras claves
                  <i
                    className="fa fa-exclamation-circle ms-1 text-warning font-11"
                    data-tip
                    data-tooltip-id="palabraclave_sancion"
                  ></i>
                  <Tooltip
                    className="w-30"
                    id="palabraclave_sancion"
                    aria-haspopup="true"
                  >
                    Este campo de búsqueda permite buscar hechos infraccionales
                    en el marco de una sanción. Puedes buscar por frases o
                    palabras que quieres que estén presentes en la acción.
                    Ejemplo: la frase aguas servidas; o la palabra suelo; o la
                    combinación aguas servida norma.
                  </Tooltip>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_palabra_clave"
                    value={filtro_palabra_clave}
                    onChange={this.onChange}
                    placeholder="Ingrese palabra clave"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.palabra_clave
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.palabra_clave &&
                    filtros_errores.data.palabra_clave.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Expediente</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_expediente"
                    value={filtro_expediente}
                    onChange={this.onChange}
                    placeholder="Ingrese expediente"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.expediente
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.expediente &&
                    filtros_errores.data.expediente.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de inicio</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha_inicio"
                    value={filtro_fecha_inicio}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha de inicio"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_inicio
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_inicio &&
                    filtros_errores.data.fecha_inicio.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de término</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha_termino"
                    value={filtro_fecha_termino}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha de término"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_termino
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_termino &&
                    filtros_errores.data.fecha_termino.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Nombre del titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_nombre"
                    value={filtro_titular_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese nombre del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_nombre &&
                    filtros_errores.data.titular_nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>RUT del titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_rut"
                    value={filtro_titular_rut}
                    onChange={this.onChange}
                    placeholder="Ingrese rut del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_rut
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_rut &&
                    filtros_errores.data.titular_rut.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Categoría</label>
                <SelectPicker
                  name="filtro_categoria"
                  data={categorias_listado}
                  onChange={(value) => {
                    this.setState({ filtro_categoria: value });
                  }}
                  value={filtro_categoria}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.categoria &&
                  filtros_errores.data.categoria.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Región</label>
                <SelectPicker
                  name="filtro_region"
                  data={regiones_listado}
                  onChange={(value) => {
                    this.setState({ filtro_region: value });
                  }}
                  value={filtro_region}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.region &&
                  filtros_errores.data.region.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtrosSanciones}
            >
              <i className="fa fa-search"></i> Filtrar sanciones
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Sanciones.propTypes = {
  obtenerSanciones: PropTypes.func.isRequired,
  obtenerSancionesFiltros: PropTypes.func.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  filtros_sanciones: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_errores: state.vigilancias.filtros_errores,
  filtros_sanciones: state.vigilancias.filtros_sanciones,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerSanciones, obtenerSancionesFiltros },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sanciones);
