import React, { Component } from "react";
import { Tooltip } from "react-tooltip";

export class TablaPequena extends Component {
  render = () => {
    const { hechos } = this.props;

    if (hechos && hechos.length > 1) {
      return hechos.length < 1 ? (
        <span className="badge badge-soft-dark ">Sin Información</span>
      ) : (
        <ul
          className={`ml--10 mb-0 ${
            hechos.length > 8 ? "vh-30 overflow-auto" : ""
          }`}
        >
          {hechos.map((hecho, x) => (
            <li key={x}>
              {hecho && hecho.clasificacion && (
                <>
                  <span className="text-uppercase badge badge-primary">
                    {hecho.clasificacion}
                  </span>
                  <Tooltip
                    className="tooltip-limite"
                    id={`clasificacion_detalle_${hecho.id}`}
                    aria-haspopup="true"
                  >
                    {hecho.clasificacion_detalle}
                  </Tooltip>
                  <i
                    data-tip
                    data-tooltip-id={`clasificacion_detalle_${hecho.id}`}
                    className="fa fa-exclamation-circle text-danger ms-1 font-11"
                  ></i>
                  <br />
                </>
              )}
              {hecho && hecho.nombre && (
                <span className="text-uppercase me-2">{hecho.nombre}</span>
              )}
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <div>
          {hechos && hechos.length > 0 ? (
            hechos.map((hecho, x) => (
              <div key={x}>
                {hecho && hecho.clasificacion && (
                  <>
                    <span className="text-uppercase badge badge-primary">
                      {hecho.clasificacion}
                    </span>
                    <Tooltip
                      className="tooltip-limite"
                      id={`clasificacion_detalle_${hecho.id}`}
                      aria-haspopup="true"
                    >
                      {hecho.clasificacion_detalle}
                    </Tooltip>
                    <i
                      data-tip
                      data-tooltip-id={`clasificacion_detalle_${hecho.id}`}
                      className="fa fa-exclamation-circle text-danger ms-1 font-11"
                    ></i>
                    <br />
                  </>
                )}
                {hecho && hecho.nombre && (
                  <span className="text-uppercase">{hecho.nombre}</span>
                )}
              </div>
            ))
          ) : (
            <span className="badge badge-soft-dark">Sin Información</span>
          )}
        </div>
      );
    }
  };
}

export default TablaPequena;
