import React, { Component } from "react";

import { Tabs as Hecho } from "../hechos";

export class Tabs extends Component {
  render = () => {
    const { instrumentos_infringidos } = this.props;

    return instrumentos_infringidos && instrumentos_infringidos.length > 0 ? (
      instrumentos_infringidos.map((inst, x) => (
        <div key={x}>
          <ul className="nav nav-pills nav-justified">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="instrumento-general-tab"
                data-bs-toggle="pill"
                data-bs-target="#instrumento-general"
                type="button"
                role="tab"
                aria-controls="instrumento-general"
                aria-selected="true"
              >
                Información del Instrumento Infringido
              </button>
            </li>
            {inst.hecho && (
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="hecho-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#hecho"
                  type="button"
                  role="tab"
                  aria-controls="hecho"
                  aria-selected="false"
                >
                  Hecho Infraccional
                </button>
              </li>
            )}
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade active show mt-3"
              id="instrumento-general"
              role="tabpanel"
              aria-labelledby="instrumento-general-tab"
            >
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="table-secondary w-20 text-dark">Nombre</td>
                      <td>{inst.nombre}</td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">Enlace</td>
                      <td>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={inst.url}
                          className="btn btn-primary btn-xs"
                        >
                          Ver enlace <i className="fa fa-angle-right"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {inst.hecho && (
              <div
                className="tab-pane fade mt-3"
                id="hecho"
                role="tabpanel"
                aria-labelledby="hecho-tab"
              >
                <Hecho hechos={[inst.hecho]} />
              </div>
            )}
          </div>
        </div>
      ))
    ) : (
      <h6>No existe instrumento infringido</h6>
    );
  };
}

export default Tabs;
