import React, { Component } from "react";

import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format as formatRut } from "rut.js";

import {
  limpiarFiltroQuinencoUnidades,
  obtenerQuinencoUnidades,
  obtenerQuinencoUnidadesFiltros,
} from "../../../../redux/actions/vigilancias/quinenco/action";
export class Filtros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_region:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.region
          ? this.props.unidades_filtro_input.region
          : "",
      filtro_categoria:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.categoria
          ? this.props.unidades_filtro_input.categoria
          : "",
      filtro_nombre:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.nombre
          ? this.props.unidades_filtro_input.nombre
          : "",
      filtro_titular:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.titular
          ? this.props.unidades_filtro_input.titular
          : "",
      filtro_sociedad:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.sociedad
          ? this.props.unidades_filtro_input.sociedad
          : "",
      filtro_comuna:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.comuna
          ? this.props.unidades_filtro_input.comuna
          : "",
      ver_unidades:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.ver_unidades
          ? this.props.unidades_filtro_input.ver_unidades
          : false,
      filtros_avanzandos:
        this.props.unidades_filtro_input &&
        this.props.unidades_filtro_input.filtros_avanzandos
          ? this.props.unidades_filtro_input.filtros_avanzandos
          : false,
    };
  }

  filtroUnidades = () => {
    const { obtenerQuinencoUnidadesFiltros, obtenerQuinencoUnidades } =
      this.props;
    const {
      filtro_region,
      filtro_categoria,
      filtro_nombre,
      filtro_titular,
      filtro_sociedad,
      filtro_comuna,
      filtros_avanzandos,
      ver_unidades,
    } = this.state;
    if (
      filtro_region ||
      filtro_categoria ||
      filtro_nombre ||
      filtro_titular ||
      filtro_sociedad ||
      filtro_comuna
    ) {
      var filtros = {
        region: filtro_region ? filtro_region : null,
        categoria: filtro_categoria ? filtro_categoria : null,
        nombre: filtro_nombre ? filtro_nombre : null,
        titular: filtro_titular ? filtro_titular : null,
        comuna: filtro_comuna ? filtro_comuna : null,
        sociedad: filtro_sociedad ? filtro_sociedad : null,
        filtros_avanzandos: filtros_avanzandos,
        ver_unidades: ver_unidades,
      };
      obtenerQuinencoUnidadesFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerQuinencoUnidades(1);
    }
  };

  borrarFiltros = () => {
    const { obtenerQuinencoUnidades, limpiarFiltroQuinencoUnidades } =
      this.props;
    this.setState({
      filtro_region: "",
      filtro_categoria: "",
      filtro_nombre: "",
      filtro_titular: "",
      filtro_sociedad: "",
      filtro_comuna: "",
    });
    limpiarFiltroQuinencoUnidades();
    obtenerQuinencoUnidades(1);
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (e.target.name === "filtro_titular_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  render = () => {
    const { filtros_errores, quinenco_unidades_filtros } = this.props;
    const {
      filtro_region,
      filtro_comuna,
      filtro_categoria,
      filtro_sociedad,
      filtro_nombre,
      filtro_titular,
      // filtros_avanzandos,
    } = this.state;
    const regiones_listado =
      quinenco_unidades_filtros && quinenco_unidades_filtros["regiones"]
        ? _.orderBy(quinenco_unidades_filtros["regiones"], "nombre")
        : [];
    const comunas_listado =
      quinenco_unidades_filtros && quinenco_unidades_filtros["comunas"]
        ? _.orderBy(quinenco_unidades_filtros["comunas"], "nombre")
        : [];
    const sociedades_listado =
      quinenco_unidades_filtros && quinenco_unidades_filtros["sociedades"]
        ? _.orderBy(quinenco_unidades_filtros["sociedades"], "nombre")
        : [];
    const categorias_listado =
      quinenco_unidades_filtros && quinenco_unidades_filtros["categorias"]
        ? _.orderBy(quinenco_unidades_filtros["categorias"], "nombre")
        : [];
    const titulares_listado =
      quinenco_unidades_filtros && quinenco_unidades_filtros["titulares"]
        ? _.orderBy(quinenco_unidades_filtros["titulares"], "nombre")
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          {/* <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div> */}
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Nombre</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_nombre"
                    value={filtro_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese nombre"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre &&
                    filtros_errores.data.nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Categoría</label>
                <div className="input-group">
                  <select
                    name="filtro_categoria"
                    onChange={this.onChange}
                    value={filtro_categoria}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.estado
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    {categorias_listado.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.region &&
                    filtros_errores.data.region.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Tag</label>
                <div className="input-group">
                  <select
                    name="filtro_sociedad"
                    onChange={this.onChange}
                    value={filtro_sociedad}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.sociedad
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    {sociedades_listado.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.sociedad &&
                    filtros_errores.data.sociedad.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Titular</label>
                <div className="input-group">
                  <select
                    name="filtro_titular"
                    onChange={this.onChange}
                    value={filtro_titular}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    {titulares_listado.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular &&
                    filtros_errores.data.titular.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Región</label>
                <div className="input-group">
                  <select
                    name="filtro_region"
                    onChange={this.onChange}
                    value={filtro_region}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.region
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    {regiones_listado.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.region &&
                    filtros_errores.data.region.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Comuna</label>
                <div className="input-group">
                  <select
                    name="filtro_comuna"
                    onChange={this.onChange}
                    value={filtro_comuna}
                    className={`form-select ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.comuna
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Selecciona una opción</option>
                    {comunas_listado.map((option, x) => (
                      <option key={x} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </select>
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.comuna &&
                    filtros_errores.data.comuna.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroUnidades}
            >
              <i className="fa fa-search"></i> Filtrar unidades fiscalizables
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Filtros.propTypes = {
  obtenerQuinencoUnidades: PropTypes.func.isRequired,
  obtenerQuinencoUnidadesFiltros: PropTypes.func.isRequired,
  limpiarFiltroQuinencoUnidades: PropTypes.func.isRequired,
  quinenco_unidades_filtros: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  unidades_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  quinenco_unidades_filtros: state.vigilancias.quinenco_unidades_filtros,
  filtros_errores: state.vigilancias.filtros_errores,
  unidades_filtro_input: state.vigilancias.unidades_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerQuinencoUnidades,
      obtenerQuinencoUnidadesFiltros,
      limpiarFiltroQuinencoUnidades,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
