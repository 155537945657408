import React, { Component } from "react";

export class Tabla extends Component {
  render = () => {
    const { instrumentos } = this.props;

    if (instrumentos && instrumentos.length > 1) {
      return instrumentos.length < 1 ? (
        <span className="badge badge-soft-dark">Sin Información</span>
      ) : (
        <ul
          className={`ml--10 mb-0 ${
            instrumentos.length > 8 ? "vh-30 overflow-auto" : ""
          }`}
        >
          {instrumentos.map((instrumento, x) => (
            <li key={x}>
              {instrumento && (instrumento.anio || instrumento.numero) && (
                <>
                  <span className="text-uppercase badge badge-dark me-1">
                    {instrumento.anio} / {instrumento.numero}
                  </span>
                </>
              )}
              {instrumento && instrumento.tipo && (
                <>
                  <span className="text-uppercase badge badge-primary">
                    {instrumento.tipo}
                  </span>
                  <br />
                </>
              )}
              {instrumento && instrumento.nombre && (
                <span className="text-uppercase me-2">
                  {instrumento.nombre}
                </span>
              )}
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <div>
          {instrumentos && instrumentos.length > 0 ? (
            instrumentos.map((instrumento, x) => (
              <div key={x}>
                {instrumento && (instrumento.anio || instrumento.numero) && (
                  <>
                    <span className="text-uppercase badge badge-dark me-1">
                      {instrumento.anio} / {instrumento.numero}
                    </span>
                  </>
                )}
                {instrumento && instrumento.tipo && (
                  <>
                    <span className="text-uppercase badge badge-primary">
                      {instrumento.tipo}
                    </span>
                    <br />
                  </>
                )}
                {instrumento && instrumento.nombre && (
                  <span className="text-uppercase">{instrumento.nombre}</span>
                )}
              </div>
            ))
          ) : (
            <span className="badge badge-soft-dark">Sin Información</span>
          )}
        </div>
      );
    }
  };
}

export default Tabla;
