import React, { Component } from "react";

import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerDashboard } from "../../../redux/actions/permitting/action";
import { Footer, Navbar, SiderbarPermittingAplicabilidad } from "../../general";
import { FichaProyecto } from "../general";

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { obtenerDashboard, consulta_seleccionada } = this.props;
    if (consulta_seleccionada > 0) {
      obtenerDashboard({
        consulta: consulta_seleccionada,
      });
    }
  };

  render = () => {
    const { dashboard, is_loading_permitting, consulta_seleccionada } =
      this.props;
    if (consulta_seleccionada === 0) {
      return <Navigate to="/permitting-target/proyectos" />;
    }

    const legend = {
      showForSingleSeries: true,
      position: "right",
      width: 200,
      fontSize: "10px",
    };

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Dashboard",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo la información, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SiderbarPermittingAplicabilidad menu_1="permitting_dashboard" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h4>REPORTE DE APLICABILIDAD</h4>
                  </div>
                  <div className="card-body">
                    <ol className="breadcrumb mt-0 mb-0 font-14">
                      <li className="breadcrumb-item">
                        <Link to="/permitting-target/proyectos">Proyectos</Link>
                      </li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                    <FichaProyecto />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card card-eco mh-185">
                  <div className="card-header">
                    <h5>ETAPA ACTUAL DEL PROCESO DE LA CONSULTA</h5>
                  </div>
                  <div className="card-body">
                    <ol className="c-progress-steps">
                      <li className="c-progress-steps__step">
                        <span>1. Parametrización de la consulta</span>
                      </li>
                      <li className="c-progress-steps__step current">
                        <span>2. Reporte de aplicabilidad</span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5 className="mt-0">
                      Número de permisos aplicables total
                    </h5>
                    <div className="d-flex justify-content-between">
                      <h2 className="text-purple">
                        {dashboard.permisos_totales
                          ? dashboard.permisos_totales
                          : 0}
                      </h2>
                      <i className="fa fa-check card-eco-icon bg-icon-purple align-self-center"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5 className="mt-0">
                      Número de permisos aplicables único
                    </h5>
                    <div className="d-flex justify-content-between">
                      <h2 className="text-purple">
                        {dashboard.permisos_unicos
                          ? dashboard.permisos_unicos
                          : 0}
                      </h2>
                      <i className="fa fa-check card-eco-icon bg-icon-purple align-self-center"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5 className="mt-0">
                      Número de permisos sectoriales identificados
                    </h5>
                    <div className="d-flex justify-content-between">
                      <h2 className="text-purple">
                        {dashboard.permisos_ps ? dashboard.permisos_ps : 0}
                      </h2>
                      <i className="fa fa-list-alt card-eco-icon bg-icon-purple align-self-center"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5 className="mt-0">
                      Número de permisos críticos identificados
                    </h5>
                    <div className="d-flex justify-content-between">
                      <h2 className="text-purple">
                        {dashboard.permisos_criticos
                          ? dashboard.permisos_criticos
                          : 0}
                      </h2>
                      <i className="fa fa-exclamation-triangle card-eco-icon bg-icon-purple align-self-center"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5 className="mt-0">
                      Número de permisos ambientales sectoriales identificados
                    </h5>
                    <div className="d-flex justify-content-between">
                      <h2 className="text-purple">
                        {dashboard.permisos_pas ? dashboard.permisos_pas : 0}
                      </h2>
                      <i className="fa fa-seedling card-eco-icon bg-icon-purple align-self-center"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5 className="mt-0">
                      Número de permisos aplicables en definición
                    </h5>
                    <div className="d-flex justify-content-between">
                      <h2 className="text-purple">
                        {dashboard.permisos_posibles
                          ? dashboard.permisos_posibles
                          : 0}
                      </h2>
                      <i className="fa fa-lightbulb card-eco-icon bg-icon-purple align-self-center"></i>
                    </div>
                  </div>
                </div>
              </div>
              {/*
              <div className="col-lg-6">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5 className="mt-0">PRESUPUESTO MÍMINO ESTIMADO</h5>
                    <div className="d-flex justify-content-between">
                      <h2 className="text-purple">{dashboard.presupuesto_min ? dashboard.presupuesto_min : 'S/I'}</h2>
                      <i className="fa fa-dollar-sign card-eco-icon bg-icon-purple align-self-center"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5 className="mt-0">PRESUPUESTO MÁXIMO ESTIMADO</h5>
                    <div className="d-flex justify-content-between">
                      <h2 className="text-purple">{dashboard.presupuesto_max ? dashboard.presupuesto_max : 'S/I'}</h2>
                      <i className="fa fa-dollar-sign card-eco-icon bg-icon-purple align-self-center"></i>
                    </div>
                  </div>
                </div>
              </div>
              */}
              <div className="col-lg-6 col-sm-6">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5>
                      <i className="fa fa-chart-pie text-primary"></i> Gráfico
                      autoridades v/s permisos
                    </h5>
                    <div className="p-3">
                      <Chart
                        options={{
                          labels:
                            dashboard && dashboard.autoridades
                              ? dashboard.autoridades[1]
                              : [],
                          legend: legend,
                        }}
                        series={
                          dashboard && dashboard.autoridades
                            ? dashboard.autoridades[0]
                            : []
                        }
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5>
                      <i className="fa fa-chart-pie text-primary"></i> Gráfico
                      clasificación de permisos{" "}
                      <i
                        className="text-warning fa fa-exclamation-circle"
                        data-tip
                        data-tooltip-id="grafico_clasificacion"
                      ></i>
                    </h5>
                    <Tooltip id="grafico_clasificacion" aria-haspopup="true">
                      <ul>
                        <li>
                          <h6 className="mb-0">Permiso tipo A</h6>
                          <p className="font-10">
                            El trámite de obtención del permiso requiere:
                            <br />
                            1) Elaborar una carta solicitando la aprobación
                          </p>
                        </li>
                        <li>
                          <h6 className="mb-0">Permiso tipo B</h6>
                          <p className="font-10">
                            El trámite de obtención del permiso requiere:
                            <br />
                            1) Elaborar una carta solicitando la aprobación
                            <br />
                            2) Completar y acompañar formulario de solicitud
                            <br />
                            3) Elaborar y acompañar láminas, esquemas, planos"
                          </p>
                        </li>
                        <li>
                          <h6 className="mb-0">Permiso tipo C</h6>
                          <p className="font-10">
                            El trámite de obtención del permiso requiere:
                            <br />
                            1) Elaborar una carta solicitando la aprobación
                            <br />
                            2) Elaborar y acompañar memoria descriptiva
                            <br />
                            3) Elaborar y acompañar láminas, esquemas, planos
                          </p>
                        </li>
                        <li>
                          <h6 className="mb-0">Permiso tipo D</h6>
                          <p className="font-10">
                            1) Elaborar una carta solicitando la aprobación
                            <br />
                            2) Elaborar y acompañar memoria descriptiva
                            <br />
                            3) Elaborar y acompañar láminas, esquemas, planos
                            <br />
                            4) Elaborar y acompañar informes de especialidad
                            <br />
                            5) Elaborar y acompañar informe técnico conductor
                          </p>
                        </li>
                        <li>
                          <h6 className="mb-0">Permiso tipo E</h6>
                          <p className="font-10">
                            El trámite de obtención del permiso requiere:
                            <br />
                            1) Elaborar una carta solicitando la aprobación
                            <br />
                            2) Elaborar y acompañar memoria de cálculo
                            <br />
                            3) Elaborar y acompañar láminas, esquemas, planos
                            <br />
                            4) Elaborar y acompañar informes de especialidad
                            <br />
                            5) Elaborar y acompañar informe técnico conductor
                            <br />
                            6) Elaborar y acompañar especificaciones técnicas
                          </p>
                        </li>
                      </ul>
                    </Tooltip>
                    <div className="p-3">
                      <Chart
                        options={{
                          labels:
                            dashboard && dashboard.clasificaciones
                              ? dashboard.clasificaciones[1]
                              : [],
                          legend: legend,
                        }}
                        series={
                          dashboard && dashboard.clasificaciones
                            ? dashboard.clasificaciones[0]
                            : []
                        }
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5>
                      <i className="fa fa-chart-pie text-primary"></i> Gráfico
                      tipo de permisos
                    </h5>
                    <div className="p-3">
                      <Chart
                        options={{
                          labels:
                            dashboard && dashboard.tipos
                              ? dashboard.tipos[1]
                              : [],
                          legend: legend,
                        }}
                        series={
                          dashboard && dashboard.tipos ? dashboard.tipos[0] : []
                        }
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5>
                      <i className="fa fa-chart-pie text-primary"></i> Gráfico
                      costo de la gestión de permisos
                    </h5>
                    <div className="p-3">
                      <Chart
                        options={{
                          labels:
                            dashboard && dashboard.costos
                              ? dashboard.costos[1]
                              : [],
                          legend: legend,
                        }}
                        series={
                          dashboard && dashboard.costos
                            ? dashboard.costos[0]
                            : []
                        }
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5>
                      <i className="fa fa-chart-pie text-primary"></i> Gráfico
                      autoridades v/s permisos críticos
                    </h5>
                    <div className="p-3">
                      <Chart
                        options={{
                          labels:
                            dashboard && dashboard.autoridades_criticos
                              ? dashboard.autoridades_criticos[1]
                              : [],
                          legend: legend,
                        }}
                        series={
                          dashboard && dashboard.autoridades_criticos
                            ? dashboard.autoridades_criticos[0]
                            : []
                        }
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5>
                      <i className="fa fa-chart-pie text-primary"></i> Gráfico
                      etapas v/s permisos
                    </h5>
                    <div className="p-3">
                      <Chart
                        options={{
                          labels:
                            dashboard && dashboard.etapas
                              ? dashboard.etapas[1]
                              : [],
                          legend: legend,
                        }}
                        series={
                          dashboard && dashboard.etapas
                            ? dashboard.etapas[0]
                            : []
                        }
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5>
                      <i className="fa fa-chart-pie text-primary"></i> Gráfico
                      titularidad v/s permisos
                    </h5>
                    <div className="p-3">
                      <Chart
                        options={{
                          labels:
                            dashboard && dashboard.titularidades
                              ? dashboard.titularidades[1]
                              : [],
                          legend: legend,
                        }}
                        series={
                          dashboard && dashboard.titularidades
                            ? dashboard.titularidades[0]
                            : []
                        }
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6">
                <div className="card card-eco">
                  <div className="card-body">
                    <h5>
                      <i className="fa fa-chart-pie text-primary"></i> Gráfico
                      elemento regulado v/s permisos
                    </h5>
                    <div className="p-3">
                      <Chart
                        options={{
                          labels:
                            dashboard && dashboard.elementos_regulados
                              ? dashboard.elementos_regulados[1]
                              : [],
                          legend: legend,
                        }}
                        series={
                          dashboard && dashboard.elementos_regulados
                            ? dashboard.elementos_regulados[0]
                            : []
                        }
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Dashboard.propTypes = {
  obtenerDashboard: PropTypes.func.isRequired,
  dashboard: PropTypes.object.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  consulta_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  dashboard: state.permitting.dashboard,
  is_loading_permitting: state.permitting.is_loading_permitting,
  consulta_seleccionada: state.permitting.consulta_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerDashboard }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
