import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { withRouter } from "../../general/with_router";

import {
  crearAlerta,
  limpiarMensajes,
} from "../../../redux/actions/vigilancias/alertas/action";

import { Footer, Navbar, SidebarInicio } from "../../general";

export class Nueva extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modulo: "",
      expedientes_regiones: [],
      expedientes_tipologias: [],
      expedientes_tipos: [],
      expedientes_estados: [],
      hechos_categorias: [],
      hechos_regiones: [],
      pertinencias_regiones: [],
      pertinencias_tipologias: [],
    };
  }

  creacionAlerta = () => {
    const { crearAlerta } = this.props;
    const {
      modulo,
      expedientes_regiones,
      expedientes_tipologias,
      expedientes_tipos,
      expedientes_estados,
      hechos_categorias,
      hechos_regiones,
      pertinencias_regiones,
      pertinencias_tipologias,
    } = this.state;
    if (!modulo) {
      SweetAlert.fire({
        title: "Alerta",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Debes seleccionar el módulo para crear la alerta",
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    } else if (
      (modulo === "expedientes" && expedientes_regiones.length === 0) ||
      (modulo === "hechos" && hechos_regiones.length === 0) ||
      (modulo === "pertinencias" && pertinencias_regiones.length === 0)
    ) {
      SweetAlert.fire({
        title: "Alerta",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Debes seleccionar la región para crear la alerta",
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    } else if (
      modulo ||
      expedientes_regiones ||
      expedientes_tipologias ||
      expedientes_tipos ||
      expedientes_estados ||
      hechos_categorias ||
      hechos_regiones ||
      pertinencias_regiones ||
      pertinencias_tipologias
    ) {
      crearAlerta({
        modulo: modulo,
        expedientes_regiones: expedientes_regiones,
        expedientes_tipologias: expedientes_tipologias,
        expedientes_tipos: expedientes_tipos,
        expedientes_estados: expedientes_estados,
        hechos_categorias: hechos_categorias,
        hechos_regiones: hechos_regiones,
        pertinencias_regiones: pertinencias_regiones,
        pertinencias_tipologias: pertinencias_tipologias,
      });
      SweetAlert.fire({
        title: "Alerta",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos validando la información, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.fire({
        title: "Alerta",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Debes seleccionar los filtros para crear una alerta",
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "modulo") {
      this.setState({
        expedientes_regiones: [],
        expedientes_tipologias: [],
        expedientes_tipos: [],
        expedientes_estados: [],
        hechos_categorias: [],
        hechos_regiones: [],
        pertinencias_regiones: [],
        pertinencias_tipologias: [],
      });
    } else {
      var valor = e.target.value;
      if (
        (e.target.name === "expedientes_regiones" ||
          e.target.name === "expedientes_tipologias" ||
          e.target.name === "expedientes_tipos" ||
          e.target.name === "expedientes_estados" ||
          e.target.name === "hechos_categorias" ||
          e.target.name === "hechos_regiones" ||
          e.target.name === "pertinencias_regiones" ||
          e.target.name === "pertinencias_tipologias") &&
        valor !== ""
      ) {
        valor = Array.from(e.target.selectedOptions, (item) => item.value);
      }
      this.setState({ [e.target.name]: valor });
    }
  };

  render = () => {
    const {
      limpiarMensajes,
      alertas_filtros,
      alerta_crear,
      alerta_crear_error,
    } = this.props;

    if (
      !alertas_filtros ||
      !alertas_filtros.modulos ||
      alertas_filtros.modulos.length === 0
    ) {
      return <Navigate to="/compliance-analytics/alertas" />;
    }

    if (Object.entries(alerta_crear).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Alerta creada",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: alerta_crear.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.props.navigate("/compliance-analytics/alertas");
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(alerta_crear_error).length > 0) {
      SweetAlert.close();
      if (alerta_crear_error.data && alerta_crear_error.data.message) {
        SweetAlert.fire({
          title: "Alerta no creada",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: alerta_crear_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    const {
      modulo,
      expedientes_regiones,
      expedientes_tipologias,
      expedientes_tipos,
      expedientes_estados,
      hechos_categorias,
      hechos_regiones,
      pertinencias_regiones,
      pertinencias_tipologias,
    } = this.state;

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_alertas" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tower-broadcast"></i> ALERTAS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h5>NUEVA ALERTA</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-3 col-sm-4 col-6">
                        <div className="form-group">
                          <label>Selecciona un módulo</label>
                          <div className="input-group">
                            <select
                              name="modulo"
                              className="form-select"
                              onChange={this.onChange}
                              value={modulo}
                            >
                              <option value="">Selecciona una opción</option>
                              {alertas_filtros.modulos.map((option, x) => (
                                <option key={x} value={option.id}>
                                  {option.nombre}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-lg-2 col-sm-6 col-6 ${
                          modulo === "expedientes" ? "" : "d-none"
                        }`}
                      >
                        <div className="form-group">
                          <label>Selecciona una o más regiones</label>
                          <div className="input-group">
                            <select
                              multiple={true}
                              size="20"
                              name="expedientes_regiones"
                              className="form-select"
                              onChange={this.onChange}
                              value={expedientes_regiones}
                            >
                              {alertas_filtros.expedientes_regiones.map(
                                (option, x) => (
                                  <option key={x} value={option.id}>
                                    {option.nombre}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-lg-3 col-sm-6 col-6 ${
                          modulo === "expedientes" ? "" : "d-none"
                        }`}
                      >
                        <div className="form-group">
                          <label>Selecciona una o más tipologías</label>
                          <div className="input-group">
                            <select
                              multiple={true}
                              size="20"
                              name="expedientes_tipologias"
                              className="form-select"
                              onChange={this.onChange}
                              value={expedientes_tipologias}
                            >
                              {alertas_filtros.expedientes_tipologias.map(
                                (option, x) => (
                                  <option key={x} value={option.id}>
                                    {option.nombre}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-lg-2 col-sm-6 col-6 ${
                          modulo === "expedientes" ? "" : "d-none"
                        }`}
                      >
                        <div className="form-group">
                          <label>Selecciona uno o más tipos</label>
                          <div className="input-group">
                            <select
                              multiple={true}
                              size="20"
                              name="expedientes_tipos"
                              className="form-select"
                              onChange={this.onChange}
                              value={expedientes_tipos}
                            >
                              {alertas_filtros.expedientes_tipos.map(
                                (option, x) => (
                                  <option key={x} value={option.id}>
                                    {option.nombre}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-lg-2 col-sm-6 col-6 ${
                          modulo === "expedientes" ? "" : "d-none"
                        }`}
                      >
                        <div className="form-group">
                          <label>Selecciona uno o más estados</label>
                          <div className="input-group">
                            <select
                              multiple={true}
                              size="20"
                              name="expedientes_estados"
                              className="form-select"
                              onChange={this.onChange}
                              value={expedientes_estados}
                            >
                              {alertas_filtros.expedientes_estados.map(
                                (option, x) => (
                                  <option key={x} value={option.id}>
                                    {option.nombre}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-lg-3 col-sm-4 col-6 ${
                          modulo === "hechos" ? "" : "d-none"
                        }`}
                      >
                        <div className="form-group">
                          <label>Selecciona una categoría</label>
                          <div className="input-group">
                            <select
                              multiple={true}
                              size="20"
                              name="hechos_categorias"
                              className="form-select"
                              onChange={this.onChange}
                              value={hechos_categorias}
                            >
                              {alertas_filtros.hechos_categorias.map(
                                (option, x) => (
                                  <option key={x} value={option.id}>
                                    {option.nombre}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-lg-3 col-sm-4 col-6 ${
                          modulo === "hechos" ? "" : "d-none"
                        }`}
                      >
                        <div className="form-group">
                          <label>Selecciona una región</label>
                          <div className="input-group">
                            <select
                              multiple={true}
                              size="20"
                              name="hechos_regiones"
                              className="form-select"
                              onChange={this.onChange}
                              value={hechos_regiones}
                            >
                              {alertas_filtros.hechos_regiones.map(
                                (option, x) => (
                                  <option key={x} value={option.id}>
                                    {option.nombre}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-lg-3 col-sm-4 col-6 ${
                          modulo === "pertinencias" ? "" : "d-none"
                        }`}
                      >
                        <div className="form-group">
                          <label>Selecciona una región</label>
                          <div className="input-group">
                            <select
                              multiple={true}
                              size="20"
                              name="pertinencias_regiones"
                              className="form-select"
                              onChange={this.onChange}
                              value={pertinencias_regiones}
                            >
                              {alertas_filtros.pertinencias_regiones.map(
                                (option, x) => (
                                  <option key={x} value={option.id}>
                                    {option.nombre}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-lg-3 col-sm-4 col-6 ${
                          modulo === "pertinencias" ? "" : "d-none"
                        }`}
                      >
                        <div className="form-group">
                          <label>Selecciona una tipología</label>
                          <div className="input-group">
                            <select
                              multiple={true}
                              size="20"
                              name="pertinencias_tipologias"
                              className="form-select"
                              onChange={this.onChange}
                              value={pertinencias_tipologias}
                            >
                              {alertas_filtros.pertinencias_tipologias.map(
                                (option, x) => (
                                  <option key={x} value={option.id}>
                                    {option.nombre}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Link
                        className="btn btn-danger"
                        to="/compliance-analytics/alertas"
                      >
                        <i className="fa fa-trash"></i> Cancelar
                      </Link>
                      <button
                        className="btn btn-primary"
                        onClick={this.creacionAlerta}
                      >
                        <i className="fa fa-save"></i> Crear nueva alerta
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Nueva.propTypes = {
  crearAlerta: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  alerta_crear: PropTypes.object.isRequired,
  alerta_crear_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  alertas_filtros: state.vigilancias.alertas_filtros,
  alerta_crear: state.vigilancias.alerta_crear,
  alerta_crear_error: state.vigilancias.alerta_crear_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ crearAlerta, limpiarMensajes }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nueva));
