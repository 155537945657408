import axios from "axios";

import { tokenConfig } from "../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../auth/types";
import {
  ARTICULO_TAREA_SELECCIONADO,
  CARGANDO_LIMPIAR_LEYES,
  LIMPIAR_NORMAS_FILTROS,
  MENSAJES_LIMPIAR,
  NORMAS_ESTADISTICAS_FALLIDO,
  NORMAS_ESTADISTICAS_OBTENER,
  NORMAS_FALLIDO,
  NORMAS_FAVORITAS_FALLIDO,
  NORMAS_FAVORITAS_OBTENER,
  NORMAS_FILTROS_FALLIDO,
  NORMAS_FILTROS_INPUT,
  NORMAS_FILTROS_OBTENER,
  NORMAS_OBTENER,
  NORMAS_PROMULGADAS_FALLIDO,
  NORMAS_PROMULGADAS_OBTENER,
  NORMAS_PUBLICADAS_FALLIDO,
  NORMAS_PUBLICADAS_OBTENER,
  NORMA_ALERTAS_FALLIDO,
  NORMA_ALERTAS_OBTENER,
  NORMA_ALERTA_CREAR_FALLIDO,
  NORMA_ALERTA_CREAR_OBTENER,
  NORMA_ALERTA_ELIMINAR_FALLIDO,
  NORMA_ALERTA_ELIMINAR_OBTENER,
  NORMA_FALLIDO,
  NORMA_FAVORITA_CREAR_FALLIDO,
  NORMA_FAVORITA_CREAR_OBTENER,
  NORMA_FAVORITA_ELIMINAR_FALLIDO,
  NORMA_FAVORITA_ELIMINAR_OBTENER,
  NORMA_GUARDAR,
  NORMA_OBTENER,
  REDIRECT_FAV,
  TITULO_SELECCIONADO,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerAlertas = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_LEYES });
  axios
    .get(`${url_api}/api/laws/alertas/${pagina}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: NORMA_ALERTAS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMA_ALERTAS_FALLIDO,
        payload: error,
      });
    });
};

export const eliminarAlerta = (data) => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/laws/alerta`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: NORMA_ALERTA_ELIMINAR_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMA_ALERTA_ELIMINAR_FALLIDO,
        payload: error,
      });
    });
};

export const crearAlerta = (data) => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/laws/alerta`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: NORMA_ALERTA_CREAR_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMA_ALERTA_CREAR_FALLIDO,
        payload: error,
      });
    });
};

export const guardarNorma = (id, tipo) => (dispatch) => {
  dispatch({ type: NORMA_GUARDAR, payload: id });
  dispatch({
    type: REDIRECT_FAV,
    payload: tipo === "favoritos" ? true : false,
  });
};

export const obtenerNormasPromulgadas = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_LEYES });
  axios
    .post(
      `${url_api}/api/laws/ultimas/${pagina}`,
      { tipo: "promulgadas" },
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: NORMAS_PROMULGADAS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMAS_PROMULGADAS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerNormasPublicadas = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_LEYES });
  axios
    .post(
      `${url_api}/api/laws/ultimas/${pagina}`,
      { tipo: "publicadas" },
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: NORMAS_PUBLICADAS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMAS_PUBLICADAS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerNormas = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_LEYES });
  axios
    .get(`${url_api}/api/laws/normas/${pagina}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: NORMAS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMAS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerNorma = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_LEYES });
  axios
    .post(`${url_api}/api/laws/norma`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: NORMA_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMA_FALLIDO,
        payload: error,
      });
    });
};

export const limpiarMensajes = () => (dispatch) => {
  dispatch({ type: MENSAJES_LIMPIAR });
};

export const agregarLeyUsuario = (data) => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/laws/favorito`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: NORMA_FAVORITA_CREAR_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMA_FAVORITA_CREAR_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerFavoritos = () => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_LEYES });
  axios
    .get(`${url_api}/api/laws/favoritos`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: NORMAS_FAVORITAS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMAS_FAVORITAS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerEstadistica = () => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_LEYES });
  axios
    .get(`${url_api}/api/laws/estadisticas`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: NORMAS_ESTADISTICAS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMAS_ESTADISTICAS_FALLIDO,
        payload: error,
      });
    });
};

export const loadingTrue = () => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_LEYES });
};

export const EliminacionFavorito = (data) => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/laws/favorito`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: NORMA_FAVORITA_ELIMINAR_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMA_FAVORITA_ELIMINAR_FALLIDO,
        payload: error,
      });
    });
};

export const guardarTitulo = (titulo) => (dispatch) => {
  dispatch({ type: TITULO_SELECCIONADO, payload: titulo });
};

export const setArticuloTarea = (articulo) => (dispatch) => {
  dispatch({ type: ARTICULO_TAREA_SELECCIONADO, payload: articulo });
};

export const limpiarFiltroNormas = (articulo) => (dispatch) => {
  dispatch({ type: LIMPIAR_NORMAS_FILTROS });
};

export const obtenerNormasFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_LEYES });
  axios
    .post(
      `${url_api}/api/laws/normas/${data.pagina}`,
      data.filtros,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: NORMAS_FILTROS_INPUT,
        payload: data.filtros,
      });
      dispatch({
        type: NORMAS_FILTROS_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NORMAS_FILTROS_FALLIDO,
        payload: error,
      });
    });
};
