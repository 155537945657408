import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { guardarFiscalizacion } from "../../../redux/actions/vigilancias/fiscalizaciones/action";
import { Eliminar as EliminarFavorito } from "../favoritos";
import { Tabla as InstrumentosFiscalizados } from "../instrumentos_fiscalizados";
import { TablaPequena as UnidadesFiscalizables } from "../unidades";

export class Tabla extends Component {
  setFiscalizacion = (id) => {
    const { guardarFiscalizacion } = this.props;
    guardarFiscalizacion(id);
  };

  render = () => {
    const { fiscalizaciones, eliminar_favorito } = this.props;

    return (
      <div className="table-responsive">
        <Tooltip id="detalle_instrumento" aria-haspopup="true">
          Ver detalle de la fiscalización
        </Tooltip>
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th className="w-13">Expediente</th>
              <th className="w-20">Unidad Fiscalizable</th>
              <th>Instrumentos Fiscalizados</th>
              <th className="w-7">Estado</th>
              <th className="w-3">Año</th>
              <th className="text-center w-3">Acción</th>
            </tr>
          </thead>
          <tbody>
            {fiscalizaciones &&
            fiscalizaciones.result &&
            fiscalizaciones.result.length > 0 ? (
              fiscalizaciones.result.map((fisca, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {fiscalizaciones.page > 1
                      ? (fiscalizaciones.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td>
                    <a
                      href={fisca.url_snifa}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {fisca.expediente} <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td>
                    <UnidadesFiscalizables
                      unidades_fiscalizables={
                        fisca.unidad_fiscalizable
                          ? [fisca.unidad_fiscalizable]
                          : []
                      }
                    />
                  </td>
                  <td>
                    <InstrumentosFiscalizados
                      instrumentos={fisca.instrumentos}
                      bg="dark"
                    />
                  </td>
                  <td className="text-center">
                    <span className="badge badge-dark font-13 text-center">
                      {fisca.estado}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="badge badge-warning font-13 text-center">
                      {fisca.anio}
                    </span>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {eliminar_favorito && (
                        <EliminarFavorito tipo="fiscalizacion" id={fisca.id} />
                      )}
                      <Link
                        data-tip
                        data-tooltip-id="detalle_instrumento"
                        to="/compliance-analytics/fiscalizacion"
                        className="btn btn-soft-dark"
                        onClick={this.setFiscalizacion.bind(this, fisca.id)}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="7">
                  No existen fiscalizaciones
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarFiscalizacion: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarFiscalizacion }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
