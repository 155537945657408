import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  obtenerEncargadoFiltros,
  obtenerEncargados,
} from "../../../redux/actions/vigilancias/encargados/action";

export class Filtros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre: "",
      filtro_email: "",
    };
  }

  filtroEncargados = () => {
    const { obtenerEncargadoFiltros, obtenerEncargados } = this.props;
    const { filtro_nombre, filtro_email } = this.state;

    if (filtro_nombre || filtro_email) {
      obtenerEncargadoFiltros({
        nombre: filtro_nombre ? filtro_nombre : null,
        email: filtro_email ? filtro_email : null,
      });
    } else {
      obtenerEncargados(1);
    }
  };

  borrarFiltros = () => {
    const { obtenerEncargados } = this.props;
    obtenerEncargados(1);
    this.setState({
      filtro_nombre: "",
      filtro_email: "",
    });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { filtros_errores } = this.props;
    const { filtro_nombre, filtro_email } = this.state;
    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5>FILTROS</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Nombre</label>
                <input
                  type="text"
                  name="filtro_nombre"
                  onChange={this.onChange}
                  value={filtro_nombre}
                  placeholder="Ingrese nombre del encargado"
                  className={`form-control ${
                    filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.nombre &&
                  filtros_errores.data.nombre.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  name="filtro_email"
                  onChange={this.onChange}
                  value={filtro_email}
                  placeholder="Ingrese email del encargado"
                  className={`form-control ${
                    filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.email
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.email &&
                  filtros_errores.data.email.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroEncargados}
            >
              <i className="fa fa-search"></i> Filtrar encargado
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Filtros.propTypes = {
  obtenerEncargados: PropTypes.func.isRequired,
  obtenerEncargadoFiltros: PropTypes.func.isRequired,
  filtros_errores: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_errores: state.vigilancias.filtros_errores,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerEncargados, obtenerEncargadoFiltros },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
