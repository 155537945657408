import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerPermisosRequisitos } from "../../../../redux/actions/permitting/action";
import {
  Footer,
  Navbar,
  SiderbarPermittingAplicabilidad,
} from "../../../general";

export class Requisitos extends Component {
  componentDidMount = () => {
    const {
      obtenerPermisosRequisitos,
      consulta_seleccionada,
      permisos_requisitos,
    } = this.props;
    if (consulta_seleccionada > 0 && permisos_requisitos.length === 0) {
      obtenerPermisosRequisitos({
        consulta: consulta_seleccionada,
      });
    }
  };

  render = () => {
    const {
      is_loading_permitting,
      consulta_seleccionada,
      // permisos_requisitos,
    } = this.props;

    // _.forEach(permisos_requisitos, function (permiso) {
    //   console.log(permiso);
    // });

    if (consulta_seleccionada === 0) {
      return <Navigate to="/permitting-target/proyectos" />;
    }

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Permisos Requeridos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo la información, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SiderbarPermittingAplicabilidad menu_1="permitting_requisitos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h4 className="float-start">
                      PERMISOS REQUISITOS <small>(timeline)</small>
                    </h4>
                    <p className="float-end mt-2">
                      <Link
                        className="btn btn-dark btn-xs"
                        to="/permitting-target/aplicabilidad/requisitos"
                      >
                        Ver Listado <i className="fa fa-angle-right"></i>
                      </Link>
                    </p>
                  </div>
                  <div className="card-body"></div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Requisitos.propTypes = {
  obtenerPermisosRequisitos: PropTypes.func.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  consulta_seleccionada: PropTypes.number.isRequired,
  permisos_requisitos: PropTypes.array.isRequired,
  permisos_requisitos_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_permitting: state.permitting.is_loading_permitting,
  consulta_seleccionada: state.permitting.consulta_seleccionada,
  permisos_requisitos: state.permitting.permisos_requisitos,
  permisos_requisitos_error: state.permitting.permisos_requisitos_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerPermisosRequisitos }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Requisitos);
