import React, { Component } from "react";

import currencyformatter from "currencyformatter.js";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerExpedientesEstadistica } from "../../../redux/actions/vigilancias/expedientes/action";
import { Chart, Footer, Navbar, SidebarInicio } from "../../general";

export class Listado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ver_expedientes: true,
      ver_mapa: false,
    };
  }

  componentDidMount = () => {
    const { obtenerExpedientesEstadistica, expedientes_estadisticas } =
      this.props;
    if (Object.entries(expedientes_estadisticas).length === 0) {
      obtenerExpedientesEstadistica(1);
    }
  };

  render = () => {
    const { expedientes_estadisticas, is_loading_vigilancias } = this.props;
    var regiones = _.countBy(
      _.orderBy(expedientes_estadisticas.result, "region_display"),
      "region_display"
    );
    var tipos = _.countBy(
      _.orderBy(expedientes_estadisticas.result, "tipo"),
      "tipo"
    );
    var fechas_ingreso = _.countBy(
      _.orderBy(expedientes_estadisticas.result, [
        "fecha_format.anio",
        "fecha_format.mes_numero",
      ]),
      "fecha_format.anio_mes"
    );
    var inversion = _.sumBy(expedientes_estadisticas.result, "inversion");
    var total_inversion =
      inversion > 0
        ? currencyformatter.format(inversion, {
            currency: "CLP",
            locale: "es_CL",
          })
        : "$0";
    var fecha_min = _.minBy(expedientes_estadisticas.result, "fecha");
    var fecha_max = _.maxBy(expedientes_estadisticas.result, "fecha");

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "SEIA",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los expedientes seia, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_expedientes" menu_2="estadistica" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-seedling"></i> EXPEDIENTES SEIA{" "}
                    <small>
                      (
                      {expedientes_estadisticas &&
                        expedientes_estadisticas.total &&
                        expedientes_estadisticas.total}
                      )
                    </small>
                  </h4>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">Proyectos</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-purple">
                            {expedientes_estadisticas &&
                            expedientes_estadisticas.result &&
                            expedientes_estadisticas.result.length > 0
                              ? expedientes_estadisticas.result.length
                              : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">Tipo DIA</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-purple">
                            <span className="me-5">
                              {tipos.DIA ? tipos && tipos.DIA : 0}
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">Tipo EIA</h5>
                        <div className="d-flex justify-content-between">
                          <h2 className="text-purple">
                            <span className="me-5">
                              {tipos.EIA ? tipos && tipos.EIA : 0}
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="card card-eco">
                      <div className="card-body">
                        <h5 className="mt-0">
                          Inversión total{" "}
                          <span className="small">(millones de dólares)</span>
                        </h5>
                        <div className="d-flex justify-content-between">
                          {fecha_min && fecha_max && (
                            <p className="small mt-0">
                              Desde {fecha_min.fecha} / Hasta {fecha_max.fecha}
                            </p>
                          )}
                          <h2 className="text-purple">
                            {total_inversion}
                            <small className="ms-1">mm</small>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <Chart
                      data={tipos}
                      nombre="Expedientes SEIA"
                      titulo="Expedientes SEIA por tipos"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8">
                <Chart
                  is_vertical
                  data={regiones}
                  nombre="Expedientes SEIA"
                  titulo="Expedientes SEIA por región"
                />
              </div>
              <div className="col-lg-12 col-sm-12">
                <Chart
                  is_vertical
                  data={fechas_ingreso}
                  nombre="Expedientes SEIA"
                  titulo="Expedientes SEIA por mes"
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerExpedientesEstadistica: PropTypes.func.isRequired,
  expedientes_estadisticas: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  expedientes_estadisticas: state.vigilancias.expedientes_estadisticas,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerExpedientesEstadistica }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
