import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  LIMPIAR_RCAS_FILTROS,
  RCA_FALLIDO,
  RCA_GUARDAR,
  RCA_OBTENER,
  RCAS_ESTADISTICAS_FALLIDO,
  RCAS_ESTADISTICAS_OBTENER,
  RCAS_FALLIDO,
  RCAS_FILTROS_FALLIDO,
  RCAS_FILTROS_INPUT,
  RCAS_FILTROS_OBTENER,
  RCAS_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerRcasEstadistica = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/rcas/estadisticas/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: RCAS_ESTADISTICAS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : RCAS_ESTADISTICAS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerRcas = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  dispatch({ type: LIMPIAR_RCAS_FILTROS });
  axios
    .get(`${url_api}/api/vigilances/rcas/${pagina}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: RCAS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : RCAS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerRcasFiltro = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/rcas/filtros/${data.pagina}`,
      data.filtros,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: RCAS_FILTROS_INPUT, payload: data.filtros });
      dispatch({ type: RCAS_FILTROS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : RCAS_FILTROS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerRca = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(`${url_api}/api/vigilances/rca`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: RCA_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401 ? AUTENTIFICACION_FALLIDO : RCA_FALLIDO,
        payload: error,
      });
    });
};

export const guardarRca = (id) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  dispatch({ type: RCA_GUARDAR, payload: id });
};
