import React, { Component } from "react";

export class TablaPequena extends Component {
  render = () => {
    const { titulares } = this.props;

    if (titulares && titulares.length > 1) {
      return titulares.length < 1 ? (
        <span className="badge badge-soft-dark">Sin Información</span>
      ) : (
        <ul
          className={`ml--10 mb-0 ${
            titulares.length > 8 ? "vh-30 overflow-auto" : ""
          }`}
        >
          {titulares.map((titular, x) => (
            <li key={x} className="mb-1">
              {titular && titular.rut && (
                <>
                  <span className="text-uppercase badge badge-soft-blue me-2">
                    {titular.rut}
                  </span>
                </>
              )}
              {titular && titular.nombre && (
                <span className="text-uppercase">{titular.nombre}</span>
              )}
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <div>
          {titulares && titulares.length > 0 ? (
            titulares.map((titular, x) => (
              <div key={x}>
                {titular && titular.rut && (
                  <>
                    <span className="text-uppercase badge badge-soft-blue me-2 ms-3">
                      {titular.rut}
                    </span>
                  </>
                )}
                {titular && titular.nombre && (
                  <span className="text-uppercase">{titular.nombre}</span>
                )}
              </div>
            ))
          ) : (
            <span className="badge badge-soft-dark">Sin Información</span>
          )}
        </div>
      );
    }
  };
}

export default TablaPequena;
