export const PROYECTOS_TAREAS_OBTENER = 'PROYECTOS_TAREAS_OBTENER';
export const PROYECTOS_TAREAS_FALLIDO = 'PROYECTOS_TAREAS_FALLIDO';
export const TAREA_SELECCIONADA = 'TAREA_SELECCIONADA';
export const TAREA_OBTENER = 'TAREA_OBTENER';
export const TAREA_FALLIDO = 'TAREA_FALLIDO';
export const LOADING_TRUE = 'LOADING_TRUE';
export const EQUIPO_NUEVO_OBTENER = 'EQUIPO_NUEVO_OBTENER';
export const EQUIPO_NUEVO_FALLIDO = 'EQUIPO_NUEVO_FALLIDO';
export const MENSAJES_LIMPIAR = 'MENSAJES_LIMPIAR';
export const EQUIPO_ELIMINAR_OBTENER = 'EQUIPO_ELIMINAR_OBTENER';
export const EQUIPO_ELIMINAR_FALLIDO = 'EQUIPO_ELIMINAR_FALLIDO';
export const TAREA_ESTADO_OBTENER = 'TAREA_ESTADO_OBTENER';
export const TAREA_ESTADO_FALLIDO = 'TAREA_ESTADO_FALLIDO';
export const ENTREGABLE_EDITAR_OBTENER = 'ENTREGABLE_EDITAR_OBTENER';
export const ENTREGABLE_EDITAR_FALLIDO = 'ENTREGABLE_EDITAR_FALLIDO';
export const ENTREGABLE_ELIMINAR_FALLIDO = 'ENTREGABLE_ELIMINAR_FALLIDO';
export const ENTREGABLE_ELIMINAR_OBTENER = 'ENTREGABLE_ELIMINAR_OBTENER';
export const ENTREGABLE_NUEVO_OBTENER = 'ENTREGABLE_NUEVO_OBTENER';
export const ENTREGABLE_NUEVO_FALLIDO = 'ENTREGABLE_NUEVO_FALLIDO';
export const REQUISITO_NUEVO_FALLIDO = 'REQUISITO_NUEVO_FALLIDO';
export const REQUISITO_NUEVO_OBTENER = 'REQUISITO_NUEVO_OBTENER';
export const REQUISITO_ELIMINAR_OBTENER = 'REQUISITO_ELIMINAR_OBTENER';
export const REQUISITO_ELIMINAR_FALLIDO = 'REQUISITO_ELIMINAR_FALLIDO';
export const ARCHIVO_ESTADO_FALLIDO = 'ARCHIVO_ESTADO_FALLIDO';
export const ARCHIVO_ESTADO_OBTENER = 'ARCHIVO_ESTADO_OBTENER';
export const TAREA_ELIMINAR_OBTENER = 'TAREA_ELIMINAR_OBTENER';
export const TAREA_ELIMINAR_FALLIDO = 'TAREA_ELIMINAR_FALLIDO';
export const TAREA_NUEVA_OBTENER = 'TAREA_NUEVA_OBTENER';
export const TAREA_NUEVA_FALLIDO = 'TAREA_NUEVA_FALLIDO';
export const TAREA_EDITAR_FALLIDO = 'TAREA_EDITAR_FALLIDO';
export const TAREA_EDITAR_OBTENER = 'TAREA_EDITAR_OBTENER';
export const CARGA_NUEVO_OBTENER = 'CARGA_NUEVO_OBTENER';
export const CARGA_NUEVO_FALLIDO = 'CARGA_NUEVO_FALLIDO';
export const RECURSO_NUEVO_OBTENER = 'RECURSO_NUEVO_OBTENER';
export const RECURSO_NUEVO_FALLIDO = 'RECURSO_NUEVO_FALLIDO';
export const ARCHIVO_NUEVO_OBTENER = 'ARCHIVO_NUEVO_OBTENER';
export const ARCHIVO_NUEVO_FALLIDO = 'ARCHIVO_NUEVO_FALLIDO';
export const ARCHIVO_ELIMINAR_OBTENER = 'ARCHIVO_ELIMINAR_OBTENER';
export const ARCHIVO_ELIMINAR_FALLIDO = 'ARCHIVO_ELIMINAR_FALLIDO';
export const ENTREGABLE_DUPLICAR_OBTENER = 'ENTREGABLE_DUPLICAR_OBTENER';
export const ENTREGABLE_DUPLICAR_FALLIDO = 'ENTREGABLE_DUPLICAR_FALLIDO';
export const TAREA_RESPONSABLE_OBTENER = 'TAREA_RESPONSABLE_OBTENER';
export const TAREA_RESPONSABLE_FALLIDO = 'TAREA_RESPONSABLE_FALLIDO';
export const CARGANDO_LIMPIAR_TAREAS = 'CARGANDO_LIMPIAR_TAREAS';
export const ENTREGABLE_RESPONSABLE_OBTENER = 'ENTREGABLE_RESPONSABLE_OBTENER';
export const ENTREGABLE_RESPONSABLE_FALLIDO = 'ENTREGABLE_RESPONSABLE_FALLIDO';
export const ENTREGABLE_ESTADO_OBTENER = 'ENTREGABLE_ESTADO_OBTENER';
export const ENTREGABLE_ESTADO_FALLIDO = 'ENTREGABLE_ESTADO_FALLIDO';
