import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  ALERTAS_FALLIDO,
  ALERTAS_OBTENER,
  ALERTA_CREAR_FALLIDO,
  ALERTA_CREAR_OBTENER,
  ALERTA_ELIMINAR_FALLIDO,
  ALERTA_ELIMINAR_OBTENER,
  MENSAJES_LIMPIAR_ALERTAS,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerAlertas = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(`${url_api}/api/vigilances/alertas/${pagina}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ALERTAS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : ALERTAS_FALLIDO,
        payload: error,
      });
    });
};

export const crearAlerta = (data) => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/vigilances/alerta/crear`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ALERTA_CREAR_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : ALERTA_CREAR_FALLIDO,
        payload: error,
      });
    });
};

export const limpiarMensajes = () => (dispatch) => {
  dispatch({
    type: MENSAJES_LIMPIAR_ALERTAS,
  });
};

export const eliminarAlerta = (data) => (dispatch, getState) => {
  axios
    .put(
      `${url_api}/api/vigilances/alerta/eliminar`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: ALERTA_ELIMINAR_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : ALERTA_ELIMINAR_FALLIDO,
        payload: error,
      });
    });
};
