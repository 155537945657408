export const MEDIDAS_PROVISIONALES_OBTENER = 'MEDIDAS_PROVISIONALES_OBTENER';
export const MEDIDAS_PROVISIONALES_FALLIDO = 'MEDIDAS_PROVISIONALES_FALLIDO';
export const MEDIDA_PROVISIONAL_GUARDAR = 'MEDIDA_PROVISIONAL_GUARDAR';
export const MEDIDA_PROVISIONAL_FALLIDO = 'MEDIDA_PROVISIONAL_FALLIDO';
export const MEDIDA_PROVISIONAL_OBTENER = 'MEDIDA_PROVISIONAL_OBTENER';
export const MEDIDAS_PROVISIONALES_FILTROS_OBTENER = 'MEDIDAS_PROVISIONALES_FILTROS_OBTENER';
export const MEDIDAS_PROVISIONALES_FILTROS_FALLIDO = 'MEDIDAS_PROVISIONALES_FILTROS_FALLIDO';
export const MEDIDAS_PROVISIONALES_FILTROS_INPUT = 'MEDIDAS_PROVISIONALES_FILTROS_INPUT';
export const LIMPIAR_MEDIDAS_FILTROS = 'LIMPIAR_MEDIDAS_FILTROS';
export const MEDIDAS_PROVISIONALES_ESTADISTICAS_OBTENER = 'MEDIDAS_PROVISIONALES_ESTADISTICAS_OBTENER';
export const MEDIDAS_PROVISIONALES_ESTADISTICAS_FALLIDO = 'MEDIDAS_PROVISIONALES_ESTADISTICAS_FALLIDO';
