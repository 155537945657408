import React, { Component } from "react";

import { Link } from "react-router-dom";

export class Inicio extends Component {
  render = () => {
    const { menu_1, laws, sub_laws } = this.props;
    return (
      laws && (
        <>
          <h5 className="mt-3">LEGAL TRACKING</h5>
          <li className="mm-active-link">
            <a
              href="https://web.cumplimientolean.com/software-legal-tracking"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-laptop"></i>
              <span> Centro de Descubrimiento</span>
            </a>
          </li>
          <li className="mm-active-link">
            <a
              href="https://cumplimientolean.notion.site/Tutoriales-Software-Legal-Tracking-4f3ddd2ed840498eb95de19ce218fdcc?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-video"></i>
              <span> Tutoriales</span>
            </a>
          </li>
          {sub_laws.normas_leychile_alertas && (
            <li
              className={
                menu_1 && menu_1 === "leyes_alertas"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link to="/legal-tracking/alertas">
                <i className="fa fa-tower-broadcast"></i>
                <span> Alertas</span>
              </Link>
            </li>
          )}
          {sub_laws.normas_leychile && (
            <>
              <li
                className={
                  menu_1 && menu_1 === "leyes_favoritos"
                    ? "mm-active"
                    : "mm-inactivo"
                }
              >
                <Link to="/legal-tracking/favoritos">
                  <i className="fa fa-heart"></i>
                  <span> Mis Favoritos</span>
                </Link>
              </li>
              <li
                className={
                  menu_1 && menu_1 === "leyes_normas"
                    ? "mm-active"
                    : "mm-inactivo"
                }
              >
                <Link to="/legal-tracking/normas">
                  <i className="fa fa-gavel"></i>
                  <span> Normas</span>
                </Link>
              </li>
              <li
                className={
                  menu_1 && menu_1 === "leyes_publicadas"
                    ? "mm-active"
                    : "mm-inactivo"
                }
              >
                <Link to="/legal-tracking/normas/publicadas">
                  <i className="fa fa-clock"></i>
                  <span> Normas Publicadas</span>
                </Link>
              </li>
              <li
                className={
                  menu_1 && menu_1 === "leyes_promulgadas"
                    ? "mm-active"
                    : "mm-inactivo"
                }
              >
                <Link to="/legal-tracking/normas/promulgadas">
                  <i className="fa fa-clock"></i>
                  <span> Normas Promulgadas</span>
                </Link>
              </li>
            </>
          )}
          {sub_laws.expedientes_mma && (
            <li
              className={
                menu_1 && menu_1 === "mma_expedientes"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link to="/legal-tracking/mma/expedientes">
                <i className="fa fa-envelope-open-text"></i>
                <span> Expedientes MMA</span>
              </Link>
            </li>
          )}
        </>
      )
    );
  };
}

export default Inicio;
