import React, { Component } from "react";
// import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import { format as formatRut } from "rut.js";
import {
  limpiarFiltroDocumentos,
  obtenerDocumentos,
  obtenerDocumentosFiltros,
} from "../../../redux/actions/vigilancias/documentos/action";

export class Documentos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre:
        this.props.documentos_filtro_input &&
        this.props.documentos_filtro_input.nombre
          ? this.props.documentos_filtro_input.nombre
          : "",
      filtro_tipo:
        this.props.documentos_filtro_input &&
        this.props.documentos_filtro_input.tipo
          ? this.props.documentos_filtro_input.tipo
          : "",
      filtro_fuente:
        this.props.documentos_filtro_input &&
        this.props.documentos_filtro_input.fuente
          ? this.props.documentos_filtro_input.fuente
          : "",
      // filtro_titular_rut: this.props.documentos_filtro_input && this.props.documentos_filtro_input.titular_rut ? this.props.documentos_filtro_input.titular_rut : '',
      // filtro_titular_nombre: this.props.documentos_filtro_input && this.props.documentos_filtro_input.titular_nombre ? this.props.documentos_filtro_input.titular_nombre : '',
      // filtro_categoria: this.props.documentos_filtro_input && this.props.documentos_filtro_input.categoria ? this.props.documentos_filtro_input.categoria : '',
      filtros_avanzandos:
        this.props.documentos_filtro_input &&
        this.props.documentos_filtro_input.filtros_avanzandos
          ? this.props.documentos_filtro_input.filtros_avanzandos
          : false,
    };
  }

  borrarFiltros = () => {
    const { obtenerDocumentos, limpiarFiltroDocumentos } = this.props;
    obtenerDocumentos(1);
    limpiarFiltroDocumentos();
    this.setState({
      filtro_nombre: "",
      filtro_tipo: "",
      filtro_fuente: "",
      // filtro_titular_rut: '',
      // filtro_titular_nombre: '',
      // filtro_categoria: '',
    });
  };

  filtroDocumentos = () => {
    const { obtenerDocumentosFiltros, obtenerDocumentos } = this.props;
    const { filtro_nombre, filtro_tipo, filtro_fuente, filtros_avanzandos } =
      this.state;

    if (filtro_nombre || filtro_tipo || filtro_fuente) {
      var filtros = {
        nombre: filtro_nombre ? filtro_nombre : null,
        tipo: filtro_tipo ? filtro_tipo : null,
        fuente: filtro_fuente ? filtro_fuente : null,
        // titular_rut: filtro_titular_rut ? filtro_titular_rut : null,
        // titular_nombre: filtro_titular_nombre ? filtro_titular_nombre : null,
        // categoria: filtro_categoria ? filtro_categoria : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerDocumentosFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerDocumentos(1);
    }
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (e.target.name === "filtro_titular_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  render = () => {
    const { filtros_documentos, filtros_errores } = this.props;
    const { filtro_nombre, filtro_tipo, filtro_fuente, filtros_avanzandos } =
      this.state;

    // const categorias_listado = filtros_documentos && filtros_documentos['categorias'] ? _.orderBy(filtros_documentos['categorias'], 'nombre') : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Nombre del documento</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_nombre"
                    value={filtro_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese una palabra"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.nombre &&
                    filtros_errores.data.nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipo</label>
                <SelectPicker
                  name="filtro_tipo"
                  data={
                    filtros_documentos.tipos ? filtros_documentos.tipos : []
                  }
                  onChange={(value) => {
                    this.setState({ filtro_tipo: value });
                  }}
                  value={filtro_tipo}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipo &&
                  filtros_errores.data.tipo.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fuente</label>
                <SelectPicker
                  name="filtro_fuente"
                  data={
                    filtros_documentos.fuentes ? filtros_documentos.fuentes : []
                  }
                  onChange={(value) => {
                    this.setState({ filtro_fuente: value });
                  }}
                  value={filtro_fuente}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.fuente &&
                  filtros_errores.data.fuente.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            {/*
            <div className={`col-lg-3 ${filtros_avanzandos ? '' : 'd-none'}`}>
              <div className="form-group">
                <label>Nombre del titular</label>
                <div className="input-group">
                  <input type="text" name="filtro_titular_nombre" value={filtro_titular_nombre} onChange={this.onChange} placeholder="Ingrese nombre del titular" className={`form-control ${filtros_errores && filtros_errores.data && filtros_errores.data.titular_nombre ? 'is-invalid' : ''}`} />
                  {filtros_errores && filtros_errores.data && filtros_errores.data.titular_nombre &&
                    filtros_errores.data.titular_nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">{err}</div>
                    ))}
                </div>
              </div>
            </div>
            <div className={`col-lg-3 ${filtros_avanzandos ? '' : 'd-none'}`}>
              <div className="form-group">
                <label>RUT del titular</label>
                <div className="input-group">
                  <input type="text" name="filtro_titular_rut" value={filtro_titular_rut} onChange={this.onChange} placeholder="Ingrese rut del titular" className={`form-control ${filtros_errores && filtros_errores.data && filtros_errores.data.titular_rut ? 'is-invalid' : ''}`} />
                  {filtros_errores && filtros_errores.data && filtros_errores.data.titular_rut &&
                    filtros_errores.data.titular_rut.map((err, x) => (
                      <div key={x} className="invalid-feedback">{err}</div>
                    ))}
                </div>
              </div>
            </div>
            <div className={`col-lg-3 ${filtros_avanzandos ? '' : 'd-none'}`}>
              <div className="form-group">
                <label>Categorías</label>
                <div className="input-group">
                  <select name="filtro_categoria" onChange={this.onChange} value={filtro_categoria} className={`form-select ${filtros_errores && filtros_errores.data && filtros_errores.data.categoria ? 'is-invalid' : ''}`} >
                    <option value="">Selecciona una opción</option>
                    {categorias_listado.map((option, x) =>
                      <option key={x} value={option.id}>{option.nombre}</option>
                    )}
                  </select>
                  {filtros_errores && filtros_errores.data && filtros_errores.data.categoria &&
                    filtros_errores.data.categoria.map((err, x) => (
                      <div key={x} className="invalid-feedback">{err}</div>
                    ))}
                </div>
              </div>
            </div>
            */}
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroDocumentos}
            >
              <i className="fa fa-search"></i> Filtrar documentos
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Documentos.propTypes = {
  obtenerDocumentos: PropTypes.func.isRequired,
  obtenerDocumentosFiltros: PropTypes.func.isRequired,
  limpiarFiltroDocumentos: PropTypes.func.isRequired,
  filtros_documentos: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  documentos_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_documentos: state.vigilancias.filtros_documentos,
  filtros_errores: state.vigilancias.filtros_errores,
  documentos_filtro_input: state.vigilancias.documentos_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerDocumentos, obtenerDocumentosFiltros, limpiarFiltroDocumentos },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Documentos);
