import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  obtenerArchivoPermisoInteligencia,
  obtenerPermisoInteligencia,
  setPermisoTarea,
} from "../../../redux/actions/permitting/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { FichaPermiso } from "../general";

export class Permiso extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.permisoSeleccionado();
  };

  nuevaTarea = (permiso) => {
    const { setPermisoTarea } = this.props;
    setPermisoTarea(permiso);
  };

  permisoSeleccionado = () => {
    const {
      obtenerPermisoInteligencia,
      permiso_seleccionado,
      inteligencia_seleccionada,
    } = this.props;
    if (permiso_seleccionado && inteligencia_seleccionada) {
      obtenerPermisoInteligencia({
        permiso: permiso_seleccionado,
        proyecto: inteligencia_seleccionada.id,
      });
    }
  };

  verArchivo = (archivo, permiso) => {
    const {
      obtenerArchivoPermisoInteligencia,
      inteligencia_seleccionada,
      is_externo,
    } = this.props;
    if (!is_externo) {
      SweetAlert.fire({
        title: "Restriccion de Acceso",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "No puede ver el archivo seleccionado",
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    } else {
      obtenerArchivoPermisoInteligencia({
        archivo: archivo,
        permiso: permiso,
        proyecto: inteligencia_seleccionada.id,
      });
    }
  };

  render = () => {
    const {
      permiso,
      is_loading_permitting,
      permiso_seleccionado,
      is_externo,
      inteligencia_seleccionada,
      archivo_permiso_error,
      user,
    } = this.props;

    if (permiso_seleccionado === 0) {
      return <Navigate to="/permitting-target/permisoteca/permisos" />;
    } else if (
      !Object.entries(inteligencia_seleccionada).length > 0 ||
      inteligencia_seleccionada.id === 0
    ) {
      return <Navigate to="/permitting-target/permisoteca/proyectos" />;
    }

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Permiso",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo el permiso, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    if (
      Object.entries(archivo_permiso_error).length > 0 &&
      archivo_permiso_error.message
    ) {
      SweetAlert.fire({
        title: "Descarga Archivo",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: archivo_permiso_error.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="permitting_permisoteca" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row">
                    <div className="col-lg-8">
                      <h4>PERMISO DE PROYECTO DE INTELIGENCIA</h4>
                    </div>
                    <div className="col-lg-4">
                      <div className="btn-group float-end">
                        <Link
                          to="/permitting-target/permisoteca/permisos"
                          className="btn btn-primary"
                        >
                          <i className="fa fa-angle-left"></i> Volver al listado
                          de permisos
                        </Link>
                        {user && user.modulos && user.modulos.tasks && (
                          <>
                            <Link
                              data-tip
                              data-tooltip-id="nueva_tarea"
                              to="/permitting-target/permisoteca/permiso/tarea"
                              onClick={this.nuevaTarea.bind(this, permiso)}
                              type="button"
                              className="btn btn-warning float-end"
                            >
                              Crea una Tarea <i className="fa fa-plus"></i>
                            </Link>
                            <Tooltip id="nueva_tarea" aria-haspopup="true">
                              Crear una tarea del permiso y gestiona con QUALITY
                              TASKING
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FichaPermiso
                permiso={permiso}
                verArchivo={this.verArchivo}
                ver_pas={inteligencia_seleccionada.is_pas}
                is_externo={is_externo}
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Permiso.propTypes = {
  setPermisoTarea: PropTypes.func.isRequired,
  obtenerPermisoInteligencia: PropTypes.func.isRequired,
  obtenerArchivoPermisoInteligencia: PropTypes.func.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  permiso: PropTypes.object.isRequired,
  permiso_seleccionado: PropTypes.number.isRequired,
  inteligencia_seleccionada: PropTypes.object.isRequired,
  archivo_permiso_error: PropTypes.object.isRequired,
  is_externo: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_permitting: state.permitting.is_loading_permitting,
  permiso: state.permitting.permiso,
  permiso_seleccionado: state.permitting.permiso_seleccionado,
  inteligencia_seleccionada: state.permitting.inteligencia_seleccionada,
  archivo_permiso_error: state.permitting.archivo_permiso_error,
  is_externo: state.permitting.is_externo,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerPermisoInteligencia,
      obtenerArchivoPermisoInteligencia,
      setPermisoTarea,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Permiso);
