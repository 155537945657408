import {
  useNavigate,
  useParams,
} from 'react-router-dom';

export const withRouter = Component => props => {
  const navigate = useNavigate();
  const match = {params: useParams()};
  return (
    <Component {...props} navigate={navigate} match={match} />
  );
};
