import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  obtenerUnidadesFiscalizables,
  obtenerUnidadesFiscalizablesFiltros,
} from "../../../redux/actions/vigilancias/unidades_fiscalizables/action";
import { Footer, Maps, Navbar, Paginador, SidebarInicio } from "../../general";
import { Filtros, Tabla } from "./";

export class Listado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ver_mapa: false,
    };
  }

  componentDidMount = () => {
    const { obtenerUnidadesFiscalizables, unidades } = this.props;
    if (Object.entries(unidades).length === 0) {
      obtenerUnidadesFiscalizables(1);
    }
  };

  nuevaPagina = (pagina) => {
    const {
      obtenerUnidadesFiscalizables,
      obtenerUnidadesFiscalizablesFiltros,
      unidades_filtros,
      unidades_filtro_input,
    } = this.props;
    if (unidades_filtros && Object.entries(unidades_filtros).length > 0) {
      obtenerUnidadesFiscalizablesFiltros({
        filtros: unidades_filtro_input,
        pagina: pagina,
      });
    } else {
      obtenerUnidadesFiscalizables(pagina);
    }
  };

  verListadoMapa = (estado) => {
    this.setState({
      ver_mapa: estado ? estado : false,
    });
  };

  render = () => {
    const { unidades, unidades_filtros, is_loading_vigilancias } = this.props;
    const { ver_mapa } = this.state;

    var unidades_result = unidades;
    if (unidades_filtros && Object.entries(unidades_filtros).length > 0) {
      unidades_result = unidades_filtros;
    }

    var markers = [];
    if (
      unidades &&
      unidades_result.result &&
      unidades_result.result.length > 0
    ) {
      unidades_result.result.forEach(function (unidad) {
        if (unidad.latitud && unidad.longitud) {
          markers.push({
            titulo: "SNIFA - Unidad Fiscalizable",
            nombre: unidad.nombre,
            position: [unidad.latitud, unidad.longitud],
          });
        }
      });
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Unidades Fiscalizables",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los unidades fiscalizables, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_unidades" menu_2="listado" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-house-user"></i> UNIDADES FISCALIZABLES
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE UNIDADES FISCALIZABLES{" "}
                      <small>
                        (
                        {unidades_result && unidades_result.total
                          ? unidades_result.total
                          : 0}
                        )
                      </small>
                    </h5>
                    <div className="btn-group float-end">
                      <button
                        onClick={this.verListadoMapa.bind(this, true)}
                        className={`btn btn-primary btn-sm ${
                          ver_mapa ? "d-none" : ""
                        }`}
                      >
                        Ver mapa <i className="fa fa-map-marker-alt"></i>
                      </button>
                      <button
                        onClick={this.verListadoMapa.bind(this, false)}
                        className={`btn btn-danger btn-sm ${
                          !ver_mapa ? "d-none" : ""
                        }`}
                      >
                        Esconcer mapa <i className="fa fa-chevron-up"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className={ver_mapa ? "d-none" : ""}>
                      <Paginador
                        paginador={unidades_result}
                        nuevaPagina={this.nuevaPagina}
                      />
                      <Tabla
                        unidades_fiscalizables={unidades_result}
                        componente="unidades"
                      />
                    </div>
                    <div className={!ver_mapa ? "d-none" : ""}>
                      <Maps
                        center={[-33, -70]}
                        zoom={6}
                        markers={markers}
                        size={1000}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerUnidadesFiscalizables: PropTypes.func.isRequired,
  obtenerUnidadesFiscalizablesFiltros: PropTypes.func.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  unidades: PropTypes.object.isRequired,
  unidades_filtros: PropTypes.object.isRequired,
  unidades_filtro_input: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_errores: state.vigilancias.filtros_errores,
  unidades: state.vigilancias.unidades,
  unidades_filtros: state.vigilancias.unidades_filtros,
  unidades_filtro_input: state.vigilancias.unidades_filtro_input,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerUnidadesFiscalizables, obtenerUnidadesFiscalizablesFiltros },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
