import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { obtenerQuinencoPertinencias } from "../../../../redux/actions/vigilancias/quinenco/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../../general";
import { Tabla } from "../../pertinencias";
import { Filtros } from "./";

export class QuinencoPertinencias extends Component {
  nuevaPagina = (pagina) => {
    const { obtenerQuinencoPertinencias } = this.props;
    obtenerQuinencoPertinencias(pagina);
  };

  componentDidMount = () => {
    const { obtenerQuinencoPertinencias, quinenco_pertinencias } = this.props;
    if (Object.entries(quinenco_pertinencias).length === 0) {
      obtenerQuinencoPertinencias(1);
    }
  };

  render = () => {
    const { quinenco_pertinencias, is_loading_vigilancias } = this.props;

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Consulta de Pertinencia",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las consultas de pertinencia, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="empresa_quinenco" menu_2="pertinencias" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-leaf"></i> QUIÑENCO
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE CONSULTAS DE PERTINENCIA{" "}
                      <small>
                        (
                        {quinenco_pertinencias && quinenco_pertinencias.total
                          ? quinenco_pertinencias.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={quinenco_pertinencias}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla
                      pertinencias={quinenco_pertinencias}
                      componente="quinenco"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

QuinencoPertinencias.propTypes = {
  obtenerQuinencoPertinencias: PropTypes.func.isRequired,
  quinenco_pertinencias: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  quinenco_pertinencias: state.vigilancias.quinenco_pertinencias,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerQuinencoPertinencias }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuinencoPertinencias);
