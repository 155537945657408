import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  guardarExpediente,
  obtenerExpedientesDocumentosFiltros,
  obtenerFiltrosExpedientesDocumentos,
} from "../../../redux/actions/vigilancias/expedientes/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { FiltrosDocumentos as Filtros, TablaFolios as Tabla } from "./";

export class Documentos extends Component {
  componentDidMount = () => {
    const {
      obtenerFiltrosExpedientesDocumentos,
      filtros_expedientes_documentos,
    } = this.props;
    if (Object.entries(filtros_expedientes_documentos).length === 0) {
      obtenerFiltrosExpedientesDocumentos();
    }
  };

  nuevaPagina = (pagina) => {
    const {
      obtenerExpedientesDocumentosFiltros,
      expedientes_documentos_filtro_input,
    } = this.props;

    obtenerExpedientesDocumentosFiltros({
      filtros: expedientes_documentos_filtro_input,
      pagina: pagina,
    });
  };

  setExpediente = (id) => {
    const { guardarExpediente } = this.props;
    guardarExpediente({ id: id, componente: "expedientes" });
  };

  render = () => {
    const { expedientes_documentos, is_loading_vigilancias } = this.props;

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "SEIA",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los documentos, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_expedientes" menu_2="documentos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-file-lines"></i> DOCUMENTOS EXPEDIENTES
                    SEIA
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE DOCUMENTOS{" "}
                      <small>
                        (
                        {expedientes_documentos && expedientes_documentos.total
                          ? expedientes_documentos.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={expedientes_documentos}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla folios={expedientes_documentos} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Documentos.propTypes = {
  obtenerFiltrosExpedientesDocumentos: PropTypes.func.isRequired,
  guardarExpediente: PropTypes.func.isRequired,
  obtenerExpedientesDocumentosFiltros: PropTypes.func.isRequired,
  expedientes_documentos: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  expedientes_documentos: state.vigilancias.expedientes_documentos,
  expedientes_documentos_filtro_input:
    state.vigilancias.expedientes_documentos_filtro_input,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  filtros_expedientes_documentos:
    state.vigilancias.filtros_expedientes_documentos,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerFiltrosExpedientesDocumentos,
      guardarExpediente,
      obtenerExpedientesDocumentosFiltros,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Documentos);
