import { AUTENTIFICACION_FALLIDO } from '../actions/auth/types';
import {
  ARCHIVO_PERMISO_FALLIDO,
  ARCHIVO_PREGUNTA_FALLIDO,
  CARGANDO_LIMPIAR_PERMITTING,
  CARGANDO_OK,
  CATEGORIA_SECCION_SELECCIONADA,
  CATEGORIA_SELECCIONADA,
  CATEGORIAS_FALLIDO,
  CATEGORIAS_OBTENER,
  CONSULTA_CREAR_FALLIDO,
  CONSULTA_CREAR_OBTENER,
  CONSULTA_DUPLICAR_FALLIDO,
  CONSULTA_DUPLICAR_OBTENER,
  CONSULTA_ELIMINAR_FALLIDO,
  CONSULTA_ELIMINAR_OBTENER,
  CONSULTA_SELECCIONADA,
  DASHBOARD_FALLIDO,
  DASHBOARD_OBTENER,
  GENERAR_ENTREGABLE_FALLIDO,
  GENERAR_ENTREGABLE_OBTENER,
  INTELIGENCIA_SELECCIONADA,
  INTELIGENCIAS_FALLIDO,
  INTELIGENCIAS_OBTENER,
  PERMISO_FALLIDO,
  PERMISO_INCLUIDO_FALLIDO,
  PERMISO_INCLUIDO_OBTENER,
  PERMISO_OBTENER,
  PERMISO_OMITIR_FALLIDO,
  PERMISO_OMITIR_OBTENER,
  PERMISO_SELECCIONADO,
  PERMISO_TAREA_SELECCIONADO,
  PERMISOS_FALLIDO,
  PERMISOS_FILTROS_FALLIDO,
  PERMISOS_FILTROS_OBTENER,
  PERMISOS_OBTENER,
  PERMISOS_REQUISITOS_FALLIDO,
  PERMISOS_REQUISITOS_OBTENER,
  PERMISOS_REQUISITOS_TIMELINE_FALLIDO,
  PERMISOS_REQUISITOS_TIMELINE_OBTENER,
  PREGUNTAS_FALLIDO,
  PREGUNTAS_OBTENER,
  PROYECTOS_FALLIDO,
  PROYECTOS_OBTENER,
  RESPUESTAS_FALLIDO,
  RESPUESTAS_INDEFINIDAS_FALLIDO,
  RESPUESTAS_INDEFINIDAS_OBTENER,
  RESPUESTAS_OBTENER,
  SECCION_SELECCIONADA,
  SECCIONES_FALLIDO,
  SECCIONES_OBTENER,
} from '../actions/permitting/types';

const initialState = {
  is_loading_permitting: true,
  estado: false,
  is_externo: false,
  permiso_tarea_seleccionado: {},
  permisos: [],
  permiso_url: '',
  permisos_requisitos: [],
  permisos_requisitos_error: {},
  permisos_requisitos_timeline: [],
  permisos_requisitos_timeline_error: {},
  omitidos: [],
  is_descargar: false,
  permisos_error: {},
  secciones: [],
  secciones_error: {},
  categorias: [],
  respuestas_indefinidas: [],
  respuestas_indefinidas_error: {},
  categorias_error: {},
  preguntas: [],
  proyectos: [],
  inteligencias: [],
  respuestas: {},
  respuestas_error: {},
  permiso_seleccionado: 0,
  inteligencia_seleccionada: {},
  seccion_seleccionada: {},
  consulta_seleccionada: 0,
  categoria_seleccionada: {},
  proyecto_seleccionado: {},
  permiso: {},
  dashboard: {},
  filtros: {},
  permisos_filtro_error: {},
  consulta_crear: {},
  consulta_crear_error: {},
  consulta_eliminar: {},
  consulta_eliminar_error: {},
  consulta_duplicar: {},
  consulta_duplicar_error: {},
  generar_entregables: {},
  generar_entregables_error: {},
  preguntas_error: {},
  archivo_pregunta_error: {},
  archivo_permiso_error: {},
  permiso_omitido: {},
  permiso_omitido_error: {},
  permiso_incluido: {},
  permiso_incluido_error: {},
};

export default function permitting(state = initialState, action) {
  switch (action.type) {
    case AUTENTIFICACION_FALLIDO:
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
        is_authenticated: false,
        is_loading_permitting: false,
      };
    case PERMISOS_REQUISITOS_TIMELINE_OBTENER:
      return {
        ...state,
        permisos_requisitos_timeline: action.payload,
        permisos_requisitos_timeline_error: {},
        is_loading_permitting: false,
      };
    case RESPUESTAS_INDEFINIDAS_OBTENER:
      return {
        ...state,
        respuestas_indefinidas: action.payload,
        respuestas_indefinidas_error: {},
        is_loading_permitting: false,
      };
    case RESPUESTAS_INDEFINIDAS_FALLIDO:
      return {
        ...state,
        respuestas_indefinidas: [],
        respuestas_indefinidas_error: action.payload,
        is_loading_permitting: false,
      };
    case PERMISOS_REQUISITOS_TIMELINE_FALLIDO:
      return {
        ...state,
        permisos_requisitos_timeline: [],
        permisos_requisitos_timeline_error: action.payload,
        is_loading_permitting: false,
      };
    case PERMISOS_REQUISITOS_OBTENER:
      return {
        ...state,
        permisos_requisitos: action.payload,
        permisos_requisitos_error: {},
        is_loading_permitting: false,
      };
    case PERMISOS_REQUISITOS_FALLIDO:
      return {
        ...state,
        permisos_requisitos: [],
        permisos_requisitos_error: action.payload,
        is_loading_permitting: false,
      };
    case GENERAR_ENTREGABLE_FALLIDO:
      return {
        ...state,
        generar_entregables_error: action.payload,
        generar_entregables: {},
      };
    case PERMISO_TAREA_SELECCIONADO:
      return {
        ...state,
        permiso_tarea_seleccionado: action.payload,
      };
    case PERMISO_OMITIR_OBTENER:
      return {
        ...state,
        permiso_omitido: action.payload,
        permiso_omitido_error: {},
        is_loading_permitting: false,
      };
    case PERMISO_OMITIR_FALLIDO:
      return {
        ...state,
        permiso_omitido: {},
        permiso_omitido_error: action.payload,
        is_loading_permitting: false,
      };
    case PERMISO_INCLUIDO_OBTENER:
      return {
        ...state,
        permiso_incluido: action.payload,
        permiso_incluido_error: {},
        is_loading_permitting: false,
      };
    case PERMISO_INCLUIDO_FALLIDO:
      return {
        ...state,
        permiso_incluido: {},
        permiso_incluido_error: action.payload,
        is_loading_permitting: false,
      };
    case INTELIGENCIAS_OBTENER:
      return {
        ...state,
        inteligencias: action.payload,
        is_loading_permitting: false,
        inteligencia_seleccionada: {},
        permiso_seleccionado: 0,
      };
    case INTELIGENCIAS_FALLIDO:
      return {
        ...state,
        inteligencias: [],
        is_loading_permitting: false,
        inteligencia_seleccionada: {},
        permiso_seleccionado: 0,
      };
    case GENERAR_ENTREGABLE_OBTENER:
      return {
        ...state,
        generar_entregables: action.payload,
        generar_entregables_error: {},
      };
    case CONSULTA_SELECCIONADA:
      return {
        ...state,
        consulta_seleccionada: action.payload.consulta,
        proyecto_seleccionado: action.payload.proyecto,
      };
    case RESPUESTAS_OBTENER:
      return {
        ...state,
        respuestas: action.payload,
        respuestas_error: {},
        is_loading_permitting: false,
      };
    case ARCHIVO_PREGUNTA_FALLIDO:
      return {
        ...state,
        archivo_pregunta_error: action.payload.data,
        is_loading_permitting: false,
      };
    case ARCHIVO_PERMISO_FALLIDO:
      return {
        ...state,
        archivo_permiso_error: action.payload.data,
        is_loading_permitting: false,
      };
    case RESPUESTAS_FALLIDO:
      return {
        ...state,
        respuestas_error: action.payload.data,
        respuestas: {},
        is_loading_permitting: false,
      };
    case CONSULTA_CREAR_FALLIDO:
      return {
        ...state,
        consulta_crear_error: action.payload.data,
        consulta_crear: {},
        is_loading_permitting: false,
      };
    case CONSULTA_CREAR_OBTENER:
      return {
        ...state,
        consulta_crear: action.payload,
        consulta_crear_error: {},
        is_loading_permitting: false,
      };
    case CONSULTA_ELIMINAR_FALLIDO:
      return {
        ...state,
        consulta_eliminar_error: action.payload.data,
        consulta_eliminar: {},
        is_loading_permitting: false,
      };
    case CONSULTA_ELIMINAR_OBTENER:
      return {
        ...state,
        consulta_eliminar: action.payload,
        consulta_eliminar_error: {},
        is_loading_permitting: false,
      };
    case CONSULTA_DUPLICAR_FALLIDO:
      return {
        ...state,
        consulta_duplicar_error: action.payload.data,
        consulta_duplicar: {},
        is_loading_permitting: false,
      };
    case CONSULTA_DUPLICAR_OBTENER:
      return {
        ...state,
        consulta_duplicar: action.payload,
        consulta_duplicar_error: {},
        is_loading_permitting: false,
      };
    case PROYECTOS_OBTENER:
      return {
        ...state,
        proyectos: action.payload,
        consulta_seleccionada: 0,
        proyecto_seleccionado: {},
        is_loading_permitting: false,
        secciones: [],
      };
    case PROYECTOS_FALLIDO:
      return {
        ...state,
        proyectos: [],
        secciones: [],
        consulta_seleccionada: 0,
        proyecto_seleccionado: {},
        is_loading_permitting: false,
      };
    case PREGUNTAS_OBTENER:
      return {
        ...state,
        categorias: [],
        secciones: [],
        preguntas: action.payload.preguntas,
        estado: action.payload.estado,
        preguntas_error: {},
        is_loading_permitting: false,
      };
    case PREGUNTAS_FALLIDO:
      return {
        ...state,
        categorias: [],
        secciones: [],
        preguntas: [],
        preguntas_error: action.payload.data,
        estado: false,
        is_loading_permitting: false,
      };
    case CATEGORIAS_FALLIDO:
      return {
        ...state,
        categorias: [],
        categorias_error: action.payload.data,
        is_loading_permitting: false,
        secciones: [],
        preguntas: [],
      };
    case CATEGORIAS_OBTENER:
      return {
        ...state,
        categorias: action.payload,
        categorias_error: {},
        is_loading_permitting: false,
        secciones: [],
        preguntas: [],
      };
    case SECCION_SELECCIONADA:
      return {
        ...state,
        seccion_seleccionada: action.payload,
      };
    case CATEGORIA_SELECCIONADA:
      return {
        ...state,
        categoria_seleccionada: action.payload,
      };
    case CATEGORIA_SECCION_SELECCIONADA:
      return {
        ...state,
        categoria_seleccionada: action.payload.categoria,
        seccion_seleccionada: action.payload.seccion,
      };
    case SECCIONES_FALLIDO:
      return {
        ...state,
        secciones: [],
        categorias: [],
        preguntas: [],
        secciones_error: action.payload.data,
        is_loading_permitting: false,
        seccion_seleccionada: {},
        categoria_seleccionada: {},
      };
    case SECCIONES_OBTENER:
      return {
        ...state,
        secciones_error: {},
        categorias: [],
        preguntas: [],
        secciones: action.payload,
        is_loading_permitting: false,
        seccion_seleccionada: {},
      };
    case PERMISOS_FILTROS_FALLIDO:
      return {
        ...state,
        permisos: [],
        permisos_filtro_error: action.payload,
        is_loading_permitting: false,
      };
    case PERMISOS_FILTROS_OBTENER:
      return {
        ...state,
        permisos: action.payload,
        permisos_filtro_error: {},
        is_loading_permitting: false,
      };
    case PERMISO_OBTENER:
      return {
        ...state,
        permiso: action.payload.permiso,
        is_externo: action.payload.is_externo,
        is_loading_permitting: false,
      };
    case PERMISO_FALLIDO:
      return {
        ...state,
        permiso: {},
        is_externo: false,
        is_loading_permitting: false,
      };
    case PERMISOS_OBTENER:
      return {
        ...state,
        permisos: action.payload.permisos,
        omitidos: action.payload.omitidos,
        is_descargar: action.payload.descargar,
        permisos_error: {},
        filtros: action.payload.filtros,
        permiso_seleccionado: 0,
        is_loading_permitting: false,
        permiso: {},
      };
    case PERMISOS_FALLIDO:
      return {
        ...state,
        is_loading_permitting: false,
        is_descargar: false,
        permiso_seleccionado: 0,
        permisos: [],
        omitidos: [],
        permisos_error: action.payload.data,
        permiso: {},
      };
    case CARGANDO_OK:
      return {
        ...state,
        is_loading_permitting: false,
      };
    case CARGANDO_LIMPIAR_PERMITTING:
      return {
        ...state,
        is_loading_permitting: true,
        permisos_filtro_error: {},
        secciones_error: {},
        consulta_crear: {},
        consulta_crear_error: {},
        consulta_eliminar: {},
        consulta_eliminar_error: {},
        consulta_duplicar: {},
        consulta_duplicar_error: {},
        respuestas: {},
        preguntas_error: {},
        respuestas_error: {},
        categorias_error: {},
        permisos_error: {},
        archivo_permiso_error: {},
        archivo_pregunta_error: {},
        generar_entregables_error: {},
        generar_entregables: {},
        permiso_tarea_seleccionado: {},
        permiso_omitido: {},
        permiso_omitido_error: {},
        permiso_incluido: {},
        permiso_incluido_error: {},
        permisos_requisitos: [],
      };
    case PERMISO_SELECCIONADO:
      return {
        ...state,
        permiso_seleccionado: action.payload.id,
        permiso_url: action.payload.url,
        is_loading_permitting: false,
      };
    case INTELIGENCIA_SELECCIONADA:
      return {
        ...state,
        inteligencia_seleccionada: action.payload,
        is_loading_permitting: false,
      };
    case DASHBOARD_FALLIDO:
      return {
        ...state,
        dashboard: {},
        is_loading_permitting: false,
      };
    case DASHBOARD_OBTENER:
      return {
        ...state,
        dashboard: action.payload,
        is_loading_permitting: false,
      };
    default:
      return state;
  }
}
