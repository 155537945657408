import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  LIMPIAR_REQUERIMIENTOS_INGRESO_FILTROS,
  REQUERIMIENTO_INGRESO_FALLIDO,
  REQUERIMIENTO_INGRESO_GUARDAR,
  REQUERIMIENTO_INGRESO_OBTENER,
  REQUERIMIENTOS_INGRESOS_FALLIDO,
  REQUERIMIENTOS_INGRESOS_FILTROS_FALLIDO,
  REQUERIMIENTOS_INGRESOS_FILTROS_INPUT,
  REQUERIMIENTOS_INGRESOS_FILTROS_OBTENER,
  REQUERIMIENTOS_INGRESOS_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerRequerimientosIngresos =
  (pagina) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .get(
        `${url_api}/api/vigilances/requerimientos-ingresos/${pagina}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: REQUERIMIENTOS_INGRESOS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : REQUERIMIENTOS_INGRESOS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerRequerimientosIngresosFiltros =
  (data) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .post(
        `${url_api}/api/vigilances/requerimientos-ingresos/filtros/${data.pagina}`,
        data.filtros,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: REQUERIMIENTOS_INGRESOS_FILTROS_INPUT,
          payload: data.filtros,
        });
        dispatch({
          type: REQUERIMIENTOS_INGRESOS_FILTROS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : REQUERIMIENTOS_INGRESOS_FILTROS_FALLIDO,
          payload: error,
        });
      });
  };

export const guardarRequerimientoIngreso = (id, componente) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  dispatch({
    type: REQUERIMIENTO_INGRESO_GUARDAR,
    payload: { id: id, componente: componente },
  });
};

export const obtenerRequerimientoIngreso = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/requerimiento-ingreso`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: REQUERIMIENTO_INGRESO_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : REQUERIMIENTO_INGRESO_FALLIDO,
        payload: error,
      });
    });
};

export const limpiarFiltroRequerimientosIngreso = () => (dispatch) => {
  dispatch({ type: LIMPIAR_REQUERIMIENTOS_INGRESO_FILTROS });
};
