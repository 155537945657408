import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  obtenerMedidasProvisionales,
  obtenerMedidasProvisionalesFiltros,
} from "../../../redux/actions/vigilancias/medidas/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Filtros, Tabla } from "./";

export class Listado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ver_medidas: true,
    };
  }

  nuevaPagina = (pagina) => {
    const {
      obtenerMedidasProvisionales,
      obtenerMedidasProvisionalesFiltros,
      medidas_provisionales_filtros,
      medidas_provisionales_filtro_input,
    } = this.props;

    if (Object.entries(medidas_provisionales_filtros).length > 0) {
      obtenerMedidasProvisionalesFiltros({
        filtros: medidas_provisionales_filtro_input,
        pagina: pagina,
      });
    } else {
      obtenerMedidasProvisionales(pagina);
    }
  };

  componentDidMount = () => {
    const { obtenerMedidasProvisionales, medidas_provisionales } = this.props;
    if (Object.entries(medidas_provisionales).length === 0) {
      obtenerMedidasProvisionales(1);
    }
  };

  verListadoMedidas = (estado) => {
    this.setState({
      ver_medidas: estado ? estado : false,
    });
  };

  render = () => {
    const {
      medidas_provisionales,
      medidas_provisionales_filtros,
      is_loading_vigilancias,
    } = this.props;
    const { ver_medidas } = this.state;

    var medidas_provisionales_result = medidas_provisionales;
    if (Object.entries(medidas_provisionales_filtros).length > 0) {
      medidas_provisionales_result = medidas_provisionales_filtros;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Medidas Provisionales",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo las medidas provisionales, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_medidas" menu_2="listado" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-cogs"></i> MEDIDAS PROVISIONALES
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE MEDIDAS PROVISIONALES{" "}
                      <small>
                        (
                        {medidas_provisionales_result &&
                        medidas_provisionales_result.total
                          ? medidas_provisionales_result.total
                          : 0}
                        )
                      </small>
                    </h5>
                    <button
                      onClick={this.verListadoMedidas.bind(this, true)}
                      className={`btn btn-primary float-end btn-sm ${
                        ver_medidas ? "d-none" : ""
                      }`}
                    >
                      Ver listado <i className="fa fa-angle-down"></i>
                    </button>
                    <button
                      onClick={this.verListadoMedidas.bind(this, false)}
                      className={`btn btn-danger float-end btn-sm ${
                        !ver_medidas ? "d-none" : ""
                      }`}
                    >
                      Esconder listado <i className="fa fa-chevron-up"></i>
                    </button>
                  </div>
                  <div className={`card-body ${!ver_medidas ? "d-none" : ""}`}>
                    <Paginador
                      paginador={medidas_provisionales_result}
                      nuevaPagina={this.nuevaPagina}
                    />
                    {medidas_provisionales_result && (
                      <Tabla
                        medidas_provisionales={medidas_provisionales_result}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerMedidasProvisionales: PropTypes.func.isRequired,
  obtenerMedidasProvisionalesFiltros: PropTypes.func.isRequired,
  medidas_provisionales: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  medidas_provisionales_filtros: PropTypes.object.isRequired,
  medidas_provisionales_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  medidas_provisionales: state.vigilancias.medidas_provisionales,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  medidas_provisionales_filtros:
    state.vigilancias.medidas_provisionales_filtros,
  medidas_provisionales_filtro_input:
    state.vigilancias.medidas_provisionales_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerMedidasProvisionales, obtenerMedidasProvisionalesFiltros },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
