import React, { Component } from "react";

export class TablaMedia extends Component {
  render = () => {
    const { titular } = this.props;

    return (
      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td className="table-secondary w-10 text-dark">Nombre</td>
              <td>{titular.nombre ? titular.nombre : "S/N"}</td>
            </tr>
            <tr>
              <td className="table-secondary text-dark">RUT</td>
              <td>{titular.rut ? titular.rut : "S/I"}</td>
            </tr>
            <tr>
              <td className="table-secondary text-dark">Email</td>
              <td>{titular.email ? titular.email : "S/I"}</td>
            </tr>
            <tr>
              <td className="table-secondary text-dark">Teléfono</td>
              <td>{titular.telefono ? titular.telefono : "S/I"}</td>
            </tr>
            <tr>
              <td className="table-secondary text-dark">Ciudad</td>
              <td>{titular.ciudad ? titular.ciudad : "S/I"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}

export default TablaMedia;
