import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { guardarSeguimiento } from "../../../redux/actions/vigilancias/seguimientos/action";
import { Eliminar as EliminarFavorito } from "../favoritos";
import { TablaPequena as UnidadesFiscalizables } from "../unidades";

export class Tabla extends Component {
  setSeguimiento = (id, componente) => {
    const { guardarSeguimiento } = this.props;
    guardarSeguimiento({
      id: id,
      componente: componente ? componente : "listado",
    });
  };

  render = () => {
    const { seguimientos, componente, eliminar_favorito } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre del seguimiento</th>
              <th className="w-9">Fecha del Informe</th>
              <th className="w-30">Unidad Fiscalizable</th>
              <th className="w-5">Frecuencia</th>
              <th className="w-13">Subcomponente Ambiental</th>
              <th className="w-3 text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            {seguimientos &&
            seguimientos.result &&
            seguimientos.result.length > 0 ? (
              seguimientos.result.map((seguimiento, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {seguimientos.page > 1
                      ? (seguimientos.page - 1) * 20 + (x + 1)
                      : x + 1}
                  </td>
                  <td>
                    <a
                      href={seguimiento.url_snifa}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {seguimiento.nombre} <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                  <td>{seguimiento.fecha_informe}</td>
                  <td>
                    <UnidadesFiscalizables
                      unidades_fiscalizables={
                        seguimiento.unidades_fiscalizables
                      }
                    />
                  </td>
                  <td>
                    <span className="badge badge-dark font-13 text-center">
                      {seguimiento.frecuencia}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="badge badge-warning font-13 text-center">
                      {seguimiento.subcomponentes_ambiental &&
                      seguimiento.subcomponentes_ambiental.length < 2 ? (
                        <span>
                          {seguimiento.subcomponentes_ambiental[0]?.nombre}
                        </span>
                      ) : (
                        seguimiento.subcomponentes_ambiental.map((sub, x) => (
                          <li key={x}>{sub.nombre}</li>
                        ))
                      )}
                    </span>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {eliminar_favorito && (
                        <EliminarFavorito
                          tipo="seguimiento"
                          id={seguimiento.id}
                        />
                      )}
                      <Link
                        to="/compliance-analytics/seguimiento-ambiental"
                        className="btn btn-soft-dark"
                        data-tip
                        data-tooltip-id="detalle_expediente"
                        onClick={this.setSeguimiento.bind(
                          this,
                          seguimiento.id,
                          componente
                        )}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="11">
                  No existen seguimientos ambiental
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarSeguimiento: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarSeguimiento }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
