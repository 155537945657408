import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  obtenerPermisosRequisitos,
  PermisoSeleccionado,
} from "../../../../redux/actions/permitting/action";
import {
  Footer,
  Navbar,
  SiderbarPermittingAplicabilidad,
} from "../../../general";

export class Requisitos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ver_requisitos: 0,
    };
  }
  componentDidMount = () => {
    const {
      obtenerPermisosRequisitos,
      consulta_seleccionada,
      permisos_requisitos,
    } = this.props;
    if (consulta_seleccionada > 0 && permisos_requisitos.length === 0) {
      obtenerPermisosRequisitos({
        consulta: consulta_seleccionada,
      });
    }
  };

  verRequisito = (id) => {
    const { ver_requisitos } = this.state;
    this.setState({
      ver_requisitos: ver_requisitos === id ? 0 : id,
    });
  };

  setPermiso = (id) => {
    const { PermisoSeleccionado } = this.props;
    PermisoSeleccionado({
      id: id ? id : 0,
      url: "/permitting-target/aplicabilidad/requisitos",
    });
  };

  render = () => {
    const {
      permisos_requisitos,
      is_loading_permitting,
      consulta_seleccionada,
    } = this.props;
    const { ver_requisitos } = this.state;

    if (consulta_seleccionada === 0) {
      return <Navigate to="/permitting-target/proyectos" />;
    }

    if (is_loading_permitting) {
      SweetAlert.fire({
        title: "Permisos Requeridos",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo la información, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SiderbarPermittingAplicabilidad menu_1="permitting_requisitos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <h4 className="float-start">PERMISOS REQUISITOS</h4>
                    <p className="float-end mt-2">
                      <Link
                        className="btn btn-dark btn-xs"
                        to="/permitting-target/aplicabilidad/requisitos/timeline"
                      >
                        Ver Timeline <i className="fa fa-angle-right"></i>
                      </Link>
                    </p>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered mb-0">
                        <thead>
                          <tr>
                            <th className="text-center w-3">ID</th>
                            <th className="text-center w-3">Código</th>
                            <th>Nombre del permiso</th>
                            <th className="w-30">Autoridad</th>
                            <th className="w-35">Etapa</th>
                            <th className="text-center w-10">Accion</th>
                          </tr>
                        </thead>
                        <tbody>
                          {permisos_requisitos &&
                            permisos_requisitos.length > 0 &&
                            permisos_requisitos.map((permiso, x) => (
                              <Fragment key={x}>
                                <tr>
                                  <td className="text-center">{x + 1}</td>
                                  <td className="text-center">
                                    CL-{permiso.id}
                                  </td>
                                  <td>{permiso.nombre}</td>
                                  <td>
                                    {permiso.autoridad &&
                                      permiso.autoridad.length > 0 && (
                                        <div className="table-responsive">
                                          <table className="table table-sm table-bordered bg-white">
                                            <thead>
                                              <tr>
                                                <th className="text-center w-5">
                                                  ID
                                                </th>
                                                <th className="w-20">
                                                  Autoridad
                                                </th>
                                                <th className="w-20">
                                                  Ministerio
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {permiso.autoridad.map(
                                                (autoridad, x) => (
                                                  <tr key={x}>
                                                    <td className="text-center">
                                                      {x + 1}
                                                    </td>
                                                    <td>
                                                      {autoridad.nombre} (
                                                      {autoridad.sigla})
                                                    </td>
                                                    <td>
                                                      {
                                                        autoridad.ministerio
                                                          .nombre
                                                      }
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      )}
                                  </td>
                                  <td>
                                    {permiso.subetapa &&
                                      permiso.subetapa.length > 0 && (
                                        <div className="table-responsive">
                                          <table className="table table-sm table-bordered">
                                            <thead>
                                              <tr>
                                                <th className="text-center w-5">
                                                  ID
                                                </th>
                                                <th className="w-45">Etapa</th>
                                                <th className="w-50">
                                                  Subetapa
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {permiso.subetapa.map(
                                                (subetapa, x) => (
                                                  <tr key={x}>
                                                    <td className="text-center">
                                                      {x + 1}
                                                    </td>
                                                    <td>
                                                      {subetapa.etapa.nombre}
                                                    </td>
                                                    <td>{subetapa.nombre}</td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      )}
                                  </td>
                                  <td className="text-center">
                                    <div className="btn-group">
                                      <button
                                        onClick={this.verRequisito.bind(
                                          this,
                                          permiso.id
                                        )}
                                        data-tip
                                        data-tooltip-id={`permiso_requisitos_${permiso.id}`}
                                        className="btn btn-warning btn-xs"
                                      >
                                        R: {permiso.requisitos.length}
                                      </button>
                                      <Tooltip
                                        id={`permiso_requisitos_${permiso.id}`}
                                        aria-haspopup="true"
                                      >
                                        Listado de requisitos del permiso{" "}
                                        <span className="text-lowercase">
                                          {permiso.nombre}
                                        </span>
                                      </Tooltip>
                                      <Link
                                        data-tip
                                        data-tooltip-id={`permiso_detalle_${permiso.id}`}
                                        className="btn btn-primary btn-xs"
                                        onClick={this.setPermiso.bind(
                                          this,
                                          permiso.id
                                        )}
                                        to="/permitting-target/aplicabilidad/permiso"
                                      >
                                        <i className="fa fa-angle-right"></i>
                                      </Link>
                                      <Tooltip
                                        id={`permiso_detalle_${permiso.id}`}
                                        aria-haspopup="true"
                                      >
                                        Ver detalle del permiso{" "}
                                        <span className="text-lowercase">
                                          {permiso.nombre}
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  className={
                                    ver_requisitos === permiso.id
                                      ? ""
                                      : "d-none"
                                  }
                                >
                                  <td colSpan="6">
                                    <div className="table-responsive">
                                      <h5>
                                        REQUISITOS DEL PERMISO {permiso.nombre}
                                      </h5>
                                      <table className="table table-sm table-bordered mb-2">
                                        <thead className="table-dark">
                                          <tr>
                                            <th className="text-white text-center w-3">
                                              ID
                                            </th>
                                            <th className="text-white text-center w-5">
                                              Código
                                            </th>
                                            <th className="text-white">
                                              Nombre
                                            </th>
                                            <th className="text-center text-white w-6">
                                              Tipo
                                            </th>
                                            <th className="text-white w-30">
                                              Autoridad
                                            </th>
                                            <th className="text-white w-35">
                                              Etapa
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {permiso.requisitos.map((c, y) => (
                                            <tr key={`${x}.${y}`}>
                                              <td className="text-center">
                                                {x + 1}.{y + 1}
                                              </td>
                                              <td className="text-center">
                                                CL-{c.requisito.id}
                                              </td>
                                              <td>{c.requisito.nombre}</td>
                                              <td className="text-center">
                                                {c.tipo}
                                              </td>
                                              <td>
                                                {c.requisito &&
                                                  c.requisito.autoridad.length >
                                                    0 && (
                                                    <div className="table-responsive">
                                                      <table className="table table-sm table-bordered bg-white">
                                                        <thead>
                                                          <tr>
                                                            <th className="text-center w-5">
                                                              ID
                                                            </th>
                                                            <th className="w-20">
                                                              Autoridad
                                                            </th>
                                                            <th className="w-20">
                                                              Ministerio
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {c.requisito.autoridad.map(
                                                            (autoridad, x) => (
                                                              <tr key={x}>
                                                                <td className="text-center">
                                                                  {x + 1}
                                                                </td>
                                                                <td>
                                                                  {
                                                                    autoridad.nombre
                                                                  }{" "}
                                                                  (
                                                                  {
                                                                    autoridad.sigla
                                                                  }
                                                                  )
                                                                </td>
                                                                <td>
                                                                  {
                                                                    autoridad
                                                                      .ministerio
                                                                      .nombre
                                                                  }
                                                                </td>
                                                              </tr>
                                                            )
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  )}
                                              </td>
                                              <td>
                                                {c.requisito.subetapa &&
                                                  c.requisito.subetapa.length >
                                                    0 && (
                                                    <div className="table-responsive">
                                                      <table className="table table-sm table-bordered">
                                                        <thead>
                                                          <tr>
                                                            <th className="text-center w-5">
                                                              ID
                                                            </th>
                                                            <th className="w-45">
                                                              Etapa
                                                            </th>
                                                            <th className="w-50">
                                                              Subetapa
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {permiso.subetapa.map(
                                                            (subetapa, x) => (
                                                              <tr key={x}>
                                                                <td className="text-center">
                                                                  {x + 1}
                                                                </td>
                                                                <td>
                                                                  {
                                                                    subetapa
                                                                      .etapa
                                                                      .nombre
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {
                                                                    subetapa.nombre
                                                                  }
                                                                </td>
                                                              </tr>
                                                            )
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  )}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Requisitos.propTypes = {
  obtenerPermisosRequisitos: PropTypes.func.isRequired,
  PermisoSeleccionado: PropTypes.func.isRequired,
  is_loading_permitting: PropTypes.bool.isRequired,
  consulta_seleccionada: PropTypes.number.isRequired,
  permisos_requisitos: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_permitting: state.permitting.is_loading_permitting,
  consulta_seleccionada: state.permitting.consulta_seleccionada,
  permisos_requisitos: state.permitting.permisos_requisitos,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerPermisosRequisitos, PermisoSeleccionado },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Requisitos);
