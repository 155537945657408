import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  TITULAR_FALLIDO,
  TITULAR_GUARDAR,
  TITULAR_OBTENER,
  TITULARES_FALLIDO,
  TITULARES_FILTRO_FALLIDO,
  TITULARES_FILTRO_OBTENER,
  TITULARES_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerTitulares = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(`${url_api}/api/vigilances/titulares/${pagina}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: TITULARES_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TITULARES_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerTitularesFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/titulares/filtros`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: TITULARES_FILTRO_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TITULARES_FILTRO_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerTitular = (id) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(`${url_api}/api/vigilances/titular/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: TITULAR_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : TITULAR_FALLIDO,
        payload: error,
      });
    });
};

export const guardarTitular = (id) => (dispatch) => {
  dispatch({ type: TITULAR_GUARDAR, payload: id });
};
