import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { guardarExpediente } from "../../../../redux/actions/vigilancias/expedientes/action";
import { obtenerQuinencoExpedientes } from "../../../../redux/actions/vigilancias/quinenco/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../../general";
import { Tabla } from "../../expedientes";
import { Filtros } from "./";

export class QuinencoExpedientes extends Component {
  componentDidMount = () => {
    const { obtenerQuinencoExpedientes, quinenco_expedientes } = this.props;
    if (Object.entries(quinenco_expedientes).length === 0) {
      obtenerQuinencoExpedientes(1);
    }
  };

  setExpediente = (id) => {
    const { guardarExpediente } = this.props;
    guardarExpediente({ id: id, componente: "quinenco" });
  };

  nuevaPagina = (pagina) => {
    const { obtenerQuinencoExpedientes } = this.props;
    obtenerQuinencoExpedientes(pagina);
  };

  render = () => {
    const { quinenco_expedientes, is_loading_vigilancias } = this.props;
    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Expedientes SEIA",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los expedientes seia, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="empresa_quinenco" menu_2="expedientes" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-leaf"></i> QUIÑENCO
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE EXPEDIENTES SEIA{" "}
                      <small>
                        (
                        {quinenco_expedientes && quinenco_expedientes.total
                          ? quinenco_expedientes.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={quinenco_expedientes}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla expedientes={quinenco_expedientes} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

QuinencoExpedientes.propTypes = {
  obtenerQuinencoExpedientes: PropTypes.func.isRequired,
  guardarExpediente: PropTypes.func.isRequired,
  quinenco_expedientes: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  quinenco_expedientes: state.vigilancias.quinenco_expedientes,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerQuinencoExpedientes, guardarExpediente },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuinencoExpedientes);
