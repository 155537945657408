import React, { Component } from "react";

export class TablaPequena extends Component {
  render = () => {
    const { expediente } = this.props;

    return !expediente ? (
      <span className="badge badge-soft-dark">Sin Información</span>
    ) : (
      <>
        <p className="mb-1">
          <a
            href={expediente.url_seia}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <i className="fa fa-link me-1"></i>
            </span>
            <span className="text-uppercase">{expediente.nombre}</span>
          </a>
        </p>
        <p className="mb-0">
          <span className="badge badge-dark font-10 me-1">
            {expediente.estado}
          </span>
          <span className="badge badge-soft-dark font-10">
            {expediente.region_display}
          </span>
        </p>
        <p className="mb-0">
          <span className="badge badge-danger font-10 me-1">
            {expediente.tipologia_display}
          </span>
        </p>
      </>
    );
  };
}

export default TablaPequena;
