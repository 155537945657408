import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import {
  CARGANDO_LIMPIAR_NOTIFICACIONES,
  NOTIFICACIONES_FALLIDO,
  NOTIFICACIONES_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerNotificaciones = () => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_NOTIFICACIONES });
  axios
    .get(`${url_api}/api/users/notificaciones`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: NOTIFICACIONES_OBTENER,
        payload: res.data,
      });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : NOTIFICACIONES_FALLIDO,
        payload: error,
      });
    });
};
