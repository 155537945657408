import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import {
  obtenerExpedientes,
  obtenerExpedientesFiltros,
} from "../../../redux/actions/mma/action";
import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Filtros, Tabla } from "./";

export class Listado extends Component {
  componentDidMount = () => {
    const { obtenerExpedientes, expedientes } = this.props;
    if (Object.entries(expedientes).length === 0) {
      obtenerExpedientes(1);
    }
  };

  nuevaPagina = (pagina) => {
    const {
      obtenerExpedientes,
      obtenerExpedientesFiltros,
      expedientes_filtros,
      expedientes_filtro_input,
    } = this.props;
    if (Object.entries(expedientes_filtros).length > 0) {
      obtenerExpedientesFiltros({
        filtros: expedientes_filtro_input,
        pagina: pagina,
      });
    } else {
      obtenerExpedientes(pagina);
    }
  };

  render = () => {
    const { expedientes, expedientes_filtros, is_loading_mma } = this.props;

    var expedientes_result = expedientes;
    if (Object.entries(expedientes_filtros).length > 0) {
      expedientes_result = expedientes_filtros;
    }

    if (is_loading_mma) {
      SweetAlert.fire({
        title: "Expedientes MMA",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo los expedientes, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="mma_expedientes" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-envelope-open-text"></i> EXPEDIENTES MMA
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <Filtros />
                <div className="card card-eco">
                  <div className="card-header">
                    <h5 className="float-start">
                      LISTADO DE EXPEDIENTES{" "}
                      <small>
                        (
                        {expedientes_result && expedientes_result.total
                          ? expedientes_result.total
                          : 0}
                        )
                      </small>
                    </h5>
                  </div>
                  <div className="card-body">
                    <Paginador
                      paginador={expedientes_result}
                      nuevaPagina={this.nuevaPagina}
                    />
                    <Tabla expedientes={expedientes_result} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Listado.propTypes = {
  obtenerExpedientes: PropTypes.func.isRequired,
  obtenerExpedientesFiltros: PropTypes.func.isRequired,
  expedientes: PropTypes.object.isRequired,
  expedientes_filtros: PropTypes.object.isRequired,
  expedientes_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_mma: state.mma.is_loading_mma,
  expedientes: state.mma.expedientes,
  expedientes_filtros: state.mma.expedientes_filtros,
  expedientes_filtro_input: state.mma.expedientes_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { obtenerExpedientes, obtenerExpedientesFiltros },
    // { obtenerExpedientes },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Listado);
