import React, { Component } from 'react';

import _ from 'lodash';
import Chart from 'react-apexcharts';

export class Pie extends Component {

  render = () => {
    const { nombre, titulo, data, is_vertical } = this.props

    return (
      <div className="card card-eco card-grafico">
        <div className="card-body">
          <h5><i className="fa fa-business-time text-primary"></i> {titulo}</h5>
          <div className="p-3">
            <Chart
              options={{
                xaxis: {
                  categories: _.keys(data)
                },
                plotOptions: {
                  bar: {
                    horizontal: is_vertical ? false : true,
                    distributed: true,
                    barHeight: '90%',
                  },
                },
                legend: {
                  horizontalAlign: 'left',
                  fontSize: '12px',
                },
                tooltip: {
                  fixed: {
                    enabled: true,
                  },
                }
              }}
              series={[
                {
                  name: nombre,
                  data: _.values(data)
                }
              ]}
              type="bar"
            />
          </div>
        </div>
      </div>
    );
  }
}


export default Pie;
