import { AUTENTIFICACION_FALLIDO } from "../actions/auth/types";
import {
  CARGANDO_LIMPIAR_MMA,
  EXPEDIENTES_MMA_FALLIDO,
  EXPEDIENTES_MMA_FAVORITO_ELIMINAR_FALLIDO,
  EXPEDIENTES_MMA_FAVORITO_ELIMINAR_OBTENER,
  EXPEDIENTES_MMA_FAVORITO_FALLIDO,
  EXPEDIENTES_MMA_FAVORITO_OBTENER,
  EXPEDIENTES_MMA_FILTROS_FALLIDO,
  EXPEDIENTES_MMA_FILTROS_INPUT,
  EXPEDIENTES_MMA_FILTROS_OBTENER,
  EXPEDIENTES_MMA_OBTENER,
  EXPEDIENTE_MMA_FALLIDO,
  EXPEDIENTE_MMA_GUARDAR,
  EXPEDIENTE_MMA_OBTENER,
  LIMPIAR_EXPEDIENTES_MMA_FILTROS,
  MENSAJES_LIMPIAR,
} from "../actions/mma/types";

const initialState = {
  is_loading_mma: true,
  // expedientes
  expediente_id: 0,
  expedientes: {},
  expedientes_filtros: {},
  expedientes_filtro_input: {},
  expediente: {},
  filtros_errores: {},
  filtros_expedientes: {},
  expediente_favorito: {},
  expediente_favorito_error: {},
  expediente_favorito_eliminar: {},
  expediente_favorito_eliminar_error: {},
};

export default function vigilancias(state = initialState, action) {
  switch (action.type) {
    case AUTENTIFICACION_FALLIDO:
      localStorage.clear();
      return {
        ...state,
        token: null,
        user: null,
        is_authenticated: false,
        is_loading_mma: false,
      };
    case MENSAJES_LIMPIAR:
      return {
        ...state,
        expediente_favorito: {},
        expediente_favorito_error: {},
        expediente_favorito_eliminar: {},
        expediente_favorito_eliminar_error: {},
      };
    case EXPEDIENTES_MMA_FAVORITO_ELIMINAR_OBTENER:
      return {
        ...state,
        expediente_favorito_eliminar: action.payload,
        expediente_favorito_eliminar_error: {},
        is_loading_mma: false,
      };
    case EXPEDIENTES_MMA_FAVORITO_ELIMINAR_FALLIDO:
      return {
        ...state,
        expediente_favorito_eliminar: {},
        expediente_favorito_eliminar_error: action.payload,
        is_loading_mma: false,
      };
    case EXPEDIENTES_MMA_FAVORITO_OBTENER:
      return {
        ...state,
        expediente_favorito: action.payload,
        expediente_favorito_error: {},
        is_loading_mma: false,
      };
    case EXPEDIENTES_MMA_FAVORITO_FALLIDO:
      return {
        ...state,
        expediente_favorito: {},
        expediente_favorito_error: action.payload,
        is_loading_mma: false,
      };
    case CARGANDO_LIMPIAR_MMA:
      return {
        ...state,
        is_loading_mma: true,
        normas_ultimas: {},
      };
    case EXPEDIENTES_MMA_OBTENER:
      return {
        ...state,
        expedientes: action.payload.expedientes,
        filtros_expedientes: action.payload.filtros,
        is_loading_mma: false,
      };
    case EXPEDIENTES_MMA_FALLIDO:
      return {
        ...state,
        expedientes: {},
        expedientes_filtros: {},
        is_loading_mma: false,
      };
    case EXPEDIENTE_MMA_GUARDAR:
      return {
        ...state,
        expediente_id: action.payload.id,
      };
    case EXPEDIENTE_MMA_OBTENER:
      return {
        ...state,
        expediente: action.payload,
        is_loading_mma: false,
      };
    case EXPEDIENTE_MMA_FALLIDO:
      return {
        ...state,
        expediente: {},
        is_loading_mma: false,
      };
    case EXPEDIENTES_MMA_FILTROS_INPUT:
      return {
        ...state,
        expedientes_filtro_input: action.payload,
      };
    case EXPEDIENTES_MMA_FILTROS_OBTENER:
      return {
        ...state,
        expedientes: action.payload.expedientes,
        filtros_errores: {},
        is_loading_mma: false,
      };
    case EXPEDIENTES_MMA_FILTROS_FALLIDO:
      return {
        ...state,
        expedientes_filtros: {},
        filtros_errores: action.payload,
        is_loading_mma: false,
      };
    case LIMPIAR_EXPEDIENTES_MMA_FILTROS:
      return {
        ...state,
        is_loading_mma: true,
        expedientes_filtros: {},
        expedientes_filtro_input: {},
        expedientes: {},
      };
    default:
      return state;
  }
}
