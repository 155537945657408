import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  ENCARGADO_FALLIDO,
  ENCARGADO_GUARDAR,
  ENCARGADO_OBTENER,
  ENCARGADOS_FALLIDO,
  ENCARGADOS_FILTRO_FALLIDO,
  ENCARGADOS_FILTRO_OBTENER,
  ENCARGADOS_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerEncargados = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/encargados/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: ENCARGADOS_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : ENCARGADOS_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerEncargado = (id) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(`${url_api}/api/vigilances/encargado/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ENCARGADO_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : ENCARGADO_FALLIDO,
        payload: error,
      });
    });
};

export const guardarEncargado = (id) => (dispatch) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  dispatch({ type: ENCARGADO_GUARDAR, payload: id });
};

export const obtenerEncargadoFiltros = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/encargados/filtros`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: ENCARGADOS_FILTRO_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : ENCARGADOS_FILTRO_FALLIDO,
        payload: error,
      });
    });
};
