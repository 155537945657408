import React, { Component } from "react";

export class Tabla extends Component {
  render = () => {
    const { normas, bg = "secondary" } = this.props;

    return (
      <table className="table table-bordered table-hover">
        <thead className={`table-${bg}`}>
          <tr>
            <th className="w-3">ID</th>
            <th>Nombre</th>
            <th className="w-10">Tipo</th>
            <th className="w-10">Número</th>
            <th className="w-10">Año</th>
          </tr>
        </thead>
        <tbody>
          {normas && normas.length > 0 ? normas.map((norma, x) => (
            <tr key={x}>
              <td className="text-center">{x + 1}</td>
              <td>
                <a href={norma.link} target="_blank" rel="noopener noreferrer">
                  {norma.nombre} <i className="fa fa-angle-right"></i>
                </a>
              </td>
              <td>{norma.tipo}</td>
              <td>{norma.numero}</td>
              <td>{norma.anio}</td>
            </tr>
          )) : 
          <tr>
            <td colSpan="5" className="text-center">No hay instrumentos</td>
          </tr>
          }
        </tbody>
      </table>
    );
  };
}

export default Tabla;
