import axios from "axios";

import { tokenConfig } from "../../auth/action";
import { AUTENTIFICACION_FALLIDO } from "../../auth/types";
import { CARGANDO_LIMPIAR_VIGILANCIAS } from "../types";
import {
  LIMPIAR_MEDIDAS_FILTROS,
  MEDIDA_PROVISIONAL_FALLIDO,
  MEDIDA_PROVISIONAL_GUARDAR,
  MEDIDA_PROVISIONAL_OBTENER,
  MEDIDAS_PROVISIONALES_ESTADISTICAS_FALLIDO,
  MEDIDAS_PROVISIONALES_ESTADISTICAS_OBTENER,
  MEDIDAS_PROVISIONALES_FALLIDO,
  MEDIDAS_PROVISIONALES_FILTROS_FALLIDO,
  MEDIDAS_PROVISIONALES_FILTROS_INPUT,
  MEDIDAS_PROVISIONALES_FILTROS_OBTENER,
  MEDIDAS_PROVISIONALES_OBTENER,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const obtenerMedidasProvisionalesEstadistica =
  (pagina) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .get(
        `${url_api}/api/vigilances/medidas-provisionales/estadisticas/${pagina}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: MEDIDAS_PROVISIONALES_ESTADISTICAS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : MEDIDAS_PROVISIONALES_ESTADISTICAS_FALLIDO,
          payload: error,
        });
      });
  };

export const obtenerMedidaProvisional = (data) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .post(
      `${url_api}/api/vigilances/medida-provisional`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: MEDIDA_PROVISIONAL_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : MEDIDA_PROVISIONAL_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerMedidasProvisionales = (pagina) => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
  axios
    .get(
      `${url_api}/api/vigilances/medidas-provisionales/${pagina}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({ type: MEDIDAS_PROVISIONALES_OBTENER, payload: res.data });
    })
    .catch((err) => {
      var error = err.response;
      dispatch({
        type:
          error && error.status === 401
            ? AUTENTIFICACION_FALLIDO
            : MEDIDAS_PROVISIONALES_FALLIDO,
        payload: error,
      });
    });
};

export const obtenerMedidasProvisionalesFiltros =
  (data) => (dispatch, getState) => {
    dispatch({ type: CARGANDO_LIMPIAR_VIGILANCIAS });
    axios
      .post(
        `${url_api}/api/vigilances/medidas-provisionales/filtros/${data.pagina}`,
        data.filtros,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: MEDIDAS_PROVISIONALES_FILTROS_INPUT,
          payload: data.filtros,
        });
        dispatch({
          type: MEDIDAS_PROVISIONALES_FILTROS_OBTENER,
          payload: res.data,
        });
      })
      .catch((err) => {
        var error = err.response;
        dispatch({
          type:
            error && error.status === 401
              ? AUTENTIFICACION_FALLIDO
              : MEDIDAS_PROVISIONALES_FILTROS_FALLIDO,
          payload: error,
        });
      });
  };

export const guardarMedidaProvisional = (id, componente) => (dispatch) => {
  dispatch({
    type: MEDIDA_PROVISIONAL_GUARDAR,
    payload: { id: id, componente: componente },
  });
};

export const limpiarFiltroMedidas = () => (dispatch) => {
  dispatch({ type: LIMPIAR_MEDIDAS_FILTROS });
};
