import InputMask from "@mona-health/react-input-mask";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { CheckTreePicker } from "rsuite";
import { format as formatRut } from "rut.js";
import {
  limpiarFiltroPrograma,
  obtenerProgramasCumplimiento,
  obtenerProgramasCumplimientoFiltros,
} from "../../../redux/actions/vigilancias/programas/action";

export class Programas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_tipologia:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.categoria
          ? this.props.programas_filtro_input.categoria
          : [],
      filtro_region:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.region
          ? this.props.programas_filtro_input.region
          : [],
      filtro_estado:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.estado
          ? this.props.programas_filtro_input.estado
          : [],
      filtro_tipo:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.tipo
          ? this.props.programas_filtro_input.tipo
          : [],
      filtro_frecuencia:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.frecuencia_reporte
          ? this.props.programas_filtro_input.frecuencia_reporte
          : [],
      filtro_expediente:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.expediente
          ? this.props.programas_filtro_input.expediente
          : "",
      filtro_fecha_resolucion:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.fecha_resolucion
          ? this.props.programas_filtro_input.fecha_resolucion
          : "",
      filtro_fecha_termino:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.fecha_termino
          ? this.props.programas_filtro_input.fecha_termino
          : "",
      filtro_titular_nombre:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.titular_nombre
          ? this.props.programas_filtro_input.titular_nombre
          : "",
      filtro_titular_rut:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.titular_rut
          ? this.props.programas_filtro_input.titular_rut
          : "",
      filtro_palabra_clave:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.palabra_clave
          ? this.props.programas_filtro_input.palabra_clave
          : "",
      filtro_hecho:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.hecho
          ? this.props.programas_filtro_input.hecho
          : "",
      filtro_tag:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.tag
          ? this.props.programas_filtro_input.tag
          : "",
      filtros_avanzandos:
        this.props.programas_filtro_input &&
        this.props.programas_filtro_input.filtros_avanzandos
          ? this.props.programas_filtro_input.filtros_avanzandos
          : false,
    };
  }

  filtroProgramas = () => {
    const { obtenerProgramasCumplimientoFiltros } = this.props;
    const {
      filtro_tipologia,
      filtro_region,
      filtro_estado,
      filtro_tipo,
      filtro_frecuencia,
      filtro_expediente,
      filtro_fecha_resolucion,
      filtro_fecha_termino,
      filtro_titular_nombre,
      filtro_titular_rut,
      filtro_palabra_clave,
      filtro_hecho,
      filtros_avanzandos,
      filtro_tag,
    } = this.state;
    if (
      filtro_tipologia ||
      filtro_region ||
      filtro_estado ||
      filtro_tipo ||
      filtro_frecuencia ||
      filtro_expediente ||
      filtro_fecha_resolucion ||
      filtro_fecha_termino ||
      filtro_titular_nombre ||
      filtro_titular_rut ||
      filtro_palabra_clave ||
      filtro_hecho ||
      filtro_tag
    ) {
      var estado = filtro_estado;
      if (filtro_estado === "S/I" || !filtro_estado) {
        estado = null;
      }
      var filtros = {
        categoria: filtro_tipologia ? filtro_tipologia : null,
        region: filtro_region ? filtro_region : null,
        estado: estado,
        tipo: filtro_tipo ? filtro_tipo : null,
        frecuencia_reporte: filtro_frecuencia ? filtro_frecuencia : null,
        expediente: filtro_expediente ? filtro_expediente : null,
        fecha_resolucion: filtro_fecha_resolucion
          ? filtro_fecha_resolucion
          : null,
        fecha_termino: filtro_fecha_termino ? filtro_fecha_termino : null,
        titular_nombre: filtro_titular_nombre ? filtro_titular_nombre : null,
        titular_rut: filtro_titular_rut ? filtro_titular_rut : null,
        palabra_clave: filtro_palabra_clave ? filtro_palabra_clave : null,
        hecho: filtro_hecho ? filtro_hecho : null,
        tag: filtro_tag ? filtro_tag : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerProgramasCumplimientoFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerProgramasCumplimiento(1);
    }
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  borrarFiltros = () => {
    const { obtenerProgramasCumplimiento, limpiarFiltroPrograma } = this.props;
    this.setState({
      filtro_tipologia: [],
      filtro_region: [],
      filtro_estado: [],
      filtro_tipo: [],
      filtro_frecuencia: [],
      filtro_expediente: "",
      filtro_fecha_resolucion: "",
      filtro_fecha_termino: "",
      filtro_titular_nombre: "",
      filtro_titular_rut: "",
      filtro_palabra_clave: "",
      filtro_hecho: "",
      filtro_tag: "",
    });
    limpiarFiltroPrograma();
    obtenerProgramasCumplimiento(1);
  };

  onChange = (e) => {
    var valor = e.target.value;
    if (e.target.name === "filtro_titular_rut") {
      if (valor.length > 0) {
        valor = formatRut(valor);
      }
    }
    this.setState({ [e.target.name]: valor });
  };

  render = () => {
    const {
      filtro_tipologia,
      filtro_region,
      filtro_estado,
      filtro_tipo,
      filtro_frecuencia,
      filtro_expediente,
      filtro_fecha_resolucion,
      filtro_fecha_termino,
      filtro_titular_nombre,
      filtro_titular_rut,
      filtro_palabra_clave,
      filtros_avanzandos,
      filtro_hecho,
      filtro_tag,
    } = this.state;
    const { filtros_programas, filtros_errores } = this.props;

    const tipologias_listado =
      filtros_programas && filtros_programas["tipologias"]
        ? _.orderBy(filtros_programas["tipologias"], "nombre")
        : [];
    const regiones_listado =
      filtros_programas && filtros_programas["regiones"]
        ? _.orderBy(filtros_programas["regiones"], "nombre")
        : [];
    const estados_listado =
      filtros_programas && filtros_programas["estados"]
        ? _.orderBy(filtros_programas["estados"], "nombre")
        : [];
    const tipos_listado =
      filtros_programas && filtros_programas["tipos"]
        ? _.orderBy(filtros_programas["tipos"], "nombre")
        : [];
    const frecuencias_listado =
      filtros_programas && filtros_programas["frecuencias"]
        ? _.orderBy(filtros_programas["frecuencias"], "nombre")
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>
                  Búsqueda por palabras claves
                  <i
                    className="fa fa-exclamation-circle ms-1 text-warning font-11"
                    data-tip
                    data-tooltip-id="palabraclave_pdc"
                  ></i>
                  <Tooltip
                    className="w-30"
                    id="palabraclave_pdc"
                    aria-haspopup="true"
                  >
                    Este campo de búsqueda permite buscar acciones propuestas
                    por un regulado en el marco de un PDC. Puedes buscar por
                    frases o palabras que quieres que estén presentes en la
                    acción. Ejemplo: la frase aguas servidas; o la palabra
                    suelo; o la combinación aguas servida norma.
                  </Tooltip>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_palabra_clave"
                    value={filtro_palabra_clave}
                    onChange={this.onChange}
                    placeholder="Ingrese palabra clave"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.palabra_clave
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.palabra_clave &&
                    filtros_errores.data.palabra_clave.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Expediente</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_expediente"
                    value={filtro_expediente}
                    onChange={this.onChange}
                    placeholder="Ingrese expediente"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.expediente
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.expediente &&
                    filtros_errores.data.expediente.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Búsqueda por Hecho Infraccional</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_hecho"
                    value={filtro_hecho}
                    onChange={this.onChange}
                    placeholder="Ingrese palabra clave"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.hecho
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.hecho &&
                    filtros_errores.data.hecho.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de Resolución</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha_resolucion"
                    value={filtro_fecha_resolucion}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha de resolución"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_resolucion
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_resolucion &&
                    filtros_errores.data.fecha_resolucion.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de Término</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha_termino"
                    value={filtro_fecha_termino}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha de término"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_termino
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_termino &&
                    filtros_errores.data.fecha_termino.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipología</label>
                <CheckTreePicker
                  name="filtro_tipologia"
                  data={tipologias_listado}
                  onChange={(value) => {
                    this.setState({ filtro_tipologia: value });
                  }}
                  value={filtro_tipologia}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.categoria &&
                  filtros_errores.data.categoria.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Región</label>
                <CheckTreePicker
                  name="filtro_region"
                  data={regiones_listado}
                  onChange={(value) => {
                    this.setState({ filtro_region: value });
                  }}
                  value={filtro_region}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.region &&
                  filtros_errores.data.region.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Estado</label>
                <CheckTreePicker
                  name="filtro_estado"
                  data={estados_listado}
                  onChange={(value) => {
                    this.setState({ filtro_estado: value });
                  }}
                  value={filtro_estado}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.estado &
                    filtros_errores.data.estado.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipo</label>
                <CheckTreePicker
                  name="filtro_tipo"
                  data={tipos_listado}
                  onChange={(value) => {
                    this.setState({ filtro_tipo: value });
                  }}
                  value={filtro_tipo}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipo &&
                  filtros_errores.data.tipo.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Frecuencia de Reporte</label>
                <CheckTreePicker
                  name="filtro_frecuencia"
                  data={frecuencias_listado}
                  onChange={(value) => {
                    this.setState({ filtro_frecuencia: value });
                  }}
                  value={filtro_frecuencia}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.frecuencia_reporte &&
                  filtros_errores.data.frecuencia_reporte.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Nombre del Titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_nombre"
                    value={filtro_titular_nombre}
                    onChange={this.onChange}
                    placeholder="Ingrese nombre del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_nombre
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_nombre &&
                    filtros_errores.data.titular_nombre.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>RUT del Titular</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_titular_rut"
                    value={filtro_titular_rut}
                    onChange={this.onChange}
                    placeholder="Ingrese rut del titular"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.titular_rut
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.titular_rut &&
                    filtros_errores.data.titular_rut.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tag</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_tag"
                    value={filtro_tag}
                    onChange={this.onChange}
                    placeholder="Ingrese tag"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.tag
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.tag &&
                    filtros_errores.data.tag.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtroProgramas}
            >
              <i className="fa fa-search"></i> Filtrar programas de cumplimiento
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Programas.propTypes = {
  obtenerProgramasCumplimiento: PropTypes.func.isRequired,
  obtenerProgramasCumplimientoFiltros: PropTypes.func.isRequired,
  limpiarFiltroPrograma: PropTypes.func.isRequired,
  filtros_programas: PropTypes.object.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  programas_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pertinencias: state.vigilancias.pertinencias,
  filtros_programas: state.vigilancias.filtros_programas,
  filtros_errores: state.vigilancias.filtros_errores,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  programas_filtro_input: state.vigilancias.programas_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerProgramasCumplimiento,
      obtenerProgramasCumplimientoFiltros,
      limpiarFiltroPrograma,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Programas);
