import axios from 'axios';

import { tokenConfig } from '../../auth/action';
import { AUTENTIFICACION_FALLIDO } from '../../auth/types';
import { CARGANDO_LIMPIAR_TAREAS } from '../types';
import {
  PLANACCION_ELIMINAR_FALLIDO,
  PLANACCION_ELIMINAR_OBTENER,
  PLANACCION_ESTADO_FALLIDO,
  PLANACCION_ESTADO_OBTENER,
  PLANACCION_FALLIDO,
  PLANACCION_OBTENER,
} from './types';

var url_api = process.env.REACT_APP_API_URL;


export const nuevoPlanAccion = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_TAREAS });
  axios
    .post(`${url_api}/api/tasks/plan-accion`, data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: PLANACCION_OBTENER, payload: res.data });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PLANACCION_FALLIDO,
        payload: error
      });
    });
};

export const eliminarPlandeAccion = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_TAREAS });
  axios
    .post(`${url_api}/api/tasks/plan-accion/eliminar`, data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: PLANACCION_ELIMINAR_OBTENER, payload: res.data });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PLANACCION_ELIMINAR_FALLIDO,
        payload: error
      });
    });
};

export const estadoPlandeAccion = data => (dispatch, getState) => {
  dispatch({ type: CARGANDO_LIMPIAR_TAREAS });
  axios
    .post(`${url_api}/api/tasks/plan-accion/estado`, data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: PLANACCION_ESTADO_OBTENER, payload: res.data });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: error && error.status === 401 ? AUTENTIFICACION_FALLIDO : PLANACCION_ESTADO_FALLIDO,
        payload: error
      });
    });
};
