import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

export class FichaProyecto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      esconder_ficha: true,
    };
  }

  verFicha = (estado) => {
    this.setState({
      esconder_ficha: estado,
    });
  };

  render = () => {
    const { proyecto_seleccionado } = this.props;
    const { esconder_ficha } = this.state;
    return (
      <>
        <h5 className="mt-0 float-start mt-1">
          Ficha del proyecto
          {esconder_ficha ? (
            <i
              onClick={this.verFicha.bind(this, false)}
              type="button"
              className="ms-1 fa fa-angle-down"
            ></i>
          ) : (
            <i
              onClick={this.verFicha.bind(this, true)}
              type="button"
              className="ms-1 fa fa-angle-up"
            ></i>
          )}
        </h5>
        <div className="mt-0 pb-1">
          {proyecto_seleccionado && (
            <div className={`mt-5 ${esconder_ficha ? "d-none" : ""}`}>
              <div className="table-responsive">
                <table className="table table-sm table-bordered bg-white">
                  <tbody>
                    <tr>
                      <td className="table-secondary w-45 text-dark">
                        Nombre del proyecto
                      </td>
                      <td>{proyecto_seleccionado.nombre}</td>
                    </tr>
                    <tr>
                      <td className="table-secondary text-dark">Cliente</td>
                      <td>{proyecto_seleccionado.empresa}</td>
                    </tr>
                    {proyecto_seleccionado.tipo_display && (
                      <tr>
                        <td className="table-secondary text-dark">Tipo</td>
                        <td>{proyecto_seleccionado.tipo_display}</td>
                      </tr>
                    )}
                    {proyecto_seleccionado.elemento_tipo &&
                      proyecto_seleccionado.elemento_tipo.length > 0 && (
                        <tr>
                          <td className="table-secondary text-dark">
                            Elemento consultado
                          </td>
                          <td>
                            <ul className="ps-4 mb-0">
                              {proyecto_seleccionado.elemento_tipo.map(
                                (elemento, x) => (
                                  <li key={x}>{elemento.nombre}</li>
                                )
                              )}
                            </ul>
                          </td>
                        </tr>
                      )}
                    {proyecto_seleccionado.elemento_nombre && (
                      <tr>
                        <td className="table-secondary text-dark">
                          Nombre del elemento consultado
                        </td>
                        <td>{proyecto_seleccionado.elemento_nombre}</td>
                      </tr>
                    )}
                    {proyecto_seleccionado.titular && (
                      <tr>
                        <td className="table-secondary text-dark">
                          Titular del elemento consultado
                        </td>
                        <td>{proyecto_seleccionado.titular}</td>
                      </tr>
                    )}
                    {proyecto_seleccionado.comuna_display &&
                      proyecto_seleccionado.region_display && (
                        <tr>
                          <td className="table-secondary text-dark">
                            Ubicación geográfica del elemento consultado
                          </td>
                          <td>
                            {proyecto_seleccionado.region_display}{" "}
                            <i className="fa fa-angle-right"></i> Comuna:{" "}
                            {proyecto_seleccionado.comuna_display}
                          </td>
                        </tr>
                      )}
                    {proyecto_seleccionado.estado &&
                      proyecto_seleccionado.estado.length > 0 && (
                        <tr>
                          <td className="table-secondary text-dark">
                            Estado del elemento consultado
                          </td>
                          <td>
                            <ul className="ps-4 mb-0">
                              {proyecto_seleccionado.estado.map((estado, x) => (
                                <li key={x}>{estado.nombre}</li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      )}
                    {proyecto_seleccionado.tipologia && (
                      <tr>
                        <td className="table-secondary text-dark">
                          Tipología del proyecto
                        </td>
                        <td>{proyecto_seleccionado.tipologia.nombre}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };
}

FichaProyecto.propTypes = {
  proyecto_seleccionado: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  proyecto_seleccionado: state.permitting.proyecto_seleccionado,
});

export default connect(mapStateToProps, null)(FichaProyecto);
