import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { obtenerDocumento } from "../../../redux/actions/vigilancias/documentos/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Guardar as GuardarFavorito } from "../favoritos/";
import { Tabs } from "./";

export class Detalle extends Component {
  componentDidMount = () => {
    const { obtenerDocumento, documento_id } = this.props;
    if (documento_id > 0) {
      obtenerDocumento({
        id: documento_id,
      });
    }
  };

  render = () => {
    const { is_loading_vigilancias, documento_id, documento } = this.props;

    if (documento_id === 0) {
      return <Navigate to="/compliance-analytics/documentos" />;
    }

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Documento",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo el documento, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="vigilancias_documentos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-file-archive"></i> DOCUMENTOS
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-lg-9">
                        <h5 className="float-start">
                          {documento ? documento.nombre : "S/N"}
                        </h5>
                      </div>
                      <div className="col-lg-3">
                        <div className="btn-group">
                          {documento && (
                            <GuardarFavorito
                              tipo="documento"
                              id={documento.id}
                            />
                          )}
                          <Link
                            className="btn btn-primary"
                            to="/compliance-analytics/documentos"
                          >
                            <i className="fa fa-angle-left"></i> Volver al
                            listado
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {documento && <Tabs documentos={[documento]} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerDocumento: PropTypes.func.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
  documento: PropTypes.object.isRequired,
  documento_id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
  documento: state.vigilancias.documento,
  documento_id: state.vigilancias.documento_id,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ obtenerDocumento }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
