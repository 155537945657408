import React, { Component } from "react";

export class TablaPequena extends Component {
  render = () => {
    const { instrumentos } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre</th>
              <th>Enlace</th>
            </tr>
          </thead>
          <tbody>
            {instrumentos && instrumentos.length > 0 ? (
              instrumentos.map((inst, a) => (
                <tr key={a}>
                  <td className="text-center">{a + 1}</td>
                  <td>{inst.nombre}</td>
                  <td>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={inst.url}
                      className="btn btn-primary btn-xs"
                    >
                      Ver <i className="fa fa-angle-right"></i>
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan="2">No existen instrumentos infringidos</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

export default TablaPequena;
