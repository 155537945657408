import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { guardarExpediente } from "../../../redux/actions/vigilancias/expedientes/action";
import { TablaMedia as Expediente } from "../expedientes";

export class Tabla extends Component {
  setExpediente = (id, componente) => {
    const { guardarExpediente } = this.props;
    guardarExpediente({ id: id, componente: componente });
  };

  setExpedienteTag = (id) => {
    this.setState({
      is_tags: id,
    });
  };

  render = () => {
    const { folios } = this.props;

    return (
      <div className="table-responsive">
        <Tooltip id="detalle_expediente" aria-haspopup="true">
          Ver detalle del documento SEIA
        </Tooltip>
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre del Documento</th>
              <th className="w-10">Fecha Emisión</th>
              <th className="w-20">Organismo Emisor</th>
              <th className="w-20">Destinatario</th>
              <th className="w-25">Nombre del Proyecto</th>
            </tr>
          </thead>
          <tbody>
            {folios.result ? (
              folios.result.map((folio, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {folios.page > 1 ? (folios.page - 1) * 20 + (x + 1) : x + 1}
                  </td>
                  <td>
                    <div className="row align-items-center">
                      {folio.documentos && folio.documentos.length > 0 && (
                        <div className="col-12">
                          {folio.documentos.map((doc, y) => (
                            <p key={y} className="mb-0">
                              <a
                                href={doc.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-tip
                                data-tooltip-id="detalle_expediente"
                              >
                                <span className="me-1">
                                  <i className="fa fa-link"></i>
                                </span>
                                <span>{doc.nombre}</span>
                              </a>
                            </p>
                          ))}
                        </div>
                      )}
                      {folio.documentos && folio.documentos.length < 1 && (
                        <span className="badge badge-soft-dark">
                          Sin Información
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <span className="badge badge-primary font-11">
                      {folio.fecha ? folio.fecha : "S/I"}
                    </span>
                  </td>
                  <td className="text-uppercase">{folio.remitido}</td>
                  <td className="text-uppercase">
                    {folio.destinatario_url ? (
                      <a
                        target="_blank"
                        href={folio.destinatario_url}
                        rel="noreferrer"
                      >
                        <i className="fa fa-link me-1"></i>
                        {folio.destinatario_nombre}
                      </a>
                    ) : (
                      folio.destinatario_nombre
                    )}
                  </td>
                  <td>
                    <Expediente expediente={folio.expediente} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  Sin documentos
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

Tabla.propTypes = {
  guardarExpediente: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarExpediente }, dispatch);
};

export default connect(null, mapDispatchToProps)(Tabla);
