import { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import { limpiarMensajes } from "../../../redux/actions/tareas/action";
import { creacionProyecto } from "../../../redux/actions/tareas/proyectos/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { withRouter } from "../../general/with_router";

export class Nuevo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proyecto_nombre: "",
      proyecto_descripcion: "",
      proyecto_link: "",
      proyecto_id: "",
    };
  }

  crearProyecto = () => {
    const { proyecto_nombre, proyecto_descripcion, proyecto_link } = this.state;
    const { creacionProyecto } = this.props;
    creacionProyecto({
      nombre: proyecto_nombre,
      link: proyecto_link ? proyecto_link : null,
      descripcion: proyecto_descripcion ? proyecto_descripcion : null,
    });
    SweetAlert.fire({
      title: "Creación Proyecto",
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: "Estamos validando la información, espere unos segundos",
      icon: "warning",
      showConfirmButton: false,
      didOpen: () => {
        SweetAlert.showLoading();
      },
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render = () => {
    const { proyecto_link, proyecto_nombre, proyecto_descripcion } = this.state;
    const { limpiarMensajes, proyecto_crear_error, proyecto_crear } =
      this.props;

    if (Object.entries(proyecto_crear).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Proyecto creado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: proyecto_crear.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.props.navigate("/quality-tasking/proyectos");
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(proyecto_crear_error).length > 0) {
      SweetAlert.close();
      if (proyecto_crear_error.data && proyecto_crear_error.data.message) {
        SweetAlert.fire({
          title: "Proyecto no creado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: proyecto_crear_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio menu_1="tareas_proyectos" />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-tasks"></i> PROYECTOS
                  </h4>
                  <p className="float-end">
                    <Link
                      to="/quality-tasking/proyectos"
                      className="ms-2 btn btn-danger"
                    >
                      <i className="fa fa-angle-left"></i> Cancelar y volver
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header bg-primary text-white">
                    <h5>CREAR NUEVO PROYECTO</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 text-end">
                        <p className="text-danger">
                          <i className="fa fa-asterisk"></i> datos obligatorios
                        </p>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Nombre <small className="text-danger">*</small>
                          </label>
                          <input
                            type="text"
                            name="proyecto_nombre"
                            onChange={this.onChange}
                            value={proyecto_nombre}
                            placeholder="Ingrese un nombre"
                            className={
                              proyecto_crear_error &&
                              proyecto_crear_error.data &&
                              proyecto_crear_error.data.nombre
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {proyecto_crear_error &&
                            proyecto_crear_error.data &&
                            proyecto_crear_error.data.nombre &&
                            proyecto_crear_error.data.nombre.map((err, x) => (
                              <div key={x} className="invalid-feedback">
                                {err}
                              </div>
                            ))}
                        </div>
                        <div className="form-group">
                          <label>Link</label>
                          <input
                            type="text"
                            name="proyecto_link"
                            onChange={this.onChange}
                            value={proyecto_link}
                            placeholder="Ingrese un link o url"
                            className={
                              proyecto_crear_error &&
                              proyecto_crear_error.data &&
                              proyecto_crear_error.data.link
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {proyecto_crear_error &&
                            proyecto_crear_error.data &&
                            proyecto_crear_error.data.link &&
                            proyecto_crear_error.data.link.map((err, x) => (
                              <div key={x} className="invalid-feedback">
                                {err}
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Descripción</label>
                          <textarea
                            rows="5"
                            name="proyecto_descripcion"
                            onChange={this.onChange}
                            value={proyecto_descripcion}
                            placeholder="Ingrese una descripción"
                            className={
                              proyecto_crear_error &&
                              proyecto_crear_error.data &&
                              proyecto_crear_error.data.descripcion
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          ></textarea>
                          {proyecto_crear_error &&
                            proyecto_crear_error.data &&
                            proyecto_crear_error.data.descripcion &&
                            proyecto_crear_error.data.descripcion.map(
                              (err, x) => (
                                <div key={x} className="invalid-feedback">
                                  {err}
                                </div>
                              )
                            )}
                        </div>
                      </div>
                      <div className="col-sm-12 text-end">
                        <div className="btn-group">
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={this.crearProyecto}
                          >
                            Crear proyecto <i className="fa fa-save"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}

Nuevo.propTypes = {
  creacionProyecto: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  proyecto_crear: PropTypes.object.isRequired,
  proyecto_crear_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  proyecto_crear: state.tareas.proyecto_crear,
  proyecto_crear_error: state.tareas.proyecto_crear_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ creacionProyecto, limpiarMensajes }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nuevo));
