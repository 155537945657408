import { Component } from "react";

import InputMask from "@mona-health/react-input-mask";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  edicionEntregable,
  limpiarMensajes,
  obtenerTarea,
} from "../../../redux/actions/tareas/action";

export class Editar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entregable_id:
        this.props.entregable && this.props.entregable.id
          ? this.props.entregable.id
          : "",
      entregable_nombre:
        this.props.entregable && this.props.entregable.nombre
          ? this.props.entregable.nombre
          : "",
      entregable_tipo:
        this.props.entregable && this.props.entregable.tipo
          ? this.props.entregable.tipo
          : "",
      entregable_descripcion:
        this.props.entregable && this.props.entregable.descripcion
          ? this.props.entregable.descripcion
          : "",
      entregable_fecha_entrega:
        this.props.entregable && this.props.entregable.fecha_entrega
          ? this.props.entregable.fecha_entrega
          : "",
    };
  }

  cancelarEdicionEntregable = () => {
    this.props.cancelarEdicionEntregable();
  };

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  editarEntregable = () => {
    const { edicionEntregable, tarea_seleccionada } = this.props;
    const {
      entregable_id,
      entregable_nombre,
      entregable_fecha_entrega,
      entregable_tipo,
      entregable_descripcion,
    } = this.state;
    edicionEntregable({
      id: entregable_id,
      nombre: entregable_nombre,
      fecha_entrega: entregable_fecha_entrega,
      tipo: entregable_tipo,
      descripcion: entregable_descripcion,
      tarea: tarea_seleccionada,
    });
    SweetAlert.fire({
      title: "Edición Entregable",
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: "Estamos validando el entregable editado, espere unos segundos",
      icon: "warning",
      showConfirmButton: false,
      didOpen: () => {
        SweetAlert.showLoading();
      },
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render = () => {
    const {
      entregable_nombre,
      entregable_fecha_entrega,
      entregable_tipo,
      entregable_descripcion,
    } = this.state;
    const { limpiarMensajes, entregable_editar, entregable_editar_error } =
      this.props;

    if (Object.entries(entregable_editar).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Entregable editado",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: entregable_editar.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.tareaSeleccionada();
          this.cancelarEdicionEntregable();
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(entregable_editar_error).length > 0) {
      SweetAlert.close();
      if (
        entregable_editar_error.data &&
        entregable_editar_error.data.message
      ) {
        SweetAlert.fire({
          title: "Entregable no editado",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: entregable_editar_error.data.message,
          icon: "error",
          confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            limpiarMensajes();
          }
        });
      }
    }

    return (
      <div className="card card-eco">
        <div className="card-header bg-primary text-white">
          <h5>Editar Entregable</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12 text-end">
              <p className="text-danger">
                <i className="fa fa-asterisk"></i> datos obligatorios
              </p>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>
                  Nombre del entregable <small className="text-danger">*</small>
                </label>
                <input
                  type="text"
                  name="entregable_nombre"
                  onChange={this.onChange}
                  value={entregable_nombre}
                  className={
                    entregable_editar_error &&
                    entregable_editar_error.data &&
                    entregable_editar_error.data.nombre
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ingrese un nombre"
                />
                {entregable_editar_error &&
                  entregable_editar_error.data &&
                  entregable_editar_error.data.nombre &&
                  entregable_editar_error.data.nombre.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
              <div className="form-group">
                <label>
                  Tipo del entregable <small className="text-danger">*</small>
                </label>
                <select
                  name="entregable_tipo"
                  onChange={this.onChange}
                  defaultValue={entregable_tipo}
                  className={
                    entregable_editar_error &&
                    entregable_editar_error.data &&
                    entregable_editar_error.data.tipo
                      ? "form-select is-invalid"
                      : "form-select"
                  }
                >
                  <option value="">Seleccione una opción</option>
                  <option value="archivo">Archivo</option>
                  <option value="texto">Texto</option>
                </select>
                {entregable_editar_error &&
                  entregable_editar_error.data &&
                  entregable_editar_error.data.tipo &&
                  entregable_editar_error.data.tipo.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Fecha de entrega del entregable</label>
                <InputMask
                  name="entregable_fecha_entrega"
                  value={entregable_fecha_entrega}
                  onChange={this.onChange}
                  mask="99/99/9999"
                  placeholder="Ingrese una fecha de término"
                  className={
                    entregable_editar_error &&
                    entregable_editar_error.data &&
                    entregable_editar_error.data.fecha_entrega
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
                {entregable_editar_error &&
                  entregable_editar_error.data &&
                  entregable_editar_error.data.fecha_entrega &&
                  entregable_editar_error.data.fecha_entrega.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
              <div className="form-group">
                <label>
                  Descripción del entregable{" "}
                  <small className="text-danger">*</small>
                </label>
                <textarea
                  rows="4"
                  name="entregable_descripcion"
                  onChange={this.onChange}
                  value={entregable_descripcion}
                  className={
                    entregable_editar_error &&
                    entregable_editar_error.data &&
                    entregable_editar_error.data.descripcion
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Ingrese una descripción"
                ></textarea>
                {entregable_editar_error &&
                  entregable_editar_error.data &&
                  entregable_editar_error.data.descripcion &&
                  entregable_editar_error.data.descripcion.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-sm-12 text-end">
              <div className="btn-group">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.editarEntregable}
                >
                  Editar entregable <i className="fa fa-save"></i>
                </button>
                <button
                  onClick={this.cancelarEdicionEntregable}
                  className="btn btn-danger"
                  type="button"
                >
                  Cancelar <i className="fa fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

Editar.propTypes = {
  limpiarMensajes: PropTypes.func.isRequired,
  edicionEntregable: PropTypes.func.isRequired,
  obtenerTarea: PropTypes.func.isRequired,
  entregable_editar: PropTypes.object.isRequired,
  entregable_editar_error: PropTypes.object.isRequired,
  tarea_seleccionada: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  entregable_editar: state.tareas.entregable_editar,
  entregable_editar_error: state.tareas.entregable_editar_error,
  tarea_seleccionada: state.tareas.tarea_seleccionada,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { limpiarMensajes, edicionEntregable, obtenerTarea },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Editar);
