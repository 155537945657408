import React, { Component } from "react";
import { Tooltip } from "react-tooltip";
import SweetAlert from "sweetalert2";
import { Responsable } from "./";

export class Ficha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responsable_entregable_mostrar: false,
    };
  }

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  cancelarResponsableEntregable = () => {
    this.setState({
      responsable_entregable_mostrar: false,
    });
  };

  mostrarAsignarResponsableEntregable = () => {
    const { tarea } = this.props;
    if (tarea.equipo.length > 1) {
      this.setState({
        responsable_entregable_mostrar: true,
      });
    } else {
      SweetAlert.fire({
        title: "Responsable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Para asignar un responsable del entregable el equipo debe tener al menos 2 miembros del equipo",
        icon: "warning",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    }
  };

  render = () => {
    const { responsable_entregable_mostrar } = this.state;
    const { entregable, user_equipo } = this.props;

    return (
      <div className="row">
        <div
          className={
            responsable_entregable_mostrar && !entregable.responsable
              ? "col-lg-8"
              : "col-lg-12"
          }
        >
          <div className="table-responsive">
            <table className="table">
              <tbody>
                <tr>
                  <td className="table-secondary w-25 text-dark">
                    Originador del Entregable
                  </td>
                  <td>{entregable.usuario_text}</td>
                </tr>
                {entregable.estado_text !== "Finalizada" &&
                  user_equipo.rol_text === "Propietario" && (
                    <tr>
                      <td className="table-secondary text-dark">Responsable</td>
                      <td>
                        {entregable.responsable ? (
                          entregable.responsable
                        ) : (
                          <button
                            onClick={this.mostrarAsignarResponsableEntregable}
                            className="btn btn-warning"
                          >
                            Asignar un responsable{" "}
                            <i className="fa fa-user-plus"></i>
                          </button>
                        )}
                      </td>
                    </tr>
                  )}
                <tr>
                  <td className="table-secondary text-dark">
                    Fecha de Creación del Entregable
                  </td>
                  <td>{entregable.fecha_creacion}</td>
                </tr>
                <tr>
                  <td className="table-secondary text-dark">
                    Estado del Entregable
                  </td>
                  <td>{entregable.estado_text}</td>
                </tr>
                {entregable.fecha_entrega && (
                  <tr>
                    <td className="table-secondary text-dark">
                      Fecha de Entrega del Entregable
                    </td>
                    <td>{entregable.fecha_entrega}</td>
                  </tr>
                )}
                {entregable.fecha_inicio && (
                  <tr>
                    <td className="table-secondary text-dark">
                      Fecha de Inicio del Entregable
                    </td>
                    <td>{entregable.fecha_inicio}</td>
                  </tr>
                )}
                {entregable.fecha_termino && (
                  <tr>
                    <td className="table-secondary text-dark">
                      Fecha de Término del Entregable
                    </td>
                    <td>{entregable.fecha_termino}</td>
                  </tr>
                )}
                {entregable.descripcion && (
                  <tr>
                    <td className="table-secondary text-dark">
                      Descripción del Entregable
                    </td>
                    <td>{entregable.descripcion}</td>
                  </tr>
                )}
                <tr>
                  <td className="table-secondary text-dark">
                    Tipo del Entregable
                  </td>
                  <td>
                    <span>{entregable.tipo_text}</span>
                    {entregable.tipo_text === "Archivo" && (
                      <>
                        <i
                          data-tip
                          data-tooltip-id="archivo"
                          className="fa fa-exclamation-circle ms-1 text-warning"
                        ></i>
                        <Tooltip id="archivo" aria-haspopup="true">
                          A nivel de archivo, corresponde a un archivo digital
                          que el colaborador carga a la plataforma. Ejemplo:
                          Archivo PDF.
                        </Tooltip>
                      </>
                    )}
                    {entregable.tipo_text === "Texto" && (
                      <>
                        <i
                          data-tip
                          data-tooltip-id="texto"
                          className="fa fa-exclamation-circle ms-1 text-warning"
                        ></i>
                        <Tooltip id="texto" aria-haspopup="true">
                          A nivel de texto, corresponde a una narración escrita
                          aportada por el colaborador en la plataforma.
                        </Tooltip>
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {responsable_entregable_mostrar && !entregable.responsable && (
          <div className="col-lg-4">
            <Responsable
              entregable={entregable}
              tareaSeleccionada={this.tareaSeleccionada}
              cancelarResponsableEntregable={this.cancelarResponsableEntregable}
            />
          </div>
        )}
      </div>
    );
  };
}

export default Ficha;
