import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { guardarHecho } from "../../../redux/actions/vigilancias/hechos/action";
import { Eliminar as EliminarFavorito } from "../favoritos";
import { TablaPequena as Titulares } from "../titulares";

export class TablaMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_tags: 0,
    };
  }

  setExpedienteTag = (id) => {
    this.setState({
      is_tags: id,
    });
  };

  setHecho = (id) => {
    const { guardarHecho } = this.props;
    guardarHecho(id);
  };

  render = () => {
    const { hechos, eliminar_favorito } = this.props;
    const { is_tags } = this.state;

    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-secondary">
            <tr>
              <th className="text-center w-3">ID</th>
              <th>Nombre del hecho infraccional</th>
              <th className="text-center w-8">
                Clasificación
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="clasificacion_titulo"
                ></i>
                <Tooltip id="clasificacion_titulo" aria-haspopup="true">
                  Art.36 LOSMA
                </Tooltip>
              </th>
              <th className="w-17">
                Tipo de infracción
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="tipo_titulo"
                ></i>
                <Tooltip id="tipo_titulo" aria-haspopup="true">
                  Art.35 LOSMA
                </Tooltip>
              </th>
              <th className="w-15">Titular</th>
              <th className="w-5">
                Fecha
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="fechainicio_titulo"
                ></i>
                <Tooltip id="fechainicio_titulo" aria-haspopup="true">
                  Fecha de inicio del procedimiento sancionatorio
                </Tooltip>
              </th>
              <th className="w-8">
                Estado
                <i
                  className="fa fa-exclamation-circle ms-1 text-warning font-11"
                  data-tip
                  data-tooltip-id="estado_titulo"
                ></i>
                <Tooltip id="estado_titulo" aria-haspopup="true">
                  Estado del procedimiento sancionatorio
                </Tooltip>
              </th>
              <th className="w-5 text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            {hechos && hechos.result && hechos.result.length > 0 ? (
              hechos.result.map((hecho, x) => (
                <tr key={x}>
                  <td className="text-center">
                    {hechos.page > 1 ? (hechos.page - 1) * 20 + (x + 1) : x + 1}
                  </td>
                  <td>
                    <span className="text-uppercase">{hecho.nombre}</span>
                    {hecho.tag &&
                      hecho.tag.length > 0 &&
                      (is_tags === hecho.id ? (
                        <p className="mt-2">
                          {hecho.tag.map((tag, z) => (
                            <span
                              key={z}
                              className="badge badge-soft-danger p-1 me-1 font-12"
                            >
                              #{tag}
                            </span>
                          ))}
                        </p>
                      ) : (
                        <p className="mt-1">
                          <button
                            onClick={this.setExpedienteTag.bind(this, hecho.id)}
                            type="button"
                            className="btn btn-sm btn-outline-danger btn-tags"
                          >
                            <i className="fa fa-hashtag"></i> TAG
                          </button>
                        </p>
                      ))}
                  </td>
                  <td className="text-center">
                    {hecho.clasificacion ? (
                      <Fragment>
                        {hecho.clasificacion}
                        <Tooltip
                          className="tooltip-limite"
                          id={`clasificacion_detalle_${x}`}
                          aria-haspopup="true"
                        >
                          {hecho.clasificacion_detalle}
                        </Tooltip>
                        <i
                          data-tip
                          data-tooltip-id={`clasificacion_detalle_${x}`}
                          className="fa fa-exclamation-circle text-danger ms-1 font-11"
                        ></i>
                      </Fragment>
                    ) : (
                      "S/I"
                    )}
                  </td>
                  <td>{hecho.infraccion}</td>
                  <td>
                    <Titulares titulares={hecho.titulares} />
                  </td>
                  <td>{hecho.procedimiento.fecha_inicio}</td>
                  <td>
                    <span className="badge badge-dark font-13 text-center">
                      {hecho.procedimiento.estado}
                    </span>
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {eliminar_favorito && (
                        <EliminarFavorito tipo="hecho" id={hecho.id} />
                      )}
                      <Link
                        to="/compliance-analytics/hecho-infraccional"
                        className="btn btn-soft-dark"
                        onClick={this.setHecho.bind(this, hecho.id)}
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center" colSpan="7">
                  No existen hechos infraccionales
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
}

TablaMedia.propTypes = {
  guardarHecho: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ guardarHecho }, dispatch);
};

export default connect(null, mapDispatchToProps)(TablaMedia);
