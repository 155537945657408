import React, { Component } from "react";

import { Link } from "react-scroll";

export class NavegacionNorma extends Component {
  seleccionarTitulo = (id, padre) => {
    this.props.seleccionarTitulo(id, padre);
  };

  render = () => {
    const { hijo, padre, titulo_seleccionado } = this.props;

    return (
      <li
        className={`link-norma ${
          titulo_seleccionado.indexOf(hijo.value) >= 0 ? "active" : ""
        }`}
      >
        <Link
          smooth={true}
          offset={-90}
          to={hijo.value.toString()}
          onClick={this.seleccionarTitulo.bind(this, hijo.value, padre)}
          href={`#${hijo.parte_id}`}
        >
          {hijo.label}
          {hijo.hijos && hijo.hijos.length > 0 && (
            <i className="float-end fa fa-angle-right"></i>
          )}
        </Link>
        {hijo.hijos && hijo.hijos.length > 0 && (
          <ul
            className={`list-unstyled ${
              titulo_seleccionado.indexOf(hijo.value) >= 0 ? "" : "d-none"
            }`}
          >
            {hijo.hijos.map((h, x) => (
              <NavegacionNorma
                key={x}
                hijo={h}
                padre={hijo.value}
                seleccionarTitulo={this.seleccionarTitulo}
                titulo_seleccionado={titulo_seleccionado}
              />
            ))}
          </ul>
        )}
      </li>
    );
  };
}

export default NavegacionNorma;
