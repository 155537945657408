import React, { Component } from "react";

import { TablaPequena as Titulares } from "../titulares";
import { TablaPequena as UnidadesFiscalizables } from "../unidades";

export class TablaMedia extends Component {
  render = () => {
    const { medida } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-borderless">
          <tbody>
            <tr>
              <td className="w-15 text-center">
                <a
                  href={medida.url_snifa}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {medida.expediente} <i className="fa fa-angle-right"></i>
                </a>
                <p className="mb-0">
                  <span className="text-center badge badge-soft-danger">
                    {medida.estado}
                  </span>
                </p>
              </td>
              <td className="w-30">
                <Titulares titulares={medida.titulares} />
              </td>
              <td className="w-30">
                <UnidadesFiscalizables
                  unidades_fiscalizables={medida.unidad_fiscalizable}
                />
              </td>
              <td className="w-30">
                <span className="badge badge-danger font-11">
                  Último Movimiento
                </span>
                <p className="mb-0">{medida.ultimo_documento.fecha}</p>
                <p className="mb-0 small text-uppercase">
                  {medida.ultimo_documento.nombre}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}

export default TablaMedia;
