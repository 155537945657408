import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";
import { estadoSidebar } from "../../../redux/actions/auth/action";
import {
  limpiarFiltroLite,
  obtenerLiteFiltros,
} from "../../../redux/actions/vigilancias/lite/action";

import { Footer, Navbar, Paginador, SidebarInicio } from "../../general";
import { Tabla } from "./";

export class Lite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_nombre: "",
    };
  }

  componentDidMount = () => {
    const { estadoSidebar, limpiarFiltroLite } = this.props;
    limpiarFiltroLite();
    estadoSidebar(true);
  };

  borrarFiltros = () => {
    const { limpiarFiltroLite } = this.props;
    limpiarFiltroLite();
    this.setState({
      filtro_nombre: "",
    });
  };

  filtroLite = () => {
    const { obtenerLiteFiltros } = this.props;
    const { filtro_nombre } = this.state;

    if (filtro_nombre) {
      var filtros = {
        nombre: filtro_nombre ? filtro_nombre : null,
      };
      obtenerLiteFiltros({ filtros: filtros, pagina: 1 });
    }
  };

  nuevaPagina = (pagina) => {
    const { obtenerLiteFiltros } = this.props;
    const { filtro_nombre } = this.state;

    if (filtro_nombre) {
      var filtros = {
        nombre: filtro_nombre ? filtro_nombre : null,
      };
      obtenerLiteFiltros({ filtros: filtros, pagina: pagina });
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { filtros_errores, lite_filtros, is_loading_vigilancias } =
      this.props;
    const { filtro_nombre } = this.state;

    if (is_loading_vigilancias) {
      SweetAlert.fire({
        title: "Lite",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos obteniendo la informacion solicitada, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <SidebarInicio menu_1="vigilancias_lite" />
        <Navbar />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-start"></i> COMPLIANCE ANALYTICS LITE
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card card-eco">
                <div className="card-header">
                  <h5 className="text-uppercase">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h5>
                </div>
                <div className="card-body">
                  <p className="mb-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    varius tincidunt efficitur. In sed maximus lacus.
                  </p>
                  <form className="row">
                    <div className="col-6">
                      <input
                        type="text"
                        name="filtro_nombre"
                        value={filtro_nombre}
                        onChange={this.onChange}
                        placeholder="Ingrese una palabra"
                        className={`form-control form-control-lg ${
                          filtros_errores &&
                          filtros_errores.data &&
                          filtros_errores.data.nombre
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {filtros_errores &&
                        filtros_errores.data &&
                        filtros_errores.data.nombre &&
                        filtros_errores.data.nombre.map((err, x) => (
                          <div key={x} className="invalid-feedback">
                            {err}
                          </div>
                        ))}
                    </div>
                    <div className="col-auto">
                      <div className="btn-group">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={this.filtroLite}
                        >
                          <i className="fa fa-search"></i> Buscar{" "}
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={this.borrarFiltros}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h5 className="float-start">
                    RESULTADOS OBTENIDOS{" "}
                    <small>
                      (
                      {lite_filtros && lite_filtros.total
                        ? lite_filtros.total
                        : 0}
                      )
                    </small>
                  </h5>
                </div>
                <div className="card-body">
                  <Paginador
                    paginador={lite_filtros}
                    nuevaPagina={this.nuevaPagina}
                  />
                  <Tabla data={lite_filtros} />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Lite.propTypes = {
  estadoSidebar: PropTypes.func.isRequired,
  obtenerLiteFiltros: PropTypes.func.isRequired,
  limpiarFiltroLite: PropTypes.func.isRequired,
  lite_filtros: PropTypes.object.isRequired,
  is_loading_vigilancias: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  lite_filtros: state.vigilancias.lite_filtros,
  is_loading_vigilancias: state.vigilancias.is_loading_vigilancias,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { estadoSidebar, obtenerLiteFiltros, limpiarFiltroLite },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Lite);
