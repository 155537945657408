export const PERTINENCIAS_FALLIDO = 'PERTINENCIAS_FALLIDO';
export const PERTINENCIAS_OBTENER = 'PERTINENCIAS_OBTENER';
export const PERTINENCIAS_FILTROS_FALLIDO = 'PERTINENCIAS_FILTROS_FALLIDO';
export const PERTINENCIAS_FILTROS_OBTENER = 'PERTINENCIAS_FILTROS_OBTENER';
export const PERTINENCIA_GUARDAR = 'PERTINENCIA_GUARDAR';
export const PERTINENCIA_OBTENER = 'PERTINENCIA_OBTENER';
export const PERTINENCIA_FALLIDO = 'PERTINENCIA_FALLIDO';
export const PERTINENCIAS_FILTROS_INPUT = 'PERTINENCIAS_FILTROS_INPUT';
export const LIMPIAR_PERTINENCIAS_FILTROS = 'LIMPIAR_PERTINENCIAS_FILTROS';
export const PERTINENCIAS_ESTADISTICAS_OBTENER = 'PERTINENCIAS_ESTADISTICAS_OBTENER';
export const PERTINENCIAS_ESTADISTICAS_FALLIDO = 'PERTINENCIAS_ESTADISTICAS_FALLIDO';
