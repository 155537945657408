import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2";

import {
  archivoEstado,
  eliminarArchivo,
  limpiarMensajes,
} from "../../../redux/actions/tareas/action";
import { Nuevo as CargaNuevo } from "./";

export class Tabla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carga_nueva_mostrar: false,
    };
  }

  mostrarNuevaCargaEntregable = () => {
    this.setState({ carga_nueva_mostrar: true });
  };

  cancelarCreacionCargaEntregable = () => {
    this.setState({ carga_nueva_mostrar: false });
  };

  tareaSeleccionada = () => {
    this.props.tareaSeleccionada();
  };

  estadoArchivo = (archivo, estado) => {
    const { archivoEstado, tarea_seleccionada, entregable } = this.props;
    var data_alerta = {
      title: "Estado de carga del entregable",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: "¿Está seguro de aceptar el archivo seleccionado?",
      icon: "info",
      confirmButtonText: 'Si, Aceptar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    };
    if (estado === "rechazado") {
      data_alerta["input"] = "textarea";
      data_alerta["text"] =
        "¿Está seguro de rechazar el archivo seleccionado? Favor ingrese una razón del rechazado de la carga del entregable";
      data_alerta["confirmButtonText"] =
        'Si, Rechazar <i class="fa fa-thumbs-up"></i>';
      data_alerta["inputPlaceholder"] = "Ingrese una razón";
      data_alerta["inputValidator"] = (value) => {
        if (!value) {
          return "Es obligatorio ingresar una razón del rechazo de la carga del entregable";
        }
        if (value && value.length < 20) {
          return "Al menos se debe ingresar 20 caracteres";
        }
      };
    }

    SweetAlert.fire(data_alerta).then((result) => {
      if (result.value) {
        var asunto = null;
        if (estado === "rechazado") {
          asunto = result.value;
        }
        archivoEstado({
          tarea: tarea_seleccionada,
          entregable: entregable.id,
          archivo: archivo.id,
          estado: estado,
          asunto: asunto,
        });
        SweetAlert.fire({
          title: "Estado de carga del entregable",
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos guardando el estado del archivo, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  eliminacionArchivo = (archivo, tipo) => {
    const { eliminarArchivo, tarea_seleccionada, entregable } = this.props;
    var mensaje = "";
    if (tipo === "carga") {
      mensaje = "de la carga del entregable";
    } else {
      mensaje = "del recurso de apoyo";
    }
    SweetAlert.fire({
      title: `Eliminación ${mensaje}`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      text: `¿Está seguro de eliminar ${
        tipo === "carga" ? "la carga del entregable" : "el recurso de apoyo"
      } seleccionado?`,
      icon: "info",
      confirmButtonText: 'Si, Eliminar <i class="fa fa-thumbs-up"></i>',
      cancelButtonText: 'No, Cancelar <i class="fa fa-times"></i>',
      customClass: {
        confirmButton: "btn btn-primary me-2",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        eliminarArchivo({
          tarea: tarea_seleccionada,
          entregable: entregable.id,
          archivo: archivo.id,
          tipo: tipo,
        });
        SweetAlert.fire({
          title: `Eliminación ${mensaje}`,
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: "Estamos eliminando lo seleccionado, espere unos segundos",
          icon: "warning",
          showConfirmButton: false,
          didOpen: () => {
            SweetAlert.showLoading();
          },
        });
      }
    });
  };

  render = () => {
    const { carga_nueva_mostrar } = this.state;
    const {
      limpiarMensajes,
      entregable,
      user_equipo,
      archivo_estado_error,
      archivo_estado,
    } = this.props;

    if (
      Object.entries(archivo_estado_error).length > 0 &&
      archivo_estado_error.data &&
      archivo_estado_error.data.message
    ) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Estado de carga del entregable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: archivo_estado_error.data.message,
        icon: "error",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          limpiarMensajes();
        }
      });
    }

    if (Object.entries(archivo_estado).length > 0) {
      SweetAlert.close();
      SweetAlert.fire({
        title: "Estado de carga del entregable",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: archivo_estado.message,
        icon: "success",
        confirmButtonText: 'Cerrar <i class="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.tareaSeleccionada();
          this.cancelarCreacionCargaEntregable();
          limpiarMensajes();
        }
      });
    }

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="float-end mb-3">
            {!carga_nueva_mostrar &&
              entregable.estado_text !== "Finalizada" &&
              user_equipo.rol_text !== "Visualizador" && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.mostrarNuevaCargaEntregable}
                >
                  Crear Carga del Entregable{" "}
                  <i className="fa fa-plus-circle"></i>
                </button>
              )}
          </div>
        </div>
        {carga_nueva_mostrar && (
          <CargaNuevo
            entregable={entregable}
            tareaSeleccionada={this.tareaSeleccionada}
            cancelarCreacionCargaEntregable={
              this.cancelarCreacionCargaEntregable
            }
          />
        )}
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-secondary">
                <tr>
                  <th className="text-center w-3">ID</th>
                  <th className="w-15">Nombre</th>
                  <th>Descripción</th>
                  <th className="text-center w-12">Originador de la Carga</th>
                  <th className="text-center w-7">Documento</th>
                  <th className="text-center w-7">Link</th>
                  <th className="w-10">Estado</th>
                  {entregable.estado_text !== "Finalizada" &&
                    user_equipo.rol_text !== "Visualizador" && (
                      <th className="text-center w-7">Acción</th>
                    )}
                </tr>
              </thead>
              <tbody>
                {entregable.cargas && entregable.cargas.length > 0 ? (
                  entregable.cargas.map((archivo, b) => (
                    <tr key={b}>
                      <td className="text-center">{b + 1}</td>
                      <td>{archivo.nombre}</td>
                      <td>{archivo.descripcion} </td>
                      <td className="text-center">{archivo.user_equipo}</td>
                      <td className="text-center">
                        {archivo.documento_nombre && (
                          <a
                            className="btn btn-xs btn-primary"
                            target="_blank"
                            rel="noreferrer"
                            href={`/static/documentos/${archivo.documento_nombre}`}
                          >
                            Ver <i className="fa fa-angle-right"></i>
                          </a>
                        )}
                      </td>
                      <td className="text-center">
                        {archivo.link && (
                          <a
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-xs"
                            target="_blank"
                            href={archivo.link}
                          >
                            Ver <i className="fa fa-angle-right"></i>
                          </a>
                        )}
                      </td>
                      <td>
                        {archivo.estado_text === "Aprobado" && (
                          <i className="fa fa-check-circle text-success"></i>
                        )}
                        {archivo.estado_text === "Rechazado" && (
                          <i className="fa fa-times-circle text-danger"></i>
                        )}
                        {archivo.estado_text === "En Revisión" && (
                          <i className="fas fa-question-circle text-warning"></i>
                        )}
                        <span className="ms-1">{archivo.estado_text}</span>
                        {archivo.estado_text === "Rechazado" &&
                          archivo.asunto && (
                            <>
                              <i
                                data-tip
                                data-tooltip-id={`ver_asunto_${archivo.id}`}
                                className="ms-1 fa fa-exclamation-circle"
                              ></i>
                              <Tooltip
                                id={`ver_asunto_${archivo.id}`}
                                aria-haspopup="true"
                              >
                                {archivo.asunto}
                              </Tooltip>
                            </>
                          )}
                      </td>
                      {entregable.estado_text !== "Finalizada" && (
                        <td className="text-center">
                          <div className="btn-group">
                            {archivo.estado_text === "En Revisión" &&
                              user_equipo.rol_text === "Propietario" && (
                                <>
                                  <button
                                    data-tip
                                    data-tooltip-id="aceptar_carga"
                                    onClick={this.estadoArchivo.bind(
                                      this,
                                      archivo,
                                      "aprobado"
                                    )}
                                    className="btn btn-xs btn-success"
                                  >
                                    <i className="fa fa-check"></i>
                                  </button>
                                  <Tooltip
                                    id="aceptar_carga"
                                    aria-haspopup="true"
                                  >
                                    Aceptar carga del entregable
                                  </Tooltip>
                                  <button
                                    data-tip
                                    data-tooltip-id="rechazar_carga"
                                    onClick={this.estadoArchivo.bind(
                                      this,
                                      archivo,
                                      "rechazado"
                                    )}
                                    className="btn btn-xs btn-danger"
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                  <Tooltip
                                    id="rechazar_carga"
                                    aria-haspopup="true"
                                  >
                                    Rechazar carga del entregable
                                  </Tooltip>
                                </>
                              )}
                            <button
                              onClick={this.eliminacionArchivo.bind(
                                this,
                                archivo,
                                "carga"
                              )}
                              data-tip
                              data-tooltip-id="eliminar_carga"
                              className="btn btn-xs btn-outline-danger"
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                            <Tooltip id="eliminar_carga" aria-haspopup="true">
                              Eliminar carga del entregable
                            </Tooltip>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="mt-4 text-center">
                      No existen cargas de entregables creados
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
}

Tabla.propTypes = {
  eliminarArchivo: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  archivoEstado: PropTypes.func.isRequired,
  tarea_seleccionada: PropTypes.number.isRequired,
  archivo_estado: PropTypes.object.isRequired,
  archivo_estado_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  tarea_seleccionada: state.tareas.tarea_seleccionada,
  archivo_estado: state.tareas.archivo_estado,
  archivo_estado_error: state.tareas.archivo_estado_error,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { eliminarArchivo, limpiarMensajes, archivoEstado },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabla);
