import InputMask from "@mona-health/react-input-mask";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";
import { SelectPicker } from "rsuite";
import {
  limpiarFiltroMedidas,
  obtenerMedidasProvisionales,
  obtenerMedidasProvisionalesFiltros,
} from "../../../redux/actions/teams/medidas/action";

export class Sanciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro_fecha_inicio:
        this.props.medidas_provisionales_filtro_input &&
        this.props.medidas_provisionales_filtro_input.fecha_inicio
          ? this.props.medidas_provisionales_filtro_input.fecha_inicio
          : "",
      filtro_fecha:
        this.props.medidas_provisionales_filtro_input &&
        this.props.medidas_provisionales_filtro_input.fecha
          ? this.props.medidas_provisionales_filtro_input.fecha
          : "",
      filtro_expediente:
        this.props.medidas_provisionales_filtro_input &&
        this.props.medidas_provisionales_filtro_input.expediente
          ? this.props.medidas_provisionales_filtro_input.expediente
          : "",
      filtro_palabra_clave:
        this.props.medidas_provisionales_filtro_input &&
        this.props.medidas_provisionales_filtro_input.palabra_clave
          ? this.props.medidas_provisionales_filtro_input.palabra_clave
          : "",
      filtro_categoria:
        this.props.medidas_provisionales_filtro_input &&
        this.props.medidas_provisionales_filtro_input.categoria
          ? this.props.medidas_provisionales_filtro_input.categoria
          : "",
      filtro_region:
        this.props.medidas_provisionales_filtro_input &&
        this.props.medidas_provisionales_filtro_input.region
          ? this.props.medidas_provisionales_filtro_input.region
          : "",
      filtro_estado:
        this.props.medidas_provisionales_filtro_input &&
        this.props.medidas_provisionales_filtro_input.estado
          ? this.props.medidas_provisionales_filtro_input.estado
          : "",
      filtro_tipo_documento:
        this.props.medidas_provisionales_filtro_input &&
        this.props.medidas_provisionales_filtro_input.tipo_documento
          ? this.props.medidas_provisionales_filtro_input.tipo_documento
          : "",
      filtros_avanzandos:
        this.props.medidas_provisionales_filtro_input &&
        this.props.medidas_provisionales_filtro_input.filtros_avanzandos
          ? this.props.medidas_provisionales_filtro_input.filtros_avanzandos
          : false,
    };
  }

  filtrosMedidas = () => {
    const { obtenerMedidasProvisionalesFiltros, obtenerMedidasProvisionales } =
      this.props;
    const {
      filtro_fecha_inicio,
      filtro_fecha,
      filtro_expediente,
      filtro_palabra_clave,
      filtro_categoria,
      filtro_region,
      filtro_estado,
      filtro_tipo_documento,
      filtros_avanzandos,
    } = this.state;

    if (
      filtro_fecha_inicio ||
      filtro_fecha ||
      filtro_expediente ||
      filtro_palabra_clave ||
      filtro_categoria ||
      filtro_region ||
      filtro_estado ||
      filtro_tipo_documento
    ) {
      var filtros = {
        fecha_inicio: filtro_fecha_inicio ? filtro_fecha_inicio : null,
        fecha: filtro_fecha ? filtro_fecha : null,
        expediente: filtro_expediente ? filtro_expediente : null,
        palabra_clave: filtro_palabra_clave ? filtro_palabra_clave : null,
        categoria: filtro_categoria ? filtro_categoria : null,
        region: filtro_region ? filtro_region : null,
        estado: filtro_estado ? filtro_estado : null,
        tipo_documento: filtro_tipo_documento ? filtro_tipo_documento : null,
        filtros_avanzandos: filtros_avanzandos,
      };
      obtenerMedidasProvisionalesFiltros({ filtros: filtros, pagina: 1 });
    } else {
      obtenerMedidasProvisionales(1);
    }
  };

  filtrosAvanzados = (estado) => {
    this.setState({
      filtros_avanzandos: estado,
    });
  };

  borrarFiltros = () => {
    const { obtenerMedidasProvisionales, limpiarFiltroMedidas } = this.props;
    this.setState({
      filtro_fecha_inicio: "",
      filtro_fecha: "",
      filtro_expediente: "",
      filtro_palabra_clave: "",
      filtro_categoria: "",
      filtro_region: "",
      filtro_estado: "",
      filtro_tipo_documento: "",
    });
    limpiarFiltroMedidas();
    obtenerMedidasProvisionales(1);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render = () => {
    const { filtros_errores, filtros_medidas } = this.props;
    const {
      filtro_fecha_inicio,
      filtro_fecha,
      filtro_palabra_clave,
      filtro_expediente,
      filtro_categoria,
      filtro_region,
      filtro_estado,
      filtro_tipo_documento,
      filtros_avanzandos,
    } = this.state;
    const categorias_listado =
      filtros_medidas && filtros_medidas["categorias"]
        ? _.orderBy(filtros_medidas["categorias"], "nombre")
        : [];
    const regiones_listado =
      filtros_medidas && filtros_medidas["regiones"]
        ? _.orderBy(filtros_medidas["regiones"], "nombre")
        : [];
    const estados_listado =
      filtros_medidas && filtros_medidas["estados"]
        ? _.orderBy(filtros_medidas["estados"], "nombre")
        : [];
    const tipos_documentos_listado =
      filtros_medidas && filtros_medidas["tipos_documentos"]
        ? _.orderBy(filtros_medidas["tipos_documentos"], "nombre")
        : [];

    return (
      <div className="card card-eco">
        <div className="card-header">
          <h5 className="float-start">FILTROS</h5>
          <div className="float-end">
            {filtros_avanzandos ? (
              <button
                className="btn btn-danger btn-sm"
                onClick={this.filtrosAvanzados.bind(this, false)}
              >
                Esconder filtros avanzados <i className="fa fa-angle-up"></i>
              </button>
            ) : (
              <button
                className="btn btn-primary btn-sm"
                onClick={this.filtrosAvanzados.bind(this, true)}
              >
                Mostrar filtros avanzados <i className="fa fa-angle-down"></i>
              </button>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>
                  Búsqueda por palabras claves
                  <i
                    className="fa fa-exclamation-circle ms-1 text-warning font-11"
                    data-tip
                    data-tooltip-id="palabraclave_medida"
                  ></i>
                  <Tooltip
                    className="w-30"
                    id="palabraclave_medida"
                    aria-haspopup="true"
                  >
                    Este campo de búsqueda permite buscar hechos infraccionales
                    en el marco de una medida provisional. Puedes buscar por
                    frases o palabras que quieres que estén presentes en la
                    acción. Ejemplo: la frase aguas servidas; o la palabra
                    suelo; o la combinación aguas servida norma.
                  </Tooltip>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_palabra_clave"
                    value={filtro_palabra_clave}
                    onChange={this.onChange}
                    placeholder="Ingrese palabra clave"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.palabra_clave
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.palabra_clave &&
                    filtros_errores.data.palabra_clave.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-6">
              <div className="form-group">
                <label>Expediente</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="filtro_expediente"
                    value={filtro_expediente}
                    onChange={this.onChange}
                    placeholder="Ingrese expediente"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.expediente
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.expediente &&
                    filtros_errores.data.expediente.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Tipo de Documento</label>
                <SelectPicker
                  name="filtro_tipo_documento"
                  data={tipos_documentos_listado}
                  onChange={(value) => {
                    this.setState({ filtro_tipo_documento: value });
                  }}
                  value={filtro_tipo_documento}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.tipo_documento &&
                  filtros_errores.data.tipo_documento.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de inicio</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha_inicio"
                    value={filtro_fecha_inicio}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha de inicio"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha_inicio
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha_inicio &&
                    filtros_errores.data.fecha_inicio.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Fecha de creación</label>
                <div className="input-group">
                  <InputMask
                    name="filtro_fecha"
                    value={filtro_fecha}
                    onChange={this.onChange}
                    mask="99/99/9999"
                    placeholder="Ingrese una fecha de término"
                    className={`form-control ${
                      filtros_errores &&
                      filtros_errores.data &&
                      filtros_errores.data.fecha
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {filtros_errores &&
                    filtros_errores.data &&
                    filtros_errores.data.fecha &&
                    filtros_errores.data.fecha.map((err, x) => (
                      <div key={x} className="invalid-feedback">
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Categoría</label>
                <SelectPicker
                  name="filtro_categoria"
                  data={categorias_listado}
                  onChange={(value) => {
                    this.setState({ filtro_categoria: value });
                  }}
                  value={filtro_categoria}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.categoria &&
                  filtros_errores.data.categoria.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Región</label>
                <SelectPicker
                  name="filtro_region"
                  data={regiones_listado}
                  onChange={(value) => {
                    this.setState({ filtro_region: value });
                  }}
                  value={filtro_region}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.region &&
                  filtros_errores.data.region.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
            <div
              className={`col-lg-3 col-sm-4 col-6 ${
                filtros_avanzandos ? "" : "d-none"
              }`}
            >
              <div className="form-group">
                <label>Estado</label>
                <SelectPicker
                  name="filtro_estado"
                  data={estados_listado}
                  onChange={(value) => {
                    this.setState({ filtro_estado: value });
                  }}
                  value={filtro_estado}
                  locale={{
                    noResultsText: "Sin resultados",
                    placeholder: "Selecciona una opción",
                    searchPlaceholder: "Buscar",
                  }}
                  block
                />
                {filtros_errores &&
                  filtros_errores.data &&
                  filtros_errores.data.estado &&
                  filtros_errores.data.estado.map((err, x) => (
                    <div key={x} className="invalid-feedback">
                      {err}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-end">
          <div className="btn-group" role="group">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.filtrosMedidas}
            >
              <i className="fa fa-search"></i> Filtrar medidas provisionales
            </button>
            <button
              type="button"
              className="btn btn-soft-primary"
              onClick={this.borrarFiltros}
            >
              <i className="fa fa-trash"></i> Limpiar
            </button>
          </div>
        </div>
      </div>
    );
  };
}

Sanciones.propTypes = {
  obtenerMedidasProvisionales: PropTypes.func.isRequired,
  obtenerMedidasProvisionalesFiltros: PropTypes.func.isRequired,
  limpiarFiltroMedidas: PropTypes.func.isRequired,
  filtros_errores: PropTypes.object.isRequired,
  filtros_medidas: PropTypes.object.isRequired,
  medidas_provisionales_filtro_input: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filtros_errores: state.teams.filtros_errores,
  filtros_medidas: state.teams.filtros_medidas,
  medidas_provisionales_filtro_input:
    state.teams.medidas_provisionales_filtro_input,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerMedidasProvisionales,
      obtenerMedidasProvisionalesFiltros,
      limpiarFiltroMedidas,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sanciones);
