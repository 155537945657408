import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import {
  AUTENTIFICACION_FALLIDO,
  CERRAR_SESION_CORRECTO,
  CONTRASENATOKEN_FALLIDO,
  CONTRASENATOKEN_NUEVA,
  CONTRASENA_FALLIDO,
  CONTRASENA_NUEVA,
  INICIO_SESION_CORRECTO,
  INICIO_SESION_FALLIDO,
  MENSAJES_LIMPIAR,
  REGISTRATE_CORRECTO,
  REGISTRATE_FALLIDO,
  REGISTRO_CORRECTO,
  REGISTRO_FALLIDO,
  SIDEBAR_ESTADO,
  TOKEN_CORRECTO,
  TOKEN_FALLIDO,
  TOKEN_REGISTRO_CORRECTO,
  TOKEN_REGISTRO_FALLIDO,
  USUARIO_CARGADO,
  USUARIO_CARGANDO,
} from "./types";

var url_api = process.env.REACT_APP_API_URL;

export const cargarUsuario = () => (dispatch, getState) => {
  dispatch({ type: USUARIO_CARGANDO });
  axios
    .get(`${url_api}/api/users/auth/user`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USUARIO_CARGADO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: AUTENTIFICACION_FALLIDO,
        payload: err.response,
      });
    });
};

export const iniciarSesion = (email, password) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });
  axios
    .post(`${url_api}/api/users/auth/login`, body, config)
    .then((res) => {
      dispatch({
        type: INICIO_SESION_CORRECTO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: INICIO_SESION_FALLIDO,
        payload: err.response,
      });
    });
};

export const registrate = (email) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email });
  axios
    .post(`${url_api}/api/users/auth/registrate`, body, config)
    .then((res) => {
      dispatch({
        type: REGISTRATE_CORRECTO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: REGISTRATE_FALLIDO,
        payload: err.response,
      });
    });
};

export const validarToken = (data) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .post(`${url_api}/api/users/auth/validar-token`, data, config)
    .then((res) => {
      dispatch({
        type: TOKEN_CORRECTO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: TOKEN_FALLIDO,
        payload: err.response,
      });
    });
};

export const validarTokenRegistro = (data) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .post(`${url_api}/api/users/auth/registro`, data, config)
    .then((res) => {
      dispatch({
        type: TOKEN_REGISTRO_CORRECTO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: TOKEN_REGISTRO_FALLIDO,
        payload: err.response,
      });
    });
};

export const nuevaContrasenaToken = (data) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .post(`${url_api}/api/users/auth/nueva-contrasena-token`, data, config)
    .then((res) => {
      dispatch({
        type: CONTRASENATOKEN_NUEVA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONTRASENATOKEN_FALLIDO,
        payload: err.response,
      });
    });
};

export const nuevaContrasena = (data) => (dispatch, getState) => {
  axios
    .post(
      `${url_api}/api/users/auth/nueva-contrasena`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: CONTRASENA_NUEVA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONTRASENA_FALLIDO,
        payload: err.response,
      });
    });
};

export const cambioContrasena = (email) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .post(
      `${url_api}/api/users/auth/restablecer-contrasena`,
      { email: email },
      config
    )
    .then((res) => {
      dispatch({
        type: CONTRASENATOKEN_NUEVA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONTRASENATOKEN_FALLIDO,
        payload: err.response,
      });
    });
};

export const registro = (data) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .put(`${url_api}/api/users/auth/registro`, data, config)
    .then((res) => {
      dispatch({
        type: REGISTRO_CORRECTO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: REGISTRO_FALLIDO,
        payload: err.response,
      });
    });
};

export const cerrarSesion = () => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/users/auth/logout`, null, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: MENSAJES_LIMPIAR });
      dispatch({ type: CERRAR_SESION_CORRECTO });
    })
    .catch((err) => {
      dispatch({
        type: INICIO_SESION_FALLIDO,
        payload: err.response,
      });
    });
};

export const tokenConfig = (getState) => {
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

export const tokenArchivoDescargaConfig = (getState) => {
  const token = getState().auth.token;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  };
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

export const tokenConfigArchivo = (getState, archivo) => {
  const token = getState().auth.token;
  var file = archivo.name;
  var ext = (function (file, lio) {
    return lio === -1 ? undefined : file.substring(lio + 1);
  })(file, file.lastIndexOf("."));
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Content-Disposition": `attachment; filename=${uuidv4()}.${ext}`,
    },
  };
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

export const limpiarMensajes = () => (dispatch) => {
  dispatch({
    type: MENSAJES_LIMPIAR,
  });
};

export const estadoSidebar = (data) => (dispatch) => {
  dispatch({
    type: SIDEBAR_ESTADO,
    payload: data,
  });
};
