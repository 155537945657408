import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import SweetAlert from "sweetalert2/src/sweetalert2";

import {
  loadingTrue,
  obtenerNorma,
  setArticuloTarea,
} from "../../../redux/actions/leyes/action";
import { Footer, Navbar, SidebarInicio } from "../../general";
import { Ficha } from "./";

export class Detalle extends Component {
  componentDidMount = () => {
    const { obtenerNorma, norma_id } = this.props;
    obtenerNorma({ id: norma_id });
  };

  nuevaTarea = (articulo) => {
    const { setArticuloTarea } = this.props;
    setArticuloTarea(articulo);
  };

  render = () => {
    const { norma_id, norma, is_loading_leyes, redirect_fav } = this.props;
    if (norma_id === 0) {
      return <Navigate to="/legal-tracking/favoritos" />;
    }

    if (is_loading_leyes) {
      SweetAlert.fire({
        title: "Norma Favorita",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "Estamos cargando la norma, espere unos segundos",
        icon: "warning",
        showConfirmButton: false,
        didOpen: () => {
          SweetAlert.showLoading();
        },
      });
    } else {
      SweetAlert.close();
    }

    return (
      <div className="page-wrapper">
        <Navbar />
        <SidebarInicio
          menu_1={`leyes_${redirect_fav ? "favoritos" : "normas"}`}
        />
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="float-start">
                    <i className="fa fa-heart"></i> NORMA FAVORITA
                  </h4>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="card card-eco">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-lg-8"></div>
                      <div className="col-lg-4">
                        <div className="float-end">
                          <Link
                            to={
                              redirect_fav
                                ? "/legal-tracking/favoritos"
                                : "/legal-tracking/normas"
                            }
                            className="btn btn-primary"
                          >
                            <i className="fa fa-angle-left"></i> Volver a las
                            Normas
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <Ficha norma={norma} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };
}

Detalle.propTypes = {
  obtenerNorma: PropTypes.func.isRequired,
  loadingTrue: PropTypes.func.isRequired,
  setArticuloTarea: PropTypes.func.isRequired,
  norma: PropTypes.object.isRequired,
  norma_id: PropTypes.number.isRequired,
  is_loading_leyes: PropTypes.bool.isRequired,
  redirect_fav: PropTypes.bool.isRequired,
  titulo_seleccionado: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  norma: state.leyes.norma,
  norma_id: state.leyes.norma_id,
  is_loading_leyes: state.leyes.is_loading_leyes,
  titulo_seleccionado: state.leyes.titulo_seleccionado,
  redirect_fav: state.leyes.redirect_fav,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      obtenerNorma,
      loadingTrue,
      setArticuloTarea,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);
