import React, { Component } from 'react';

import _ from 'lodash';
import Chart from 'react-apexcharts';

export class Pie extends Component {

  render = () => {
    const { nombre, data } = this.props

    return (
      <div className="card card-eco card-grafico">
        <div className="card-body">
          <h5><i className="fa fa-business-time text-primary"></i> {nombre}</h5>
          <div className="p-3">
            <Chart
              options={{
                labels: _.keys(data),
                legend: {
                  position: 'bottom',
                },
                tooltip: {
                  fixed: {
                    enabled: true,
                  },
                },
              }}
              series={_.values(data)}
              type="pie"
            />
          </div>
        </div>
      </div>
    );
  }
}


export default Pie;
