import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

export class SidebarLeft extends Component {
  render = () => {
    const { menu_1, siderbar_estado, user } = this.props;
    return (
      <div className={`left-sidenav ${siderbar_estado ? "d-none" : ""}`}>
        <ul className="left-sidenav-menu">
          <li className="mm-inactivo">
            <Link to="/permitting-target/proyectos">
              <i className="fa fa-angle-left"></i>
              <span>Cambiar de proyecto</span>
            </Link>
          </li>
          <li
            className={
              menu_1 && menu_1 === "permitting_dashboard"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link
              className="nav-link"
              to="/permitting-target/aplicabilidad/dashboard"
            >
              <i className="fa fa-chart-pie"></i> Dashboard
            </Link>
          </li>
          <li
            className={
              menu_1 && menu_1 === "permitting_permisos"
                ? "mm-active"
                : "mm-inactivo"
            }
          >
            <Link
              className="nav-link"
              to="/permitting-target/aplicabilidad/permisos"
            >
              <i className="fa fa-list"></i> Plan maestro de permisos
            </Link>
          </li>
          {user.is_staff && (
            <li
              className={
                menu_1 && menu_1 === "permitting_requisitos"
                  ? "mm-active"
                  : "mm-inactivo"
              }
            >
              <Link
                className="nav-link"
                to="/permitting-target/aplicabilidad/requisitos"
              >
                <i className="fa fa-random"></i> Requisitos
              </Link>
            </li>
          )}
        </ul>
      </div>
    );
  };
}

SidebarLeft.propTypes = {
  siderbar_estado: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  siderbar_estado: state.auth.siderbar_estado,
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(SidebarLeft);
